import React, { useEffect } from 'react';
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function ConnectCreateSecondStep({ NextFormStep, handlePreviousOpen, data, error, handleChange, VersionList, GatewayData, GatewayDescription, UserData }) {
  var today = new Date();
  // const [configName, setconfigName] = useState();
  // const [configDesc, setconfigDesc] = useState();
  var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

  useEffect(() => {
    const configname = data.appname + " configuration v1";
    const configdesc = data.appname + " configuration v1 " + date;
    data.gatewaydesc = GatewayDescription?.description;

    data.apkConfigname = configname;
    data.apkconfigdesc = configdesc;
    //  setconfigName(configname);
    //  setconfigDesc(configdesc);
  }, [data])

  let history = useHistory();
  return (
    <div className="directapplication">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="gridHead">Step 2 of 6 - APK Configuration Details</div>
        </Grid>

        <Grid item xs={4}>
          <div className="company_Label">CONNECT Config Server Gateway</div>

          <div>
            <select type="text"
              className={
                !error.servergatway ? "directFieldSelect fieldStyle" : "directFieldSelecterr fieldStyle"
              }
              id="servergatway"
              style={{ height: '38px' }}
              name="servergatway"
              onChange={handleChange}
              defaultValue={data.servergatway}>

              <option></option>
              {GatewayData && GatewayData.length > 0
                ? GatewayData.map((post) => (
                  <option key={post.lic_serv_id} value={post.gateway}>
                    {post.gateway}
                  </option>
                ))
                : null}
            </select>
            {error.servergatway ? (
              <span style={{ float: 'left' }} className="errormsg">{error.msg}</span>
            ) : (
              ""
            )}
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <div className="company_Label">Gateway Description</div>

          <div>
            <input
              type="text"
              className="direct_FieldInput fieldStyle"
              name="gatewaydesc"
              id="gatewaydesc"
              onChange={handleChange}
              defaultValue={data.gatewaydesc}
              value={GatewayDescription?.description}
              disabled="disabled"
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="company_Label">APK Config Name</div>

          <div>

            <input
              type="text"
              style={{ width: '97%' }}
              className={
                !error.apkConfigname ? "directFieldSelect fieldStyle" : "directFieldSelecterr fieldStyle"
              }
              name="apkConfigname"
              id="apkConfigname"
              onChange={handleChange}
              defaultValue={data.apkConfigname}
            />
            {error.apkConfigname ? (
              <span style={{ float: 'left' }} className="errormsg">{error.msg}</span>
            ) : (
              ""
            )}
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <div className="company_Label">APK Config Description</div>

          <div>

            <input
              type="text"
              className={
                !error.apkconfigdesc ? "directFieldSelect fieldStyle" : "directFieldSelecterr fieldStyle"
              }
              name="apkconfigdesc"
              id="apkconfigdesc"
              defaultValue={data.apkconfigdesc}
              onChange={handleChange}
            />
            {error.apkconfigdesc ? (
              <span style={{ float: 'left' }} className="errormsg">{error.msg}</span>
            ) : (
              ""
            )}
          </div>
        </Grid>


        <Grid item xs={4}>
          <div className="company_Label">Datafree APK Library Version</div>

          <div>
            <select type="text"
              className={
                !error.apklibversion ? "directFieldSelect fieldStyle" : "directFieldSelecterr fieldStyle"
              }
              id="apklibversion"
              style={{ height: '38px' }}
              name="apklibversion"
              onChange={handleChange}
              defaultValue={data.apklibversion}>
              <option></option>
              {VersionList && VersionList.length > 0
                ? VersionList.map((post) => (
                  <option key={post.binu_client_version_id} value={post.binu_client_version_id}>
                    {post.binu_client_version_name + "(" + post.version_tag + ")"}
                  </option>
                ))
                : null}
            </select>
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <div className="company_Label">Distribution Email</div>

          <div>

            <input
              type="text"
              className={
                !error.distribution_mail ? "directFieldSelect fieldStyle" : "directFieldSelecterr fieldStyle"
              }
              name="distribution_mail"
              id="distribution_mail"
              defaultValue={data.distribution_mail}
              onChange={handleChange}
            />
            {error.distribution_mail ? (
              <span style={{ float: 'left' }} className="errormsg">{error.distemailmsg}</span>
            ) : (
              ""
            )}

          </div>
        </Grid>

        <Grid item xs={6}>
          <div className="ivitediv">
            <button className="datafreeConnectBtnDanger" color="primary"
              onClick={() => history.push("/admin/datafreeconnect/application/index")}>
              Cancel
            </button>
          </div>
        </Grid>
        <Grid item xs={6}>
          {" "}
          <div className="ivitediv">
            <button className="datafreeConnectButton" color="primary" onClick={() => handlePreviousOpen(2)}>
              Previous
            </button>
            {" "}
            <button className="datafreeConnectButton" color="primary" style={{ marginLeft: '10px' }}
              onClick={() => NextFormStep(2)}>
              Next
            </button>
          </div>
        </Grid>


      </Grid>

      <div className="divSpace"></div>
    </div>
  )
}

export default ConnectCreateSecondStep
