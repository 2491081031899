import React, { useState, useEffect } from "react";
import Loader from "react-loading";
import {
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Box,
  withStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker } from "@material-ui/pickers";
import { FaDownload } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import alpha from "../../../assets/img/blank1.png";
import defaultIcon from "../../../assets/img/datafree_defaulticon.png";
import defaultSplash from "../../../assets/img/default-app-splash.jpg";
import service from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
const AntTabs = Tabs;
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AdminConnectApplication(props) {
  const classes = useStyles();
  let API_URL = process.env.REACT_APP_API_URL;
  const [deleteopen, setDeleteOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [val, setVal] = React.useState(0);
  const [checked] = useState(false);
  const [color, setColor] = useState("#303f9f");
  const [actionColor] = useState("#3f51b5");
  const [accentColor] = useState("#ff4081");
  const [applicationData, setApplicationData] = useState(false);
  const [configDetail, setCOnfigDetail] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [configCreateopen, setConfigCreateopen] = useState(false);
  const [appConfig, setAppConfig] = useState(false);
  const [accentcolorchecked, setAccentColorchecked] = useState(false);
  const [actioncolorchecked, setActionColorchecked] = useState(false);
  const [editAppOpen, setEditAppOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const { appId } = useParams();
  const [icon, setIcon] = useState({ preview: "", raw: "" });
  const [splash, setSplash] = useState({ preview: "", raw: "" });
  const [logo, setLogo] = useState({ preview: "", raw: "" });
  const [colorchecked, setColorchecked] = useState(false);
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  const appReg = /^[^'",]*$/i;
  const identifyReg = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/i;
  const appIdReg = /^[a-z][a-z0-9_]*(\.[a-z][a-z0-9_]*)+[0-9a-z_]?$/i;
  const [openBuild, setopenBuild] = useState(false);
  const [openPublish, setopenPublish] = useState(false);
  const [mdbuildapk, setmdbuildapk] = useState(false)
  const [editConfig, setEditConfig] = useState(false);
  const [configData, setConfigData] = useState({
    dev_configs: "",
    name: "",
    deploymentType: "",
    description: "",
  });

  
  const handleClose = () => {
    setDeleteOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setVal(newValue);
  };


  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setConfigCreateopen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  const handleAppClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setEditAppOpen(false);
    }

  };
  const handleAppOpen = () => {
    setEditAppOpen(true);
  };
  const openBuildClose = () => {
    setopenBuild(false);
  };
  const openBuildOpen = () => {

    getBuildDetails(applicationData?.applicationDeploymentObj?.deploymentId ? applicationData?.applicationDeploymentObj?.deploymentId : "")
    setopenBuild(true);
    setScroll('body');
  };

  const openPublishClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setopenPublish(false);
    }

  };
  const openPublishOpen = () => {
    getPublish(applicationData?.applicationDeploymentObj?.deploymentId ? applicationData?.applicationDeploymentObj?.deploymentId : "");
    setopenPublish(true);

  };

  function openModalBulidApk() {
    getBuildApk(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")
    getBuildPopup(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")

    setmdbuildapk(true);
    setScroll('body');
  }
  const [BuildApkData, setBuildApkData] = useState();
  const [editApk, setEditApk] = useState({
    build_id: "",
    version_code: "",
    version_name: "",
    comments: "",
    email_distribution: "",
    buildTypes: "",
    package_suffix: "",
    application_id: "",
    deployment_type: "",
    deployment_id: "",
    configid: ""
  });

  const [ApkError, setApkError] = useState({

    version_code: false,
    version_name: false,
    comments: false,
    email_distribution: false,
    buildTypes: false,
    package_suffix: false,
    versionMsg: "",
    emailMsg: "",
  });

  const [configIds] = useState({
    configId: ""
  });
  const getBuildApk = (id) => {
    configIds["configId"] = id;
    try {
      trackPromise(
        service.ViewApk(configIds).then((res) => {

          setBuildApkData(res.data);
          setEditApk({
            build_id: "",
            version_code: res.data?.obj.versionCode ? res.data?.obj.versionCode : "",
            version_name: res.data?.obj.versionName ? res.data?.obj.versionName : "",
            comments: res.data?.obj.comment ? res.data?.obj.comment : "",
            email_distribution: res.data?.obj.distributionEmail ? res.data?.obj.distributionEmail : "",
            buildTypes: "",
            package_suffix: res.data?.obj.packageSuffix ? res.data?.obj.packageSuffix : "",
            application_id: res.data?.obj.applicationid ? res.data?.obj.applicationid : "",
            deployment_type: res.data?.obj.deploymentType ? res.data?.obj.deploymentType : "",
            deployment_id: res.data?.obj.deploymentId ? res.data?.obj.deploymentId : "",
            configid: id,

          })

        })
      );
    } catch (err) {

    }
  }

  const getBuildPopup = (id) => {
    configIds["configId"] = id;
    try {
      trackPromise(
        service.GetPopup(configIds).then((res) => {

        })
      );
    } catch (err) {

    }
  }

  const ValidateApk = (editApk) => {
    let apkcounts = 0;
    const apkerror = { ...ApkError };

    if (editApk.version_code === "") {
      apkerror.version_code = true;
      apkerror.versionMsg = "This field is required";
      apkcounts++;
    }
    else {
      if (versionReg.test(editApk.version_code) === false) {
        apkerror.version_code = true;
        apkerror.versionMsg = "Please enter only digits.";
        apkcounts++;
      }
      else {
        apkerror.version_code = false;
        apkerror.versionMsg = "";
      }
    }


    if (editApk.email_distribution === "") {
      apkerror.email_distribution = true;
      apkerror.emailMsg = "This field is required";
      apkcounts++;
    }
    else {
      if (distemail.test(editApk.email_distribution) === false) {
        apkerror.email_distribution = true;
        apkerror.emailMsg = "You must enter a valid email, or comma separated emails";
        apkcounts++;
      }
      else {
        apkerror.email_distribution = false;
        apkerror.emailMsg = "";
      }
    }


    if (editApk.version_name === "") {
      apkerror.version_name = true;
      apkcounts++;
    }
    else {
      apkerror.version_name = false;
    }

    if (editApk.comments === "") {
      apkerror.comments = true;
      apkcounts++;
    }
    else {
      apkerror.comments = false;
    }

    if (editApk.buildTypes === "") {
      apkerror.buildTypes = true;
      apkcounts++;
    }
    else {
      apkerror.buildTypes = false;
    }

    setApkError(apkerror);
    return apkcounts;
  }
  const [loading, setLoading] = useState(false);
  const BuildSave = () => {
    const apkCount = ValidateApk(editApk);
    if (apkCount === 0) {
      setLoading(true);
      try {
        trackPromise(
          service.GenerateApk(editApk).then((res) => {
            if (res.data.status === "success") {
              setmdbuildapk(false);
              setLoading(false);
            }
          })
        );
      } catch (err) {

      }
    }
  }
  const [deploy] = useState({
    deploymentId: ""
  });
  const [PublishData, setPublishData] = useState();
  const [BuildDetails, setBuildDetails] = useState();
  const getPublish = (id) => {
    deploy["deploymentId"] = id;
    try {
      trackPromise(
        service.viewPublish(deploy).then((res) => {

          setPublishData(res.data?.publishDetails);
        })
      );
    } catch (err) {

    }
  }

  const getBuildDetails = (id) => {
    deploy["deploymentId"] = id;
    try {
      trackPromise(
        service.viewBuild(deploy).then((res) => {

          setBuildDetails(res.data?.BuildDetails);

        })
      );
    } catch (err) {

    }
  }
  const [appIdVal] = useState({
    build_id: "",
    application_id: appId,
  })
  const [openApprove, setOpenApprove] = useState(false);
  const handleApproveAlertClose = () => {
    setOpenApprove(false);
  };
  const [state] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const [pubError, setPubError] = useState();
  const SavePubish = () => {

    try {
      trackPromise(
        service.editPublish(appIdVal).then((res) => {
          openPublishClose();
          if (res.status === "success") {
            window.location.reload();
          }
          else {
            setOpenApprove(true);
            setPubError(JSON.stringify(res.data));

          }
        })
      );
    } catch (err) {

    }
  }

  function closeModalBuildApk(event, reason) {
    if (reason !== 'backdropClick') {
      setmdbuildapk(false);
    }


  }
  const versionReg = /^[0-9]*$/;

  const handleApkEdit = (e) => {
    //
    e.preventDefault();
    const newdata = { ...editApk };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "version_code") {
      if (e.target.value) {
        if (versionReg.test(e.target.value) === false) {
          setApkError({
            ...ApkError,
            version_code: true,
            versionMsg: "Please enter only digits.",
          })
        }
        else {
          setApkError({
            ...ApkError,
            version_code: false,
            versionMsg: "",
          })
        }
      }
      else {
        setApkError({
          ...ApkError,
          version_code: true,
          versionMsg: "This field is required",
        })
      }
    }

    if (e.target.id === "version_name") {
      if (e.target.value) {
        setApkError({
          ...ApkError,
          version_name: false,
        })
      }
      else {
        setApkError({
          ...ApkError,
          version_name: true,
        })
      }
    }

    if (e.target.id === "comments") {
      if (e.target.value) {
        setApkError({
          ...ApkError,
          comments: false,
        })
      }
      else {
        setApkError({
          ...ApkError,
          comments: true,
        })
      }
    }

    if (e.target.id === "buildTypes") {
      if (e.target.value) {
        setApkError({
          ...ApkError,
          buildTypes: false,
        })
      }
      else {
        setApkError({
          ...ApkError,
          buildTypes: true,
        })
      }
    }

    if (e.target.id === "email_distribution") {
      if (e.target.value) {
        if (distemail.test(e.target.value) === false) {
          setApkError({
            ...ApkError,
            email_distribution: true,
            emailMsg: "You must enter a valid email, or comma separated emails",
          })
        }
        else {
          setApkError({
            ...ApkError,
            email_distribution: false,
            emailMsg: "",
          })
        }
      }
      else {
        setApkError({
          ...ApkError,
          email_distribution: true,
          emailMsg: "This field is required",
        })
      }
    }
    setEditApk(newdata);
  }
  const [buildIds] = useState({
    buildId: ""
  })

  const [MoreData, setMoreData] = useState();
  const [AppAttributes, setAppAttributes] = useState();
  const GetInfo = (id) => {
    buildIds["buildId"] = id;
    try {
      trackPromise(
        service.moreInfo(buildIds).then((res) => {

          setMoreData(res.data.moreInfo);
          const val = res.data.moreInfo?.appAttributes.replace(/["{}]/g, "");

          setAppAttributes(val.split(','));
          
          let x = document.getElementById("Moreinfo_" + id);
          let y = document.getElementById("debugDownload" + id);
          if (x.style.display === "none") {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
          if (y.style.display === "block") {
            y.style.display = "none";
          } else {
            y.style.display = "none";
          }

        })
      );
    } catch (err) {

    }
  }
  // const [LogoImage, setLogoImage] = useState({
  //   logo_image: ""
  // })
  // const [IconImage, setIconImage] = useState({
  //   icon_image: ""
  // })
  // const [SplashImage, setSplashImage] = useState({
  //   splash_image: ""
  // })
  const [lodervisible, setlodervisible] = useState(false);
  const getApplicationDetails = () => {
    setlodervisible(true);
    try {
      trackPromise(
        service.connectDetailIndex(appId).then((res) => {

          setApplicationData(res.data);
          setEdits({
            name: res.data.appliactionObj?.name,
            splash_image: res.data.appliactionObj?.splashScreenImgUrl,
            logo_image: res.data.appliactionObj?.logoUrl,
            icon_image: res.data.appliactionObj?.iconUrl,
            store_id: res.data.appliactionObj?.googleAppId,
            application_id: appId,

          })

          setEditConfigData({
            config_name: res.data.editConfigDetails ? res.data.editConfigDetails?.name : "",
            description: res.data.editConfigDetails ? res.data.editConfigDetails?.description : "",
            platform: res.data.editConfigDetails ? res.data.editConfigDetails?.platformId : "",
            distribution_email: res.data.editConfigDetails ? res.data.editConfigDetails?.distributionEmail : "",
            configId: res.data.editConfigDetails ? res.data.editConfigDetails?.id : "",
            deploymentType: res.data.editConfigDetails ? res.data.editConfigDetails?.deploymentType : "",
            currentPlatform: res.data.editConfigDetails ? res.data.editConfigDetails?.platformId : "",
            productId: res.data.editConfigDetails ? res.data.editConfigDetails?.productId : ""
          })
          setlodervisible(false);
          setLookDatas({
            status_bar_color: res.data.editConfigDetails ? res.data.editConfigDetails?.statusbarcolor : "",
            header_color: res.data.editConfigDetails ? res.data.editConfigDetails?.actionBarColor : "",
            accent_color: res.data.editConfigDetails ? res.data.editConfigDetails?.accentColor : "",
            status_manual: res.data.editConfigDetails ? res.data.editConfigDetails?.statusbarcolor : "",
            header_manual: res.data.editConfigDetails ? res.data.editConfigDetails?.actionBarColor : "",
            accent_manual: res.data.editConfigDetails ? res.data.editConfigDetails?.accentColor : "",
            deploymentType: res.data.editConfigDetails ? res.data.editConfigDetails?.deploymentType : "",
            use_dark_action_bar_icons: res.data.editConfigDetails ? res.data.editConfigDetails?.useDarkActionBarIcons : "",
            names: res.data.editConfigDetails ? res.data.editConfigDetails?.name : "",
            descriptions: res.data.editConfigDetails ? res.data.editConfigDetails?.description : "",
            configId: res.data.editConfigDetails ? res.data.editConfigDetails?.id : "",
            page_title: res.data.editConfigDetails ? res.data.editConfigDetails?.pageTitle : "",
          })

          setCOnfigDetail(res.data.applicationObj.existingConfigDetails);

        })
      );
    } catch (err) {

    }
  };
  const [errorConfig, setErrorConfig] = useState({
    config_name: false,
    description: false,
    distribution_email: false,
    currentPlatform: false,
    nameMsg: "",
    descmsg: "",
    emailmsg: ""
  })
  const [editConfigData, setEditConfigData] = useState({
    config_name: "",
    description: "",
    platform: "",
    distribution_email: "",
    configId: "",
    deploymentType: "PROD",
    currentPlatform: "",
    productId: ""
  })
  const [edits, setEdits] = useState({
    name: "",
    store_id: "",
    icon_image: "",
    logo_image: "",
    splash_image: "",
    application_id: ""
  })
  const [error, setError] = useState({
    name: false,
    store_id: false,
    icon_image: false,
    splash_image: false,
    logo_image: false,
    logomsg: "",
    msg: "",
    nameMsg: "",
    imgmsg: "",
    iconmsg: "",
    splashmsg: "",
    packageMsg: "",
  })
  const [statusLogo, setStatusLogo] = useState(0);
  const [statusIcon, setStatusIcon] = useState(0);
  const [statusSplash, setStatusSplash] = useState(0);
  function UploadLogoImage(imageVal) {

    setStatusLogo(100);
    let file = imageVal;



    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("logo_icon_image", file ? file : "");
    }


    setEdits({
      ...edits,
      logo_image: "",
    })
    trackPromise(
      service.uploadLogo(values).then((res) => {

        setStatusLogo(res.status);
        setEdits({
          ...edits,
          logo_image: res.data,
        })

      }).catch((error) => {


      })

    );


  }

  function UploadLogoDefault() {


    fetch(defaultIcon)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "datafree_defaulticon.png";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "datafree_defaulticon.png", {
          type: myBlob.type,
        });

        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("logo_icon_image", myFile ? myFile : "");
        }


        trackPromise(
          service.uploadLogo(values).then((res) => {

            // setLogoImage({
            //   logo_image: res.data,
            // })

          }).catch((error) => {


          })

        );
      });

  }

  function UploadIconImage(imageVal) {

    setStatusIcon(100);
    let file = imageVal;


    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("icon_image", file ? file : "");
    }


    setEdits({
      ...edits,
      icon_image: "",
    })
    trackPromise(
      service.uploadIcon(values).then((res) => {

        setStatusIcon(res.status);

        setEdits({
          ...edits,
          icon_image: res.data,
        })
      }).catch((error) => {


      })

    );
  }


  function UploadSplashDefault() {


    fetch(defaultSplash)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "default-app-splash.jpg";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "default-app-splash.jpg", {
          type: myBlob.type,
        });



        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("splash_image", myFile ? myFile : "");
        }


        trackPromise(
          service.uploadSplash(values).then((res) => {

            // setSplashImage({
            //   splash_image: res.data,

            // })
          }).catch((error) => {


          })

        );
      });

  }

  function UploadSplashImage(imageVal) {

    setStatusSplash(100);
    let file = imageVal;



    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("splash_image", file ? file : "");
    }

    trackPromise(
      service.uploadSplash(values).then((res) => {

        setStatusSplash(res.status);

        setEdits({
          ...edits,
          splash_image: res.data,
        })
      }).catch((error) => {


      })

    );


  }

  function UploadIconDefault() {


    fetch(defaultIcon)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "datafree_defaulticon.png";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "datafree_defaulticon.png", {
          type: myBlob.type,
        });



        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("icon_image", myFile ? myFile : "");
        }


        trackPromise(
          service.uploadIcon(values).then((res) => {

            // setIconImage({
            //   icon_image: res.data,
            // })

          }).catch((error) => {


          })

        );
      });

  }

  const handleEdit = (e) => {
    e.preventDefault();

    const newdata = { ...edits };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "name") {
      if (e.target.value) {
        if (appReg.test(e.target.value) === false) {
          setError({
            ...error,
            name: true,
            nameMsg: `Comma(,), Single Quote('), Double Quote(") characters not allowed`,

          })
        }
        else {
          setError({
            ...error,
            name: false,
            nameMsg: "",

          })
        }

      }
      else {
        setError({
          ...error,
          name: true,
          nameMsg: "This field is required",

        })
      }
    }

    if (e.target.id === "store_id") {
      if (e.target.value) {
        if (appIdReg.test(e.target.value) === false) {
          setError({
            ...error,
            store_id: true,
            packageMsg: "The ID must have at least 2 segments separated by dot; each segment must start with a letter; all characters must be alphanumeric or an underscore [ a-z A-Z 0-9 _ ]",

          })
        }
        else {
          setError({
            ...error,
            store_id: false,
            packageMsg: "",

          })
        }

      }
      else {
        setError({
          ...error,
          store_id: true,
          packageMsg: "This field is required",

        })
      }
    }

    if (e.target.id === "logo_image") {
      if (e.target.files.length !== 0) {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        let filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setLogo({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            logo_image: true,
            imgmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 512 && img.height >= 512) {

              setLogo({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],

              });
              setError({
                ...error,
                logo_image: false,
                imgmsg: ""
              });
              UploadLogoImage(e.target.files[0]);
            }
            else if (img.width <= 512) {
              setLogo({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_image: true,
                imgmsg: "Minimum width must be 512px"
              });
            }
            else if (img.height <= 512) {
              setLogo({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_image: true,
                imgmsg: "Minimum height must be 512px"
              });
            }
          };
        }
      }
      else {
        setLogo({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          logo_image: false,
          imgmsg: ""
        });

      }
    }

    if (e.target.id === "icon_image") {

      if (e.target.files.length !== 0) {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        let filePath = e.target.files[0].name;


        if (!allowedExtensions.exec(filePath)) {
          setIcon({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            icon_image: true,
            iconmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 512 && img.height >= 512) {

              setIcon({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                icon_image: false,
                iconmsg: ""
              });
              UploadIconImage(e.target.files[0])
            }
            else if (img.width <= 512) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                iconmsg: "Minimum width must be 512px"
              });
            }
            else if (img.height <= 512) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                iconmsg: "Minimum height must be 512px"
              });
            }
          };
        }
      }
      else {
        setIcon({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          icon_image: false,
          iconmsg: ""
        });

      }
    }

    if (e.target.id === "splash_image") {
      if (e.target.files.length !== 0) {
        var img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        var filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setSplash({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            splash_image: true,
            splashmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 1080 && img.height >= 1920) {

              setSplash({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                splash_image: false,
                splashmsg: ""
              });
              UploadSplashImage(e.target.files[0]);
            }
            else if (img.width <= 1080) {
              setSplash({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                splash_image: true,
                splashmsg: "Minimum width must be 1080px"
              });
            }
            else if (img.height <= 1920) {
              setSplash({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                splash_image: true,
                splashmsg: "Minimum height must be 1920px"
              });
            }
          };
        }
      }
      else {
        setSplash({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          splash_image: false,
          splashmsg: ""
        });

      }
    }

    setEdits(newdata);
  };

  //create clone config
  const createCloneConfig = () => {
    try {
      trackPromise(
        service.cloneconfig(configData).then((res) => {
          if (res.status === 200) {
            setsuccessOpen(true);
          }
        })
      );
    } catch (err) {

    }
  };

  const ValidateData = (edits) => {
    let count = 0;
    const nwerror = { ...error };
    if (edits.name === "") {
      nwerror.name = true;
      nwerror.nameMsg = "This field is required";
      count++;
    }
    else {
      if (appReg.test(edits.name) === false) {
        nwerror.name = true;
        nwerror.nameMsg = "Please enter a name without comma";
        count++;
      }
      else {
        nwerror.name = false;
        nwerror.nameMsg = "";
      }
    }

    if (error.icon_image === true) {
      nwerror.icon_image = true;
      nwerror.iconmsg = error.imgmsg
      count++;
    }
    else {
      nwerror.icon_image = false;
      nwerror.iconmsg = ""
    }

    if (error.splash_image === true) {
      nwerror.splash_image = true;
      nwerror.splashmsg = error.splashmsg
      count++;
    }
    else {
      nwerror.splash_image = false;
      nwerror.splashmsg = ""
    }

    if (error.logo_image === true) {
      nwerror.logo_image = true;
      nwerror.imgmsg = error.logomsg
      count++;
    }
    else {
      nwerror.logo_image = false;
      nwerror.imgmsg = ""
    }


    if (edits.store_id === "") {
      nwerror.store_id = true;
      nwerror.packageMsg = "This field is required";
      count++;
    }
    else {
      if (appIdReg.test(edits.store_id) === false) {
        nwerror.store_id = true;
        nwerror.packageMsg = "Must have two or more segments separated by a fullstops, all segments starting with a character e.g: 'ABC3.com'";
        count++;
      }
      else {
        nwerror.store_id = false;
        nwerror.packageMsg = "";
      }
    }
    setError(nwerror);
    return count;
  }
  const EditApplication = () => {
    const nwcount = ValidateData(edits);

    if (nwcount === 0) {

      if (edits.logo_image === "") {
        //edits.logo_image = setLogoImage.logo_image
      }
      if (edits.splash_image === "") {
        //edits.splash_image = setSplashImage.splash_image
      }
      if (edits.icon_image === "") {
        //edits.icon_image = setIconImage.icon_image
      }

      if (statusLogo === 100) {
        //edits.logo_image = setLogoImage.logo_image
      }
      if (statusIcon === 100) {
       // edits.icon_image = setIconImage.icon_image
      }
      if (statusSplash === 100) {
        //edits.splash_image = setSplashImage.splash_image
      }
      try {


        trackPromise(
          service.connectEdit(edits).then((res) => {
            if (res.status === 200) {
              setEditAppOpen(false);
              getApplicationDetails();
            }
          })
        );
      } catch (err) {

      }

    }
  }
  const lifecycleChange = (e) => {
    e.preventDefault();
    const newdata = { ...configData };
    newdata[e.target.id] = e.target.value;
    newdata["deploymentType"] = props.tabClick;
    setConfigData(newdata);
  };
  const appConfigOpenClick = (scrollType) => {
    setAppConfig(true);
    setConfigCreateopen(false);
    setOpen(false);
    setScroll(scrollType);
  };
  const configOpenClick = () => {
    setConfigCreateopen(true);
    setOpen(false);
  }
  const [config] = useState({
    configId: ""
  })
  const [editLook, setEditLook] = useState();
  const [editAuth, setEditAuth] = useState();
  const [lookDatas, setLookDatas] = useState({
    status_bar_color: "",
    header_color: "",
    accent_color: "",
    deploymentType: "",
    use_dark_action_bar_icons: "",
    names: "",
    descriptions: "",
    configId: "",
    sbColor: "",
    hColor: "",
    accentColor: "",
    status_manual: "",
    header_manual: "",
    accent_manual: "",
    page_title:""
  });

  const [lookError, setLookError] = useState({
    status_manual: false,
    header_manual: false,
    accent_manual: false,
    page_title:false,
    pageMsg:"",
    statusMsg: "",
    headerMsg: "",
    accentMsg: ""
  });
  const [AuthData, setAuthData] = useState();
  const [AuthDataEdit, setAuthDataEdit] = useState({
    user_authentications: "",
    authentication_endpoint: "",
    expected_valid_response: "",
    application_id: "",
    configID: "",
    product_id: ""
  })
  const [AuthError, setAuthError] = useState({
    authentication_endpoint: false,
    authMsg: ""
  })
  const getAuth = (id) => {
    config["configId"] = id;

    try {
      trackPromise(
        service.connectAuth(config).then((res) => {

          setAuthData(res.data);
          setAuthDataEdit({
            user_authentications: res.data.authentication === null ? "no" : "yes",
            authentication_endpoint: res.data.authentication?.endpoint ? res.data.authentication?.endpoint : "",
            expected_valid_response: res.data.authentication?.validResponse ? res.data.authentication?.validResponse : "",
            application_id: res.data?.applicationId ? res.data?.applicationId : "",
            configID: id,
            product_id: res.data?.productId ? res.data?.productId : "",
          })
        })
      );
    } catch (err) {

    }
  }


  const getConfig = (id) => {
    config["configId"] = id;

    try {
      trackPromise(
        service.connectConfig(config).then((res) => {

         
          setEditConfigData({
            config_name: res.data ? res.data?.name : "",
            description: res.data ? res.data?.description : "",
            platform: res.data ? res.data?.platformId : "",
            distribution_email: res.data ? res.data?.distributionEmail : "",
            configId: res.data ? res.data?.id : "",
            deploymentType: res.data ? res.data?.deploymentType : "",
            currentPlatform: res.data ? res.data?.platformId : "",
            productId: res.data ? res.data?.productId : ""
          })

          setLookDatas({
            status_bar_color: res.data ? res.data?.statusbarcolor : "",
            header_color: res.data ? res.data?.actionBarColor : "",
            accent_color: res.data ? res.data?.accentColor : "",
            status_manual: res.data ? res.data?.statusbarcolor : "",
            header_manual: res.data ? res.data?.actionBarColor : "",
            accent_manual: res.data ? res.data?.accentColor : "",
            deploymentType: res.data ? res.data?.deploymentType : "",
            use_dark_action_bar_icons: res.data ? res.data?.useDarkActionBarIcons : "",
            names: res.data ? res.data?.name : "",
            descriptions: res.data ? res.data?.description : "",
            configId: res.data ? res.data?.id : "",
            page_title: res.data ? res.data?.pageTitle : "",
          })

        })
      );
    } catch (err) {

    }
  }
  const [SupApps, setSupApps] = useState([
    
  ]);
  const [AppsError, setAppsError] = useState([{
    playstore_app_identifierss: false,
    application_label_vpnss: false,
    labelmsg: "",
    identifiermsg: "",
  }])
  const [SupportedApps, setSupportedApps] = useState();
  const getApps = (id) => {
    config["configId"] = id;

    try {
      trackPromise(
        service.connectSupportedApps(config).then((res) => {

          setSupportedApps(res.data);
          res.data.map((v, index) => {
            if (index === 0) {
              setSupApps(
                [{
                  application_label_vpnss: v.label,
                  playstore_app_identifierss: v.id,
                  current_apps_row_count_editss: "",
                  domain_map_switch_value_mainss_editss: "",
                  disabled_countss: "",
                  configIds: id
                },]);


              setAppsError(
                [{
                  playstore_app_identifierss: false,
                  application_label_vpnss: false,
                  labelmsg: "",
                  identifiermsg: "",
                },]);

            }
            else {
              setSupApps((prevState) => (
                [...prevState, {
                  application_label_vpnss: v.label,
                  playstore_app_identifierss: v.id,
                  current_apps_row_count_editss: "",
                  domain_map_switch_value_mainss_editss: "",
                  disabled_countss: "",
                  configIds: id
                },]))


              setAppsError((prevState) => (
                [...prevState, {
                  playstore_app_identifierss: false,
                  application_label_vpnss: false,
                  labelmsg: "",
                  identifiermsg: "",
                },]));

            }

          });

        })
      );
    } catch (err) {

    }
  }
  const distemail = /([a-zA-Z0-9_\-.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))/

  const handleConfig = (e) => {
    e.preventDefault();
    const newdata = { ...editConfigData }
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "distribution_email") {
      if (e.target.value) {
        if (distemail.test(e.target.value) === false) {
          setErrorConfig({
            ...errorConfig,
            distribution_email: true,
            emailmsg: "You must enter a valid email, or comma separated emails"
          })
        } else {
          setErrorConfig({
            ...errorConfig,
            distribution_email: false,
            emailmsg: ""
          })
        }
      }
      else {
        setErrorConfig({
          ...errorConfig,
          distribution_email: true,
          emailmsg: "This field is required."
        })
      }
    }


    if (e.target.id === "description") {
      if (e.target.value) {

        setErrorConfig({
          ...errorConfig,
          description: false,
          descmsg: ""
        })

      }
      else {
        setErrorConfig({
          ...errorConfig,
          description: true,
          descmsg: "This field is required."
        })
      }
    }

    setEditConfigData(newdata)
  }
  const ValidateConfig = (editConfigData) => {
    let count = 0;
    const nwerror = { ...errorConfig };

    if (editConfigData.description === "") {
      nwerror.description = true;
      nwerror.descmsg = "This field is required";
      count++;
    }
    else {
      nwerror.description = false;
      nwerror.descmsg = "";
    }

    if (editConfigData.distribution_email === "") {
      nwerror.distribution_email = true;
      nwerror.emailmsg = "This field is required";
      count++;
    }
    else {
      if (distemail.test(editConfigData.distribution_email) === false) {
        nwerror.distribution_email = true;
        nwerror.emailmsg = "You must enter a valid email, or comma separated emails";
        count++;
      }
      else {
        nwerror.distribution_email = false;
        nwerror.emailmsg = "";
      }

    }
    setErrorConfig(nwerror);
    return count;
  }
  const SaveConfig = () => {

    const nwcount = ValidateConfig(editConfigData)
    if (nwcount === 0) {
      try {
        trackPromise(
          service.ConfigSave(editConfigData).then((res) => {

            if (res.data.status === "success") {
              setEditConfig(!editConfig);
              localStorage.removeItem("EditOpen")
              getApplicationDetails();
            }

          })
        );
      } catch (err) {

      }
    }

  }
  const stateColoReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const accentColorReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const actionColorReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const pageReg = /^[A-Za-z0-9 ]+$/;

  const handleLook = (e) => {
    e.preventDefault();
    const newdata = { ...lookDatas }
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "status_manual") {
      if (e.target.value) {
        if (stateColoReg.test(e.target.value) === false) {
          setLookError({
            ...lookError,
            status_manual: true,
            statusMsg: "Please enter a valid color"
          })
        } else {
          setLookError({
            ...lookError,
            status_manual: false,
            statusMsg: ""
          })
        }
      }
      else {
        setLookError({
          ...lookError,
          status_manual: false,
          statusMsg: ""
        })
      }
    }


    if (e.target.id === "header_manual") {
      if (e.target.value) {
        if (actionColorReg.test(e.target.value) === false) {
          setLookError({
            ...lookError,
            header_manual: true,
            headerMsg: "Please enter a valid color"
          })
        } else {
          setLookError({
            ...lookError,
            header_manual: false,
            headerMsg: ""
          })
        }
      }
      else {
        setLookError({
          ...lookError,
          header_manual: false,
          headerMsg: ""
        })
      }
    }


    if (e.target.id === "accent_manual") {
      if (e.target.value) {
        if (accentColorReg.test(e.target.value) === false) {
          setLookError({
            ...lookError,
            accent_manual: true,
            accentMsg: "Please enter a valid color"
          })
        } else {
          setLookError({
            ...lookError,
            accent_manual: false,
            accentMsg: ""
          })
        }
      }
      else {
        setLookError({
          ...lookError,
          accent_manual: false,
          accentMsg: ""
        })
      }
    }

    if(e.target.id === "page_title"){
      if(e.target.value === ""){
        setLookError({
          ...lookError,
          page_title: false,
          pageMsg: ""
        });
      }
      else{
        if(pageReg.test(e.target.value) === false){
          setLookError({
            ...lookError,
            page_title: true,
            pageMsg: "Only alphanumeric characters is allowed"
          });
        }
        else if(e.target.value.length > 40){
          setLookError({
            ...lookError,
            page_title: true,
            pageMsg: "Please enter no more than 40 characters."
          });
        }
        else{
          setLookError({
            ...lookError,
            page_title: false,
            pageMsg: ""
          });
        }
      }
    }
    setLookDatas(newdata)

  }

  const ValidateLook = (lookDatas) => {

    let count = 0;
    const nwerror = { ...lookError };

    if (lookDatas.status_manual !== "") {
      if (stateColoReg.test(lookDatas.status_manual) === false) {
        nwerror.status_manual = true;
        nwerror.statusMsg = "Please enter a valid color";
        count++;
      }
      else {
        nwerror.status_manual = false;
        nwerror.statusMsg = "";

        if (colorchecked) {
          lookDatas.status_bar_color = lookDatas.status_manual;
        }
      }
    }
    else {
      nwerror.status_manual = false;
      nwerror.statusMsg = "";

    }

    if (lookDatas.header_manual !== "") {
      if (actionColorReg.test(lookDatas.header_manual) === false) {
        nwerror.header_manual = true;
        nwerror.headerMsg = "Please enter a valid color";
        count++;
      }
      else {
        nwerror.header_manual = false;
        nwerror.headerMsg = "";
        if (actioncolorchecked) {
          lookDatas.header_color = lookDatas.header_manual;
        }

      }
    }
    else {
      nwerror.header_manual = false;
      nwerror.headerMsg = "";

    }

    if (lookDatas.accent_manual !== "") {
      if (accentColorReg.test(lookDatas.accent_manual) === false) {
        nwerror.accent_manual = true;
        nwerror.accentMsg = "Please enter a valid color";
        count++;
      }
      else {
        nwerror.accent_manual = false;
        nwerror.accentMsg = "";
        if (accentcolorchecked) {
          lookDatas.accent_color = lookDatas.accent_manual;
        }

      }
    }
    else {
      nwerror.accent_manual = false;
      nwerror.accentMsg = "";

    }

    if(lookDatas.page_title === ""){
      nwerror.page_title = false;
      nwerror.pageMsg = "";
    }
    else{
       if(pageReg.test(lookDatas.page_title) === false){
          nwerror.page_title = true;
          nwerror.pageMsg = "Only alphanumeric characters is allowed";
          count++;
        }
        else if((lookDatas.page_title).length > 40){
          nwerror.page_title = true;
          nwerror.pageMsg = "Please enter no more than 40 characters.";
          count++;
        }
        else{
          nwerror.page_title = false;
          nwerror.pageMsg = "";

        }
    }
    setLookError(nwerror)
    return count;
  }

  const SaveLook = () => {

    const nwcount = ValidateLook(lookDatas);

    if (nwcount === 0) {

      if (colorchecked) {
        lookDatas.sbColor = "on";
      }
      if (actioncolorchecked) {
        lookDatas.hColor = "on";
      }
      if (accentcolorchecked) {
        lookDatas.accentColor = "on";
      }
      if (lookDatas.use_dark_action_bar_icons === "yes") {
        lookDatas.use_dark_action_bar_icons = "1";
      }

      if (lookDatas.use_dark_action_bar_icons === "no") {
        lookDatas.use_dark_action_bar_icons = "0";
      }

      try {
        trackPromise(
          service.LookSave(lookDatas).then((res) => {

            if (res.data.message === "success") {
              setEditLook(!editLook);
              if (accentcolorchecked) {
                setAccentColorchecked(!accentcolorchecked);
              }
              if (colorchecked) {
                setColorchecked(!colorchecked)
              }
              if (actioncolorchecked) {
                setActionColorchecked(!actioncolorchecked)
              }
              localStorage.removeItem("EditOpen");
              getApplicationDetails();
            }

          })
        );
      } catch (err) {

      }
    }

  }

  const handleLookClose = () => {
    setEditLook(!editLook);

    if (accentcolorchecked) {
      setAccentColorchecked(!accentcolorchecked);
    }
    if (colorchecked) {
      setColorchecked(!colorchecked)
    }
    if (actioncolorchecked) {
      setActionColorchecked(!actioncolorchecked)
    }

    setLookError({
      ...lookError,
      page_title:false,
      pageMsg:""
    })
    getConfig(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "");
  }

  const handleConfigClose = () => {
    setEditConfig(!editConfig);
    getApplicationDetails();
    setErrorConfig({
      config_name: false,
      description: false,
      distribution_email: false,
      currentPlatform: false,
      nameMsg: "",
      descmsg: "",
      emailmsg: ""
    })

  }
  const [editApps, setEditApps] = useState(false);
  const apkValidRegs = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const SaveAuth = () => {

    const countauth = ValidateAuth(AuthDataEdit);

    if (countauth === 0) {
      if (AuthDataEdit.user_authentications === "no") {
        AuthDataEdit.expected_valid_response = "";
        AuthDataEdit.authentication_endpoint = "";
      }
      try {
        trackPromise(
          service.AuthSave(AuthDataEdit).then((res) => {

            if (res.data === "success") {
              setEditAuth(!editAuth);
              localStorage.removeItem("EditOpen");
              getAuth(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")

            }

          })
        );
      } catch (err) {

      }
    }

  }

  const handleAuth = (e) => {
    e.preventDefault();
    const newdata = { ...AuthDataEdit }
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "user_authentications") {

      if (e.target.value === "yes") {
        newdata["expected_valid_response"] = AuthDataEdit.expected_valid_response !== "" ? AuthDataEdit.expected_valid_response : "true";
      }
    }
    if (e.target.id === "authentication_endpoint") {
      if (e.target.value) {
        if (apkValidRegs.test(e.target.value) === false) {
          setAuthError({
            ...AuthError,
            authentication_endpoint: true,
            authMsg: "Please enter a valid URL."
          })
        }
        else {
          setAuthError({
            ...AuthError,
            authentication_endpoint: false,
            authMsg: ""
          })
        }
      }
      else {
        setAuthError({
          ...AuthError,
          authentication_endpoint: true,
          authMsg: "This field is required"
        })
      }
    }
    setAuthDataEdit(newdata);
  }

  const ValidateAuth = (AuthDataEdit) => {
    let authCount = 0;
    const nwerror = { ...AuthError };

    if (AuthDataEdit.user_authentications === "yes") {
      if (AuthDataEdit.authentication_endpoint === "") {
        nwerror.authentication_endpoint = true;
        nwerror.authMsg = "This field is required";
        authCount++;
      }
      else {
        if (apkValidRegs.test(AuthDataEdit.authentication_endpoint) === false) {
          nwerror.authentication_endpoint = true;
          nwerror.authMsg = "Please enter a valid URL.";
          authCount++;
        }
        else {
          nwerror.authentication_endpoint = false;
          nwerror.authMsg = "";
        }
      }
    }

    setAuthError(nwerror);
    return authCount;
  }
  const addRow = (e) => {
    e.preventDefault();

    setSupApps((prevState) => (
      [...prevState, {

        application_label_vpnss: "",
        playstore_app_identifierss: "",
        current_apps_row_count_editss: "",
        domain_map_switch_value_mainss_editss: "",
        disabled_countss: "",
        configIds: applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "",
      }]
    ))

    setAppsError((prevState) => (
      [...prevState, {
        playstore_app_identifierss: false,
        application_label_vpnss: false,
        labelmsg: "",
        identifiermsg: "",
      }]
    ))

  }

  const deleteRow = (record, idx) => {
    const temp = [...SupApps];
    const nwvals = temp.filter(r => r !== record);
    setSupApps(nwvals);

    const temp1 = [...AppsError];
    temp1.splice(idx, 1);
    setAppsError(temp1);
  }

  const checkError = () => {
    for (let i = 0; i <= SupApps.length; i++) {
      setAppsError(AppsError.filter((newError, index) => {
        if (index === i) {
          if (SupApps[i]?.playstore_app_identifierss !== "") {
            if (SupApps[i]?.application_label_vpnss !== "") {
              newError.application_label_vpnss = false;

            }
            else {
              newError.application_label_vpnss = true;
            }

            if (identifyReg.test(SupApps[i]?.playstore_app_identifierss) === false) {
              newError.playstore_app_identifierss = true;
              newError.identifiermsg = "Please enter a valid application ID";
            }
            else {
              newError.playstore_app_identifierss = false;
              newError.identifiermsg = "";
            }

          }
          else {
            if (SupApps[i]?.application_label_vpnss === "") {
              newError.application_label_vpnss = false;
              newError.playstore_app_identifierss = false;
              newError.identifiermsg = "";
            }
            else {
              newError.application_label_vpnss = true;
            }
            newError.playstore_app_identifierss = false;
            newError.identifiermsg = "";

          }



          if (SupApps[i]?.application_label_vpns !== "") {
            if (SupApps[i]?.playstore_app_identifierss !== "") {
              newError.application_label_vpnss = false;
            }

            else {
              newError.application_label_vpnss = true;
            }


          }
          else {
            if (SupApps[i]?.playstore_app_identifierss === "") {
              newError.application_label_vpnss = false;
            }
            else {
              newError.application_label_vpnss = true;
            }

          }
        }
        return newError;
      }))


    }

  }


  const handleApps = (e) => {
    e.preventDefault();
    if (["playstore_app_identifierss", "application_label_vpnss"].includes(e.target.name)) {
      //const valuesNew = [...SupApps];
      SupApps[e.target.dataset.id][e.target.name] = e.target.value
      checkError();
    }

    setSupApps(SupApps);

  }

  const ValidateApps = (SupApps) => {
    let countapps = 0;
    for (let i = 0; i <= SupApps.length; i++) {
      setAppsError(AppsError.filter((newError, index) => {
        if (index === i) {
          if (SupApps[i]?.playstore_app_identifierss !== "") {
            if (SupApps[i]?.application_label_vpnss !== "") {
              newError.application_label_vpnss = false;

            }
            else {
              newError.application_label_vpnss = true;
              countapps++;
            }

            if (identifyReg.test(SupApps[i]?.playstore_app_identifierss) === false) {
              newError.playstore_app_identifierss = true;
              newError.identifiermsg = "Please enter a valid application ID";
              countapps++;
            }
            else {
              newError.playstore_app_identifierss = false;
              newError.identifiermsg = "";
            }

          }
          else {
            if (SupApps[i]?.application_label_vpnss === "") {
              newError.application_label_vpnss = false;
              newError.playstore_app_identifierss = false;
              newError.identifiermsg = "";
            }
            else {
              newError.application_label_vpnss = true;
              countapps++;
            }
            newError.playstore_app_identifierss = false;
            newError.identifiermsg = "";

          }



          if (SupApps[i]?.application_label_vpns !== "") {
            if (SupApps[i]?.playstore_app_identifierss !== "") {
              newError.application_label_vpnss = false;
            }

            else {
              newError.application_label_vpnss = true;
              countapps++;
            }


          }
          else {
            if (SupApps[i]?.playstore_app_identifierss === "") {
              newError.application_label_vpnss = false;
            }
            else {
              newError.application_label_vpnss = true;
              countapps++;
            }

          }
        }
        return newError;
      }))


    }
    return countapps;
  }

  const SaveApps = () => {

    const newAppCount = ValidateApps(SupApps);


    if (newAppCount === 0) {
      if (SupApps.length === 0) {
        const Appsval = [{
          application_label_vpnss: "",
          configIds: applicationData?.generateconfigObj?.PROD[0].id,
          current_apps_row_count_editss: "0",
          disabled_countss: "",
          domain_map_switch_value_mainss_editss: "",
          playstore_app_identifierss: "",
        }];
        try {
          trackPromise(
            service.SupportedAppsSave(Appsval).then((res) => {

              if (res.data === "success") {
                setEditApps(!editApps);
                localStorage.removeItem("EditOpen")
                getApps(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")

              }

            })
          );
        } catch (err) {

        }
      }
      else {
        try {
          trackPromise(
            service.SupportedAppsSave(SupApps).then((res) => {

              if (res.data === "success") {
                setEditApps(!editApps);
                localStorage.removeItem("EditOpen")
                getApps(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")

              }

            })
          );
        } catch (err) {

        }
      }
    }
  }

  const CancelApps = () => {
    setEditApps(!editApps);
    getApps(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")

  }

  const handleAuthClose = () => {
    setEditAuth(!editAuth);
    setAuthError({
      authentication_endpoint: false,
      authMsg: ""
    })
    getAuth(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")

  }

  const [openPopup, setOpenPopup] = useState(false);

  document.addEventListener("click", e => {

    let editNot = localStorage.getItem("EditOpen");
    if (editNot) {
      if (e.target.id === "Edit" || e.target.id === "Cancel" || e.target.id === "Save" ||
        e.target.id === "playstore_app_identifierss" || e.target.id === "application_label_vpnss" ||
        e.target.type === "file" || e.target.name === "company_name" || e.target.name === "popup" || e.target.id === "status" || e.target.id === "Remove") {

        if (e.target.id === "Cancel" || e.target.id === "Save" || e.target.id === "Return") {
          localStorage.removeItem("EditOpen")

        }
        setOpenPopup(false);
      }
      else {
        setOpenPopup(true);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

      }
    }
    else {
      setOpenPopup(false);
    }

  }, true);


  const editConfigClick = () => {
    setEditConfig(!editConfig);
    localStorage.setItem("EditOpen", true);
  }

  const editLookTab = () => {
    setEditLook(!editLook);
    localStorage.setItem("EditOpen", true);
  }

  const editSupportApps = () => {
    setEditApps(!editApps);
    localStorage.setItem("EditOpen", true);
  }

  const editAuthTab = () => {
    setEditAuth(!editAuth);
    localStorage.setItem("EditOpen", true);
  }
  const [decommision, setDecommision] = useState(false);
  const DecommisionConnect = (scrollType) => {

    setDecommision(true);
    setScroll(scrollType);
  }

  const handleCloseDecommision = () => {

    setDecommision(false);
  }
  const [decommisionDel, setDecommisionDel] = useState(false);
  const DecommisionDeletePopup = (scrollType) => {
    setDecommision(false);
    setDecommisionDel(true);
    setScroll(scrollType);
  }

  const handleCloseDelete = () => {
    setDecommisionDel(false);
  }

  const deleteConnectApp = () => {
    service.decommisionConnect({ applicationId: appId }).then((res) => {
      handleCloseDelete();
      if (res.data.status !== 200) {
        setOpenApprove(true);
        setPubError(JSON.stringify(res.data));
      }
      else {
        setOpenApprove(true);
        setPubError(JSON.stringify(res.data));
      }
      getApplicationDetails();
    }).catch((err) => {

    })
  }

  const [notifyData, setNotifyData] = useState({
    maxVersion: "",
    type: "message",
    frequency: "once",
    text: "",
    title: "",
    dispalyUntil: "",
    action: "app",
    appId: "",
    actionUrl: "",
    applicationId: "",
  });
  const [notifyError, setNotifyError] = useState({
    text: false,
    title: false,
    dispalyUntil: false,
    action: false,
    actionUrl: false,
    maxVersion: false,
    versionMsg: "",
    urlMsg: ""
  });
  const IntReg = /^\d+$/;
  const webReg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  const handleNotification = (e) => {

    e.preventDefault();
    const newdata = { ...notifyData };

    newdata[e.target.id] = e.target.value;

    if (e.target.id === "maxVersion") {
      if (e.target.value) {

        if (IntReg.test(e.target.value) === false) {
          setNotifyError({
            ...notifyError,
            maxVersion: true,
            versionMsg: "Please enter only digits."
          })
        }
        else {
          setNotifyError({
            ...notifyError,
            maxVersion: false,
            versionMsg: ""
          })
        }
      }
      else {
        setNotifyError({
          ...notifyError,
          maxVersion: true,
          versionMsg: "This field is required"
        })
      }
    }

    if (e.target.id === "title") {
      if (e.target.value) {

        setNotifyError({
          ...notifyError,
          title: false,
        })
      }
      else {
        setNotifyError({
          ...notifyError,
          title: true,
        })
      }
    }

    if (e.target.id === "text") {
      if (e.target.value) {

        setNotifyError({
          ...notifyError,
          text: false,
        })
      }
      else {
        setNotifyError({
          ...notifyError,
          text: true,
        })
      }
    }

    if (e.target.id === "dispalyUntil") {
      if (e.target.value) {

        setNotifyError({
          ...notifyError,
          dispalyUntil: false,
        })
      }
      else {
        setNotifyError({
          ...notifyError,
          dispalyUntil: true,
        })
      }
    }

    if (e.target.id === "actionUrl") {
      if (e.target.value) {

        if (webReg.test(e.target.value) === false) {
          setNotifyError({
            ...notifyError,
            actionUrl: true,
            urlMsg: "Please enter a valid URL."
          })
        }
        else {
          setNotifyError({
            ...notifyError,
            actionUrl: false,
            urlMsg: ""
          })
        }
      }
      else {
        setNotifyError({
          ...notifyError,
          actionUrl: true,
          urlMsg: "This field is required"
        })
      }
    }
    setNotifyData(newdata);

  }
  const [pubConfirm, setPubConfirm] = useState(false);
  const publishMsg = () => {

    const pubCount = publishMsgValidate();

    if (pubCount === 0) {
      notifyData.applicationId = appId;
      notifyData.appId = applicationData?.generateconfigObj?.PROD[0].appId;

      setPubConfirm(true);

    }


  }

  const [clickBut, setClickBut] = useState(false);

  const publishMsgConfirm = () => {
    setClickBut(true);
    service.notifyMsg(notifyData).then((res) => {
      pubClose();
      if (res.data.status !== 200) {
        setOpenApprove(true);
        setPubError(JSON.stringify(res.data));
      }
      else {
        setOpenApprove(true);
        setPubError(JSON.stringify(res.data));
      }

    }).catch((err) => {

    })
  }

  const pubClose = () => {
    setPubConfirm(false);
    setClickBut(false);
    setValuess(null);
    setNotifyData({
      maxVersion: "",
      type: "message",
      frequency: "once",
      text: "",
      title: "",
      dispalyUntil: "",
      action: "app",
      appId: "",
      actionUrl: "",
    })
  }

  const publishMsgValidate = () => {
    let count = 0;
    const nwerror = { notifyError };

    if (notifyData.maxVersion === "") {
      nwerror.maxVersion = true;
      nwerror.versionMsg = "This field is required"
      count++;
    }
    else {
      if (IntReg.test(notifyData.maxVersion) === false) {
        nwerror.maxVersion = true;
        nwerror.versionMsg = "Please enter only digits."
        count++;
      }
      else {
        nwerror.maxVersion = false;
        nwerror.versionMsg = ""
      }
    }

    if (notifyData.title === "") {
      nwerror.title = true;
      count++;
    }
    else {
      nwerror.title = false;
    }

    if (notifyData.text === "") {
      nwerror.text = true;
      count++;
    }
    else {
      nwerror.text = false;
    }

    if (notifyData.dispalyUntil === "") {
      nwerror.dispalyUntil = true;
      count++;
    }
    else {
      nwerror.dispalyUntil = false;
    }

    if (notifyData.actionUrl === "") {
      if (notifyData.action === "update") {
        nwerror.actionUrl = true;
        nwerror.urlMsg = "This field is required"
        count++;
      }
      else {
        nwerror.actionUrl = false;
        nwerror.urlMsg = ""
      }
    }
    else {
      if (notifyData.action === "update") {
        if (webReg.test(notifyData.actionUrl) === false) {
          nwerror.actionUrl = true;
          nwerror.urlMsg = "Please enter a valid URL."
          count++;
        }
        else {
          nwerror.actionUrl = false;
          nwerror.urlMsg = ""
        }
      }
      else {
        nwerror.actionUrl = false;
        nwerror.urlMsg = ""
      }
    }
    setNotifyError(nwerror);
    return count;
  }


  const convertTime = (time) => {
    let times1 = time.getTime() / 1000;
    return times1;
  }

  const [valuess, setValuess] = useState(null);

  const handleDateTime = (newValue) => {
    setValuess(newValue ? newValue : null);
    let timesdatenw = convertTime(newValue);
    const newIPdata = { ...notifyData };
    newIPdata['dispalyUntil'] = timesdatenw !== null ? timesdatenw : "";
    setNotifyData(newIPdata);

    if (timesdatenw !== null || timesdatenw !== "") {
      setNotifyError({
        ...notifyError,
        dispalyUntil: false
      })
    }
    else {
      setNotifyError({
        ...notifyError,
        dispalyUntil: true
      })
    }
  };

  // const [exDisable, setExDisable] = useState(true);
  const handleAction = (val, e) => {
    // if (val === "update") {
    //   setExDisable(false);
    // }
    // if (val === "app") {
    //   setExDisable(true);
    // }
    setNotifyData({
      ...notifyData,
      action: val
    })
  };

  const handleRadio = (val, name, e) => {
    let newdatas = { ...notifyData }
    newdatas[name] = val;
    setNotifyData(newdatas)
  };

  const [downloadApks, setDownloadApks] = useState();
  const [downloadApksKey, setDownloadApksKey] = useState();
  const [apkList, setApkList] = useState(false);
  const showApk = (buildid) => {
    service.apkUrl({ build_id: buildid }).then((res) => {
      setDownloadApks(res?.data?.apkUrlJson);
      setDownloadApksKey(res?.data?.key)
      setApkList(true);
    }).catch((err) => {

    })
  }

  const showApkDebug = (index, buildid) => {
    let x = document.getElementById("debugDownload" + buildid);
    if (x.style.display === "none") {
      x.style.display = "block"
    }
    else {
      x.style.display = "none"
    }
  }
  useEffect(() => {
    localStorage.removeItem("EditOpen")
    setlodervisible(true);
    getApplicationDetails();
    UploadIconDefault();
    UploadSplashDefault();
    UploadLogoDefault();
  }, []);
  return (
    <div>
      <div className="applicationStartView">
        <div className="applicationHeadTitle">#datafree CONNECT Application Details - Admin View</div>
        <Grid className="applicationhead">
          <Grid item xs={2}>
            <div className="applicationheadLogo">
              <img
                src={
                  applicationData ?
                    applicationData.appliactionObj?.iconUrl !== ""
                      ? API_URL + applicationData.iconImage + applicationData.appliactionObj?.iconUrl
                      : defaultIcon
                    : alpha
                }
                alt=""
                className="imgapplicationLogo"
              ></img>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className="applicationsubContent">
              <div className="applicationurl">
                {applicationData ? applicationData.appliactionObj?.name : ""}
              </div>
              <div className="updateddate">
                Last updated: {applicationData ? applicationData.lastCreated : ""}
              </div>
              <div className="productType">
                <div className="productTypeContent">Product Type: </div>
                <div>
                  <span type="text" className="producttypeField" disabled>
                    #datafree CONNECT
                  </span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="applicationsubContent">
              <div>
                <button
                  className={applicationData.appliactionObj?.status === "Deleted" ? "subbutton4" : "subbutton1"}
                  disabled={applicationData.appliactionObj?.status === "Deleted" ? "disabled" : false}
                  onClick={handleAppOpen}>
                  Edit Application Details
                </button>
                <div>
                  <button className="subbutton2">Production</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {applicationData.ProductType !== "#datafree DIRECT" ? (
          <div className="applicationTab">

            <AntTabs
              value={value}
              onChange={handleChange}
              className="tabChange tabViewMenu"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <AntTab
                label="Production"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              />
              {
                lodervisible &&
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: '0px',
                  right: '0px',
                  margin: 'auto',
                  position: 'absolute',
                  left: '0px',
                  bottom: '0px',
                  zIndex: '3000',

                  paddingTop: '8px',
                  paddingBottom: '8px',
                  borderRadius: '5px',
                  flexDirection: 'column',
                  width: '70px',

                }} >
                  <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
                </div>
              }
            </AntTabs>
            <TabPanel value={value} index={0}>

              {applicationData.appliactionObj
                ? (
                  <div className="developmentTab">
                    <div className="developTabContent">
                      <div className="developTabContenthead">
                        biNu ID : <span>{applicationData?.generateconfigObj?.PROD[0].appId ? applicationData?.generateconfigObj?.PROD[0].appId : ""}</span>
                      </div>
                      <div className="developTabContenthead">
                        Config ID : <span>{applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : ""}</span>
                      </div>
                      <div className="developTabContenthead">
                        Name : <span>{applicationData?.appliactionObj.name}</span>
                      </div>
                      <div className="developTabContenthead">
                        Last Build : <span>{applicationData?.lastCreated}</span>
                      </div>

                      <div className="developToolsTabContenthead">Tools:</div>
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <button
                          className={applicationData.appliactionObj?.status === "Deleted" ? "workbenchsDisable" : "workbenchs"}
                          disabled={applicationData.appliactionObj?.status === "Deleted" ? "disabled" : false}

                          onClick={openModalBulidApk}>Build APK</button>
                      </div>
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <button
                          className={applicationData.appliactionObj?.status === "Deleted" ? "workbenchsDisable" : "workbenchs"}
                          disabled={applicationData.appliactionObj?.status === "Deleted" ? "disabled" : false}


                          onClick={openBuildOpen}>
                          Build History and Download
                        </button>
                      </div>
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <button
                          className={applicationData.appliactionObj?.status === "Deleted" ? "workbenchsDisable" : "workbenchs"}
                          disabled={applicationData.appliactionObj?.status === "Deleted" ? "disabled" : false}
                          onClick={openPublishOpen}>
                          Publish Configuration
                        </button>
                      </div>
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <button
                          className={applicationData.appliactionObj?.status === "Deleted" ? "devdtbtn2Disable" : "devdtbtn2"}
                          disabled={applicationData.appliactionObj?.status === "Deleted" ? "disabled" : false}
                          style={{ width: '95%', height: '54px', padding: '10px', marginTop: '10px', fontSize: '14px' }}
                          onClick={() => DecommisionConnect('body')}
                        >
                          Decommission App Config and Delete App
                        </button>
                      </div>
                    </div>
                    <div className="developMaxTabContent">
                      <AntTabs
                        value={val}
                        onChange={handleChanges}
                        className="tabChange"
                        TabIndicatorProps={{
                          style: {
                            display: "none",
                          },
                        }}
                      >
                        <AntTab
                          label="APK Config"
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          onClick={() => getConfig(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")}

                        />
                        <AntTab
                          label="Look and Feel"
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          onClick={() => getConfig(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")}
                        />
                        <AntTab
                          label="Supported Apps"
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          onClick={() => getApps(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")}

                        />
                        <AntTab
                          label="Authentication"
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          onClick={() => getAuth(applicationData?.generateconfigObj?.PROD[0].id ? applicationData?.generateconfigObj?.PROD[0].id : "")}
                        />
                        <AntTab
                          label="Notification Centre"
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        />
                      </AntTabs>
                      <TabPanel value={val} index={0}>
                        {applicationData.appliactionObj?.status !== "Deleted" ?
                          <div className="developmentSecurityTab">
                            <button
                              className={editConfig ? 'NotEdit' : 'editbtn'}
                              id="Edit"
                              /*className="editbtn" */
                              onClick={editConfigClick}>
                              Edit
                            </button>

                            <button
                              id="Cancel"
                              className={!editConfig ? 'NotEdit' : 'editbtn'}
                              onClick={handleConfigClose}
                            /*className="editbtn" */
                            >
                              Cancel
                            </button>

                            <button
                              id="Save"
                              className={!editConfig ? 'NotEdit' : 'editbtn'}
                              onClick={SaveConfig}
                            /*className="editbtn" */
                            >Save</button>
                          </div>
                          : null}
                        <div className="companyForm">
                          <div className="InputflexRow">
                            <div className="ddflex">
                              <div className="ConfigLabel">Connect Config Server Gateway</div>

                              <div>

                                <input
                                  type="text"
                                  className="inputField"
                                  name="popup"
                                  disabled="disabled"
                                  value={applicationData.appliactionObj.reachGateway}
                                  style={{ cursor: 'not-allowed', marginBottom: '12px' }}
                                />

                              </div>
                            </div>

                            <div className="ddflex">
                              <div className="ConfigLabel">Gateway Description</div>
                              <div>
                                <input
                                  type="text"
                                  className="inputField"
                                  name="popup"
                                  disabled="disabled"
                                  value={applicationData.gatewayDescription[0].description}
                                  style={{ cursor: 'not-allowed', marginBottom: '12px' }}
                                />

                              </div>
                            </div>
                          </div>
                          <div className="InputflexRow">
                            <div className="ddflex">
                              <div className="ConfigLabel">Config Name</div>
                              <div>
                                {!editConfig ? (
                                  <input
                                    type="text"
                                    className="inputField"
                                    disabled="disabled"
                                    name="popup"
                                    value={applicationData.editConfigDetails.name}
                                    style={{ marginBottom: '12px' }}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className={!errorConfig.config_name ? "editinputField" : "editinputFielderr"}
                                    name="popup"
                                    id="config_name"
                                    defaultValue={applicationData.editConfigDetails.name}
                                    style={{ marginBottom: '12px' }}
                                    onChange={handleConfig}
                                  />
                                )}
                                {errorConfig.config_name ? (
                                  <p style={{ float: 'left', marginTop: '-10px' }} className="errorClass">{errorConfig.Namemsg}</p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="ddflex">
                              <div className="ConfigLabel">APK Config Description</div>
                              <div>
                                {!editConfig ? (
                                  <input
                                    type="text"
                                    className="inputField"
                                    name="popup"
                                    disabled="disabled"
                                    style={{ marginBottom: '12px' }}
                                    value={applicationData.editConfigDetails.description}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className={!errorConfig.description ? "editinputField" : "editinputFielderr"}
                                    name="popup"
                                    id="description"
                                    style={{ marginBottom: '12px' }}
                                    defaultValue={applicationData.editConfigDetails.description}
                                    onChange={handleConfig}
                                  />
                                )}
                                {errorConfig.description ? (
                                  <p style={{ float: 'left', marginTop: '-10px' }} className="errorClass">{errorConfig.descmsg}</p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="InputflexRow">
                            <div className="ddflex">
                              <div className="ConfigLabel">Datafree APK Library Version</div>
                              <div>
                                {!editConfig ?
                                  <select disabled="disabled"
                                    className="inputField"
                                    name="popup"
                                    id="platform"
                                    style={
                                      {
                                        width: '110%',
                                        height: '36px',
                                        marginBottom: '12px'
                                      }
                                    }
                                  >
                                    {applicationData && applicationData?.binuPlatformOptions.length > 0
                                      ? applicationData?.binuPlatformOptions.map((post) => (
                                        <option key={post.id} value={post.id} >
                                          {post.name}
                                        </option>
                                      ))
                                      : null}
                                  </select> :

                                  <select
                                    className="editinputField"
                                    name="popup"
                                    id="currentPlatform"
                                    style={
                                      {
                                        width: '110%',
                                        height: '36px',
                                        marginBottom: '12px'
                                      }
                                    }
                                    onChange={handleConfig}
                                  >
                                    {applicationData && applicationData?.binuPlatformOptions.length > 0
                                      ? applicationData?.binuPlatformOptions.map((post) => (
                                        <option key={post.id} value={post.id} >
                                          {post.name}
                                        </option>
                                      ))
                                      : null}
                                  </select>
                                }

                              </div>
                            </div>
                            <div className="ddflex">
                              <div className="ConfigLabel ">Store App Id</div>
                              <div>

                                <input
                                  type="text"
                                  className="inputField"
                                  name="popup"
                                  disabled="disabled"
                                  value={applicationData.appliactionObj.googleAppId}
                                  style={{ cursor: 'not-allowed' }}
                                />

                              </div>
                            </div>


                            <div></div>
                          </div>


                          <div className="InputflexRow">
                            <div className="ddflex">

                            </div>
                            <div className="ddflex">
                              <div className="packageLabel ">Distribution Email</div>
                              <div>
                                {!editConfig ? (
                                  <textarea
                                    type="text"
                                    className="inputField"
                                    name="popup"
                                    disabled="disabled"
                                    value={applicationData.editConfigDetails.distributionEmail}
                                    style={{ marginBottom: '12px', height: '50px' }}
                                  />
                                ) : (
                                  <textarea
                                    type="text"
                                    className={!errorConfig.distribution_email ? "editinputField" : "editinputFielderr"}
                                    name="popup"
                                    id="distribution_email"
                                    style={{ marginBottom: '12px', height: '50px' }}
                                    defaultValue={applicationData.editConfigDetails.distributionEmail}
                                    onChange={handleConfig}
                                  />

                                )}

                                {errorConfig.distribution_email ? (
                                  <p style={{ float: 'left', marginTop: '-10px' }} className="errorClass">{errorConfig.emailmsg}</p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>


                            <div></div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={val} index={1}>
                        {applicationData.appliactionObj?.status !== "Deleted" ?
                          <div className="developmentSecurityTab">
                            <button
                              className={editLook ? 'NotEdit' : 'editbtn'}
                              id="Edit"
                              onClick={editLookTab}
                            >
                              Edit
                            </button>

                            <button
                              className={!editLook ? 'NotEdit' : 'editbtn'}
                              onClick={handleLookClose}
                              id="Cancel"
                            >
                              Cancel
                            </button>

                            <button
                              className={!editLook ? 'NotEdit' : 'editbtn'}
                              id="Save"
                              onClick={SaveLook}
                            >Save</button>
                          </div> : null}
                        <div className="InputflexRow">
                        <div className="ddflex">
                          <label className="Connectinputtext">Page Title</label>
                          {!editLook ? 
                                <input
                                  type="text"
                                  id="page_title"
                                  className="inputField"
                                  name="popup"
                                  disabled={!editLook ? "disabled" : false}
                                  style={{ cursor: "pointer", width:'50%', marginLeft:'20px'}}
                                  value={lookDatas.page_title}
                                />
                          :

                               <input
                                  type="text"
                                  id="page_title"
                                  className={!lookError.page_title ? "editinputField" : "editinputFielderr"}
                                  name="popup"
                                  style={{ cursor: "pointer", width:'50%', marginLeft:'20px'}}
                                  onChange={handleLook}
                                  value={lookDatas.page_title}
                                />

                          }
                          {lookError.page_title ? (
                                  <span style={{ float: 'left',marginLeft:'83px' }} className="errormsg">{lookError.pageMsg}</span>
                                ) : (
                                  ""
                          )}
                        </div>
                        </div>
                        <div className="InputflexRow">
                          <div className="ddflex">
                            <label for="head" className="Connectinputtext">
                              Status Bar Colour
                            </label>
                            {!colorchecked ? (
                              <div style={{ display: "inline-block" }} className="colorPickerDiv">
                                <input
                                  type="color"
                                  className="colorBox"
                                  id="status_bar_color"
                                  name="popup"
                                  disabled={!editLook ? "disabled" : false}
                                  style={{ cursor: "pointer" }}
                                  value={lookDatas.status_bar_color}
                                  onChange={handleLook}
                                />
                                <input
                                  type="text"
                                  className="colorInput"
                                  name="popup"
                                  disabled="disabled"
                                  value={lookDatas.status_bar_color}
                                />
                              </div>
                            ) : (
                              <div>
                                <input
                                  type="text"
                                  className={!lookError.status_manual ? "registerInput" : "registerInputerr"}
                                  name="popup"
                                  id="status_manual"
                                  disabled={!editLook ? "disabled" : false}
                                  value={lookDatas?.status_manual}
                                  style={{ width: '92%' }}
                                  onChange={handleLook}
                                />
                                {lookError.status_manual ? (
                                  <span style={{ float: 'left' }} className="errormsg">{lookError.statusMsg}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                          <div className="ddflex">
                            <label for="head" className="Connectinputtext">
                              Action Bar Colour{" "}
                            </label>
                            {!actioncolorchecked ? (
                              <div style={{ display: "inline-block" }} className="colorPickerDiv">
                                <input
                                  type="color"
                                  className="colorBox"
                                  id="header_color"
                                  name="popup"
                                  disabled={!editLook ? "disabled" : false}
                                  value={lookDatas?.header_color}
                                  style={{ cursor: "pointer" }}
                                  onChange={handleLook}
                                />
                                <input
                                  type="text"
                                  className="colorInput"
                                  disabled="disabled"
                                  name="popup"
                                  value={lookDatas?.header_color}
                                />
                              </div>
                            ) : (
                              <div>
                                <input
                                  type="text"


                                  className={!lookError.header_manual ? "registerInput" : "registerInputerr"}
                                  name="popup"
                                  id="header_manual"
                                  disabled={!editLook ? "disabled" : false}
                                  value={lookDatas?.header_manual}
                                  style={{ width: '92%' }}
                                  onChange={handleLook}
                                />
                                {lookError.header_manual ? (
                                  <span style={{ float: 'left' }} className="errormsg">{lookError.headerMsg}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="InputflexRow">
                          <div className="ddflex">
                            <div>

                              <label for="websiteName" className="Connectinputtext">
                                Click here to enter Manually
                              </label>
                              <input
                                type="checkbox"
                                name="popup"
                                id="websiteName"
                                disabled={!editLook ? "disabled" : false}
                                onChange={() => setColorchecked(!colorchecked)}
                                checked={colorchecked}
                              />
                            </div>
                          </div>
                          <div className="ddflex">
                            <div>

                              <label for="websiteName" className="Connectinputtext">
                                Click here to enter Manually
                              </label>
                              <input
                                type="checkbox"
                                name="popup"
                                id="websiteName"
                                disabled={!editLook ? "disabled" : false}
                                onChange={() => setActionColorchecked(!actioncolorchecked)}
                                checked={actioncolorchecked}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="InputflexRow">
                          <div className="ddflex">
                            <label for="head" className="Connectinputtext">
                              Accent Colour{" "}
                            </label>
                            {!accentcolorchecked ?

                              <div style={{ display: "inline-block" }} className="colorPickerDiv">
                                <input
                                  type="color"
                                  id="accent_color"
                                  name="popup"
                                  disabled={!editLook ? "disabled" : false}
                                  value={lookDatas?.accent_color}
                                  className="colorBox"
                                  style={{ cursor: "pointer" }}
                                  onChange={handleLook}

                                />
                                <input
                                  type="text"
                                  name="popup"
                                  className="colorInput"
                                  disabled="disabled"
                                  value={lookDatas.accent_color}
                                />
                              </div>
                              :

                              <div>
                                <input
                                  type="text"
                                  className={!lookError.accent_manual ? "registerInput" : "registerInputerr"}
                                  name="popup"
                                  id="accent_manual"
                                  disabled={!editLook ? "disabled" : false}
                                  value={lookDatas?.accent_manual}
                                  style={{ width: '92%' }}
                                  onChange={handleLook}
                                />
                                {lookError.accent_manual ? (
                                  <span style={{ float: 'left' }} className="errormsg">{lookError.accentMsg}</span>
                                ) : (
                                  ""
                                )}
                              </div>


                            }
                          </div>
                          <div className="ddflex">

                          </div>

                        </div>

                        <div className="InputflexRow">
                          <div className="ddflex">
                            <div>

                              <label for="websiteName" className="Connectinputtext">
                                Click here to enter Manually
                              </label>
                              <input
                                type="checkbox"
                                name="popup"
                                id="websiteName"
                                disabled={!editLook ? "disabled" : false}
                                onChange={() => setAccentColorchecked(!accentcolorchecked)}
                                checked={accentcolorchecked}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="InputflexRow">
                          <div className="ddflex">
                            <div className="companyLabel" style={{ fontSize: '14px' }}>
                              Use Dark Action Bar Icons
                            </div>
                            <div>
                              {!editLook ? (
                                <select
                                  type="text"
                                  className="inputField"
                                  name="popup"
                                  id="use_dark_action_bar_icons"
                                  disabled="disabled"
                                >
                                  <option value="no" selected={lookDatas?.use_dark_action_bar_icons === "no" && true}>No</option>
                                  <option value="yes" selected={lookDatas?.use_dark_action_bar_icons === "yes" && true}>Yes</option>

                                </select>
                              ) : (
                                <select
                                  type="text"
                                  className="editinputField"
                                  name="popup"
                                  id="use_dark_action_bar_icons"
                                  onChange={handleLook}

                                >
                                  <option value="no" selected={lookDatas?.use_dark_action_bar_icons === "no" && true}>No</option>
                                  <option value="yes" selected={lookDatas?.use_dark_action_bar_icons === "yes" && true}>Yes</option>

                                </select>
                              )}
                            </div>
                          </div>
                          <div className="ddflex"></div>
                        </div>
                      </TabPanel>
                      <TabPanel value={val} index={2}>
                        {applicationData.appliactionObj?.status !== "Deleted" ?
                          <div className="developmentSecurityTab">
                            <button
                              className={editApps ? 'NotEdit' : 'editbtn'}
                              onClick={editSupportApps}
                              id="Edit"
                            >
                              Edit
                            </button>

                            <button
                              className={!editApps ? 'NotEdit' : 'editbtn'}
                              onClick={CancelApps}
                              id="Cancel"
                            >
                              Cancel
                            </button>

                            <button className={!editApps ? 'NotEdit' : 'editbtn'}
                              onClick={SaveApps}
                              id="Save"
                            >Save</button>
                          </div> : null}
                        <div className="InputflexRows">
                          <div className="InputflexRow">
                            <div className="ddflex">
                              <div className="connectLabel" style={{ marginLeft: '0px' }}>
                                Application Label in VPN Launcher
                              </div>
                            </div>

                            <div className="ddflex">
                              <div className="connectLabel" style={{ marginLeft: '-36px' }}>
                                Google Playstore App Identifier
                              </div>
                            </div>
                          </div>

                          {!editApps ?
                            <div className="datafeeSubBox boxConnect">
                              {SupportedApps ? SupportedApps.map((vals, index) => (
                                <div className="boxRow">
                                  <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                                    <input
                                      type="text"
                                      className="inputField"

                                      disabled="disabled"
                                      value={vals.label}
                                    />
                                  </div>

                                  <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                                    <input
                                      type="text"
                                      className="inputField"
                                      disabled="disabled"
                                      style={{ marginLeft: '60px' }}
                                      value={vals.id}
                                    />
                                  </div>
                                  <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                                    <button className="closeIcon" disabled="disabled"
                                      style={{ marginLeft: '69px' }}
                                      id={index}>
                                      X
                                    </button>
                                  </div>
                                </div>
                              )) : null}
                            </div> :


                            <div className="datafeeSubBox boxConnect">
                              {SupApps ? SupApps.map((v, index) => (
                                <div className="boxRow">
                                  <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                                    <input
                                      type="text"
                                      className={
                                        !AppsError[index]?.application_label_vpnss ? "editinputField" : "editinputFielderr"
                                      }
                                      name="application_label_vpnss"
                                      id="application_label_vpnss"
                                      data-id={index}
                                      value={v.application_label_vpnss}
                                      onChange={handleApps}
                                    />
                                    {AppsError[index]?.application_label_vpnss ? <span style={{ float: 'left' }} className="errormsg" name="identifiererror">Please fill both fields</span> : null}

                                  </div>

                                  <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                                    <input
                                      type="text"
                                      className={
                                        !AppsError[index]?.playstore_app_identifierss ? "editinputField" : "editinputFielderr"
                                      }
                                      name="playstore_app_identifierss"
                                      id="playstore_app_identifierss"
                                      data-id={index}
                                      style={{ marginLeft: '60px' }}
                                      value={v.playstore_app_identifierss}
                                      onChange={handleApps}
                                    />
                                    {AppsError[index]?.playstore_app_identifierss ? <span style={{ float: 'left', width: '151%', marginLeft: '53px' }} className="errormsg" name="identifiererror">{AppsError[index]?.identifiermsg}</span> : null}

                                  </div>
                                  <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                                    <button className="closeIcon" id={index}
                                      name="popup"
                                      style={{ marginLeft: '69px' }}
                                      //disabled={invaluedex == 0 ? "disabled" : false}
                                      onClick={() => deleteRow(v, index)}>
                                      X
                                    </button>
                                  </div>
                                </div>
                              )) : null}
                            </div>

                          }



                          <div style={{ textAlign: "left" }}>
                            <button className={editApps ? "editbtn" : "NotEdit"} style={{ marginLeft: '30px' }}
                              disabled={!editApps ? "disabled" : false}
                              name="popup"
                              onClick={addRow}>
                              Add App
                            </button>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={val} index={3}>
                        {applicationData.appliactionObj?.status !== "Deleted" ?
                          <div className="developmentSecurityTab">
                            <button
                              className={editAuth ? 'NotEdit' : 'editbtn'}
                              onClick={editAuthTab}
                              id="Edit"
                            >
                              Edit
                            </button>

                            <button
                              className={!editAuth ? 'NotEdit' : 'editbtn'}
                              onClick={handleAuthClose}
                              id="Cancel"
                            >
                              Cancel
                            </button>

                            <button
                              className={!editAuth ? 'NotEdit' : 'editbtn'}
                              id="Save"
                              onClick={SaveAuth}
                            >Save</button>
                          </div> : null}
                        <div className="InputflexRow">
                          <div className="ddflex">
                            <div className="companyLabel" style={{ fontSize: '14px' }}>
                              Is User Authentication Required?(Yes / No)
                            </div>

                            <div>
                              {!editAuth ? (
                                <select
                                  type="text"
                                  className="inputField"
                                  name="name"
                                  disabled="disabled"
                                  style={{ width: '109%', height: '34px' }}
                                >
                                  <option value="yes" selected={AuthDataEdit.user_authentications === "yes" && true}>Yes</option>
                                  <option value="no" selected={AuthDataEdit.user_authentications === "no" && true}>No</option>
                                </select>
                              ) : (
                                <select
                                  type="text"
                                  className="editinputField"
                                  name="popup"
                                  id="user_authentications"
                                  onChange={handleAuth}
                                  style={{ width: '109%', height: '34px' }}

                                >

                                  <option value="yes" selected={AuthDataEdit.user_authentications === "yes" && true}>Yes</option>
                                  <option value="no" selected={AuthDataEdit.user_authentications === "no" && true}>No</option>
                                </select>
                              )}
                            </div>
                          </div>

                          <div className="ddflex"></div>


                        </div>
                        {AuthDataEdit.user_authentications === "no" ? (
                          <div className="InputflexRow">
                            <div className="ddflex">
                              <div className="companyLabel" style={{ fontSize: '14px' }}>Customer Authentication Endpoint</div>

                              <div>

                                <input
                                  type="text"
                                  className="inputField"
                                  name="popup"
                                  id="authentication_endpoints"
                                  disabled="disabled"
                                  value={""}
                                />
                              </div>
                            </div>

                            <div className="ddflex">
                              <div className="companyLabel" style={{ fontSize: '14px' }}>Expected Valid Response Value</div>
                              <div>
                                <input
                                  type="text"
                                  className="inputField"
                                  name="popup"
                                  id="expected_valid_responses"
                                  disabled="disabled"
                                  value={""}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="InputflexRow">
                            <div className="ddflex">
                              <div className="companyLabel" style={{ fontSize: '14px' }}>Customer Authentication Endpoint</div>

                              <div>
                                {!editAuth ? (
                                  <input
                                    type="text"
                                    className="inputField"
                                    name="authentication_endpoint"
                                    id="authentication_endpoint"
                                    disabled="disabled"
                                    value={AuthData ? AuthData.authentication?.endpoint : ""}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    name="popup"
                                    id="authentication_endpoint"
                                    className={!AuthError.authentication_endpoint ? "editinputField" : "editinputFielderr"}
                                    value={AuthDataEdit?.authentication_endpoint}
                                    onChange={handleAuth}
                                  />

                                )}
                                {AuthError.authentication_endpoint ? (
                                  <p style={{ float: 'left' }} className="errorClass">{AuthError.authMsg}</p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="ddflex">
                              <div className="companyLabel" style={{ fontSize: '14px' }}>Expected Valid Response Value</div>
                              <div>

                                {!editAuth ? (
                                  <input
                                    type="text"
                                    className="inputField"
                                    name="expected_valid_response"
                                    id="expected_valid_response"
                                    disabled="disabled"
                                    value={AuthData ? AuthData.authentication?.validResponse : ""}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    name="popup"
                                    id="expected_valid_response"
                                    className={AuthDataEdit.user_authentications === "yes" ? "editinputField" : "inputField"}
                                    disabled={AuthDataEdit.user_authentications === "yes" ? false : "disabled"}
                                    value={AuthDataEdit?.expected_valid_response}
                                    //value={AuthDataEdit?.expected_valid_response ?  AuthDataEdit?.expected_valid_response : "true"}
                                    onChange={handleAuth}
                                  />
                                )}

                              </div>
                            </div>
                          </div>
                        )}
                      </TabPanel>

                      <TabPanel value={val} index={4}>


                        <div className="InputflexRow">
                          <label className="companyLabel" style={{ fontSize: '14px' }}>Type:</label>

                          <div className="ddflex">
                            <label className="connectLabel" style={{ marginLeft: '30px' }}>Message</label>
                            <input
                              type="radio"
                              name="type"
                              value="message"
                              onClick={(e) => handleRadio(e.target.value, e.target.name)}
                              checked={notifyData.type === "message" ? true : false}
                            />
                            <label className="connectLabel" style={{ marginLeft: '11px' }}>Warning</label>
                            <input
                              type="radio"
                              name="type"
                              value="warning"
                              onClick={(e) => handleRadio(e.target.value, e.target.name)}
                              checked={notifyData.type === "warning" ? true : false}
                            />
                          </div>

                          <div className="ddflex">
                            <label className="companyLabel" style={{ fontSize: '14px' }}>Max version:</label>
                            <input
                              type="text"
                              name="maxVersion"
                              id="maxVersion"
                              value={notifyData.maxVersion}
                              className={notifyError.maxVersion ? "inlineText errEdit" : "inlineText"}
                              onChange={handleNotification}
                            />
                            {notifyError.maxVersion ? (
                              <span style={{ float: 'left', marginLeft: '40%' }} className="errormsg">{notifyError.versionMsg}</span>
                            ) : (
                              ""
                            )}
                          </div>

                        </div>

                        <div className="InputflexRow">
                          <label className="companyLabel" style={{ fontSize: '14px', marginTop: '10px' }}>Title:</label>

                          <div className="ddflex">
                            <input
                              type="text"
                              id="title"
                              name="gateway_description"
                              value={notifyData.title}
                              onChange={handleNotification}
                              style={{ marginLeft: '34px' }}
                              className={notifyError.title ? "connectInput errEdit" : "connectInput"}
                            />
                            {notifyError.title ? (
                              <span style={{ float: 'left', marginLeft: '7%' }} className="errormsg">This field is required</span>
                            ) : (
                              ""
                            )}
                          </div>

                        </div>

                        <div className="InputflexRow">
                          <label className="companyLabel" style={{ fontSize: '14px', marginTop: '10px' }}>Text:</label>

                          <div className="ddflex">
                            <input
                              type="text"
                              name="text"
                              id="text"
                              style={{ marginLeft: '34px' }}
                              value={notifyData.text}
                              onChange={handleNotification}
                              className={notifyError.text ? "connectText errEdit" : "connectText"}
                            />
                            {notifyError.text ? (
                              <span style={{ float: 'left', marginLeft: '7%' }} className="errormsg">This field is required</span>
                            ) : (
                              ""
                            )}
                          </div>

                        </div>


                        <div className="InputflexRow">
                          <label className="companyLabel" style={{ fontSize: '14px' }}>Frequency:</label>

                          <div className="ddflex">
                            <label className="connectLabel" style={{ marginLeft: '-8px' }}>Once</label>
                            <input
                              type="radio"
                              name="frequency"
                              value="once"
                              onClick={(e) => handleRadio(e.target.value, e.target.name)}
                              checked={notifyData.frequency === "once" ? true : false}
                            />
                            <label className="connectLabel" style={{ marginLeft: '40px' }}>Every Call</label>
                            <input
                              type="radio"
                              name="frequency"
                              value="eveycall"
                              onClick={(e) => handleRadio(e.target.value, e.target.name)}
                              checked={notifyData.frequency === "eveycall" ? true : false}
                            />
                          </div>

                          <div className="ddflex">
                            <label className="companyLabel" style={{ fontSize: '14px' }}>Display until: </label>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DateTimePicker
                                value={valuess}
                                id="dispalyUntil"
                                onChange={handleDateTime}
                                className={notifyError.dispalyUntil ? "inlineText errEdit" : "inlineText"}
                                format="dd/MM/yyyy hh:mm:ss a"
                                style={{ width: '54% !important', height: 'auto' }}
                              />
                            </MuiPickersUtilsProvider>
                            {notifyError.dispalyUntil ? (
                              <span style={{ float: 'left', marginLeft: '40%' }} className="errormsg">This field is required</span>
                            ) : (
                              ""
                            )}

                          </div>

                        </div>

                        <div className="InputflexRow">
                          <label className="companyLabel" style={{ fontSize: '14px' }}>Action:</label>

                          <div className="ddflex">
                            <label className="connectLabel" style={{ marginLeft: '19px' }} >Go to App</label>
                            <input
                              type="radio"
                              name="action"
                              id="action"
                              value="app"
                              checked={notifyData.action === "app" ? true : false}
                              onClick={(e) => handleAction(e.target.value)}
                            />
                            <label className="connectLabel" style={{ marginLeft: '20px' }}>Update Url</label>
                            <input
                              type="radio"
                              name="action"
                              id="action"
                              value="update"
                              checked={notifyData.action === "update" ? true : false}
                              onClick={(e) => handleAction(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="InputflexRow">
                          <label className="companyLabel" style={{ fontSize: '14px', marginTop: '10px' }}>Action Url:</label>
                          {notifyData.action === "app" ?
                            <div className="ddflex">
                              <input
                                type="text"
                                name="gateway_description"
                                id="actionUrl"
                                disabled="disabled"
                                value={notifyData.actionUrl}
                                className="inlineText"
                                style={{ marginLeft: '-2px', width: '91%', height: '22px', marginTop: '5px' }}
                              />

                            </div> :

                            <div className="ddflex">
                              <input
                                type="text"
                                name="gateway_description"
                                id="actionUrl"
                                onChange={handleNotification}
                                value={notifyData.actionUrl}
                                className={notifyError.actionUrl ? "connectInput errEdit" : "connectInput"}
                                style={{ marginLeft: '-2px', width: '97%' }}
                              />
                              {notifyError.actionUrl ? (
                                <span style={{ float: 'left', marginLeft: '1%' }} className="errormsg">{notifyError.urlMsg}</span>
                              ) : (
                                ""
                              )}
                            </div>}

                        </div>

                        <div className="popupbutton">
                          <button className="Pub_button"
                            onClick={publishMsg}
                          >
                            Publish Message
                          </button>
                        </div>
                      </TabPanel>

                    </div>
                  </div>
                ) :
                //   :  <div className="configDefinebtn" onClick={() => setOpen(true)}>
                //   {" "}
                //   + Create Development App Configuration and Define Entry Point
                // </div>
                null}
            </TabPanel>

          </div>
        ) : null}
      </div>



      <Dialog
        open={deleteopen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="deletedialoguettitle">
          WARNING!
        </DialogTitle>
        <DialogContent>
          <div>
            Are you sure you want to delete this #datafree Website Registration
            ? This cannot be undone.
          </div>
        </DialogContent>
        <DialogActions>
          <div className="iviteRow">
            <button
              className="deletecancelbtn"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <div className="flexSpace"></div>
            <button className="deletecancelbtn" color="primary">
              Confirm Deletion
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //clone Config */}
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="cmpdialogtitle">  Clone Development Config as template
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>

        </DialogTitle>
        <DialogContentText className="DialogContentText">
          Do you want to clone a previously defined configuration?
        </DialogContentText>
        <DialogActions>
          <Grid container>
            <Grid item xs={6}>
              <button
                className="inviteButton"
                style={{ width: "90%" }}
                color="primary"
                onClick={configOpenClick}
              >
                YES
              </button>
            </Grid>
            <Grid item xs={6}>
              <button
                className="inviteButton"
                style={{ width: "90%" }}
                color="primary"
                onClick={() => appConfigOpenClick("body")}
              >
                NO
              </button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* //cloneApp define config form */}
      <Dialog
        open={configCreateopen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">

          <div className="cmpdialogtitle">  Clone App Config and Entry Point
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContentText className="DialogContentText">
          <div className="dialogdiv">
            <div className="cmpdialoginputtext">
              {" "}
              Choose Development Configuration to Clone
            </div>
            <div className="cmpdialoginputtext">
              Select Development Configuration
            </div>
            <select
              type="text"
              id="dev_configs"
              name="dev_configs"
              onChange={(e) => lifecycleChange(e)}
              className="dialoginput"
              fullWidth
            >
              <option value=""></option>
              {configDetail && configDetail.length > 0
                ? configDetail.map((post) => (
                  <option key={post.configId} value={post.configId}>
                    {post.name}
                  </option>
                ))
                : null}
            </select>

            <div className="cmpdialoginputtext">New Name for Cloned Config</div>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => lifecycleChange(e)}
              className="cmpnydialoginput"
              fullWidth
            />

            <div className="cmpdialoginputtext">
              New Description for Cloned Config
            </div>
            <input
              type="text"
              id="description"
              name="description"
              onChange={(e) => lifecycleChange(e)}
              className="cmpnydialoginput"
              fullWidth
            />
          </div>
        </DialogContentText>
        <DialogActions>
          <div className="ivitediv">
            <button
              className="inviteButton"
              color="primary"
              onClick={() => setConfigCreateopen(false)}
            >
              Cancel
            </button>

            <button
              className="inviteButton"
              color="primary"
              onClick={createCloneConfig}
            >
              Save and Return
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //Create App Config Create Open */}
      <Dialog
        open={appConfig}
        scroll={scroll}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">

          <div className="cmpdialogtitle">   Create App Config and Entry Point
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={() => setAppConfig(false)} />
          </div>
        </DialogTitle>
        <DialogContentText className="DialogContentText">
          <div className="dialogdiv">
            <div className="cmpdialoginputtext">
              App Configuration Options
            </div>
            <div className="cmpdialoginputtext">Name</div>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => lifecycleChange(e)}
              className="cmpnydialoginput"
              fullWidth
            />
            <div className="cmpdialoginputtext">Description</div>
            <textarea
              type="text"
              id="name"
              name="name"
              onChange={(e) => lifecycleChange(e)}
              className="cmpnydialoginput"
              fullWidth
            />

            <div className="cmpdialoginputtext">
              biNu Platform
            </div>
            <select
              type="text"
              id="dev_configs"
              name="dev_configs"
              onChange={(e) => lifecycleChange(e)}
              className="dialoginput"
              fullWidth
            >
              <option value=""></option>
              {configDetail && configDetail.length > 0
                ? configDetail.map((post) => (
                  <option key={post.configId} value={post.configId}>
                    {post.name}
                  </option>
                ))
                : null}
            </select>

            <div className="cmpdialoginputtext">Google App Id</div>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => lifecycleChange(e)}
              className="cmpnydialoginput"
              fullWidth
            />

            <div className="cmpdialoginputtext">
              Package Suffix
            </div>
            <input
              type="text"
              id="description"
              name="description"
              onChange={(e) => lifecycleChange(e)}
              className="cmpnydialoginput"
              fullWidth
            />
            <div className="cmpdialoginputtext">
              Distribution Email
            </div>
            <textarea
              type="text"
              id="description"
              name="description"
              onChange={(e) => lifecycleChange(e)}
              className="cmpnydialoginput"
              fullWidth
            />

            <div className="cmpdialoginputtext">Include SDKs</div>
            <div className="cmpdialoginputtext">
              <input
                type="checkbox"
                name="websiteName"
                id="websiteName"
                checked={checked}
              />
              <label for="websiteName" className="optiontext">
                Effective Measure Tracker
              </label></div>

            <div >
              <input
                type="checkbox"
                name="websiteName"
                id="websiteName"
                checked={checked}
              />
              <label for="websiteName" className="optiontext">
                Video Advertising
              </label></div>
            <div>
              <label for="websiteName" className="optiontext">
                Include Developer Menu
              </label>
              <input
                type="checkbox"
                name="websiteName"
                id="websiteName"
                checked={checked}
              />
            </div>
            <div className="cmpdialoginputtext">
              Look and Feel

            </div>

            {colorchecked ? <div>
              <div className="registerFormTitle">Status Bar Colour</div>
              <div>
                <input
                  type="text"
                  className="registerInput"
                  placeholder=""
                  name="maxByteContent"
                  id="maxByteContent"
                />
              </div>
            </div> : ""}
            <div style={{ display: "flex" }}>
              {!colorchecked ? <div className="registerFormTitle">Status Bar Colour</div> : ""}

              {!colorchecked ? <div>
                <input
                  type="color"
                  className="colorPicker"
                  style={{ cursor: "pointer" }}
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
                <input
                  type="text"
                  className="colorinput"
                  disabled="disabled"
                  value={color}
                />
              </div> : ""}
              <div className="spanFormTitle">Click here to Enter Manually</div>
              <div>
                <input
                  type="checkbox"
                  className="spancheck"
                  name="websiteName"
                  id="websiteName"
                  onChange={() => setColorchecked(!colorchecked)}
                  checked={colorchecked}
                />
              </div></div>
            {/* //Action Color */}

            {actioncolorchecked ? <div>
              <div className="registerFormTitle">Action Bar Colour</div>
              <div>
                <input
                  type="text"
                  className="registerInput"
                  placeholder=""
                  name="maxByteContent"
                  id="maxByteContent"
                />
              </div>
            </div> : ""}
            <div style={{ display: "flex" }}>
              {!actioncolorchecked ? <div className="registerFormTitle">Action Bar Colour</div> : ""}

              {!actioncolorchecked ? <div>
                <input
                  type="color"
                  className="colorPicker"
                  style={{ cursor: "pointer" }}
                  value={actionColor}
                  onChange={(e) => setColor(e.target.value)}
                />
                <input
                  type="text"
                  className="colorinput"
                  disabled="disabled"
                  value={actionColor}
                />
              </div> : ""}
              <div className="spanFormTitle">Click here to Enter Manually</div>
              <div>
                <input
                  type="checkbox"
                  className="spancheck"
                  name="websiteName"
                  id="websiteName"
                  onChange={() => setActionColorchecked(!actioncolorchecked)}
                  checked={actioncolorchecked}
                />
              </div></div>
            {/* Accent Color */}
            {accentcolorchecked ? <div>
              <div className="registerFormTitle">Accent Colour</div>
              <div>
                <input
                  type="text"
                  className="registerInput"
                  placeholder=""
                  name="maxByteContent"
                  id="maxByteContent"
                />
              </div>
            </div> : ""}
            <div style={{ display: "flex" }}>
              {!accentcolorchecked ? <div className="registerFormTitle">Accent Colour</div> : ""}

              {!accentcolorchecked ? <div>
                <input
                  type="color"
                  className="colorPicker"
                  style={{ cursor: "pointer" }}
                  value={accentColor}
                  onChange={(e) => setColor(e.target.value)}
                />
                <input
                  type="text"
                  className="colorinput"
                  disabled="disabled"
                  value={accentColor}
                />
              </div> : ""}
              <div className="spanFormTitle">Click here to Enter Manually</div>
              <div>
                <input
                  type="checkbox"
                  className="spancheck"
                  name="websiteName"
                  id="websiteName"
                  onChange={() => setAccentColorchecked(!accentcolorchecked)}
                  checked={accentcolorchecked}
                />
              </div></div>
            <div className="cmpdialoginputtext">
              Use Dark Action Bar Icons
            </div>
            <select
              type="text"
              id="dev_configs"
              name="dev_configs"
              onChange={(e) => lifecycleChange(e)}
              className="dialoginput"
              fullWidth
            >
              <option value=""></option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <div className="cmpdialoginputtext">
              App Entry Point, Structure

            </div>
            <div style={{ display: "flex" }}>
              <div className="cmpdialoginputtext">Entry Point Hosting </div>
              <div><select
                type="text"
                id="dev_configs"
                name="dev_configs"
                onChange={(e) => lifecycleChange(e)}
                className="dialoginput"
                fullWidth
              >
                <option value="Self">Self</option>
                <option value="No">biNu</option>

              </select></div>
            </div>
            <div className="cmpdialoginputtext">Entry Point URL</div>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => lifecycleChange(e)}
              className="cmpnydialoginput"
              fullWidth
            />
          </div>


        </DialogContentText>
        <DialogActions>
          <div className="ivitediv">
            <button
              className="inviteButton"
              color="primary"
              onClick={() => setAppConfig(false)}
            >
              Cancel
            </button>

            <button
              className="inviteButton"
              color="primary"
              onClick={createCloneConfig}
            >
              Save and Return
            </button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog
        open={editAppOpen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="md"
        onClose={handleAppClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">

          <div className="cmpdialogtitle"> Application Details
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleAppClose} />
          </div>

          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>

        <DialogContent>
          <div className="dialoguRow">
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <div className="connectModalTitle">Application Name</div>
                <input
                  type="text"
                  id="name"
                  label="name"

                  className={!error.name ? "dialoginput" : "errdialoginput"}
                  placeholder="Enter the name of your application"
                  fullWidth
                  defaultValue={applicationData.appliactionObj?.name}
                  onChange={handleEdit}
                  style={{
                    height: '38px'
                  }}
                />

                {error.name ? (
                  <p style={{ float: 'left' }} className="errorClass">{error.nameMsg}</p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}>
                <div>
                  {/* <img src={defaultApp} alt="" className="imgdefaultapp"></img> */}
                  <img

                    src={splash.preview ? splash.preview : applicationData?.appliactionObj?.splashScreenImgUrl !== "" ? API_URL + applicationData.splashImage + applicationData.appliactionObj?.splashScreenImgUrl : defaultSplash}

                    alt=""
                    className="imgdefaultapp"
                  ></img>{" "}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>

                  <div className="connectModalTitle">Store App Id</div>
                  <input
                    className={!error.package ? "dialoginput" : "errdialoginput"}
                    fullWidth
                    name="store_id"
                    id="store_id"
                    defaultValue={applicationData.appliactionObj?.googleAppId}
                    onChange={handleEdit}
                    style={{
                      height: '38px'
                    }}
                  />
                  {error.store_id ? (
                    <p style={{ float: 'left' }} className="errorClass">{error.packageMsg}</p>
                  ) : (
                    ""
                  )}
                  <div className="connectModalTitle">Product Type</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="connectModalInput"
                    fullWidth
                    value="#datafree CONNECT"
                  />


                  <div className="connectModalTitle">#datafree Gateway Domain</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="connectModalInput"
                    fullWidth
                    defaultValue={applicationData.appliactionObj?.reachGateway}

                  />

                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={icon.preview ? icon.preview : applicationData.appliactionObj?.iconUrl !== "" ? API_URL + applicationData.iconImage + applicationData.appliactionObj?.iconUrl : defaultIcon}
                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload an icon image</div>

                      <div className="IconText3">
                        (Min. dimensions of 512px X 512px)
                      </div>
                      {error.icon_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.iconmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="icon_image"
                            id="icon_image"
                            onChange={handleEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={logo.preview ? logo.preview : applicationData?.appliactionObj?.logoUrl !== "" ? API_URL + applicationData.logoFile + applicationData.appliactionObj?.logoUrl : defaultIcon}
                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload an Logo image</div>

                      <div className="IconText3">
                        (Min. dimensions of 512px X 512px)
                      </div>
                      {error.logo_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.imgmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="logo_image"
                            id="logo_image"
                            onChange={handleEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={splash.preview ? splash.preview : applicationData?.appliactionObj?.splashScreenImgUrl !== "" ? API_URL + applicationData.splashImage + applicationData.appliactionObj?.splashScreenImgUrl : defaultSplash}

                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload a splash image</div>

                      <div className="IconText3">
                        (Min. dimensions of 1080px X 1920px)
                      </div>
                      {error.splash_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.splashmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="splash_image"
                            id="splash_image"
                            onChange={handleEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleAppClose}
              color="primary"
            >
              Cancel
            </button>

            <button className="inviteButton" color="primary"
              onClick={EditApplication}
            >
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* //////////////////////Build APK Model///////////// /////////////*/}

      <Dialog
        open={mdbuildapk} scroll={scroll} classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth maxWidth="sm" onClose={closeModalBuildApk} aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
          <div className="cmpdialogtitle"> Build APK
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={closeModalBuildApk} />
          </div>
          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>App ID</label>
            <input className="newconfiginput" type="text" disabled={true}
              value={BuildApkData?.obj?.binuAppId}
              style={{ cursor: 'not-allowed' }} />
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Connect Config Server</label>
            <input className="newconfiginput" type="text" disabled={true} value={BuildApkData?.obj?.configName}
              style={{ cursor: 'not-allowed' }} />
          </div>
          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <label style={{ marginRight: '4px',fontSize:'14px' }}>Datafree APK Library Version</label>
            <input className="newconfiginputRows" type="text" disabled={true} value={BuildApkData?.obj?.binuName} />
          </div>
          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '-17px' }}>
            <label style={{ marginRight: '10px',fontSize:'14px' }}>Name</label>
            <input className="newconfiginputRows" type="text" disabled={true} value={BuildApkData?.obj?.packageSuffix} />
          </div>
          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '-17px' }}>
            <label style={{ marginRight: '10px',fontSize:'14px' }}>Value</label>
            <input className="newconfiginputRows" type="text" disabled={true} value={BuildApkData?.obj?.reachGateway} />
          </div>

          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Version Code</label>
            <input
              className={!ApkError.version_code ? "newconfig" : "newconfigerr"}
              type="text"
              name="version_code" id="version_code"
              defaultValue={BuildApkData?.obj?.versionCode}
              onChange={handleApkEdit} />
            {ApkError.version_code ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">{ApkError.versionMsg}</span>
            ) : (
              ""
            )}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Version Name</label>
            <input
              className={!ApkError.version_name ? "newconfig" : "newconfigerr"}
              type="text"
              name="version_name" id="version_name"
              defaultValue={BuildApkData?.obj?.versionName}
              onChange={handleApkEdit} />
            {ApkError.version_name ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">This field is required</span>
            ) : (
              ""
            )}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Comments</label>
            <input
              className={!ApkError.comments ? "newconfig" : "newconfigerr"}
              type="text"
              name="comments" id="comments"
              defaultValue={BuildApkData?.obj?.comment}
              onChange={handleApkEdit} />
            {ApkError.comments ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">This field is required</span>
            ) : (
              ""
            )}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{ fontSize: '14px' }}>On completion of the APK generation, an email will be sent to any valid addresses noted in the Email Distribution List</label>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Email Distribution</label>
            <input
              className={!ApkError.email_distribution ? "newconfig" : "newconfigerr"}
              type="text" name="email_distribution"
              id="email_distribution"
              defaultValue={BuildApkData?.obj?.distributionEmail}
              onChange={handleApkEdit} />

            {ApkError.email_distribution ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">{ApkError.emailMsg}</span>
            ) : (
              ""
            )}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Build Type</label>
            <select
              className={!ApkError.buildTypes ? "newconfig" : "newconfigerr"}
              type="text"
              style={{ height: '35px' }}
              name="buildTypes" id="buildTypes" onChange={handleApkEdit}>
              <option value=""></option>
              <option key="DEBUG_PRODPROXY" value="DEBUG_PRODPROXY"> DEBUG_PRODPROXY </option>
              <option key="NORMAL" value="NORMAL"> NORMAL </option>
            </select>

            {ApkError.buildTypes ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">This field is required</span>
            ) : (
              ""
            )}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Package Suffix Override</label>
            <input className="newconfig" type="text"
              name="package_suffix"
              id="package_suffix"
              onChange={handleApkEdit}
              defaultValue={BuildApkData?.obj?.packageSuffix} />
          </div>
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ margin: '10px' }}>
              <button
                className="BuildBtn"
                onClick={closeModalBuildApk}
              >
                Cancel
              </button>
            </div>
            <div style={{ margin: '10px', height: '30px' }}>
              <button className="BuildBtn"
                onClick={BuildSave}>
                Build
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>

      {/* //////////////////////Build History///////////// /////////////*/}
      <Dialog open={openBuild}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="md" onClose={handleAppClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
          <div className="cmpdialogtitle"> Build History
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={openBuildClose} />
          </div>
          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} >
            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Build Id</div>
            </Grid>
            <Grid item xs={2}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Date</div>
            </Grid>
            <Grid item xs={2}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Base Config Name</div>
            </Grid>
            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Version Code</div>
            </Grid>
            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Version Name</div>
            </Grid>
            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Binu Version</div>
            </Grid>
            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Status</div>
            </Grid>
          </Grid>

          {BuildDetails && BuildDetails.length > 0 ?
            BuildDetails.map((apk, index) => (

              <Grid container spacing={1} style={{ borderTop: '1px solid #e5e5e5' }} key={index}>
                <Grid item xs={1}>
                  <div className="dialoginputtext">{apk.id}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">{apk.created}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">{apk.configName}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext" >{apk.versionCode}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext">{apk.versionName}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext" style={{ textAlign: 'center' }}>{apk.tag}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext">{apk.status}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">

                    {apk.buildType === "NORMAL" ?
                      apk.downloadApk !== null ?

                        (
                          <span className="anchDownload"
                            onClick={() => showApk(apk.id)}>Download</span>
                        ) : "Download" : null}

                    {apk.buildType === "DEBUG_PRODPROXY" ?
                      apk.downloadApk !== null ?
                        (
                          <span className="anchDownload"
                            onClick={() => showApkDebug(index, apk.id)}>Download Apk</span>
                        ) : "Download Apk"

                      : null}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext">
                    <span style={{ cursor: 'pointer',color: '#337ab7' }} onClick={() => GetInfo(apk.id)}>More Info</span>
                  </div>
                </Grid>

                <div id={"debugDownload" + apk.id} style={{ display: 'none' }}>
                  {apk && apk.downloadApk?.apkUrlJson?.map((uls, index) => (

                    <Grid container>
                      <div className="dialoginputtext" style={{ marginBottom: '20px' }}>

                        <label className="downstyle">
                          <a className="anchDownload"
                            href={API_URL + apk.key + uls.url}
                            download
                          >{"Download " + uls.channel + "  Apk"}</a>
                        </label>
                      </div>
                    </Grid>

                  ))

                  }
                </div>
                <div aria-label="simple table" style={{ display: 'none', fontSize:'14px' }} name="more_info" id={"Moreinfo_" + apk.id}>

                  <span>
                    <b>Deployment Id :</b>
                    {MoreData?.deploymentId?.deploymentId ? MoreData?.deploymentId?.deploymentId : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Base Config Id : </b>
                    {MoreData?.baseConfigId?.configId ? MoreData?.baseConfigId?.configId : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Base Config Name : </b>
                    {MoreData?.baseConfigId?.name ? MoreData?.baseConfigId?.name : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Deployment Type : </b>
                    {MoreData?.deploymentType ? MoreData?.deploymentType : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Proxy Endpoint : </b>
                    {MoreData?.proxyEndpoint ? MoreData?.proxyEndpoint : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Google App Id : </b>
                    {MoreData?.googleAppId ? MoreData?.googleAppId : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Distribution Email  : </b>
                    {MoreData?.distributionEmail ? MoreData?.distributionEmail : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Build Type : </b>
                    {MoreData?.buildType ? MoreData?.buildType : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Status Datetime : </b>
                    {MoreData?.statusDatetime ? MoreData?.statusDatetime : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Comments : </b>
                    {MoreData?.comment ? MoreData?.comment : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Version Tag : </b>
                    {MoreData?.versionTag ? MoreData?.versionTag : ""}
                  </span>
                  <br />
                  <br />
                  <span><b>App Attributes</b> <br />
                    {AppAttributes ? AppAttributes.map((att) => (
                      <div key={att}>
                        <br /><label>{att}</label><br />

                      </div>

                    )) : null}

                  </span>
                  <span><b>Channels :</b>
                    {MoreData?.channels ? MoreData?.channels : ""}
                  </span>
                  <br />
                  <br />
                  <span><b>Generated By :</b>
                    {MoreData?.userId?.name ? MoreData?.userId?.name : ""}
                  </span>
                  <br />
                  <br />
                  <span><b>Created On :</b>
                    {MoreData?.userId?.created ? MoreData?.userId?.created : ""}
                  </span>
                </div>
              </Grid>
            ))
            : null}
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="inviteButton" onClick={openBuildClose} color="primary">
              Return
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //////////////////////Publish Configuration///////////// /////////////*/}

      <Dialog open={openPublish} classes={{ scrollPaper: classes.scrollPaper }} scroll={scroll} maxWidth="sm" onClose={openPublishClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
          <div className="cmpdialogtitle">Publish App Config to Connect App Server
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={openPublishClose} />
          </div>
          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <div className="dialoginputtext">App ID</div>
              <input className="newconfiginputs" type="text" disabled={true} value={PublishData?.appId ? PublishData?.appId : ""} />
            </Grid>
            <Grid item xs={12}>
              <div className="dialoginputtext">Connect App Name</div>
              <input className="newconfiginputs" type="text" disabled={true} value={PublishData?.appName ? PublishData?.appName : ""} />
            </Grid>
            <Grid item xs={12}>
              <div className="dialoginputtext">Connect Config Server Gateway</div>
              <input className="newconfiginputs" type="text" disabled={true} value={PublishData?.gateway ? PublishData?.gateway : ""} />

            </Grid>
            <Grid item xs={12}>
              <div className="dialoginputtext">Gateway Description</div>
              <input className="newconfiginputs" type="text" disabled={true} value={PublishData?.description ? PublishData?.description : ""} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="inviteButtonCancel" onClick={openPublishClose} color="primary">
              Cancel
            </button>
            <button className="inviteButtonPublish" onClick={SavePubish} color="primary">
              Publish
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* //////////////////////Not edit or save Popup///////////// /////////////*/}
      <Dialog open={openPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //////////////////////Publish message Confirmation///////////// /////////////*/}

      <Dialog open={pubConfirm} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">

          <div className="cmpdialogtitle">
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={() => setPubConfirm(false)} />
          </div>

          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ color: '#939598' }}>
            <p> This message will replace any existing message for this app and will become effective </p>
            <p>  immediately. Please check that it is working as expected in the App after publication. </p>
            <p> Continue?</p></div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary" onClick={publishMsgConfirm}
              disabled={clickBut ? "disabled" : false}
            >
              Continue
            </button>
          </div>
        </DialogActions>
      </Dialog>


      {/* //////////////////////Decommision Direct App///////////// /////////////*/}
      <Dialog open={decommision} scroll={scroll}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth maxWidth="md" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="appdialogtitle">#datafree CONNECT - Decommission App Config and Delete App</div>
          <div className="appsubHead styleColor" style={{ width: '94%' }}>
            YOU HAVE REQUESTED TO DECOMMISSION THE #datafree CONNECT App DEFINED BELOW AND DELETE THE APP. ARE YOU SURE ?
          </div>
          <div className="appsubHead styleColor">
            If so please click on the Decommission button at the bottom of the screen.
          </div>
          <div className="appsubHead styleColor">
            The CONNECT Config Server will remove the App preventing any further use and the App will have its status set to Deleted preventing further update.
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container style={{ borderBottom: '1px solid #ccc', marginBottom: '10px' }}>
            <Grid item xs={12}>
              <div className="topHead">App Owner and Basic Details</div>
            </Grid>

            <Grid item xs={2}>
              <div className="labelDirect">Licensee</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={applicationData?.appliactionObj?.publisherId?.parentCompanyId?.parentCompanyId?.name}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <div className="labelDirect">Channel Owner</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={applicationData?.appliactionObj?.publisherId?.parentCompanyId?.name}

                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>


            <Grid item xs={5}>
              <div className="labelDirect">Publisher</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={applicationData?.appliactionObj?.publisherId?.name}

                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <div className="labelDirect">Customer Type</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={applicationData?.appliactionObj?.publisherId?.companyType}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <div className="labelDirect">Customer Status</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={applicationData?.appliactionObj?.publisherId?.status}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>

            <Grid item xs={5}>
              <div className="labelDirect">Connect Config Server</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={applicationData?.editConfigDetails?.name}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={7}>
            </Grid>

            <Grid item xs={5}>
              <div className="labelDirect">App Name</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={applicationData?.appliactionObj?.name}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <div className="labelDirect">App ID</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={applicationData?.generateconfigObj?.PROD[0].appId}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <div className="labelDirect">Created</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={moment(applicationData?.appliactionObj?.created).format("DD/MM/YYYY")}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{ float: 'none' }}>
            <button className="clearblueButton" color="primary"
              style={{ marginLeft: '5%', float: 'left' }}
              onClick={handleCloseDecommision}
            >
              Cancel
            </button>
            <button className="ButtonDirect" style={{ float: 'right', width: '54%' }} color="primary"
              onClick={() => DecommisionDeletePopup('body')}
            >
              Decommision #datafree Connect App
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={decommisionDel}
        scroll={scroll}
        classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="appdialogtitle">#datafree CONNECT - Decommission App Config and Delete App</div>

        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext"
            style={{ color: 'red', fontSize: '17px', fontWeight: '600' }}>DECOMMISSION - ARE YOU SURE ?</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{ float: 'none' }}>
            <button className="clearblueButton" name="popup" color="primary"
              onClick={handleCloseDelete}
              style={{ marginLeft: '5%', float: 'left' }}>
              Cancel
            </button>
            <button className="ButtonDirect" name="popup" color="primary"
              onClick={deleteConnectApp}
              style={{ float: 'right', width: '43%' }}>
              Confirm Deletion
            </button>
          </div>
        </DialogActions>
      </Dialog>



      <Dialog open={apkList} classes={{ scrollPaper: classes.scrollPaper }}
        scroll={scroll}
        maxWidth="sm"
        fullWidth
        onClose={openPublishClose} aria-labelledby="form-dialog-title"
      >


        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">

          <div className="cmpdialogtitle">Click to Download
          </div>

          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent>



          {downloadApks?.map((ul, index) => (
            <Grid Xs={12} >
              <Grid item xs={12}>
                <div className="dialoginputtext" style={{ marginBottom: '20px' }}>

                  <label className="downstyle">{"Download " + ul.channel + "  Apk"}</label>
                  <span style={{ marginLeft: '4%' }}>
                    <a style={{ color: 'black' }}
                      href={API_URL + downloadApksKey + ul.url} download><FaDownload /></a>
                  </span>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="dialoginputtext" style={{ marginBottom: '20px' }}>

                  <label className="downstyle">{"Download " + ul.channel + "  Aab"}</label>
                  <span style={{ marginLeft: '4%' }}>
                    <a style={{ color: 'black' }}
                      href={API_URL + downloadApksKey + ul.aabUrl} download><FaDownload /></a>
                  </span>
                </div>
              </Grid>
            </Grid>
          ))}



        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="inviteButtonCancel" onClick={() => setApkList(false)} color="primary">
              Cancel
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successopen}
        autoHideDuration={1500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          "Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={erroropen}
        autoHideDuration={2500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          An Error Occured!
        </Alert>
      </Snackbar>

      <Snackbar
        style={{
          backgroundColor: "white", maxWidth: '100%', height: 100
        }}
        open={openApprove}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={1500}
        onClose={handleApproveAlertClose}
      >
        <Alert
          style={{
            backgroundColor: "white",
            alignItems: "center",
            fontSize: "13px",
            maxWidth: '100%', height: 100,
            color: "#939598",
          }}
          onClose={handleApproveAlertClose}
          severity="error"
        >
          {pubError ? pubError : ""}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default AdminConnectApplication;
