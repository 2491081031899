import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ChannelService from "../../../api/channelService";
import applicationIcon from "../../../assets/img/application-icon.png";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import { IoMdCloseCircleOutline } from "react-icons/io";
import EmptyTable from "../../../Recomponent/EmptyTable";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function UserList() {
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [userDetails, setuserDetails] = useState();
  const [user_id, setUser_id] = useState();
  const [changedropdown, setChangedropdown] = useState(false);
  const [publisherDetails, setPublisherDetails] = useState();
  const [erroropen, seterrorOpen] = useState(false);
  const [company_id, setcompany_id] = useState();
  let history = useHistory();
  const [userdata, setUserdata] = useState({
    username: "",
    user_verified: "",
    user_status: "",
    user_id: "",
    company_id: ""
  });
  const [editError, setEditError] = useState({
    username: false,

  })
  const EditUser = (e) => {
    e.preventDefault();
    const newdata = { ...userdata };
    newdata[e.target.id] = e.target.value;
    newdata["user_id"] = user_id;
    newdata['company_id'] = company_id;
    if (e.target.id === "username") {
      if (e.target.value) {
        setEditError({
          ...editError,
          username: false
        })
      } else {
        setEditError({
          ...editError,
          username: true
        })
      }
    }
    setUserdata(newdata);
  };
  const handleClose = () => {

    setOpen(false);
    setEditError({
      username: false,
    })

  };
  const [errMsg, setErrMsg] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const handleAlertCloses = () => {
    setsuccessRequest(false);
    setOpen(false);
  }
 
  const [successRequest, setsuccessRequest] = useState(false);
  const [message, setMessage] = useState();
  //passwordReset
  async function resetPassword(EMAIL) {

    try {
      trackPromise(
        ChannelService.emailRequest({ email: EMAIL, admin: "admin" }).then((res) => {
          if (res.status === 200) {
            setsuccessRequest(true);
            setMessage(res.data.message);
            handleClose();
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  const [tablecols] = useState([
    {
      colName: 'User’s Name',
      colMap: 'name',
      linkName: true,
      cModel: true,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Email Address',
      colMap: 'email',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Role',
      colMap: 'role',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Type',
      colMap: 'userType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Verified',
      colMap: 'verified',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Publisher',
      colMap: 'created',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Company Type',
      colMap: 'CompanyTypeParentJson',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
  ])


  const [data, setData] = useState({
    searchPublisherName: "",
    searchUserName: "",
    searchEmail: "",
    searchVerified: "",
    searchStatus: "",
    channelId: "",
  });

  const Channeldata = () => {

    trackPromise(
      ChannelService.channelUserRequest().then((res) => {
        data["channelId"] = res.data?.userDetails?.companyId?.companyId;
      })
    );

  }
  const [empty, setEmpty] = useState(false);
  async function publisherListdata() {
    data["channelId"] = localStorage.getItem("channelId")
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setPublisherDetails({});
    try {
      trackPromise(
        ChannelService.channelUserLIst(data).then((res) => {
          setPublisherDetails(res.data);
          if (res.status === 200 && res.data.userListResult.length > 0) {
            setChangedropdown(false);
          }
          else if (res.status === 200 && res.data.userListResult.length === 0) {
            setEmpty(true);
            setChangedropdown(false);
          }
          else {
            setChangedropdown(true);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [scroll, setScroll] = useState("paper");
  const [userid, setUserId] = useState({
    userId: "",
    companyId: ""
  });
  const OpenUserTab = (scrollType, Id, cId) => {

    userid["userId"] = Id;
    setUserId(userid);

    getUserDetail(cId);

    setOpen(true);
    setScroll(scrollType);
  };
  // //getUser
  async function getUserDetail(id) {
    userid["companyId"] = id;
    setuserDetails("");
    try {
      trackPromise(
        ChannelService.channelUserBYId(userid).then((res) => {

          setuserDetails(res.data);

          setUser_id(res.data.userId);

          setUserdata({
            username: res.data.userName ? res.data.userName : "",
            user_verified: res.data.verified ? res.data.verified : "",
            user_status: res.data.status ? res.data.status : "",
            user_id: res.data.userId ? res.data.userId : "",
            company_id: res.data.companyId ? res.data.companyId : "",
          });
          setcompany_id(res.data.companyId);
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [loadings, setLoadings] = useState(false);
  //edit user
  async function EditUserData() {
    if (editError.username) {
      setEditError({
        ...editError,
        username: true,
      });
    } else {
      setLoadings(true);
      try {
        trackPromise(
          ChannelService.channelUserEdit(userdata).then((res) => {
            setLoadings(false);
            if (res.status === 200) {
             
              setOpen(false);
      
              handleClose();
            } else {
              seterrorOpen(true);
              setOpen(true);
            
            }
            publisherListdata()
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }
  }

  const lifecycleChange = (e) => {
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };
  const handlePublisherRouteLink = (companyId) => {
    history.push(`/channel/publisher/${companyId}/detail/view`)
  }
  useEffect(() => {
    Channeldata();
  }, []);

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">User List - Channel View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">

                </td>
                <td className="col-md-2">
                  Publisher
                </td>
                <td className="col-md-2">
                  User's Name
                </td>
                <td className="col-md-3">
                  Email Address
                </td>
                <td className="col-md-1">
                  Verified
                </td>
                <td className="col-md-2">
                  Status
                </td>
                <td className="col-md-2">

                </td>
              </tr>
              <tr>
                <td className="col-md-2">

                </td>
                <td className="col-md-2">
                  <input
                    type="text"
                    className="form-control"
                    id="searchPublisherName"
                    name="searchPublisherName"

                    onChange={(e) => lifecycleChange(e)}
                  ></input>
                </td>
                <td className="col-md-2">
                  <input
                    type="text"
                    className="form-control"
                    id="searchUserName"
                    name="searchUserName"

                    onChange={(e) => lifecycleChange(e)}
                  ></input>

                </td>
                <td className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    id="searchEmail"
                    name="searchEmail"

                    onChange={(e) => lifecycleChange(e)}
                  ></input>
                </td>
                <td className="col-md-1">
                  <select
                    type="text"
                    className="form-control form-select"
                    id="searchVerified"
                    name="searchVerified"
                    onChange={(e) => lifecycleChange(e)}
                  >

                    <option value=""></option>
                    <option value="no">No</option>
                    <option value="yes">Yes</option>

                  </select>
                </td>
                <td className="col-md-2">
                  <select
                    type="text"
                    className="form-control form-select"
                    id="searchStatus"
                    name="searchStatus"

                    onChange={(e) => lifecycleChange(e)}
                  >
                    {" "}
                    <option value=""></option>
                    <option value="Active">Active</option>

                  </select>
                </td>
                <td className="col-md-2">
                  <button className={
                    changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"
                  }
                    disabled={changedropdown ? "disabled" : false}

                    onClick={publisherListdata}>
                    Search
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>

        {search ? (
          <div>
            {publisherDetails && publisherDetails?.userListResult?.length > 0 ? <TableContainer>
              <Table className="accouttablestyle" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHead">User’s Name</TableCell>
                    <TableCell className="tableHead">Email Address</TableCell>
                    <TableCell className="tableHead">Role</TableCell>
                    <TableCell className="tableHead">Type</TableCell>
                    <TableCell className="tableHead">Verified</TableCell>
                    <TableCell className="tableHead">Status</TableCell>
                    <TableCell className="tableHead">Publisher</TableCell>
                    <TableCell className="tableHead">Company Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {publisherDetails.userListResult.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" style={{
                        textShadow: "none",
                        color: "#337ab7",
                        cursor: "pointer",

                      }}
                        onClick={() => OpenUserTab("body", row.userId, row.companyId)}>
                        {row?.userIdObj?.name}
                      </TableCell>
                      <TableCell
                      >
                        {row?.userIdObj?.email}
                      </TableCell>

                      <TableCell>{row.role}</TableCell>
                      <TableCell>{row.userIdObj?.userType}</TableCell>
                      <TableCell>{row.userIdObj?.verified === 1 ? "Yes" : "No"}

                      </TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell style={{
                        textShadow: "none",
                        color: "#337ab7",
                        cursor: "pointer",
                      }} onClick={() => handlePublisherRouteLink(row.companyIdObj.companyId)}>{row.companyIdObj.name}</TableCell >
                      <TableCell>{row.companyIdObj.companyType}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Users Found" col={tablecols} tableName="Applications List" /> : null}
              </div>}
          </div>
        ) : (
          null
        )}
      </div>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">User Details - Channel View
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="cmpdialoginputtext">User’s Name</div>
          <input
            type="text"
            id="username"
            label="username"
            className={!editError.username ? "form-control" : "form-control errorField"}
            defaultValue={userDetails ? userDetails.userName : ""}
            fullwidth="true"
            onChange={(e) => EditUser(e)}
          />
          {editError.username ? <span className="errorClass">This field is required</span> : null}
          <div className="cmpdialoginputtext">Email Address</div>
          <input
            type="text"
            id="email"
            name="email"
            disabled="disabled"
            label="email"
            value={userDetails ? userDetails.email : ""}
            className="form-control"
            fullwidth="true"
            style={{ cursor: 'not-allowed' }}

          />
          <div className="cmpdialoginputtext">Role</div>
          <input
            type="text"
            id="role"
            label="role"
            disabled="disabled"
            value={userDetails ? userDetails.role : ""}

            className="form-control"
            style={{ cursor: 'not-allowed' }}
            fullwidth="true"
          />
          <div className="cmpdialoginputtext">Type</div>
          <input
            type="text"
            id="role"
            label="role"
            disabled="disabled"
            value={userDetails ? userDetails.type : ""}

            className="form-control"
            fullwidth="true"
            style={{ cursor: 'not-allowed' }}
          />
          <div className="cmpdialoginputtext">
            Verified
          </div>
          <select
            type="text"
            id="user_verified"
            name="user_verified"
            label="email"
            className="form-control form-select"
            onChange={(e) => EditUser(e)}
           
            fullwidth="true"
          >
            <option value="0" selected={userDetails?.verified === 0 && true}>No</option>
            <option value="1" selected={userDetails?.verified === 1 && true}>Yes</option>
          </select>
          <div className="cmpdialoginputtext">Status</div>
          <select
            type="text"
            id="user_status"
          
            name="user_status"
            label="email"
            className="form-control form-select"
            onChange={(e) => EditUser(e)}
            fullwidth="true"
          ><option value="Active" selected={userDetails?.status === "Active" && true}>Active</option>
            <option value="Deleted" selected={userDetails?.status === "Deleted" && true}>Deleted</option></select>
          <div className="cmpdialoginputtext">Created</div>
          <input
            type="text"
            id="email"
            label="email"
            disabled="disabled"
            style={{ cursor: 'not-allowed' }}
            value={userDetails ? userDetails.created + " GMT" : ""}
            className="form-control"

            fullwidth="true"
          />

          {/* {checked ? (
            <div>
              <div className="cmpdialoginputtext">User’s Name</div>
              <input
                type="text"
                id="email"
                label="email"
                className="form-control"
                onChange={(e) => EditUser(e)}
                fullwidth="true"
              />
              <div className="cmpdialoginputtext">Email Address (username)</div>
              <input
                type="text"
                id="email"
                label="email"
                className="form-control"
                onChange={(e) => EditUser(e)}
                fullwidth="true"
              />
            </div>
          ) : (
            <div></div>
          )} */}
        </DialogContent>
        <DialogActions>
          {loadings ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="buttonRow">
            <div>
              <button
                className="inviteButton"

                onClick={() => resetPassword(userDetails?.email)}
                color="primary"
              >
                Request Password Reset
              </button>
            </div>
            <div>
              <button
                className="inviteButton"
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </button>

              <button
                className="inviteButton"
                color="primary"
                onClick={EditUserData}
              >
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar open={successRequest} autoHideDuration={1500} onClose={handleAlertCloses}>
        <Alert onClose={handleAlertCloses} severity="success">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={erroropen}
        autoHideDuration={1500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default UserList;
