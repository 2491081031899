import React, { useState, useEffect } from "react";
import { FaIndustry } from "react-icons/fa"
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Service from "../../../api/service";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: "baseline",
    },
});
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const LoadingIndicator = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress && (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
            </div>
        )
    );
};
function ClentList() {
    const [open, setOpen] = useState(false)
    const classes = useStyles();
    const handleClose = () => {
        setOpen(false);
        setError({
            companyname: false,
            msg: ""
        })
        setEditdata({
            companyname: "",

        })
        setNameCheck({
            companyname: "",

        })
    }
    const [loading, setLoading] = useState(false)
    const [toastMsg, setToastMsg] = useState();
    const [successopen, setsuccessOpen] = useState(false);
    const [erroropen, seterrorOpen] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [errMessage, setErrMessage] = useState();
    let history = useHistory();
    const [error, setError] = useState({
        companyname: false,
        msg: ""
    })
    const [editdata, setEditdata] = useState({
        companyname: "",

    })
    const [nameCheck, setNameCheck] = useState({
        companyname: "",

    })
    const [list, setList] = useState();
    const cmpyReg = /[a-zA-Z]/;
    const handlesuccessAlert = () => {
        setsuccessOpen(false);

    };
    const handleAlertClose = () => {
        seterrorOpen(false);


    };
    //Client List
    const ClientListdata = () => {
        setLoading(true);
        Service.GetClientList().then((res) => {
            setLoading(false)
            setList(res?.data?.userResellerClientList)
            if (res?.data?.userResellerClientList?.length === 0) {
                setEmpty(true)
            }
        })

    }
    const companyChangeHandler = (e) => {
        const newdata = { ...editdata };
        newdata[e.target.id] = e.target.value;
        if (e.target.id === "companyname") {
            if (e.target.value) {
                companyCheck(e.target.value);

            } else {
                setError({
                    ...error,
                    companyname: true,
                    msg: "This field is required",
                });
            }
        }
        setEditdata(newdata)
    }
    async function companyCheck(cname) {
        nameCheck["companyname"] = cname;

        Service.ClientExist(nameCheck).then((res) => {
            if (res.data === false) {
                setError({
                    ...error,
                    companyname: true,
                    msg: "This​ Company ​name ​already​ exist",
                });
            } else {

                if (cname.includes(",")) {
                    setError({
                        ...error,
                        companyname: true,
                        msg: "Please enter a name without comma",
                    });
                }
                else if (cname.length === 0) {
                    setError({
                        ...error,
                        companyname: true,
                        msg: "This field is required",
                    });
                }
                else {
                    setError({
                        ...error,
                        companyname: false,
                    });
                }
            }
        })

    }
    const clientSave = () => {
        if (error.companyname) {
            setError({
                ...error,
                companyname: true,
            });

        }
        else if (editdata.companyname.length === 0) {
            setError({
                ...error,
                companyname: true,
                msg: "This field is required"
            });

        }
        else {

            Service.AddClient(editdata).then((res) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setToastMsg("Created Successfully!");
                    setOpen(false)
                    setsuccessOpen(true);
                    handleClose();
                } else {
                    seterrorOpen(true);

                }

                ClientListdata();
            }).catch((err) => {
                if (err?.response?.status === 500)
                    setLoaderVisible(false);
                setErrMessage(err?.response?.data?.data);
                seterrorOpen(true);
            })

        }
    }
        const redirectPath=(appId)=>{
            history.push(`/user/application/${appId}/details`);
        }
    useEffect(() => {
        setLoading(true);
        ClientListdata();
    }, [])

    return (
        <div className="mainBox">
            <div className="NavBoxDiv col-md-12 col-sm-12">
                <div className="NavBoxRow">
                    <div>
                        <FaIndustry className="faIconClient" />

                    </div>
                    <div className="NavBoxRowText">Client List - Reseller View</div>
                </div>
                <div><hr className="applications-list-hr"></hr></div>
                <div className="createButtonDiv">
                    <button className="defultbtn"
                        title="Click here to create new Client"
                        onClick={() => setOpen(true)}
                    >

                        + Create New Clent
                    </button>
                </div>

                <div className="NavBoxRowFilter table-responsive">
                    <table className="table-sm tableClent">
                        <thead>
                            <tr>
                                <th>Client Name </th>
                                <th>Created</th>
                                <th>App Name</th>
                                <th>Product Type</th>
                                <th> Dev biNu Id</th>
                                <th>Prod biNu Id</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.length > 0 ?
                                list?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.clientName}</td>
                                        <td>{item?.created}</td>
                                        <td><span style={{
                                                textShadow: "none",
                                                color: "#337ab7",
                                                cursor: "pointer"}} onClick={()=>redirectPath(item?.applicationId)}>{item?.appName}</span></td>
                                        <td>{item?.productType}</td>
                                        <td>{item?.devBiNuId}</td>
                                        <td>{item?.prodBiNuId}</td>
                                        <td>{item?.status}</td>
                                    </tr>
                                ))
                                : <tr> {!empty && !loading ? <td colSpan={"20"} >No Data Found</td> : ""

                                }</tr>}
                        </tbody>

                    </table>
                    {loading ? <div className="loaderContainer">
                        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}

                </div>

            </div>
            <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }}
                fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <div className="cmpdialogtitle">Create New Client
                        <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="form-group row">
                        <div className="col-md-3">
                            <label>Clent Name</label>
                        </div>

                        <div className="col-md-9">
                            <input className={error?.companyname ? "form-control errorField" : "form-control"} id="companyname" name="companyname"
                                onChange={(e) => companyChangeHandler(e)}></input>
                            {error.companyname ? <span className="errorClass">{error.msg ? error.msg : "This field is required"}</span> : null}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {loaderVisible ? <LoadingIndicator /> : null}
                    <div className="ivitediv">
                        <button className="inviteButton" color="primary" onClick={handleClose}>
                            Cancel
                        </button>

                        <button className="inviteButton"

                            color="primary" onClick={clientSave}>
                            Save
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
            <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
                <Alert onClose={handlesuccessAlert} severity="success">
                    {toastMsg ? toastMsg : "Updated Successfully!"}
                </Alert>
            </Snackbar>

            <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">
                    {errMessage ? errMessage : "An Error Occured!"}
                </Alert>
            </Snackbar>

        </div>

    )
}
export default ClentList