import axios from "axios";
import {
  accountListRequests,
  binuSdkRequests,
  API_URL,
  impersonate,
  applicationListRequests,
  companyListRequests,
  customerListRequests,
  userListRequests,
  exitimpersonate,
  publisherListRequests,userProfileRequests
} from "./constants";

class AdminService {
  //All managed List
  async ManagedListData() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${companyListRequests.getManagedList}`,
      { headers }
    );
    return response;
  }
//applicationIndex
async applicationIndex() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.get(
    `${API_URL}${applicationListRequests.getapplicationIndex}`,
    { headers }
  );
  return response;
}
  //Search By lifecycle,producttype,freedomain

  async listSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.companyListsearch}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  async userRoleSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${userProfileRequests.userRoleSearch}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //editChannel
  async editChannel(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.editChannelList}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //editChannel
  async AddCompany(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.addCompany}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //awsSERVICE API FO IMAGE
  async editImage(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.imageEdit}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //awsSERVICE API FOR Splash Image
  async editSplashImage(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.splashImageEdit}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  
  //licenseeServideDetailBYId
   
   async licenseeServiceDetailId(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.get(
      `${API_URL}${companyListRequests.licenseeServiceIdDetails}/${data}/service/details`,
     { headers }
      // { headers,params: data  }
    );
    return response;
  }
    
  async getUserRoleById(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.get(
      `${API_URL}${userProfileRequests.getUserRoleById}/${data}/details`,
     { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //binusdk

  async getbinusdkListbyId(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.getbinusdkListbyId}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //connect Application Search
  // async connectSearch(data) {
  //   let auth = localStorage.getItem("token");
  //   const headers = {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + auth,
  //   };
  //   const response = await axios.post(
  //     `${API_URL}${companyListRequests.connectSearch}`,
  //     data,
  //     { headers }
  //   );
  //   return response;
  // }

  //editBInusdk
  async editbinusdkListbyId(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.editBinuSdk}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  async channeldetail(id) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${companyListRequests.getChanneldetailView}/${id}/detail/view`,
      { headers }
    );
    return response;
  }
  //getcompanyList
  async UserListSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.companyListsearch}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //invite channel new user
  async invitechannelUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.invitechannelUser}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //getCustomerList
  async getCustomerList(id) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.get(
      `${API_URL}${customerListRequests.getcustomerList}/${id}/detail`,

      { headers }
      // { headers,params: data  }
    );
    return response;
  }
   //getGatewayDomain detailView
   async getGatewayDomainDetailView(gateway) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.get(
      `${API_URL}${companyListRequests.getdatafreeGatewayDomainDetailView}/${gateway}/detail/view`,

      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //editcustomeLIst
  async editcustomerList(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${customerListRequests.editcustomerRequest}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //mno ceck exist data
   
   async mnoCheckExist(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.mnoCheckExist}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //moya check exist data
   
  async moyaCheckExist(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.moyaCheck}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //mnoAdd
 
  async mnoAdd(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.mnoAdd}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
    //moya Add
 
    async moyaAdd(data) {
      let auth = localStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      };
      const response = await axios.post(
        `${API_URL}${companyListRequests.moyaAdd}`,
        data,
        { headers }
        // { headers,params: data  }
      );
      return response;
    }
   //editgatewayDomain
   async editGatewayDomain(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.editdatafreeGatewayDomainDetailView}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
 //maintain Invoice Data Search
 async searchMaintainInvoice(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.MNOInvoice}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//maintain MOYA INVOICE SEARCH
async moyaInvoiceSearch(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.MNOMOUSEARCH}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//companyName Check
async companyNameCheck(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.companyNameCheck}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//company Check
async companyCheck(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.companyCheck}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//Version Search
async versionSearch(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.versionSearch}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//Version Details
async versionDetails(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.getVersionDetails}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//Version Update Detail
async versionUpdate(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.updateBinuVersion}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
  //edit licensee sevice
  
  async editLicenseeService(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.editLicenseeSevice}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  
  //Add New Ips
  
  async addNewIps(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.addNewIp}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }

  //invite customer new user
  async invitecustomerUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.invitecustomerUser}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  async UserSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${userListRequests.getsearchList}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //editUser
  async editUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.editUserDetails}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
//editUser
async RoleSaveAdd(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.roleSave}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
  //application search
  async ApplicationSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${applicationListRequests.getApplicationsearchList}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  async accountListData() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${accountListRequests.getsaccountList}`,
      { headers }
    );
    return response;
  }

  //All binusdk List
  async BinusdkList() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${binuSdkRequests.getbinusdkList}`,
      { headers }
    );
    return response;
  }
  //version Index
 async BinuVersionIndex() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.get(
    `${API_URL}${companyListRequests.versionIndex}`,

    { headers }
    // { headers,params: data  }
  );
  return response;
}
//vesionADD
async AddVersion(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.versionAdd}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//connect index
async connectIndex() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.get(
    `${API_URL}${companyListRequests.connectIndex}`,
  
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//connect Search
async connectSearch(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.connectSearch}`,
   data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//APPROVE CONFIGURATION
async approveConfiguation(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.approveConfiguration}`,
   data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//Delete Moya User
async deleteMoya(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.moyaDelete}`,
   data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
  async SearchName(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${binuSdkRequests.seachName}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }

  //checkAdminStatus
  async CheckAdminStatus(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.checkAdminServiceStatus}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
//AddLicenseeService
async AddAdminLicenseeService(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.addLicenseeService}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


  //invite CompanyUser
  async InviteCompanyUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.inviteCompanyUser}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //getUser
  async getUserById(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.getUserById}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
//passWordReset
async passWordReset(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.passWordReset}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//invite User
async inviteAccount(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.inviteAccount}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//licensee ShortCode Check
async CheckShortcode(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.checkLicenseeShotCode}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//Change Owner
async ChangeOwner(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.changeOwnerShip}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

   //CheckEmailExist
   async EmailCheck(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.checkEmailExist}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //getReserved Ip
  async getReservedIPBYAccount(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.getDeploymentByAccount}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //getReservedIP
  async getReservedIP(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.getReservedIps}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //delete mno invoice
  async deleteMNOInvoice(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.maintainInvoiceDelete}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //Approve adminLicensee Service
  async approveAdminLicenseeService(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.adminLicenseeSeviceAppove}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
//Edit Deployment Ips
async EditDeploymentIp(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.updateDeploymentApis}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


  //impersonate User
  async impersonateUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${impersonate.impersonateuser}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  async exitImpersonate() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${exitimpersonate.exitimpersonateuser}`,
      { headers }
    );
    return response;
  }
  //datafree Direct Application Inex
  async datafreeApplicationIndex() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${companyListRequests.datafreedirectApplicationIndex}`,
      { headers }
    );
    return response;
  }
  //Datafree Application search
  async datafreeApplicationSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.datafreeApplicationSearch}`,
      data,
      { headers }
     
    );
    return response;
  }
  //getChannelByLicensee
  async ChannelByLicensee(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.getApplicationChannelBYLicensee}`,
      data,
      { headers }
     
    );
    return response;
  }
  //datafreeApp Create
  async dataFreeAppCreateRoute(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.admin_datafreedirect_application_create_route}`,
      data,
      { headers }
     
    );
    return response;
  }
  //datafreePublisherByChannelId
  
   async datafreePublisherByChannel(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${companyListRequests.getPublisherByChannelId}`,
      data,
      { headers }
     
    );
    return response;
  }

//datafreeDeploymentTypeByPublisherId
async datafreeDeploymentTypeByPublisherId(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.getDeploymentType}`,
    data,
    { headers }
   
  );
  return response;
}
  //datafreeProviderByDepType
async datafreeProviderByDepType(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.getProviderByDeployment}`,
    data,
    { headers }
   
  );
  return response;
}
 //datafreeRegion
 async datafreeRegionByProvider(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.getRegionByProvider}`,
    data,
    { headers }
   
  );
  return response;
}
//getAccount
async datafreeAccountByRegion(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.getAccountByRegion}`,
    data,
    { headers }
   
  );
  return response;
}

//datafreeGatewayDomain
async datafreeGatewayDomain() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.get(
    `${API_URL}${companyListRequests.admindatafreeGatewayDomainIndex}`,
    
    { headers }
   
  );
  return response;
}
//datafreeGatewayDomainSearch
async datafreeGatewayDomainSearch(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.admindatafreeGatewayDomainSearch}`,data,
    
    { headers }
   
  );
  return response;
}

  //datafreedirectapplnId details
  async datafreeIpIdDetails(id,hni) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${companyListRequests.getdatafree_direct_application_ips_details}${id}/${hni}/ips/details`,
      { headers }
    );
    return response;
  }
//getCompanyName_GatewayDomain
async getCompanyName_GatewayDomain(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.getCompanynameByChannelInGateway}`,data,
    { headers }
  );
  return response;
}
//getCompanyName_GatewayDomain
async approveDatafree(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.datafreeApprove}`,data,
    { headers }
  );
  return response;
}
//getapi_get_company_list_companytype
async getCompanyType_GatewayDomain(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.getapi_get_company_list_companytype}`,data,
    { headers }
  );
  return response;
}
//getapi_get_licensee_point_service
async getLicenseeService_GatewayDomain(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.getapi_get_licensee_point_service}`,data,
    { headers }
  );
  return response;
}
//dataFreeGateway save
async SaveDatafreeGateway(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.datafreegatewaySave}`,data,
    { headers }
  );
  return response;
}
//ViewBinuIP Deployment
async ViewIpDeployment(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.viewBinuIpDeployment}`,data,
    { headers }
  );
  return response;
}
 //datafree App Id Detail View
 async datafreeAppIdDetailView(id) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.get(
    `${API_URL}${companyListRequests.datafreeAppIdDeatilView}/${id}/detail/view`,
    { headers }
  );
  return response;
}
//datafree App Create
async datafreeAppCreate(id) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.get(
    `${API_URL}${companyListRequests.datafreeAppCreate}`,
    { headers }
  );
  return response;
}
// admin_check_date_greater
async CheckDateGreater(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.admin_check_date_greater}`,data,
    { headers }
  );
  return response;
}
// admindateRange
async AdminDateRange(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.admindateRange}`,data,
    { headers }
  );
  return response;
}
// ipExist
async IpExists(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.ipExist}`,data,
    { headers }
  );
  return response;
}
// hniExist
async HNIExists(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.checkHNIExist}`,data,
    { headers }
  );
  return response;
}

// add HNI
async addHNI(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.addHNI}`,data,
    { headers }
  );
  return response;
}

// UPDATE HNI
async updateHNI(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.updateHNI}`,data,
    { headers }
  );
  return response;
}

// GET HNI
async getHNI(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${companyListRequests.getHNI}`,data,
    { headers }
  );
  return response;
}
//ADD Moya
async MoyaCreate(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.moyaAdd}`,
    data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


//Connect Licensee Dropdown
async ConnectLicenseeDrop() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.get(
    `${API_URL}${companyListRequests.adminConnectLicensee}`,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//Connect Channel Dropdown ConnectPublisherDrop
async ConnectChannelDrop(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.getChannelName}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//Connect Publisher Dropdown 
async ConnectPublisherDrop(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.getPublisherName}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//Connect Gateway Dropdown 
async ConnectGatewayDrop(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.getGatewayList}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//Connect VersionList
async ConnectVersionList() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.get(
    `${API_URL}${companyListRequests.getVersionList}`,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//Connect Gateway Description 
async ConnectGatewayDescription(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.getGatewayDescription}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


//Connect Save
async saveConnect(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.saveConnect}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//dataFreeDecommision
async datafreeDecommision(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.datafreeDirectDecommision}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}
//Upload Logo
async uploadLogo(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.uploadLogo}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//Upload Icon
async uploadIcon(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.uploadIcon}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//Upload Splash
async uploadSplash(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.uploadSplash}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


//connect Detail View Index
async connectDetailIndex(id) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.get(
    `${API_URL}${companyListRequests.connectDetailIndex}${id}/detail/view`,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


//Connect Modal edit 

async connectEdit(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.connectEdit}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async connectAuth(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.connectAuth}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

async connectConfig(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.connectConfig}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async connectSupportedApps(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.connectSupportedApps}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async ConfigSave(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.ConfigSave}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

async LookSave(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.LookSave}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async AuthSave(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.AuthSave}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async SupportedAppsSave(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.SupportedAppsSave}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

async ViewApk(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.ViewApk}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

async GetPopup(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.GetPopup}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async viewBuild(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.viewBuild}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async viewPublish(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.viewPublish}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async GenerateApk(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.GenerateApk}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async editPublish(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.editPublish}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


async moreInfo(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.moreInfo}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

async CheckCompany(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.CheckCompany}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

async CheckEmail(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.CheckEmail}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

async Period(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.Period}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


//Edit Direct Save

async dataFreeAppEditRoute(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.dataFreeAppEditRoute}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


//shortcode

async shortCodeConnect(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.shortCodeConnect}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//gateway drop user

async GatewayDrop() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.get(
    `${API_URL}${companyListRequests.GatewayDrop}`, 
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//Domain and gateway check
async CheckExist(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.CheckExist}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//distribution email
async popUp(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.popUp}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


// Connect App decommision
async decommisionConnect(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.decommisionConnect}`,data,
  
    { headers }
  );
  return response;
}

// Connect App publish msg
async notifyMsg(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.notifyMsg}`,data,
  
    { headers }
  );
  return response;
}


// Connect App Download APk
async apkUrl(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.apkUrl}`,data,
  
    { headers }
  );
  return response;
}
//Wrap Edit
async editWrap(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.editWrap}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}


//Notification search
async notifySearch(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${customerListRequests.notifySearch}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//Notification Details
async notifyDetails(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${customerListRequests.notifyDetails}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//rangeDateTime Details
async rangeDateTime(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${customerListRequests.rangeDateTime}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//GreaterDate Details
async GreaterDate(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${customerListRequests.GreaterDate}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//saveNotify Details
async saveNotify(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${customerListRequests.saveNotify}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//saveNotify Details
async editNotify(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${customerListRequests.editNotify}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//saveNotify Details
async directLogo(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.directLogo}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//company Exist check multiple user add Details
async companyExist(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.companyExist}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//revenue Exist check 
async revenueExist(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.revenueExist}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//revenue type Add check 
async revenueAdd(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.revenueAdd}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//revenue type search check 
async revenueSearch(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.revenueSearch}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//revenue type exist check 
async revenueExists(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.revenueExists}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//revenue type Delete
async revenueDelete(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.revenueDelete}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

//revenue type change in application list
async revenueChange(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.revenueChange}`, data,
    { headers }
    // { headers,params: data  }
  );
  return response;
}

}
export default new AdminService();
