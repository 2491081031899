import React, { useState, useEffect } from 'react';
import "./adminStyle.css";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Loader from "react-loading";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ConnectCreateSixthStep({ handlePreviousOpen, data, handleChange, RegisterApplication, successOpen, loaderVisible, errorOpen, handleAlertClose }) {
    let history = useHistory();
    const [apkReg, setapkReg] = useState(false);
    const [isDisable, setisDisable] = useState(false);
    //const[loaderVisible,setLoaderVisible] = useState(false);
    const onChange = (e) => {
        e.preventDefault();
        if (e.target.id === "apk_registration") {
            if (e.target.value === "yes") {

                setapkReg(true);
            }
            else {
                setapkReg(false);
            }
        }
        handleChange(e);
    }

    const RegClick = (e) => {
        e.preventDefault();
        setisDisable(true);
        RegisterApplication(e);
    }

    useEffect(() => {

        data.email_distribution = data?.distribution_mail;

    }, [data])

    return (
        <div className="directapplication">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className="gridHead">Step 6 of 6 - Generate self-signed test APK - Optional</div>
                </Grid>

                <Grid item xs={12}>
                    <div className="labelDirect auth" >Generate APK on Registration ? (Yes / No)</div>
                    <Grid item xs={2}>
                        <div>
                            <select type="text" className="selectIcon" id="apk_registration" name="apk_registration"
                                onChange={onChange}
                                defaultValue={data.apk_registration}>
                                <option>{""}</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>

                    </Grid>
                </Grid>

                {apkReg || data.apk_registration === "yes" ? (
                    <Grid item xs={12}>
                        <div className="labelDirect auth" >Email distribution list - An email will be sent on generation completion to the email list given in Step 2</div>
                        <Grid item xs={6}>
                            <div>
                                <input
                                    type="text"
                                    className="directFieldSelect"
                                    name="email_distribution"
                                    id="email_distribution"
                                    defaultValue={data.distribution_mail}
                                    onChange={handleChange}
                                />
                            </div>

                        </Grid>
                    </Grid>
                ) : null}

                <Grid item xs={6}>
                    <div className="ivitediv">
                        <button className={isDisable ? "datafreeConnectBtnDangerDisable" : "datafreeConnectBtnDanger"} id="Sixpage" color="primary"
                            onClick={() => history.push("/admin/datafreeconnect/application/index")} disabled={isDisable ? true : false}>
                            Cancel
                        </button>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    {" "}
                    <div className="ivitediv">
                        <button className={isDisable ? "datafreeConnectDisable" : "datafreeConnectButton"} id="Sixpage" color="primary" disabled={isDisable ? true : false}
                            onClick={() => handlePreviousOpen(6)}>
                            Previous
                        </button>
                        {" "}
                        <button className={isDisable ? "datafreeConnectDisable" : "datafreeConnectButton"} id="Sixpage" color="primary" style={{ marginLeft: '10px' }}
                            disabled={isDisable ? true : false}
                            onClick={RegClick}
                        >
                            Register Application
                        </button>
                    </div>
                </Grid>


                <Grid>
                    <div></div>
                </Grid>
                {
                    loaderVisible ?

                        <Grid item xs={12}> <div className="loaderpop" >
                            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />

                        </div></Grid> : null}

                {successOpen ? (
                    <Grid item xs={6}>
                        <div className="gridHead">Registration of CONNECT Application has been Successfull</div>
                    </Grid>) : null}
                {successOpen ? (
                    <Grid item xs={12}>
                        <div className="label_Direct auth" >
                            Further modifications can be made through the CONNECT App Details screen navigated to via the CONNECT Application List Screen
                        </div>
                    </Grid>
                ) : null}
                {successOpen ? (
                    <Grid item xs={6}></Grid>) : null}
                {successOpen ? (
                    <Grid item xs={6}>
                        <div>
                            {" "}
                            <button className="datafreeConnectButton" color="primary" style={{ marginLeft: '10px', float: 'right' }}
                                onClick={() => history.push("/admin/datafreeconnect/application/index")}
                            >
                                Continue
                            </button>
                        </div>
                    </Grid>
                ) : null}
            </Grid>

            <Snackbar open={errorOpen} autoHideDuration={2500} onClose={handleAlertClose} >
                <Alert onClose={handleAlertClose} severity="error">
                    Something went wrong. Please reload the page!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ConnectCreateSixthStep
