import React, { useState, useEffect } from "react";
import "../../user/userPages/reporting/reporting.css";
import ReportingImg from "../../../assets/img/reporting-icon-black.png";
import Service from "../../../api/service";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { Grid} from "@material-ui/core";
import AccountService from "../../../api/accountService";
import {Chart} from "react-google-charts";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Reporting() {
  const [application, setApplication] = useState();
  const [period, setPeriod] = useState();
  const [erroropen, seterrorOpen] = useState(false);
  const [resMessage, setResMessage] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [reportData, setReportData] = useState({
    appId: "",
    reportPeriod: "28days",
    summaryTable: "28days",
    format: "Graph",
  });
  const [selectedappId] = useState({
    selectedAppId: "",
  });

  //get all application
  async function ApplicationListdata() {
    try {
      trackPromise(
        AccountService.reportingAccount().then((res) => {
          setApplication(res.data.applicationsListResult);
          setReportData({
            ...reportData,
            appId:res.data?.applicationsListResult[0]?.appId
          })
          Perioddata(res.data?.applicationsListResult[0]?.appId);
        })
      );
    } catch (err) {
      setResMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  //get all periods
  async function Perioddata(selectedappId) {
    try {
      trackPromise(
        AccountService.reportPeriod({selectedAppId:selectedappId}).then((res) => {
          setPeriod(res.data);
        })
      );
    } catch (err) {
      setResMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [reportdata] = useState({
    appId: "All Apps",
    reportPeriod: "28days"
  })
  //report Download
  async function csvDownload() {
    try {
      trackPromise(
        Service.reportDownload({appId:reportData.appId,reportPeriod:reportData.reportPeriod}).then((res) => {
          if(res.status === 200){
            if(reportData.appId === "All Apps"){
              const headers = ['Date,#datafree(MB),Paid(MB),WIFI(MB),Other(MB),TotalData(MB)']
              const usersCsv = res.data.mainTable.reduce((acc, user) => {
                const { date, totaldatafreemb, totalpaidmb, totalwifimb, totalunclassifiedmb, totaldatamb } = user
                acc.push([date, totaldatafreemb, totalpaidmb, totalwifimb, totalunclassifiedmb, totaldatamb].join(','))
                return acc
              }, []);
              downloadFile({
                data: [...headers, ...usersCsv].join('\n'),
                fileName: 'report.csv',
                fileType: 'text/csv',
              })
            }
            else{
              const headers = ['Date,New Users,Existing Users,Total Users,#datafree(MB),Paid(MB),WIFI(MB),Other(MB),Total Data(MB),#datafree per User(MB),Navs per User,Focus Mins per User']
              const usersCsv = res.data.mainTable.reduce((acc, user) => {
                const { date, newusers, existingusers, totalusers, totaldatafreemb, totalpaidmb, totalwifimb, totalunclassifiedmb, totaldatamb, mbperuser, navsperuser, focusminutesperuser} = user
                acc.push([date, newusers, existingusers, totalusers, totaldatafreemb, totalpaidmb, totalwifimb, totalunclassifiedmb, totaldatamb, mbperuser, navsperuser, focusminutesperuser].join(','))
                return acc
              }, []);
              downloadFile({
                data: [...headers, ...usersCsv].join('\n'),
                fileName: 'report.csv',
                fileType: 'text/csv',
              })
            }

          }
        })
      );
    } catch (err) {
      setResMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  const [searchDetails, setSearchDetails] = useState();
  const [loaderRun, setLoaderRun] = useState(false);
  const [reportsearch, setreportSearch] = useState(false);
  //search
  async function SearchReport() {
    setreportSearch(true);
    setLoaderRun(true);
    try {
      trackPromise(
        Service.searchReport(reportData).then((res) => {
          setLoaderRun(false);
          setSearchDetails(res.data)
          
        })
      );
    } catch (err) {
      setResMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const ReportHandle = (e) => {
    setreportSearch(false);
    const newdata = { ...reportData, [e.target.id]: e.target.value };
    document.getElementById("binuId").value = e.target.value;
    reportdata['appId'] = document.getElementById("binuId").value

    if (e.target.id === "appId") {
      GetPeriod(e.target.value);
    }
    setReportData(newdata);
  };

  function GetPeriod(id) {
    setPeriod();
    try {
      trackPromise(
        AccountService.reportPeriod({ selectedAppId: id }).then((res) => {
          setPeriod(res.data);
        })
      );
    } catch (err) {
      setResMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [graphForm, setGraphForm] = useState(true);

  const lifecycleChange = (e) => {
    setreportSearch(false);
    const newdata = { ...reportData, [e.target.id]: e.target.value };
    // newdata[e.target.id] = e.target.value;
    if (e.target.id === "reportPeriod") {
      const reportvalueSelected = period.reportValues[e.target.selectedIndex];

      newdata["reportPeriod"] = reportvalueSelected;

      newdata["summaryTable"] = reportvalueSelected;
      reportdata['reportPeriod'] = reportvalueSelected;
    }
    if (e.target.id === 'format') {
      if (e.target.value === 'Graph') { setGraphForm(true) } else setGraphForm(false)
    }
    selectedappId["selectedAppId"] = document.getElementById("binuId").value;
    let selectedappIds = document.getElementById("binuId").value;
    Perioddata(selectedappIds);
    setReportData(newdata);
  };

  useEffect(() => {
    ApplicationListdata();
    document.title = "Datafree Portal";  
  }, []);

  return (
    <>
      <div className="reportingView">
        <div className="reportpageView">
          <div className="reportNav">
            <div className="reportingtextview">
              <div className="reportingtextTop">
                <div>
                  <img
                    src={ReportingImg}
                    alt=""
                    className="reportheadIcon"
                  ></img>
                </div>
                <div className="reportheadToptext">Reporting</div>
              </div>
              <div className="reportsidetext" onClick={() => { window.open("https://powerbi.microsoft.com/en-us/") }}>Open Analytics and Insights</div>
            </div>
          </div>
          <div style={{margin:"25px"}}>
          <div className="reportCard">
            <div className="reportcardView">
              <Grid container>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="labelfield">
                    <label className="rrlabel">Application</label>
                    <select
                      className="fieldtext"
                      id="appId"
                      name="appId"
                      onChange={(e) => ReportHandle(e)}
                    >
                      {application && application.length > 0
                        ? application.map((post) => (
                          <option key={post.appId} value={post.appId}>
                            {post.applicationId.name}
                          </option>
                        ))
                        : null}
                    </select> 
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <div className="labelfield">
                    <label className="rrlabel">biNu Id</label>
                    <input
                      type="text"
                      className="applicationtext"
                      name="binuId"
                      id="binuId"
                      style={{height:'32px'}}
                      defaultValue={application ? application[0].appId : ""}
                      disabled="disabled"
                    /> 
                  </div>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="labelfield">
                    <label className="rrlabel">Reporting Period</label>
                     <select
                      className="fieldtext"
                      defaultValue="All Apps"
                      id="reportPeriod"
                      name="reportPeriod"
                      onChange={(e) => lifecycleChange(e)}
                    >
                      {period && period.description.length > 0
                        ? period.description.map((post, index) => (
                          <option key={index} value={period?.reportValues[index]}>{post}</option>
                        ))
                        : null}
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>

                  <label className="rrlabel">Format</label>
                  <select
                    className="fieldtext"
                    id="format"
                    name="format"
                    onChange={(e) => lifecycleChange(e)}
                  >
                    <option value="Graph">Graph</option>
                    <option value="Table">Table</option>
                  </select>

                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <div className="labelfield">
                    <button
                      type="submit"
                      className="runbutton"
                      onClick={SearchReport}
                    >
                      Run Report
                    </button>
                  </div>
                </Grid> 
              </Grid>

            </div>
          </div>
          { loaderRun ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          { reportsearch && !loaderRun ?

            (

              <div className={reportData?.format === "Table" ? "user-reporting-search-div row" : ""}>

                <div className="index-application-report-view-main-div">
                  {reportData?.format === "Table"? <div className="reportsMainTable">
                    <div className="table-responsive">
                      <table className="table table-bordered table-sm table-striped user_application_report_table">
                        <thead className="">
                          <tr>
                            <th>Date</th>
                            <th>New Users</th> 
                            <th>Existing Users</th> 
                            <th>Total Users</th> 
                            <th>#datafree(MB)</th>
                            <th>Paid(MB)</th>
                            <th>WIFI(MB)</th>
                            <th>Other(MB)</th>
                            <th>Total Data(MB)</th>
                            <th>#datafree per User(MB)</th> 
                           <th>Navs per User</th> 
                           <th>Focus Mins per User</th> 
                          </tr>
                        </thead>
                        <tbody>
                          {searchDetails && searchDetails?.searchListResult?.length > 0 ? searchDetails?.searchListResult?.map((item, index) => (
                            <tr>
                              <td>{item.date ? item.date : null}</td>
                             <td>{item.newusers ? item.newusers : "-"}</td> 
                             <td>{item.existingusers ? item.existingusers : "-"}</td> 
                             <td>{item.totalusers ? item.totalusers : "-"}</td> 
                              <td>{item.totaldatafreemb ? parseFloat(item.totaldatafreemb).toFixed(1) : "-"}</td>
                              <td>{item.totalpaidmb ? parseFloat(item.totalpaidmb).toFixed(1) : "-"}</td>
                              <td>{item.totalwifimb ? parseFloat(item.totalwifimb).toFixed(1) : "-"}</td>
                              <td>{item.totalunclassifiedmb ? parseFloat(item.totalunclassifiedmb).toFixed(1) : "-"}</td>
                              <td>{item.totaldatamb ? parseFloat(item.totaldatamb).toFixed(1) : "-"}</td>
                             <td>{item.mbperuser ? item.mbperuser : "-"}</td> 
                             <td>{item.navsperuser ? item.navsperuser : "-"}</td> 
                             <td>{item.focusminutesperuser ? item.focusminutesperuser : "-"}</td> 
                            </tr>
                          )) : <tr><td colSpan={"20"}>No Results Found</td></tr>}
                        </tbody>
                      </table>
                    </div>

                    <div className="report-table-28-days-div">

                      <Grid container>

                        <Grid item  xs={12} sm={3} md={3}>

                          <button className="btn-Report" onClick={csvDownload}>Export data to csv</button>

                        </Grid>
                        <Grid item  xs={12} sm={9} md={9}>
                          <div style={{ display: "block" }} className="reports-summary-table-div">
                            <table className="table reports-table-28-days">
                              <thead className="">
                                <tr>
                                  <th>Summary Total Unique Users</th>
                                  <th>Total #datafree Data (MB)</th>
                                  <th>Total Data (MB)</th>
                                  <th>Avg #datafree per User (MB)</th>
                                  <th>Avg Navs per User</th>
                                  <th>Avg Foc Mins per User</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>

                                  <td className="tableDiv"><input type="text" className="full-width total_unique_users" name="total_unique_users" 
                                  value={searchDetails?.summaryTable28?.TotalUsers !== null ? searchDetails?.summaryTable28?.TotalUsers : "-"} 
                                  disabled="disabled" /></td>
                                  <td className="tableDiv"><input type="text" className="full-width total_data_free" name="total_data_free" 
                                  value={searchDetails?.totalFreeMb !== null ? searchDetails?.totalFreeMb : "0"} disabled="disabled" /></td>
                                  <td className="tableDiv"><input type="text" className="full-width total_data" name="total_data" 
                                  value={searchDetails?.totalDataInMb !== null ? searchDetails?.totalDataInMb : "0"} disabled="disabled" /></td>
                                  <td className="tableDiv"><input type="text" className="full-width average_data" name="average_data" 
                                  value="0.00" disabled="disabled" /></td>
                                  <td className="tableDiv"><input type="text" className="full-width average_navs" name="average_navs" 
                                  value={searchDetails?.summaryTable28?.NavsPerUser !== null ? searchDetails?.summaryTable28?.NavsPerUser : "-"} disabled="disabled" /></td>
                                  <td className="tableDiv"><input type="text" className="full-width average_focus" name="average_focus" 
                                  value={searchDetails?.summaryTable28?.FocusMinutesPerUser !== null ? searchDetails?.summaryTable28?.FocusMinutesPerUser : "-"} disabled="disabled" /></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Grid>
                      </Grid>

                    </div>

                  </div> : 
                  <Grid container spacing={2}>
                  {searchDetails?.userDataGraph?.data?.arrayToDataTable?
                   <Grid item xs={12}>
                   
                  <Chart
                       width="100%"
                       height={400}
                       data={searchDetails?.userDataGraph?.data?.arrayToDataTable}
                       chartType="AreaChart"
                       chartArea={{
                        top: 55,
                        height: '40%' 
                     }}
                       options = {{
                        title: 'Total Active Users Existing and New By Date',
                        legend: { position: 'top', alignment: 'start' },
                        hAxis: {title: '',  titleTextStyle: {color: '#333'}, textPosition: 'out',slantedText:true, slantedTextAngle:45,
                        count: -1, 
                        viewWindowMode: 'pretty', },
                        vAxis: {minValue: 0},
                       
                      }}
                     >
                    
                     </Chart>
                  </Grid>:null}
                  
                  {searchDetails?.dataUsageGraph?
                  <Grid item  xs={12} sm={6} md={6}>
                  <Chart
                       width={580}
                       height={400}
                       data={searchDetails?.dataUsageGraph}
                       chartType="AreaChart"
                       chartArea={{
                        top: 55,
                        height: '40%' 
                     }}
                       options = {{
                        title: 'Data Usage MB',
                        legend: { position: 'top', alignment: 'start' },
                        hAxis: {title: '',  titleTextStyle: {color: '#333'}, textPosition: 'out',slantedText:true, slantedTextAngle:45,
                        count: -1, 
                        viewWindowMode: 'pretty', },
                        vAxis: {minValue: 0},
                       
                      }}
                     >
                    
                     </Chart>
                  </Grid>:null}
                  {searchDetails?.dataUsagePerUserPerDayReport? 
                  <Grid item  xs={12} sm={6} md={6}>
                  <Chart
                       width={580}
                       height={400}
                       data={searchDetails?.dataUsagePerUserPerDayReport}
                       chartType="AreaChart"
                       chartArea={{
                        top: 55,
                        height: '40%' 
                     }}
                       options = {{
                        title: 'Data Usage MB Per User',
                        legend: { position: 'top', alignment: 'start' },
                        hAxis: {title: '',  titleTextStyle: {color: '#333'}, textPosition: 'out',slantedText:true, slantedTextAngle:45,
                        count: -1, 
                        viewWindowMode: 'pretty', },
                        vAxis: {minValue: 0},
                       
                      }}
                     >
                    
                     </Chart>
                  </Grid>:null}
                  {/* //cvcvvvvvvvvv */}
                  {searchDetails?.navsPerUser? 
                  <Grid item xs={12} sm={6} md={6}>
                  <Chart
                       width={580}
                       height={400}
                       data={searchDetails?.navsPerUser}
                       chartType="AreaChart"
                       chartArea={{
                        top: 55,
                        height: '40%' 
                     }}
                       options = {{
                        title: 'Navigations Per User',
                        
                        legend: { position: 'top', alignment: 'start' },
                        hAxis: {title: '',  titleTextStyle: {color: '#333'}, textPosition: 'out',slantedText:true, slantedTextAngle:45,
                        count: -1, 
                        viewWindowMode: 'pretty', },
                        vAxis: {minValue: 0},
                       
                      }}
                     >
                    
                     </Chart>
                  </Grid>:null}
                  {searchDetails?.focusMinutesPerUser? 
                  <Grid item  xs={12} sm={6} md={6}>
                  <Chart
                       width={580}
                       height={400}
                       data={searchDetails?.focusMinutesPerUser}
                       chartType="AreaChart"
                       chartArea={{
                        top: 55,
                        height: '40%' 
                     }}
                       options = {{
                        title: 'Focus Minutes Per User ',
                       
                        legend: { position: 'top', alignment: 'start' },
                        hAxis: {title: '',  titleTextStyle: {color: '#333'}, textPosition: 'out',slantedText:true, slantedTextAngle:45,
                        count: -1, 
                        viewWindowMode: 'pretty', },
                        vAxis: {minValue: 0},
                       
                      }}
                     >
                    
                     </Chart>
                  </Grid>:null}
                  {searchDetails?.userDataGraph === null && searchDetails?.dataUsageGraph === null 
                  && searchDetails?.dataUsagePerUserPerDayReport === null &&
                  searchDetails?.navsPerUser === null && searchDetails?.focusMinutesPerUser === null? 
                  <Grid item xs={12}>
                   <div className="reportCard">No results found</div> 

                  </Grid>:null}
                  </Grid>}

                </div>


              </div>)
            : (
              ""
            ) }
          </div>
        </div>
        <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">
                    {resMessage}
                </Alert>
        </Snackbar>
      </div>
    </>
  );
}
export default Reporting;
