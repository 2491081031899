import React, { useState } from "react";
import SideNav from "../sidebar/user/sidebarUser";
import User from "../user/user";
function Index(props) {
 
  const [openSidebar,setOpenSidebar]=useState(true);
  const [openMOb,setOpenMob]=useState(false);
 const sidebarOpen=(val)=>{
  
   setOpenSidebar(!openSidebar);
 }
 const openMobileView=(ob)=>{
 
  setOpenMob(!openMOb);
}

const [open, setOpen] = useState(false);
  function init() {
    document.addEventListener('click', function (e) {
      if (document.getElementById('sidenavToggler')?.contains(e.target)) {
        
        e.preventDefault();
        open ? setOpen(false) : setOpen(true);
      } else {
     
      }
    });
  }
  document.addEventListener('load', init);

  return (
 <>
 
 <SideNav sidebarOpen={(val)=>sidebarOpen(val)} mobileviewOpen={(ob)=>openMobileView(ob)}/>
 <div className={!open ? (openSidebar ? "bodyMargin" : "bodyMarginOpen") : "bodyMarginOpen"} >
 <User />
 </div>
 </>
  );
}
export default Index;
