import React, { useState,useEffect } from 'react';
import applicationIcon from "../../../assets/img/application-icon.png";
import VMCTable from "../../../Recomponent/Table";
import EmptyTable from "../../../Recomponent/EmptyTable";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import AccountManagerService from "../../../api/accountService";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
       <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
}; 


function PublisherList() { 
  let history = useHistory();
  const [search, setSearch] = useState(false);
  const [changedropdown, setChangedropdown] = useState(false);
  const[userData,setUserData] =useState();
  const [erroropen, seterrorOpen] = useState(false);
  const [message, setMessage] = useState();

  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  const [data, setData] = useState({
    searchStatus: "",
    searchPublisherId:"" ,
    searchPublisherName: "",
    searchAccountLevel: "",
    channelId: ""
  });

  const [searchDetails, setSearchDetails] = useState();
  const [tablecols] = useState([
    {
      colName: 'Publisher Id',
      colMap: 'companyId',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Publisher Name',
      colMap: 'name',
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Company Type',
      colMap: 'companyType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Contract Term',
      colMap: 'contractTerm',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Created',
      colMap: 'created',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    }
  ])

  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  async function publisherListdata() {
    setChangedropdown(true);
    setSearch(true);
    setSearchDetails({});
    setLoading(true);
    setEmpty(false);
    data['channelId'] = userData?.channelId;
    AccountManagerService.publisherListSearch(data).then((res)=>{
    setSearchDetails(res?.data);  
    setLoading(false); 
    if (res.status === 200 && res.data.publisherListResult.length > 0) {
      setChangedropdown(false);
    } else if (res.status === 200 && res.data.publisherListResult.length === 0) {
      setEmpty(true);
      setChangedropdown(false);
    } else {
      setChangedropdown(true);
      setEmpty(false);
    }

  }).catch((err)=>{
      setMessage("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    })
  }
  
  const getData = () => {
    setLoading(true);
    AccountManagerService.AccountIndex().then((res) => {
          if(res.status === 200){
            setUserData(res.data);
            setData({
              ...data,
              channelId:res?.data?.channelId,
            });
          }
          setLoading(false);
        }).catch((err)=> {
          setMessage("Something went wrong. Please reload the page.");
          seterrorOpen(true);
        }); 
  };
  const handleRouteLink = (companyId) => {
      history.push(`/accountmanager/publisher/${companyId}/detail/view`);
  };

  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };
   useEffect(()=>{
   setLoading(true);
   getData();
   document.title = "Datafree Portal";
   },[])

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Publisher List - Account Manager View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">

        <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                Status
                </td>
                <td className="col-md-3">
                Publisher Id
                </td>
                <td className="col-md-2">
                Publisher Name
                </td>
                <td className="col-md-3">
                Account Level
                </td>
                <td className="col-md-1">
                
                </td>
                
              </tr>
              <tr>
                <td className="col-md-2">
                <select
                  type="text"
                  className="form-control form-select"
                  onChange={(e) => lifecycleChange(e)}
                  id="searchStatus"
                  name="searchStatus"
                 
                >
                  <option value=""></option>
                  <option value="New">New</option>
                  <option value="Approved">Approved</option>
                  <option value="NoAgreement">NoAgreement</option>
                  <option value="Suspended">Suspended</option>
                  <option value="Archived">Archived</option>
                </select>
                </td>
                <td className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => lifecycleChange(e)}
                  id="searchPublisherId"
                  name="searchPublisherId"
                ></input>
                </td>
                <td className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => lifecycleChange(e)}
                  id="searchPublisherName"
                  name="searchPublisherName"
                ></input>

                </td>
                <td className="col-md-3">
                <select
                  type="text"
                  className="form-control form-select"
                  onChange={(e) => lifecycleChange(e)}
                  id="searchAccountLevel"
                  name="searchAccountLevel"
                 
                >
                  <option value=""></option>
                  <option value="Standard">Standard</option>
                </select>
                </td>
                <td className="col-md-1">
                <button
                 
                 className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                 disabled={changedropdown ? "disabled" : false}
                 onClick={publisherListdata}>
                 Search
               </button>
                </td>
                
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>
        <div>
        {loading?  <div className="loaderContainer">
            <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
            
          {search ? (
            <div>
              {searchDetails && searchDetails?.publisherListResult?.length > 0 ? (
                <VMCTable key={searchDetails?.publisherListResult} data={searchDetails?.publisherListResult} 
                col={tablecols}  tableName="AccountManager Publisher List" serviceNav={handleRouteLink} />
              ) : (
                <div>
                  <LoadingIndicator />
                  {empty ? <EmptyTable data="No Publishers Found" col={tablecols} tableName="AccountManager Publisher List" /> : null}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
         {message ? message : "An Error Occured!"}
        </Alert>
        </Snackbar>
    </div>
  )
}

export default PublisherList;