import React, { useEffect, useState } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/reporting-icon-black.png";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import AdminService from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import moment from "moment";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { usePromiseTracker } from "react-promise-tracker";
import VMCTable from "../../../Recomponent/Table";
import EmptyTable from "../../../Recomponent/EmptyTable";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function BinuSDK() {
  const [binuList, setBinuList] = useState();
  const [open, setOpen] = useState(false);
  const [checked] = useState(1);
  const [searchName, setSearchName] = useState({
    searchString: "",
  });
  const [successopen, setsuccessOpen] = useState(false);
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    setOpen(false);
    seterrorOpen(false);
  };

  const [scroll, setScroll] = useState("paper");
  const [empty, setEmpty] = useState(false);
  const [binuVersionList, setBInuVersionList] = useState({});
  const [versionEditData, setVersionEditData] = useState({
    status: "",
   
    version_documentation_url: "",
    binuSdkVersionId: "",
  });

  const [tablecols] = useState([
    {
      colName: "SDK Vers Id",
      colMap: "binuSdkVersionId",
      linkName: true,
      title : "Click here to view version details",
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Version Tag",
      colMap: "binuSdkVersionTag", 
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Version Note",
      colMap: "binuSdkVersionNote",
      linkName: false,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: " Use as Default",
      colMap: "isDefault",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]);

  async function binusdkListdata() {
    setBinuList({});
    try {
      trackPromise(
        AdminService.BinusdkList().then((res) => {
          setBinuList(res.data.binuSdkVersionsResult);
        })
      );
    } catch (err) {
    
    }
  }
  async function binuNameSearch() {
   
    setEmpty(false);

    try {
      trackPromise(
        AdminService.SearchName(searchName).then((res) => {
          setBinuList(res.data.binuSdkVersionsResult);
        
          if (res.status === 200 && res.data.binuSdkVersionsResult.length > 0) {
          } else if ((res.status === 200 && res.data.binuSdkVersionsResult === []) || res.data.binuSdkVersionsResult === "") {
            setEmpty(true);
          } else {
            setEmpty(false);
          }
        })
      );
    } catch (err) {
    
    }
  }

  async function binusdkLIstbyID(version) {
   
    trackPromise(
      AdminService.getbinusdkListbyId({ binuSdkVersionId: version })
        .then((res) => {
        
          setBInuVersionList(res.data);
        })
        .catch((error) => {
        
        })
    );
  }

  const nameSearch = (e) => {
    e.preventDefault();
    const newdata = { ...searchName };
    newdata[e.target.id] = e.target.value;
    setSearchName(newdata);
  };
  const handleClose = () => {
    setOpen(false);
    setVersionEditData({
      status: "",
      
      version_documentation_url: "",
      binuSdkVersionId: "",
    });
    setBInuVersionList([]);
  };
  const handleCLickOpen = (scrollType, binuVersionId) => {
    setOpen(true);
    setScroll(scrollType);
    binusdkLIstbyID(binuVersionId);
    versionEditData["binuSdkVersionId"] = binuVersionId;
  };

  const defaultUse = () => {
    //no any default case ,not checked
    if (binuVersionList.isDefault === 0 && binuVersionList.isDefaultChange === "yes") {
      return (
        <div>
          <label for="websiteName"className="cmpdialoginputtext">
            Use as Default
          </label>
          <input type="checkbox" onChange={(e) => versionEditChange(e)} 
          name="editDefaultVersion" id="editDefaultVersion" defaultChecked={!checked} />
        </div>
      );
    }
    //already have default case .,disabled
    if (binuVersionList.isDefault === 0 && binuVersionList.isDefaultChange === "no") {
      return (
        <div>
          <label for="websiteName"className="cmpdialoginputtext">
            Use as Default
          </label>
          <input type="checkbox" disabled="disabled" />
        </div>
      );
    }
    //defaulted,can change
    if (binuVersionList.isDefault === 1 && binuVersionList.isDefaultChange === "yes") {
      return (
        <div>
          <label for="websiteName"className="cmpdialoginputtext">
            Use as Default
          </label>
          <input type="checkbox" onChange={(e) => versionEditChange(e)} name="editDefaultVersion" id="editDefaultVersion" defaultChecked={checked} />
        </div>
      );
    }
  };

  const versionEditChange = (e) => {
    e.preventDefault();
    const newversionEditdata = { ...versionEditData };
    newversionEditdata[e.target.id] = e.target.value;
    if (e.target.id === "editDefaultVersion") {
     
      if (e.target.checked === true) {
        newversionEditdata["editDefaultVersion"] = 1;
      } else {
        newversionEditdata["editDefaultVersion"] = null;
      }
    }
    setVersionEditData(newversionEditdata);
  };
  const [message, setMessage] = useState();
  const [erroropen, seterrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  async function binusdkEdit() {
    setLoading(true);
    trackPromise(
      AdminService.editbinusdkListbyId(versionEditData)
        .then((res) => {
          if (res.status === 200) {
            setToastMsg("binuSDK Version Updated Successfully!")
            binusdkListdata();            
            setsuccessOpen(true);
            handleClose();
            setLoading(false);
          }
         
        })
        .catch((error) => {
          setMessage(error.response.data);
          seterrorOpen(true);
        })
    );
  }

  useEffect(() => {
    binusdkListdata();
  }, []);

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">biNu SDK Version List</div>
        </div>

        <div className="searchVersion">
          <TextField
            placeholder="Search"
            size="small"
            className="searchVersionInput"
            id="searchString"
            name="searchString"
            onChange={(e) => nameSearch(e)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon onClick={binuNameSearch} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="tableDiv">
          {binuList && binuList.length > 0 ? (
            <VMCTable key={binuList} data={binuList} col={tablecols} navigateFunction={handleCLickOpen} tableName="Admin BinuSDK" />
          ) : (
            <div>
              <LoadingIndicator />
              {empty ? <EmptyTable data="No biNu SDK Versions Found" col={tablecols} tableName="Admin BinuSDK" /> : null}
            </div>
          )}
        </div>
      </div>
      <Dialog open={open} scroll={scroll} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="FlexRow">
            <div className="cmpdialogtitle">biNu Platform Version Details</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }} className="modalClose" 
              onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Version Id</div>
              <input
                type="text"
                id="binuSdkVersionId"
                name="binuSdkVersionId"
                className="form-conrol"
                disabled="disabled"
                fullWidth
                value={binuVersionList ? binuVersionList.binuSdkVersionId : ""}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Version Tag</div>
              <input
                type="text"
                id="customer_identifier"
                name="customer_identifier"
                className="form-conrol"
                disabled="disabled"
                label="email"
                fullWidth
                value={binuVersionList ? binuVersionList.binuSdkVersionTag : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Version Note</div>
              <textarea type="text" className="dialogtextArea" disabled="disabled" value={binuVersionList ? binuVersionList.binuSdkVersionNote : ""} label="email" fullWidth />
            </Grid>
            <Grid item xs={9}>
              <div>
                <label for="websiteName" className="cmpdialoginputtext">
                  Status
                </label>
                <select
                  type="checkbox"
                  name="status"
                  id="status"
                  className="checkboxSelect"
                  defaultValue={binuVersionList ? binuVersionList.status : ""}
                  onChange={(e) => versionEditChange(e)}
                  style={{ backgroundColor: "#f4f4f4", outline: "none" }}
                >
                  <option selected={binuVersionList.status === "InActive" && true} value="InActive">
                    InActive
                  </option>
                  <option selected={binuVersionList.status === "Active" && true} value="Active">
                    Active
                  </option>
                </select>
              </div>
            </Grid>
            <Grid item xs={3}>
              {defaultUse()}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Version documentation URL</div>
              <input
                type="text"
                id="version_documentation_url"
                name="version_documentation_url"
                label="email"
                onChange={(e) => versionEditChange(e)}
                className="form-control"
                fullWidth
                defaultValue={binuVersionList ? binuVersionList.binuSdkDocumentation : ""}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Created</div>
              <input
                type="text"
                label="email"
                className="form-control"
                disabled="disabled"
                fullWidth
                value={binuVersionList ? moment(binuVersionList.created).format("YYYY-MM-DD HH:mm") + " GMT" : ""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        {loading ?  <div className="loaderContainer">
            <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="inviteButton" color="primary" onClick={binusdkEdit}>
              Update Details
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar open={successopen} autoHideDuration={2000} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
         {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>
      <Snackbar open={erroropen} autoHideDuration={2000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default BinuSDK;
