import axios from "axios";
import {
  API_URL,
  licenseeListRequests,
  licenseeUserRequests,
  userChannelListRequests,impersonate,publisherListRequests,exitimpersonate
} from "./constants";

class service {
  //show details in publisherDesigner Page
  async licenseeDetailView(id) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${licenseeListRequests.getlicenseeDetailView}/${id}/detail`,
      { headers }
    );
    return response;
  }
  async exitImpersonate() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${exitimpersonate.exitimpersonateuser}`,
      { headers }
    );
    return response;
  }
  async licenseeUserRequest() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${licenseeUserRequests.getlicenseeUser}`,
      { headers }
    );
    return response;
  }
  async licenseeIndexRequest(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeUserRequests.getlicenseeIndex}`,
      data,
      { headers }
    );
    return response;
  }
  //get Licensee Version Details
  async licenseeVersion() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${userChannelListRequests.versionDetails}`,

      { headers }
    );
    return response;
  }
  //impersonate User
  async impersonateUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${impersonate.impersonateuser}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
   //awsSERVICE API FO IMAGE
   async editImage(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.imageEdit}`,
      data,
      { headers }
      // { headers,params: data  }
    );
    return response;
  }
  //licenseeDetailView
  async licenseeChannelDetail(id) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${userChannelListRequests.licenseeChannelDetailView}/${id}/detail/view`,
      { headers }
    );
    return response;
  }
//licensee Account Detail View
async licenseeAccountDetailView() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.get(
    `${API_URL}${userChannelListRequests.licenseeAccountDetailView}`,
    { headers }
  );
  return response;
}

  //licensee CHannel Detail view Edit
  async licenseeChannelDetailViewEdit(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${userChannelListRequests.licenseeChannelDetailViewEdit}`,
      data,
      { headers }
    );
    return response;
  }
  //licensee add Company
  async AddLicenseeCompany(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${userChannelListRequests.addLicenseeCompany}`,
      data,
      { headers }
    );
    return response;
  }

  //licensee User
  async licenseeUserListRequest(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${userChannelListRequests.licenseeuseLIstsearch}`,
      data,
      { headers }
    );
    return response;
  }
  //licenseeCustomerDetailView
  async licenseeCustomerDetail(id) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${userChannelListRequests.licenseeCustomeriddetailview}/${id}/detail/view`,
      { headers }
    );
    return response;
  }

//licenseeCompanynameCheck
async licenseeCompanyName(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${userChannelListRequests.companynamevalueCheck}`,data,
    { headers }
  );
  return response;
}
//licenseeCompanynameCheck
async licenseeEmailExist(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${userChannelListRequests.licemailCheck}`,data,
    { headers }
  );
  return response;
}
  //licensee Utility
   async licenseeUtility() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${userChannelListRequests.licenseeUtility}`,
      { headers }
    );
    return response;
  }
  //licensee custoem detail edit

  async licenseeEditCustomerRequest(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${userChannelListRequests.editCustomerDetail}`,
      data,
      { headers }
    );
    return response;
  }
  //publisherLIstIndex
  async licenseePublisherIndex() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${userChannelListRequests.publiserlistIndex}`,
      { headers }
    );
    return response;
  }
  //publisherLIstIndexSearch
  async licenseePublisherIndexSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${userChannelListRequests.publisherIndexSearch}`,
      data,
      { headers }
    );
    return response;
  }
  //invite New User
  async licenseeInviteNewUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    
    const response = await axios.post(
      `${API_URL}${userChannelListRequests.inviteLicenseeUser}`,
      data,
      { headers }
    );
    return response;
  }
  //adminLIcenseeEdit

  async adminLicenseeEditRequest(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeListRequests.editLicenseeDetailView}`,
      data,
      { headers }
    );
    return response;
  }
  //licenseeUserEdit

  async licenseeEditUserRequest(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${userChannelListRequests.editLicenseeUserDetails}`,
      data,
      { headers }
    );
    return response;
  }
  //licenseegetUserDetails
  async licenseegetUserRequest(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${userChannelListRequests.licenseegetUserDetails}`,
      data,
      { headers }
    );
    return response;
  }
  //licensee Index
  async licenseeIndex() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${userChannelListRequests.getLicenseeIndex}`,
      { headers }
    );
    return response;
  }
  //licensee Application Search
  async licenseeApplicationSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${userChannelListRequests.getapplicationListSearchLicensee}`,
      data,
      { headers }
    );
    return response;
  }

  //password Request
  async passwordRequest(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeUserRequests.passwordRequest}`,
      data,
      { headers }
    );
    return response;
  }

  //Licensee Account Page edit
  async accountEdit(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeUserRequests.accountEdit}`,
      data,
      { headers }
    );
    return response;
  }

   //inviteNew
   async inviteNew(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeUserRequests.inviteNew}`,
      data,
      { headers }
    );
    return response;
  }

   //checkMails
   async checkMails(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeUserRequests.checkMails}`,
      data,
      { headers }
    );
    return response;
  }

  //applicationList
  async applicationList(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeUserRequests.applicationList}`,
      data,
      { headers }
    );
    return response;
  }

  //licensee revenuetype List search
  async revenueSearchs(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeListRequests.revenueSearchs}`,
      data,
      { headers }
    );
    return response;
  }

   //licensee revenuetype List Add
   async revenueAddds(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeListRequests.revenueAddds}`,
      data,
      { headers }
    );
    return response;
  }

   //licensee revenuetype Code exist
   async revenueCodeExist(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${licenseeListRequests.revenueCodeExist}`,
      data,
      { headers }
    );
    return response;
  }

 //licensee revenuetype exist
 async revenueTypeExist(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${licenseeListRequests.revenueTypeExist}`,
    data,
    { headers }
  );
  return response;
}

//licensee revenuetype exist
async revenueDeletes(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.post(
    `${API_URL}${licenseeListRequests.revenueDeletes}`,
    data,
    { headers }
  );
  return response;
}

}

export default new service();
