import React, { useState, useEffect } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import VMCTable from "../../../Recomponent/Table";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import Loader from "react-loading";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { IoMdCloseCircleOutline } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function NotificationPage() {
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [empty, setEmpty] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [searchDetails, setSearchDetails] = useState();
  const [changedropdown, setChangedropdown] = useState(false);
  const [notifyIdOpen, setnotifyIdOpen] = useState(false);
  const [notifyDetails, setNotifyDetails] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [editopen, seteditOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setnotifyIdOpen(false);
    seteditOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [data, setData] = useState({
    searchNotificationId: "",
    searchNotificationLevel: "",
    searchNotificationType: "",
    searchActiveDate: "",
    searchStatus: "",
    searchAccount: "",
  });

  const [tablecol] = useState([
    {
      colName: "Managed By",
      colMap: "parentCompanyIdname",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Id",
      colMap: "companyId",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Name",
      colMap: "names",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Type",
      colMap: "companyType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Contract Term",
      colMap: "contractTerm",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false,

    },
  ]);
  const [tablecols ] = useState([
    {
      colName: "ID",
      colMap: "notificationId",
      linkName: true,
      cModel: true,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Level",
      colMap: "notificationLevel",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Type",
      colMap: "notificationType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Account",
      colMap: "accountName",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Active From",
      colMap: "active_from",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Active To",
      colMap: "active_to",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Notification Message",
      colMap: "notificationText",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]);

  const handleNotify = (scrollType, Id) => {
    NotifyDetails(Id, scrollType);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    setNotifyError({
      level: false,
      type: false,
      account: false,
      messages: false,
      status: false,
      activeFrom: false,
      activeTo: false,
      endRangeMsg: "",
      startRangeMsg: "",
    });
    setNotifyCreate({
      level: "",
      type: "",
      account: "",
      messages: "",
      status: "",
      activeFrom: "",
      activeTo: "",
    });
  };

  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleRouteLinks = (companyNames, companyId) => {
    setNotifyCreate({
      ...notifyCreate,
      account: companyNames
    });
    if (companyNames !== "") {
      setNotifyError({
        ...notifyError,
        account: false
      });
      document.getElementById("account_id").innerText = companyId;
    }
    setAccountOpen(false);
  };
  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "searchActiveDate") {
      newdata['searchActiveDate'] = e.target.value !== "" ? moment(e.target.value).format("DD/MM/YYYY") : "";
    }
    setData(newdata);
  };

  const handleNotifyClose = () => {
    setnotifyIdOpen(false);
    setEditError({
      end_date: false,
      status: false,
      start_date: false,
      endRangeMsg: "",
    })
  }
  const [editNotify, setEditNotify] = useState({
    notification_id: "",
    end_date: "",
    status: "",
    start_date: "",
  });
  const [editError, setEditError] = useState({
    end_date: false,
    status: false,
    start_date: false,
    endRangeMsg: "",
  })
  //notification Details
  async function NotifyDetails(id, scrollType) {
    setNotifyDetails();
    AdminService.notifyDetails({ notificationId: id }).then((res) => {
      setNotifyDetails(res.data);
      setnotifyIdOpen(true);
      setScroll(scrollType);
      setEditNotify({
        notification_id: id,
        end_date: moment(res?.data.activeTo.slice(0, 10)).format("DD-MM-YYYY") + " " + res?.data.activeTo.slice(11, 19),
        start_date: moment(res?.data.activeFrom.slice(0, 10)).format("DD-MM-YYYY") + " " + res?.data.activeFrom.slice(11, 19),
        status: res?.data.status,
      });
      setActiveTo(moment(res?.data.activeTo.slice(0, 10)).format("DD-MM-YYYY") + " " + res?.data.activeTo.slice(11, 19))
    }).catch((err) => {

    })
  }

  const [searchLoad, setSearchLoad] = useState(false);
  //search
  function companyListdata() {
    setSearchDetails({});
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setSearchLoad(true);
    AdminService.notifySearch(data).then((res) => {

      setSearchDetails(res.data.notificationListResult);
      setSearchLoad(false);
      if (res.status === 200 && res.data.notificationListResult.length > 0) {
        setEmpty(false);
        setChangedropdown(false);

      } else if (res.status === 200 && res.data.notificationListResult.length === 0) {
        setEmpty(true);
        setChangedropdown(false);
      } else {
        setChangedropdown(true);
        setEmpty(false);
      }
    }).catch((err) => {

    })
  }


  const [accountOpen, setAccountOpen] = useState(false);
  const accountPicker = (scrollType) => {
    setAccountOpen(true);
    setScroll(scrollType);
  }
  const [notifyCreate, setNotifyCreate] = useState({
    level: "",
    type: "",
    account: "",
    messages: "",
    status: "",
    activeFrom: "",
    activeTo: "",
  });
  const [notifyError, setNotifyError] = useState({
    level: false,
    type: false,
    account: false,
    messages: false,
    status: false,
    activeFrom: false,
    activeTo: false,
    endRangeMsg: "",
    startRangeMsg: "",

  });
  const notificationHandle = (e) => {
    e.preventDefault();
    const notifyData = { ...notifyCreate };
    notifyData[e.target.id] = e.target.value;
    if (e.target.id === "level") {
      if (e.target.value) {
        setNotifyError({
          ...notifyError,
          level: false,
        });
      }
      else {
        setNotifyError({
          ...notifyError,
          level: true,
        });
      }
    }
    if (e.target.id === "type") {
      if (e.target.value === "Account") {
        notifyData.account = "";
        accountPicker('body')
      }
      else if (e.target.value === "Licensee") {
        notifyData.account = "";
        getLicensee();
      }
      else if (e.target.value === "General") {
        notifyData.account = "";
      }
      if (e.target.value) {
        setNotifyError({
          ...notifyError,
          type: false,
        });
      }
      else {
        setNotifyError({
          ...notifyError,
          type: true,
        });
      }
    }
    if (e.target.id === "account") {
      if (e.target.value) {
        setNotifyError({
          ...notifyError,
          account: false,
        });
      }
      else {
        setNotifyError({
          ...notifyError,
          account: true,
        });
      }
    }
    if (e.target.id === "status") {
      if (e.target.value) {
        setNotifyError({
          ...notifyError,
          status: false,
        });
      }
      else {
        setNotifyError({
          ...notifyError,
          status: true,
        });
      }
    }
    if (e.target.id === "messages") {
      if (e.target.value) {
        setNotifyError({
          ...notifyError,
          messages: false,
        });
      }
      else {
        setNotifyError({
          ...notifyError,
          messages: true,
        });
      }
    }
    setNotifyCreate(notifyData);
  };
  //Add Notification
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState();
  const [clickSave, setClickSave] = useState(false);
  function AddNotification() {
    const notifyCounts = validateNotify(notifyCreate);

    if (notifyCounts === 0) {
      setClickSave(true);
      setLoading(true);
      if (notifyCreate.type === "Account") {
        notifyCreate['account_id'] = document.getElementById('account_id').innerText;
      }
      else {
        notifyCreate['account_id'] = "";
      }
      AdminService.saveNotify(notifyCreate)
        .then((res) => {
          setClickSave(false);
          setLoading(false);
          if (res.status === 200) {
            setsuccessOpen(true);
            handleClose();
          }
        })
        .catch((error) => {
          setMessage("Something went wrong. Please reload the page");
          seterrorOpen(true);
        });
    }
  }

  const validateNotify = (notifyCreate) => {
    let count = 0;
    let nwerror = { ...notifyError }
    if (notifyCreate.level === "") {
      nwerror.level = true;
      count++;
    }
    else {
      nwerror.level = false;
    }

    if (notifyCreate.type === "") {
      nwerror.type = true;
      count++;
    }
    else {
      nwerror.type = false;
    }

    if (notifyCreate.account === "") {
      if (notifyCreate.account === "General") {
        nwerror.account = false;
      }
      else {
        nwerror.account = true;
      }
    }
    else {
      nwerror.account = false;
    }

    if (notifyCreate.status === "") {
      nwerror.status = true;
      count++;
    }
    else {
      nwerror.status = false;
    }

    if (notifyCreate.messages === "") {
      nwerror.messages = true;
      count++;
    }
    else {
      nwerror.messages = false;
    }

    if (notifyCreate.activeFrom === "") {
      nwerror.activeFrom = true;
      nwerror.startRangeMsg = "This field is required"
      count++;
    }
    else {
      if (notifyError.activeFrom === true) {
        nwerror.activeFrom = true;
        count++;
      }
      else {
        nwerror.activeFrom = false;
      }
    }

    if (notifyCreate.activeTo === "") {
      nwerror.activeTo = true;
      nwerror.endRangeMsg = "This field is required"
      count++;
    }
    else {
      if (notifyError.activeTo === true) {
        nwerror.activeTo = true;
        count++;
      }
      else {
        nwerror.activeTo = false;
      }
    }
    setNotifyError(nwerror);
    return count;
  }
  const [activeFrom, setActiveFrom] = useState(null);
  const [activeTo, setActiveTo] = useState(null);

  const handleStart = (date) => {
    setActiveFrom(date !== null ? date : "");
    setActiveTo(null);
    if (date !== "") {
      if (date) {
        StartDateRange(moment(date).format("DD-MM-YYYY  HH:mm:ss"))
      }
    }
    else {
      setNotifyError({
        ...notifyError,
        activeFrom: true,
        startRangeMsg: "This field is required"
      })
    }
    const newIPdata = { ...notifyCreate };
    newIPdata['activeFrom'] = date !== "" ? moment(date).format("DD-MM-YYYY HH:mm:ss") : "";
    setNotifyCreate(newIPdata);
  };

  const handleEnd = (date) => {

    setActiveTo(date ? date : "");
    if (date !== "") {
      EndDateRange(moment(date).format("DD-MM-YYYY HH:mm:ss"))

    }
    else {
      setNotifyError({
        ...notifyError,
        activeTo: true,
        endRangeMsg: "This field is required"
      })
    }
    const newIPdata = { ...notifyCreate };
    newIPdata['activeTo'] = date !== "" ? moment(date).format("DD-MM-YYYY HH:mm:ss") : "";
    setNotifyCreate(newIPdata);

  };

  const handleEnds = (date) => {
    if (date !== "") {
      EndDateRanges(moment(date).format("DD-MM-YYYY HH:mm:ss"))
    }
    else {
      setEditError({
        ...editError,
        end_date: true,
        endRangeMsg: "This field is required"
      })
    }
    const newIPdata = { ...editNotify };
    newIPdata['end_date'] = date !== "" || date !== null ? moment(date).format("DD-MM-YYYY HH:mm:ss") : "";
    setEditNotify(newIPdata);
  };

  //DateRange
  const StartDateRange = (date) => {

    AdminService.rangeDateTime({ start_date: date }).then((res) => {
      if (res.status === 200 && res.data === false) {
        setNotifyError({
          ...notifyError,
          activeFrom: true,
          startRangeMsg: "Value must be greater than or equal to todays date"
        })
      }
      else {
        setNotifyError({
          ...notifyError,
          activeFrom: false,
          startRangeMsg: ""

        })
        notifyCreate['activeFrom'] = date;
        DateTimeGreater(date);
      }
    })

  }

  //End DateRange
  const EndDateRange = (date) => {
    AdminService.rangeDateTime({ end_date: date }).then((res) => {
      if (res.status === 200 && res.data === false) {
        setNotifyError({
          ...notifyError,
          activeTo: true,
          endRangeMsg: "Value must be greater than or equal to today's date"
        })
      }
      else {
        setNotifyError({
          ...notifyError,
          activeTo: false,
          endRangeMsg: ""

        })
        DateGreater(date);
      }
    })

  }
  //DateGreater
  const DateGreater = (date) => {
    notifyCreate['activeTo'] = date;
    AdminService.GreaterDate({ start_date: notifyCreate.activeFrom, end_date: notifyCreate.activeTo }).then((res) => {
      if (res.status === 200 && res.data === false) {
        setNotifyError({
          ...notifyError,
          activeTo: true,
          endRangeMsg: "Value must be greater than or equal to start date"
        })
      }
      else {
        setNotifyError({
          ...notifyError,
          activeTo: false,
          endRangeMsg: ""
        })

      }
    })

  }

  //DateGreater
  const DateTimeGreater = (date) => {
    notifyCreate['activeFrom'] = date;
    AdminService.GreaterDate({ start_date: notifyCreate.activeFrom, end_date: notifyCreate.activeTo }).then((res) => {
      if (res.status === 200 && res.data === false) {
        setNotifyError({
          ...notifyError,
          activeTo: true,
          endRangeMsg: "Value must be greater than or equal to start date",
          activeFrom: false,
          startRangeMsg: ""
        })
      }
      else {
        setNotifyError({
          ...notifyError,
          activeTo: false,
          endRangeMsg: "",
          activeFrom: false,
          startRangeMsg: ""
        })

      }
    })

  }
  //End DateRange
  const EndDateRanges = (date) => {
    AdminService.rangeDateTime({ end_date: date }).then((res) => {
      if (res.status === 200 && res.data === false) {
        setEditError({
          ...editError,
          end_date: true,
          endRangeMsg: "Value must be greater than or equal to today's date"
        })
      }
      else {
        setEditError({
          ...editError,
          end_date: false,
          endRangeMsg: ""

        })
        DateGreaters(date);
      }
    })

  }
  //DateGreater
  const DateGreaters = (date) => {
    editNotify['end_date'] = date;


    AdminService.GreaterDate({
      start_date: editNotify.start_date,
      end_date: editNotify.end_date
    }).then((res) => {
      if (res.status === 200 && res.data === false) {
        setEditError({
          ...editError,
          end_date: true,
          endRangeMsg: "Value must be greater than or equal to start date"
        })
      }
      else {
        setEditError({
          ...editError,
          end_date: false,
          endRangeMsg: ""
        })

      }
    })

  }

  const handleEdit = (e) => {

    e.preventDefault();
    const notifyData = { ...editNotify };
    notifyData[e.target.id] = e.target.value;
    if (e.target.id === "status") {
      if (e.target.value) {
        setEditError({
          ...editError,
          status: false,
        });
      }
      else {
        setEditError({
          ...editError,
          status: true,
        });
      }
    }
    setEditNotify(notifyData);
  }
  const [clickEdit, setClickEdit] = useState(false);
  const EditNotification = () => {
    const editCounts = validateEdit(editNotify);
    if (editCounts === 0) {
      setClickEdit(true);
      setLoading(true);
      AdminService.editNotify(editNotify)
        .then((res) => {
          setClickEdit(false);
          setLoading(false);
          if (res.status === 200) {
            handleNotifyClose();
            seteditOpen(true);
          }
        })
        .catch((error) => {
          setMessage("Something went wrong. Please reload the page");
          seterrorOpen(true);
        });
    }
  }

  const validateEdit = (editNotify) => {
    let counts = 0;
    let nwerror = { ...editError }
    if (editNotify.status === "") {
      nwerror.status = true;
      counts++;
    }
    else {
      nwerror.status = false;
    }

    if (editError.end_date === true) {
      nwerror.end_date = true;
      counts++;
    }
    else {
      nwerror.end_date = false;
    }

    setEditError(nwerror);
    return counts;
  }
  //managed By List
  const [manageList, setManageList] = useState();
  async function manageListdata() {
    AdminService.ManagedListData().then((res) => {
      setManageList(res.data.publisherListResult);
    }).catch((err) => {

    });
  }

  const [datas, setDatas] = useState({
    searchParentCompany: "",
    searchCompanyType: "",
    searchPublisherId: "",
    searchPublisherName: "",
    searchStatus: "",
  });
  const lifecycleChanges = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...datas };
    newdata[e.target.id] = e.target.value;
    setDatas(newdata);
  };
  const [emptys, setEmptys] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchs, setSearchs] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  //search
  async function companyListdatas() {
    setSearchs(true);
    setChangedropdown(true);
    setEmptys(false);
    setAccountLoading(true);
    setSearchData({});
    AdminService.listSearch(datas).then((res) => {
      setSearchData(res.data.publisherListResult);
      setAccountLoading(false);
      if (res.status === 200 && res.data.publisherListResult.length > 0) {
        setChangedropdown(false);
      } else if (res.status === 200 && res.data.publisherListResult.length === 0) {
        setEmptys(true);
        setChangedropdown(false);
      } else {
        setChangedropdown(true);
        setEmptys(false);
      }
    }).catch((err) => {
      setMessage("Something went wrong. Please reload the page");
      seterrorOpen(true);
    })
  }

  //Licensee details
  const [LicenseeData, setLicenseeData] = useState();
  function getLicensee() {
    try {
      AdminService.ConnectLicenseeDrop().then((res) => {
        setLicenseeData(res.data?.licensee);
      });
    } catch (err) {

    }
  }

  const [selectDate, setSelectDate] = useState();
  const handleDate = (date) => {
    setSelectDate(date !== null ? date : null);

    const newIPdata = { ...data };
    newIPdata['searchActiveDate'] = date !== null ? moment(date).format("DD/MM/YYYY") : "";
    setData(newIPdata);
  }
  useEffect(() => {
    manageListdata();
  }, []);

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Datafree Notifications Maintenance - Admin View</div>
        </div>
        <div className="NavBoxRowFilter">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <div className="NavBoxRowFilterLabel">Notification ID</div>
              <div>
                <input type="text" className="form-control"
                  id="searchNotificationId" name="searchNotificationId" onChange={(e) => lifecycleChange(e)}></input>


              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="NavBoxRowFilterLabel">Level </div>
              <div>
                <select type="text" className="form-control form-select"
                  id="searchNotificationLevel" name="searchNotificationLevel"
                  onChange={(e) => lifecycleChange(e)}>
                  <option value=""></option>
                  <option value="Alert">Alert</option>
                  <option value="Information">Information</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="NavBoxRowFilterLabel">Type</div>
              <div>
                <select type="text" className="form-control form-select"
                  id="searchNotificationType" name="searchNotificationType"
                  onChange={(e) => lifecycleChange(e)}>
                  <option value=""></option>
                  <option value="Account">Account</option>
                  <option value="General">General</option>
                  <option value="Licensee">Licensee</option>
                </select> </div>
            </Grid>
            <Grid item xs={2}>
              <div className="NavBoxRowFilterLabel">Active Date</div>
              <div>
                <DatePicker
                  id="searchActiveDate"
                  name="searchActiveDate"
                  className="form-control"
                  selected={selectDate}
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  onChange={(e) => handleDate(e)}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className="NavBoxRowFilterLabel">Status </div>
              <div>
                <select type="text" className="form-control form-select"
                  id="searchStatus" name="searchStatus" onChange={(e) => lifecycleChange(e)}>
                  <option value=""></option>
                  <option value="Active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="NavBoxRowFilterLabel">Account</div>
              <div>
                <input type="text" className="form-control"
                  id="searchAccount" name="searchAccount" onChange={(e) => lifecycleChange(e)}></input>

              </div>
            </Grid>

            <Grid item xs={1}>
              <div className="divSpace">
                <button
                  className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                  disabled={changedropdown ? "disabled" : false}
                  style={{ marginTop: '36px', height: '34px' }}
                  onClick={companyListdata}>
                  Search
                </button>
              </div>
            </Grid>

          </Grid>

        </div>
        <div className="createButtonDiv">
          <button className="defultbtn"
            title="Click here to create new Notification"
            onClick={() => handleClickOpen("body")}>
            Create New Notification
          </button>
        </div>
        {searchLoad ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
        {search ? (
          <div>
            {searchDetails && searchDetails.length > 0 ? (
              <VMCTable key={searchDetails} data={searchDetails} col={tablecols}
                tableName="Notification Table" clickOpen={handleNotify} />
            ) : (
              <div>

                {empty ? <EmptyTable data="No Notifications Found" col={tablecols} tableName="Company List" /> : null}
              </div>
            )}
          </div>
        ) : null}
      </div>


      {/* /////////////////////////////////////////Create Notification////////////////////////////////////// */}

      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Notification Type Creation
            <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>

            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Level</div>
              <select type="text" id="level" name="level"
                onChange={(e) => notificationHandle(e)}

                className={notifyError.level ? "form-control form-select errorField" : "form-control form-select"}
                fullWidth>

                <option value=""></option>
                <option value="Alert">Alert</option>
                <option value="Information">Information</option>
              </select>
              {notifyError.level ? <span className="errorClass paddingBottom">This field is required</span> : null}
            </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Type</div>
              <select type="text" id="type"
                name="type"
                onChange={(e) => notificationHandle(e)}
                className={notifyError.type ? "form-control form-select errorField" : "form-control form-select"}
                fullWidth>
                <option value=""></option>
                <option value="Account">Account</option>
                <option value="General">General</option>
                <option value="Licensee">Licensee</option>
              </select>
              {notifyError.type ? <span className="errorClass paddingBottom">This field is required</span> : null}
            </Grid>

            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Account</div>
              {notifyCreate.type === "General" ?
                <>
                  <select type="text" id="account"
                    name="account"
                    disabled
                    style={{ backgroundColor: '#eef1f5', cursor: 'not-allowed' }}
                    className="form-control form-select"
                    fullWidth>

                  </select>
                </>

                :
                notifyCreate.type === "Account" ?
                  <>
                    <input type="text" id="account"
                      name="account"
                      value={notifyCreate.account}
                      style={{ backgroundColor: '#eef1f5', cursor: 'not-allowed' }}
                      className={notifyError.account ? "form-control errorField" : "form-control"}

                      fullWidth>

                    </input>
                    {notifyError.account ? <span className="errorClass paddingBottom">This field is required</span> : null}

                  </> :
                  notifyCreate.type === "Licensee" ?
                    <>
                      <select type="text"
                        id="account"
                        name="account"
                        onChange={(e) => notificationHandle(e)}
                        className={notifyError.account ? "form-control form-select errorField" : "form-control form-select"}
                        fullWidth>

                        <option value=""></option>
                        {LicenseeData && LicenseeData.length > 0
                          ? LicenseeData.map((post) => (
                            <option key={post.companyId} value={post.companyId}>
                              {post.name}
                            </option>
                          ))
                          : null}
                      </select>
                      {notifyError.account ? <span className="errorClass paddingBottom">This field is required</span> : null}

                    </>
                    :
                    <>
                      <select type="text"
                        id="account"
                        name="account"
                        onChange={(e) => notificationHandle(e)}
                        className={notifyError.account ? "form-control form-select errorField" : "form-control form-select"}
                        fullWidth>

                        <option value=""></option>

                      </select>
                      {notifyError.account ? <span className="errorClass paddingBottom">This field is required</span> : null}
                    </>
              }
              <div type="text" style={{ display: 'none' }} id="account_id"></div>
            </Grid>

            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Active from</div>
              <Datetime type="text" id="activeFrom"
                name="activeFrom"
                selected={activeFrom}
                dateFormat="DD-MM-YYYY" timeFormat="HH:mm:ss"
                onChange={(e) => handleStart(e)}
                onBlur={(e) => handleStart(e)}
                className={notifyError.activeFrom ? "form-control errorField form-picker" : "form-control form-picker"}
                fullWidth>

              </Datetime>
              {notifyError.activeFrom ? <span className="errorClass paddingBottom">{notifyError.startRangeMsg}</span> : null}
            </Grid>

            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Active To</div>
              <Datetime type="text" id="activeTo"
                name="activeTo"
                selected={activeTo}
                onChange={(e) => handleEnd(e)}
                onBlur={(e) => handleEnd(e)}
                dateFormat="DD-MM-YYYY" timeFormat="HH:mm:ss"
                className={notifyError.activeTo ? "form-control errorField form-picker" : "form-control form-picker"}
                //className="NavBoxRowFilterinputBox bottomSpace" 
                fullWidth>

              </Datetime>
              {notifyError.activeTo ? <span className="errorClass paddingBottom">{notifyError.endRangeMsg}</span> : null}
            </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Status</div>
              <select type="text" id="status"
                name="status"
                onChange={(e) => notificationHandle(e)}
                className={notifyError.status ? "form-control form-select errorField" : "form-control form-select"}
                fullWidth>

                <option value=""></option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              {notifyError.status ? <span className="errorClass paddingBottom">This field is required</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Notification Message</div>
              <textarea
                id="messages"
                name="messages"
               
                onChange={(e) => notificationHandle(e)}
                className={notifyError.messages ? "form-control errorField" : "form-control"}
                fullWidth>
              </textarea>
              {notifyError.messages ? <span className="errorClass paddingBottom">This field is required</span> : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="inviteButton" 
            disabled = {clickSave ? "disabled" : false}
            onClick={AddNotification} color="primary">
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>


      {/* /////////////////////////////////////////Edit Notification////////////////////////////////////// */}

      <Dialog open={notifyIdOpen} classes={{ scrollPaper: classes.scrollPaper }}
        scroll={scroll}
        fullWidth
        maxWidth="sm"
        onClose={handleNotifyClose} aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Notification Details
            <IoMdCloseCircleOutline className="modalClose"
              onClick={handleNotifyClose}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">ID</div>
              <input type="text" id="level" name="level"
                style={{ cursor: 'not-allowed' }}
                className="form-control"
                value={notifyDetails?.notificationId}
                fullWidth>
              </input>
            </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Level</div>
              <input type="text" id="level" name="level"
                style={{ cursor: 'not-allowed' }}
                className="form-control"
                value={notifyDetails?.notificationLevel?.level}
                fullWidth>
              </input>
            </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Type</div>
              <input type="text" id="type"
                name="type"
                style={{ cursor: 'not-allowed' }}
                className="form-control"
                value={notifyDetails?.notificationType?.notification}
                fullWidth>
              </input>
            </Grid>

            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Account</div>

              <input type="text" id="account"
                name="account"
                value={notifyDetails?.companyId?.name}
                style={{ cursor: 'not-allowed' }}
                className="form-control"
                fullWidth>
              </input>
            </Grid>
            <Grid item xs={8}> </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Active from</div>
              <input type="text" id="activeFrom"
                name="activeFrom"
                value={moment(notifyDetails?.activeFrom.slice(0, 10)).format("DD-MM-YYYY") + " " + notifyDetails?.activeFrom.slice(11, 19)}
                style={{ cursor: 'not-allowed' }}
                className="form-control"
                fullWidth>

              </input>
            </Grid>

            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Active To</div>
              <Datetime type="text" id="activeTo"
                name="activeTo"
                dateFormat="DD-MM-YYYY" timeFormat="HH:mm:ss"
                value={editNotify?.end_date}
                selected={editNotify?.end_date}
                onChange={(e) => handleEnds(e)}
                onBlur={(e) => handleEnds(e)}
                className={editError.end_date ? "form-control errorField form-picker" : "form-control form-picker"}
                fullWidth>

              </Datetime>
              {editError.end_date ? <span className="errorClass AlignTop">{editError.endRangeMsg ? editError.endRangeMsg : ""}</span> : null}
            </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Status</div>
              <select type="text" id="status"
                name="status"
                className={editError.status ? "form-control form-select errorField" : "form-control form-select"}
                onChange={handleEdit}
                fullWidth>
                <option value=""></option>
                <option value="active" selected={notifyDetails?.status === "active" && true}>Active</option>
                <option value="inactive" selected={notifyDetails?.status === "inactive" && true}>Inactive</option>
              </select>
              {editError.status ? <span className="errorClass AlignTop">This field is required</span> : null}

            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Notification Message</div>
              <textarea
                id="mesage"
                name="mesage"
                value={notifyDetails?.notificationText}
                style={{ cursor: 'not-allowed' }}
                className="form-control"
                fullWidth>
              </textarea>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton"
              onClick={handleNotifyClose}
              color="primary">
              Cancel
            </button>
            <button className="inviteButton"
              disabled = {clickEdit ? "disabled" : false}
              onClick={EditNotification}
              color="primary">
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* /////////////////////////////////////////Account Picker////////////////////////////////////// */}

      <Dialog open={accountOpen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="lg"
        aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Account Picker
            <IoMdCloseCircleOutline className="modalClose" onClick={() => setAccountOpen(false)} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="NavBoxRowFilter wrapAccount">
            <Grid container spacing={1}>

              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Managed By</div>
                <select type="text" id="searchParentCompany" name="searchParentCompany"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control form-select">
                  <option value=""></option>
                  {manageList && manageList.length > 0
                    ? manageList.map((post) => (
                      <option key={post.companyId} value={post.companyId}>
                        {post.name}
                      </option>
                    ))
                    : null}
                </select>
              </Grid>
              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Company Type</div>
                <select type="text" id="searchCompanyType"
                  name="searchCompanyType"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control form-select">
                  <option value=""></option>
                  <option value="CHANNEL">CHANNEL</option>
                  <option value="CUSTOMER">CUSTOMER</option>
                  <option value="RESELLER">RESELLER</option>
                  <option value="LICENSEE">LICENSEE</option>
                </select>
              </Grid>

              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Company Id</div>
                <input
                  type="text"
                  id="searchPublisherId"
                  name="searchPublisherId"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control">
                </input>
              </Grid>
              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Company Name</div>
                <input
                  type="searchPublisherName"
                  id="searchPublisherName"
                  name="mesage"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control">
                </input>
              </Grid>

              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Status</div>
                <select type="text" id="searchStatus"
                  name="searchStatus"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control form-select"
                  fullWidth>

                  <option value=""></option>
                  <option value="Active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </Grid>
              <Grid item xs={1}>
                <div className="divSpace">
                  <button
                    className={changedropdown ? "binudefaultbtnafterclick" : "binudefaultbtn"}
                    disabled={changedropdown ? "disabled" : false}
                    style={{ marginTop: '38px', height: '34px' }}
                    onClick={companyListdatas}
                  >
                    Search
                  </button>
                </div>
              </Grid>
            </Grid>
            {accountLoading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          </div>
          <div className="createButtonDiv"></div>
         
          {searchs ? (
            <div>
              {searchData && searchData.length > 0 ? (
                <VMCTable key={searchData} data={searchData} col={tablecol}
                  navigateFunctions={handleRouteLinks} tableName="RoleCompany List" />
              ) : (
                <div style={{ alignItems: "center" }}>

                  {emptys ? <EmptyTable data="No Companies Found" col={tablecol} tableName="Company List" /> : null}
                </div>
              )}
            </div>
          ) : null}
        </DialogContent>

      </Dialog>
      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          Notification Created Successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={editopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          Notification Edited Successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default NotificationPage;
