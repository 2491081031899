import React, { useState, useEffect } from 'react';
import "./adminStyle.css";
import appIcon from "../../../assets/img/datafree_defaulticon.png";
import splashIcon from "../../../assets/img/default-app-splash.jpg";
import ConnectCreateFirstStep from "./connectCreateFirstStep";
import ConnectCreateSecondStep from "./connectCreateSecondStep";
import ConnectCreateThirdStep from "./connectCreateThirdStep";
import ConnectCreateFourthStep from './connectCreateFourthStep';
import ConnectCreateFifthStep from './connectCreateFifthStep';
import ConnectCreateSixthStep from './connectCreateSixthStep';
import service from "../../../api/adminService";


function ConnectCreate() {
  const [formStep, setFormStep] = useState(1);
  const [logo, setLogo] = useState({ preview: appIcon, raw: "" });
  const [icon, setIcon] = useState({ preview: appIcon, raw: "" });
  const [splash, setSplash] = useState({ preview: splashIcon, raw: "" });

  const errormsg = "This field is required";
  const [data, setData] = useState({
    licensee: "",
    channel: "",
    publisher: "",
    appname: "",
    appId: "",
    servergatway: "",
    apkConfigname: "",
    apkconfigdesc: "",
    apklibversion: "",
    distribution_mail: "",
    gatewaydesc: "",
    statusColor: "#303f9f",
    actionColor: "#3f51b5",
    accentColor: "#ff4081",
    bar_icons: "0",
    values: [{ index: Math.random(), application_label_vpn: "", playstore_app_identifier: "" }],
    user_authentication: "No",
    apk_registration: "No",
    statusmanual: "",
    actionmanual: "",
    accentmanual: "",
    logo_image: "",
    icon_image: "",
    splash_image: "",
    validResponse: "true",
    authentication_endpoint: "",
    email_distribution: "",
    page_title: ""
  });



  const [error, setError] = useState({
    msg: "",
    appmsg: "",
    licensee: false,
    channel: false,
    appname: false,
    appId: false,
    publisher: false,
    servergatway: false,
    apkConfigname: false,
    apkconfigdesc: false,
    apklibversion: false,
    distribution_mail: false,
    distemailmsg: "",
    actionColor: false,
    colormsg: "",
    statusbar: false,
    accentColor: false,
    labelmsg: "",
    application_label_vpn: false,
    playstore_app_identifier: false,
    identifiermsg: "",
    logo_image: false,
    imgmsg: "",
    icon_image: false,
    splash_image: false,
    splashmsg: "",
    iconmsg: "",
    actionmsg: "",
    accentmsg: "",
    authentication_endpoint: false,
    Authmsg: "",
    nameMsg: "",
    page_title: false,
    pageMsg: ""
  });
  const pageReg = /^[A-Za-z0-9 ]+$/;
  const appIdReg = /^[a-z][a-z0-9_]*(\.[a-z][a-z0-9_]*)+[0-9a-z_]?$/i;
  const distemail = /([a-zA-Z0-9_\-.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))/
  const stateColoReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const accentColorReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const actionColorReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const appReg = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/i;
  const nameReg = /[,]/;
  const apkValidReg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
 
  //Licensee Data
  const [LicenseeData, setLicenseeData] = useState();
  const [licensees] = useState({
    licenseeId: ""
  });
  const [channels] = useState({
    channelId: ""
  });
  const [shortcodes] = useState({
    licenseeId: "",
    companyId: ""
  });
  const [serverGateways] = useState({
    serverGateway: ""
  });

  const [ChannelData, setChannelData] = useState();
  const [PublisherData, setPublisherData] = useState();
  const [GatewayData, setGatewayData] = useState();
  const [VersionList, setVersionList] = useState();
  const [UserData, setUserData] = useState();
  const [GatewayDescription, setGatewayDescription] = useState();
  function getLicensee() {

    try {

      service.ConnectLicenseeDrop().then((res) => {

        setLicenseeData(res.data?.licensee);
      })

    } catch (err) {

    }

  }

  //ChannelName
  async function ChannelName(companyId) {
    licensees["licenseeId"] = companyId;
    try {

      service.ConnectChannelDrop(licensees).then((res) => {
        setChannelData(res.data?.licenseeChannels);
      })

    } catch (err) {

    }
  }

  //PublisherName
  async function PublisherName(channelId) {
    channels["channelId"] = channelId;

    try {

      service.ConnectPublisherDrop(channels).then((res) => {
        setPublisherData(res.data);
      })

    } catch (err) {

    }
  }

  //PublisherName
  async function shortcode(companyId, licenceeId) {
    shortcodes["licenseeId"] = licenceeId;
    shortcodes["companyId"] = companyId;

    try {

      service.shortCodeConnect(shortcodes).then((res) => {

        const pubname = res.data?.comapnyName;
        const lice = res.data?.licenseeShortcode === null ? "" : res.data?.licenseeShortcode;

        if (pubname !== "" && lice !== "" && data.appname !== "") {
          let appvalue = pubname + "." + lice + "." + data.appname;

          setData(
            {
              ...data,
              appId: appvalue.replace(/ /g, "")
            }
          );

          if (appvalue === "") {

            setError({
              ...error,
              appId: true,
              appmsg: errormsg
            });

          }
          else {
            setError({
              ...error,
              appId: false,
              appmsg: ""
            });
          }
        }



      })

    } catch (err) {

    }
  }

  //Gateway List
  async function GatewayList(companyId) {
    licensees["licenseeId"] = companyId;

    try {

      service.ConnectGatewayDrop(licensees).then((res) => {
        console.log(res.data,"res.data")
        setGatewayData(res.data);
      })

    } catch (err) {

    }
  }

  //VersionList

  function getVersions() {

    try {

      service.ConnectVersionList().then((res) => {
        setVersionList(res.data);
      })

    } catch (err) {

    }
  }

  //Gateway Description

  function displayDescription(gatewayVal) {
    serverGateways["serverGateway"] = gatewayVal;
    try {

      service.ConnectGatewayDescription(serverGateways).then((res) => {
        setGatewayDescription(res.data);
      })

    } catch (err) {

    }
  }

  //Distribution email

  function getUserMail() {
    setLoaderVisible(true);
    try {

      service.connectIndex().then((res) => {
        setUserData(res.data.distributionemail);
        data.distribution_mail = res.data.distributionemail?.email;
        setLoaderVisible(false);
      })

    } catch (err) {

    }
  }

  const [connectError, setconnectError] = useState([{
    index: Math.random(),
    playstore_app_identifier: false,
    application_label_vpn: false,
    labelmsg: "",
    identifiermsg: "",
  }])




  const addRow = e => {
    e.preventDefault();
    setData((prevState) => ({
      ...prevState,
      values: [...prevState.values, { index: Math.random(), playstore_app_identifier: "", application_label_vpn: "" }]
    }))

    setconnectError((prevState) => (
      [...prevState, {
        index: Math.random(),
        playstore_app_identifier: false,
        application_label_vpn: false,
        labelmsg: "",
        identifiermsg: "",
      }]
    ))

  };

  const deleteRow = (record, idx) => {

    setData({
      ...data, values: data.values.filter(r => r !== record)

    });

    const temp = [...connectError];
    temp.splice(idx, 1);
    setconnectError(temp);

  }
  const generateAppId = (name, pub, licen) => {
    if (name !== "" && pub !== "" && licen !== "") {
      shortcode(data.publisher, data.licensee);
    }
  }

  const [LogoImage, setLogoImage] = useState({
    logo_image: ""
  })
  const [IconImage, setIconImage] = useState({
    icon_image: ""
  })
  const [SplashImage, setSplashImage] = useState({
    splash_image: ""
  })
  const NextFormStep = (formStep) => {

    //e.preventDefault();

    let count = validateInfo(data, formStep);

    if (count > 0) {
      setFormStep(formStep);
    }
    else {
      setFormStep(formStep + 1);
    }


  };



  function UploadLogoDefault() {


    fetch(appIcon)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "datafree_defaulticon.png";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "datafree_defaulticon.png", {
          type: myBlob.type,
        });

        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("logo_icon_image", myFile ? myFile : "");
        }


        service.uploadLogo(values).then((res) => {

          setLogoImage({
            logo_image: res.data,
          })

        }).catch((error) => {

        })


      });

  }



  function UploadIconDefault() {


    fetch(appIcon)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "datafree_defaulticon.png";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "datafree_defaulticon.png", {
          type: myBlob.type,
        });



        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("icon_image", myFile ? myFile : "");
        }



        service.uploadIcon(values).then((res) => {

          setIconImage({
            icon_image: res.data,
          })

        }).catch((error) => {

        })


      });

  }


  function UploadSplashDefault() {


    fetch(splashIcon)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "default-app-splash.jpg";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "default-app-splash.jpg", {
          type: myBlob.type,
        });



        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("splash_image", myFile ? myFile : "");
        }

        service.uploadSplash(values).then((res) => {

          setSplashImage({
            splash_image: res.data,

          })
        }).catch((error) => {

        })


      });

  }


  async function UploadLogoImage(imageVal) {

    let file = imageVal;



    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("logo_icon_image", file ? file : "");
    }



    service.uploadLogo(values).then((res) => {

      setLogoImage({
        logo_image: res.data,
      })

    }).catch((error) => {

    })


  }

  async function UploadIconImage(imageVal) {

    let file = imageVal;


    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("icon_image", file ? file : "");
    }



    service.uploadIcon(values).then((res) => {

      setIconImage({
        icon_image: res.data,
      })
    }).catch((error) => {

    })


  }


  async function UploadSplashImage(imageVal) {

    let file = imageVal;


    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("splash_image", file ? file : "");
    }

    service.uploadSplash(values).then((res) => {

      setSplashImage({
        splash_image: res.data,
      })
    }).catch((error) => {

    })


  }
  const handleAlertClose = () => {
    setErrorOpen(false);
  };
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const RegisterApplication = (e) => {

    e.preventDefault();
    let step = 6;
    let count = validateInfo(data, step);

    if (count > 0) {
      setFormStep(formStep);
    }
    else {
      // alert("Call api to register here");
      //UploadLogoImage();
      const Savedata = {
        "licensee_list": data.channel,
        "channel_list": data.channel,
        "company_list": data.publisher,
        "app_name": data.appname,
        "storeAppIds": data.appId,
        "logo_image": LogoImage.logo_image,
        "icon_image": IconImage.icon_image,
        "splash_image": SplashImage.splash_image,
        "config_server_gateway": data.servergatway,
        "apk_config_name": data.apkConfigname,
        "apk_config_description": data.apkconfigdesc,
        "deployment_type": "PROD",
        "apk_library_version": data.apklibversion,
        "distribution_email": data.distribution_mail,
        "status_bar_colors": data.statusColor,
        "header_colors": data.actionColor,
        "accent_colors": data.accentColor,
        "use_dark_action_bar_icons": data.bar_icons,
        "user_authentication": data.user_authentication,
        "authentication_endpoint": data.authentication_endpoint,
        "apk_registration": data.apk_registration,
        "email_distribution": data.email_distribution,
        "application_type": "connect",
        "values": data.values,
        "page_title": data.page_title
      }



      setLoaderVisible(true);

      service.saveConnect(Savedata).then((res) => {
        if (res.status === 200) {
          setSuccessOpen(true);
          setLoaderVisible(false);
        }

      }).catch((error) => {
        setSuccessOpen(false);
        setErrorOpen(true);
      })

    }
  }
  const validateInfo = (data, formStep) => {

    let count = 0;
    const nwerror = { ...error };
    if (formStep === 1) {
      if (data.licensee === "") {

        nwerror.licensee = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.licensee = false;
      }

      if (data.channel === "") {

        nwerror.channel = true;
        nwerror.msg = errormsg
        count++;
      }
      else {
        nwerror.channel = false;
      }

      if (data.publisher === "") {

        nwerror.publisher = true;
        nwerror.msg = errormsg
        count++;
      }
      else {
        nwerror.publisher = false;
      }

      if (data.appname === "") {

        nwerror.appname = true;
        nwerror.nameMsg = errormsg;
        count++;
      }
      else {
        if (nameReg.test(data.appname) === true) {
          nwerror.appname = true;
          nwerror.nameMsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.appname = false;
        }

      }

      if (data.appId === "") {

        nwerror.appId = true;
        nwerror.appmsg = errormsg
        count++;
      }
      else if (appIdReg.test(data.appId) === false) {
        nwerror.appId = true;
        nwerror.appmsg = "The ID must have at least 2 segments separated by dot; each segment must start with a letter; all characters must be alphanumeric or an underscore [ a-z A-Z 0-9 _ ]"
        count++;
      }
      else {
        nwerror.appId = false;
      }

      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.iconmsg = error.iconmsg;
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.iconmsg = ""
      }

      if (error.logo_image === true) {
        nwerror.logo_image = true;
        nwerror.imgmsg = error.imgmsg;
        count++;
      }
      else {
        nwerror.logo_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg;
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }
    }


    else if (formStep === 2) {
      if (data.licensee === "") {

        nwerror.licensee = true;
        nwerror.msg = errormsg
        count++;
      }
      else {
        nwerror.channel = false;
      }
      if (data.channel === "") {

        nwerror.channel = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.channel = false;
      }

      if (data.publisher === "") {

        nwerror.publisher = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.publisher = false;
      }
      if (data.appname === "") {

        nwerror.appname = true;
        nwerror.nameMsg = errormsg;
        count++;
      }
      else {
        if (nameReg.test(data.appname) === true) {
          nwerror.appname = true;
          nwerror.nameMsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.appname = false;
        }

      }
      if (data.appId === "") {

        nwerror.appId = true;
        nwerror.appmsg = errormsg;
        count++;
      }
      else if (appIdReg.test(data.appId) === false) {
        nwerror.appId = true;
        nwerror.appmsg = "The ID must have at least 2 segments separated by dot; each segment must start with a letter; all characters must be alphanumeric or an underscore [ a-z A-Z 0-9 _ ]"
        count++;
      }
      else {
        nwerror.appId = false;
      }
      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.iconmsg = error.iconmsg;
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.iconmsg = ""
      }

      if (error.logo_image === true) {
        nwerror.logo_image = true;
        nwerror.imgmsg = error.imgmsg;
        count++;
      }
      else {
        nwerror.logo_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg;
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }

      if (data.servergatway === "") {

        nwerror.servergatway = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.servergatway = false;
      }
      if (data.apkConfigname === "") {

        nwerror.apkConfigname = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apkConfigname = false;
      }
      if (data.apkconfigdesc === "") {

        nwerror.apkconfigdesc = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apkconfigdesc = false;
      }
      if (data.apklibversion === "") {

        nwerror.apklibversion = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apklibversion = false;
      }
      if (data.distribution_mail === "") {

        nwerror.distribution_mail = true;
        nwerror.distemailmsg = errormsg;
        count++;
      }
      else if (distemail.test(data.distribution_mail) === false) {
        nwerror.distribution_mail = true;
        nwerror.distemailmsg = "You must enter a valid email, or comma separated emails";
        count++;
      }
      else {
        nwerror.distribution_mail = false;
      }
    }


    else if (formStep === 3) {
      if (data.licensee === "") {

        nwerror.licensee = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.channel = false;
      }
      if (data.channel === "") {

        nwerror.channel = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.channel = false;
      }

      if (data.publisher === "") {

        nwerror.publisher = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.publisher = false;
      }
      if (data.appname === "") {

        nwerror.appname = true;
        nwerror.nameMsg = errormsg;
        count++;
      }
      else {
        if (nameReg.test(data.appname) === true) {
          nwerror.appname = true;
          nwerror.nameMsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.appname = false;
        }

      }
      if (data.appId === "") {

        nwerror.appId = true;
        nwerror.appmsg = errormsg;
        count++;
      }
      else if (appIdReg.test(data.appId) === false) {
        nwerror.appId = true;
        nwerror.appmsg = "The ID must have at least 2 segments separated by dot; each segment must start with a letter; all characters must be alphanumeric or an underscore [ a-z A-Z 0-9 _ ]"
        count++;
      }
      else {
        nwerror.appId = false;
      }

      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.iconmsg = error.iconmsg;
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.iconmsg = ""
      }

      if (error.logo_image === true) {
        nwerror.logo_image = true;
        nwerror.imgmsg = error.imgmsg;
        count++;
      }
      else {
        nwerror.logo_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg;
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }

      if (data.servergatway === "") {

        nwerror.servergatway = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.servergatway = false;
      }
      if (data.apkConfigname === "") {

        nwerror.apkConfigname = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apkConfigname = false;
      }
      if (data.apkconfigdesc === "") {

        nwerror.apkconfigdesc = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apkconfigdesc = false;
      }
      if (data.apklibversion === "") {

        nwerror.apklibversion = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apklibversion = false;
      }
      if (data.distribution_mail === "") {

        nwerror.distribution_mail = true;
        nwerror.distemailmsg = errormsg;
        count++;
      }
      else if (distemail.test(data.distribution_mail) === false) {
        nwerror.distribution_mail = true;
        nwerror.distemailmsg = "You must enter a valid email, or comma separated emails";
        count++;
      }
      else {
        nwerror.distribution_mail = false;
      }


      if (data.actionmanual === "") {

        nwerror.actionColor = false;
        nwerror.actionmsg = "";

      }
      else {
        if (actionColorReg.test(data.actionmanual) === false) {
          nwerror.actionColor = true;
          nwerror.actionmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.actionColor = false;
          nwerror.actionmsg = "";
          data.actionColor = data.actionmanual;
        }
      }



      if (data.accentmanual === "") {

        nwerror.accentColor = false;
        nwerror.accentmsg = "";

      }
      else {

        if (accentColorReg.test(data.accentmanual) === false) {
          nwerror.accentColor = true;
          nwerror.accentmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.accentColor = false;
          nwerror.accentmsg = "";
          data.accentColor = data.accentmanual;
        }
      }



      if (data.statusmanual === "") {

        nwerror.statusbar = false;
        nwerror.colormsg = "";

      }
      else {
        if (stateColoReg.test(data.statusmanual) === false) {
          nwerror.statusbar = true;
          nwerror.colormsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.statusbar = false;
          nwerror.colormsg = "";
          data.statusColor = data.statusmanual;

        }
      }

      if (data.page_title === "") {
        nwerror.page_title = false;
        nwerror.pageMsg = "";
      }
      else {
        if (pageReg.test(data.page_title) === false) {
          nwerror.page_title = true;
          nwerror.pageMsg = "Only alphanumeric characters is allowed";
          count++;
        }
        else if ((data.page_title).length > 40) {
          nwerror.page_title = true;
          nwerror.pageMsg = "Please enter no more than 40 characters.";
          count++;
        }
        else {
          nwerror.page_title = false;
          nwerror.pageMsg = "";

        }
      }

    }


    else if (formStep === 4) {
      if (data.licensee === "") {

        nwerror.licensee = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.channel = false;
      }
      if (data.channel === "") {

        nwerror.channel = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.channel = false;
      }

      if (data.publisher === "") {

        nwerror.publisher = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.publisher = false;
      }
      if (data.appname === "") {

        nwerror.appname = true;
        nwerror.nameMsg = errormsg;
        count++;
      }
      else {
        if (nameReg.test(data.appname) === true) {
          nwerror.appname = true;
          nwerror.nameMsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.appname = false;
        }

      }
      if (data.appId === "") {

        nwerror.appId = true;
        nwerror.appmsg = errormsg;
        count++;
      }
      else if (appIdReg.test(data.appId) === false) {
        nwerror.appId = true;
        nwerror.appmsg = "The ID must have at least 2 segments separated by dot; each segment must start with a letter; all characters must be alphanumeric or an underscore [ a-z A-Z 0-9 _ ]"
        count++;
      }
      else {
        nwerror.appId = false;
      }


      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.iconmsg = error.iconmsg;
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.iconmsg = ""
      }

      if (error.logo_image === true) {
        nwerror.logo_image = true;
        nwerror.imgmsg = error.imgmsg;
        count++;
      }
      else {
        nwerror.logo_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg;
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }

      if (data.servergatway === "") {

        nwerror.servergatway = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.servergatway = false;
      }
      if (data.apkConfigname === "") {

        nwerror.apkConfigname = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apkConfigname = false;
      }
      if (data.apkconfigdesc === "") {

        nwerror.apkconfigdesc = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apkconfigdesc = false;
      }
      if (data.apklibversion === "") {

        nwerror.apklibversion = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apklibversion = false;
      }
      if (data.distribution_mail === "") {

        nwerror.distribution_mail = true;
        nwerror.distemailmsg = errormsg;
        count++;
      }
      else if (distemail.test(data.distribution_mail) === false) {
        nwerror.distribution_mail = true;
        nwerror.distemailmsg = "You must enter a valid email, or comma separated emails";
        count++;
      }
      else {
        nwerror.distribution_mail = false;
      }


      if (data.actionmanual === "") {

        nwerror.actionColor = false;
        nwerror.actionmsg = "";

      }
      else {
        if (actionColorReg.test(data.actionmanual) === false) {
          nwerror.actionColor = true;
          nwerror.actionmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.actionColor = false;
          nwerror.actionmsg = "";
          data.actionColor = data.actionmanual;
        }
      }



      if (data.accentmanual === "") {

        nwerror.accentColor = false;
        nwerror.accentmsg = "";

      }
      else {

        if (accentColorReg.test(data.accentmanual) === false) {
          nwerror.accentColor = true;
          nwerror.accentmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.accentColor = false;
          nwerror.accentmsg = "";
          data.accentColor = data.accentmanual;
        }
      }



      if (data.statusmanual === "") {

        nwerror.statusbar = false;
        nwerror.colormsg = "";

      }
      else {
        if (stateColoReg.test(data.statusmanual) === false) {
          nwerror.statusbar = true;
          nwerror.colormsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.statusbar = false;
          nwerror.colormsg = "";
          data.statusColor = data.statusmanual;

        }
      }

      if (data.page_title === "") {
        nwerror.page_title = false;
        nwerror.pageMsg = "";
      }
      else {
        if (pageReg.test(data.page_title) === false) {
          nwerror.page_title = true;
          nwerror.pageMsg = "Only alphanumeric characters is allowed";
          count++;
        }
        else if ((data.page_title).length > 40) {
          nwerror.page_title = true;
          nwerror.pageMsg = "Please enter no more than 40 characters.";
          count++;
        }
        else {
          nwerror.page_title = false;
          nwerror.pageMsg = "";

        }
      }
      /*add app*/
      for (let i = 0; i <= data.values.length; i++) {
        connectError.filter((newError, index) => {
          if (index === i) {
            if (data.values[i]?.playstore_app_identifier !== "") {
              if (data.values[i]?.application_label_vpn !== "") {
                newError.application_label_vpn = false;

              }
              else {
                newError.application_label_vpn = true;
                count++;
              }

              if (appReg.test(data.values[i]?.playstore_app_identifier) === false) {
                newError.playstore_app_identifier = true;
                newError.identifiermsg = "Please enter a valid application ID";
                count++;
              }
              else {
                newError.playstore_app_identifier = false;
                newError.identifiermsg = "";
              }

            }
            else {
              if (data.values[i]?.application_label_vpn === "") {
                newError.application_label_vpn = false;
                newError.playstore_app_identifier = false;
                newError.identifiermsg = "";

              }
              else {
                newError.application_label_vpn = true;
                count++;
              }
            }



            if (data.values[i]?.application_label_vpn !== "") {
              if (data.values[i]?.playstore_app_identifier !== "") {
                newError.application_label_vpn = false;
              }

              else {
                newError.application_label_vpn = true;
                count++;
              }
            }
            else {
              if (data.values[i]?.playstore_app_identifier === "") {
                newError.application_label_vpn = false;
              }
              else {
                newError.application_label_vpn = true;
                count++;
              }

            }

          }
        return  newError
        })


      }


    }


    else if (formStep >= 5) {
      if (data.licensee === "") {

        nwerror.licensee = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.channel = false;
      }
      if (data.channel === "") {

        nwerror.channel = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.channel = false;
      }

      if (data.publisher === "") {

        nwerror.publisher = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.publisher = false;
      }
      if (data.appname === "") {

        nwerror.appname = true;
        nwerror.nameMsg = errormsg;
        count++;
      }
      else {
        if (nameReg.test(data.appname) === true) {
          nwerror.appname = true;
          nwerror.nameMsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.appname = false;
        }

      }
      if (data.appId === "") {

        nwerror.appId = true;
        nwerror.appmsg = errormsg;
        count++;
      }
      else if (appIdReg.test(data.appId) === false) {
        nwerror.appId = true;
        nwerror.appmsg = "The ID must have at least 2 segments separated by dot; each segment must start with a letter; all characters must be alphanumeric or an underscore [ a-z A-Z 0-9 _ ]"
        count++;
      }
      else {
        nwerror.appId = false;
      }
      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.iconmsg = error.iconmsg;
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.iconmsg = ""
      }

      if (error.logo_image === true) {
        nwerror.logo_image = true;
        nwerror.imgmsg = error.imgmsg;
        count++;
      }
      else {
        nwerror.logo_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg;
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }


      if (data.servergatway === "") {

        nwerror.servergatway = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.servergatway = false;
      }
      if (data.apkConfigname === "") {

        nwerror.apkConfigname = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apkConfigname = false;
      }
      if (data.apkconfigdesc === "") {

        nwerror.apkconfigdesc = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apkconfigdesc = false;
      }
      if (data.apklibversion === "") {

        nwerror.apklibversion = true;
        nwerror.msg = errormsg;
        count++;
      }
      else {
        nwerror.apklibversion = false;
      }
      if (data.distribution_mail === "") {

        nwerror.distribution_mail = true;
        nwerror.distemailmsg = errormsg;
        count++;
      }
      else if (distemail.test(data.distribution_mail) === false) {
        nwerror.distribution_mail = true;
        nwerror.distemailmsg = "You must enter a valid email, or comma separated emails";
        count++;
      }
      else {
        nwerror.distribution_mail = false;
      }


      if (data.actionmanual === "") {

        nwerror.actionColor = false;
        nwerror.actionmsg = "";

      }
      else {
        if (actionColorReg.test(data.actionmanual) === false) {
          nwerror.actionColor = true;
          nwerror.actionmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.actionColor = false;
          nwerror.actionmsg = "";
          data.actionColor = data.actionmanual;
        }
      }



      if (data.accentmanual === "") {

        nwerror.accentColor = false;
        nwerror.accentmsg = "";

      }
      else {

        if (accentColorReg.test(data.accentmanual) === false) {
          nwerror.accentColor = true;
          nwerror.accentmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.accentColor = false;
          nwerror.accentmsg = "";
          data.accentColor = data.accentmanual;
        }
      }



      if (data.statusmanual === "") {

        nwerror.statusbar = false;
        nwerror.colormsg = "";

      }
      else {
        if (stateColoReg.test(data.statusmanual) === false) {
          nwerror.statusbar = true;
          nwerror.colormsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.statusbar = false;
          nwerror.colormsg = "";
          data.statusColor = data.statusmanual;

        }
      }

      if (data.page_title === "") {
        nwerror.page_title = false;
        nwerror.pageMsg = "";
      }
      else {
        if (pageReg.test(data.page_title) === false) {
          nwerror.page_title = true;
          nwerror.pageMsg = "Only alphanumeric characters is allowed";
          count++;
        }
        else if ((data.page_title).length > 40) {
          nwerror.page_title = true;
          nwerror.pageMsg = "Please enter no more than 40 characters.";
          count++;
        }
        else {
          nwerror.page_title = false;
          nwerror.pageMsg = "";

        }
      }

      if (data.user_authentication === "yes") {
        if (data.authentication_endpoint === "") {
          nwerror.authentication_endpoint = true;
          nwerror.Authmsg = "This field is required";
          count++;
        }
        else {
          if (apkValidReg.test(data.authentication_endpoint) === false) {
            nwerror.authentication_endpoint = true;
            nwerror.Authmsg = "Please enter a valid URL.";
            count++;
          }
          else {
            nwerror.authentication_endpoint = false;
            nwerror.Authmsg = "";
          }

        }
      }

      for (let i = 0; i <= data.values.length; i++) {
        connectError.filter((newError, index) => {
          if (index === i) {
            if (data.values[i]?.playstore_app_identifier !== "") {
              if (data.values[i]?.application_label_vpn !== "") {
                newError.application_label_vpn = false;

              }
              else {
                newError.application_label_vpn = true;
                count++;
              }

              if (appReg.test(data.values[i]?.playstore_app_identifier) === false) {
                newError.playstore_app_identifier = true;
                newError.identifiermsg = "Please enter a valid application ID";
                count++;
              }
              else {
                newError.playstore_app_identifier = false;
                newError.identifiermsg = "";
              }

            }
            else {
              if (data.values[i]?.application_label_vpn === "") {
                newError.application_label_vpn = false;
                newError.playstore_app_identifier = false;
                newError.identifiermsg = "";

              }
              else {
                newError.application_label_vpn = true;
                count++;
              }


            }



            if (data.values[i]?.application_label_vpn !== "") {
              if (data.values[i]?.playstore_app_identifier !== "") {
                newError.application_label_vpn = false;
              }

              else {
                newError.application_label_vpn = true;
                count++;
              }
            }
            else {
              if (data.values[i]?.playstore_app_identifier === "") {
                newError.application_label_vpn = false;

              }
              else {
                newError.application_label_vpn = true;
                count++;
              }

            }
          }
          return newError
        });


      }



    }
    setError(nwerror);


    return count;



  }

  const checkError = () => {
    for (let i = 0; i <= data.values.length; i++) {
      setconnectError(connectError.filter((newError, index) => {
        if (index === i) {
          if (data.values[i]?.playstore_app_identifier !== "") {
            if (data.values[i]?.application_label_vpn !== "") {
              newError.application_label_vpn = false;

            }
            else {
              newError.application_label_vpn = true;
            }

            if (appReg.test(data.values[i]?.playstore_app_identifier) === false) {
              newError.playstore_app_identifier = true;
              newError.identifiermsg = "Please enter a valid application ID";
            }
            else {
              newError.playstore_app_identifier = false;
              newError.identifiermsg = "";
            }

          }
          else {
            if (data.values[i]?.application_label_vpn === "") {
              newError.application_label_vpn = false;
              newError.playstore_app_identifier = false;
              newError.identifiermsg = "";
            }
            else {
              newError.application_label_vpn = true;
            }
            newError.playstore_app_identifier = false;
            newError.identifiermsg = "";

          }



          if (data.values[i]?.application_label_vpn !== "") {
            if (data.values[i]?.playstore_app_identifier !== "") {
              newError.application_label_vpn = false;
            }

            else {
              newError.application_label_vpn = true;
            }


          }
          else {
            if (data.values[i]?.playstore_app_identifier === "") {
              newError.application_label_vpn = false;
            }
            else {
              newError.application_label_vpn = true;
            }

          }
        }
        return newError;
      }))


    }

  }

  const handlePreviousOpen = (formStep) => {
    setFormStep(formStep - 1);
  };
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  const handleChange = (e) => {


    const { id, value } = e.target;
    const newdata = { ...data }
    newdata[id] = value;

    if (["playstore_app_identifier", "application_label_vpn"].includes(e.target.name)) {
      let values = [...data.values]
      values[e.target.dataset.id][e.target.name] = e.target.value

      checkError();

    }

    if (id === "authentication_endpoint") {
      if (data.user_authentication === "yes") {
        if (value === "") {
          setError({
            ...error,
            authentication_endpoint: true,
            Authmsg: "This field is required"
          });
        }
        else {
          if (apkValidReg.test(value) === false) {
            setError({
              ...error,
              authentication_endpoint: true,
              Authmsg: "Please enter a valid URL."
            });
          }
          else {
            setError({
              ...error,
              authentication_endpoint: false,
              Authmsg: ""
            });
          }
        }
      }
    }
    if (id === "page_title") {
      if (value === "") {
        setError({
          ...error,
          page_title: false,
          pageMsg: ""
        });
      }
      else {
        if (pageReg.test(value) === false) {
          setError({
            ...error,
            page_title: true,
            pageMsg: "Only alphanumeric characters is allowed"
          });
        }
        else if (value.length > 40) {
          setError({
            ...error,
            page_title: true,
            pageMsg: "Please enter no more than 40 characters."
          });
        }
        else {
          setError({
            ...error,
            page_title: false,
            pageMsg: ""
          });
        }
      }
    }
    if (id === "logo_image") {
      if (e.target.files.length !== 0) {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        let filePath = e.target.files[0].name;
        ;
        if (!allowedExtensions.exec(filePath)) {
          setLogo({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            logo_image: true,
            imgmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 512 && img.height >= 512) {

              setLogo({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],

              });
              setError({
                ...error,
                logo_image: false,
                imgmsg: ""
              });
              UploadLogoImage(e.target.files[0]);
            }
            else if (img.width <= 512) {
              setLogo({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_image: true,
                imgmsg: "Minimum width must be 512px"
              });
            }
            else if (img.height <= 512) {
              setLogo({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_image: true,
                imgmsg: "Minimum height must be 512px"
              });
            }
          };
        }
      }
      else {
        setLogo({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          logo_image: false,
          imgmsg: ""
        });

      }
    }

    if (id === "icon_image") {
      if (e.target.files.length !== 0) {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        let filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setIcon({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            icon_image: true,
            iconmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 512 && img.height >= 512) {

              setIcon({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                icon_image: false,
                iconmsg: ""
              });
              UploadIconImage(e.target.files[0])
            }
            else if (img.width <= 512) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                iconmsg: "Minimum width must be 512px"
              });
            }
            else if (img.height <= 512) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                iconmsg: "Minimum height must be 512px"
              });
            }
          };
        }
      }
      else {
        setIcon({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          icon_image: false,
          iconmsg: ""
        });

      }
    }

    if (id === "splash_image") {
      if (e.target.files.length !== 0) {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        let filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setSplash({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            splash_image: true,
            splashmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 1080 && img.height >= 1920) {

              setSplash({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                splash_image: false,
                splashmsg: ""
              });
              UploadSplashImage(e.target.files[0]);
            }
            else if (img.width <= 1080) {
              setSplash({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                splash_image: true,
                splashmsg: "Minimum width must be 1080px"
              });
            }
            else if (img.height <= 1920) {
              setSplash({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                splash_image: true,
                splashmsg: "Minimum height must be 1920px"
              });
            }
          };
        }
      }
      else {
        setSplash({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          splash_image: false,
          splashmsg: ""
        });

      }
    }


    if (id === "statusmanual") {
      if (value) {

        if (stateColoReg.test(e.target.value) === false) {

          setError({
            ...error,
            statusbar: true,
            colormsg: "Please enter a valid color"
          });
        }
        else {
          setError({
            ...error,
            statusbar: false,
            colormsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          statusbar: false,
          colormsg: ""
        });
      }

    }


    if (id === "actionmanual") {
      if (value) {

        if (actionColorReg.test(e.target.value) === false) {

          setError({
            ...error,
            actionColor: true,
            actionmsg: "Please enter a valid color"
          });
        }
        else {
          setError({
            ...error,
            actionColor: false,
            actionmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          actionColor: false,
          actionmsg: ""
        });
      }

    }

    if (id === "accentmanual") {
      if (value) {

        if (accentColorReg.test(e.target.value) === false) {

          setError({
            ...error,
            accentColor: true,
            accentmsg: "Please enter a valid color"
          });
        }
        else {
          setError({
            ...error,
            accentColor: false,
            accentmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          accentColor: false,
          accentmsg: ""
        });
      }

    }


    if (id === "licensee") {
      ChannelName(e.target.value);
      GatewayList(e.target.value);
      if (!value) {
        setError({
          ...error,
          licensee: true,
          msg: errormsg
        });
      }
      else {
        setError({
          ...error,
          licensee: false
        });
      }

    }

    if (id === "channel") {
      PublisherName(e.target.value);
      if (!value) {
        setError({
          ...error,
          channel: true,
          msg: errormsg
        });
      } else {
        setError({
          ...error,
          channel: false,
        });
      }


    }

    if (id === "publisher") {
      if (!value) {
        setError({
          ...error,
          publisher: true,
          msg: errormsg
        });
      } else {
        setError({
          ...error,
          publisher: false,
        });
      }

    }

    if (id === "appname") {
      if (!value) {
        setError({
          ...error,
          appname: true,
          nameMsg: errormsg
        });
      } else {
        if (nameReg.test(value) === true) {
          setError({
            ...error,
            appname: true,
            nameMsg: "Please enter a name without comma"
          });
        }
        else {
          setError({
            ...error,
            appname: false
          });
        }
      }
    }

    if (id === "appId") {
      if (!value) {
        setError({
          ...error,
          appId: true,
          appmsg: errormsg
        });
      } else if (appIdReg.test(e.target.value) === false && e.target.value !== "") {
        setError({
          ...error,
          appId: true,
          appmsg: "The ID must have at least 2 segments separated by dot; each segment must start with a letter; all characters must be alphanumeric or an underscore [ a-z A-Z 0-9 _ ]"
        });
      }

      else {
        setError({
          ...error,
          appId: false,
        });
      }
    }

    if (id === "servergatway") {
      displayDescription(e.target.value);
      if (!value) {
        setError({
          ...error,
          servergatway: true,
          msg: errormsg
        });
      } else {
        setError({
          ...error,
          servergatway: false
        });
      }
    }


    if (id === "apkConfigname") {
      if (!value) {
        setError({
          ...error,
          apkConfigname: true,
          msg: errormsg
        });
      } else {
        setError({
          ...error,
          apkConfigname: false
        });
      }
    }

    if (id === "apkconfigdesc") {
      if (!value) {
        setError({
          ...error,
          apkconfigdesc: true,
          msg: errormsg
        });
      } else {
        setError({
          ...error,
          apkconfigdesc: false
        });
      }
    }

    if (id === "apklibversion") {
      if (!value) {
        setError({
          ...error,
          apklibversion: true,
          msg: errormsg
        });
      } else {
        setError({
          ...error,
          apklibversion: false
        });
      }
    }

    if (id === "distribution_mail") {
      if (!value) {
        setError({
          ...error,
          distribution_mail: true,
          distemailmsg: errormsg
        });
      } else if (distemail.test(e.target.value) === false && e.target.value !== "") {
        setError({
          ...error,
          appId: true,
          distemailmsg: "You must enter a valid email, or comma separated emails"
        });
      } else {
        setError({
          ...error,
          distribution_mail: false
        });
      }
    }
    setData(newdata);

  }



  function showStep(formStep) {

    switch (formStep) {
      case 1:
        return <ConnectCreateFirstStep
          NextFormStep={NextFormStep}
          handleChange={handleChange}
          data={data}
          error={error}
          logo={logo}
          icon={icon}
          splash={splash}
          generateAppId={generateAppId}
          LicenseeData={LicenseeData}
          ChannelData={ChannelData}
          PublisherData={PublisherData}
          loaderVisible={loaderVisible}
        />;

      case 2:
        return <ConnectCreateSecondStep
          NextFormStep={NextFormStep}
          handlePreviousOpen={handlePreviousOpen}
          data={data}
          error={error}
          handleChange={handleChange}
          VersionList={VersionList}
          GatewayData={GatewayData}
          GatewayDescription={GatewayDescription}
          UserData={UserData}
        />;


      case 3:
        return <ConnectCreateThirdStep
          NextFormStep={NextFormStep}
          handlePreviousOpen={handlePreviousOpen}
          data={data}
          error={error}
          handleChange={handleChange}
        />;

      case 4:
        return <ConnectCreateFourthStep
          NextFormStep={NextFormStep}
          handlePreviousOpen={handlePreviousOpen}
          data={data}
          error={error}
          handleChange={handleChange}
          addRow={addRow}
          deleteRow={deleteRow}
          connectError={connectError}
        />;

      case 5:
        return <ConnectCreateFifthStep
          NextFormStep={NextFormStep}
          handlePreviousOpen={handlePreviousOpen}
          data={data}
          error={error}
          handleChange={handleChange}
        />;

      case 6:
        return <ConnectCreateSixthStep
          handlePreviousOpen={handlePreviousOpen}
          data={data}
          handleChange={handleChange}
          RegisterApplication={RegisterApplication}
          successOpen={successOpen}
          errorOpen={errorOpen}
          loaderVisible={loaderVisible}
          handleAlertClose={handleAlertClose}
        />;

    }
  }
  useEffect(() => {
    showStep(formStep);
  }, [formStep])

  useEffect(() => {
    localStorage.setItem("connectSave", false)
    getLicensee();
    getVersions();
    getUserMail();
    UploadSplashDefault();
    UploadLogoDefault();
    UploadIconDefault();
  }, [])
  return (
    <div className="directcontainer">
      <div className="NavBoxRow">
        <div className="NavBoxRowText">#datafree Connect Application Creation</div>

      </div>

      <div style={{ marginBottom: '10px' }}></div>
      {showStep(formStep)}

    </div>

  )
}

export default ConnectCreate;
