import React, { useState, useEffect ,useContext} from "react";
import {
  Tabs,
  Tab,
  Dialog,
  Box,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import service from "../../../../../api/service";
import { trackPromise } from "react-promise-tracker";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Loader from "react-loading";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { deploymentDispatchContext,appDetailDispatchContext} from "../../../../../api/context"

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AntTabs = Tabs;
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


function ReachProduction(props) {
  const classes = useStyles();
  const [deleteopen, setDeleteOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const deploymentDispatchContextValue = useContext(deploymentDispatchContext)
  const [editInfo, setEditInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const appDetailDispatchContextValue = useContext(appDetailDispatchContext)
  const [applicationData, setApplicationData] = useState(false);
  const handleClose = () => {
    setEditInfo(false);
    setDeleteOpen(false);
    setOpen(false);
    setSecurityError({
      monthly_data: false,
      alert_email: false,
      include_daily_dataFree_limit: false,
      max_daily_spend: false,
      action_required: false,
      max_bytes_content: false,
      max_bytes_per_content_hidden: false,
      max_bytes_per_content_changed: false,
      content_type: false,
      contentMsg: "",
      mimeMsg: "",
      monthlyMsg: "",
      emailMsg: "",
      typeMsg: ""
    })
    getApplicationDetails();
    setClear(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const edit = () => {
    setEditInfo(true);
  };

  const getApplicationDetails = () => {

    service.applicationData(props.applicationId).then((res) => {
      setApplicationData(res.data.applicationObj);
      deploymentDispatchContextValue({type:res?.data?.applicationObj?.deploymentDetails?.deploymentType[0]})
      appDetailDispatchContextValue(res?.data?.applicationObj)
      res?.data?.applicationObj?.prod?.securityRules?.blockedContent?.mime?.map((item, index) => (
        setSecurityEdit({
          ...securityEdit,
          monthly_data: res.data.applicationObj?.prod?.applicationId?.appDataUsageAlert,
          alert_email: res.data.applicationObj?.prod?.applicationId?.alertEmail,
          action_required: res.data.applicationObj?.prod?.applicationId?.maxBytesPerDayAction,
          max_bytes_content: res.data.applicationObj?.prod?.securityRules?.contentSize,
          max_daily_spend: res.data.applicationObj?.prod?.applicationId?.maxBytesPerDay,
          content_type: item ? item : "",
          max_bytes_per_content_changed: 'no',
          max_bytes_per_content_hidden: res.data.applicationObj?.prod?.securityRules?.contentSize
        })))
    
      if (res.data.applicationObj?.prod?.applicationId?.maxBytesPerDayAction === 1) {
        setChecked(true)
      } else {
        setChecked(false)

      }

    }).catch((err) => {

    })

  };

  const [reachData, setReachData] = useState({
    website_url: "",
    max_daily_spend: "",
    alert_email: "",
    max_bytes_content: "",
    content_type: "",
    application_id: props.applicationId,
    appRegisterType: "prod",
    application_type: "reach"
  });
  const [error, setError] = useState({
    website_url: false,
    max_daily_spend: false,
    alert_email: false,
    max_bytes_content: false,
    content_type: false,
    webMsg: "",
    speedMsg: "",
    emailMsg: "",
    contentMsg: "",
    mimeMsg: ""
  });

  
  const webReg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const speedReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const contentReg = /^[0-9]*$/;
  const emailReg = /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/i;
  const mimeReg = /^[a-z]+\/(\*|[a-z]+[-+.a-z0-9]*)$/m;
  const monthlyReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i
  const handleData = (e) => {
    const newdata = { ...reachData };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "website_url") {
      if (e.target.value) {
        if (webReg.test(e.target.value) === false) {
          setError({
            ...error,
            website_url: true,
            webMsg: "Please enter a valid URL."
          });
        }
        else {
          setError({
            ...error,
            website_url: false,
            webMsg: ""
          });
        }
      } else {
        setError({
          ...error,
          website_url: true,
          webMsg: "This field is required"
        })
      }
    }

    if (e.target.id === "content_type") {
      if (e.target.value) {
        var contentTypeValue = e.target.value;
        var modifiedContentTypeValue = '';
        if (contentTypeValue !== '') {
          var originalContentType = contentTypeValue.split('\n');
          for (var i = 0; i < originalContentType.length; i++) {
            if (i === originalContentType.length - 1) {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*';
                } else {
                  modifiedContentTypeValue += originalContentType[i];
                }
              }
            } else {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*' + '\n';
                } else {
                  modifiedContentTypeValue += originalContentType[i] + '\n';
                }
              }
            }
          }

        }

        if (mimeReg.test(modifiedContentTypeValue) === false) {
          setError({
            ...error,
            content_type: true,
            mimeMsg: "Please enter a valid content type."
          });
        }
        else {
          setError({
            ...error,
            content_type: false,
            mimeMsg: ""
          });
          e.target.value = modifiedContentTypeValue;
          newdata[e.target.id] = e.target.value;
        }

      }
      else {
        setError({
          ...error,
          content_type: false,
          mimeMsg: ""
        })
      }
    }

    if (e.target.id === "max_daily_spend") {
      if (e.target.value) {
        if (speedReg.test(e.target.value) === false) {
          setError({
            ...error,
            max_daily_spend: true,
            speedMsg: "Invalid Format."
          });
        }
        else {
          setError({
            ...error,
            max_daily_spend: false,
            speedMsg: ""
          })
        }

      }
      else {
        setError({
          ...error,
          max_daily_spend: false,
          speedMsg: ""
        })
      }
    }

    if (e.target.id === "alert_email") {
      if (e.target.value) {
        if (emailReg.test(e.target.value) === false) {
          setError({
            ...error,
            alert_email: true,
            emailMsg: "Warning: No message defined for alert_email"
          });
        }
        else {
          setError({
            ...error,
            alert_email: false,
            emailMsg: ""
          })
        }

      }
      else {
        setError({
          ...error,
          alert_email: false,
          emailMsg: ""
        })
      }
    }

    if (e.target.id === "max_bytes_content") {
      if (e.target.value) {
        if (contentReg.test(e.target.value) === false) {
          setError({
            ...error,
            max_bytes_content: true,
            contentMsg: "Please enter only digits."
          });
        }
        else {
          setError({
            ...error,
            max_bytes_content: false,
            contentMsg: ""
          })
        }

      }
      else {
        setError({
          ...error,
          max_bytes_content: false,
          contentMsg: ""
        })
      }
    }

    setReachData(newdata);
  }

  const validateReach = (reachData) => {
    let rCount = 0;
    let nwerror = { ...error }
    if (reachData.website_url === "") {
      nwerror.website_url = true;
      nwerror.webMsg = "This field is required";
      rCount++;
    }
    else {
      if (webReg.test(reachData.website_url) === false) {
        nwerror.website_url = true;
        nwerror.webMsg = "Please enter a valid URL.";
        rCount++;
      }
      else {
        nwerror.website_url = false;
        nwerror.webMsg = ""
      }
    }

    if (reachData.max_daily_spend === "") {
      nwerror.max_daily_spend = false;
      nwerror.speedMsg = "";
    }
    else {
      if (speedReg.test(reachData.max_daily_spend) === false) {
        nwerror.max_daily_spend = true;
        nwerror.speedMsg = "Invalid Format.";
        rCount++;
      }
      else {
        nwerror.max_daily_spend = false;
        nwerror.speedMsg = ""
      }
    }

    if (reachData.alert_email === "") {
      nwerror.alert_email = false;
      nwerror.emailMsg = "";
    }
    else {
      if (emailReg.test(reachData.alert_email) === false) {
        nwerror.alert_email = true;
        nwerror.emailMsg = "Warning: No message defined for alert_email";
        rCount++;
      }
      else {
        nwerror.alert_email = false;
        nwerror.emailMsg = ""
      }
    }

    if (reachData.max_bytes_content === "") {
      nwerror.max_bytes_content = false;
      nwerror.contentMsg = "";
    }
    else {
      if (contentReg.test(reachData.max_bytes_content) === false) {
        nwerror.max_bytes_content = true;
        nwerror.contentMsg = "Please enter only digits.";
        rCount++;
      }
      else {
        nwerror.max_bytes_content = false;
        nwerror.contentMsg = ""
      }
    }

    if (reachData.content_type === "") {
      nwerror.content_type = false;
      nwerror.mimeMsg = "";
    }
    else {

      if (mimeReg.test(reachData.content_type) === false) {
        nwerror.content_type = true;
        nwerror.mimeMsg = "Please enter a valid content type.";
        rCount++;
      }
      else {
        nwerror.content_type = false;
        nwerror.mimeMsg = ""
      }
    }

    setError(nwerror);
    return rCount;
  }
  const getGenerateApp = () => {
    setLoading(true)
    service.generateApp({
      "applicationId": props?.applicationId,
      "type": props?.deployType
    }).then((res) => {
      setLoading(false)

      if (res?.data?.hasAppId === 'Yes') {
        setHasApp(true);
      }
      else {
        setHasApp(false);
      }
    }).catch((err) => {

    })

  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [clear, setClear] = useState(false);
  const [hasApp, setHasApp] = useState(false);
  const [clickSave, setClickSave] = useState();
  const [loadings, setLoadings] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const SaveReach = (e) => {
    e.preventDefault();

    const reachCount = validateReach(reachData);


    if (reachCount === 0) {
      setClickSave(true);
      setLoadings(true);
      try {
        trackPromise(
          service.saveReachProd(reachData).then((res) => {
            setLoadings(false);
            setClickSave(false);
            setOpen(false);
            getGenerateApp();
            getApplicationDetails();
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page");
        seterrorOpen(true);
      }
    }
  }
  const [securityEdit, setSecurityEdit] = useState({
    monthly_data: "",
    alert_email: "",
    include_daily_dataFree_limit: "",
    max_daily_spend: "",
    action_required: "",
    max_bytes_content: "",
    max_bytes_per_content_hidden: "",
    max_bytes_per_content_changed: "",
    content_type: "",
    application_id: ""
  })
  const [securityError, setSecurityError] = useState({
    monthly_data: false,
    alert_email: false,
    include_daily_dataFree_limit: false,
    max_daily_spend: false,
    action_required: false,
    max_bytes_content: false,
    max_bytes_per_content_hidden: false,
    max_bytes_per_content_changed: false,
    content_type: false,
    contentMsg: "",
    mimeMsg: "",
    monthlyMsg: "",
    emailMsg: "",
    typeMsg: ""
  })
  const validateProdReach = (securityEdit) => {

    let rCount = 0;
    let nwerror = { ...securityError }

    if (securityEdit.monthly_data === "") {
      nwerror.monthly_data = false;
      nwerror.monthlyMsg = "";
    }
    else {
      if (monthlyReg.test(securityEdit.monthly_data) === false) {
        nwerror.monthly_data = true;
        nwerror.monthlyMsg = "An Alert Email will be sent to your alert email address if the Monthly Forecast Data Usage exceeds this";
        rCount++;
      }
      else {
        nwerror.monthly_data = false;
        nwerror.monthlyMsg = ""
      }
    }

    if (securityEdit.alert_email === "") {
      nwerror.alert_email = false;
      nwerror.emailMsg = "";

    }
    else {

      if (emailReg.test(securityEdit.alert_email) === false) {
        nwerror.alert_email = true;
        nwerror.emailMsg = "Not valid emails";
        rCount++;
      }
      else {
        nwerror.alert_email = false;
        nwerror.emailMsg = ""
      }
    }

    if (securityEdit.max_bytes_content === "") {
      nwerror.max_bytes_content = false;
      nwerror.contentMsg = "";
    }
    else {
      if (contentReg.test(securityEdit.max_bytes_content) === false) {
        nwerror.max_bytes_content = true;
        nwerror.contentMsg = "Invalid Format.";
        rCount++;
      }
      else {
        nwerror.max_bytes_content = false;
        nwerror.contentMsg = ""
      }
    }
    if (securityEdit.content_type === "") {
      nwerror.content_type = false;
      nwerror.mimeMsg = "";
    }
    else {

      if (mimeReg.test(securityEdit.content_type) === false) {
        nwerror.content_type = true;
        nwerror.mimeMsg = "Please enter a valid content type.";
        rCount++;
      }
      else {
        nwerror.content_type = false;
        nwerror.mimeMsg = ""
      }
    }
    setSecurityError(nwerror);
    return rCount;

  }

  const saveSecurity = () => {

    const reachCount = validateProdReach(securityEdit);
    securityEdit['application_id'] = props?.applicationId;
    if (checked) {
      securityEdit['include_daily_dataFree_limit'] = 1
    }
    else {
      securityEdit['include_daily_dataFree_limit'] = 0
      securityEdit['max_daily_spend'] = '';
      securityEdit['action_required'] = 0
    }


    if (reachCount === 0) {

      service.ReachProdSecuritySave(securityEdit).then((res) => {

        if (res?.status === 200) {
          getApplicationDetails();
          setEditInfo(false);

          handleClose();
        }

      })
    }


  }
  const launchUrl = (appId) => {

    let baseAPI_URL = process.env.REACT_APP_API_URL;
    let getToken = localStorage.getItem('token')
    let subbaseUrl = baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path = baseAPI_URL.split(hostName[0]).join("qamonitor");
    //window.open(path + "/login/" + appId);
   window.open(baseAPI_URL + "/api/sso?token=" + getToken +"&&redirectto="+path + "/login/" + appId);
  }
  const launchVisualizerUrl = () => {
    let baseAPI_URL = process.env.REACT_APP_BASE_URL;
    let subbaseUrl = baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path = baseAPI_URL.split(hostName[0]).join("qamonitor")

    window.open(path + "/run");
  }


  const contentChange = (e) => {

    const newdata = { ...securityEdit, [e.target.id]: e.target.value };

    if (e.target.id === "max_bytes_content") {
      if (e.target.value) {
        newdata['max_bytes_per_content_changed'] = 'yes'
        newdata['max_bytes_per_content_hidden'] = e.target.value
        if (contentReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            max_bytes_content: true,
            contentMsg: "Invalid Format."
          });
        }
        else {
          setSecurityError({
            ...securityError,
            max_bytes_content: false,
            contentMsg: ""
          })
        }

      }
      else {
        newdata['max_bytes_per_content_changed'] = 'no'
        newdata['max_bytes_per_content_hidden'] = securityEdit?.max_bytes_content
        setSecurityError({
          ...securityError,
          max_bytes_content: false,
          contentMsg: ""
        })
      }
    }
    if (e.target.id === "content_type") {
      if (e.target.value) {
        var contentTypeValue = e.target.value;
        var modifiedContentTypeValue = '';
        if (contentTypeValue !== '') {
          var originalContentType = contentTypeValue.split('\n');
          for (var i = 0; i < originalContentType.length; i++) {
            if (i === originalContentType.length - 1) {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*';
                } else {
                  modifiedContentTypeValue += originalContentType[i];
                }
              }
            } else {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*' + '\n';
                } else {
                  modifiedContentTypeValue += originalContentType[i] + '\n';
                }
              }
            }
          }

        }

        if (mimeReg.test(modifiedContentTypeValue) === false) {
          setSecurityError({
            ...securityError,
            content_type: true,
            mimeMsg: "Please enter a valid content type."
          });
        }
        else {
          setSecurityError({
            ...securityError,
            content_type: false,
            mimeMsg: ""
          });
          e.target.value = modifiedContentTypeValue;
          newdata[e.target.id] = e.target.value;
        }

      }
      else {
        setSecurityError({
          ...securityError,
          content_type: false,
          mimeMsg: ""
        })
      }
    }
    if (e.target.id === "alert_email") {
      if (e.target.value) {
        if (emailReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            alert_email: true,
            emailMsg: "Not valid emails"
          });
        }
        else {
          setSecurityError({
            ...securityError,
            alert_email: false,
            emailMsg: ""
          })
        }

      }
      else {
        setSecurityError({
          ...securityError,
          alert_email: true,
          emailMsg: "Required"
        })
      }
    }
    if (e.target.id === "monthly_data") {
      if (e.target.value) {
        if (monthlyReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            monthly_data: true,
            monthlyMsg: "An Alert Email will be sent to your alert email address if the Monthly Forecast Data Usage exceeds this"
          });
        }
        else {
          setSecurityError({
            ...securityError,
            monthly_data: false,
            monthlyMsg: ""
          })
        }

      }
      else {
        setSecurityError({
          ...securityError,
          monthly_data: false,
          monthlyMsg: ""
        })
      }
    }
    setSecurityEdit(newdata);
  }

  const clearValue = (e) => {
    setClear(true);
    if (e.target.id === 'max_daily_spend') {
      const newdata = { ...securityEdit, [e.target.id]: e.target.value };
      document.getElementById('max_daily_spend').value = "";
      setSecurityEdit(newdata);

    }

  }

  useEffect(() => {
    getApplicationDetails();
    document.title = "Datafree Portal";
    getGenerateApp();
  }, []);
  return (
    <div style={{ minHeight: '400px' }}>
      <div className="dialoguRow">
        <div className="devHead"></div>

        <div className="devmainHead">Production #datafree Website</div>
        <div className="devHead"></div>
      </div>
      {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
      {!loading && hasApp ? (
        <div className="developmentTab">
          <div className="developTabContent">
            <div className="developTabContenthead">
              biNu ID :{" "}
              <span>
                {applicationData ? applicationData?.prod?.appId : ""}
              </span>
            </div>
            <div className="developTabContenthead">
              Current (PAID) Start URL :{" "}
              <span>
                {applicationData
                  ? applicationData?.prod?.reachStartUrl
                  : ""}
              </span>
            </div>
            <div className="developTabContenthead">
              #datafree Domain :
              <span>
                {applicationData
                  ? applicationData?.prod?.applicationId
                    .reachSubDomain +
                  "." +
                  applicationData?.prod?.applicationId?.reachGateway
                  : ""}
              </span>
            </div>

            <div className="developToolsTabContenthead">Tools:</div>

            <div style={{ textAlign: "left", marginLeft: "10px" }}>
              <button className="workbench" onClick={() => launchUrl(applicationData?.prod?.appId)} onFocus={() => launchUrl(applicationData?.prod?.appId)}>
                Launch Website #datafree Workbench
              </button>
            </div>
            <div style={{ textAlign: "left", marginLeft: "10px" }}>
              <button className="workbench" onClick={() => launchVisualizerUrl()}>
                Launch Website #datafree Visualiser
              </button>
            </div>


          </div>

          <div className="developMaxTabContent">
            <AntTabs
              value={value}
              onChange={handleChange}
              className="tabChange tabViewMenu"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <AntTab
                label="Security"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              />
            </AntTabs>

            <TabPanel value={value} index={0}>

              <div className="developmentSecurityTab">
                <button className={!editInfo ? "editbtn" : "NotEdit"} onClick={edit}>
                  Edit
                </button>

                <button className={editInfo ? "editbtn" : "NotEdit"} onClick={handleClose}>
                  Cancel
                </button>

                <button className={editInfo ? "editbtn" : "NotEdit"} onClick={saveSecurity}>Save</button>
              </div>

              <div className="InputflexRow">
                <div className="entrypointLabel" style={{ width: '30%' }}>
                  Monthly App #datafree Data Alert (bytes){" "}
                </div>
                {!editInfo ?
                  <div style={{ width: "40%" }}> <input
                    type="text"
                    className="entryinputField"
                    name="name"
                    disabled="disabled"
                    style={{ marginLeft: '1%' }}
                    value={applicationData
                      ? applicationData?.prod?.applicationId?.appDataUsageAlert
                      : ""}
                  /></div>
                  :
                  <div style={{ width: "40%" }}> <input
                    type="text"
                    className={securityError?.monthly_data ? "editentryinputField errEdit" : "editentryinputField"}
                    name="monthly_data"
                    id="monthly_data"
                    onChange={(e) => contentChange(e)}
                    onBlur={(e) => contentChange(e)}
                    defaultValue={applicationData?.prod?.applicationId?.appDataUsageAlert
                    }
                    style={{ marginLeft: '1%' }}
                  /> {securityError.monthly_data ? (
                    <div className="errorClass">{securityError?.monthlyMsg}</div>
                  ) : null}
                  </div>
                }

              </div>

              <div className="InputflexRow">
                <div className="entrypointLabel">
                  Alert Email Addresses (comma separated){" "}
                </div>
                {!editInfo ?
                  <div style={{ textAlign: "right" }}><input
                    type="text"
                    className="entryinputField"
                    name="name"
                    disabled="disabled"

                    value={applicationData
                      ? applicationData?.prod?.applicationId?.alertEmail
                      : ""}
                  /></div>
                  :
                  <div style={{ textAlign: "right" }}> <input
                    type="text"
                    className={securityError?.alert_email ? "editentryinputField errEdit" : "editentryinputField"}
                    name="alert_email"
                    id="alert_email"
                    onChange={(e) => contentChange(e)}
                    onBlur={(e) => contentChange(e)}
                    defaultValue={applicationData
                      ? applicationData?.prod?.applicationId?.alertEmail
                      : ""}
                  />{securityError.alert_email ? (
                    <div className="errorClass">{securityError?.emailMsg}</div>
                  ) : null}
                  </div>
                }

              </div>
              <div className="InputflexRow">
                <div className="entrypointLabel" style={{ width: '32%' }}>
                  Include Daily #datafree Data Limit Alerting ?
                </div>
                <input type="checkbox" name="include_daily_dataFree_limit" id="include_daily_dataFree_limit" value="Self"
                  disabled={!editInfo ? "disabled" : false}
                  defaultChecked={applicationData?.prod?.applicationId?.maxBytesPerDayAction === 1 ? true : false}
                  onChange={() => setChecked(!checked)} className="dailyLimit" />
              </div>
              {checked ? (
                <div className="borderDiv">
                  <div className="InputflexRow">
                    <div className="entrypointLabel" style={{ width: '31%' }}>
                      Daily #datafree data limit (bytes)
                    </div>
                    {!editInfo ?
                      <input
                        type="text"
                        className="entryinputField"
                        name="name"
                        disabled="disabled"

                        style={{ marginLeft: '0%' }}
                        value={clear ? "" : securityEdit?.max_daily_spend}
                      />
                      :
                      <input
                        type="text"
                        className="editentryinputField"
                        name="max_daily_spend"
                        id="max_daily_spend"
                        onChange={(e) => contentChange(e)}
                        onBlur={(e) => contentChange(e)}
                        defaultValue={applicationData?.prod?.applicationId?.maxBytesPerDay
                        }
                        style={{ marginLeft: '0%' }}
                      />
                    }
                    <button
                      type="button"
                      className={!editInfo ? "clearButton" : "clearblueButton"}
                      name="max_daily_spend"
                      id="max_daily_spend"
                      value=""
                      disabled={!editInfo ? "disabled" : false}

                      onClick={(e) => clearValue(e)}
                    >Clear Value</button>
                  </div>

                  <div className="InputflexRow">
                    <div className="entrypointLabel" style={{ width: '30%' }}>
                      Required Action on Daily Limit
                    </div>
                    {!editInfo ? (
                      <select
                        type="text"
                        className="entyPointfield"
                        name="name"
                        disabled="disabled"
                        style={{ marginLeft: '6%', height: '37px' }}

                      >
                        <option value="0" selected={applicationData?.prod?.applicationId?.maxBytesPerDayAction === 0 && true}>Send Alerts Only</option>
                        <option value="1" selected={applicationData?.prod?.applicationId?.maxBytesPerDayAction === 1 && true}>Send Alerts and Stop App</option>
                      </select>) :
                      <select
                        type="text"
                        className="editentyPointfield"
                        name="action_required"
                        id="action_required"
                        style={{ marginLeft: '1%', height: '37px' }}
                        onChange={(e) => contentChange(e)}
                        onBlur={(e) => contentChange(e)}
                      >
                        <option value="0" selected={applicationData?.prod?.applicationId?.maxBytesPerDayAction === 0 && true}>Send Alerts Only</option>
                        <option value="1" selected={applicationData?.prod?.applicationId?.maxBytesPerDayAction === 1 && true}>Send Alerts and Stop App</option>
                      </select>}
                  </div>
                </div>) : null}
              <div className="InputflexRow">
                <div className="entrypointLabel">
                  Max Bytes per content object
                </div>
                {!editInfo ? (
                  <div style={{ textAlign: "right" }}> <input
                    type="text"
                    className="entryinputField"
                    name="name"
                    disabled="disabled"

                    value={
                      applicationData ? applicationData?.prod?.securityRules?.contentSize : ""
                    }
                  /></div>
                ) :
                  <div style={{ textAlign: "right" }}><input
                    type="text"
                    className={securityError?.max_bytes_content ? "editentryinputField errEdit" : "editentryinputField"}
                    name="max_bytes_content"
                    id="max_bytes_content"
                    onChange={(e) => contentChange(e)}
                    onBlur={(e) => contentChange(e)}
                    defaultValue={
                      applicationData ? applicationData?.prod?.securityRules?.contentSize : ""
                    }
                  />{securityError.max_bytes_content ? (
                    <div className="errorClass">{securityError?.contentMsg}</div>
                  ) : null}
                  </div>}

              </div>

              <div className="entypointflex">
                <div className="companyLabel">Content Types Blocked (Mime Types)</div>

                <div>
                  {!editInfo ? (
                    <textarea
                      type="text"
                      className="entyPointfield"
                      name="name"
                      disabled="disabled"
                      style={{ height: '75px' }}
                      value={applicationData?.prod?.securityRules?.blockedContent?.mime?.map((item, index) => (
                        item ? item : ""
                      ))}
                      placeholder="Enter the list of Mime Types to be blocked from #datafree delivery"
                    />
                  ) : (
                    <textarea
                      type="text"
                      defaultValue={applicationData?.prod?.securityRules?.blockedContent?.mime?.map((item, index) => (
                        item ? item : ""
                      ))}
                      className={securityError?.max_bytes_content ? "editentyPointfield errEdit" : "editentyPointfield"}
                      style={{ height: '75px' }}
                      name="content_type"
                      id="content_type"
                      onChange={(e) => contentChange(e)}
                      placeholder="Enter the list of Mime Types to be blocked from #datafree delivery"
                    />
                  )}

                </div>
                {securityError.content_type ? (
                  <div className="errorClass">{securityError?.mimeMsg}</div>
                ) : null}
              </div>
              <div className="entypointflex">
                <div className="companyLabel">#datafree Gateway domain</div>

                <div>

                  <input
                    type="text"
                    className="entyPointfield"
                    name="name"
                    disabled="disabled"
                    value={applicationData
                      ? applicationData?.prod?.applicationId?.reachGateway
                      : ""}
                  />
                </div>
              </div>
            </TabPanel>

          </div>
        </div>
      ) : (
        !loading && !hasApp ?
          <div className="configDefinebtn" onClick={() => setOpen(true)}>
            + Create Production App Configuration and Define Entry Point
          </div> : null
      )}

      <Dialog
        open={deleteopen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="deletedialoguettitle">
          WARNING!
        </DialogTitle>
        <DialogContent>
          <div>
            Are you sure you want to delete this #datafree Website Registration
            ? This cannot be undone.
          </div>
        </DialogContent>
        <DialogActions>
          <div className="iviteRow">
            <button
              className="deletecancelbtn"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <div className="flexSpace"></div>
            <button className="deletecancelbtn" color="primary">
              Confirm Deletion
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">

          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> Register Production Website </div>
            <div className="cmpdialogtitle" name="popup">
              <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">The website’s current start URL</div>

          <input
            type="text"
            id="website_url"
            className={!error.website_url ? "dialoginput" : "errdialoginput"}
            placeholder="Enter the website start url"
            fullwidth="true"
            onChange={handleData}
          />
          {error.website_url ? (
            <div className="errorClass">{error.webMsg}</div>
          ) : null}
          <div className="dialoginputtext" style={{paddingTop:'10px',paddingBottom:'10px'}}>
          Content access details - OPTIONAL (can be defined later)
          </div>
          <div className="dialoginputtext">
            Max #datafree Bytes Per Day (no more pages will be served when
            reached)
          </div>
          <input
            type="text"
            id="max_daily_spend"
            className={!error.max_daily_spend ? "dialoginput" : "errdialoginput"}
            placeholder="Enter the max #datafree bytes per day"
            onBlur={handleData}
          />
          {error.max_daily_spend ? (
            <div className="errorClass">{error.speedMsg}</div>
          ) : null}
          <div className="dialoginputtext">
            Alert Email Addresses (comma separated)
          </div>
          <input
            type="text"
            id="alert_email"
            className={!error.alert_email ? "dialoginput" : "errdialoginput"}
            onBlur={handleData}
          />
          {error.alert_email ? (
            <div style={{ fontWeight: '600' }} className="errorClass">{error.emailMsg}</div>
          ) : null}
          <div className="dialoginputtext">Max Bytes per content object</div>
          <input
            type="text"
            id="max_bytes_content"
            placeholder="Enter the maxbytes per content object to be delivered #datafree"
            className={!error.max_bytes_content ? "dialoginput" : "errdialoginput"}
            onBlur={handleData}
          />
          {error.max_bytes_content ? (
            <div className="errorClass">{error.contentMsg}</div>
          ) : null}
          <div className="dialoginputtext">
            Content Type blocked (Mime types)
          </div>
          <input
            type="text"
            id="content_type"
            placeholder="Enter the list of Mime Types to be blocked from #datafree delivery"
            className={!error.content_type ? "dialoginput" : "errdialoginput"}
            onBlur={handleData}
          />
          {error.content_type ? (
            <div className="errorClass">{error.mimeMsg}</div>
          ) : null}
        </DialogContent>
        <DialogActions>
        {loadings ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              disabled={clickSave ? "disabled" : false}
              color="primary"
            >
              Cancel
            </button>
            <button className="inviteButton" color="primary" 
            disabled={clickSave ? "disabled" : false}
            onClick={SaveReach}>
              Save and Return
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar open={erroropen} autoHideDuration={2500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default ReachProduction;
