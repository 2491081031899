import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AdminDatafreeGatewayDomainDetailView() {
  let history = useHistory();
  const { gateway } = useParams();
  const [manageList, setManageList] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [editInfo, setEditInfo] = useState(false);

  const handlesuccessAlert = () => {
    setsuccessOpen(false);

  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const redirectRoute = () => {
    history.push("/admin/datafreegatewaydomain/index");
  };

  const route = () => {
    setEditInfo(false)
  }
  const [data, setData] = useState({
    status: "",
    edit_data_free_description: "",
    companyId: "",
    gateway: ""
  });
  //managed By List
  async function manageListdata() {
    try {
      trackPromise(
        AdminService.getGatewayDomainDetailView(gateway).then((res) => {
          setManageList(res.data);
          setData({
            status: res.data.dataFreeGatewayDomain.status,
            edit_data_free_description: res.data.dataFreeGatewayDomain.description,
            companyId: res.data.dataFreeGatewayDomain.companyId.companyId,
            gateway: res.data.dataFreeGatewayDomain.gateway,
          });
        })
      );
    } catch (err) {

    }
  }


  const [message, setMessage] = useState();
  //save
  async function SaveData() {
    try {
      trackPromise(AdminService.editGatewayDomain(data).then((res) => {
        if (res.status === 200) {
          setsuccessOpen(true);
          setEditInfo(false);
          manageListdata();
        }
      }));
    } catch (error) {
      setMessage(error.response.data);
      seterrorOpen(true);
    }
  }
  const lifecycleChange = (e) => {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    newdata['gateway'] = gateway;

    setData(newdata);
  };
  const editfunction = () => {
    setEditInfo(true);
  }
  useEffect(() => {
    manageListdata();
  }, []);

  return (
    <div>
      <div className="NavBoxRow">
        <div className="NavBoxRowText">Company Specific #datafree Gateway Domain Details</div>
      </div>

      <div className="directapplication" style={{ marginLeft: '14px', marginTop: '0%' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="ivitediv"> <button className="inviteButton" onClick={editfunction} >
              Edit Details
            </button></div>
          </Grid>
          <Grid item xs={4}>
            <div className="companyLabel">Managed By</div>
            <div>
              <input type="text" className="userinput" disabled="disabled" style={{ cursor: "not-allowed" }} id="gateway_domain"
                value={manageList?.dataFreeGatewayDomain.companyId?.parentCompanyId?.name} name="gateway_domain"></input>
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <div className="companyLabel">Company Name</div>
            <div>
              <div>
                <input type="text" className="userinput" disabled="disabled" id="gateway_domain" style={{ cursor: "not-allowed" }}
                  value={manageList?.dataFreeGatewayDomain.companyId?.name} name="gateway_domain"></input>
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="companyLabel Dmargin">Company Type</div>
            <div>
              <input type="text" className="userinput Dmargin" id="company_type" style={{ cursor: "not-allowed" }}
                value={manageList?.dataFreeGatewayDomain.companyId?.companyType} name="company_type" disabled="disabled" />
            </div>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <div className="companyLabel">#datafree Gateway Domain</div>
            <div>
              <input type="text" className="userinput" id="gateway_domain" style={{ cursor: "not-allowed" }}
                value={manageList?.dataFreeGatewayDomain.gateway} name="gateway_domain" ></input>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="companyLabel Dmargin">Status</div>
            {editInfo ? <select type="text" id="status" name="status"
              className="domainEditUser Dmargin" onChange={(e) => lifecycleChange(e)}>
              <option value=""></option>
              <option value="LIVE" selected={manageList?.dataFreeGatewayDomain.status === "LIVE" && true}>LIVE</option>
              <option value="OFF" selected={manageList?.dataFreeGatewayDomain.status === "OFF" && true}>OFF</option>
            </select>
              : <div>
                <input type="text" className="userinput Dmargin" id="gateway_domain" disabled="disabled" style={{ cursor: "not-allowed" }}
                  value={manageList?.dataFreeGatewayDomain.status} name="gateway_domain"></input>
              </div>}
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <div className="companyLabel">Description</div>
            {editInfo ?
              <div>
                <textarea type="text" className="edituserinput" id="edit_data_free_description"
                  name="edit_data_free_description" onChange={(e) => lifecycleChange(e)}></textarea>
              </div> :
              <div>
                <textarea type="text" className="userinput" id="data_free_description" disabled="disabled" style={{ cursor: "not-allowed" }}
                  value={manageList?.dataFreeGatewayDomain.description} name="data_free_description" ></textarea>
              </div>}
          </Grid>
          <Grid item xs={8}></Grid>

          <Grid item xs={4}>
            <div className="companyLabel">Points to Licensee Service</div>
            <div>
              <input type="text" className="userinput" id="gateway_domain" name="gateway_domain" disabled="disabled"
                style={{ cursor: "not-allowed" }}></input>
            </div>
          </Grid>
          <Grid item xs={2}>

            <div className="spacefield Dmargin"></div>
            <div>
              <input type="text" className="userinput Dmargin" disabled="disabled" style={{ cursor: "not-allowed" }} />
            </div>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={11}>
            <div className="gridHead">To be used with #datafree Products :</div>
            {manageList && manageList?.products?.length > 0 ? manageList?.products?.map((item, index) => (
              <div className="companyLabel" key={index}>{item?.productType}</div>
            )) : null}
          </Grid>

          <Grid item xs={12}>
            {editInfo ? <div className="ivitediv">
              <button className="inviteButton" color="primary" onClick={route} >
                Cancel
              </button>
              <button className="inviteButton" color="primary" onClick={SaveData}>
                Save
              </button>
            </div> : null}
          </Grid>
          <Grid item xs={12}>
            <div className="ivitediv">
              <button className={editInfo ? "inviteButtonDirect" : "inviteButton"} onClick={redirectRoute} disabled={editInfo ? "disabled" : false}>
                Return to Company Specific #datafree Gateway Domain List
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={successopen}
        autoHideDuration={1500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          #datafree Gateway Domain Edited Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={erroropen}
        autoHideDuration={1500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>

  );
}
export default AdminDatafreeGatewayDomainDetailView;
