import React from "react";
import "./regForm.css";
import reachLogo from "../../../../../assets/img/reach logo.jpg";
import wrapLogo from "../../../../../assets/img/wrap logo.jpg";
import switchLogo from "../../../../../assets/img/switch logo.jpg";
import maxLogo from "../../../../../assets/img/max logo.jpg";
import GettingStartWrap from "../regForm/gettingStartWrap";
import GettingStartReach from "../regForm/gettingStartReach";
import GettingStartSwitch from "../regForm/gettingStartSwitch";
import GettingStartMax from "../regForm/gettingStartMax";
function RegForm(props) {
  const deploydata = props.history.location.state;
  
 
  const regDataPage = () => {
    if (deploydata.cardName === "reach") {
      return <GettingStartReach application_type={deploydata.cardName} appRegisterType={deploydata.prop1} />;
    }
   else if (deploydata.cardName === "wrap") {
      return <GettingStartWrap application_type={deploydata.cardName} appRegisterType={deploydata.prop1}/>;
    }
  else if (deploydata.cardName === "switch") {
      return <GettingStartSwitch application_type={deploydata.cardName} appRegisterType={deploydata.prop1}/>;
    }
  else if (deploydata.cardName === "max") {
      return <GettingStartMax application_type={deploydata.cardName} appRegisterType={deploydata.prop1}/>;
    }
  };
  return (
    <div className="mainRegView">
      <div className="registerStartTitle">
        Getting Started - Register an Application / Website
      </div>
      <div className="registerstarthead">
        <div>
          Use this screen to Register an Application or Website that you wish to
          develop #datafree
        </div>
      </div>
      <div className="publishStepsMain">
        <div className="publishStepsMainTitle">
          You want to publish your website so that it can be accessed in a
          mobile browser #datafree
        </div>
        <div className="publishSteps">
          {deploydata.cardName === "reach" ?
          <div>
            <img src={reachLogo} alt="" className="imgBox"></img>
          </div> :
          deploydata.cardName === "switch" ?
          <div>
            <img src={switchLogo} alt="" className="imgBox"></img>
          </div> :
          deploydata.cardName === "wrap" ?
          <div>
            <img src={wrapLogo} alt="" className="imgBox"></img>
          </div> :
          deploydata.cardName === "max" ?
          <div>
            <img src={maxLogo} alt="" className="imgBox"></img>
          </div> :
          null  }
          <div className="publishStepsbBox">
            <div className="publishStepBoxTitle">
              Steps inside Publisher Console
            </div>
            {deploydata.cardName === "reach" ?
            <div className="publishStepBoxContent">
              1) Register website within biNu <br />
              2) Register #datafree domain
              <br /> 3) Register content access details (content types, content
              sizing)
              <br /> 4) Use Website #datafree Workbench to develop biNu
              #datafree Gateway configuration
            </div>: 
          deploydata.cardName === "wrap" ? 
            <div className="publishStepBoxContent">
              1) Register application <br />
              2) Modify App configuration options as desired
              <br /> 3) Modify App look and feel options as desired
              <br /> 4) Register content access details (whitelist,
                content types, content sizing)
              <br /> 5) Generate development APK  
            </div>:
           deploydata.cardName === "switch" ? 
           <div className="publishStepBoxContent">
             1) Register application <br />
             2) Register content access details (whitelist,
               content types, content sizing)
             <br /> 3) Record App specific biNu Platform security 
             details
           </div>:  
           deploydata.cardName === "max" ? 
           <div className="publishStepBoxContent">
             1) Register application <br />
             2) Modify App configuration options as desired
             <br /> 3) Modify App look and feel options as desired
             <br /> 4) Register content access details (whitelist,
               content types, content sizing)
             <br /> 5) Modify App entry point structure (if 
             required)
             <br /> 6) Generate development APK  
           </div>:
            null}
          </div>
          {deploydata.cardName === "reach" ?(
          <div className="publishSubStepsbBox">
            <div className="publishStepBoxTitle">
              Steps outside Publisher Console
            </div>
            <div className="publishStepBoxContent" >
              1) Modify website to optimise for #datafree delivery
              <br />
              2) Adopt #datafree URL
              <br /> 3) Publish #datafree website
            </div>
          </div>):

        deploydata.cardName === "wrap" ?(
          <div className="publishSubStepsbBox">
            <div className="publishStepBoxTitle">
              Steps outside Publisher Console
            </div>
            <div className="publishStepBoxContent">
              1) Test development App
            </div>
          </div>):
           
           deploydata.cardName === "switch" ?(
            <div className="publishSubStepsbBox">
              <div className="publishStepBoxTitle">
                Steps outside Publisher Console
              </div>
              <div className="publishStepBoxContent">
              1) 1) Modify app codebase to incorporate 
              #datafree SWITCH to handle all<br />
              communication with backend service<br />
              2) Publish #datafree enabled App 
            </div>
            </div>):
            
            deploydata.cardName === "max" ?(
              <div className="publishSubStepsbBox">
                <div className="publishStepBoxTitle">
                  Steps outside Publisher Console
                </div>
                <div className="publishStepBoxContent">
                1) Develop appropriate backend services<br />
                2) Test development App
              </div>
              </div>):
            null}
          
        </div>
      </div>
      {regDataPage()}
    </div>
  );
}
export default RegForm;
