import React, { useState, useEffect } from "react";
import "../../admin/detailViews/detailView.css";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Grid } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import Service from "../../../api/adminService";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DataTable from "react-data-table-component";
import { IoMdCloseCircleOutline } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EmptyTable from "../../../Recomponent/EmptyTable";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
  snackbarClass: {
    backgroundColor: "white",
  },
}));
const customStyles = {

  headCells: {
    style: {
      border: "none",
      borderBottom: "none",


    },
  },
  cells: {
    style: {
      border: "none",
      width: "50px"

    },
  },
};
function AdminLicenseeServiceView() {
  const classes = useStyles();
  const [addLIcensee, setAddLIcensee] = React.useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const handleClickOpen = () => {
    history.push(`/licensee/account/detail/view`);
  };
  const { serviceid } = useParams();
  let history = useHistory();
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAddLIcensee(false);
    }

    setAppoveOpen(false);
    setReviewConfig(false);
    setBinuManage(true);

    setIpError({
      start_date: false,
      end_date: false,
      ip_address: false,
      ipExistStartDate: false,
      licDepType: false,
      provider: false,
      region: false,
      account: false,
      reservedIp: false,
      startdatemsg: "",
      ipmsg: "",
      ipexistmsg: "",
      startDateGeatemsg: "",
      startRangeMsg: "",
      endGreaterMsg: "",
      endRangeMsg: ""

    })
    setAddIp({
      is_binu_owned: "No",
      ip_address: "",
      licensee_deployment_type: "",
      provider: "",
      region: "",
      account: "",
      reserved_ip: "",
      start_date: "",
      end_date: "",
      lic_serv_id: "",
    });
    setStartDate(null);
    setEndDate(null);
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const handleApproveAlertClose = () => {
    setOpenApprove(false);
  };
  const [serviceData, setServiceData] = useState();
  const [erroropen, seterrorOpen] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [approveOpen, setAppoveOpen] = useState(false);
  const [approveData, setApproveData] = useState();
  const [ReviewConfig, setReviewConfig] = useState(false);
  const [approve] = useState({
    licenseeId: "",
  });


  const [state] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = state;
  const [tablecols] = useState([
    {
      colName: "biNu IP?",
      selector: "binuIp",
      cell: (props) => {

        return <span >{props.binuIp ? "Y" : "N"}</span>
      },
      maxWidth: "10px"
    },
    {

      colName: "IP Address",
      selector: "IpAddress",
      maxWidth: "150px",

    },
    {

      colName: "IP ID",
      selector: "ipId",
      maxWidth: "30px"
    },
    {

      colName: "Cloud",
      selector: "cloUd",
      maxWidth: "10px"
    },
    {

      colName: "Account",
      selector: "account",
      maxWidth: "150px"
    },
    {

      colName: "Region",
      selector: "region",
      maxWidth: "30px"
    },
    {
      colName: "IP Domain",
      selector: "ipDomain",
      maxWidth: "30px"
    },
    {

      colName: "Start Date",
      selector: "startDate",
      cell: (props) => {
        const custom_date = moment(props.startDate).format("DD/MM/YYYY")
        return <span >{custom_date}</span>
      },
      maxWidth: "130px"

    },
    {

      colName: "End Date",
      selector: "endDate",
      cell: (props) => {
        const custom_date = moment(props.endDate).format("DD/MM/YYYY")
        return <span >{custom_date}</span>
      },
      maxWidth: "130px"
    },
    {

      colName: "",
      selector: "View Details",
      cell: (props) => {
        return <div> <span style={{

          textShadow: "none",
          color: "#337ab7",
          cursor: "pointer",

        }} onClick={() => redirectIpView(props.ldIpId, props.binuIp)}>View Details</span></div>
      },
      maxWidth: "130px"
    },
  ]);
  const [tablecol] = useState([
    {
      name: "biNu IP?",
      selector: "binuIp",
      cell: (props) => {

        return <span >{props.binuIp ? "Y" : "N"}</span>
      },
      maxWidth: "10px"
    },
    {

      name: "IP Address",
      selector: "IpAddress",
      maxWidth: "150px",

    },
    {

      name: "IP ID",
      selector: "ipId",
      maxWidth: "30px"
    },
    {

      name: "Cloud",
      selector: "cloUd",
      maxWidth: "10px"
    },
    {

      name: "Account",
      selector: "account",
      maxWidth: "150px"
    },
    {

      name: "Region",
      selector: "region",
      maxWidth: "30px"
    },
    {
      name: "IP Domain",
      selector: "ipDomain",
      maxWidth: "30px"
    },
    {

      name: "Start Date",
      selector: "startDate",
      cell: (props) => {
        const custom_date = moment(props.startDate).format("DD/MM/YYYY")
        return <span >{custom_date}</span>
      },
      maxWidth: "130px"

    },
    {

      name: "End Date",
      selector: "endDate",
      cell: (props) => {
        const custom_date = props.endDate ? moment(props.endDate).format("DD/MM/YYYY") : null
        return <span >{custom_date}</span>
      },
      maxWidth: "130px"
    },
    {

      name: "",
      selector: "View Details",
      cell: (props) => {
        return <div> <span style={{

          textShadow: "none",
          color: "#337ab7",
          cursor: "pointer",

        }} onClick={() => redirectIpView(props.ldIpId, props.binuIp)}>View Details</span></div>
      },
      maxWidth: "130px"
    },
  ]);

  const redirectIpView = (ipId, binuIp) => {
    history.push(`/licensee/view/deployed/${serviceid}/${ipId}/ips/details`,
      { propsBInuIP: binuIp });
  };
  const [IpAddress, setIpAddess] = useState()
  const getLicenseeServiceData = () => {
    setServiceData({});
    try {
      trackPromise(
        Service.licenseeServiceDetailId(serviceid).then((res) => {
          setServiceData(res.data);
          setEditService({
            description: res.data.licenseeService.description,
            lic_serv_id: serviceid,
            action_required: res.data.licenseeService.status,
          })
          res.data?.deployedIps.map((item, index) => {
            setIpAddess(item?.IpAddress)


          })
        })
      );
    } catch (err) {
      seterrorOpen(true);
    }
  };


  const Close = () => {
    setEditInfo(false);
  };
  //approve config
  const ApproveConfigData = () => {
    approve["licenseeId"] = serviceid;
    try {
      trackPromise(
        Service.approveConfiguation(approve).then((res) => {
          setApproveData(res.data);
        })
      );
    } catch (err) {
      seterrorOpen(true);
    }
  };
  const [error, setError] = useState({
    description: false,
    action_required: false,
    msg: "",
  })
  const [editService, setEditService] = useState({
    description: "",
    lic_serv_id: "",
    action_required: "LIVE",
  });
  const [addIp, setAddIp] = useState({
    is_binu_owned: "No",
    ip_address: "",
    licensee_deployment_type: "",
    provider: "",
    region: "",
    account: "",
    reserved_ip: "",
    start_date: "",
    end_date: "",
    lic_serv_id: "",
  });
  const [binuManage, setBinuManage] = useState(true);



  const [selectedDepType] = useState({
    app_type: "",
  });

  const [region, setRegion] = useState();
  const [selectedProvider] = useState({
    provider: "",
    app_type: "",
  });
  const [serviceId] = useState({
    licServId: "",
  });
  const [openApprove, setOpenApprove] = useState(false);
  const [approveError, setApproveError] = useState();
  const approveService = () => {
    serviceId["licServId"] = serviceid;
    try {
      trackPromise(
        Service.approveAdminLicenseeService(serviceId).then((res) => {
          if (res.status === 200) {
            setApproveError(JSON.stringify(res.data));
            setOpenApprove(true);
          }
        })
      );
    } catch (err) {
      seterrorOpen(true);
    }
  };

  //Get Region
  async function getRegion(Provider) {
    selectedProvider["provider"] = Provider;
    selectedProvider["app_type"] = selectedDepType.app_type;
    try {
      trackPromise(
        Service.datafreeRegionByProvider(selectedProvider).then((res) => {
          setRegion(res.data);
        })
      );
    } catch (err) {
      seterrorOpen(true);
    }
  }
  const [account, setAccount] = useState();
  const [selectedRegion] = useState({
    provider: "",
    region: "",
    app_type: "",
  });

  async function getAccount(Region) {
    selectedRegion["region"] = Region;
    selectedRegion["provider"] = selectedProvider.provider;
    selectedRegion["app_type"] = selectedProvider.app_type;
    try {
      trackPromise(
        Service.datafreeAccountByRegion(selectedRegion).then((res) => {
          setAccount(res.data);
        })
      );
    } catch (err) {
      seterrorOpen(true);
    }
  }
  const [reserved, setReserved] = useState();
  const [selectedAccount] = useState({
    account: "",
    provider: "",
    region: "",
    lic_serv_id: "",
  });
  //getReservedIP
  async function getReserved(Account) {
    selectedAccount["account"] = Account;
    selectedAccount["region"] = selectedRegion.region;
    selectedAccount["provider"] = selectedProvider.provider;
    selectedAccount["lic_serv_id"] = serviceid;
    try {
      trackPromise(
        Service.getReservedIP(selectedAccount).then((res) => {
          setReserved(res.data.ipAddress);
        })
      );
    } catch (err) {
      seterrorOpen(true);
    }
  }
  const handleService = (e) => {
    e.preventDefault();
    const newdata = { ...editService };
    newdata[e.target.id] = e.target.value;

    newdata["lic_serv_id"] = serviceid;
    if (e.target.id === "description") {
      if (e.target.value) {
        setError({
          ...error,
          description: false,
        })
      }
      else {
        setError({
          ...error,
          description: true,
        })
      }
    }

    if (e.target.id === "action_required") {
      if (e.target.value) {
        setError({
          ...error,
          action_required: false,
        })
      }
      else {
        setError({
          ...error,
          action_required: true,
        })
      }
    }
    setEditService(newdata);
  };
  function validateInfo(editService) {

    let count = 0;
    let error = {};
    if (editService.description === "") {
      error.description = true;
      count++;
    }
    else {
      error.description = false;
    }

    if (editService.action_required === "") {
      error.action_required = true;
      count++;
    }
    else {
      error.action_required = false;
    }
    setError(error);
    return count;

  }

  const saveEdit = (e) => {
    e.preventDefault();
    try {

      let count = validateInfo(editService);

      if (count === 0) {
        trackPromise(
          Service.editLicenseeService(editService).then((res) => {
            if (res.status === 200) {
              setsuccessOpen(true);
              getLicenseeServiceData();
              setEditInfo(false);
            }
          })
        );
      }
    } catch (err) {
      seterrorOpen(true);
    }
  };
  // define check-in and check-out state
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ipError, setIpError] = useState({
    start_date: false,
    end_date: false,
    ip_address: false,
    ipExistStartDate: false,
    licDepType: false,
    provider: false,
    region: false,
    account: false,
    reservedIp: false,
    startdatemsg: "",
    ipmsg: "",
    ipexistmsg: "",
    startDateGeatemsg: "",
    startRangeMsg: "",
    endGreaterMsg: "",
    endRangeMsg: ""

  })
  const regIP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  const handleIp = (e) => {
    e.preventDefault();
    const newIPdata = { ...addIp };
    newIPdata[e.target.id] = e.target.value;
    if (e.target.id === "is_binu_owned") {
      if (e.target.value === "No") {
        setBinuManage(true);
      } else {
        setBinuManage(false);
      }
    }
    newIPdata["lic_serv_id"] = serviceid;

    if (e.target.id === "provider") {
      getRegion(e.target.value);
    }
    if (e.target.id === "region") {
      getAccount(e.target.value);
    }
    if (e.target.id === "account") {
      getReserved(e.target.value);
    }

    if (e.target.id === "end_date") {
      newIPdata['end_date'] = moment(e.target.value).format("DD/MM/YYYY")
    }
    if (e.target.id === "ip_address") {
      setIpError({
        ...ipError,
        ip_address: false,
      });
      if (regIP.test(e.target.value) === false && e.target.value !== "") {
        setIpError({
          ...ipError,
          ip_address: true,
          ipmsg: "Invalid IP address",
        });
      } else {
        setIpError({
          ...ipError,
          ip_address: false,
        });
      }
    }
    if (e.target.id === "licensee_deployment_type") {
      setIpError({
        ...ipError,
        licDepType: false,
      });
    }
    if (e.target.id === "provider") {
      setIpError({
        ...ipError,
        provider: false,
      });
    }
    if (e.target.id === "region") {
      setIpError({
        ...ipError,
        region: false,
      });
    }
    if (e.target.id === "account") {
      setIpError({
        ...ipError,
        account: false,
      });
    }
    if (e.target.id === "reserved_ip") {
      setIpError({
        ...ipError,
        reservedIp: false,
      });
    }
    setAddIp(newIPdata);
  };


  // define handler change function on check-in date
  const handleStart = (date) => {

    setStartDate(date);
    setEndDate(null);
    if (date) {
      StartDateRange(moment(date).format("DD/MM/YYYY"))

    }
    const newIPdata = { ...addIp };
    newIPdata['start_date'] = moment(date).format("DD/MM/YYYY");


    setAddIp(newIPdata);
  };


  // define handler change function on check-out date
  const handleEnd = (date) => {

    setEndDate(date ? date : "");
    if (date) {
      EndDateRange(moment(date).format("DD/MM/YYYY"))

    }

    const newIPdata = { ...addIp };
    newIPdata['end_date'] = moment(date).format("DD/MM/YYYY");


    setAddIp(newIPdata);
  };

  //DateRange
  const StartDateRange = (date) => {
    trackPromise(
      Service.AdminDateRange({ start_date: date }).then((res) => {
        if (res.status === 200 && res.data === false) {
          setIpError({
            ...ipError,
            start_date: true,

            startRangeMsg: "value must be greater than or equal to todays date"
          })
        }
        else {
          setIpError({
            ...ipError,
            start_date: false,

          })
          IpExistCheck()
        }
      })
    );
  }
  //Check IPAddress
  const IpExistCheck = () => {
    trackPromise(
      Service.IpExists(addIp).then((res) => {
        if (res.data === false) {
          setIpError({
            ...ipError,
            ipExistStartDate: true,
            start_date: false,
            ipexistmsg: "This Ip is already assigned"
          })
        }
        else {
          setIpError({
            ...ipError,
            ipExistStartDate: false,
            start_date: false,
            ipexistmsg: ""
          })

        }
      })
    );
  }
  //End DateRange
  const EndDateRange = (date) => {

    trackPromise(
      Service.AdminDateRange({ end_date: date }).then((res) => {
        if (res.status === 200 && res.data === false) {
          setIpError({
            ...ipError,
            end_date: true,

            endRangeMsg: "value must be greater than or equal to today's date"
          })
        }
        else {
          setIpError({
            ...ipError,
            end_date: false,


          })
          DateGreater(date);
        }
      })
    );
  }
  //DateGreater
  const DateGreater = (date) => {
    addIp['end_date'] = date;
    trackPromise(
      Service.CheckDateGreater(addIp).then((res) => {
        if (res.status === 200 && res.data === false) {
          setIpError({
            ...ipError,
            end_date: true,

            endRangeMsg: "value must be greater than or equal to start date"
          })
        }
        else {
          setIpError({
            ...ipError,
            end_date: false,

          })

        }
      })
    );
  }

  const newIps = () => {
    if (serviceData?.licenseeService?.service.service === "CONNECT" && addIp.licensee_deployment_type.length === 0
      || addIp.provider.length === 0 || addIp.region.length === 0 || addIp.account.length === 0
      || addIp.reserved_ip.length === 0 || addIp.start_date.length === 0 || addIp.ip_address.length === 0) {
      setIpError({
        ...ipError,
        licDepType: addIp.licensee_deployment_type.length === 0 || addIp.licensee_deployment_type === "" ? true : false,
        provider: addIp.provider.length === 0 || addIp.provider === "" ? true : false,
        region: addIp.region.length === 0 || addIp.region === "" ? true : false,
        account: addIp.account.length === 0 || addIp.account === "" ? true : false,
        ip_address: addIp.ip_address.length === 0 || addIp.ip_address === "" ? true : false,
        reservedIp: addIp.reserved_ip.length === 0 || addIp.reserved_ip === "" ? true : false,
        start_date: addIp.start_date.length === 0 || addIp.start_date === "" ? true : false,
      })
    }
    else if (serviceData?.licenseeService?.service.service !== "CONNECT" && addIp.licensee_deployment_type.length === 0
      || addIp.provider.length === 0 || addIp.region.length === 0 || addIp.account.length === 0
      || addIp.reserved_ip.length === 0 || addIp.start_date.length === 0 || addIp.ip_address.length === 0) {
      setIpError({
        ...ipError,
        licDepType: addIp.licensee_deployment_type.length === 0 || addIp.licensee_deployment_type === "" ? true : false,
        provider: addIp.provider.length === 0 || addIp.provider === "" ? true : false,
        region: addIp.region.length === 0 || addIp.region === "" ? true : false,
        account: addIp.account.length === 0 || addIp.account === "" ? true : false,
        reservedIp: addIp.reserved_ip.length === 0 || addIp.reserved_ip === "" ? true : false,
        ip_address: addIp.ip_address.length === 0 || addIp.ip_address === "" ? true : false,
        start_date: addIp.start_date.length === 0 || addIp.start_date === "" ? true : false,
      })
    }
    else if (addIp.start_date.length === 0 && addIp.ip_address.length === 0) {
      setIpError({
        ...ipError,
        start_date: addIp.start_date.length === 0 ? true : false,
        ip_address: addIp.ip_address.length === 0 ? true : false,
        ipmsg: "This field is required",
        startdatemsg: "This field is required"
      })
    }
    else {

      trackPromise(
        Service.addNewIps(addIp).then((res) => {
          if (res.status === 200) {
            getLicenseeServiceData();
            setsuccessOpen(true);
            handleClose();

          }
        }).catch((error) => {
          seterrorOpen(true);
        })
      );
    }
  };

  useEffect(() => {
    getLicenseeServiceData();
    ApproveConfigData();
  }, []);

  return (
    <div>
      <div className="licenseHead"># Licensee Service Details</div>
      <div className="rowhead">
        <div>Service Details</div>

      </div>
      <div className="gridsp">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <div className="ServiceName">Service Name </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.name ? serviceData?.licenseeService?.name : ""} disabled="disabled"></input>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className="ServiceName">Description </div>
            <div>
              {!editInfo ? (
                <input type="text" className="field" value={serviceData?.licenseeService?.description ? serviceData?.licenseeService?.description : ""} disabled="disabled"></input>
              ) : (
                <input type="text"
                  className={
                    !error.description ? "fieldEdited" : "fieldEditederr"
                  }
                  name="description" id="description" onChange={(e) => handleService(e)} />

              )}
              {error.description ? (
                <span style={{ float: 'left' }} className="errorClass topALign">This field is required</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="ServiceName">Service Type </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.service.service ? serviceData?.licenseeService?.service?.service : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="ServiceName">Proxy Domain </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.proxyDomain ? serviceData?.licenseeService?.proxyDomain : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="ServiceName">Zero Rated ?</div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.zeroRated === "1" ? "yes" : "no"} disabled="disabled" ></input>
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className="ServiceName">Status</div>
            <div>
              {!editInfo ? (
                <input type="text" className="field" value={serviceData?.licenseeService?.status ? serviceData?.licenseeService?.status : ""} disabled="disabled"></input>
              ) : (
                <select type="text"
                  className={
                    !error.action_required ? "fieldEdited" : "fieldEditederr"
                  }
                  name="action_required" id="action_required" onChange={(e) => handleService(e)}>
                  <option selected={serviceData?.licenseeService?.status === 'LIVE' && true}
                    value="LIVE">LIVE</option>
                  <option selected={serviceData?.licenseeService?.status === 'OFF' && true}
                    value="OFF">OFF</option>
                </select>
              )}
              {error.action_required ? (
                <span style={{ float: 'left' }} className="errorClass topALign">This field is required</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          {/* <Grid item xs={3}>
            <div className="ServiceName">Service Config Approved</div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.serviceConfigApproved== 0 ? false : true} disabled="disabled"></input>
            </div>
          </Grid> */}
          <Grid item xs={1}></Grid>

        </Grid>
      </div>

      <div className="fieldhead">Supported Products</div>
      <div className="typePoduct">
        <div className="rowhead">
          <div className="checkBoxtext">{serviceData?.supportedProduct?.productType ? serviceData?.supportedProduct.productType : ""}</div>
          <div>
            <input type="checkbox" checked disabled="disabled"></input>
          </div>
        </div>
      </div>
      <div className="ivitediv">
        {editInfo ? (
          <button className="inviteButton" onClick={Close} color="primary">
            Cancel
          </button>
        ) : null}
        {editInfo ? (
          <button className="inviteButton" color="primary" onClick={saveEdit}>
            Save
          </button>
        ) : null}
      </div>
      <hr className="applnslisthr" />
      <div className="fieldhead">Deployment Ip Details</div>
      <div className="fieldhead">Deployed IPs</div>
      <div>


        <div style={{ marginLeft: "20px" }}>
          {serviceData && serviceData?.deployedIps?.length > 0 ? (

            <DataTable columns={tablecol} data={serviceData.deployedIps} customStyles={customStyles}
            />
          ) : (<EmptyTable data="No Deployment Ips Found" col={tablecols} customStyles={customStyles} tableName="deployIpTable" />)}
        </div>


        <div className="invitesdiv">
          <button className="inviteButton" onClick={handleClickOpen}>
            Return Licensee Service List
          </button>
        </div>
        {/* //////////////////////////////Approve Configuration////////////////////// */}

        <Dialog open={approveOpen}

          classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <div className="appdialogtitle">#datafree CONNECT - Review / Approve Configuration</div>
            <div className="appsubHead">
              PLEASE REVIEW THE CONNECT SERVICE CONFIGURATION BELOW. IF APPROVED PLEASE CLICK ON THE APPROVAL BUTTON AT THE BOTTOM OF THE SCREEN. THE STACK WILL THEN BE AVAILABLE FOR DEPLOYMENT.
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={2}>
                <div className="topHead">Licensee</div>
              </Grid>
              <Grid item xs={4}>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={approveData?.licenseeService.companyId.name ? approveData.licenseeService.companyId.name : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}></Grid>
              <div className="spaceDiv"></div>
              <Grid item xs={12}>
                <div className="topHead">Service</div>
              </Grid>
              <div className="spaceDiv"></div>
              <Grid item xs={3}>
                <div className="labelDirect">Service Name</div>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={approveData?.licenseeService.name ? approveData.licenseeService.name : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <div className="labelDirect">Description</div>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={approveData?.licenseeService.description ? approveData.licenseeService.description : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <div className="labelDirect">Service Type</div>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={approveData?.licenseeService.service.service ? approveData.licenseeService.service.service : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <div className="labelDirect">Proxy Domain</div>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={approveData?.licenseeService.proxyDomain ? approveData.licenseeService.proxyDomain : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <div className="labelDirect">Zero Rated</div>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={approveData?.licenseeService.zeroRated ? approveData.licenseeService.zeroRated : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <div className="labelDirect">Status</div>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={approveData?.licenseeService.status ? approveData.licenseeService.status : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>
              <div className="spaceDiv"></div>
              <Grid item xs={12}>
                <div className="topHead">Servers</div>
              </Grid>

              <div className="spaceDiv"></div>

              <Grid item xs={2}>
                <div className="topHead">IP Address</div>
              </Grid>
              <Grid item xs={1}>
                <div className="topHead">Cloud</div>
              </Grid>
              <Grid item xs={2}>
                <div className="topHead">Account</div>
              </Grid>
              <Grid item xs={2}>
                <div className="topHead">Region</div>
              </Grid>
              <Grid item xs={2}>
                <div className="topHead">Start</div>
              </Grid>
              <Grid item xs={1}>
                <div className="topHead">End</div>
              </Grid>
              <Grid item xs={2}>
                <div className="topHead">Type</div>
              </Grid>
              <div className="spaceDiv"></div>
              {approveData?.deployedIps?.map((item, index) => (
                <>
                  <Grid item xs={2}>
                    <div className="labelDirect">{item.IpAddress ? item.IpAddress : ""}</div>
                  </Grid>
                  <Grid item xs={1}>
                    <div className="labelDirect">{item.cloUd ? item.cloUd : ""}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="labelDirect">{item.account ? item.account : ""}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="labelDirect">{item.region ? item.region : ""}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="labelDirect">{item.startDate ? moment(item.startDate).format("Do MMM  YYYY") : ""}</div>
                  </Grid>
                  <Grid item xs={1}>
                    <div className="labelDirect">{item.endDate ? moment(item.endDate).format("Do MMM  YYYY") : ""}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="labelDirect">{item.licDepType}</div>
                  </Grid>
                  <div className="spaceDiv"></div>
                </>
              ))}
              <>
                <div className="spaceDiv"></div>
                <Grid item xs={12}>
                  <div className="topHead">HNI Agreements</div>
                </Grid>
                <div className="spaceDiv"></div>
                <Grid item xs={2}>
                  <div className="labelDirect">HNI</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="labelDirect">Start</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="labelDirect">End</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="labelDirect">Company Billed</div>
                </Grid>

                {approveData?.hni?.map((item, index) => (
                  <>
                    <Grid item xs={2}>
                      <div className="labelDirect">{item.IpAddress ? item.IpAddress : ""}</div>
                    </Grid>
                    <Grid item xs={1}>
                      <div className="labelDirect">{item.cloUd ? item.cloUd : ""}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="labelDirect">{item.account ? item.account : ""}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="labelDirect">{item.region ? item.region : ""}</div>
                    </Grid>
                    <div className="spaceDiv"></div>
                  </>
                ))}
              </>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className="ivitediv">
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>
              <button className="approveButtonDirect" color="primary" onClick={approveService}>
                Approve Service Configuation
              </button>
            </div>
          </DialogActions>
        </Dialog>

        {/* //////////////////////ADD NEW IP// /////////////////////////////////////////*/}
        <Dialog open={addLIcensee}

          classes={{ scrollPaper: classes.scrollPaper }} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
          <DialogTitle className="ipdialogtitle">

            <div className="FlexRow">
              <div className="cmpdialogtitle">Add New IP to Licensee Service</div>
              <div className="cmpdialogtitle">
                <IoMdCloseCircleOutline style={{ cursor: "pointer" }} onClick={handleClose} />
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="accounthead">
              <div className="dialoginputtext flex3">Is the IP Address Managed by biNu ?</div>

              <div className="flex6">
                <select type="text" id="is_binu_owned" name="is_binu_owned" className="NewIpdialoginput" onChange={(e) => handleIp(e)} fullWidth>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>
            <Grid container spacing={2}>
              {serviceData?.licenseeService?.service.service === "CONNECT" ? <>
                <Grid item xs={6}>
                  <div>
                    <div className="dialoginputtext">Licensee Deployment Type</div>
                    <select type="text" id="licensee_deployment_type" name="licensee_deployment_type" className={ipError.licDepType ? "errdialogSelectinput" : "dialogSelectinput"} onChange={(e) => handleIp(e)} fullWidth>
                      <option></option>
                      <option value={serviceData?.licenseDeployedIpscheck && IpAddress === undefined ? "CONNECT-CONFIG" : "CONNECT-VPN"}>{serviceData?.licenseDeployedIpscheck ? "CONNECT-CONFIG" : "CONNECT-VPN"}</option>
                    </select>
                    {ipError.licDepType ? <span className="errorClass">This field is required.</span> : null}
                  </div>
                </Grid>
                <Grid item xs={6}></Grid>
              </> : null}
              {binuManage ? (
                <Grid item xs={6}>
                  <div>
                    <div className="dialoginputtext">Ip Address</div>
                    <input type="text" id="ip_address" name="ip_address" onChange={(e) => handleIp(e)} className={ipError.ip_address ? "errcmpnydialoginput" : "cmpnydialoginput"} fullWidth />
                    {ipError.ip_address ? <span className="errorClass">{ipError.ipmsg}.</span> : null}
                  </div>
                </Grid>
              ) : null}
              {binuManage ? <Grid item xs={6}></Grid> : null}
              {!binuManage ? (
                <Grid item xs={3}>
                  <div className="displayFlex">
                    <div className="dialoginputtext">Provider</div>
                    <select type="text" id="provider" name="provider" className={ipError.provider ? "errcmpnydialoginput" : "cmpnydialoginput"} onChange={(e) => handleIp(e)} fullWidth>
                      <option></option>
                      {serviceData && serviceData.cloud?.length > 0
                        ? serviceData.cloud.map((post) => (
                          <option key={post.cloud} value={post.cloud}>
                            {post.cloud}
                          </option>
                        ))
                        : null}
                    </select>
                    {ipError.provider ? <span className="errorClass">This field is required.</span> : null}
                  </div>
                </Grid>
              ) : null}
              {!binuManage ? (
                <Grid item xs={3}>
                  <div className="displayFlex">
                    <div className="dialoginputtext">Region</div>
                    <select type="text" id="region" name="region" className={ipError.region ? "errcmpnydialoginput" : "cmpnydialoginput"} onChange={(e) => handleIp(e)} fullWidth>
                      <option></option>
                      {region && region?.length > 0
                        ? region.map((post) => (
                          <option key={post.region} value={post.region}>
                            {post.region}
                          </option>
                        ))
                        : null}
                    </select>
                    {ipError.region ? <span className="errorClass">This field is required.</span> : null}
                  </div>
                </Grid>
              ) : null}
              {!binuManage ? (
                <Grid item xs={3}>
                  <div className="displayFlex">
                    <div className="dialoginputtext">Account</div>
                    <select type="text" id="account" name="account" className={ipError.account ? "errcmpnydialoginput" : "cmpnydialoginput"} onChange={(e) => handleIp(e)} fullWidth>
                      <option></option>
                      {account && account?.length > 0
                        ? account.map((post) => (
                          <option key={post.account} value={post.account}>
                            {post.account}
                          </option>
                        ))
                        : null}
                    </select>
                    {ipError.account ? <span className="errorClass">This field is required.</span> : null}
                  </div>
                </Grid>
              ) : null}
              {!binuManage ? (
                <Grid item xs={3}>
                  <div className="displayFlex">
                    <div className="dialoginputtext">Reserved IP</div>
                    <select type="text" id="reserved_ip" name="reserved_ip" label="email" className={ipError.account ? "errcmpnydialoginput" : "cmpnydialoginput"} onChange={(e) => handleIp(e)} fullWidth>
                      <option></option>
                      {reserved && reserved?.length > 0
                        ? reserved.map((post) => (
                          <option key={post.ipAddress} value={post.ipAddress}>
                            {post.ip_id}
                          </option>
                        ))
                        : null}
                    </select>
                    {ipError.reservedIp ? <span className="errorClass">This field is required.</span> : null}
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <div className="accounthead">
                  <div className="dialoginputtext flex3">Start Date</div>

                  <div className="flex6">

                    <DatePicker
                      id="start_date"
                      wrapperClassName="datePicker"
                      name="start_date"
                      className={ipError.start_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                      selected={startDate}
                      dateFormat="dd/MM/yyyy"
                      popperPlacement='top-start'
                      //fixedHeight={true}
                      // minDate={new Date()}
                      onChange={handleStart}
                    />
                    {ipError.start_date ? <span className="errorClass">{ipError.startRangeMsg ?
                      ipError.startRangeMsg : "This Field Required"}.</span> : null}
                  </div>

                </div>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <div className="accounthead">
                  <div className="dialoginputtext flex3">End Date</div>

                  <div className="flex6">
                    <DatePicker
                      id="end_date"
                      name="end_date"
                      className={ipError.end_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                      selected={endDate}
                      dateFormat="dd/MM/yyyy"
                      //  minDate={startDate}
                      onChange={handleEnd}
                    />
                    {ipError.end_date ? <span className="errorClass">{ipError.endRangeMsg ?
                      ipError.endRangeMsg : ""}.</span> : null}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>

                <div>{ipError.ipExistStartDate ? <span className="errorClass">{ipError.ipexistmsg}</span> : null}</div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className="ivitediv">
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>

              <button className="inviteButton" onClick={newIps} color="primary">
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>

        {/* //////////////////////Review Config///////////// */}
        <Dialog open={ReviewConfig}
          classes={{ scrollPaper: classes.scrollPaper }}
          fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <div className="appdialogtitle">CONNECT - Configuration History</div>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={4}>
                <div className="topHead">Licensee</div>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={serviceData?.licenseeService?.companyId?.name ? serviceData?.licenseeService?.companyId?.name : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>

              <Grid item xs={8}></Grid>
              <div className="spaceDiv"></div>
              <Grid item xs={12}>
                <div className="topHead">Service</div>
              </Grid>
              <div className="spaceDiv"></div>
              <Grid item xs={3}>
                <div className="topHead">Service Name</div>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={serviceData?.licenseeService?.name ? serviceData?.licenseeService?.name : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <div className="topHead">Description</div>
                <input
                  type="text"
                  id="versionName"
                  name="versionName"
                  value={approveData?.licenseeService?.description ? approveData?.licenseeService?.description : ""}
                  disabled="disabled"
                  label="email"
                  className="dialoginputValue"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <div className="topHead">Build ID</div>
              </Grid>
              <Grid item xs={2}>
                <div className="topHead">Build Date</div>
              </Grid>
              <Grid item xs={2}>
                <div className="topHead">Build User</div>
              </Grid>
              <Grid item xs={2}>
                <div className="topHead">Show Config</div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className="ivitediv">
              <button className="inviteButton" onClick={handleClose} color="primary">
                Return
              </button>
            </div>
          </DialogActions>
        </Dialog>

        <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
          <Alert onClose={handlesuccessAlert} severity="success">
            "Updated Successfully!
          </Alert>
        </Snackbar>

        <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="error">
            An Error Occured!
          </Alert>
        </Snackbar>
        <Snackbar
          style={{
            backgroundColor: "white", maxWidth: '100%', height: 100
          }}
          open={openApprove}
          anchorOrigin={{ vertical, horizontal }}
          autoHideDuration={1500}
          onClose={handleApproveAlertClose}
        >
          <Alert
            style={{
              backgroundColor: "white",
              alignItems: "center",
              fontSize: "13px",
              maxWidth: '100%', height: 100,
              color: "#939598",
            }}
            onClose={handleApproveAlertClose}
            severity="error"
          >
            {approveError ? approveError : ""}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
export default AdminLicenseeServiceView;
