import React, { createContext, useState, useEffect } from "react"

const userContext = createContext("")
const userDispatchContext = createContext("")
const roleContext = createContext("")
const roleDispatchContext = createContext("")
const deploymentContext = createContext("")
const deploymentDispatchContext = createContext("")
const appDetailContext = createContext("")
const appDetailDispatchContext = createContext("")
function ContextProvider({ children }) {

    const [tokenCheck, setTokenCheck] = useState({
        token: ""
    })
    const [roleCheck, setRoleCheck] = useState({
        crole: ""
    })
    const [deployTypeCheck, setDeployTypeCheck] = useState({
        type: ""
    })
    const [appDetail, setAppDetails] = useState([])
    const getToken = () => {
        setTokenCheck({ ...tokenCheck, token: localStorage?.getItem('token') })
        setRoleCheck({ ...roleCheck, crole: localStorage?.getItem('companyrole') })
    }

    useEffect(() => {

        getToken();
    }, [])


    return (
        <userContext.Provider value={tokenCheck}>
            <userDispatchContext.Provider value={setTokenCheck}>
                <roleContext.Provider value={roleCheck}>
                    <roleDispatchContext.Provider value={setRoleCheck}>
                    <deploymentContext.Provider value={deployTypeCheck}>
                    <deploymentDispatchContext.Provider value={setDeployTypeCheck}>
                    <appDetailContext.Provider value={appDetail}>
                    <appDetailDispatchContext.Provider value={setAppDetails}>
                        {children}
                        </appDetailDispatchContext.Provider>
                </appDetailContext.Provider>
                        </deploymentDispatchContext.Provider>
                </deploymentContext.Provider>
                    </roleDispatchContext.Provider>
                </roleContext.Provider>
            </userDispatchContext.Provider>
        </userContext.Provider >
    )
}

export {
    userDispatchContext, userContext,roleContext,roleDispatchContext,deploymentContext,deploymentDispatchContext,
    appDetailContext,appDetailDispatchContext, ContextProvider

}