import React , { useState, useEffect,useContext } from 'react'
import "../user/sidebarUser.css";
import { MdMenu } from "react-icons/md";
import { sideNavAdmin } from "./sideNavAdmin";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import datafreelogo from "../../../assets/img/data_free_inner.png";
import datafreeText from "../../../assets/img/datafree_logo_white.png";
import logoutIcon from "../../../assets/img/logout-icon.png";
import service from "../../../api/service";
import AdminService from "../../../api/adminService";
import jwt from "jwt-decode";
import {
  userDispatchContext
 } from "../../../api/context"

function SidebarAdmin(props) {
  const[userData,setUserData]=useState()
  const {sidebarOpen,mobileviewOpen}=props;
  const sidebarCollapsed = localStorage.getItem("sidebar-Collapsed");
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? true : false);
  let history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const userDispatchContextVal = useContext(userDispatchContext)

    const handleResize = () => {
      if (window.innerWidth < 990) {
        setIsMobile(true);
        mobileviewOpen(true);
        setToggleOpen(true);
      } else {
        setIsMobile(false);
        mobileviewOpen(false);
        setToggleOpen(false);
      }
    };
    
    const getData = () => {
      try {
        
          service.IndexData().then((res) => {
           
            setUserData(res.data);
          })
       
      } catch (err) {
      
      }
    };
    const redirectUser=()=>{
      history.push("/admin/user/details/view");
    }
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      getData();
    },[]);
  
    const handleToggler = () => {
     
      if (isExpanded) {
        
        setIsExpanded(false);
        sidebarOpen(true)
        localStorage.setItem("sidebar-Collapsed", false);
        // return;
      } else {
        
        setIsExpanded(true);
        sidebarOpen(false)
        localStorage.setItem("sidebar-Collapsed", true);
      }
    };
  
    const redirectPath = (path,title) => {
      if(title === "Reporting" || title === "Support"){
        window.open(path);
      }
      else{
        history.push(path);
      }
     
    };
  
    const getAccordion = () => {
      return (
        <div>
          {sideNavAdmin.map((item, index) => (
            <div key={index}>
              <Accordion
                className="accordionDiv"
                defaultExpanded={true }
              >
                <AccordionSummary
                  expandIcon={
                    isExpanded ? (
                      item.subMenu ? (
                        <ExpandMoreIcon className="panela-header" />
                      ) : null
                    ) : null
                  }
                  aria-controls="panel1a-content"
                >
                  <span className="navLinkText">
                    {isExpanded ? item.title : item.collapsedIcon}
                  </span>
                </AccordionSummary>
                {item.subMenu
                  ? item.subMenu.map((subItem, index) => (
                      <div
                      key={index}
                        style={{ textDecoration: "none" }}
                        onClick={() => redirectPath(subItem.path, subItem.title)}
                      >
                        <AccordionDetails
                          key={index}
                          className={
                            window.location.pathname === subItem.path
                              ? "dataRow"
                              : "accodiondetails"
                          }
                        >
                          <div className="subIcon">
                            {subItem.imageIcon ? (
                              <img
                              alt=""
                                src={subItem.imageIcon}
                                className="imgstyle"
                              ></img>
                            ) : (
                              subItem.icon
                            )}
                          </div>
  
                          <span className="subMenu" title={isExpanded ? subItem.titles : null}>
                            {isExpanded ? subItem.title : null}
                          </span>
                        </AccordionDetails>
                      </div>
                    ))
                  : null}
              </Accordion>
            </div>
          ))}
        </div>
      );
    };
  

    const exitImpersonate = () => {
      try {
       
        AdminService.exitImpersonate().then((res) => {
            if (res.status === 200) {
            
              localStorage.setItem("token", res.data);
              userDispatchContextVal({token: res.data});
              localStorage.removeItem("Impersonation");
              
              var decode1 = jwt(res.data);
             
              if (decode1.roles[0] === "ROLE_USER")
               history.push("/user/datafree");
              else if (decode1.roles[0] === "ROLE_ADMIN")
               history.push("/admin/company/index");
              else if (decode1.roles[0] === "ROLE_LICENSEE")
              history.push("/licensee/index/action");
              else if (decode1.roles[0] === "ROLE_CHANNEL")
               history.push("/channel/publisher/index");
               else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER")
               history.push("/accountmanager/applications/index");
            } else {
              alert("!please try again");
            }
  
         
          })
     
      } catch (err) {
       
      }
    };
   
    const iconMobile = () => {
      toggleOpen ? setToggleOpen(false) : setToggleOpen(true);
      
    };
    const logout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("refreshtoken");
      history.push('/');
    };
    return (
      <>
        <div
          className={
            isMobile
              ? "sidebar"
              : isExpanded
              ? "sideBar"
              : "SidebarCollapsed"
          }
        >
          <div className="mainhead">
            {isMobile ? (
              <div className="flexRow">
                <div className="logoFlex">
                  <div>
                    <img src={datafreeText} alt="" className="logoText"></img>
                  </div>
                  <div>
                    <MdMenu onClick={iconMobile} className="hamenuIcon" />
                  </div>
                </div>
                <div className="subspanTitle">
                  {/* Version : 1.0.170-162-g94ca40bd */}
                </div>
              </div>
            ) : (
              <div className={isExpanded ? "sidebarHead" : null}>
                {isExpanded ? (
                  <div className="sidebarHeadItem">
                    <img src={datafreelogo} alt="" className="imagelogo"></img>
                  </div>
                ) : null}
                <div
                  title="Dashboard"
                  className={
                    isExpanded ? "sidebarHeadItem1" : "sidebarHeadItem1Collapsed"
                  }
                >
                  <MdMenu onClick={handleToggler} id="sidenavToggler"/>
                </div>
              </div>
            )}
  
            {isExpanded ? (
              !isMobile ? (
                <div className="mainaccordionDiv">
                  <div className="mainNavSubtext">  {isExpanded ? "Datafree Portal" : null}</div>
                  <div className={isExpanded ? "mainnavLinkText" : null}>
                    {isExpanded ? userData?.version:  null}
                  </div>
                  {/* <div className={isExpanded ? "mainnavLinkText" : null}>
                    {isExpanded ? userData?.email: null}
                  </div> */}
                </div>
              ) : null
            ) : null}
          </div>
          {toggleOpen ? (
            <div></div>
          ) : (
            <div>
              <div className="accordionDiv">
                <div className={isExpanded ? "mainprofileText" : null} title="Dashboard">
                  {isExpanded ?userData?.name :null}
                </div>
                <div className={isExpanded ? "submainprofileText" : null} onClick={redirectUser} title="Click here to view admin user details">
                  {isExpanded ? userData?.email: null}
                </div>
              </div>
              {getAccordion()}
              <div className="accordionDivRow">
                <div className="itemIcon">
                  <img src={logoutIcon} alt=""></img>
                </div>

                {localStorage.getItem("Impersonation")? (
                <div
                className={isExpanded ? "iconNavText" : null}
                onClick={exitImpersonate}
              >
                {isExpanded ? "Exit Impersonation" : null}
              </div>
              ) : (
                <div
                  className={isExpanded ? "iconNavText" : null}
                  onClick={logout}
                  title="Click here to logout"
                >
                  {isExpanded ? "Logout" : null}
                </div>
                
              )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
export default  SidebarAdmin;