import axios from "axios";
import { API_URL,publisherListRequests,userChannelListRequests,exitimpersonate } from "./constants";

class service {
 
  //channelUserDetails
  async channelUserRequest() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${publisherListRequests.channelUserDetail}`,
      { headers }
    );
    return response;
  }

 async ChannelSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.channelListSearch}`,
      data,
      { headers }
    );
    return response;
  }
 
   //channelDetailView
   async channelDetailView(id) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${publisherListRequests.channelPublisherDetail}/${id}/detail/view`,
      { headers }
    );
    return response;
  }
  //channelUserEdit
  async channelUserEdit(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.channelUseEdit}`,
      data,
      { headers }
    );
    return response;
  }
   //channelUser
   async channelUserDetail(id) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${publisherListRequests.getChannelUserDetails}`,id,
      { headers }
    );
    return response;
  }
   //channelUser
   async channelApplicationSearch(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.post(
      `${API_URL}${userChannelListRequests.channelApplicationSearch}`,data,
      { headers }
    );
    return response;
  }
   //channelUtilties
   async channelUtilities() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${userChannelListRequests.channelUtilities}`,
      { headers }
    );
    return response;
  }
  //channelAccount
  async channelAccount() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${userChannelListRequests.accountRequest}`,
      { headers }
    );
    return response;
  }
  //channelEdit
  async channelPublisherEdit(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.editChannelDetails}`,
      data,
      { headers }
    );
    return response;
  }
  //channelUserLIst Search
  async channelUserLIst(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth
    };
    const response = await axios.post(
      `${API_URL}${userChannelListRequests.getUsersList}`,
      data,
      { headers }
    );
    return response;
  }
  //userDetailById
  async channelUserBYId(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.userDetailBYID}`,
      data,
      { headers }
    );
    return response;
  }
  //Invite New Channel New User
  async IniteNewChannelUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.invitechannelNewUser}`,
      data,
      { headers }
    );
    return response;
  }
   //Invite New Channel New User
   async IniteAccountUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.inviteAccountNewUser}`,
      data,
      { headers }
    );
    return response;
  }
  //addChannelPublisher
  
   async AddChannelPublisher(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.addChannelPublisher}`,
      data,
      { headers }
    );
    return response;
  }
  //edit Account Details
  
  
   async EditAccountDetails(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${publisherListRequests.AccountEdit}`,
      data,
      { headers }
    );
    return response;
  }
   //channel Index
   async channelIndex() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${publisherListRequests.channelIndex}`,
      { headers }
    );
    return response;
  }
//channel Index
async ApplicationchannelIndex() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.get(
    `${API_URL}${publisherListRequests.applicationChannelIndex}`,
    { headers }
  );
  return response;
}

//Notification details Index
async notificationDetails() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.get(
    `${API_URL}${publisherListRequests.notificationDetails}`,
    { headers }
  );
  return response;
}


//Change Status
  
  
async ChangeStatus(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${userChannelListRequests.ChangeStatus}`,
    data,
    { headers }
  );
  return response;
}

//Change Health CheckOn
  
  
async ChangeHealth(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${userChannelListRequests.ChangeHealth}`,
    data,
    { headers }
  );
  return response;
}

//password request

async emailRequest(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${userChannelListRequests.emailRequest}`,
    data,
    { headers }
  );
  return response;
}

//email check

async emailChannelCheck(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${userChannelListRequests.emailChannelCheck}`,
    data,
    { headers }
  );
  return response;
}

async exitImpersonate() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };

  const response = await axios.get(
    `${API_URL}${exitimpersonate.exitimpersonateuser}`,
    { headers }
  );
  return response;
}

}

export default new service();
