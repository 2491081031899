import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const EmptyTable = (props) => {

  const [cols, setCols] = useState([]);

  useEffect(() => {
    setCols(props.col);  
  },[props.col]);

  return (
    <TableContainer>
      <Table className="accouttablestyle" aria-label="simple table">
        <TableHead>
          <TableRow>
            {cols.map((item, index) => (
              <TableCell className="tableHead" key={index}>
                {item.colName === "Type" && props.tableName === "Admin Licence Service View" && props.additionData.licenseeService.service.service === "CONNECT"
                  ? "Type"
                  : props.tableName !== "Admin Licence Service View"
                  ? item.colName
                  : item.colName !== "Type" && props.tableName === "Admin Licence Service View"
                  ? item.colName
                  
                  : null}
              </TableCell>
            ))}
          </TableRow>
          
        </TableHead>
        <TableBody >
            <tr>
            <td colSpan={"20"} className= "bottomRow">{props.data}</td>
          </tr>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmptyTable;
