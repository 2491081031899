import React, { useEffect, useState } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import Loader from "react-loading";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { usePromiseTracker } from "react-promise-tracker";
import AdminService from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import VMCTable from '../../../Recomponent/Table'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
       <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function Applications() {
  const [search, setSearch] = useState(false);
  let history = useHistory();
  const [searchDetails, setSearchDetails] = useState();
  const [changedropdown, setChangedropdown] = useState(false);
  const [indexDropdown, setIndexDropdown] = useState();
  const [ChannelList , setChannelList] = useState();
  const [empty,setEmpty] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const[message ,setMessage] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [data, setData] = useState({
    searchLicensee: "",
    searchDeploymentType: "",
    searchProductType: "",
    searchBinuId: "",
    searchApplicationName: "",
    searchFreeDomain: "",
    searchPublisherName: "",
    searchChannel: "",
  });
  const[selectedLicensee]=useState({
    
      licenseeId: ""
  
  })
  const [tablecols] = useState([
    {
      colName : 'Channel',
      colMap: 'publisherIdparentCompanyIdname',
      linkName : true,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'App Name',
      colMap: 'name',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Product Type',
      colMap: 'productIdproductType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Free Domain',
      colMap: 'reachSubDomain',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Google App Id',
      colMap: 'googleAppId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Type',
      colMap: 'applicationType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Dev biNu Id',
      colMap: 'devBinuId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Prod biNu Id',
      colMap: 'ProdBinuId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Production Data Usage Mb (28 days)',
      colMap: 'publisherIdpubDataUsageAlert',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Revenue Type',
      colMap: 'revenueType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Publisher',
      colMap: 'publisherIdname',
      linkName : true,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Company Type',
      colMap: 'publisherIdcompanyType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Status',
      colMap: 'publisherIdstatus',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Production Health Check Required',
      colMap: 'ProductionHealth',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
      
    },
    {
      colName : 'Status',
      colMap: 'status',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
  ])
  
  async function applicaitonListdata() {
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setSearchDetails({});
    try {
      trackPromise(
        AdminService.ApplicationSearch(data).then((res) => {
          setResponseData(res.data);
          setSearchDetails(res.data.applicationListResult);
          if(res.status === 200 && res.data.applicationListResult.length > 0){
            setChangedropdown(false);
          }
          else if(res.status === 200 && res.data.applicationListResult.length === 0){
            setEmpty(true);
            setChangedropdown(false);
           }
          else{
            setChangedropdown(true);
           }
        })
      );
    } catch (error) {
        setMessage(error.response.data);
        seterrorOpen(true);
    }
  }
  const[responseData,setResponseData]=useState()
  //application Index
  async function applicaitonIndex() {
    try {
      trackPromise(
        AdminService.applicationIndex().then((res) => {
         
          setIndexDropdown(res.data);
        })
      );
    } catch (err) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
//GET CHANNEL
async function GetChannel(licenseeId) {
  
  selectedLicensee['licenseeId']=licenseeId;
  try {
    trackPromise(
      AdminService.ChannelByLicensee(selectedLicensee).then((res) => {
        setChannelList(res.data.licenseeChannels);
      })
    );
  } catch (err) {
    setMessage("Something went wrong. Please reload the page!");
    seterrorOpen(true);
  }
}

  const handleRouteLink = (companytype, companyId) => {
   
    if (companytype === "CUSTOMER" || "RESLR CLNT") {
      history.push(`/admin/customer/${companyId}/detail/view`);
    }
    if (companytype === "CHANNEL") {
      history.push(`/admin/Channel/${companyId}/detail/view`, {
        companyId: companyId,
      });
    }
    if (companytype === "LICENSEE") {
      history.push(`/admin/licensee/${companyId}/detail/view`, {
        licenseeId: companyId,
      });
    }
  };


  useEffect(() => {
    applicaitonIndex();
  }, []);

  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    if(e.target.id === "searchLicensee"){
      GetChannel(e.target.value)
    }
    setData(newdata);
  };

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Application List - Admin View</div>
        </div>
        <div>
          <div className="NavBoxRowFilter">
            <div>
              <div className="search-div-for-applicationList">
                <div className="table-responsive">
                  <table className="overflow">
                    <thead>
                    <tr>
                      <td className="col-md-2">
                        Licensee
                      </td>
                      <td className="col-md-2">Channel</td>
                      <td className="col-md-1">
                        Deployment Type
                      </td>
                      <td className="col-md-2">
                        Product Type
                      </td>
                      <td className="col-md-1">biNu Id</td>
                      <td className="col-md-2">
                        Application Name
                      </td>
                      <td className="col-md-2">
                        Free Domain
                      </td>
                      <td className="col-md-2">
                        Publisher Name
                      </td>
                      <td className="col-md-2">
                      
                      </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td className="col-md-2">
                        <select
                          className="application-form-control"
                          id="searchLicensee"
                          name="searchLicensee"
                          onChange={(e) => lifecycleChange(e)}
                        >
                          <option value=""></option>
                          {indexDropdown &&
                          indexDropdown.licensee.length > 0 ? (
                            indexDropdown.licensee.map((post) => (
                              <option
                                key={post.companyId}
                                value={post.companyId}
                              >
                                {post.name}
                              </option>
                            ))
                          ) : (
                          <option></option>
                          )}
                        </select>
                      </td>

                      <td className="col-md-2">
                        <select
                          className="application-form-control"
                          id="searchChannel"
                          name="searchChannel"
                          onChange={(e) => lifecycleChange(e)}
                        >
                          <option value=""></option>
                          {ChannelList && ChannelList.length > 0 ? (
                            ChannelList.map((post) => (
                              <option
                                key={post.companyId}
                                value={post.companyId}
                              >
                                {post.name}
                              </option>
                            ))
                          ) : (
                            <option></option>
                          )}
                        </select>
                      </td>
                      <td className="col-md-1">
                        <select
                          className="application-form-control"
                          id="searchDeploymentType"
                          name="searchDeploymentType"
                          onChange={(e) => lifecycleChange(e)}
                        >
                          <option value=""></option>
                          <option value="PROD">PROD</option>
                          <option value="DEV">DEV</option>
                        </select>
                      </td>
                      <td className="col-md-2">
                        <select
                          className="application-form-control"
                          id="searchProductType"
                          name="searchProductType"
                          onChange={(e) => lifecycleChange(e)}
                        >
                          <option value=""></option>
                          <option value="1">#datafree WRAP</option>
                          <option value="2">#datafree MAX</option>
                          <option value="3">#datafree SWITCH</option>
                          <option value="4">#datafree REACH</option>
                          <option value="5">#datafree DIRECT</option>
                          <option value="6">#datafree CONNECT</option>
                        </select>
                      </td>
                      <td className="col-md-1">
                        <input
                          type="text"
                          className="application-form-control"
                          id="searchBinuId"
                          name="searchBinuId"
                          onChange={(e) => lifecycleChange(e)}
                        />
                      </td>
                      <td className="col-md-2">
                        <input
                          type="text"
                          className="application-form-control"
                          id="searchApplicationName"
                          name="searchApplicationName"
                          onChange={(e) => lifecycleChange(e)}
                        />
                      </td>
                      <td className="col-md-2">
                        <input
                          type="text"
                          className="application-form-control"
                          id="searchFreeDomain"
                          name="searchFreeDomain"
                          onChange={(e) => lifecycleChange(e)}
                        />
                      </td>
                      <td className="col-md-2">
                        <input
                          type="text"
                          className="application-form-control"
                          id="searchFreeDomain"
                          name="searchFreeDomain"
                          onChange={(e) => lifecycleChange(e)}
                        />
                      </td>
                      <td className="col-md-2">
                        <button
                          onClick={applicaitonListdata}
                          className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                          disabled={changedropdown ? "disabled":false}
                        >
                          Search
                        </button>
                      </td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="ButtonDiv"></div>

          {search ? (
          <div>
            {searchDetails && searchDetails.length > 0 ? (
              <VMCTable key={searchDetails} data={searchDetails} additionalData1={responseData} col={tablecols} navigateFunction={handleRouteLink} tableName="Admin Application" />
            ) : (
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Applications Found" col={tablecols} tableName="Applications List" /> : null}
              </div>
            )}
          </div>
        ) : null}
      </div>
      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
         {message}
        </Alert>
      </Snackbar>
      
    </div>
  );
}
export default Applications;
