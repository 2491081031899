import React, { useState } from 'react';
import "./adminStyle.css";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function ConnectCreateFifthStep({NextFormStep, handlePreviousOpen, data, error, handleChange}) {
    let history = useHistory();
   
    const [CusAuthEnd,setCusAuthEnd] = useState(false);

    const onChange = (e) => {
        e.preventDefault();
        if(e.target.id === "user_authentication"){
            if(e.target.value === "yes"){
                setCusAuthEnd(true);
            }
            else{
                setCusAuthEnd(false);
            }
        }
        handleChange(e);
    }
    return (
        <div className="directapplication">
            <Grid container spacing={1}>
            <Grid item xs={12}>
                <div className="gridHead">Step 5 of 6 - User Authentication Requirements</div>
            </Grid>

            <Grid item xs={12}>
                <div className="labelDirect auth" >Is User Authentication Required ? (Yes / No)</div> 
                <Grid item xs={2}>
                    <div>
                    <select type="text" className="selectIcon" id="user_authentication" name="user_authentication"
                    onChange={onChange}
                    defaultValue={data.user_authentication}>
                        <option>{""}</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                    </div>
                
                </Grid>
            </Grid>
            
            {CusAuthEnd || data.user_authentication === "yes" ? (
            <Grid item xs={3}>
                <div className="labelDirect auth" >Customer Authentication Endpoint</div> 
                <div>
                
                <input
                  type="text"
                  className={
                    !error.authentication_endpoint ? "directFieldSelect" : "directFieldSelecterr"
                  }
                  name="authentication_endpoint"
                  id="authentication_endpoint"
                  defaultValue={data.authentication_endpoint}
                  onChange={handleChange}
                />
                {error.authentication_endpoint ? (
                    <span style={{float:'left'}} className="errormsg">{error.Authmsg}</span>
                  ) : (
                    ""
                )}
                
             </div>
            </Grid>
           
           ) : null }
         {CusAuthEnd || data.user_authentication === "yes" ? (
            <Grid item xs={1}></Grid> ) : null }   
         {CusAuthEnd || data.user_authentication === "yes" ? (   <Grid item xs={3}>
                <div className="labelDirect auth" >Expected Valid Response Value</div> 
                <div>
                 
                <input
                  type="text"
                  className="direct_FieldInput"
                  name="validResponse"
                  id="validResponse"
                  defaultValue={data.validResponse}
                  disabled="disabled"
                />
                
             </div>
            </Grid>
           ) : null }   

            <Grid item xs={6}>
             <div className="ivitediv">
              <button className="datafreeConnectBtnDanger" color="primary" 
              onClick={()=>history.push("/admin/datafreeconnect/application/index")}>
                Cancel
              </button>
            </div> 
             </Grid>
             <Grid item xs={6}>
                {" "}
                <div className="ivitediv">
                <button className="datafreeConnectButton" color="primary" onClick={()=>handlePreviousOpen(5)}>
                    Previous
                </button>
                {" "}
                <button className="datafreeConnectButton" color="primary" style={{marginLeft:'10px'}}
                onClick={()=>NextFormStep(5)}
                >
                    Next
                </button>
                </div>
            </Grid>  
            </Grid>
        </div>
    )
}

export default ConnectCreateFifthStep
