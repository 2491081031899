import React,{useContext} from "react";
import { Route, Switch,Redirect} from "react-router-dom";
import PublisherList from "../channel/channelPages/publisherList";
import UserList from "../channel/channelPages/userList";
import ApplicationList from "../channel/channelPages/applications";
import ChannelUtility from "../channel/channelPages/utilities";
import AccountDetails from "../channel/channelPages/account";
import ChannelPublisherDetailView from "../channel/channelPages/channelPublisherDetailView"
import UserDetails from "../user/userPages/userDetails";
import NotificationDetails from "../channel/channelPages/notificationDetails";
import {
  userContext
 } from "../../api/context"

export default function Channel() {
  const userContextVal = useContext(userContext)
  return (
    <div>
      <Switch>
      {userContextVal?.token !== null && userContextVal?.token !== undefined ?
      <>
        <Route exact path="/channel/publisher/index" component={PublisherList} /> 
        <Route exact path="/channel/userlist/index" component={UserList} /> 
        <Route exact path="/channel/applications/index" component={ApplicationList} /> 
        <Route exact path="/channel/utilities/options" component={ChannelUtility} /> 
        <Route exact path="/channel/account/details/view" component={AccountDetails} />
        <Route exact path="/channel/publisher/:channelId/detail/view" component={ChannelPublisherDetailView} /> 
        <Route exact path="/channel/user/details/view" component={UserDetails}/>
        <Route exact path="/channel/notification/details" component={NotificationDetails}/>
        
        </>
      :<Redirect  to="/"></Redirect>}
    
     
      </Switch>
    </div>
  );
}
