import React, { useState } from "react";
import SidebarAccountManager from "../sidebar/accountManager/sidebarAccountManager";
import AccountManager from "../accountManager/accountManager";


 function Index(props) {
  const [openSidebar,setOpenSidebar]=useState(true);
  const [openMOb,setOpenMob]=useState(false);
  const sidebarOpen = (val) => {
   
    setOpenSidebar(!openSidebar);
  };
  const openMobileView = (ob) => {
    
    setOpenMob(!openMOb);
  };
  const [open, setOpen] = useState(false);
  function init() {
    document.addEventListener('click', function (e) {
      e.preventDefault();
      if (document.getElementById('sidenavToggler')?.contains(e.target)) {
        // Clicked in box
        // alert("clicked");
        e.preventDefault();
        open ? setOpen(false) : setOpen(true);
      } else {
        // Clicked outside the box
      }
    });
  }
  document.addEventListener('load', init);


  return (
    <>
      <SidebarAccountManager
        sidebarOpen={(val) => sidebarOpen(val)}
        mobileviewOpen={(ob) => openMobileView(ob)}
      />
       <div  className={!open ? (openSidebar ? "bodyMargin" : "bodyMarginOpen") : "bodyMarginOpen"}>
        <AccountManager />
      </div>
    </>
  );
}
export default Index;