import {BsHash} from "react-icons/bs";
import {FaIndustry,FaUsers} from "react-icons/fa";
import applicationIcon from "../../../assets/img/white-app.png";
import reportingIcon from "../../../assets/img/reporting-icon.png";
import docIcon from "../../../assets/img/doc-icon.png";
import accountIcon from "../../../assets/img/account-icon.png";
import supportIcon from "../../../assets/img/support-icon.png";

export const SideNavChannel = [
    //Development
    {
      id:1,
      title: "Development",
      collapsedIcon:<BsHash/>,
      subMenu: [
        {
          id:1,
          title: "Publisher List",
          titles: "Click here to view Publisher List",
          icon : <FaIndustry/>,
          path: "/channel/publisher/index",
        },
        {
            id:2,
            title: "User List",
            titles: "Click here to view User List",
            icon :<FaUsers/>,
            path: "/channel/userlist/index",
          },
          {
            id:3,
            title: "Applications",
            titles: "Click here to view application list",
            imageIcon :applicationIcon,
            path: "/channel/applications/index",
          },
          {
            id:4,
            title: "Reporting",
            titles: "Click here to view Reporting",
            imageIcon :reportingIcon,
            path: "https://powerbi.microsoft.com/en-us/",
          },
          {
            id:5,
            title: "Utilities",
            titles: "Click here to view Utilities",
            icon : <BsHash/>,
            path: "/channel/utilities/options",
          },
          
          {
            id:6,
            title: "Datafree Knowledge Base",
            titles: "Click here to view biNu  Knowledge Base",
            imageIcon :docIcon,
            path: "https://sites.google.com/bi.nu/datafree-knowledgebase",
          },
         
      ],
      
    },
    //Settings
    {
      id:2,
      title: "Settings",
      collapsedIcon:<BsHash/>,
      subMenu: [
        { id:1,  imageIcon: accountIcon,title: "Account", titles: "Click here to view account details", path: "/channel/account/details/view" },
        { id:2, imageIcon : supportIcon,title: "Support", titles: "Example Pages", path: "https://datafr.ee/contact/" },
       
      ],
     
    },
   
  ];
  