import React, {useState,useEffect} from 'react';
import applicationIcon from "../../../assets/img/application-icon.png";

import Loader from "react-loading";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { trackPromise } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import VMCTable from '../../../Recomponent/Table';
import AccountService from "../../../api/accountService";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ApplicationList() {
  const [search, setSearch] = useState(false);
  const [changedropdown, setChangedropdown] = useState(false);
  let history = useHistory();
  const [searchDetails, setSearchDetails] = useState();
  const [productTypeDropdown, setProductTypeDropdown] = useState();
  const [empty,setEmpty] =useState(false);
    const [data, setData] = useState({
      searchDeploymentType:"",
      searchProductType:"",
      searchBinuId:"",
      searchApplicationName:"",
      searchFreeDomain:"",
      searchPublisherName:"",
      channelId:"",
      accountManagerEmail:""
      });
      const [erroropen, seterrorOpen] = useState(false);
      const [message, setMessage] = useState();
      const handleAlertClose = () => {
        seterrorOpen(false);
      };
    const [tablecols] = useState([    
    {
      colName : 'App Name',
      colMap: 'name',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Product Type',
      colMap: 'productIdproductType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Free Domain',
      colMap: 'reachSubDomain',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Google App Id',
      colMap: 'googleAppId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Type',
      colMap: 'applicationType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Dev biNu Id',
      colMap: 'devBinuId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Prod biNu Id',
      colMap: 'ProdBinuId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Revenue Type',
      colMap: 'revenueType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Publisher',
      colMap: 'publisherIdname',
      linkName : true,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Company Type',
      colMap: 'publisherIdcompanyType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Status',
      colMap: 'publisherIdstatus',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Production Health Check Required',
      colMap: 'ProductionHealth',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false      
    },
    {
      colName : 'Status',
      colMap: 'Status',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
  ])

//application Index
 const applicaitonIndex = () => {
      trackPromise(
        AccountService.AccountIndex().then((res) => {
          if(res.status === 200){
            setProductTypeDropdown(res.data);
            setData({
              ...data,
              channelId:res?.data?.channelId,
              accountManagerEmail:res.data?.email
            });
          
          }
          setLoading(false);
        })
      );
  }

  const[responseData,setResponseData]=useState()
  
  const pageRoute =(cType,cId)=>{
  history.push(`/accountmanager/publisher/${cId}/detail/view`)
  }

  const [loading, setLoading] = useState(false);
  //app Search
    async function ApplicationListdata(data) {
      setSearch(true);
      setChangedropdown(true);
      setEmpty(false);
      setLoading(true);
      try {
        trackPromise(
          AccountService.accountApplicationSearch(data).then((res) => {;
            setResponseData(res.data);
            setSearchDetails(res.data.applicationListResult);
            setLoading(false);
            if(res.status === 200 && res.data?.applicationListResult.length > 0){
              setChangedropdown(false);
              setEmpty(false);
            }
            else if(res.status === 200 && res.data?.applicationListResult.length === 0){
              setEmpty(true);
              setChangedropdown(false);
             }
            else{
              setChangedropdown(true);
              setEmpty(false);
             }
          })
        );
      } catch (err) {
        setMessage("Something went wrong. Please reload the page.");
        seterrorOpen(true);
      }
    }

    const lifecycleChange = (e) => {
      e.preventDefault();
        changedropdown ? setChangedropdown(false) : setChangedropdown(false);
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
      };
  useEffect(() => {
    setLoading(true);
    document.title = "Datafree Portal";
    applicaitonIndex();
  }, []);

    return (
        <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Application List - Account Manager View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
        <table className="table-sm">
            <tbody>
              <tr>
              <td className="col-md-2">
              Deployment Type
                </td>
                <td className="col-md-2">
                Product Type
                </td>
                <td className="col-md-2">
                biNu Id
                </td>
                <td className="col-md-3">
                Application Name
                </td>
                <td className="col-md-1">
                Free Domain
                </td>
                <td className="col-md-2">
                Publisher Name
                </td>
                <td className="col-md-2">
                
                </td>
              </tr>
              <tr>
              <td className="col-md-2">
              <select
                  type="text"
                  className="form-control form-select"
                  id="searchDeploymentType"
                  name="searchDeploymentType"
                 
                  onChange={(e) => lifecycleChange(e)}
                >
               <option value=""></option>
                  <option value="PROD">PROD</option>
                  <option value="DEV">DEV</option>
                </select>
              </td>
                <td className="col-md-2">
                <select
                  type="text"
                  className="form-control form-select"
                  id="searchProductType"
                  name="searchProductType"
                 
                  onChange={(e) => lifecycleChange(e)}
                ><option value=""></option>
                          {productTypeDropdown &&
                          productTypeDropdown?.product_type?.length > 0 ? (
                            productTypeDropdown?.product_type.map((post) => (
                              <option
                                key={post.productId}
                                value={post.productId}
                              >
                                {post.productType}
                              </option>
                            ))
                          ) : (
                          <option></option>
                          )}
                </select>
                </td>
                <td className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  id="searchBinuId"
                  name="searchBinuId"
                  onChange={(e) => lifecycleChange(e)}
                ></input>
                </td>
                <td className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="searchApplicationName"
                  name="searchApplicationName"
                  onChange={(e) => lifecycleChange(e)}
                ></input>

                </td>
                <td className="col-md-1">
                <input
                  type="text"
                  className="form-control"
                  id="searchFreeDomain"
                  name="searchFreeDomain"
                  onChange={(e) => lifecycleChange(e)}
                ></input>
                </td>
                <td className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  id="searchPublisherName"
                  name="searchPublisherName"
                  onChange={(e) => lifecycleChange(e)}
                ></input>
                </td>
                <td className="col-md-2">
                <button className={
                changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}  onClick={()=>ApplicationListdata(data)}
             
              >
                Search
              </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>
      
        {loading?  <div className="loaderContainer">
            <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
         <div>
        {search ? (
          <div>
            {searchDetails && searchDetails?.length > 0 ? (
              <VMCTable key={searchDetails} 
              data={searchDetails}  col={tablecols} 
              advanceData={responseData} tableName="Account Manager Applications" 
              navigateFunction={pageRoute}/>
           ) : (
              <div>
                {empty ? <EmptyTable data="No Applications Found" col={tablecols} tableName="Account Manager Applications" /> : null}
              </div>
            )} 
          </div>
        ) : null}
          </div>
        </div>
        <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
         {message ? message : "An Error Occured!"}
        </Alert>
        </Snackbar>
        </div>
    )
}
export default ApplicationList;
