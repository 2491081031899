import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Loader from "react-loading";
import { useHistory } from "react-router-dom";
import defaultIcon from "../../../../../assets/img/datafree_defaulticon.png";
import splashIcon from "../../../../../assets/img/default-app-splash.jpg";
import service from "../../../../../api/service";
import Adminservice from "../../../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
function GettingStartSwitch(props) {
  let history = useHistory();
  const classes = useStyles();
  const [erroropen, seterrorOpen] = useState(false);
  const [icon, setIcon] = useState({ preview: defaultIcon , raw: "" });
  const [open, setOpen] = useState(false);
  const [formStep, setFormStep] = useState(0);
  const [GatewayData, setGatewayData] = useState();
  const [ResponseData, setResponseData] = useState();
  const [errMsg, setErrMsg] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [error, setError] = useState({
    msg: "",
    name: false,
    gateway_domain: false,
    icon_image:false,
    appMsg:"",
    imgmsg:"",
    contentmsg:"",
    max_bytes_content:false,
    content_type:false,
    typemsg: "",
    gatewaymsg:""
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handlePreviousOpen = () => {
    setFormStep((cur) => cur - 1);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const cancelReg = () => {
    history.push("/user/datafree");
  };

  const completeFormStep = () => {


    const countnw = validateData(data, formStep);
    if(countnw > 0)
    {
      setFormStep((cur) => cur);
    }
    else{
      setFormStep((cur) => cur + 1);
    }
  };

  //gateway domain dropdown
  async function GatewayDrop() {
      
    try {
      trackPromise(
        Adminservice.GatewayDrop().then((res) => {
       
          setGatewayData(res.data?.gatewayDomain);
        })
      );
    } catch (err) {
    
    }
  
}

  const validateData = (data, formStep) => {
    let count = 0;
    const nwerror = {...error};
    if(formStep === 0){
      if (data.name === "") {
        nwerror.name = true;
        nwerror.appMsg = "This field is required";
        count++;
      }  
      else {
        if(AppnameReg.test(data.name) === false){  
        nwerror.name = true;
        nwerror.appMsg = "Please enter a name without comma";
        count++;
        }
        else{
          nwerror.name = false;
          nwerror.appMsg = "";
        }
      }

      if(error.icon_image === true){
        nwerror.icon_image = true;
        nwerror.imgmsg =  error.imgmsg      
        count++;
      }
      else{
        nwerror.icon_image = false;
        nwerror.imgmsg =  "" 
      }

    }

    if(formStep === 1){
      if (data.name === "") {
        nwerror.name = true;
        nwerror.appMsg = "This field is required";
        count++;
      }  
      else {
        if(AppnameReg.test(data.name) === false){  
        nwerror.name = true;
        nwerror.appMsg = "Please enter a name without comma";
        count++;
        }
        else{
          nwerror.name = false;
          nwerror.appMsg = "";
        }
      }

      if(error.icon_image === true){
        nwerror.icon_image = true;
        nwerror.imgmsg =  error.imgmsg      
        count++;
      }
      else{
        nwerror.icon_image = false;
        nwerror.imgmsg =  "" 
      }

      if (data.gateway_domain === "") {
        nwerror.gateway_domain = true;
        nwerror.gatewaymsg = "This field is required";
        count++;
      }  
      else {
          nwerror.gateway_domain = false;
          nwerror.gatewaymsg = "";
        
      }

    if (data.max_bytes_content === "") {
        nwerror.max_bytes_content = false;
        nwerror.contentmsg = "";
      }  
      else {
        if(contentReg.test(data.max_bytes_content) === false){  
        nwerror.max_bytes_content = true;
        nwerror.contentmsg = "Please enter only digits.";
        count++;
        }
        else{
          nwerror.max_bytes_content = false;
          nwerror.contentmsg = "";
        }
      }

      if (data.content_type === "") {
        nwerror.content_type = false;
        nwerror.typemsg = "";
      }  
      else {
        if(typeReg.test(data.content_type) === false){  
        nwerror.content_type = true;
        nwerror.typemsg = "Please enter a valid content type.";
        count++;
        }
        else{
          nwerror.content_type = false;
          nwerror.typemsg = "";
        }
      }
    }
    setError(nwerror);
        
    return count;
  }
 
  const continueFormStep = () => {
    const appId = ResponseData?.applicationId;
    const deployType = ResponseData?.deploymentType;
    if(deployType==="DEV"){
      history.push(`/user/application/${appId}/details`,{deploytype :deployType});
    }

    if(deployType === "PROD"){
      history.push(`/user/application/${appId}/details`,{deploytype :deployType});
    }
  };
 
  const PreviousRenderBtn = () => {
    if (formStep === 2) {
      return undefined;
    } else if (formStep === 0) {
      return undefined;
    } else {
      return (
        <button className="regLayoutbtn" onClick={handlePreviousOpen}>
          Pevious
        </button>
      );
    }
  };
  const CancelRenderBtn = () => {
    if (formStep > 1) {
      return undefined;
    } else {
      return (
        <button className="regLayoutbtn" onClick={handleClickOpen}>
          Cancel
        </button>
      );
    }
  };
  const renderBtn = () => {
    if (formStep > 2) {
      return undefined;
    } else if (formStep === 1) {
      return (
        <button className="regLayoutbtn" 
        disabled={loaderVisible ? "disabled" : false}
        onClick={regFormdata}>
          Register Application
        </button>
      );
    } else if (formStep === 2) {
      return (
        <button className="regLayoutbtn" onClick={continueFormStep}>
          Continue
        </button>
      );
    } else {
      return (
        <button className="regLayoutbtn" onClick={completeFormStep}>
          Next
        </button>
      );
    }
  };
  const [IconImage,setIconImage] = useState({
    icon_image:""
  })
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  function UploadIconDefault(){
             

    fetch(defaultIcon)
    .then((res) => res.blob())
    .then((myBlob) => {
      myBlob.name = "datafree_defaulticon.png";
      myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "datafree_defaulticon.png", {
          type: myBlob.type,
        });
    let values = myFile !== undefined ? new FormData() : "";
    if (myFile !== undefined) {
      values.append("icon_image", myFile ? myFile : "");
    }
  
     
        trackPromise(
          Adminservice.uploadIcon(values).then((res) => {
           
            setIconImage({
              icon_image: res.data,
            })
            
          }).catch((error)=>{
            setErrMsg("Something went wrong. Please reload the page!");
            seterrorOpen(true);
          })

        );
    });
    
     }

     async function UploadIconImage(imageVal) {

      let file = imageVal;

        let values = file !== undefined ? new FormData() : "";
        if (file !== undefined) {
          values.append("icon_image", file ? file : "");
        }
      
   
            trackPromise(
              Adminservice.uploadIcon(values).then((res) => {
                
                setIconImage({
                  icon_image: res.data,
                })
              }).catch((error)=>{
                setErrMsg("Something went wrong. Please reload the page!");
                seterrorOpen(true);
              })

            ); 
    }

  const [data, setData] = useState({
    name: "",
    switchSelection: "switchLiteUrlRewrite",
    icon_image: "",
    gateway_domain: "",
    max_bytes_content: "",
    content_type: "",
    reach_form_step: "product-switch-step2",
    step_no: "2",
    application_type: "",
    appRegisterType: "DEV",
    applicationId: "",
  });
  const AppnameReg = /^[^'",]*$/i;
  const contentReg = /^[0-9]*$/;
  const typeReg = /^[a-z]+\/(\*|[a-z]+[-+.a-z0-9]*)$/m;
  function handleChange(e) {
    setError(false);
    const newdata = { ...data };
    newdata['appRegisterType']=props.appRegisterType;
    newdata['application_type']=props.application_type;
    newdata[e.target.id] = e.target.value;
    if(e.target.id === "name"){
      if(e.target.value){
        if(AppnameReg.test(e.target.value) === false){  
          setError({
            ...error,
            name: true,
            appMsg: "Please enter a name without comma"
          });
        }
        else{
          setError({
            ...error,
            name: false,
            appMsg: ""
          });
        }
      }
      else{
        setError({
          ...error,
          name: true,
          appMsg: "This field is required."
        });
      }
    }

    if(e.target.id === "icon_image")
    {
    if (e.target.files.length !== 0) 
    {
       var img = document.createElement("img");
       img.src = URL.createObjectURL(e.target.files[0]);
       var filePath = e.target.files[0].name;
       
       if(!allowedExtensions.exec(filePath)){
           setIcon({
           preview: "",
           raw: "",
         });
         setError({
           ...error,
           icon_image: true,
           imgmsg: "Selected file is not an image"
         });
       }          
      else
      {
        img.onload = function () {
          if(img.width >= 120 && img.height >= 120){
         
           setIcon({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
          
          });
          setError({
            ...error,
            icon_image: false,
            imgmsg: ""
          });
          UploadIconImage(e.target.files[0]);
        }
        else if(img.width <= 120){
          setIcon({
            preview: "",
            raw: "",
            });
          setError({
            ...error,
            icon_image: true,
            imgmsg: "Minimum width must be 120px"
          });
        }
        else if(img.height <= 120){
          setIcon({
            preview: "",
            raw: "",
            });
          setError({
            ...error,
            icon_image: true,
            imgmsg: "Minimum height must be 120px"
          });
        }             
        };
      }
    }
    else 
    {
      setIcon({
       preview: "",
       raw: "",
       });
     setError({
       ...error,
       logo_image: false,
       imgmsg: ""
     });  
             
    }
    }
    

    if(e.target.id === "max_bytes_content"){
      if(e.target.value){
        if(contentReg.test(e.target.value) === false){  
          setError({
            ...error,
            max_bytes_content: true,
            contentmsg: "Please enter only digits."
          });
        }
        else{
          setError({
            ...error,
            max_bytes_content: false,
            contentmsg: ""
          });
        }
      }
      else{
        setError({
          ...error,
          max_bytes_content: false,
          contentmsg: ""
        });
      }
    }


    if(e.target.id === "content_type"){
      if(e.target.value){
       
        var contentTypeValue = e.target.value;
        var modifiedContentTypeValue = '';
        if(contentTypeValue !== ''){
            var originalContentType = contentTypeValue.split('\n');
            for(var i = 0;i < originalContentType.length;i++){
                if(i === originalContentType.length - 1){
                    if(originalContentType[i] !==''){
                        if(originalContentType[i].indexOf("/") === -1){
                            modifiedContentTypeValue += originalContentType[i]+'/*';
                        }else{
                            modifiedContentTypeValue += originalContentType[i];
                        }
                    }
                }else{
                    if(originalContentType[i] !== ''){
                        if(originalContentType[i].indexOf("/") === -1){
                            modifiedContentTypeValue += originalContentType[i]+'/*'+'\n';
                        }else{
                            modifiedContentTypeValue += originalContentType[i]+'\n';
                        }
                    }
                }
            }
            //e.target.value = modifiedContentTypeValue;
        }
       
        if(typeReg.test(modifiedContentTypeValue) === false){  
          setError({
            ...error,
            content_type: true,
            typemsg: "Please enter a valid content type."
          });
        }
        else{
          setError({
            ...error,
            content_type: false,
            typemsg: ""
          });
          e.target.value = modifiedContentTypeValue;
          newdata[e.target.id] = e.target.value;
        }
      }
      else{
        setError({
          ...error,
          content_type: false,
          typemsg: ""
        });
      }
    }

    if(e.target.id === "gateway_domain"){
      if(e.target.value){ 
        setError({
            ...error,
            gateway_domain: false,
            gatewaymsg: ""
          });
      }
      else{
        setError({
          ...error,
          gateway_domain: true,
          gatewaymsg: "This field is required"
        });
      }
    }
    setData(newdata);
  }
  
 
  const [SplashImage,setSplashImage] = useState({
    splash_image:""
  })

       function UploadSplashDefault(){
         
        fetch(splashIcon)
        .then((res) => res.blob())
        .then((myBlob) => {
          myBlob.name = "default-app-splash.jpg";
          myBlob.lastModified = new Date();
            const myFile = new File([myBlob], "default-app-splash.jpg", {
              type: myBlob.type,
            });
            
            
  
            let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("splash_image", myFile ? myFile : "");
        }
      
      
            trackPromise(
              Adminservice.uploadSplash(values).then((res) => {
            
                setSplashImage({
                  splash_image: res.data,

                })
              }).catch((error)=>{
                setErrMsg("Something went wrong. Please reload the page!");
                seterrorOpen(true);
              })
  
            );
        });
        
         }
  const[loaderVisible,setLoaderVisible] = useState(false);
  const regFormdata = () => {
    setLoaderVisible(true);
    const nextAllow = validateData(data,formStep)
    if(nextAllow === 0){
      data["icon_image"] = IconImage.icon_image;
      data["splash_image"] = SplashImage.splash_image;
      try {
        trackPromise(
          service.RegisterForm(data).then((res) => {
            setLoaderVisible(false);
            setResponseData(res.data)
            setFormStep((cur) => cur+1);
          
             
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }
    
  };

  useEffect(() => {
    UploadIconDefault();
    UploadSplashDefault()
    GatewayDrop();
  }, [])
  return (
    <div>
      <div className="publishStepsMain">
        {formStep === 0 && (
          <section>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <div className="registerFormTitle">
                  Step 1) Register Application
                </div>
              </Grid>
              <Grid item xs={11}>
                <div>
                  <input
                    type="text"
                    className={
                      !error.name ? "registerInput" : "registerInputerr"
                    }
                    placeholder="Enter the name of the Application you wish to Register and and publish #datafree"
                    name="name"
                    id="name"
                    defaultValue={data.name}
                    onChange={handleChange}
                  />
                  {error.name ? (
                    <span className="errormsg">{error.appMsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
            </Grid>
            <div className="registerFormTitleSwitch">
                SWITCH METHOD : SWITCH Lite - URL Rewrite
                </div>
            <div className="registerIconUpload"> 
              <div className="rowClass loadimg">
                <img src={icon.preview ? icon.preview : "" } alt="" className="imgdefaultIcon"></img>
              </div>
              <div className="registerIconBox">
                <div className="IconText1">Upload an icon image</div>
                <div className="IconText2">
                  This is used within the Publisher Console as a visual prompter
                  only
                </div>
                <div className="IconText3">
                  (Min. dimensions of 120px X 120px) 
                </div>
                {error.icon_image ? (
                    <p style={{float:'left'}} className="errorClass">{error.imgmsg}</p>
                  ) : (
                    ""
                  )}
                <div className="file-upload">
                  <div className="file-select">
                    <div className="file-select-button" id="fileName">
                      Choose File
                    </div>
                    <div className="file-select-name" id="noFile" defaultValue={data.icon_image}>
                      default-app-icon.png
                    </div>
                    <input type="file"  /*name={data.icon_image}*/  name="icon_image" id="icon_image" onChange={handleChange}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* //step2 */}

        
        {formStep === 1 && (
          <section>
            <div className="registerFormTitle">
              Step 2)Register content access details - OPTIONAL, you can define
              these later through the Publication Designer
            </div>

            <div className="regInLayoutbtns">
              <div className="registerInBox">
                <div className="registerFormTitle">
                  Max Bytes per content object
                </div>
                <div>
                  <input
                    type="text"
                    className={
                      !error.max_bytes_content
                        ? "regFormInput"
                        : "regFormInputerr"
                    }
                    required
                    placeholder="Enter the max size in bytes per content object to be delivered #datafree"
                    name="max_bytes_content"
                    id="max_bytes_content"
                    defaultValue={data.max_bytes_content}
                    onChange={handleChange}
                  />
                  {error.max_bytes_content ? (
                    <span className="errormsg">{error.contentmsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="registerInBox">
                <div className="registerFormTitle">
                  Content Type blocked (Mime Types)
                </div>
                <div>
                  <textarea
                    className={
                      !error.content_type
                        ? "registerInputTextarea"
                        : "registerInputTextareaerr"
                    }
                    id="content_type"
                    name="content_type"
                    onBlur={handleChange}
                    style={{fontSize:'14px'}}
                    defaultValue={data.content_type}
                    placeholder="Enter the list of Mime Types to be blocked #datafree"
                    required
                  ></textarea>
                  
                </div>
                {error.content_type ? (
                    <span className="errormsg">{error.typemsg}</span>
                  ) : (
                    ""
                  )}
              </div>
            </div>
            <Grid item xs={7} style={{marginBottom:'20px'}}>
                <div className="registerInBox">
                  <div className="registerFormTitle">
                    Choose your #datafree Gateway domain
                  </div>
                  <div>
                    <select
                      className={
                        !error.gateway_domain
                          ? "registersubInput"
                          : "registersubInputerr"
                      }
                      style={{width:'93%',fontSize:'14px'}}
                      required
                      id="gateway_domain"
                      name="gateway_domain"
                      defaultValue={data.gateway_domain}
                      onChange={handleChange}
                    >
                      <option value=""> </option>
                      {GatewayData && GatewayData?.length > 0
                    ? GatewayData.map((post) => ( 
                      <option key={post.gateway} value={post.gateway}>
                      {post.gateway}
                    </option>
                      ))
                   : null} 
                    </select>
                  </div>
                  {error.gateway_domain ? (
                    <span className="errormsg">{error.gatewaymsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
          </section>
        )}
        {formStep === 2 && (
          <section>
            <div className="registerFormTitle" style={{ marginTop: "15px" }}>
              Step 3) Record details
            </div>
            <div className="registerFormTitle" style={{ marginTop: "15px", fontSize:'14px' }}>
            Registration of your #datafree SWITCH Application has been successful. Please note following details for usage within your Application
            </div>
            <div className="registerFormTitle" style={{ marginTop: "15px", fontSize:'14px' }}>
            To modify details further, navigate to the #datafree Publication List and enter the Publication Designer
            </div>
            <div className="regInLayoutbtns Btm">
              <div className="registerInBox">
                <div className="registerFormTitle fntSmall">
                biNu Platform binuApp Id
                </div>
                <div>
                  <input
                    type="text"
                    className= "appIdSwitch"
                    required
                    name="binuAppId"
                    id="binuAppId"
                    disabled="disabled"
                    value={ResponseData?.binuAppId}
                  />
                
                </div>
              </div>
              <div className="registerInBox btnLeft">
                <div className="registerFormTitle fntSmall">
                biNu Platform Security Key
                </div>
                <div>
                <input
                    type="text"
                    className="keySwitch"
                    required
                    name="securityKey"
                    id="securityKey"
                    disabled="disabled"
                    value={ResponseData?.securityKey}
                  />
                  
                </div>
                
              </div>
            </div>

            <div className="registerFormTitle" style={{ marginTop: "15px", fontSize:'14px', marginBottom:'20px' }}>
            Please refer to the 
            <a href="https://jitpack.io/com/github/binuadmin/binuProxy/BinuProxy/1.0.21/javado1" target="_blank" rel="noreferrer">#datafree SWITCH Integration Documentation</a>
            for details of how to modify your Application code base
            </div>
          </section>
        )}
        <div className="regLayoutbtns">
          <div>{CancelRenderBtn()}</div>

          {
                loaderVisible &&
                <div>
                     <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
                    
                </div>
            }

          <div className="btnsLayout">
            <div>
              {PreviousRenderBtn()}
              {/* ///previous */}
            </div>
            <div>
              {renderBtn()}
              {/* //nect byn */}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
       <DialogTitle id="form-dialog-title" style={{borderBottom: '1px solid #e5e5e5'}}>
          <div className="cmpdialogtitle">Getting started - Register an Application / Website
          <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContentText className="DialogContentText">
          Are you sure do you want to cancel?
        </DialogContentText>
        <DialogActions>
              <button className="inviteButton" 
              onClick={cancelReg} 
              style={{width:'46%',marginLeft:'10px'}} color="primary" name="popup">
                Yes
              </button>

              <button className="inviteButton" 
              color="primary" style={{width:'46%'}} onClick={() => setOpen(false)} name="popup">
                No
              </button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={erroropen}
        autoHideDuration={1500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
         {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default GettingStartSwitch;
