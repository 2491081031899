import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import applicationIcon from "../../../assets/img/application-icon.png";
import Service from "../../../api/licenseeService";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import VMCTable from '../../../Recomponent/Table';
import EmptyTable from "../../../Recomponent/EmptyTable";
import { usePromiseTracker } from "react-promise-tracker";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function PublisherList() {
  let history = useHistory();
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [scroll, setScroll] = useState("paper");
  const [checked, setChecked] = useState(false);
  const [channelList, setChannelList] = useState();
  const [channelDropdown, setchanneldropdown] = useState();
  const [companyId] = useState("");
  const [changedropdown, setChangedropdown] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [customerdropdown, setCustomerdropdown] = useState(false);
  const [owningChannel, setOwningChannel] = useState()
  const [invite, setInvite] = useState({
    company_types: "",
    licenseeId: "",
    owning_channel: "",
    companyname: "",
    licensee_shortcode: "",
    customer_identifier: "",
    credit_limit: "",
    status: "",
    discount_price: "",
    manager_email: "",
    reg_company_name: "",
    reg_business_number: "",
    reg_address: "",
    reg_phone_number: "",
    option_create_user: "",
    users_name: "",
    email_address: ""



  });

  const [error, setError] = useState({
    company_types: false,
    companyname: false,

    status: false,
    reg_business_number: false,

    owning_channel: false,
    reg_company_name: false,
    licensee_shortcode: false,
    users_name: false,
    manager_email: false,
    email_address: false,
    discount_price: false,
    reg_address: false,
    reg_addressmsg: false,
    msg: "",
    manager_emailmsg: "",
    validemailmsg: "",
    regmsg: "",
    validnomsg: "",
    codeErrmsg: "",
    creditEmailMsg: "",
    reg_phone_number: "",
    regNoMsg: "",
    regBusNoMsg: "",


  });
  const [tablecols] = useState([
    {
      colName: 'Channel',
      colMap: 'parentCompanyIdname',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Publisher ID',
      colMap: 'companyId',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Publisher Name',
      colMap: 'name',
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Company Type',
      colMap: 'companyType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Contract Term',
      colMap: 'contractTerm',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Created',
      colMap: 'created',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    },

  ])


  const handleClose = () => {
    setOpen(false);
    setError({
      company_types: false,
      companyname: false,
      status: false,
      reg_business_number: false,
      owning_channel: false,
      reg_company_name: false,
      licensee_shortcode: false,
      users_name: false,
      manager_email: false,
      email_address: false,
      discount_price: false,
      reg_address: false,
      reg_addressmsg: false,
      msg: "",
      manager_emailmsg: "",
      validemailmsg: "",
      regmsg: "",
      validnomsg: "",
      codeErrmsg: "",
      creditEmailMsg: "",
      reg_phone_number: "",
      regNoMsg: "",
      regBusNoMsg: "",
      nameMsg: "",
      regNameMsg: "",
      discountMsg: "",
      custMsg: "",
    });
  };
  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };

  const [data, setData] = useState({
    searchChannel: "",
    searchCompanyType: "",
    searchPublisherId: "",
    searchPublisherName: "",
    searchStatus: "",
  });
  const [empty, setEmpty] = useState(false);
  async function LicenseeIndexdata() {

    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setChannelList({});
    try {
      trackPromise(
        Service.licenseePublisherIndexSearch(data).then((res) => {
          setChannelList(res.data.publisherListResult);

          if (res.status === 200 && res.data.publisherListResult.length > 0) {

            setEmpty(false);
            setChangedropdown(false);
          } else if (res.status === 200 && res.data.publisherListResult.length === 0) {
            setEmpty(true);
            setChangedropdown(false);
          } else {
            setChangedropdown(true);
            setEmpty(false);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  async function Userdata() {
    try {
      trackPromise(
        Service.licenseeUserRequest().then((res) => {
          invite['licenseeId'] = res.data.userDetails.companyId.companyId;
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  const [creditLimit, setCreditLimit] = useState()
  //get publiserIndex
  async function publisherIndex() {
    try {
      trackPromise(
        Service.licenseePublisherIndex().then((res) => {

          setchanneldropdown(res.data.publisherListResults);
          setCreditLimit(res.data.datafree_Data_Credit_Limit)
          setOwningChannel(res.data.owningChannel)
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setOpen(false);
  };
  const [errMsg, setErrMsg] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const handleRouteLink = (companyType, companyId) => {
    if (companyType === "CUSTOMER") {
      history.push(`/licensee/customer/${companyId}/detail/view`);
    }
    if (companyType === "CHANNEL") {
      history.push(`/licensee/channel/${companyId}/detail/view`);
    }
    if (companyType === "RESELLER") {
      history.push(`/licensee/customer/${companyId}/detail/view`);
    }
  };
  const dataChange = (e) => {
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    newdata["searchLicenseeId"] = companyId;
    setData(newdata);
  };
  const numberRegex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
  const emailreg = /^\w+([.+-]?\w+)*@\w+([.+-]?\w+)*(\.\w{2,3})+$/;
  const validNumberRegex = /^(?=.*[0-9])[+ 0-9]+$/i;
  const validBusinessRegex = /^[0-9A-Za-z\s+-]/i;
  const nameReg = /^.*[A-Za-z].*/i;
  const custIdReg = /^[a-zA-Z0-9-]*$/i;
  const InviteCompany = (e) => {
    const newdata = { ...invite };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "status") {
      if (e.target.value) {
        setError({
          ...error,
          status: false,
        });
      }
      else {
        setError({
          ...error,
          status: true,
        });
      }
    }
    if (e.target.id === "companyname") {
      if (e.target.value) {
        companyNameCheck(e.target.value);
      } else {
        setError({
          ...error,
          companyname: true,
          nameMsg: "This field is required",
        });
      }
    }
    if (e.target.id === "company_types") {
      if (e.target.value) {
        setError({
          ...error,
          company_types: false,
        });
      }
      else {
        setError({
          ...error,
          company_types: true,
        });
      }
    }
    if (e.target.id === "manager_email") {

      if (e.target.value) {
        if (emailreg.test(e.target.value) === false) {
          setError({
            ...error,
            manager_email: true,
            manager_emailmsg: "Please enter a valid email address.",
          });
        } else {
          setError({
            ...error,
            manager_email: false,
            manager_emailmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          manager_email: false,
          manager_emailmsg: ""
        });
      }
    }

    if (e.target.id === "discount_price") {

      if (e.target.value) {
        if (numberRegex.test(e.target.value) === false) {
          setError({
            ...error,
            discount_price: true,
            discountMsg: "Please enter a valid number.",
          });
        }
        else if (e.target.value > 99.9) {
          setError({
            ...error,
            discount_price: true,
            discountMsg: "Please enter a value less than or equal to 99.9.",
          });
        }
        else {
          setError({
            ...error,
            discount_price: false,
            discountMsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          discount_price: false,
          discountMsg: ""
        });
      }
    }

    if (e.target.id === "customer_identifier") {

      if (e.target.value) {
        if (custIdReg.test(e.target.value) === false) {
          setError({
            ...error,
            customer_identifier: true,
            custMsg: "Only alphanumeric characters and hyphen is allowed",
          });
        } else {
          setError({
            ...error,
            customer_identifier: false,
            custMsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          customer_identifier: false,
          custMsg: ""
        });
      }
    }

    if (e.target.id === "reg_company_name") {

      if (e.target.value) {
        if (nameReg.test(e.target.value) === false) {
          setError({
            ...error,
            reg_company_name: true,
            regNameMsg: "Field must have at least 1 character a-z or A-Z",
          });
        } else {
          setError({
            ...error,
            reg_company_name: false,
            regNameMsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          reg_company_name: false,
          regNameMsg: ""
        });
      }
    }

    if (e.target.id === "reg_address") {

      if (e.target.value) {
        if (nameReg.test(e.target.value) === false) {
          setError({
            ...error,
            reg_address: true,
            reg_addressmsg: "Field must have at least 1 character a-z or A-Z",
          });
        } else {
          setError({
            ...error,
            reg_address: false,
            reg_addressmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          reg_address: false,
          reg_addressmsg: ""
        });
      }
    }

    if (e.target.id === "users_name") {
      if (checked) {
        if (e.target.value) {
          setError({
            ...error,
            users_name: false,
          });
        }
        else {
          setError({
            ...error,
            users_name: true,
          });
        }
      }

    }
    if (e.target.id === "email_address") {
      if (checked) {
        if (e.target.value) {
          EmailCheck(e.target.value);
        } else {
          setError({
            ...error,
            email_address: true,
            validemailmsg: "This field is required",
          });
        }
      }
      else {
        setError({
          ...error,
          email_address: false,
          validemailmsg: "",
        });
      }
    }

    if (e.target.id === "reg_business_number") {
      if (e.target.value) {
        if (validBusinessRegex.test(e.target.value) === false) {
          setError({
            ...error,
            reg_business_number: true,
            regBusNoMsg: "Only alphanumeric characters or underscore and the first character must be a letter",
          });
        } else {
          setError({
            ...error,
            reg_business_number: false,
            regBusNoMsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          reg_business_number: false,
          regBusNoMsg: ""
        });
      }
    }

    if (e.target.id === "reg_phone_number") {
      if (e.target.value) {
        if (validNumberRegex.test(e.target.value) === false) {
          setError({
            ...error,
            reg_phone_number: true,
            regNoMsg: "Valid characters are '+' 0-9 and space",
          });
        } else {
          setError({
            ...error,
            reg_phone_number: false,
            regNoMsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          reg_phone_number: false,
          regNoMsg: ""
        });
      }
    }

    if (e.target.id === "owning_channel") {
      if (e.target.value) {
        setError({
          ...error,
          owning_channel: false,
        });
      }
      else {
        setError({
          ...error,
          owning_channel: true,
        });
      }
    }

    if (e.target.id === "company_types") {
      if (e.target.value) {
        setError({
          ...error,
          company_types: false,
        });
      }
      else {
        setError({
          ...error,
          company_types: true,
        });
      }
    }

    if ((e.target.id === "company_types" && e.target.value === "CUSTOMER") || e.target.value === "RESELLER") {
      setCustomerdropdown(true);
    }
    else if (e.target.id === "company_types" && e.target.value === "") {
      setCustomerdropdown(false);
    }

    if (checked) {
      newdata["option_create_user"] = "yes";
    } else {
      newdata["option_create_user"] = "no";
    }
    setInvite(newdata);
  };

  const [emailVal, setEmailVal] = useState();
  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      Service.licenseeEmailExist({ email_address: value })
        .then((res) => {
          setEmailVal(res.data);
          if (res.data === false) {
            setError({
              ...error,
              email_address: true,
              validemailmsg: "This​ email ​already​ used",
            });
          } else if (value && emailreg.test(value) === false) {
            setError({
              ...error,
              email_address: true,
              validemailmsg: "Please enter a valid email address.",
            });
          } else {
            setError({
              ...error,
              email_address: false,
              validemailmsg: ""
            });
          }
        })
        .catch((error) => {
          setErrMsg("Something went wrong. Please reload the page.");
          seterrorOpen(true);
        })
    );
  }
  ////CompanyNameCheck
  async function companyNameCheck(cname) {
    try {
      trackPromise(
        Service.licenseeCompanyName({ companyname: cname }).then((res) => {
          if (res.data === false) {
            setError({
              ...error,
              companyname: true,
              nameMsg: "This​ Company ​name ​already​ exist",
            });
          } else if (cname.includes(",")) {
            setError({
              ...error,
              companyname: true,
              nameMsg: "Please enter a name without comma",
            });
          }
          else if (nameReg.test(cname) === false) {
            setError({
              ...error,
              companyname: true,
              nameMsg: "Field must have at least 1 character a-z or A-Z",
            });
          }
          else {
            setError({
              ...error,
              companyname: false,
              nameMsg: ""
            });
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  const typeRef = React.useRef();
  const cmpyNameRef = React.useRef();
  const statusRef = React.useRef();
  const owingRef = React.useRef();
  const validatePublisher = (invite) => {
    let pbCount = 0;
    const nwerror = { ...error };
    if (!customerdropdown) {
      if (invite.company_types === "") {

        nwerror.company_types = true;
        pbCount++;
        typeRef.current.focus();
      }
      else {
        nwerror.company_types = false;
      }

      if (invite.companyname === "") {
        nwerror.companyname = true;
        nwerror.nameMsg = "This field is required"
        pbCount++;
        cmpyNameRef.current.focus();
      }
      else {

        if (error.companyname === true) {
          nwerror.companyname = true;
          nwerror.nameMsg = error.nameMsg;
          pbCount++;
          cmpyNameRef.current.focus();
        }
        else {
          nwerror.companyname = false;
          nwerror.nameMsg = "";
        }
      }

      if (invite.status === "") {
        nwerror.status = true;
        pbCount++;
        statusRef.current.focus();
      }
      else {
        nwerror.status = false;
      }

      if (checked) {
        if (invite.email_address !== "") {

          if (emailreg.test(invite.email_address) === false) {
            nwerror.email_address = true;
            nwerror.validemailmsg = "Enter a valid email address";
            pbCount++;
          }
          else if (emailVal === false) {
            nwerror.email_address = true;
            nwerror.validemailmsg = "This email already exits";
            pbCount++;
          }
          else {
            nwerror.email_address = false;
            nwerror.validemailmsg = "";
          }
        }
        else {
          nwerror.email_address = true;
          nwerror.validemailmsg = "This field is required";
          pbCount++;
        }

        if (invite.users_name !== "") {

          nwerror.users_name = false;
        }
        else {
          nwerror.users_name = true;
          pbCount++;
        }
      }


      if (invite.reg_company_name === "") {
        nwerror.reg_company_name = false;
        nwerror.regNameMsg = "";
      }
      else {
        if (nameReg.test(invite.reg_company_name) === false) {
          nwerror.reg_company_name = true;
          nwerror.regNameMsg = "Field must have at least 1 character a-z or A-Z";
          pbCount++;
        }
        else {
          nwerror.reg_company_name = false;
          nwerror.regNameMsg = "";
        }
      }


      if (invite.reg_address === "") {
        nwerror.reg_address = false;
        nwerror.reg_addressmsg = "";
      }
      else {
        if (nameReg.test(invite.reg_address) === false) {
          nwerror.reg_address = true;
          nwerror.reg_addressmsg = "Field must have at least 1 character a-z or A-Z";
          pbCount++;
        }
        else {
          nwerror.reg_address = false;
          nwerror.reg_addressmsg = "";
        }
      }

      if (invite.reg_business_number === "") {
        nwerror.reg_business_number = false;
        nwerror.regBusNoMsg = "";
      }
      else {
        if (validBusinessRegex.test(invite.reg_business_number) === false) {
          nwerror.reg_business_number = true;
          nwerror.regBusNoMsg = "Only alphanumeric characters or underscore and the first character must be a letter";
          pbCount++;
        } else {
          nwerror.reg_business_number = false;
          nwerror.regBusNoMsg = "";
        }
      }


      if (invite.reg_phone_number === "") {
        nwerror.reg_phone_number = false;
        nwerror.regNoMsg = "";
      }
      else {
        if (validNumberRegex.test(invite.reg_phone_number) === false) {
          nwerror.reg_phone_number = true;
          nwerror.regNoMsg = "Valid characters are '+' 0-9 and space";
          pbCount++;
        } else {
          nwerror.reg_phone_number = false;
          nwerror.regNoMsg = "";
        }
      }

    }
    else {

      if (invite.company_types === "") {

        nwerror.company_types = true;
        pbCount++;
        typeRef.current.focus();
      }
      else {
        nwerror.company_types = false;
      }

      if (invite.companyname === "") {
        nwerror.companyname = true;
        nwerror.nameMsg = "This field is required"
        pbCount++;
        cmpyNameRef.current.focus();
      }
      else {

        if (error.companyname === true) {
          nwerror.companyname = true;
          nwerror.nameMsg = error.nameMsg;
          pbCount++;
          cmpyNameRef.current.focus();
        }
        else {
          nwerror.companyname = false;
          nwerror.nameMsg = "";
        }
      }

      if (invite.status === "") {
        nwerror.status = true;
        pbCount++;
        statusRef.current.focus();
      }
      else {
        nwerror.status = false;
      }

      if (checked) {
        if (invite.email_address !== "") {

          if (emailreg.test(invite.email_address) === false) {
            nwerror.email_address = true;
            nwerror.validemailmsg = "Enter a valid email address";
            pbCount++;
          }
          else if (emailVal === false) {
            nwerror.email_address = true;
            nwerror.validemailmsg = "This email already exits";
            pbCount++;
          }
          else {
            nwerror.email_address = false;
            nwerror.validemailmsg = "";
          }
        }
        else {
          nwerror.email_address = true;
          nwerror.validemailmsg = "This field is required";
          pbCount++;
        }

        if (invite.users_name !== "") {

          nwerror.users_name = false;
        }
        else {
          nwerror.users_name = true;
          pbCount++;
        }
      }


      if (invite.reg_company_name === "") {
        nwerror.reg_company_name = false;
        nwerror.regNameMsg = "";
      }
      else {
        if (nameReg.test(invite.reg_company_name) === false) {
          nwerror.reg_company_name = true;
          nwerror.regNameMsg = "Field must have at least 1 character a-z or A-Z";
          pbCount++;
        }
        else {
          nwerror.reg_company_name = false;
          nwerror.regNameMsg = "";
        }
      }


      if (invite.reg_address === "") {
        nwerror.reg_address = false;
        nwerror.reg_addressmsg = "";
      }
      else {
        if (nameReg.test(invite.reg_address) === false) {
          nwerror.reg_address = true;
          nwerror.reg_addressmsg = "Field must have at least 1 character a-z or A-Z";
          pbCount++;
        }
        else {
          nwerror.reg_address = false;
          nwerror.reg_addressmsg = "";
        }
      }

      if (invite.reg_business_number === "") {
        nwerror.reg_business_number = false;
        nwerror.regBusNoMsg = "";
      }
      else {
        if (validBusinessRegex.test(invite.reg_business_number) === false) {
          nwerror.reg_business_number = true;
          nwerror.regBusNoMsg = "Only alphanumeric characters or underscore and the first character must be a letter";
          pbCount++;
        } else {
          nwerror.reg_business_number = false;
          nwerror.regBusNoMsg = "";
        }
      }


      if (invite.reg_phone_number === "") {
        nwerror.reg_phone_number = false;
        nwerror.regNoMsg = "";
      }
      else {
        if (validNumberRegex.test(invite.reg_phone_number) === false) {
          nwerror.reg_phone_number = true;
          nwerror.regNoMsg = "Valid characters are '+' 0-9 and space";
          pbCount++;
        } else {
          nwerror.reg_phone_number = false;
          nwerror.regNoMsg = "";
        }
      }

      if (invite.owning_channel === "") {

        nwerror.owning_channel = true;
        pbCount++;
        owingRef.current.focus();
      }
      else {
        nwerror.owning_channel = false;
      }

      if (invite.customer_identifier === "") {
        nwerror.customer_identifier = false;
        nwerror.custMsg = "";
      }
      else {
        if (custIdReg.test(invite.customer_identifier) === false) {
          nwerror.customer_identifier = true;
          nwerror.custMsg = "Only alphanumeric characters and hyphen is allowed";
          pbCount++;
        } else {
          nwerror.customer_identifier = false;
          nwerror.custMsg = "";
        }
      }

      if (invite.discount_price === "") {
        nwerror.discount_price = false;
        nwerror.discountMsg = "";
      }
      else {
        if (numberRegex.test(invite.discount_price) === false) {
          nwerror.discount_price = true;
          nwerror.discountMsg = "Please enter a valid number.";
          pbCount++;
        }
        else if (invite.discount_price > 99.9) {
          nwerror.discount_price = true;
          nwerror.discountMsg = "Please enter a value less than or equal to 99.9.";
          pbCount++;
        }
        else {
          nwerror.discount_price = false;
          nwerror.discountMsg = "";
        }
      }

      if (invite.manager_email !== "") {

        if (emailreg.test(invite.manager_email) === false) {
          nwerror.manager_email = true;
          nwerror.manager_emailmsg = "Enter a valid email address";
          pbCount++;
        }
        else {
          nwerror.manager_email = false;
          nwerror.manager_emailmsg = "";
        }
      }
      else {
        nwerror.manager_email = false;
        nwerror.manager_emailmsg = "";
      }

    }
    setError(nwerror);
    return pbCount;
  }

  //invite companyUser

  const [clickSave, setClickSave] = useState(false);
  const [loading, setLoading] = useState(false);
  async function inviteCompanys() {
    const pubcounts = validatePublisher(invite);

    if (pubcounts === 0) {

      setClickSave(true);
      setLoading(true);
      trackPromise(
        Service.AddLicenseeCompany(invite).then((res) => {

          if (res.status === 200) {
            setClickSave(false);
            setsuccessOpen(true);
            setOpen(false);
            setInvite({
              company_types: "",
              licenseeId: "",
              owning_channel: "",
              companyname: "",
              licensee_shortcode: "",
              customer_identifier: "",
              credit_limit: "",
              status: "",
              discount_price: "",
              manager_email: "",
              reg_company_name: "",
              reg_business_number: "",
              reg_address: "",
              reg_phone_number: "",
              option_create_user: "",
              users_name: "",
              email_address: ""
            });
            setLoading(false);
          }
        }).catch((error) => {
          setErrMsg("Something went wrong. Please reload the page.");
          seterrorOpen(true);
        })
      );
    }


  }

  useEffect(() => {
    publisherIndex();
    Userdata();
  }, []);

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Publisher Details - Licensee View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                  Channel
                </td>
                <td className="col-md-2">
                  Company Type
                </td>
                <td className="col-md-1">
                  Company Id
                </td>
                <td className="col-md-2">
                  Company Name
                </td>
                <td className="col-md-2">
                  Status
                </td>
                <td className="col-md-2">

                </td>
              </tr>
              <tr>
                <td className="col-md-1">
                  <select
                    type="text"
                    className="form-control form-select"
                    id="searchChannel"
                    name="searchChannel"

                    onChange={(e) => dataChange(e)}
                  >
                    <option value=""></option>
                    {channelDropdown && channelDropdown.length > 0
                      ? channelDropdown.map((post) => (
                        <option key={post.companyId} value={post.companyId}>
                          {post.name}
                        </option>
                      ))
                      : null}
                  </select>
                </td>
                <td className="col-md-2">
                  <select
                    type="text"
                    className="form-control form-select"
                    id="searchCompanyType"
                    name="searchCompanyType"
                    onChange={(e) => dataChange(e)}

                  >
                    <option value=""></option>
                    <option value="CUSTOMER">CUSTOMER</option>
                    <option value="RESELLER">RESELLER</option>
                  </select>
                </td>
                <td className="col-md-1">
                  <input
                    type="text"
                    className="form-control"
                    id="searchPublisherId"
                    name="searchPublisherId"
                    onChange={(e) => dataChange(e)}
                  ></input>

                </td>
                <td className="col-md-3">
                  <input
                    type="text"
                    className="form-control"

                    id="searchPublisherName"
                    name="searchPublisherName"
                    onChange={(e) => dataChange(e)}
                  ></input>
                </td>
                <td className="col-md-3">
                  <select
                    type="text"
                    className="form-control form-select"
                    id="searchStatus"
                    name="searchStatus"
                    onChange={(e) => dataChange(e)}
                  >
                    <option value=""></option>
                    <option value="New">New</option>
                    <option value="Approved">Approved</option>
                    <option value="NoAgreement">NoAgreement</option>
                    <option value="Suspended">Suspended</option>
                    <option value="Archived">Archived</option>
                  </select>
                </td>
                <td className="col-md-3">
                  <button
                    className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                    disabled={changedropdown ? "disabled" : false}

                    onClick={LicenseeIndexdata}
                  >
                    Search
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>
        <div className="createButtonDiv">
          <button className="licbtn" onClick={() => handleClickOpen("body")}
            title="Click here to create new Company">
            + Create New Publisher
          </button>
        </div>

        {search ? (
          <div>
            {channelList && channelList.length > 0 ? (
              <VMCTable key={channelList} data={channelList} col={tablecols} navigateFunction={handleRouteLink} tableName="Licensee Publisher" />
            ) :
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Companies Found" col={tablecols} tableName="Connect Utility Table" /> : null}
              </div>
            }
          </div>
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Create Publisher - Licensee View
            <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">Company Type</div>
              <select
                type="text"
                id="company_types"
                name="company_types"
                label="company_type"

                className={error.company_types ? "form-control form-select errorField" : "form-control form-select"}
                onChange={(e) => InviteCompany(e)}
                ref={typeRef}
                fullWidth
              >
                <option value=""></option>
                <option value="CUSTOMER">CUSTOMER</option>

                <option value="RESELLER">RESELLER</option>
              </select>
              {error.company_types ? <span className="errorClass">This field is required.</span> : null}
            </Grid>

            <Grid item xs={6}>
              {customerdropdown ? (
                <div>
                  <div className="cmpdialoginputtext">Owning Channel</div>
                  <select
                    type="text"
                    id="owning_channel"
                    name="owning_channel"
                    label="company_type"

                    className={error.owning_channel ? "form-control form-select errorField" : "form-control form-select"}
                    onChange={(e) => InviteCompany(e)}
                    ref={owingRef}
                    fullWidth
                  >

                    <option value=""></option>
                    {owningChannel && owningChannel.length > 0
                      ? owningChannel.map((post) => (
                        <option
                          key={post.companyId}
                          value={post.companyId}
                        >
                          {post.name}
                        </option>
                      ))
                      : null}
                  </select>
                </div>
              ) : null}
              {error.owning_channel ? <span className="errorClass">This field is required.</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Name</div>
              <input
                type="text"
                id="companyname"
                name="companyname"
                label="email"
                className={error.companyname ? "form-control errorField" : "form-control"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
                ref={cmpyNameRef}
              />
              {error.companyname ? <span className="errorClass">{error.nameMsg ? error.nameMsg : "This field is required"}</span> : null}
            </Grid>
            {customerdropdown ? (
              <Grid item xs={12}>
                <div className="cmpdialoginputtext">
                  Channel Customer Identifier
                </div>
                <input
                  type="text"
                  id="customer_identifier"
                  name="customer_identifier"
                  label="email"
                  className={error.customer_identifier ? "form-control errorField" : "form-control"}
                  onChange={(e) => InviteCompany(e)}
                  fullWidth
                />
                {error.customer_identifier ? <span className="errorClass">{error.custMsg}</span> : null}
              </Grid>
            ) : null}
            {customerdropdown ? (
              <Grid item xs={6}>
                <div>
                  <div className="cmpdialoginputtext">
                    #datafree Data Credit Limit
                  </div>
                  <select
                    type="text"
                    id="credit_limit"
                    label="credit_limit"
                    className="form-control form-select"

                    onChange={(e) => InviteCompany(e)}
                    fullWidth
                  >
                    <option value=""></option>
                    {creditLimit && creditLimit.length > 0
                      ? creditLimit.map((post) => (
                        <option
                          key={post.creditLimitId}
                          value={post.creditLimitId}
                        >
                          {post.creditLimit}
                        </option>
                      ))
                      : null}
                  </select>
                </div>
              </Grid>
            ) : null}

            <Grid item xs={6}>
              <div className="cmpdialoginputtext">Status</div>
              <select
                type="text"
                id="status"
                name="status"

                className={error.status ? "form-control form-select errorField" : "form-control form-select"}
                onChange={(e) => InviteCompany(e)}
                ref={statusRef}
                fullWidth
              >
                <option></option>
                <option value="New">New</option>
                <option value="NoAgreement">NoAgreement</option>
              </select>
              {error.status ? <span className="errorClass">This field is required.</span> : null}
            </Grid>

            {customerdropdown ? (
              <Grid item xs={6}>
                <div className="cmpdialoginputtext">Price Discount %</div>
                <input
                  type="text"
                  id="discount_price"
                  name="discount_price"
                  label="company_type"

                  className={error.discount_price ? "form-control errorField" : "form-control"}
                  onChange={(e) => InviteCompany(e)}
                />
                {error.discount_price ? <span className="errorClass">{error.discountMsg}</span> : null}
              </Grid>
            ) : null}
            {customerdropdown ? (
              <Grid item xs={6}>
                <div className="cmpdialoginputtext">Account Manager Email</div>
                <input
                  type="text"
                  id="manager_email"
                  name="manager_email"
                  label="company_type"

                  className={error.manager_email ? "form-control errorField" : "form-control"}
                  onChange={(e) => InviteCompany(e)}
                  fullWidth
                />
                {error.manager_email ? <span className="errorClass">{error.manager_emailmsg}.</span> : null}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Registered Company Name</div>
              <input
                type="text"
                id="reg_company_name"
                name="reg_company_name"
                label="companyname"
                className={error.reg_company_name ? "form-control errorField" : "form-control"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
              {error.reg_company_name ? <span className="errorClass">{error.regNameMsg}.</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">
                Registered Business Number (if appropriate)
              </div>
              <input
                type="text"
                id="reg_business_number"
                name="reg_business_number"
                label="email"
                className={error.reg_business_number ? "form-control errorField" : "form-control"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
              {error.reg_business_number ? <span className="errorClass">{error.regBusNoMsg}.</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Registered Address</div>
              <input
                type="text"
                id="reg_address"
                name="reg_address"
                label="email"
                className={error.reg_address ? "form-control errorField" : "form-control"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
              {error.reg_address ? <span className="errorClass">{error.reg_addressmsg}.</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Phone Number</div>
              <input
                type="text"
                id="reg_phone_number"
                name="reg_phone_number"
                label="email"
                className={error.reg_phone_number ? "form-control errorField" : "form-control"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
              {error.reg_phone_number ? <span className="errorClass">{error.regNoMsg}.</span> : null}
            </Grid>

            <div>
              <input
                type="checkbox"
                name="option_create_user"
                id="option_create_user"
                onChange={() => setChecked(!checked)}
                checked={checked}
              />
              <label for="websiteName" className="cmpdialoginputtext">
                Optionally Create User ?
              </label>
            </div>
            {checked ? (
              <>
                <Grid item xs={12}>
                  <div className="cmpdialoginputtext">User’s Name</div>
                  <input
                    type="text"
                    id="users_name"
                    name="users_name"
                    label="users_name"
                    className={error.users_name ? "form-control errorField" : "form-control"}
                    onChange={(e) => InviteCompany(e)}
                    fullWidth
                  />
                  {error.users_name ? <span className="errorClass">This field is required.</span> : null}
                </Grid>
                <Grid item xs={12}>
                  <div className="cmpdialoginputtext">
                    Email Address (username)
                  </div>
                  <input
                    type="text"
                    id="email_address"
                    name="email_address"
                    label="email"
                    className={error.email_address ? "form-control errorField" : "form-control"}
                    onChange={(e) => InviteCompany(e)}
                    fullWidth
                  />
                  {error.email_address ? <span className="errorClass">{error.validemailmsg ? error.validemailmsg : "This field is required"}</span> : null}
                </Grid>
              </>
            ) : (
              <div></div>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <button
              className="inviteButton"
              disabled={clickSave ? "disabled" : false}
              onClick={inviteCompanys}
              color="primary"
            >
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successopen}
        autoHideDuration={1500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          "New Publisher Created Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={erroropen}
        autoHideDuration={2500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default PublisherList;
