import React, { useState, useEffect } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import { useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AdminService from "../../../api/adminService";
import VMCTable from "../../../Recomponent/Table";
import EmptyTable from "../../../Recomponent/EmptyTable";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function DataFreeDirectApplication() {
  const [search, setSearch] = useState(false);
  const [searchDetails, setSearchDetails] = useState();
  const [responseData, setResponseData] = useState([]);
  const [manageList, setManageList] = useState();
  const [changedropdown, setChangedropdown] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [data, setData] = useState({
    searchChannel: "",
    searchDeploymentType: "",
    searchDeploymentStatus: "",
    searchBinuId: "",
    searchApplicationName: "",
    searchFreeDomain: "",
    searchPublisherName: "",
    searchProductType: "5",
  });

  const [tablecols] = useState([
    {
      colName: "Channel",
      colMap: "publisherIdparentCompanyIdname",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Publisher",
      colMap: "publisherIdname",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Customer Type",
      colMap: "publisherIdcompanyType",
      linkName: false,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Customer Status",
      colMap: "publisherIdstatus",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "App Name",
      colMap: "name",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Free Domain",
      colMap: "reachSubDomain",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Deployment Type",
      colMap: "deploymentType1", //appdevdeptypedeptypeor,appproddeptype
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },

    {
      colName: "biNu App Id",
      colMap: "biNuAppId", //appdevdeptypedeptypeor,appproddeptype
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Customer End Point",
      colMap: "directIpForward", //only if value
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Production Data Usage Mb (28 days)",
      colMap: "prodDataUsage28Days", //if not 0
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]); //no application found


  let history = useHistory();

  const handleClickOpen = (scrollType) => {
    history.push("/admin/datafreedirect/application/create");
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const [errMsg, setErrMsg] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  const handleRouteLink = (companytype, companyId) => {

    if (companytype === "CUSTOMER" || "RESLR CLNT") {
      history.push(`/admin/customer/${companyId}/detail/view`);
    }
    if (companytype === "CHANNEL") {
      history.push(`/admin/channel/${companyId}/detail/view`, {
        companyId: companyId,
      });
    }
    if (companytype === "LICENSEE") {
      history.push(`/admin/licensee/${companyId}/detail/view`, {
        licenseeId: companyId,
      });
    }


  };
  const appLink = (appNameId) => {
    history.push(`/admin/datafreedirect/application/${appNameId}/detail/view`);
  }
  const lifecycleChange = (e) => {
    e.preventDefault();
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };

  //managed By List
  async function manageListdata() {
    try {
      trackPromise(
        AdminService.datafreeApplicationIndex().then((res) => {
          setManageList(res.data.channel);
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  const [empty, setEmpty] = useState(false);

  //search
  async function dataFreeListListSearch() {
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setSearchDetails({});

    try {
      trackPromise(
        AdminService.datafreeApplicationSearch(data).then((res) => {
          setResponseData(res.data);
          setSearchDetails(res.data.applicationListResult);
          if (res.status === 200 && res.data.applicationListResult.length > 0) {

            setChangedropdown(false);
          } else if (res.status === 200 && res.data.applicationListResult.length === 0) {
            setChangedropdown(false);
            setEmpty(true);
          } else {
            setChangedropdown(true);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  useEffect(() => {
    manageListdata();
  }, []);

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">#datafree DIRECT Application List - Admin View</div>
        </div>


        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                  Channel
                </td>
                <td className="col-md-2">
                  Deployment Type
                </td>
                <td className="col-md-1">
                  Status
                </td>
                <td className="col-md-2">
                  biNu Id
                </td>
                <td className="col-md-2">
                  Application Name
                </td>
                <td className="col-md-2">
                  Free Domain
                </td>
                <td className="col-md-2">
                  Publisher Name
                </td>
                <td className="col-md-2">

                </td>
              </tr>
              <tr>
                <td className="col-md-2">
                  <select type="text" className="form-control form-select" id="searchParentCompany" name="searchParentCompany" onChange={(e) => lifecycleChange(e)}>
                    <option value=""></option>

                    {manageList && manageList.length > 0
                      ? manageList.map((post) => (
                        <option key={post.companyId} value={post.companyId}>
                          {post.name}
                        </option>
                      ))
                      : null}
                  </select>
                </td>
                <td className="col-md-2">
                  <select type="text" className="form-control form-select" id="searchDeploymentType" name="searchDeploymentType" onChange={(e) => lifecycleChange(e)}>
                    <option value=""></option>
                    <option value="PROD">PROD</option>
                    <option value="DEV">DEV</option>
                  </select>

                </td>
                <td className="col-md-2">
                  <select type="text" className="form-control form-select" id="searchDeploymentStatus" name="searchDeploymentStatus" onChange={(e) => lifecycleChange(e)}>
                    {" "}
                    <option value=""></option>
                    <option value="Live">Live</option>
                    <option value="Deleted">Deleted</option>
                  </select>
                </td>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="searchBinuId" name="searchBinuId" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="searchApplicationName" name="searchApplicationName" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="searchFreeDomain" name="searchFreeDomain" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="searchPublisherName" name="searchPublisherName" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                  <button className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                    disabled={changedropdown ? "disabled" : false}
                    onClick={dataFreeListListSearch}>
                    Search
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>
        <div className="createButtonDiv">
          <button className="defultbtn"
            title="Click here to create new #datafree DIRECT App"
            onClick={() => handleClickOpen("body")}>
            + Create New #datafree DIRECT App
          </button>
        </div>

        {search ? (
          <div>
            {searchDetails && searchDetails.length > 0 && responseData ? (
              <VMCTable key={searchDetails} data={searchDetails} additionalData1={responseData} col={tablecols} navigateFunction={handleRouteLink} onClickApp={appLink} tableName="Admin data free direct application" />
            ) :
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Applications Found" col={tablecols} tableName="Admin data free direct application" /> : null}
              </div>
            }
          </div>
        ) : null}
      </div>

      <Snackbar open={successopen} autoHideDuration={2500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          "Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={2500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default DataFreeDirectApplication;
