import React, { useState, useContext } from "react";
import Style from "./style";
import datafreelogo from "../../assets/img/datafree_logo_white.png";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import jwt from "jwt-decode";
import service from "../../api/service";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loading";
import { MdClose } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
  Dialog,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  userDispatchContext,roleDispatchContext
} from "../../api/context"

const useStyles = makeStyles(Style);

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function Login(props) {
  const classes = useStyles();
  let history = useHistory();
  const message = props?.location?.state?.message ? props?.location?.state?.message : "";
  const [signinState, setSigninState] = useState(true);
  // const [signUpState, setSignupState] = useState(false);
  const [load, setLoad] = useState(false);
  const [companies, setCompanies] = useState();
  const [loginState, setLoginState] = useState({
    username: "",
    password: "",
    recaptcha_response: ""
  });
  const [open, setOpen] = React.useState(false);
  const [flagVal, setFlagVal] = React.useState(false);
  const [forgetState, setForgetState] = useState(false);
  let siteKeys = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  // let secretKey = process.env.REACT_APP_SECRET_KEY;

  const [forgetData, setForgetData] = useState({
    email: "",
  });
  const handleClose = () => {
    setOpen(false)
  }
  const userDispatchContextVal = useContext(userDispatchContext)
  const roleDispatchContextVal = useContext(roleDispatchContext)
  const [error, setError] = useState({
    username: false,
    password: false,
    msg: "",
    response: false,
    email: false,
    forgetres: false,
    forgeteror: false,
    loginCheckRes: false,
    loginCheckmsg: "",
  });

  const LoginHandler = (e) => {
    errorHandler(e);
    const newdata = { ...loginState };
    newdata[e.target.id] = e.target.value;
    setLoginState(newdata);
  };
  const [errRes, setErrRes] = useState()
  const reg = /^\w+([.+-]?\w+)*@\w+([.+-]?\w+)*(\.\w{2,3})+$/;
  const errorHandler = (e) => {
    if (e.target.id === "username") {
      setError({
        username: true,
      });
      if (reg.test(e.target.value) === false) {
        setError({
          username: true,
          msg: "Please enter a valid email address.",
        });
      } else {
        setError({
          username: false,
        });
      }
    } else if (e.target.id === "password") {
      setError({
        password: false,
      });
    } else if (e.target.id === "email") {
      setError({
        email: true,
      });
      if (reg.test(e.target.value) === false) {
        setError({
          email: true,
          msg: "Please enter a valid email address.",
        });
      } else {
        setError({
          email: false,
        });
      }
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const loginSubmit = () => {

    if (loginState.username.length === 0) {
      setError({
        username: true,
        msg: "This field is required",
      });
    } else if (reg.test(loginState.username) === false) {

      setError({
        username: true,
        msg: "Please enter a valid email address.",
      });

    } else if (loginState.password.length === 0) {
      setError({
        password: true,
        msg: "This field is required",
      });
    } else {
      setLoad(true);
      // service.verifyCaptcha({ "secret_key": secretKey, "recaptcha_response": localStorage.getItem('recaptcha_token') }).then((res) => {
      setFlagVal(true);
      // if (res.data.recaptcharesponse === true) {
      loginState['recaptcha_response'] = localStorage.getItem('recaptcha_token')
      trackPromise(
        service
          .Login(loginState)
          .then((res) => {

            setLoad(false);
            if (res.status === 200) {

              localStorage.setItem("token", res.data.token);
              userDispatchContextVal({ token: res.data.token })
              loginCheck();
            } else {

              setError({
                response: true,
              });
            }
          })
          .catch((err) => {
          
            setErrRes(err?.response?.data?.message)
            setLoad(false);
            setError({
              response: true,
            });
          })
      );
    }

    // })


    // }
  };

  //loginCheck
  const loginCheck = () => {

    trackPromise(
      service
        .CheckLogin()
        .then((res) => {

          if (res.status === 200) {
            localStorage.setItem("sidebar-Collapsed", true);
            if (res?.data?.token) {
              localStorage.removeItem("token");

              localStorage.setItem("token", res?.data?.token);
              userDispatchContextVal({ token: res.data.token })
              var decode1 = jwt(res?.data?.token);
              localStorage.setItem("role", decode1?.roles[0]);
              if (decode1.roles[0] === "ROLE_ADMIN") {

                history.push("/admin/company/index");
              }
              else if (decode1.roles[0] !== "ROLE_ADMIN") {

                // if (res?.data?.token) {
                if (decode1.roles[0] === "ROLE_USER") {
                  history.push("/user/datafree");

                } else if (decode1.roles[0] === "ROLE_LICENSEE") {
                  history.push("/licensee/index/action");
                } else if (decode1.roles[0] === "ROLE_CHANNEL") {
                  history.push("/channel/applications/index");
                }
                else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER") {
                  history.push("/accountmanager/applications/index");
                }

              }


            } else {
              setCompanies(res?.data);
              handleClickOpen()
            }
          }
        })
        .catch((error) => {

          if (error.response.status === 400) {
            setError({
              loginCheckRes: true,
              loginCheckmsg: error.response.data.loginFailedMessage,
            });
          }
        })
    );
  };
  const loginUser = (role, Id, cname, ctype) => {
    localStorage.setItem("companyname", cname);
    localStorage.setItem("companytype", ctype);
    localStorage.setItem("companyrole", role);
    roleDispatchContextVal({crole:role})
    localStorage.setItem("companyId", Id);
    service
      .UserLogin({ "role": role, "companyId": Id })
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("token");
          localStorage.setItem("token", res?.data);
          userDispatchContextVal({ token: res.data })
          var decode1 = jwt(res?.data);
          localStorage.setItem("role", decode1?.roles[0]);
          if (decode1.roles[0] === "ROLE_USER") {
            history.push("/user/datafree");

          } else if (decode1.roles[0] === "ROLE_ADMIN") {
            history.push("/admin/company/index");
          } else if (decode1.roles[0] === "ROLE_LICENSEE") {
            history.push("/licensee/index/action");
          } else if (decode1.roles[0] === "ROLE_CHANNEL") {
            history.push("/channel/applications/index");
          }
          else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER") {
            history.push("/accountmanager/applications/index");
          }
        }

      })
  }

  const clearMsg = () => {
    let x = document.getElementById("buildmsgoff");
    x.style.display = "none";
  }

  const handleRouterLink = () => {
    setForgetState(true);
    setSigninState(false);
  };

  const redirectLogin = () => {
    setForgetState(false);
    setSigninState(true);
  };

  const emailHandler = (e) => {
    errorHandler(e);
    const newforgetData = { ...forgetData };
    newforgetData[e.target.id] = e.target.value;
    setForgetData(newforgetData);
  };
  const [forgotVal, setForgotVal] = useState({
    forgState: false,
    forgMsg: "",
  });
  const forgetPassword = () => {
    if (forgetData.email.length === 0) {
      setError({
        email: true,
        msg: "This field is required",
      });
    } else if (reg.test(forgetData.email) === false) {
      setError({
        email: true,
        msg: "Please enter a valid email address.",
      });
    } else {
      trackPromise(
        service
          .forgetPassword(forgetData)
          .then((res) => {

            if (res.data.forgotPasswordEmailSent) {
              setError({
                forgetres: true,
                msg: res.data.forgotPasswordEmailSent.message,
              });
              setForgotVal({
                forgState: true,
                forgMsg: res.data.forgotPasswordEmailSent.message,
              });
              setForgetState(false);
              setSigninState(true);
            } else if (res.data.emailValidationErrorForgotPassword) {
              setError({
                forgeteror: true,
              });
            } else {
              setError({
                forgeteror: false,
              });
            }

          })
          .catch((err) => {
            setError({
              response: true,
            });
          })
      );
    }
  };

  const cancelMsg = () => {
    setError(false);
    setForgotVal({
      forgState: false,
      forgMsg: "",
    });
  }

  const forget = () => {
    return (
      <div className={classes.forgetBox}>
        <div className={classes.form}>
          <div className={classes.headText}>Forgotten password ?</div>

          <div className={classes.forgetlabel}>Enter your email address to reset your password.</div>
          <div style={{ width: "100%" }}>
            {" "}
            <input
              type="text"
              style={{
                backgroundColor: error.username ? "#edd" : "#dde3ec",
                border: error.username ? "1px solid #c00" : "1px solid #dde3ec",
              }}
              className={classes.textField}
              required
              placeholder="Email"
              onChange={(e) => emailHandler(e)}
              id="email"
              name="email"
            />
            {error.email ? <span style={{ fontSize: "12px", color: "#c00" }}>{error.msg}</span> : null}
            {error.forgeteror ? <span style={{ fontSize: "12px", color: "#c00" }}>Email does not exist.</span> : null}
          </div>
          <div className={classes.flexRow}>
            <div className={classes.Backbutton} onClick={redirectLogin}>
              Back
            </div>

            {!error.forgeteror ? (
              <div className={classes.button} onClick={forgetPassword}>
                SUBMIT
              </div>
            ) : (
              <div className={classes.disablebtn}>SUBMIT</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.paper}>
      <div className={classes.outer}>
        <div>
          <img src={datafreelogo} alt="" className={classes.datafreelogocls}></img>
        </div>
        <div className={classes.subLogoImg}>Datafree Portal</div>
        {signinState ? (
          <div className={classes.box}>
            <div className={classes.form}>
              <div className={classes.headText}>Sign In</div>
              {error.response ? (
                <div
                  style={{
                    backgroundColor: "#edd",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: "12px", padding: "13px", color: "#c00" }} disabled="disabled">
                    {errRes ? errRes : "Please enter valid username or password"}
                  </div>
                  <div
                    style={{
                      padding: "13px",
                      cursor: "pointer",
                      color: "#999999",
                      fontSize: "13px",
                    }}
                  >
                    <MdClose onClick={() => setError(false)} />
                  </div>
                </div>
              ) : null}
              {message ?
                <div className={classes.alertsSuccess} id="buildmsgoff" style={{ display: 'block' }}>
                  <MdClose className="modalClose" name="popup" style={{ color: '#aba8a8' }} onClick={clearMsg} />
                  <span>{message}</span>

                </div>
                : null}
            
              {error.forgetres || forgotVal.forgState ?
                <div className={classes.alertsSuccess} id="buildmsgoff" style={{ display: 'block' }}>
                  <MdClose className="modalClose" name="popup" style={{ color: '#aba8a8' }} onClick={cancelMsg} />
                  <span> {forgotVal.forgMsg} </span>
                </div>
                : null}
              {error.loginCheckRes ? (
                <div
                  style={{
                    backgroundColor: "#edd",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="errClassMsg" disabled="disabled">
                    {error.loginCheckmsg}
                  </div>
                  <div
                    style={{
                      padding: "13px",
                      cursor: "pointer",
                      color: "#999999",
                      fontSize: "13px",
                    }}
                  >
                    <MdClose onClick={() => setError(false)} />
                  </div>
                </div>
              ) : null}
              <div style={{ width: "100%" }}>
                <input
                  type="email"
                  style={{
                    backgroundColor: error.username ? "#edd" : "#dde3ec",
                    border: error.username ? "1px solid #c00" : "1px solid #dde3ec",
                  }}
                  className={classes.textField}
                  required="required"
                  placeholder="Email"
                  id="username"
                  name="username"
                  onChange={(e) => LoginHandler(e)}
                />

                {error.username ? <span style={{ fontSize: "11px", fontWeight: "500", color: "#c00" }}>{error.msg}</span> : null}
              </div>
              <div>
                <input
                  type="password"
                  className={classes.textField}
                  style={{
                    backgroundColor: error.password ? "#edd" : "#dde3ec",
                    border: error.password ? "1px solid #c00" : "1px solid #dde3ec",
                  }}
                  required="required"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={(e) => LoginHandler(e)}
                />
                {error.password ? <span style={{ fontSize: "11px", fontWeight: "500", color: "#c00" }}>{error.msg}</span> : null}
              </div>
              <div className={classes.flexRow}>
                <div>
                  <button className={classes.button} onClick={loginSubmit} disabled={load}>
                    LOGIN
                  </button>
                </div>

                <LoadingIndicator />
                <div className={classes.forgetLink} onClick={handleRouterLink}>
                  Forgot Password?
                </div>


              </div>
            </div>
            <div className={classes.createAccount}
            >
              <a className="regCompany" href="https://datafr.ee/join/" target="_blank" rel="noreferrer"> REGISTER FOR A NEW COMPANY ACCOUNT</a>
            </div>
          </div>
        ) : null}
        {forgetState ? forget() : null}
        {/* {signUpState ? signUp() : null} */}
        {!flagVal ?
          <GoogleReCaptchaProvider
            reCaptchaKey={siteKeys}
          >
            <GoogleReCaptcha onVerify={(t) => localStorage.setItem('recaptcha_token', t)} />
          </GoogleReCaptchaProvider> : ""}
      </div>
      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }}

        fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="FlexRow">
            <div className="cmpdialogtitle"> Please choose the Account you wish to Log in to</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }}
                className="modalClose"
                onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <table className={classes.popupEnter}>
              <thead>
                <tr className={classes.popupRow}>
                  <th className={classes.popupHead}> Company</th>
                  <th className={classes.popupHead}>Type</th>
                  <th className={classes.popupHead}>Your Role</th>
                  <th></th>
                </tr>
              </thead>
              {companies?.length >= 0 ?
                companies?.map((item, index) => (
                  <tbody key={index}>
                    <tr className={classes.popupRow}>
                      <td className={classes.popupTitle}>{item?.company_name}</td>
                      <td className={classes.popupTitle}>{item?.company_type}</td>
                      <td className={classes.popupTitle}>{item?.role}</td>
                      <td><button className={classes.button} onClick={() => loginUser(item?.role, item?.company_id?.companyId, item?.company_name, item?.company_type)}> Enter</button></td>
                    </tr>

                  </tbody>
                )) : <tbody><tr className={classes.popupRow}><td>No Data found</td></tr></tbody>}
            </table>
          </div>
        </DialogContent>

      </Dialog>
    </div>
  );
}
export default Login;
