import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import service from "../../../api/service";
import { trackPromise } from "react-promise-tracker";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function UserDetails() {
  const [edit, setEdit] = useState(false);
  const [passwordedit, setPasswordEdit] = useState(false);
  const [userData, setUserData] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [data, setData] = useState({
    username_edit: "",
  });
  const [PasswordData, setPasswordData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  
  const editDetails = () => {
    setEdit(true);
  };
  const cancelEdit = () => {
    setEdit(false);
    setNameError({
      username_edit:false
    })
    setPasswordEdit(false);
    setPasswordError({
      old_password:false,
      new_password:false,
      confirm_password:false,
     
    })
  
  };
  // const cancelPasswrdChange = () => {
  //   setPasswordEdit(false);
  // };
  const PasswrdChange = () => {
    setPasswordEdit(true);
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setEdit(false);
    setPasswordEdit(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  
  const UserData = () => {
    try {
      trackPromise(
        service.GetUserData().then((res) => {
       
          setUserData(res.data.userDetails);
          setData({
            username_edit: res.data?.userDetails?.name
          })
          return res.data.userDetails;
        })
      );
    } catch (err) {
    
    }
  };
  const[nameEror,setNameError] = useState({
    username_edit:false,
    NameMsg:""
  })
  function handle(e) {
    e.preventDefault();
    const newdata = { ...data,[e.target.id]:e.target.value};
    // newdata[e.target.id] = e.target.value;
    if(e.target.id === "username_edit"){
      if(e.target.value){
        setNameError({
          username_edit:false
        })
      }else{
        setNameError({
          username_edit:true
        })
      }
      
    }
    
    setData(newdata);
  }
  const updateUserData = () => {
  if(data.username_edit.length === 0){
    setNameError({
      username_edit:true
    })
  }else{
    service.UpdateUser(data).then((res) => {
      if (res.status === 200) {
        setsuccessOpen(true);
        window.location.reload();
      } else {
        seterrorOpen(true);
      }
      UserData();
    });
  }
      
   
  };

  //const passwrdchalength = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/;
  //const passwrdchalength = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
  const passwrdchalength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{8,}/;
  
  const[newpasswd,setNewpsswrd] = useState(null);
  function passwordhandle(e) {
    e.preventDefault();
    const newpdata = { ...PasswordData };
    newpdata[e.target.id] = e.target.value;
    if(e.target.id === "old_password"){
      if(e.target.value){
        setPasswordError({
          ...passwordEror,
          old_password:false,
      
        })
      }
       else{
        setPasswordError({
          ...passwordEror,
          old_password:true,
      
        })
      }
     
    }
    if(e.target.id === "new_password"){
      if(e.target.value){
        // setPasswordError({
        //   ...passwordEror,
        //   new_password:false,
      
        // })
        setNewpsswrd(e.target.value);
       if(e.target.value.length < 8){
        setPasswordError({
          ...passwordEror,
          new_password:true,
          new_passwordMSG:"Please enter at least 8 characters."
        })
       }
        else if(passwrdchalength.test(e.target.value) === false){
          setPasswordError({
            ...passwordEror,
            new_password:true,
            new_passwordMSG:"Please enter at​ least​​ 8​ characters​ with​ at least 1​ uppercase,1 lowercase,1 digit"
          })
        }else{
          setPasswordError({
            ...passwordEror,
            new_password:false,
            new_passwordMSG:""
          })
        }
      }else{
        setPasswordError({
          ...passwordEror,
          new_password:true,
          new_passwordMSG:"This field is required"
        })
      }
      
    }
    if(e.target.id === "confirm_password"){
      if(e.target.value){
        // setPasswordError({
        //   ...passwordEror,
        //   confirm_password:true,
      
        // })
        if(newpasswd !== e.target.value){
         
          setPasswordError({
            ...passwordEror,
            confirm_password:true,
            confirm_passworMSG:"Please enter the same password as above."
          })
        }else{
          setPasswordError({
            ...passwordEror,
            confirm_password:false,
            confirm_passworMSG:""
          })
        }
      }else{
        setPasswordError({
          ...passwordEror,
          confirm_password:true,
          confirm_passworMSG:"This field is required"
        })
      }
      
    }
    setPasswordData(newpdata);
  }
  const[passwordEror,setPasswordError] = useState({
    old_password:false,
    new_password:false,
    confirm_password:false,
    old_passwordMSG:"",
    new_passwordMSG:"",
    confirm_passworMSG:""
  })

  const updatePassword = () => {
    if(PasswordData.old_password.length === 0 || PasswordData.new_password.length === 0
      || PasswordData.confirm_password.length === 0 ){
      setPasswordError({
        ...passwordEror,
        old_password:PasswordData.old_password.length === 0 ? true:false,
        new_password:PasswordData.new_password.length === 0 ? true:false,
        confirm_password:PasswordData.confirm_password.length === 0 ? true:false,
      })
    }
    else{
      service.updatePassword(PasswordData).then((res) => {
        if (res.status === 200) {
          setsuccessOpen(true);
        } else {
          seterrorOpen(true);
        }

        UserData();
      });
    }
      
    
  };

  useEffect(() => {
    document.title = "Datafree Portal";
    UserData();
  }, []);

  return (
    <div className="userPage">
      <div className="userPageToptext">User Details</div>
      <div className="ivitediv">
        <button className="inviteButton" onClick={editDetails}>
          Edit Details
        </button>
      </div>
      <div className="dialoginputtext">Company:</div>

      <input
        type="text"
        id="name"
        disabled="disabled"
        label="name"
        value={userData ? userData?.name : ""}
        className="userinput"
        fullWidth
      />
  
      <div className="dialoginputtext">User's Name:</div>
      {edit ? (
        <input
          type="text"
          id="username_edit"
          label="email"
          name="username_edit"
          className={nameEror?.username_edit?"erruserinput":"edituserinput"}
          onBlur={(e) => handle(e)}
          onChange={(e) => handle(e)}
          fullWidth
        />
      ) : (
        <input
          type="text"
          id="email"
          label="email"
          disabled="disabled"
          value={userData ? userData.name : ""}
          className="userinput"
          name="email"
          fullWidth
        />
      )}
       {nameEror?.username_edit ? <span className="errorClass">This field is required</span> : null}
      <div className="dialoginputtext">Email Address (username):</div>
      <input
        type="text"
        id="email"
        value={userData ? userData.email : ""}
        disabled="disabled"
        label="email"
        className="userinput"
        fullWidth
      />
      {edit ?(
        <div className="ivitediv">
          <button className="inviteButton" color="primary" onClick={cancelEdit}>
            Cancel
          </button>
          <button
            className="inviteButton"
            color="primary"
            onClick={updateUserData}
          >
            Update User Details
          </button>
        </div>
      ) : (
        <div></div>
      )}
      <div className="passwodinputtext">Change Password</div>
      <div className="ivitediv">
        <button className="inviteButton" onClick={PasswrdChange}>
          Change Password
        </button>
      </div>
      <div className="dialoginputtext">Old Password:</div>
      {passwordedit ? (
        <input
          type="password"
          id="old_password"
          name="old_password"
          label="name"
          className={passwordEror?.old_password?"erruserinput":"edituserinput"}
          onBlur={(e) => passwordhandle(e)}
          onChange={(e) => passwordhandle(e)}
          fullWidth
        />
      ) : (
        <input
          type="text"
          id="name"
          label="name"
          disabled="disabled"
          value=""
          className="userinput"
          fullWidth
        />
      )}
       {passwordEror?.old_password ? <span className="errorClass">{passwordEror?.old_passwordMSG?passwordEror?.old_passwordMSG:"This field is required"}</span> : null}
      <div className="dialoginputtext">New Password:</div>
      {passwordedit ? (
        <input
          type="password"
          id="new_password"
          name="new_password"
          className={passwordEror.new_password ? "erruserinput" : "edituserinput"}
          onBlur={(e) => passwordhandle(e)}
          onChange={(e) => passwordhandle(e)}
          label="email" 
          fullWidth
        />
      ) : (
        <input
          type="text"
          id="email"
          label="email"
          disabled="disabled"
          value=""
          className="userinput"
          fullWidth
        />
      )}
        {passwordEror.new_password ? <span className="errorClass">{passwordEror.new_passwordMSG ? passwordEror?.new_passwordMSG : "This field is required"}</span> : null}
        <div className="dialoginputtext" style={{marginTop:'0px', marginBottom:'25px'}}>(Must be at least 8 characters long and contain at least 1 uppercase, 1 lowercase and 1 number)</div> 
      <div className="dialoginputtext">Confirm New Password:</div>
      {passwordedit ? (
        <input
          type="password"
          id="confirm_password"
          name="confirm_password"
          className={passwordEror?.confirm_password?"erruserinput":"edituserinput"}
          onBlur={(e) => passwordhandle(e)}
          onChange={(e) => passwordhandle(e)}
          label="email"
          fullWidth
        />
      ) : (
        <input
          type="text"
          id="email"
          value=""
          label="email"
          disabled="disabled"
          className="userinput"
          fullWidth
        />
      )}
        {passwordEror?.confirm_password ? <span className="errorClass">{passwordEror?.confirm_passworMSG ? passwordEror?.confirm_passworMSG : "This field is required"}</span> : null}
      {passwordedit ? (
        <div className="ivitediv">
        <button className="inviteButton" color="primary" onClick={cancelEdit}>
          Cancel
        </button>
        <button
          className="inviteButton"
          color="primary"
          onClick={updatePassword}
        >
          Update Password
        </button>
      </div>
      ) : (
        <div></div>
      )}
      <Snackbar
        open={successopen}
       autoHideDuration={2500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          Updated Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={erroropen}
       autoHideDuration={2500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          An Error Occured!
        </Alert>
      </Snackbar>
    </div>
  );
}
export default UserDetails;
