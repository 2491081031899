import React, { useEffect, useState } from "react";
import "./datafreecard.css";
import applicationIcon from "../../../../assets/img/application-icon.png";
import defaultApp from "../../../../assets/img/datafree_defaulticon.png";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import service from "../../../../api/service";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Card(props) {
  const [userData, setUserData] = useState();
  let history = useHistory();
  let API_URL = process.env.REACT_APP_API_URL;
  const [erroropen, seterrorOpen] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [data, setData] = useState({
    deployment: "",
    productType: "",
    subDomain: "",
  });

  const getData = () => {
    setLoading(true)
    try {
      trackPromise(
        service.IndexData().then((res) => {
          setUserData(res.data);

          //  userData?.deploymentDetails.map((item, index) => {
          //   item?.deploymentDetails?.appIds?.map((subitem, sindex) => {

          //      console?.log(subitem,"subitem")

          //   });
          // })
          setLoading(false)
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  };

  const [loading, setLoading] = useState(false);
  const lifecycleChange = (e) => {
    const newdata = { ...data };
    // alert(e.target.id)
    // setting the search key deployment value
    newdata[e.target.id] = e.target.value;
    setData(newdata);

    fetchdata(newdata);
  };

  const fetchdata = (newdata) => {
    setLoading(true)
    try {
      trackPromise(
        service.Search(newdata).then((res) => {
          setUserData(res.data);

          setLoading(false)
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  };

  const routePage = (id, productType, deploymentType) => {
    props.history.push(`/user/application/${id}/details`, {
      prop1: deploymentType,
      applctnid: id,
      productType: productType
    });
  };

  const createApp = () => {
    history.push("/user/gettingStarted");
  }
  useEffect(() => {
    document.title = "Datafree Portal";
    getData();
  }, []);

  return (
    <div className="datafree">
      <div className="pageView">
        <div className="TopNavCollapased">
          <div className="headdtextTop">
            <div>
              <img src={applicationIcon} alt="" className="headIcon"></img>
            </div>
            <div className="headToptext">#datafree Publication List</div>
          </div>

          <div className="filtertextTop">
            <div className="labelhead">Filters:</div>
            <div className="textTop">
              <div className="datalabel">Lifecycle</div>
              <select
                className="lifecyclefield"
                id="deployment"
                name="deployment"
                onChange={(e) => lifecycleChange(e)}
              >
                <option value=""></option>
                <option value="PROD">PROD</option>
                <option value="DEV">DEV</option>
              </select>
            </div>

            <div className="textTop">
              <div className="datalabel">Product Type</div>
              <select
                className="lifecyclefield"
                id="productType"
                name="productType"
                onChange={(e) => lifecycleChange(e)}
              >
                <option value=""></option>
                <option value="1">#datafree WRAP</option>
                <option value="2">#datafree MAX</option>
                <option value="3">#datafree SWITCH</option>
                <option value="4">#datafree REACH</option>
                <option value="5">#datafree DIRECT</option>
                <option value="6">#datafree CONNECT</option>
              </select>
            </div>

            <div className="textTop">
              <div className="datalabel">Free Domain</div>
              <input
                type="text"
                id="subDomain"
                name="subDomain"
                onKeyDown={(e) =>
                  e.key === "Enter" ? lifecycleChange(e) : null
                }
                className="lifecyclefield"
              ></input>
            </div>

            <div className="textTop"></div>
            <div className="textTop"></div>
          </div>
        </div>
        {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}

        {/* iconImage  iconUrl */}

        {userData && userData?.applicationsListResult.length > 0 ? (
          <div className="cardRow">
            {userData.applicationsListResult.map((item, index) => {
              return (
                <div className="cardView" key={index}>
                  <div className="cardtitle">
                    <div className="cards-img">
                      <img
                        src={item.iconUrl ? API_URL + userData.iconImage + item.iconUrl : defaultApp}
                        alt=""
                        className="cards-img"
                      ></img>
                    </div>
                    <div className="cardcontent">
                      <span className="edit-applicationlink">{item.name}</span>

                      {userData?.deploymentDetails?.deploymentTypes?.[index]?.[0] ? (
                        <span
                          className={
                            userData?.deploymentDetails?.deploymentTypes?.[index]?.[0] === "PROD"
                              ? "productionstatus"
                              : "deploymentstatus"
                          }
                        >
                          {userData?.deploymentDetails?.deploymentTypes?.[index]?.[0] === "PROD" ? "Production" : "Development"}
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="lastseen">
                        {"Last Updated : " + new Date(item.created).getFullYear() + '-' + new Date(item.created).getMonth() + '-' + new Date(item.created).getDate()}
                        {item.status ? (
                          <span className="livestatus">
                            {"Status :" + item.status}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                      <span className="poducttype">
                        {item && item.productType
                          ? "Product Type :" + item.productType
                          : "Product Type :" + item.productId.productType}
                      </span>


                      <span className="binuappid" key={index}>
                        bINu Id : {userData?.deploymentDetails?.appIds?.[index]?.[0]}
                      </span>



                      <span className="application-dummydiv"></span>
                    </div>
                  </div>
                  <div className="card-middle">
                    <div className="cd1">
                      <label className="rate">#datafree Data Usage MB</label>
                      <span className="green-values">
                        {userData?.datausage[index] ?
                          userData?.datausage[index]?.datausage !== null
                            ? userData?.datausage[index]?.datausage : "-"
                          : "-"}
                      </span>
                    </div>
                    <div className="cd1">
                      <label className="rate">App Usage</label>
                      <span className="green-values">
                        {userData?.pagenavs[index] ?
                          userData?.pagenavs[index]?.pagenavs !== null ?
                            userData?.pagenavs[index]?.pagenavs : "-"
                          : "-"}
                      </span>
                    </div>
                    <div className="cd1">
                      <label className="rate">Unique Users</label>
                      <span className="green-values">
                        {userData?.uniqueusers[index] ?
                          userData?.uniqueusers[index]?.uniqueusers !== null
                            ? userData?.uniqueusers[index]?.uniqueusers : "-"
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="cardfooter">
                    <button
                      className="btndefault"
                      title="Click here to view application details"
                      onClick={(id) =>
                        routePage(
                          item.applicationId,
                          item.productType,
                          item.deploymentType
                        )
                      }
                    >
                      Publisher Designer
                    </button>
                  </div>
                </div>)
            })}
          </div>
        ) : (
          null
        )}

        {!loading ?
          <div className="cardRow">
            <button className="addCards">
              <span onClick={(e) => createApp(e)}>+ Register and Create New Application</span>
            </button>
          </div>
          : null}
      </div>
      <Snackbar
        open={erroropen}
        autoHideDuration={1500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default Card;
