import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  withStyles,
  Grid,
} from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory, useParams } from "react-router-dom";
import accountIcon from "../../../assets/img/account-icon-black.png";
import Service from "../../../api/adminService";
import ChannelService from "../../../api/channelService";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import jwt from "jwt-decode";
import Loader from "react-loading";
import {
  userDispatchContext,roleDispatchContext
 } from "../../../api/context"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",
  },
}));


function ChannelDetailView(props) {
  let history = useHistory();
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const { channelId } = useParams();
  const [lodervisible, setlodervisible] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [userOpen, setUserOpen] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const [invitedSuccess, setInvitedSuccess] = useState(false);
  const userDispatchContextVal = useContext(userDispatchContext)
  const [userid, setUserId] = useState({
    userId: "",
    channelId: ""
  });
  const [inviteUser, setinviteUser] = useState(false);
  const [channelData, setChannelData] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [user_id, setUser_id] = useState();
  const [channelCompanyId, setChannelCompanyId] = useState();
  const roleDispatchContextVal = useContext(roleDispatchContext)
  const [editdata, seteditdata] = useState({
    companyname: "",
    channel_cust_id: "",
    monthly_data: "",
    companyId: "",
    primary_cust_email: "",
    account_manager_email: "",
    pricing_discount: "",
    data_credit_limit: "",
    status: "",
    contract_term: "",
    company_id: ""

  });

  const [error, setError] = useState({
    companyname: false,
    primary_cust_email: false,
    account_manager_email: false,
    pricing_discount: false,
    monthly_data: false,
    nameMsg: "",
    emailMsg: "",
    mailMsg: "",
    discountMsg: "",
    monthMsg: ""
  });
  const [invite, setInvite] = useState({
    name: "",
    email_address: "",
    companyId: ""
  });

  const [inviteError, setInviteError] = useState({
    email_address: false,
    name: false,
    emailMsg: ""
  })

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setEditInfo(false);
    setUserOpen(false);
    setinviteUser(false);
    setInviteError({
      email_address: false,
      name: false,
      emailMsg: ""
    });
    setInvite({
      ...invite,
      name: "",
      email_address: "",
    })
    setEditError({
      username: false,
    })
    setError({
      companyname: false,
      primary_cust_email: false,
      account_manager_email: false,
      pricing_discount: false,
      monthly_data: false,
      nameMsg: "",
      emailMsg: "",
      mailMsg: "",
      discountMsg: "",
      monthMsg: ""
    })
  };

  const handleAlertCloses = () => {
    setsuccessRequest(false);
    setUserOpen(false);
  }
  const edit = () => {
    setEditInfo(true);
    localStorage.setItem("EditOpen", true)
  };
  const inviteNew = () => {
    setinviteUser(true);
    setScroll('body');
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  const [successRequest, setsuccessRequest] = useState(false);
  const [message, setMessage] = useState();
  //passwordReset
  async function resetPassword(EMAIL) {

    try {
      trackPromise(
        ChannelService.emailRequest({ email: EMAIL, admin: "admin" }).then((res) => {
          if (res.status === 200) {
            setsuccessRequest(true);
            setMessage(res.data.message);
            handleClose();
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [creditLimit, setCreditLimit] = useState();
  const [ProdHealth, setProdHealth] = useState();
  const ChannelDetail = () => {
    setlodervisible(true);

    trackPromise(
      ChannelService.channelDetailView(channelId).then((res) => {
        setlodervisible(false);

        setChannelData(res.data);
        setProdHealth(res.data?.prodHealthCheck)
        setChannelCompanyId(res.data.publisherDetail.parentCompanyId.companyId);
        setCreditLimit(res.data.MonthlyDatafreeDataCreditLimit)
        seteditdata({
          companyname: res.data?.publisherDetail?.name ? res.data?.publisherDetail?.name : "",
          channel_cust_id: res.data?.publisherDetail?.channelCustId ? res.data?.publisherDetail?.channelCustId : "",
          monthly_data: res.data?.dataUsageAlert ? res.data?.dataUsageAlert : "",
          companyId: res.data?.publisherDetail?.companyId ? res.data?.publisherDetail?.companyId : "",
          primary_cust_email: res.data?.publisherDetail?.primaryCustEmail ? res.data?.publisherDetail?.primaryCustEmail : "",
          account_manager_email: res.data?.publisherDetail?.accMngrEmail ? res.data?.publisherDetail?.accMngrEmail : "",
          pricing_discount: res.data?.publisherDetail?.channelDiscountPrice ? res.data?.publisherDetail?.channelDiscountPrice : "",
          data_credit_limit: res.data?.publisherDetail?.accDataCreditLimitId?.creditLimitId ? res.data?.publisherDetail?.accDataCreditLimitId?.creditLimitId : "",
          status: res.data?.publisherDetail?.status ? res.data?.publisherDetail?.status : "",
          contract_term: res.data?.publisherDetail?.contractTerm ? res.data?.publisherDetail?.contractTerm : "",
          company_id: res.data?.publisherDetail?.companyId ? res.data?.publisherDetail?.companyId : ""
        })
      })
    );

  }
  //edit Channel
  async function EditChannelData() {
    const editCount = ValidateEdit(editdata);
    if (editCount === 0) {
      try {
        ChannelService.channelPublisherEdit(editdata).then((res) => {

          if (res.status === 200) {
            setToastMsg("Channel Details Edited Successfully!")
            setEditInfo(false);
            setsuccessOpen(true);
          } else {
            seterrorOpen(true);
            setEditInfo(true);
          }

          ChannelDetail();
        })

      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }

  }
  const [emailId] = useState({
    email: "",
    role: "",
    companyId: ""
  });
  const impersonate = (emailid, role, Id, type, name) => {
    emailId["email"] = emailid;
    emailId["role"] = role;
    emailId["companyId"] = Id;
    try {
      trackPromise(
        Service.impersonateUser(emailId).then((res) => {

          if (res.status === 200) {
            localStorage.setItem("token", res.data);
            userDispatchContextVal({ token: res.data });
            localStorage.setItem("Impersonation", true);
            localStorage.setItem("companyname", name)
            localStorage.setItem("companytype", type)
            localStorage.setItem("companyrole", role)
            roleDispatchContextVal({crole:role})
            var decode1 = jwt(res.data);

            if (decode1.roles[0] === "ROLE_USER")
              props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_RESELLER")
              props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_CUSTOMER")
              props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_ADMIN")
              props.history.push("/admin/company/index");
            else if (decode1.roles[0] === "ROLE_LICENSEE")
              props.history.push("/licensee/index/action");

          } else {
            alert("!please try again");
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  };

  const ValidateInvite = (invite) => {

    let count = 0;
    const nwerror = { ...inviteError }
    if (invite.email_address === "") {
      nwerror.email_address = true;
      nwerror.mailMsg = "This field is required."
      count++;
    }
    else {
      if (resValue === false) {
        nwerror.email_address = true;
        nwerror.mailMsg = "A user exists with this email. Contact Datafree Support if you wish this user to  be linked to this account"
        count++;
      }
      else if (emailReg.test(invite.email_address) === false) {
        nwerror.email_address = true;
        nwerror.mailMsg = "Please enter a valid email address."
        count++;
      }
      else {
        nwerror.email_address = false;
        nwerror.mailMsg = ""
      }
    }


    if (invite.name === "") {
      nwerror.name = true;
      count++;
    }
    else {
      nwerror.name = false;
    }
    setInviteError(nwerror);
    return count;
  }
  const [loading, setLoading] = useState(false);
  const [clickInvite, setClickInvite] = useState(false);
  //invite New
  async function InviteNew() {

    const inviteCount = ValidateInvite(invite);
    if (inviteCount === 0) {
      setLoading(true);
      setClickInvite(true);
      try {
        trackPromise(
          ChannelService.IniteNewChannelUser(invite).then((res) => {
            if (res.status === 200) {
              handleClose();
              setLoading(false);
              setInvitedSuccess(true);
              setinviteUser(false);
              setInvite({
                ...invite,
                name: "",
                email_address: "",
              })
            } else {
              seterrorOpen(true);
            }

            setClickInvite(false);
            ChannelDetail();
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }

  }

  const dataChange = (e) => {
    e.preventDefault();
    const newdata = { ...editdata };
    newdata[e.target.id] = e.target.value;

    newdata["company_id"] = channelId;
    newdata['companyId'] = channelCompanyId;

    if (e.target.id === "companyname") {
      CheckCompany(e.target.value, channelCompanyId)
      if (e.target.value) {
        if ((e.target.value).includes(",")) {
          setError({
            ...error,
            companyname: true,
            nameMsg: "Please enter a name without comma",
          });
        }
        else if (nameReg.test(e.target.value) === false) {
          setError({
            ...error,
            companyname: true,
            nameMsg: "Field must have at least 1 character a-z or A-Z",
          });
        }
        else {
          setError({
            ...error,
            companyname: false,
            nameMsg: ""
          })
        }

      }

      else {
        setError({
          ...error,
          companyname: true,
          nameMsg: "This field is required."
        })
      }

    }


    if (e.target.id === "primary_cust_email") {
      if (e.target.value) {
        // CheckEmail(e.target.value)
        if (emailReg.test(e.target.value) === false) {
          setError({
            ...error,
            primary_cust_email: true,
            emailMsg: "Please enter a valid email address."
          })
        }
        else {
          setError({
            ...error,
            primary_cust_email: false,
            emailMsg: ""
          })
        }

      }
      else {
        setError({
          ...error,
          primary_cust_email: false,
          emailMsg: ""
        })
      }

    }

    if (e.target.id === "channel_cust_id") {
      if (e.target.value) {
        // CheckEmail(e.target.value)
        if (CusIdReg.test(e.target.value) === false) {
          setError({
            ...error,
            channel_cust_id: true,
          })
        }
        else {
          setError({
            ...error,
            channel_cust_id: false,
          })
        }

      }
      else {
        setError({
          ...error,
          channel_cust_id: false,
        })
      }

    }

    if (e.target.id === "monthly_data") {
      if (e.target.value) {
        // CheckEmail(e.target.value)
        if (monthReg.test(e.target.value) === false) {
          setError({
            ...error,
            monthly_data: true,
            monthMsg: "An Alert Email will be sent to your company contacts if the Monthly Forecast Data Usage exceeds this"
          })
        }
        else {
          setError({
            ...error,
            monthly_data: false,
            monthMsg: ""
          })
        }

      }
      else {
        setError({
          ...error,
          monthly_data: false,
          monthMsg: ""
        })
      }

    }

    if (e.target.id === "pricing_discount") {
      if (e.target.value) {
        // CheckEmail(e.target.value)
        if (discountReg.test(e.target.value) === false) {
          setError({
            ...error,
            pricing_discount: true,
            discountMsg: "Please enter a value between 0 and 99.9."
          })
        }
        else {
          setError({
            ...error,
            pricing_discount: false,
            discountMsg: ""
          })
        }

      }
      else {
        setError({
          ...error,
          pricing_discount: false,
          discountMsg: ""
        })
      }

    }
    //

    if (e.target.id === "account_manager_email") {
      if (e.target.value) {
        // CheckEmail(e.target.value)
        if (emailReg.test(e.target.value) === false) {
          setError({
            ...error,
            account_manager_email: true,
            mailMsg: "Please enter a valid email address."
          })
        }
        else {
          setError({
            ...error,
            account_manager_email: false,
            mailMsg: ""
          })
        }

      }
      else {
        setError({
          ...error,
          account_manager_email: false,
          mailMsg: ""
        })
      }

    }
    seteditdata(newdata);
  };


  const ValidateEdit = (editdata) => {

    let count = 0;
    const nwerror = { ...error };
    if (editdata.companyname === "") {
      nwerror.companyname = true;
      nwerror.nameMsg = "This field is required."
      count++;
    }
    else {
      if (resVal === false) {
        nwerror.companyname = true;
        nwerror.nameMsg = "This​ Company ​name ​already​ exist"
        count++;
      }
      else if ((editdata.companyname).includes(",")) {
        nwerror.companyname = true;
        nwerror.nameMsg = "Please enter a name without comma"
        count++;
      }
      else if (nameReg.test(editdata.companyname) === false) {
        nwerror.companyname = true;
        nwerror.nameMsg = "Field must have at least 1 character a-z or A-Z"
        count++;
      }
      else {
        nwerror.companyname = false;
        nwerror.nameMsg = ""
      }
    }

    if (editdata.primary_cust_email === "") {
      nwerror.primary_cust_email = false;
      nwerror.emailMsg = "";
    }
    else {
      if (emailReg.test(editdata.primary_cust_email) === false) {
        nwerror.primary_cust_email = true;
        nwerror.emailMsg = "Please enter a valid email address."
        count++;
      }
      else {
        nwerror.primary_cust_email = false;
        nwerror.emailMsg = ""
      }
    }

    if (editdata.channel_cust_id === "") {
      nwerror.channel_cust_id = false;
    }
    else {
      if (CusIdReg.test(editdata.channel_cust_id) === false) {
        nwerror.channel_cust_id = true;
        count++;
      }
      else {
        nwerror.channel_cust_id = false;
      }
    }

    if (editdata.account_manager_email === "") {
      nwerror.account_manager_email = false;
      nwerror.mailMsg = "";
    }
    else {
      if (emailReg.test(editdata.account_manager_email) === false) {
        nwerror.account_manager_email = true;
        nwerror.mailMsg = "Please enter a valid email address."
        count++;
      }
      else {
        nwerror.account_manager_email = false;
        nwerror.mailMsg = ""
      }
    }


    if (editdata.pricing_discount === "") {
      nwerror.pricing_discount = false;
      nwerror.discountMsg = "";
    }
    else {
      if (discountReg.test(editdata.pricing_discount) === false) {
        nwerror.pricing_discount = true;
        nwerror.discountMsg = "Please enter a value between 0 and 99.9."
        count++;
      }
      else {
        nwerror.pricing_discount = false;
        nwerror.discountMsg = ""
      }
    }

    if (editdata.monthly_data === "") {
      nwerror.monthly_data = false;
      nwerror.monthMsg = "";
    }
    else {
      if (monthReg.test(editdata.monthly_data) === false) {
        nwerror.monthly_data = true;
        nwerror.monthMsg = "An Alert Email will be sent to your company contacts if the Monthly Forecast Data Usage exceeds this"
        count++;
      }
      else {
        nwerror.monthly_data = false;
        nwerror.monthMsg = ""
      }
    }
    setError(nwerror);
    return count;
  }

  const [userdata, setUserdata] = useState({
    username: "",
    user_verified: "",
    user_status: "",
    user_id: "",
    company_id: ""
  });

  const [resVal, setResVal] = useState();
  async function CheckCompany(name, id) {

    try {
      if (name.includes(",")) {
        setError({
          ...error,
          companyname: true,
          nameMsg: "Please enter a name without comma",
        });
      }
      else if (nameReg.test(name) === false) {
        setError({
          ...error,
          companyname: true,
          nameMsg: "Field must have at least 1 character a-z or A-Z",
        });
      }
      else {
        trackPromise(
          Service.CheckCompany({ companyname: name, companyid: id }).then((res) => {

            setResVal(res.data);
            if (res.data === true) {
              setError({
                ...error,
                companyname: false,
                nameMsg: ""
              })
            }

            else {
              setError({
                ...error,
                companyname: true,
                nameMsg: "This​ Company ​name ​already​ exist"
              })
            }

          })
        );
      }

    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [resValue, setResValue] = useState();
  async function CheckEmail(email) {
    try {
      trackPromise(
        ChannelService.emailChannelCheck({ email_address: email }).then((res) => {

          setResValue(res.data);
          if (res.data === true) {
            setInviteError({
              ...inviteError,
              email_address: false,
              mailMsg: ""
            })
          }

          else {
            setInviteError({
              ...inviteError,
              email_address: true,
              mailMsg: "This​ email address already exists please use admin utilities to check current user access"
            })
          }

        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const emailReg = /^\w+([.+-]?\w+)*@\w+([.+-]?\w+)*(\.\w{2,3})+$/;
  const monthReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const discountReg = /^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$/;
  const nameReg = /^.*[A-Za-z].*/i;
  const CusIdReg = /^[a-zA-Z0-9-]*$/i;
  const handleUser = (e) => {
    e.preventDefault();
    const invitedata = { ...invite };
    invitedata[e.target.id] = e.target.value;
    invitedata["companyId"] = channelId;

    if (e.target.id === "name") {
      if (e.target.value) {
        setInviteError({
          ...inviteError,
          name: false
        })
      }
      else {
        setInviteError({
          ...inviteError,
          name: true
        })
      }
    }


    if (e.target.id === "email_address") {
      if (e.target.value) {
        CheckEmail(e.target.value);
        if (emailReg.test(e.target.value) === false) {
          setInviteError
            ({
              ...inviteError,
              email_address: true,
              mailMsg: "Please enter a valid email address."
            })
        }
        else {
          setInviteError({
            ...inviteError,
            email_address: false,
            mailMsg: ""
          })
        }
      }
      else {
        setInviteError({
          ...inviteError,
          email_address: true,
          mailMsg: "This field is required."
        })
      }
    }
    setInvite(invitedata);
  };

  const redirectRoute = () => {
    history.push("/channel/publisher/index");
  };

  //getUser
  async function getUserDetail(Ids, channelIds) {
    userid["channelId"] = localStorage.getItem("channelId");
    setuserDetails("");
    try {
      trackPromise(
        ChannelService.channelUserBYId({ 'userId': Ids, 'companyId': channelIds }).then((res) => {

          setuserDetails(res.data);

          setUser_id(res.data.userId);

          setUserdata({
            username: res.data.userName ? res.data.userName : "",
            user_verified: res.data.verified ? res.data.verified : "",
            user_status: res.data.status ? res.data.status : "",
            user_id: res.data.userId ? res.data.userId : "",
            company_id: res.data.companyId ? res.data.companyId : "",
          });
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  const [loadings, setLoadings] = useState(false);

  const [toastMsg, setToastMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  //editCompanyPublisher Detail
  async function EditUserData() {
    if (editError.username) {
      setEditError({
        ...editError,
        username: true,
      });
    } else {
      setLoadings(true);
      try {
        trackPromise(
          ChannelService.channelUserEdit(userdata).then((res) => {
            setLoadings(false);
            if (res.status === 200) {
              setToastMsg("User Details Updated Successfully!");
              setEditInfo(false);
              setUserOpen(false);
              setsuccessOpen(true);
              handleClose();
            } else {
              seterrorOpen(true);
              setUserOpen(true);
              setEditInfo(true);
            }
            ChannelDetail();
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }
  }

  const OpenUserTab = (scrollType, Id, channelId) => {

    userid["userId"] = Id;
    setUserId(userid);

    getUserDetail(Id, channelId);

    setUserOpen(true);
    setScroll(scrollType);
  };
  const [editError, setEditError] = useState({
    username: false,

  })
  const EditUser = (e) => {
    const newdata = { ...userdata };
    newdata[e.target.id] = e.target.value;
    newdata["user_id"] = user_id;

    if (e.target.id === "username") {
      if (e.target.value) {
        setEditError({
          ...editError,
          username: false
        })
      } else {
        setEditError({
          ...editError,
          username: true
        })
      }
    }
    setUserdata(newdata);
  };

  const [openPopup, setOpenPopup] = useState(false);

  document.addEventListener("click", e => {

    let editNot = localStorage.getItem("EditOpen");
    if (editNot) {
      if (e.target.id === "Edit" || e.target.id === "credit_control_email" || e.target.id === "Cancel" || e.target.id === "Save" ||
        e.target.type === "file" || e.target.name === "company_name" || e.target.name === "popup" || e.target.id === "status" || e.target.id === "Remove") {

        if (e.target.id === "Cancel" || e.target.id === "Save") {
          localStorage.removeItem("EditOpen")

        }
        setOpenPopup(false);
      }
      else {
        setOpenPopup(true);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

      }
    }
    else {
      setOpenPopup(false);

    }

  }, true);

  const StatusChange = (e, id) => {

    try {
      trackPromise(
        ChannelService.ChangeStatus({ applicationId: id, status: e.target.value }).then((res) => {

          ChannelDetail();
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  const RevenueChange = (e, ids) => {
    try {
      trackPromise(
        Service.revenueChange({ applicationId: ids, rtId: e.target.value }).then((res) => {

          ChannelDetail();
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const ChangeHealth = (e, healthon, id) => {
    let health;
    e.target.checked = !healthon
    if (e.target.checked === false) {
      health = "off";
    }
    else {
      health = "on";
    }
    try {
      trackPromise(
        ChannelService.ChangeHealth({ deploymentId: id, health: health }).then((res) => {

          ChannelDetail();
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  useEffect(() => {
    setlodervisible(true);
    ChannelDetail();

    localStorage.removeItem("EditOpen")
  }, []);

  return (
    <div className="accountContent">
      <div className="accountView">
        <div className="accounthead">
          <div>
            <img src={accountIcon} alt=""></img>
          </div>
          <div className="accountToptext">Publisher Details - Channel View</div>
        </div>
        <AntTabs
          value={value}
          className="tabViewMenu"
          onChange={handleChange}
          aria-label="ant example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <AntTab
            label="Company"
            title="Click here to view Company Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            label="Users"
            title="Click here to view Users Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            label="Applications"
            title="Click here to view Applications Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
        </AntTabs>
        <Typography className={classes.padding} />

        <div>
          <TabPanel value={value} index={0}>

            {
              lodervisible &&
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '0px',
                right: '0px',
                margin: 'auto',
                position: 'absolute',
                left: '0px',
                bottom: '0px',
                zIndex: '3000',
                backgroundColor: "#ffffff",
                //boxShadow: '0px 0px 5px -1px rgba(0,0,0,0.75)',
                // paddingLeft: '2px',
                // paddingRight: '2px',
                paddingTop: '8px',
                paddingBottom: '8px',
                borderRadius: '5px',
                flexDirection: 'column',
                width: '70px',
                height: '60px'
              }} >
                <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />

              </div>
            }

            {channelData?.publisherDetail ?
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">Publisher Name</div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="form-control"
                        id="companyname"
                        name="companyname"
                        style={{ cursor: 'not-allowed' }}
                        disabled="disabled"
                        value={channelData.publisherDetail.name}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.companyname ? "form-control" : "errorField form-control"}
                        name="popup"
                        id="companyname"
                        defaultValue={channelData.publisherDetail.name}
                        onChange={(e) => dataChange(e)}
                        onBlur={(e) => dataChange(e)}
                      />
                    )}
                    {error.companyname ? <span className="errormsg">{error.nameMsg}</span> : null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">Your Customer Identifier</div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="form-control"
                        name="channel_cust_id"
                        id="channel_cust_id"
                        style={{ cursor: 'not-allowed' }}
                        disabled="disabled"
                        value={channelData.publisherDetail.channelCustId}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.channel_cust_id ? "form-control" : "form-control errorField"}
                        name="popup"
                        id="channel_cust_id"
                        defaultValue={channelData.publisherDetail.channelCustId}
                        onChange={(e) => dataChange(e)}
                        onBlur={(e) => dataChange(e)}
                      />
                    )}
                    {error.channel_cust_id ? <span className="errormsg">MTN ID format is 1-2A34BC</span> : null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="ivitediv">
                    {!editInfo ? (
                      <button className="inviteButton" onClick={edit} id="Edit">
                        Edit Details
                      </button>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">
                    Monthly Account #datafree Usage Alert (bytes)
                  </div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="form-control"
                        name="monthly_data"
                        id="monthly_data"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={channelData.dataUsageAlert}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.monthly_data ? "form-control" : "form-control errorField"}
                        name="popup"
                        id="monthly_data"
                        onChange={(e) => dataChange(e)}
                        onBlur={(e) => dataChange(e)}
                        defaultValue={channelData.dataUsageAlert}
                      ></input>
                    )}
                    {error.monthly_data ? <span className="errormsg">{error.monthMsg}</span> : null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">Usage Alert Email</div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="form-control"
                        name="primary_cust_email"
                        id="primary_cust_email"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={channelData.publisherDetail.primaryCustEmail}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.primary_cust_email ? "form-control" : "form-control errorField"}
                        name="popup"
                        id="primary_cust_email"
                        onChange={(e) => dataChange(e)}
                        onBlur={(e) => dataChange(e)}
                        defaultValue={channelData.publisherDetail.primaryCustEmail}
                      />
                    )}
                    {error.primary_cust_email ? <span className="errormsg">{error.emailMsg}</span> : null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}></Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">Account Manager Email</div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="form-control"
                        name="account_manager_email"
                        id="account_manager_email"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={channelData.publisherDetail.accMngrEmail}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.account_manager_email ? "form-control" : "form-control errorField"}
                        name="popup"
                        id="account_manager_email"
                        onChange={(e) => dataChange(e)}
                        onBlur={(e) => dataChange(e)}
                        defaultValue={channelData.publisherDetail.accMngrEmail}
                      />
                    )}
                    {error.account_manager_email ? <span className="errormsg">{error.mailMsg}</span> : null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={3} md={3}> </Grid>
                <Grid item xs={12} sm={4} md={4}></Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">Pricing Discount %</div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="form-control"
                        name="pricing_discount"
                        id="pricing_discount"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={channelData.publisherDetail.channelDiscountPrice}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.pricing_discount ? "form-control" : "form-control errorField"}
                        name="popup"
                        id="pricing_discount"
                        onChange={(e) => dataChange(e)}
                        onBlur={(e) => dataChange(e)}
                        defaultValue={channelData.publisherDetail.channelDiscountPrice}
                      />
                    )}
                    {error.pricing_discount ? <span className="errormsg">{error.discountMsg}</span> : null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">
                    Monthly #datafree Data Credit Limit (bytes)
                  </div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="form-control"
                        name="data_credit_limit"
                        id="data_credit_limit"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={channelData.publisherDetail.accDataCreditLimitId?.creditLimit ? channelData.publisherDetail.accDataCreditLimitId?.creditLimit : ""}
                      />
                    ) : (
                      <select
                        type="text"
                        className="form-control"
                        id="data_credit_limit"
                        name="popup"

                        onChange={(e) => dataChange(e)}
                        onBlur={(e) => dataChange(e)}
                      > <option value="All Apps">All Apps</option>
                        {creditLimit && creditLimit.length > 0
                          ? creditLimit.map((post) => (
                            <option key={post.creditLimit} value={post.creditLimitId} selected={channelData.publisherDetail.accDataCreditLimitId?.creditLimitId === post.creditLimitId && true}>
                              {post.creditLimit}
                            </option>
                          ))
                          : null}</select>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}></Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">Status</div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"

                        className="form-control"
                        name="companyname"
                        id="companyname"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={channelData.publisherDetail.status}
                      />
                    ) : (
                      <select type="text" className="form-control form-select" name="popup"
                        id="status" onChange={(e) => dataChange(e)} onBlur={(e) => dataChange(e)}
                      >

                        {channelData?.publisherDetail?.status === "Approved" ? (<>
                          <option value="Approved" selected>Approved</option>
                          <option value="Suspended">Suspended</option>
                          <option value="Archived">Archived</option>

                        </>) :

                          channelData?.publisherDetail?.status === "New" ? (<>
                            <option value="New" selected>New</option>
                            <option value="Approved">Approved</option>
                            <option value="NoAgreement">NoAgreement</option>

                          </>) :
                            channelData?.publisherDetail?.status === "NoAgreement" ? (<>
                              <option value="NoAgreement" selected>NoAgreement</option>
                              <option value="Approved">Approved</option>
                              <option value="Archived">Archived</option>

                            </>) :
                              channelData?.publisherDetail?.status === "Suspended" ? (<>
                                <option value="Suspended" selected>Suspended</option>
                                <option value="Approved">Approved</option>
                                <option value="Archived">Archived</option>

                              </>) :
                                channelData?.publisherDetail?.status === "Archived" ? (<>
                                  <option value="Archived" selected>Archived</option>

                                </>) :
                                  null}
                      </select>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">Contract Term</div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="form-control"
                        name="contract_term"
                        id="contract_term"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={channelData.publisherDetail.contractTerm}
                      />
                    ) : (
                      <select
                        type="text"

                        className="form-control form-select"
                        name="popup"
                        id="contract_term"
                        onChange={(e) => dataChange(e)}
                        onBlur={(e) => dataChange(e)}
                      >
                        {" "}
                        <option value="Ad Hoc" selected={channelData.publisherDetail.contractTerm === "Ad Hoc" && true}>
                          Ad Hoc
                        </option>
                        <option value="12 Months" selected={channelData.publisherDetail.contractTerm === "12 Months" && true}>12 Months</option>
                        <option value="24 Months" selected={channelData.publisherDetail.contractTerm === "24 Months" && true}>24 Months</option>
                        <option value="36 Months" selected={channelData.publisherDetail.contractTerm === "36 Months" && true}>36 Months</option>
                        <option value="Special" selected={channelData.publisherDetail.contractTerm === "Special" && true}>Special</option>
                      </select>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}></Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">Company Type</div>

                  <div>
                    <input
                      type="text"
                      className="form-control"
                      style={{ cursor: 'not-allowed' }}
                      disabled="disabled"
                      name="popup"
                      value={channelData.publisherDetail.companyType}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div className="companyLabel">Created</div>

                  <div>
                    <input
                      type="text"
                      className="form-control"
                      style={{ cursor: 'not-allowed' }}
                      disabled="disabled"
                      value={moment(channelData?.publisherDetail?.created).format("YYYY-MM-DD ") + " GMT"}
                      name="popup"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}></Grid>
              </Grid> : null}

            <div className="ivitediv">
              {editInfo ? (
                <button
                  className="inviteButton"
                  onClick={handleClose}
                  id="Cancel"
                  color="primary"
                >
                  Cancel
                </button>
              ) : null}
              {editInfo ? (
                <button
                  className="inviteButton"
                  color="primary"
                  id="Save"
                  onClick={EditChannelData}
                >
                  Save
                </button>
              ) : null}
            </div>
            <div className="ivitediv">
              {" "}
              <button className="inviteButton" onClick={redirectRoute} id="Return">
                Return to Publisher List
              </button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!editInfo ? (
              <div className="ivitediv">
                <button className="inviteButton" onClick={inviteNew}>
                  + Invite New Users
                </button>
              </div>
            ) : (
              <div className="ivitediv">
                <button
                  className="inviteButton"
                  onClick={handleClose}
                  color="primary"
                >
                  Cancel
                </button>
                <button className="inviteButton" color="primary">
                  Save
                </button>
              </div>
            )}

            <TableContainer>
              <Table className="accouttablestyle" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHead">User’s Name</TableCell>
                    <TableCell className="tableHead">Email Address</TableCell>
                    <TableCell className="tableHead">Role</TableCell>
                    <TableCell className="tableHead">Type</TableCell>
                    <TableCell className="tableHead">Verified</TableCell>
                    <TableCell className="tableHead">Status</TableCell>
                    <TableCell className="tableHead">Created</TableCell>
                    <TableCell className="tableHead">Impersonate</TableCell>
                  </TableRow>
                </TableHead>
                {channelData && channelData.usersListDetail.length > 0 ? (
                  channelData.usersListDetail.map((row, index) => (
                    <TableBody>
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ color: "#337ab7", cursor: "pointer" }}
                          onClick={() => OpenUserTab("body", row.userId, row?.companyIdObj?.companyId)}
                        >
                          {row?.userIdObj?.name}
                        </TableCell>
                        <TableCell>{row?.userIdObj?.email}</TableCell>
                        <TableCell>{row?.role}</TableCell>
                        <TableCell>{row?.userIdObj?.userType}</TableCell>
                        <TableCell>{row?.userIdObj?.verified === 1 ? "Yes" : "No"}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{moment(row?.userIdObj?.created).format("Do MMM YYYY") + " GMT"}</TableCell>
                        <TableCell>
                          {row.status === "Active" &&
                            row.role !== "ROLE_CHANNEL" ?
                            <button className="impesonatebtn" onClick={() => impersonate(row.userIdObj?.email, row?.role, row?.companyId,
                              row?.companyIdObj?.companyType, row?.companyIdObj?.name)}>
                              Impersonate User
                            </button> : <div></div>}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableRow style={{ columnSpan: 10 }}>No Users Found</TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <div className="ivitediv">
              <button className="inviteButton" onClick={redirectRoute}>
                Return To Publisher List
              </button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TableContainer>
              <Table className="accouttablestyle" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHead">biNu Id</TableCell>
                    <TableCell className="tableHead">App Name </TableCell>
                    <TableCell className="tableHead">Product Type</TableCell>
                    <TableCell className="tableHead">Free Domain</TableCell>
                    <TableCell className="tableHead">Google App Id</TableCell>
                    <TableCell className="tableHead"> Type </TableCell>
                    <TableCell className="tableHead">Created</TableCell>
                    <TableCell className="tableHead">Max Bytes / Day(MB)</TableCell>
                    <TableCell className="tableHead">Production Health Check Required	</TableCell>
                    <TableCell className="tableHead">Status	</TableCell>
                    <TableCell className="tableHead">Revenue Type	</TableCell>
                  </TableRow>
                </TableHead>
                {channelData &&
                  channelData.applicationsListDetail.length > 0 ? (
                  <TableBody>
                    {channelData.applicationsListDetail.map((row, index) => (
                      <TableRow key={row.biNuId}>
                        <TableCell component="th" scope="row">
                          {row.applicationId}
                        </TableCell>
                        <TableCell
                        >
                          {row.name}
                        </TableCell>
                        <TableCell>{row.productId.productType}</TableCell>
                        <TableCell>{row.reachSubDomain}</TableCell>
                        <TableCell>
                          {row.googleAppId
                            ? row.googleAppId
                            : ""}
                        </TableCell>
                        <TableCell>
                          {row.applicationType
                            ? row.applicationType
                            : ""}
                        </TableCell>
                        <TableCell>{moment(row.created).format("Do MM YYYY") + " GMT"}</TableCell>
                        <TableCell>{row.maxBytesPerDay ? row.maxBytesPerDay : ""}</TableCell>
                        <TableCell>
                          {ProdHealth?.[index] ?

                            <label className="NewCheck">
                              <input type="checkbox"
                                value="on"
                                id={"check_" + ProdHealth?.[index].deploymentId}
                                checked={ProdHealth?.[index].prodHealthCheckOn ? ProdHealth?.[index].prodHealthCheckOn : ""}
                                onChange={(e) => ChangeHealth(e, ProdHealth?.[index].prodHealthCheckOn, ProdHealth?.[index].deploymentId)}
                              >

                              </input>
                            </label>
                            : " "}
                        </TableCell>
                        <TableCell>

                          <select className="pubSelect" onChange={(e) => StatusChange(e, row.applicationId)}>
                            <option value="Live" selected={row.status === "Live" && true}>Live</option>
                            <option value="ForwardTraffic" selected={row.status === "ForwardTraffic" && true}>ForwardTraffic</option>
                            <option value="Suspended" selected={row.status === "Suspended" && true}>Suspended</option>
                            <option value="Deleted" selected={row.status === "Deleted" && true}>Deleted</option>
                          </select>


                        </TableCell>
                        <TableCell>

                          <select className="pubSelect" onChange={(e) => RevenueChange(e, row.applicationId)}>
                            <option value=""></option>
                            {channelData?.revenueType.map((revs, indx) => (
                              <option value={revs.rtId} key={revs.indx} selected={row.rtId === revs.rtId && true}>{revs.revType}</option>
                            ))}
                          </select>


                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <tr>
                      <td colSpan={"20"} className="bottomRow"> No Applications Found</td>
                    </tr>

                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <div className="ivitediv">
              <button className="inviteButton" onClick={redirectRoute}>Return To Publisher List</button>
            </div>
          </TabPanel>
        </div>

      </div>
      {/* //UserEDit */}
      <Dialog
        open={userOpen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">User Details - Channel View
            <IoMdCloseCircleOutline style={{ cursor: "pointer", float: 'right' }}
              className="modalClose"
              name="popup" onClick={handleClose} />

          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">User’s Name</div>
              <input
                type="text"
                id="username"
                name="username"
                defaultValue={userDetails ? userDetails.userName : ""}
                onChange={(e) => EditUser(e)}
                onBlur={(e) => EditUser(e)}
                className={!editError.username ? "form-control" : "form-control errorField"}
                fullWidth
              />

              {editError.username ? <span className="errorClass">This field is required</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Email Address</div>
              <input
                type="text"
                style={{ cursor: 'not-allowed' }}
                disabled="disabled"
                label="email"
                value={userDetails ? userDetails.email : ""}
                className="form-control"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Role</div>
              <input
                type="text"
                style={{ cursor: 'not-allowed' }}
                disabled="disabled"
                value={userDetails ? userDetails.role : ""}
                label="email"
                className="form-control"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Type</div>
              <input
                type="text"
                style={{ cursor: 'not-allowed' }}
                label="email"
                value={userDetails ? userDetails.type : ""}
                disabled="disabled"
                className="form-control"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Verified</div>
              <select
                type="text"
                id="user_verified"
                name="user_verified"
                label="email"

                className="form-control form-select"
                fullWidth
                onChange={(e) => EditUser(e)}
              >
                <option value="1" selected={userDetails?.verified === 1 && true}>Yes</option>
                <option value="0" selected={userDetails?.verified === 0 && true}>No</option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Status</div>
              <select
                type="text"
                id="user_status"
                name="user_status"

                label="email"
                className="form-control form-select"
                fullWidth
                onChange={(e) => EditUser(e)}
              >
                <option value="Active" selected={userDetails?.status === "Active" && true}>Active</option>
                <option value="Deleted" selected={userDetails?.status === "Deleted" && true}>Deleted</option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Created</div>
              <input
                type="text"
                label="email"
                style={{ cursor: 'not-allowed' }}
                value={userDetails ? userDetails.created + " GMT" : ""}
                disabled="disabled"
                className="form-control"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loadings ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="buttonRow">
            <div>
              <button
                className="inviteButton"
                onClick={() => resetPassword(userDetails?.email)}
                color="primary"
              >
                Request Password Reset
              </button>
            </div>
            <div>
              <button
                className="inviteButton"
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </button>

              <button
                className="inviteButton"
                color="primary"
                onClick={EditUserData}
              >
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {/* //INVITE uSER */}
      <Dialog
        open={inviteUser}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle">  Invite Publisher User </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>

          <div className="dialoginputtext">Name:</div>
          <input
            type="text"
            id="name"
            label="name"
            className={!inviteError.name ? "form-control" : "form-control errorField"}
            onChange={(e) => handleUser(e)}
            fullWidth
          />
          {inviteError.name ? <span className="errorClass">This field is required</span> : null}
          <div className="dialoginputtext">Email:</div>
          <input
            type="text"
            id="email_address"
            name="email_address"
            label="email"
            className={!inviteError.email_address ? "form-control" : "form-control errorField"}
            onChange={(e) => handleUser(e)}
            fullWidth

          />
          {inviteError.email_address ? <span className="errorClass">{inviteError.emailMsg}</span> : null}
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <button
              className="inviteButton"
              disabled={clickInvite ? "disabled" : false}
              onClick={InviteNew}
              color="primary"
            >
              Invite User
            </button>
          </div>
        </DialogActions>
      </Dialog>


      {/*///////////////////////Popup//////////////////////////////*/}
      <Dialog open={openPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>



      {/*///////////////////////Invite successPopup//////////////////////////////*/}

      <Dialog open={invitedSuccess} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Success Message</div>

        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ fontSize: '17px', padding: '22px 71px !important' }}>Successfully added new user. Invitation link sent to user's E-mail id.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" style={{ width: '11%' }} name="popup" color="primary" onClick={() => setInvitedSuccess(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successopen}
        autoHideDuration={2500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>

      <Snackbar
        open={erroropen}
        autoHideDuration={2500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>

      <Snackbar open={successRequest} autoHideDuration={2500} onClose={handleAlertCloses}>
        <Alert onClose={handleAlertCloses} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default ChannelDetailView;
