import React, {useState} from 'react';
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./adminStyle.css";

function ConnectCreateThirdStep({NextFormStep, handlePreviousOpen, data, error, handleChange}) {
    let history = useHistory();
   const [inputShown,setInputShown] =useState(false);
   const [inputActionShown,setInputActionShown] =useState(false);
   const [inputAccentShown,setInputAccentShown] =useState(false);

    const handleToggle = id => {

     
      if(id === 1)
      {
        setInputShown(inputShown ? false : true);
      }
      
      if(id === 2)
      {
        setInputActionShown(inputActionShown ? false : true);
      }

      if(id === 3)
      {
        setInputAccentShown(inputAccentShown ? false : true);
        
      }
    }
    return (
        <div className="directapplication">
            <Grid container spacing={1}>
            <Grid item xs={12}>
                <div className="gridHead">Step 3 of 6 - APK Look and Feel Options</div>
            </Grid>
              <Grid item xs={12} >
                <div className="label_Direct">
                <label className="label_Direct">Page Title</label>
                  <input type="text"
                  defaultValue={data.page_title}
                  style={{width:'45%', marginLeft:'15px' }}
                  className={
                    !error.page_title ? "directFieldSelect" : "directFieldSelecterr"
                  }
                  id="page_title" 
                  name="page_title"
                  onChange={handleChange}></input>
                </div>
                {error.page_title ? (
                  <div>
                    <span style={{float:'left', marginLeft:'75px'}} className="errormsg topAlign">{error.pageMsg}</span></div>
                  ) : (
                    ""
                  )}
              </Grid>
              <Grid item xs={12} >
                <div className="gridHead" 
                style={{color:'#939598', fontSize:'13px', fontWeight:'bold',float:'left'}}>Status Bar Colour 

                <div style={{ display: "inline-block" }} className="colorPickerDiv">
                <input type={inputShown?'hidden':'color'} style={{marginLeft:'10px'}}
                name="statusColor"
                id="statusColor"  
                className="colorBox"
                onChange={handleChange}
                defaultValue={data.statusColor}/>
                <input
                  type={inputShown?'hidden':'text'}
                  className="colorInput"
                  disabled="disabled"
                  value={data.statusColor}
                />
                </div>

                <input type={inputShown?'text':'hidden'} 
                className={
                  !error.statusbar ? "directFieldSelect" : "directFieldSelecterr"
                }
                name="statusmanual"
                id="statusmanual" 
                defaultValue={data.statusmanual}
                onChange={handleChange}
                />

                  {error.statusbar ? (
                    <span style={{float:'left'}} className="errormsg">{error.colormsg}</span>
                  ) : (
                    ""
                  )}
                <span style={{padding:'5px', color:'#333333',fontWeight:'normal'}}>Click here to Enter Manually</span>
                <input type="checkbox" key ="1" 
                onClick={()=>handleToggle(1)}></input>
                </div>
                
              
              </Grid>
             
              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                <div className="gridHead" 
                style={{color:'#939598', fontSize:'13px', fontWeight:'bold',float:'left'}}>Action Bar Colour  

                <div style={{ display: "inline-block" }} className="colorPickerDiv">
                <input type={inputActionShown?'hidden':'color'}  style={{marginLeft:'10px'}}
                name="actionColor"
                className="colorBox"
                id="actionColor" 
                onChange={handleChange}
                defaultValue={data.actionColor}
                />

                          <input
                            type={inputActionShown?'hidden':'text'}
                            className="colorInput"
                            disabled="disabled"
                            value={data.actionColor}
                          />
                </div>

                <input type={inputActionShown?'text':'hidden'} 
                className={
                  !error.actionColor ? "directFieldSelect" : "directFieldSelecterr"
                }
                name="actionmanual" 
                id="actionmanual" 
                onChange={handleChange}
                defaultValue={data.actionmanual}
                />

                  {error.actionColor ? (
                    <span style={{float:'left'}} className="errormsg">{error.actionmsg}</span>
                  ) : (
                    ""
                  )}
                <span style={{padding:'5px', color:'#333333',fontWeight:'normal'}}>Click here to Enter Manually</span>
                <input type="checkbox" key="2"  onClick={()=>handleToggle(2)}></input>
                </div>
                
              
              </Grid>

              <Grid item xs={6}></Grid>
              <Grid item xs={12}></Grid>

              <Grid item xs={6}>
                <div className="gridHead" 
                style={{color:'#939598', fontSize:'13px', fontWeight:'bold',float:'left'}}>Accent Colour  

                <div style={{ display: "inline-block" }} className="colorPickerDiv">
                <input type={inputAccentShown?'hidden':'color'}  style={{marginLeft:'10px'}}
                 name="accentColor"
                 id="accentColor"
                 className="colorBox"
                 onChange={handleChange}
                 defaultValue={data.accentColor}/>
                 <input
                            type={inputAccentShown?'hidden':'text'}
                            className="colorInput"
                            disabled="disabled"
                            value={data.accentColor}
                          />
                 </div>
                <input type={inputAccentShown?'text':'hidden'} 
                className={
                  !error.accentColor ? "directFieldSelect" : "directFieldSelecterr"
                }
                 name="accentmanual" 
                 id="accentmanual"
                 onChange={handleChange}
                 defaultValue={data.accentmanual}
                 />
                {error.accentColor ? (
                    <span style={{float:'left'}} className="errormsg">{error.accentmsg}</span>
                  ) : (
                    ""
                  )}
                <span style={{padding:'5px', color:'#333333',fontWeight:'normal'}}>Click here to Enter Manually</span>
                <input type="checkbox" key="3" id="3" onClick={()=>handleToggle(3)}></input>
                </div>
                
              
              </Grid>
              <Grid item xs={6}></Grid>


              <Grid item xs={2} style={{marginTop:'20px'}}>
                <div className="gridHead" 
                style={{color:'#939598', fontSize:'13px', fontWeight:'bold',float:'left'}}>Use Dark Action Bar Icons
                </div>
                <div>
                  <select type="text" className="selectIcon" id="bar_icons" name="bar_icons"
                  onChange={handleChange}
                  defaultValue={data.bar_icons}>
                    <option>{""}</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
                
              
              </Grid>
              <Grid item xs={8}></Grid>


             <Grid item xs={6}>
             <div className="ivitediv">
              <button className="datafreeConnectBtnDanger" color="primary" 
              onClick={()=>history.push("/admin/datafreeconnect/application/index")}>
                Cancel
              </button>
            </div> 
             </Grid>
             <Grid item xs={6}>
                {" "}
                <div className="ivitediv">
                <button className="datafreeConnectButton" color="primary" onClick={()=>handlePreviousOpen(3)}>
                    Previous
                </button>
                {" "}
                <button className="datafreeConnectButton" color="primary" style={{marginLeft:'10px'}}
                onClick={()=>NextFormStep(3)}
                >
                    Next
                </button>
                </div>
            </Grid>  


            </Grid>

            <div className="divSpace"></div>
          </div>
    )
}

export default ConnectCreateThirdStep
