import React, { useState, useEffect, useContext } from "react";
import "./application.css";
import defaultIcon from "../../../../assets/img/datafree_defaulticon.png";
import defaultApp from "../../../../assets/img/spashwhite-overlay.png";
import defaultSplash from "../../../../assets/img/default-app-splash.jpg";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdWarning } from "react-icons/md";
import alpha from "../../../../assets/img/blank1.png";
import {
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Box,
  Grid,
  withStyles,
  Typography,
} from "@material-ui/core";
import DevelopmentAppConfiguation from "./maxapplicationDetails/maxdevelopmentAppConfiguation";
import ProductionAppConfiguation from "./maxapplicationDetails/maxProduction";
import ReachDevelopment from "./reachapplication/reachDevelopment";
import ReachPoduction from "./reachapplication/reachProduction";
import SwitchDevelopment from "./switchapplication/switchDevelopment";
import SwitchProduction from "./switchapplication/switchProduction";
import WrapDevelopment from "./wrapapplications/wrapDevelopment";
import WrapProduction from "./wrapapplications/wrapPriduction";
import Adminservice from "../../../../api/adminService";
import DirectPage from "./directPage";
import ConnectPage from "./connectPage";
import { trackPromise } from "react-promise-tracker";
import service from "../../../../api/service";
import { useParams } from "react-router";
import Modal from 'react-modal';
import adminService from "../../../../api/adminService";
import Loader from "react-loading";
import { useHistory } from "react-router-dom";
import { deploymentContext, deploymentDispatchContext, appDetailDispatchContext, appDetailContext } from "../../../../api/context"
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",

  },
});

export default function Application(props) {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  let API_URL = process.env.REACT_APP_API_URL;
  const { paramsId } = useParams();
  let history = useHistory();
  const [editWebsiteopen, setEditWebsiteOpen] = useState(false);
  const [switchOpen, setSwitchOpen] = useState(false);
  const [reachOpen, setReachOpen] = useState(false);
  const [connectOpen, setConnectOpen] = useState(false);
  const [applicationData, setApplicationData] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [icon, setIcon] = useState({ preview: "", raw: "" });
  const [splash, setSplash] = useState({ preview: "", raw: "" });
  const [logo] = useState({ preview: "", raw: "" });
  const [appdelet, setappdelet] = useState(false);
  const [deleteRego, setdeleteRego] = useState(false);
  const [lodervisible, setlodervisible] = useState(false);
  const deploymentContextValue = useContext(deploymentContext)
  const deploymentDispatchContextValue = useContext(deploymentDispatchContext)
  const appDetailDispatchContextValue = useContext(appDetailDispatchContext)
  const appDetailContextValue = useContext(appDetailContext)
  const customStylesDelete = {
    content: {
      width: '550px',
      height: '200px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    }
  }

  function openDelete() {
    setdeleteRego(true);

  }

  function closeDelete() {
    setdeleteRego(false);
  }

  function closeModalDelete() {
    setappdelet(false);
  }

  const handleClose = () => {
    setIconEditOpen(false);
    setEditWebsiteOpen(false);
    setSwitchOpen(false);
    setReachOpen(false);
    setConnectOpen(false);
  };
  const [IconImage, setIconImage] = useState({
    icon_image: ""
  })
  async function UploadIconImage(imageVal) {

    let file = imageVal;

    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("icon_image", file ? file : "");
    }

    trackPromise(
      Adminservice.uploadIcon(values).then((res) => {

        setEditData({
          ...editData,
          icon_image: res.data,
        })
      }).catch((error) => {

      })

    );
  }


  const deleteApp = () => {

    service.deleteAppReg({ applicationId: paramsId }, 'registration').then((res) => {
      if (res.status === 200) {
        history.push("/user/datafree");
      }
    }).catch((err) => {

    })
  }

  const [SplashImage, setSplashImage] = useState({
    splash_image: ""
  })

  function UploadSplashDefault() {


    fetch(defaultSplash)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "default-app-splash.jpg";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "default-app-splash.jpg", {
          type: myBlob.type,
        });

        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("splash_image", myFile ? myFile : "");
        }

        trackPromise(
          Adminservice.uploadSplash(values).then((res) => {

            setSplashImage({
              splash_image: res.data,

            })
          }).catch((error) => {

          })

        );
      });

  }

  async function UploadSplashImage(imageVal) {

    let file = imageVal;

    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("splash_image", file ? file : "");
    }

    trackPromise(
      Adminservice.uploadSplash(values).then((res) => {

        setEditData({
          ...editData,
          splash_image: res.data,
        })
      }).catch((error) => {

      })

    );


  }

  function UploadIconDefault() {

    fetch(defaultIcon)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "datafree_defaulticon.png";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "datafree_defaulticon.png", {
          type: myBlob.type,
        });


        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("icon_image", myFile ? myFile : "");
        }


        trackPromise(
          Adminservice.uploadIcon(values).then((res) => {

            setIconImage({
              icon_image: res.data,
            })

          }).catch((error) => {

          })

        );
      });

  }

  useEffect(() => {
    UploadIconDefault();
    UploadSplashDefault();
  }, []);
  const handleChangeIcon = (e) => {
    const newdata = { ...editData };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "icon_image") {
      if (e.target.files.length !== 0) {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        let filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setIcon({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            icon_image: true,
            imgmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 120 && img.height >= 120) {

              setIcon({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],

              });
              setError({
                ...error,
                icon_image: false,
                imgmsg: ""
              });
              UploadIconImage(e.target.files[0]);
            }
            else if (img.width <= 120) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                imgmsg: "Minimum width must be 120px"
              });
            }
            else if (img.height <= 120) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                imgmsg: "Minimum height must be 120px"
              });
            }
          };
        }
      }
      else {
        setIcon({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          icon_image: false,
          imgmsg: ""
        });

      }
    }

    setEditData(newdata);
  }
  const AppnameReg = /^[^'",]*$/i;
  const googleReg = /^[a-z][a-z0-9_]*(\.[a-z][a-z0-9_]*)+[0-9a-z_]?$/i;
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  const handleChange = (e) => {
    const newdata = { ...editData };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "name") {
      if (e.target.value) {
        if (AppnameReg.test(e.target.value) === false) {
          setError({
            ...error,
            name: true,
            nameMsg: "Please enter a name without comma"
          })
        }
        else {
          setError({
            ...error,
            name: false,
            nameMsg: ""
          })
        }
      }
      else {
        setError({
          ...error,
          name: true,
          nameMsg: "This field is required"
        })
      }
    }


    if (e.target.id === "package") {
      if (e.target.value) {
        if (googleReg.test(e.target.value) === false) {
          setError({
            ...error,
            package: true,
            packageMsg: "Must have two or more segments separated by a fullstops, all segments starting with a character e.g: 'ABC3.com'"
          });
        }
        else {
          setError({
            ...error,
            package: false,
            packageMsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          package: false,
          packageMsg: ""
        });
      }
    }


    if (e.target.id === "icon_image") {
      if (e.target.files.length !== 0) {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        let filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setIcon({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            icon_image: true,
            imgmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 120 && img.height >= 120) {

              setIcon({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],

              });
              setError({
                ...error,
                icon_image: false,
                imgmsg: ""
              });
              UploadIconImage(e.target.files[0]);
            }
            else if (img.width <= 120) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                imgmsg: "Minimum width must be 120px"
              });
            }
            else if (img.height <= 120) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                imgmsg: "Minimum height must be 120px"
              });
            }
          };
        }
      }
      else {
        setIcon({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          icon_image: false,
          imgmsg: ""
        });

      }
    }



    if (e.target.id === "splash_image") {
      if (e.target.files.length !== 0) {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        let filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setSplash({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            splash_image: true,
            splashmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 1080 && img.height >= 1920) {

              setSplash({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],

              });
              setError({
                ...error,
                splash_image: false,
                splashmsg: ""
              });
              UploadSplashImage(e.target.files[0]);
            }
            else if (img.width <= 1080) {
              setSplash({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                splash_image: true,
                splashmsg: "Minimum width must be 1080px"
              });
            }
            else if (img.height <= 120) {
              setSplash({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                splash_image: true,
                splashmsg: "Minimum height must be 1920px"
              });
            }
          };
        }
      }
      else {
        setSplash({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          splash_image: false,
          splashmsg: ""
        });

      }
    }
    setEditData(newdata)
  };

  const editWebsite = (scrollType) => {
    setEditWebsiteOpen(true);
    setScroll(scrollType);
  };

  const editSwitch = (scrollType) => {
    setSwitchOpen(true);
    setScroll(scrollType);
  };

  const editConnect = (scrollType) => {
    setConnectOpen(true);
    setScroll(scrollType);
  };

  const editReach = (scrollType) => {
    setReachOpen(true);
    setScroll(scrollType);
  };

  const ValidateData = (editData) => {
    let count = 0;
    const nwerror = { ...error };
    if (editData.name === "") {
      nwerror.name = true;
      nwerror.nameMsg = "This field required";
      count++;
    }
    else {
      if (AppnameReg.test(editData.name) === false) {
        nwerror.name = true;
        nwerror.nameMsg = "Please enter a name without comma";
        count++;
      }
      else {
        nwerror.name = false;
        nwerror.nameMsg = "";
      }
    }


    if (error.icon_image === true) {
      nwerror.icon_image = true;
      nwerror.imgmsg = error.imgmsg
      count++;
    }
    else {
      nwerror.icon_image = false;
      nwerror.imgmsg = ""
    }

    if (error.splash_image === true) {
      nwerror.splash_image = true;
      nwerror.splashmsg = error.splashmsg
      count++;
    }
    else {
      nwerror.splash_image = false;
      nwerror.splashmsg = ""
    }


    if (editData.package === "") {
      nwerror.package = false;
      nwerror.packageMsg = "";
    }
    else {
      if (googleReg.test(editData.package) === false) {
        nwerror.package = true;
        nwerror.packageMsg = "Must have two or more segments separated by a fullstops, all segments starting with a character e.g: 'ABC3.com'";
        count++;
      }
      else {
        nwerror.package = false;
        nwerror.packageMsg = "";
      }
    }
    setError(nwerror);
    return count;
  }

  const [loading, setLoading] = useState(false);
  const SaveDataImage = (e) => {
    e.preventDefault();
    let file = document.getElementById("icon_image").files[0];
    if (error.icon_image === false) {

      let values = file !== undefined ? new FormData() : "";
      if (file !== undefined) {
        values.append("icon_image", file ? file : "");
      }
      if (values.toString().trim().length > 0) {
        setLoading(true);
        adminService.uploadIcon(values).then((res) => {
          setLoading(true);
          adminService.directLogo({ icon_image: res.data, application_id: paramsId }).then((res) => {

            if (res.status === 200) {
              setLoading(false);
              getApplicationDetails();
              setIconEditOpen(false);
            }

          }).catch((err) => {

          })

        }).catch((err) => {

        })

      }
      else {
        setLoading(true);
        adminService.directLogo({ icon_image: editData.icon_image, application_id: paramsId }).then((res) => {

          if (res.status === 200) {
            setLoading(false);
            getApplicationDetails();
            setIconEditOpen(false);
          }

        }).catch((err) => {

        })
      }
    }
  }
  const SaveData = () => {

    const counts = ValidateData(editData);

    if (editData.icon_image === "(binary)") {
      editData["icon_image"] = IconImage.icon_image;
    }
    if (editData.splash_image === "(binary)") {
      editData["splash_image"] = SplashImage.splash_image;
    }

    if (counts === 0) {

      try {
        trackPromise(
          adminService.editWrap(editData).then((res) => {

            setEditWebsiteOpen(false);
            getApplicationDetails();
            setSwitchOpen(false);
            setReachOpen(false);
          })
        );
      } catch (err) {

      }
    }



  }
  const getApplicationDetails = () => {

    setlodervisible(true);
    try {
      trackPromise(
        service.applicationData(paramsId).then((res) => {
          setlodervisible(false);

          setApplicationData(res?.data?.applicationObj);

          deploymentDispatchContextValue({ type: res?.data?.applicationObj?.deploymentDetails?.deploymentType[0] })
          appDetailDispatchContextValue(res?.data?.applicationObj)
          setEditData({
            name: res.data.applicationObj?.name,
            package: res.data.applicationObj?.googleAppId ? res.data.applicationObj?.googleAppId : "",
            application_id: paramsId,
            icon_image: res.data.applicationObj?.iconUrl,
            splash_image: res.data.applicationObj?.splashScreenImgUrl,
          })
        })
      );
    } catch (err) {

    }
  };

  const [IconEditOpen, setIconEditOpen] = useState(false);
  const [editData, setEditData] = useState({
    name: "",
    package: "",
    icon_image: "",
    splash_image: "",
    application_id: "",
  })

  const [error, setError] = useState({
    name: false,
    package: false,
    icon_image: false,
    splash_image: false,
    msg: "",
    nameMsg: "",
    imgmsg: "",
    splashmsg: "",
    packageMsg: "",
  })

  const showDevelopment = () => {

    if (applicationData.ProductType === "#datafree MAX") {
      return (
        <DevelopmentAppConfiguation
          deployType='DEV'
          applicationId={paramsId}
          tabClick="DEV"
        />
      );
    } else if (applicationData.ProductType === "#datafree REACH") {
      return (
        <ReachDevelopment
          deployType='DEV'
          applicationId={paramsId}
        />
      );
    } else if (applicationData.ProductType === "#datafree SWITCH") {
      return (
        <SwitchDevelopment
          deployType='DEV'
          applicationId={paramsId}
        />
      );
    } else if (applicationData.ProductType === "#datafree WRAP") {
      return (
        <WrapDevelopment
          deployType='DEV'
          applicationId={paramsId}
        />
      );
    } else {

    }
  }

  const showProduction = () => {
    if (applicationData.ProductType === "#datafree MAX") {
      return (<ProductionAppConfiguation applicationId={paramsId} deployType='PROD' />);
    } else if (applicationData.ProductType === "#datafree REACH") {
      return (<ReachPoduction applicationId={paramsId} deployType='PROD' />);
    } else if (applicationData.ProductType === "#datafree SWITCH") {
      return (<SwitchProduction applicationId={paramsId} />);
    }
    else if (applicationData.ProductType === "#datafree WRAP") {
      return (
        <WrapProduction
          deployType={applicationData.deploymentType}
          applicationId={paramsId}
        />
      );
    } else {

    }
  };

  const handleTabChange = (event, newValue) => {

    setValue(newValue);


  };

  const showDirect = () => {
    return <DirectPage />;
  };

  const showConnect = () => {
    return <ConnectPage />;
  };

  const topNav = () => {
    if (
      applicationData?.ProductType === "#datafree REACH" &&
      deploymentContextValue?.type === "PROD"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => editReach("body")}>
            Edit Website Details
          </button>
          <div>
            <button className="subbutton2">Production</button>
          </div>
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree REACH" &&
      deploymentContextValue?.type === "DEV"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => editReach("body")}>
            Edit Website details
          </button>
          <div>
            <button className="devsubbutton2">Development</button>
          </div>
          <div>
            {appDetailContextValue?.prod ? null :
              <button className="delsubbutton2" onClick={() => { openDelete() }}>
                Delete #datafree Website Rego
              </button>}
          </div>
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree WRAP" &&
      deploymentContextValue?.type === "DEV"
    ) {
      return (
        <div style={{ margin: 10 }}>
          <button className="subbutton1" onClick={() => editWebsite("body")}>
            Edit Application Items
          </button>
          <div>
            <button className="devsubbutton2">Development</button>
          </div>
          {appDetailContextValue?.configProd ? null :
            <div>
              <button className="delsubbutton2" onClick={() => openDelete()}>
                Delete #datafree App Rego</button>
            </div>}
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree WRAP" &&
      deploymentContextValue?.type === "PROD"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => editWebsite("body")}>
            Edit Application Items
          </button>

          <div>
            <button className="subbutton2">Production</button>
          </div>
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree MAX" &&
      deploymentContextValue?.type === "DEV"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => editWebsite("body")}>
            Edit Application Items
          </button>
          <div>
            <button className="devsubbutton2">Development</button>
          </div>
          {appDetailContextValue?.configProd ? null :
            <div>
              <button className="delsubbutton2" onClick={() => openDelete()}>Delete #datafree App Rego</button>
            </div>}
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree MAX" &&
      deploymentContextValue?.type === "PROD"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => editWebsite("body")}>
            Edit Application Items
          </button>
          <div>
            <button className="subbutton2">Production</button>
          </div>
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree SWITCH" &&
      deploymentContextValue?.type === "DEV"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => editSwitch("body")}>
            Edit Application Items
          </button>
          <div>
            <button className="devsubbutton2">Development</button>
          </div>
          {appDetailContextValue?.prod ? null : <div>
            <button className="delsubbutton2" onClick={() => openDelete()}>Delete #datafree App Rego</button>
          </div>}
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree SWITCH" &&
      deploymentContextValue?.type === "PROD"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => editSwitch("body")}>
            Edit Application Items
          </button>
          <div>
            <button className="subbutton2">Production</button>
          </div>
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree DIRECT" &&
      deploymentContextValue?.type === "PROD"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => setIconEditOpen(true)}>
            Edit Application Icons
          </button>
          <button className="subbutton2">Production</button>
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree DIRECT" &&
      deploymentContextValue?.type === "DEV"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => setIconEditOpen(true)}>
            Edit Application Icons
          </button>
          <button className="devsubbutton2">Development</button>
        </div>
      );
    }

    else if (
      applicationData?.ProductType === "#datafree CONNECT" &&
      deploymentContextValue?.type === "PROD"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => editConnect("body")}>
            Edit Application Items
          </button>
          <div>
            <button className="subbutton2">Production</button>
          </div>
        </div>
      );
    } else if (
      applicationData?.ProductType === "#datafree CONNECT" &&
      deploymentContextValue?.type === "DEV"
    ) {
      return (
        <div>
          <button className="subbutton1" onClick={() => editWebsite("body")}>
            Edit Application Items
          </button>
          <div>
            <button className="devsubbutton2">Development</button>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    getApplicationDetails();
  }, []);


  return (
    <div>

      <div className="applicationStartView">
        {applicationData?.ProductType !== "#datafree CONNECT" ?
          <div className="applicationTitle">Publisher Designer</div> :
          <div className="applicationHeadTitle">#datafree CONNECT Application Details - User View</div>}
        <Grid className="applicationhead">
          <Grid item xs={12} sm={2} md={2}>
            <div className="applicationheadLogo">
              <img
                src={
                  applicationData ?
                    applicationData?.iconUrl !== "(binary)"
                      ? API_URL + applicationData?.iconUrlPath + applicationData?.iconUrl
                      : defaultIcon
                    : alpha
                }
                alt=""
                className="imgapplicationLogo"
              ></img>
            </div>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <div className="applicationsubContent">
              <div className="applicationurl">
                {applicationData ? applicationData?.name : ""}
              </div>
              <div className="updateddate">
                Last updated: {applicationData ? applicationData?.created : ""}
              </div>
              <div className="productType">
                <div className="productTypeContent">Product Type: </div>
                <div>
                  <span type="text" className="producttypeField" disabled="disabled" style={{ padding: '5px 22px' }}>
                    {applicationData?.ProductType ? applicationData?.ProductType : ""}
                  </span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>

            <div className="applicationsubContent">{topNav()}</div>
          </Grid>
        </Grid>

        {applicationData.ProductType !== "#datafree DIRECT" && applicationData?.ProductType !== "#datafree CONNECT" ? (
          <div className="applicationTab">

            <AntTabs
              value={value}

              onChange={handleTabChange}
              className="tabChange tabViewMenu"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              component="span"
            >
              <AntTab
                label="Development"
                title="Click here to view Development Tab"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
                component="span"
              />
              <AntTab
                label="Production"
                title="Click here to view Production Tab"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
                component="span"
              />
            </AntTabs>
            <TabPanel value={value} index={0}>
              {lodervisible ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
              {showDevelopment()}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {lodervisible ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
              {showProduction()}
            </TabPanel>

          </div>
        ) : (
          applicationData.ProductType === "#datafree DIRECT" ?
            showDirect() : showConnect()

        )}


      </div>

      <Dialog
        open={editWebsiteopen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >

        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Application Details
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="dialoguRow">
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <div className="dialoginputtext">Application Name</div>
                <input
                  type="text"
                  id="name"
                  label="name"

                  className={!error.name ? "dialoginput" : "errdialoginput"}
                  placeholder="Enter the name of your application"
                  fullWidth
                  defaultValue={applicationData.name}
                  onChange={handleChange}
                  style={{
                    height: '38px'
                  }}
                />

                {error.name ? (
                  <p style={{ float: 'left' }} className="errorClass">{error.nameMsg}</p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}>
                <div>
                  <img src={defaultApp} alt="" className="imgdefaultapp"></img>{" "}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>

                  <div className="dialoginputtext">Google App Id</div>
                  <input
                    className={!error.package ? "dialoginput" : "errdialoginput"}
                    placeholder="Enter your Google App ID"
                    fullWidth
                    name="package"
                    id="package"
                    defaultValue={applicationData.googleAppId}
                    onChange={handleChange}
                    disabled="disabled"
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',

                      height: '38px'
                    }}
                  />
                  {error.package ? (
                    <p style={{ float: 'left' }} className="errorClass">{error.packageMsg}</p>
                  ) : (
                    ""
                  )}
                  <div className="dialoginputtext">biNu Product Type</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="dialoginput"
                    fullWidth
                    defaultValue={applicationData.ProductType}
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',
                      height: '38px'
                    }}
                  />

                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={icon.preview
                          ? icon.preview :
                          applicationData?.iconUrl !== "(binary)"
                            ? API_URL + applicationData.iconUrlPath + applicationData.iconUrl :
                            defaultIcon
                        }
                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload an icon image</div>

                      <div className="IconText3">
                        (Min. dimensions of 120px X 120px)
                      </div>
                      {error.icon_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.imgmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="icon_image"
                            id="icon_image"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={splash.preview ? splash.preview : applicationData?.splashScreenImgUrl !== "(binary)" ? API_URL + applicationData.splashScreenImgUrlPath + applicationData.splashScreenImgUrl : defaultSplash}

                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload a splash image</div>

                      <div className="IconText3">
                        (Min. dimensions of 1080px X 1920px)
                      </div>
                      {error.splash_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.splashmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="splash_image"
                            id="splash_image"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>

            <button className="inviteButton" color="primary"
              onClick={SaveData}>
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={switchOpen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Application Details
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="dialoguRow">
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <div className="dialoginputtext">Application Name</div>
                <input
                  type="text"
                  id="name"
                  label="name"

                  className={!error.name ? "dialoginput" : "errdialoginput"}
                  placeholder="Enter the name of your application"
                  fullWidth
                  defaultValue={applicationData.name}
                  onChange={handleChange}
                  style={{
                    height: '38px'
                  }}
                />

                {error.name ? (
                  <p style={{ float: 'left' }} className="errorClass">{error.nameMsg}</p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}>
                <div>
                  <img src={defaultApp} alt="" className="imgdefaultapp"></img>{" "}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>

                  <div className="dialoginputtext">biNu Product Type</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="dialoginput"
                    fullWidth
                    defaultValue={applicationData.ProductType}
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',
                      height: '38px'
                    }}
                  />

                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={icon.preview ?
                          icon.preview :
                          applicationData?.iconUrl !== "(binary)" ? API_URL + applicationData.iconUrlPath + applicationData.iconUrl :
                            defaultIcon}
                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload an icon image</div>

                      <div className="IconText3">
                        (Min. dimensions of 120px X 120px)
                      </div>
                      {error.icon_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.imgmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="icon_image"
                            id="icon_image"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={splash.preview ? splash.preview : applicationData?.splashScreenImgUrl !== "(binary)" ? API_URL + applicationData.splashScreenImgUrlPath + applicationData.splashScreenImgUrl : defaultSplash}

                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload a splash image</div>

                      <div className="IconText3">
                        (Min. dimensions of 1080px X 1920px)
                      </div>
                      {error.splash_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.splashmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="splash_image"
                            id="splash_image"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>

          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>

            <button className="inviteButton" color="primary"
              onClick={SaveData}>
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={connectOpen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Application Details
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="dialoguRow">
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <div className="dialoginputtext">Application Name</div>
                <input
                  type="text"
                  id="name"
                  label="name"

                  //className={!error.name ? "dialoginput" : "errdialoginput"}
                  className="dialoginput"
                  disabled="disabled"
                  placeholder="Enter the name of your application"
                  fullWidth
                  value={applicationData.name}
                  onChange={handleChange}
                  style={{
                    color: '#333',
                    backgroundColor: "#e6e6e6",
                    borderColor: "#adadad",
                    outline: 'none !important',
                    boxShadow: 'none !important',
                    cursor: 'not-allowed',
                    height: '38px'
                  }}
                />

                {error.name ? (
                  <p style={{ float: 'left' }} className="errorClass">{error.nameMsg}</p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}>
                <div>
                  {/* <img src={defaultApp} alt="" className="imgdefaultapp"></img>{" "} */}
                  <img

                    src={splash.preview ? splash.preview : applicationData?.splashScreenImgUrl !== "(binary)" ? API_URL + applicationData.splashScreenImgUrlPath + applicationData.splashScreenImgUrl : defaultSplash}

                    alt=""
                    className="imgdefaultapp"
                  ></img>{" "}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>

                  <div className="dialoginputtext">Store App ID</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="dialoginput"
                    fullWidth
                    defaultValue={applicationData?.googleAppId}
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',
                      height: '38px'
                    }}
                  />

                  <div className="dialoginputtext">biNu Product Type</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="dialoginput"
                    fullWidth
                    defaultValue={applicationData.ProductType}
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',
                      height: '38px'
                    }}
                  />


                  <div className="dialoginputtext">#datafree Gateway domain</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="dialoginput"
                    fullWidth
                    defaultValue={applicationData?.reachGateway}
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',
                      height: '38px'
                    }}
                  />
                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={icon.preview ? icon.preview : applicationData?.iconUrl !== "(binary)" ? API_URL + applicationData.iconUrlPath + applicationData.iconUrl : defaultIcon}
                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload an icon image</div>

                      <div className="IconText3">
                        (Min. dimensions of 120px X 120px)
                      </div>
                      {error.icon_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.imgmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="icon_image"
                            id="icon_image"
                            disabled="disabled"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={logo.preview ? logo.preview : applicationData?.logoUrl !== "(binary)" ? API_URL + applicationData.logoUrlPath + applicationData.logoUrl : defaultIcon}
                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload a logo image</div>

                      <div className="IconText3">
                        (Min. dimensions of 120px X 120px)
                      </div>
                      {error.icon_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.imgmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="logo_image"
                            id="logo_image"
                            disabled="disabled"
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={splash.preview ? splash.preview : applicationData?.splashScreenImgUrl !== "(binary)" ? API_URL + applicationData.splashScreenImgUrlPath + applicationData.splashScreenImgUrl : defaultSplash}

                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload a splash image</div>

                      <div className="IconText3">
                        (Min. dimensions of 1080px X 1920px)
                      </div>
                      {error.splash_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.splashmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            disabled="disabled"
                            name="splash_image"
                            id="splash_image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>

            {/* <button className="inviteButton" color="primary"
            onClick={SaveData}>
              Save
            </button> */}
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={reachOpen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Website Details
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="dialoguRow">
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <div className="dialoginputtext">Website Name</div>
                <input
                  type="text"
                  id="name"
                  label="name"

                  className={!error.name ? "dialoginput" : "errdialoginput"}
                  placeholder="Enter the name of your application"
                  fullWidth
                  defaultValue={applicationData.name}
                  onChange={handleChange}
                  style={{
                    height: '38px'
                  }}
                />

                {error.name ? (
                  <p style={{ float: 'left' }} className="errorClass">{error.nameMsg}</p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}>
                <div>
                  <img src={defaultApp} alt="" className="imgdefaultapp"></img>{" "}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>

                  <div className="dialoginputtext">biNu Product Type</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="dialoginput"
                    fullWidth
                    defaultValue={applicationData.ProductType}
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',
                      height: '38px'
                    }}
                  />

                  <div className="dialoginputtext">#datafree sub-domain</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="dialoginput"
                    fullWidth
                    defaultValue={applicationData?.reachSubDomain}
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',
                      height: '38px'
                    }}
                  />
                  <div className="dialoginputtext">#datafree Gateway domain</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="dialoginput"
                    fullWidth
                    defaultValue={applicationData?.reachGateway}
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',
                      height: '38px'
                    }}
                  />
                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={icon.preview ? icon.preview : applicationData?.iconUrl !== "(binary)" ? API_URL + applicationData.iconUrlPath + applicationData.iconUrl : defaultIcon}
                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload an icon image</div>

                      <div className="IconText3">
                        (Min. dimensions of 120px X 120px)
                      </div>
                      {error.icon_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.imgmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="icon_image"
                            id="icon_image"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img

                        src={splash.preview ? splash.preview : applicationData?.splashScreenImgUrl !== "(binary)" ? API_URL + applicationData.splashScreenImgUrlPath + applicationData.splashScreenImgUrl : defaultSplash}

                        alt=""
                        className="iconimgupload"
                      ></img>
                    </div>
                    <div className="iconBox">
                      <div className="fileIconText1">Upload a splash image</div>

                      <div className="IconText3">
                        (Min. dimensions of 1080px X 1920px)
                      </div>
                      {error.splash_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.splashmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="appfile-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="splash_image"
                            id="splash_image"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>

            <button className="inviteButton" color="primary"
              onClick={SaveData}>
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/** Delete Model */}
      <Modal
        isOpen={appdelet}
        style={customStylesDelete}
        scroll={scroll}
        maxWidth="md"
        /* onAfterOpen={afterOpenModal}
         onRequestClose={closeModalDelete}*/
        // style={customStyles}
        contentLabel="Clone confiration"
      >
        <div style={{
          display: 'flex', flexDirection: 'row',
          justifyContent: 'space-between', margin: '10px', alignItems: 'center'
        }}
        >
          <div>
            <h4>WARNING !</h4>
          </div>
          <div onClick={closeModalDelete}>
            <div
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: '2px solid gray',
                textAlign: 'center',
                cursor: 'pointer'
              }}
            >
              <span>X</span>
            </div>
          </div>
        </div>
        <p>
          Are you sure you want to delete this #datafree App Version ? This cannot be undone.
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <div>
            <button onClick={closeModalDelete} style={{
              backgroundColor: 'white',
              border: '2px solid red',
              borderRadius: '10px',
              width: '200px',
              padding: '5px',
              color: 'red'

            }}>Cancel</button>
          </div>
          <div>
            <button style={{
              backgroundColor: 'white',
              border: '2px solid red',
              borderRadius: '10px',
              width: '200px',
              padding: '5px',
              color: 'red'

            }} >Confirm Delete</button>
          </div>
        </div>
      </Modal>


      <Dialog open={deleteRego} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup" onClick={closeDelete} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={closeDelete} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
            <div className="warningIcon"><MdWarning /></div>
            <div className="warningText">WARNING !</div>

          </div>
          <div className="logoConfirmtext">Are you sure you want to delete this #datafree App Registration ? This cannot be undone.
          </div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{ textAlign: 'center' }}>
            <button className="continueBtn" name="popup" onClick={closeDelete} color="primary">
              Cancel
            </button>

            <button className="continueBtn" name="popup" color="primary" onClick={deleteApp}>
              Confirm Delete
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* Application Icon Model */}
      <Dialog
        open={IconEditOpen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Application Icon
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="dialoguRow">
            <Grid container>

              <Grid item xs={12}>
                <div className="dialoginputtext">Application Name</div>
                <input
                  type="text"
                  id="name"
                  label="name"

                  //className={!error.name ? "dialoginput" : "errdialoginput"}
                  className="dialoginput"
                  disabled="disabled"
                  placeholder="Enter the name of your application"
                  fullWidth
                  value={applicationData.name}
                  onChange={handleChange}
                  style={{
                    color: '#333',
                    backgroundColor: "#e6e6e6",
                    borderColor: "#adadad",
                    outline: 'none !important',
                    boxShadow: 'none !important',
                    cursor: 'not-allowed',
                    height: '38px'
                  }}
                />

                {error.name ? (
                  <p style={{ float: 'left' }} className="errorClass">{error.nameMsg}</p>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12}>
                <div>

                  <div className="dialoginputtext">biNu Product Type</div>
                  <input
                    type="text"
                    id="email"
                    disabled="disabled"
                    label="email"
                    className="dialoginput"
                    fullWidth
                    defaultValue={applicationData.ProductType}
                    style={{
                      color: '#333',
                      backgroundColor: "#e6e6e6",
                      borderColor: "#adadad",
                      outline: 'none !important',
                      boxShadow: 'none !important',
                      cursor: 'not-allowed',
                      height: '38px'
                    }}
                  />

                </div>
              </Grid>
              <div className="registerIconUpload">
                <div className="rowClass loadimg">
                  <img

                    src={icon.preview ?
                      icon.preview :
                      applicationData?.iconUrl !== null ?
                        API_URL + applicationData.iconUrlPath + applicationData.iconUrl :
                        defaultIcon}
                    alt=""
                    className="iconimgupload"
                  ></img>
                </div>
                <div className="iconBox">
                  <div className="fileIconText1">Upload a icon image</div>

                  <div className="IconText3">
                    (Min. dimensions of 120px X 120px)
                  </div>
                  {error.icon_image ? (
                    <p style={{ float: 'left' }} className="errorClass">{error.imgmsg}</p>
                  ) : (
                    ""
                  )}
                  <div className="file-upload">
                    <div className="appfile-select">
                      <div className="file-select-button" id="fileName">
                        Choose File
                      </div>
                      <div className="file-select-name" id="noFile">
                        default-app-icon.png
                      </div>
                      <input
                        type="file"
                        name="icon_image"
                        id="icon_image"
                        onChange={handleChangeIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>

            <button className="inviteButton" color="primary"
              onClick={SaveDataImage}>
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// ReactDOM.render(<WrapDevelopment />, document.getElementById('root'));