import React, { useState, useEffect,useContext } from "react";
import {
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Box,
  withStyles,
  Grid,
  Typography,
} from "@material-ui/core";
import { FaTimes } from "react-icons/fa";
import service from "../../../../../api/service";
import { trackPromise } from "react-promise-tracker";
import { MdWarning } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Loader from "react-loading";
import { deploymentDispatchContext,appDetailDispatchContext} from "../../../../../api/context"
const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
});
const AntTabs = Tabs;
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function SwitchDevelopment(props) {
  const classes = useStyles();
  const [deleteopen, setDeleteOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [editInfo, setEditInfo] = useState(false);
  const [applicationData, setApplicationData] = useState(false);
  const deploymentDispatchContextValue = useContext(deploymentDispatchContext)
  const appDetailDispatchContextValue = useContext(appDetailDispatchContext)
  const handleopendelete = () => {
    setDeleteOpen(true);
  };
  const handleCloseSwitch = () => {
    setEditInfo(false);
    setSwitchDev({
      max_bytes_content: "",
      content_type: "",
      use_test_whitelist: "",
      max_bytes_per_content_hidden: "",
      max_bytes_per_content_changed: "",
      application_id: props.applicationId
    });
    setError({
      max_bytes_content: false,
      content_type: false,
      contentMsg: "",
      mimeMsg: ""
    });
    getApplicationDetails();
  }
  const handleClose = () => {

    setDeleteOpen(false);
    getApplicationDetails();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [switchDev, setSwitchDev] = useState({
    max_bytes_content: "",
    max_bytes_per_content_hidden: "",
    max_bytes_per_content_changed: "",
    content_type: "",
    use_test_whitelist: "",
    application_id: props.applicationId
  })

  const [error, setError] = useState({
    max_bytes_content: false,
    content_type: false,
    contentMsg: "",
    mimeMsg: ""
  })

  const edit = () => {
    setEditInfo(true);

  };
  const [DomainMap, setDomainMap] = useState([
    {
      domain_map_source: "",
      domain_map_target: "",
      domain_map_https: "",
      domain_map_redirect: "",
    }
  ]);
  const [DomainError, setDomainError] = useState([{
    domain_map_source: false,
    domain_map_target: false,
    sourcemsg: "",
    targetmsg: "",
  }])
  const [securityEditss, setSecurityEditss] = useState({
    content_type: ""
  })
  const getApplicationDetails = () => {
    setLoading(true);
    try {
      trackPromise(
        service.applicationData(props.applicationId).then((res) => {
          setApplicationData(res.data.applicationObj);
          appDetailDispatchContextValue(res?.data?.applicationObj)
          deploymentDispatchContextValue({type:res?.data?.applicationObj?.deploymentDetails?.deploymentType[0]})
          res?.data?.applicationObj?.dev?.securityRules?.blockedContent?.mime?.map((item, index) => (
            setSecurityEditss({ content_type: item ? item : "" })
          ))
          if (res?.data?.applicationObj?.appObjBinuSdkVersionId === 1) {
            setSwitchDev({
              max_bytes_content: res?.data?.applicationObj?.dev?.securityRules?.contentSize ? res?.data?.applicationObj?.dev?.securityRules?.contentSize : "",
              max_bytes_per_content_hidden: res?.data?.applicationObj?.dev?.securityRules?.contentSize ? res?.data?.applicationObj?.dev?.securityRules?.contentSize : "",
              max_bytes_per_content_changed: "no",
              content_type: securityEditss.content_type
            })

          }

          if (res?.data?.applicationObj?.appObjBinuSdkVersionId === 1 && res?.data?.applicationObj?.appObjReachGateway === null) {
            res?.data?.applicationObj?.dev?.securityRules?.domainMap.map((v, index) => {
              if (index === 0) {
                setDomainMap(
                  [{
                    domain_map_source: v.source ? v.source : "",
                    domain_map_target: v.target ? v.target : "",
                    domain_map_https: v.httpsOnly ? v.httpsOnly : false,
                    domain_map_redirect: v.redirect ? v.redirect : false,
                  },]);


                setDomainError(
                  [{
                    domain_map_source: false,
                    domain_map_target: false,
                    sourcemsg: "",
                    targetmsg: "",
                  },]);

              }
              else {
                setDomainMap((prevState) => (
                  [...prevState, {
                    domain_map_source: v.source ? v.source : "",
                    domain_map_target: v.target ? v.target : "",
                    domain_map_https: v.httpsOnly ? v.httpsOnly : false,
                    domain_map_redirect: v.redirect ? v.redirect : false,
                  },]))


                setDomainError((prevState) => (
                  [...prevState, {
                    domain_map_source: false,
                    domain_map_target: false,
                    sourcemsg: "",
                    targetmsg: "",
                  },]));

              }



            });

            setSwitchDev({
              binu_sdk_version: res?.data?.applicationObj?.appObjBinuSdkVersionId,
              max_bytes_content: res?.data?.applicationObj?.dev?.securityRules?.contentSize ? res?.data?.applicationObj?.dev?.securityRules?.contentSize : "",
              max_bytes_per_content_hidden: res?.data?.applicationObj?.dev?.securityRules?.contentSize ? res?.data?.applicationObj?.dev?.securityRules?.contentSize : "",
              max_bytes_per_content_changed: "no",
              content_type: securityEditss.content_type
            });
            // res?.data?.applicationObj?.dev?.securityRules?.blockedContent?.mime?.map((item, index) => (
            //   setSwitchDev({...switchDev, content_type: item ?  item : ""})
            // ));
          }
          if (res?.data?.applicationObj?.appObjBinuSdkVersionId !== 1) {
            setSwitchDev({
              binu_sdk_version: res?.data?.applicationObj?.appObjBinuSdkVersionId,
              systest: res?.data?.applicationObj?.dev?.whitelist ? res?.data?.applicationObj?.dev?.whitelist : "",
              use_test_whitelist: res?.data?.applicationObj?.dev?.useWhiteList === true ? true : false,
              max_bytes_content: res?.data?.applicationObj?.dev?.securityRules?.contentSize ? res?.data?.applicationObj?.dev?.securityRules?.contentSize : "",
              max_bytes_per_content_hidden: res?.data?.applicationObj?.dev?.securityRules?.contentSize ? res?.data?.applicationObj?.dev?.securityRules?.contentSize : "",
              max_bytes_per_content_changed: "no",
              content_type: securityEditss.content_type
            })
          }
          setLoading(false)
          setUseCheck(res?.data?.applicationObj?.dev?.useWhiteList === true ? true : false);
        })
      );
    } catch (err) {

    }
  };

  const [useCheck, setUseCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  

  const [hasApp, setHasApp] = useState(false);
  const getGenerateApp = () => {
    setLoading(true)
    service.generateApp({
      "applicationId": props?.applicationId,
      "type": "DEV"
    }).then((res) => {

      setLoading(false);
      if (res.data?.hasAppId === "Yes") {
      
        getApplicationDetails();
        setHasApp(true);
      }
      else {
        setHasApp(false);
      }

    }).catch((err) => {

    })

  };

  const createDev = () => {
    try {
      trackPromise(
        service.createSwitch({ application_id: props.applicationId, application_type: "dev", product_type: "3" }).then((res) => {
         
          getGenerateApp();

        })
      );
    } catch (err) {

    }
  }


  const contentReg = /^[0-9]*$/;
  const mimeReg = /^[a-z]+\/(\*|[a-z]+[-+.a-z0-9]*)$/m;
  const whitelistReg = /^((^\*\.)|[a-zA-Z0-9-_])*([.][.a-zA-Z0-9-_]+)$/m;

  const handleData = (e) => {
    const newdata = { ...switchDev }


    newdata[e.target.id] = e.target.value;
  
    if (e.target.id === "max_bytes_content") {
      if (e.target.value) {
        newdata['max_bytes_per_content_changed'] = 'yes'
        newdata['max_bytes_per_content_hidden'] = e.target.value
        if (contentReg.test(e.target.value) === false) {
          setError({
            ...error,
            max_bytes_content: true,
            contentMsg: "Invalid Format."
          });
        }
        else {
          setError({
            ...error,
            max_bytes_content: false,
            contentMsg: ""
          })
        }
      }
      else {
        newdata['max_bytes_per_content_changed'] = 'no'
        newdata['max_bytes_per_content_hidden'] = switchDev?.max_bytes_content
        setError({
          ...error,
          max_bytes_content: false,
          contentMsg: ""
        })
      }
    }
    if (e.target.id === "content_type") {
      if (e.target.value) {
        var contentTypeValue = e.target.value;
        var modifiedContentTypeValue = '';
        if (contentTypeValue !== '') {
          var originalContentType = contentTypeValue.split('\n');
          for (var i = 0; i < originalContentType.length; i++) {
            if (i === originalContentType.length - 1) {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*';
                } else {
                  modifiedContentTypeValue += originalContentType[i];
                }
              }
            } else {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*' + '\n';
                } else {
                  modifiedContentTypeValue += originalContentType[i] + '\n';
                }
              }
            }
          }
       
        }

        if (mimeReg.test(modifiedContentTypeValue) === false) {
          setError({
            ...error,
            content_type: true,
            mimeMsg: "Please enter a valid content type."
          });
        }
        else {
          setError({
            ...error,
            content_type: false,
            mimeMsg: ""
          });
          e.target.value = modifiedContentTypeValue;
          newdata[e.target.id] = e.target.value;
        }

      }
      else {
        setError({
          ...error,
          content_type: false,
          mimeMsg: ""
        })
      }
    }

    if (e.target.id === "systest") {
      if (e.target.value) {
        if (whitelistReg.test(e.target.value) === false) {
          setError({
            ...error,
            systest: true,
            sysMsg: "Please enter a valid whitelist."
          });
        }
        else {
          setError({
            ...error,
            systest: false,
            sysMsg: ""
          })
        }
      }
      else {
        setError({
          ...error,
          systest: false,
          sysMsg: ""
        })
      }


    }

    setSwitchDev(newdata);
  }
  const validateReach = (switchDev) => {
  
    let rCount = 0;
    let nwerror = { ...error }

    if (switchDev.max_bytes_content === "" || switchDev.max_bytes_content === null) {
      nwerror.max_bytes_content = false;
      nwerror.contentMsg = "";
    }
    else {
      if (contentReg.test(switchDev.max_bytes_content) === false) {
        nwerror.max_bytes_content = true;
        nwerror.contentMsg = "Invalid Format.";
        rCount++;
      }
      else {
        nwerror.max_bytes_content = false;
        nwerror.contentMsg = ""
      }
    }

    if (switchDev.content_type === "" || switchDev.content_type === null) {
      nwerror.content_type = false;
      nwerror.mimeMsg = "";
    }
    else {

      if (mimeReg.test(switchDev.content_type) === false) {
        nwerror.content_type = true;
        nwerror.mimeMsg = "Please enter a valid content type.";
        rCount++;
      }
      else {
        nwerror.content_type = false;
        nwerror.mimeMsg = ""
      }
    }


    if (applicationData?.appObjBinuSdkVersionId !== 1) {
      if (switchDev.systest === "" || switchDev.systest === null || switchDev.systest === undefined) {
        nwerror.systest = false;
        nwerror.sysMsg = "";
      }
      else {
        if (whitelistReg.test(switchDev.systest) === false) {
          nwerror.systest = true;
          nwerror.sysMsg = "Please enter a valid whitelist.";
          rCount++;
        }
        else {
          nwerror.systest = false;
          nwerror.sysMsg = ""
        }
      }
    }


    for (let i = 0; i <= DomainMap.length; i++) {
      setDomainError(DomainError.filter((newError, index) => {
        if (index === i) {
          if (DomainMap[i]?.domain_map_source !== "") {
            if (apkValidReg.test(DomainMap[i]?.domain_map_source) === false) {
              newError.domain_map_source = true;
              newError.sourcemsg = "Please enter a valid URL";
              rCount++;
            }
            else {
              newError.domain_map_source = false;
              newError.sourcemsg = "";
            }
          }
          else {
            newError.domain_map_source = false;
            newError.sourcemsg = "";
          }


          if (DomainMap[i]?.domain_map_target !== "") {
            if (apkReg.test(DomainMap[i]?.domain_map_target) === false) {
              newError.domain_map_target = true;
              newError.targetmsg = "Please enter a valid URL";
              rCount++;
            }
            else {
              newError.domain_map_target = false;
              newError.targetmsg = "";
            }
          }
          else {
            newError.domain_map_target = false;
            newError.targetmsg = "";
          }

        }
        return newError;
      }))


    }

    setError(nwerror);
    return rCount;
  }

  const deleteRow = (record, idx) => {
    const temp = [...DomainMap];
    const nwvals = temp.filter(r => r !== record);
    setDomainMap(nwvals);

    const temp1 = [...DomainError];
    temp1.splice(idx, 1);
    setDomainError(temp1);
  }

  const addRow = (e) => {
    e.preventDefault();

    setDomainMap((prevState) => (
      [...prevState, {
        domain_map_source: "",
        domain_map_target: "",
        domain_map_https: "",
        domain_map_redirect: "",

      }]
    ))

    setDomainError((prevState) => (
      [...prevState, {
        domain_map_source: false,
        domain_map_target: false,
        sourcemsg: "",
        targetmsg: "",
      }]
    ))

  }


  const handleDomain = (e) => {
    e.preventDefault();
    if (["domain_map_source", "domain_map_target"].includes(e.target.name)) {
      //const valuesNew = [...SupApps];
      DomainMap[e.target.dataset.id][e.target.name] = e.target.value

      checkError();
    }
    if (["domain_map_https", "domain_map_redirect"].includes(e.target.name)) {
      //const valuesNew = [...SupApps];
      //alert(e.target.checked);

      DomainMap[e.target.dataset.id][e.target.name] = e.target.checked
      checkError();
    }
    setDomainMap(DomainMap);

  }
  const apkValidReg = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/i;
  const apkReg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const checkError = () => {
    for (let i = 0; i <= DomainMap.length; i++) {
      setDomainError(DomainError.filter((newError, index) => {
        if (index === i) {
          if (DomainMap[i]?.domain_map_source !== "") {
            if (apkValidReg.test(DomainMap[i]?.domain_map_source) === false) {
              newError.domain_map_source = true;
              newError.sourcemsg = "Please enter a valid URL";
            }
            else {
              newError.domain_map_source = false;
              newError.sourcemsg = "";
            }
          }
          else {
            newError.domain_map_source = false;
            newError.sourcemsg = "";
          }


          if (DomainMap[i]?.domain_map_target !== "") {
            if (apkReg.test(DomainMap[i]?.domain_map_target) === false) {
              newError.domain_map_target = true;
              newError.targetmsg = "Please enter a valid URL";
            }
            else {
              newError.domain_map_target = false;
              newError.targetmsg = "";
            }
          }
          else {
            newError.domain_map_target = false;
            newError.targetmsg = "";
          }

        }
        return newError;
      }))


    }

  }

  //secutiy Edit
  const [switchClick, setSwitchClick] = useState();
  const [switchPop, setSwitchPop] = useState(false);
  const [message, setMessage] = useState();
  const saveSwitch = () => {
    const switchCount = validateReach(switchDev);


    if (switchCount === 0) {

      switchDev.use_test_whitelist = "";
      if (applicationData.appObjBinuSdkVersionId !== 1) {
        if (useCheck) {
          switchDev.use_test_whitelist = 1;
        }
        else {
          switchDev.use_test_whitelist = 0;
        }
      }
      switchDev.taskList = DomainMap;

      if (applicationData?.appObjReachGateway === null && applicationData?.appObjBinuSdkVersionId === 1) {

        switchDev.taskList = DomainMap;

      }
      setSwitchClick(true);
      switchDev['application_id'] = props?.applicationId;
     
      service.saveSwitcDev(switchDev).then((res) => {
        if (res?.data?.message === "success") {
          setEditInfo(false);
          setSwitchClick(false);
          setSwitchDev({
            max_bytes_content: "",
            max_bytes_per_content_hidden: "",
            max_bytes_per_content_changed: "no",
            content_type: "",
            use_test_whitelist: "",
            application_id: props.applicationId
          });
          handleCloseSwitch();
        }
        if (res?.data?.message === "failed") {
          setEditInfo(false);
          setSwitchClick(false);
          setSwitchPop(true);
          setMessage(res?.data?.description)
          setSwitchDev({
            max_bytes_content: "",
            max_bytes_per_content_hidden: "",
            max_bytes_per_content_changed: "no",
            content_type: "",
            use_test_whitelist: "",
            application_id: props.applicationId
          });
          handleCloseSwitch();
        }
      })
    }
  }

  const launchUrl = (appId) => {
    let baseAPI_URL = process.env.REACT_APP_API_URL;
    let getToken = localStorage.getItem('token');
    let subbaseUrl = baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path = baseAPI_URL.split(hostName[0]).join("qamonitor");
    //window.open(path + "/login/" + appId);
    window.open(baseAPI_URL + "/api/sso?token=" + getToken +"&&redirectto="+ path + "/login/" + appId);
  }

  const deleteVersion = () => {
    service.deleteAppReg({ applicationId: props.applicationId }, 'version').then((res) => {
      handleClose();
      getApplicationDetails();
      getGenerateApp();
    }).catch((err) => {

    })
  }

  useEffect(() => {
    setLoading(true);
    document.title = "Datafree Portal";
    getApplicationDetails();
    getGenerateApp();
  }, []);
  return (
    <div>
      <div className="dialoguRow">
        <div className="devHead">

        </div>
        <div className="devmainHead">
          Development App SDK / Library
        </div>
        <div className="devHead">

        </div>

      </div>
      {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
      {!loading && hasApp ? (
        <div className="developmentTab">

          <div className="developTabContent">
            <div className="developTabContenthead">
              biNu ID :{" "}
              <span>
                {applicationData ? applicationData?.dev?.appId : ""}
              </span>
            </div>
            <div className="developTabContenthead">
              Security Key  : <span>{applicationData ? applicationData?.dev?.securityKey : ""}</span>
            </div>


            <div className="developToolsTabContenthead">Tools:</div>

            <div style={{ textAlign: "left", marginLeft: "10px", marginTop: '65px' }}>
              <button className="devdtbtn1"
                style={{ width: '97%', height: '60px', cursor: 'pointer' }}>
                <a style={{ textDecoration: 'none' }}
                  target="_blank"
                  href={applicationData ? applicationData?.dev?.binuSdkVersionId?.binuSdkDocumentation : ""} rel="noreferrer"
                >
                  #datafree SWITCH Integration Documentation
                </a>
              </button>
            </div>
            {applicationData?.dev?.binuSdkVersionId?.binuSdkVersionId === 1 && applicationData?.dev?.applicationId?.reachGateway !== null ?
              <div style={{ textAlign: "left", marginLeft: "10px" }} >
                <button className="devdtbtn1"
                  style={{ width: '97%', height: '60px', cursor: 'pointer' }} onClick={() => launchUrl(applicationData?.dev?.appId)}>

                  Launch SWITCH Lite #datafree Workbench

                </button>
              </div> : null}

            <div style={{ textAlign: "left", marginLeft: "10px" }}>
              <button className="devdtbtn2" onClick={handleopendelete} style={{ width: '97%', cursor: 'pointer', height: '39px' }}>
                Delete Website Version Definition
              </button>
            </div>
          </div>
          <div className="developMaxTabContent">
            <AntTabs
              value={value}
              onChange={handleChange}
              className="tabChange tabViewMenu"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <AntTab
                label="Security"
                title="Click here to view Security Tab"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              />
            </AntTabs>

            <TabPanel value={value} index={0}>
              <div className="developmentSecurityTab">
                <button
                  className={!editInfo ? "editbtn" : "NotEdit"}
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={edit}>
                  Edit
                </button>

                <button
                  className={editInfo ? "editbtn" : "NotEdit"}
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={handleCloseSwitch}>
                  Cancel
                </button>

                <button
                  className={editInfo ? "editbtn" : "NotEdit"}
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  disabled={switchClick ? 'disabled' : false}
                  onClick={saveSwitch}
                >Save</button>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className="companyLabel">
                    biNu SDK Version
                  </div>

                  <div>
                    {applicationData?.dev?.binuSdkVersionId?.binuSdkVersionId === 1 ? (
                      <input
                        type="text"
                        className="inputField"
                        name="name"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={applicationData ? applicationData?.dev?.binuSdkVersionId?.binuSdkVersionTag : ""}
                      />
                    ) :
                      (
                        !editInfo ? (
                          <select
                            type="text"
                            className="inputField"
                            name="binu_sdk_version"
                            id="binu_sdk_version"
                            disabled="disabled"
                            style={{ cursor: 'not-allowed', width: '108%', height: '36px' }}
                          >
                            {applicationData?.binuSdkVersion?.map(post => (
                              post.binuSdkVersionId !== 1 ?
                                <option key={post.binuSdkVersionId}
                                  value={post.binuSdkVersionId}
                                  selected={post.binuSdkVersionId === applicationData?.dev?.binuSdkVersionId?.binuSdkVersionId && true}>
                                  {post.binuSdkVersionTag}
                                </option>
                                : null
                            ))}
                          </select>)

                          :
                          <select
                            type="text"
                            className="editinputField"
                            name="binu_sdk_version"
                            id="binu_sdk_version"
                            style={{ width: '108%', height: '36px' }}
                            onChange={handleData}
                          >
                            {applicationData?.binuSdkVersion?.map(post => (
                              post.binuSdkVersionId !== 1 ?
                                <option key={post.binuSdkVersionId}
                                  value={post.binuSdkVersionId}
                                  selected={post.binuSdkVersionId === applicationData?.dev?.binuSdkVersionId?.binuSdkVersionId && true}>
                                  {post.binuSdkVersionTag}
                                </option>
                                : null
                            ))}
                          </select>
                      )}
                  </div>
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                  <div className="companyLabel">
                    Max Bytes per content object
                  </div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="inputField"
                        name="name"
                        disabled="disabled"
                        value={applicationData ? applicationData?.dev?.securityRules?.contentSize : ""}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.max_bytes_content ? "editinputField" : "editinputFielderr"}
                        name="name"
                        id="max_bytes_content"
                        defaultValue={applicationData ? applicationData?.dev?.securityRules?.contentSize : ""}
                        onChange={handleData}
                        onBlur={handleData}
                      />
                    )}
                    {error.max_bytes_content ? (
                      <div className="errorClass">{error.contentMsg}</div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={6}></Grid>
                {applicationData?.dev?.binuSdkVersionId?.binuSdkVersionId !== 1 ? (
                  <Grid item xs={6}>
                    <div className="companyLabel">Test WhiteList

                      <input
                        type="checkbox"
                        id="use_test_whitelist"
                        checked={useCheck}
                        //disabled={!editInfo ? 'disabled' : false}
                        //onChange={handleData}
                        onChange={() => setUseCheck(!useCheck)}
                        style={{ float: 'right' }}
                      />
                      <label className="companyLabel" style={{ float: 'right' }}>Use whitelist</label>
                    </div>

                    <div>
                      {!editInfo ? (
                        <textarea
                          type="text"
                          className="inputField"
                          id="systest"
                          disabled="disabled"
                          style={{ height: '70px' }}
                          value={applicationData ? applicationData?.dev?.whitelist : ""}
                        />
                      ) : (
                        <textarea
                          type="text"
                          className={!error.systest ? "editinputField" : "editinputFielderr"}
                          id="systest"
                          style={{ height: '70px' }}
                          defaultValue={applicationData ? applicationData?.dev?.whitelist : ""}
                          onBlur={handleData}
                          onChange={handleData}
                        />
                      )}
                      {error.systest ? (
                        <div className="errorClass">{error.sysMsg}</div>
                      ) : null}
                    </div>
                  </Grid>) : null}
                {applicationData?.dev?.binuSdkVersionId?.binuSdkVersionId !== 1 ? (<Grid item xs={6}></Grid>) : null}
                <Grid item xs={6}>
                  <div className="companyLabel">Content Types blocked (Mime types)</div>
                  <div>
                    {!editInfo ? (
                      <textarea
                        type="text"
                        className="inputField"
                        name="Description"
                        disabled="disabled"
                        style={{ height: '70px' }}
                        value={applicationData ? applicationData?.dev?.securityRules?.blockedContent?.mime : ""}
                      />
                    ) : (
                      <textarea
                        type="text"
                        className={!error.content_type ? "editinputField" : "editinputFielderr"}
                        name="Description"
                        id="content_type"
                        style={{ height: '70px' }}
                        defaultValue={applicationData ? applicationData?.dev?.securityRules?.blockedContent?.mime : ""}
                        onBlur={handleData}
                      />
                    )}
                    {error.content_type ? (
                      <div className="errorClass">{error.mimeMsg}</div>
                    ) : null}
                  </div>
                </Grid>

                {applicationData?.dev?.binuSdkVersionId?.binuSdkVersionId === 1 && applicationData?.dev?.applicationId?.reachGateway === null ? (
                  <Grid item xs={12}>
                    <div className="companyLabel">Domain Map (for datafree SWITCH Lite Product only)</div>
                    <div className="datafeeSubBox boxConnect" style={{ marginLeft: '2px' }}>
                      <div className="boxRow">
                        <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                          <div className="companyLabel" style={{ textAlign: 'right' }}>Source</div>
                        </div>

                        <div className="flex1" style={{ marginTop: '6px', marginLeft: '48px' }}>
                          <div className="companyLabel" style={{ textAlign: 'right', marginLeft: '69px' }}>Target</div>
                        </div>
                        <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                          <div className="companyLabel" style={{ textAlign: 'right', marginLeft: '32px' }}>HTTPS Only</div>
                        </div>
                        <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                          <div className="companyLabel" style={{ textAlign: 'right' }}>Redirect</div>
                        </div>
                        <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                          <div className="companyLabel" style={{ textAlign: 'right' }}>Delete</div>
                        </div>
                      </div>


                      {!editInfo && applicationData?.dev?.securityRules?.domainMap ?
                        applicationData?.dev?.securityRules?.domainMap.map((item, index) => (
                          <div className="boxRow" key={item.index}>
                            <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>

                              <input
                                type="text"
                                className="editinputField"
                                style={{ width: '220%' }}
                                disabled="disabled"
                                value={item?.source ? item?.source : ""}
                              />
                            </div>

                            <div className="flex1" style={{ marginTop: '6px', marginLeft: '48px' }}>

                              <input
                                type="text"
                                className="editinputField"
                                style={{ marginLeft: '60px' }}
                                disabled="disabled"
                                value={item?.target ? item?.target : ""}
                              />
                            </div>
                            <div className="flex1" style={{ marginTop: '12px', marginLeft: '33px' }}>

                              <input
                                type="checkbox"
                                disabled="disabled"
                                style={{ marginLeft: '90px' }}
                                checked={item?.httpsOnly ? item?.httpsOnly : false}
                              />
                            </div>
                            <div className="flex1" style={{ marginTop: '12px', marginLeft: '33px' }}>

                              <input
                                type="checkbox"
                                disabled="disabled"
                                style={{ marginLeft: '20px' }}
                                checked={item?.redirect ? item?.redirect : false}
                              />
                            </div>
                            <div className="flex1" style={{ marginTop: '6px', marginLeft: '0px' }}>
                              <button disabled="disabled"
                                style={{ marginLeft: '69px', border: 'none' }}>
                                <FaTimes className="fatimesclass" style={{ fontSize: '20px', color: 'red' }} />
                              </button>
                            </div>
                          </div>

                        )) :



                        DomainMap.map((v, index) => (
                          <div className="boxRow" key={v.index}>
                            <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>

                              <input
                                type="text"
                                id="domain_map_source"
                                name="domain_map_source"
                                className={
                                  !DomainError[index]?.domain_map_source ? "editinputField" : "editinputFielderr"
                                }
                                style={{ width: '220%' }}
                                data-id={index}
                                value={v.domain_map_source}
                                onChange={handleDomain}
                              />
                              {DomainError[index]?.domain_map_source ? <span style={{ float: 'left', width: '258%' }} className="errormsg" name="identifiererror">{DomainError[index]?.sourcemsg}</span> : null}

                            </div>

                            <div className="flex1" style={{ marginTop: '6px', marginLeft: '48px' }}>

                              <input
                                type="text"
                                className={
                                  !DomainError[index]?.domain_map_target ? "editinputField" : "editinputFielderr"
                                }
                                id="domain_map_target"
                                name="domain_map_target"
                                style={{ marginLeft: '60px' }}
                                data-id={index}
                                value={v.domain_map_target}
                                onChange={handleDomain}
                              />
                              {DomainError[index]?.domain_map_target ? <span style={{ float: 'left', width: '151%', marginLeft: '53px' }} className="errormsg" name="identifiererror">{DomainError[index]?.targetmsg}</span> : null}

                            </div>
                            <div className="flex1" style={{ marginTop: '12px', marginLeft: '33px' }}>

                              <input
                                type="checkbox"
                                id="domain_map_https"
                                name="domain_map_https"
                                style={{ marginLeft: '90px' }}
                                checked={v.domain_map_https}
                                data-id={index}
                                onChange={handleDomain}
                              />
                            </div>
                            <div className="flex1" style={{ marginTop: '12px', marginLeft: '33px' }}>

                              <input
                                type="checkbox"
                                id="domain_map_redirect"
                                name="domain_map_redirect"
                                style={{ marginLeft: '20px' }}
                                checked={v.domain_map_redirect}
                                data-id={index}
                                onChange={handleDomain}
                              />
                            </div>
                            <div className="flex1" style={{ marginTop: '6px', marginLeft: '0px' }}>
                              <button
                                style={{ marginLeft: '69px', border: 'none' }} id={index}
                                onClick={() => deleteRow(v, index)}>
                                <FaTimes className="fatimesclass" style={{ fontSize: '20px', color: 'red' }} />
                              </button>
                            </div>
                          </div>))

                      }
                      <div style={{ textAlign: "left", marginTop: '12px' }}>
                        <button className={editInfo ? "editbtn" : "NotEdit"} style={{ marginLeft: '30px' }}
                          disabled={!editInfo ? "disabled" : false}
                          name="popup"
                          onClick={addRow}
                        >
                          Add App
                        </button>
                      </div>
                    </div>
                  </Grid>) : null}
              </Grid>
            </TabPanel>
          </div>
        </div>
      ) : (
        // <div className="configDefinebtn" onClick={createDev}>
        //   {" "}
        //   + Create Development App Configuration and Define Entry Point
        // </div>

        !loading && !hasApp ?
          <div className="configDefinebtn" onClick={createDev}>
            + Create Development App Configuration and Define Entry Point
          </div> : null
      )}

      <Dialog open={deleteopen} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup" onClick={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
            <div className="warningIcon"><MdWarning /></div>
            <div className="warningText">WARNING !</div>

          </div>
          <div className="logoConfirmtext"> Are you sure you want to delete this #datafree App Version ?
            This cannot be undone.</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{ textAlign: 'center' }}>
            <button className="continueBtn" style={{ width: '43%' }} name="popup" onClick={handleClose} color="primary">
              Cancel
            </button>

            <button className="continueBtn" style={{ width: '43%', marginLeft: '25px' }} name="popup"
              onClick={deleteVersion}
              color="primary">
              Confirm Delete

            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* Error MESSAGE*/}
      <Dialog open={switchPop} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">

        <DialogContent className="diaContents">
          <div >{message ? message : null}</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" color="primary" onClick={() => setSwitchPop(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default SwitchDevelopment;
