import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import applicationIcon from "../../../assets/img/application-icon.png";
import ChannelService from "../../../api/channelService";
import { trackPromise } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import EmptyTable from "../../../Recomponent/EmptyTable";
import VMCTable from '../../../Recomponent/Table'
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function PublisherList() {
  const [search, setSearch] = useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [publisherDetails, setPublisherDetails] = useState();
  const [checked, setChecked] = useState(false);
  const [changedropdown, setChangedropdown] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [data, setData] = useState({
    searchStatus: "",
    searchPublisherId: "",
    searchPublisherName: "",
    searchAccountLevel: "",
    channelId: "",
  });
  const [addPublisher, setAddPublisher] = useState({
    company_type: "",
    companyname: "",
    customer_identifier: "",
    credit_limit: "",
    status: "",
    discount_price: "",
    manager_email: "",
    reg_company_name: "",
    reg_business_number: "",
    reg_address: "",
    reg_phone_number: "",
    option_create_user: "",
    users_name: "",
    email_address: "",
    owning_channel: "",
  });

  const [pubError, setPubError] = useState({
    company_type: false,
    companyname: false,
    customer_identifier: false,
    credit_limit: false,
    status: false,
    discount_price: false,
    manager_email: false,
    reg_company_name: false,
    reg_business_number: false,
    reg_address: false,
    reg_phone_number: false,
    users_name: false,
    email_address: false,
    owning_channel: false,
    nameMsg: "",
    managerEmail: "",
    emailMsg: "",
    phoneMsg: "",
    custidMsg: "",
    businessMsg: "",
    discountMsg: ""
  });
  let history = useHistory();

  const [tablecols] = useState([
    {
      colName: 'Publisher Id',
      colMap: 'companyId',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Publisher Name',
      colMap: 'name',
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Company Type',
      colMap: 'companyType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Contract Term',
      colMap: 'contractTerm',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Created',
      colMap: 'created',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    }

  ])

  const [scroll, setScroll] = useState("paper");
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleRouteLink = (companyType, companyId) => {
    history.push(`/channel/publisher/${companyId}/detail/view`);
  };
  const [creditLimit, setCreditLimit] = useState();

  const Channeldata = () => {
    try {
      trackPromise(
        ChannelService.channelUserRequest().then((res) => {
          data["channelId"] = res.data?.userDetails?.companyId?.companyId;
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [empty, setEmpty] = useState(false);
  const [errMsg, setErrMsg] = useState();
  async function publisherListdata() {
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setPublisherDetails({});
    data["channelId"] = localStorage.getItem("channelId")
    try {
      trackPromise(
        ChannelService.ChannelSearch(data).then((res) => {
          setPublisherDetails(res.data);
          if (res.status === 200 && res.data.publisherListResult.length > 0) {
            setChangedropdown(false);
          }
          else if (res.status === 200 && res.data.publisherListResult.length === 0) {
            setEmpty(true);
            setChangedropdown(false);
          }
          else {
            setChangedropdown(true);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  //add Publisher
  const [successopen, setsuccessOpen] = useState(false);
  const [clickSave, setClickSave] = useState(false);
  const [loading, setLoading] = useState(false);
  async function AddChannelPublisher() {

    const pubCount = ValidatePublisher(addPublisher);

    if (pubCount === 0) {
      if (checked) {
        addPublisher.option_create_user = "yes";
      }
      try {
        setClickSave(true);
        setLoading(true);
        trackPromise(
          ChannelService.AddChannelPublisher(addPublisher).then((res) => {

            if (res.data === "success") {
              handleClose();
              setsuccessOpen(true);
              setClickSave(false);
              setLoading(false);
            }

          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }

  }
  //creditLImit

  const manageListdata = () => {

    trackPromise(
      ChannelService.channelIndex().then((res) => {
        setCreditLimit(res.data.datafree_Data_Credit_Limit);
        addPublisher['owning_channel'] = res.data.channelId
      })
    );

  }

  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };
  const companyReg = /^.*[A-Za-z].*/i;
  const managerEmailReg = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
  const businessReg = /^[0-9A-Za-z\s+-]/i;
  const cusIdReg = /^[a-zA-Z0-9-]*$/i;
  const phoneReg = /^(?=.*[0-9])[+ 0-9]+$/i;
  const emailReg = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
  const discountReg = /^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$/;
  const addPublisherChange = (e) => {
    e.preventDefault();
    const newPublisher = { ...addPublisher };
    newPublisher[e.target.id] = e.target.value;
    if (e.target.id === "companyname") {
      if (e.target.value) {
        if (companyReg.test(e.target.value) === false) {
          setPubError({
            ...pubError,
            companyname: true,
            nameMsg: "Field must have at least 1 character a-z or A-Z"
          })
        }
        else {
          setPubError({
            ...pubError,
            companyname: false,
            nameMsg: ""
          })
        }
      }
      else {
        setPubError({
          ...pubError,
          companyname: true,
          nameMsg: "This field is required."
        })
      }
    }

    if (e.target.id === "manager_email") {
      if (e.target.value) {
        if (managerEmailReg.test(e.target.value) === false) {
          setPubError({
            ...pubError,
            manager_email: true,
            managerEmail: "Please enter a valid email address."
          })
        }
        else {
          setPubError({
            ...pubError,
            manager_email: false,
            managerEmail: ""
          })
        }
      }
      else {
        setPubError({
          ...pubError,
          manager_email: false,
          managerEmail: ""
        })
      }
    }

    if (e.target.id === "company_type") {
      if (e.target.value) {
        setPubError({
          ...pubError,
          company_type: false,
        })
      }
      else {
        setPubError({
          ...pubError,
          company_type: true,
        })
      }
    }

    if (e.target.id === "status") {
      if (e.target.value) {
        setPubError({
          ...pubError,
          status: false,
        })
      }
      else {
        setPubError({
          ...pubError,
          status: true,
        })
      }
    }

    if (e.target.id === "reg_phone_number") {
      if (e.target.value) {
        if (phoneReg.test(e.target.value) === false) {
          setPubError({
            ...pubError,
            reg_phone_number: true,
            phoneMsg: "Valid characters are '+' 0-9 and space"
          })
        }
        else {
          setPubError({
            ...pubError,
            reg_phone_number: false,
            phoneMsg: ""
          })
        }
      }
      else {
        setPubError({
          ...pubError,
          reg_phone_number: false,
          phoneMsg: ""
        })
      }
    }

    if (e.target.id === "reg_business_number") {
      if (e.target.value) {
        if (businessReg.test(e.target.value) === false) {
          setPubError({
            ...pubError,
            reg_business_number: true,
            businessMsg: "Only alphanumeric characters or underscore and the first character must be a letter"
          })
        }
        else {
          setPubError({
            ...pubError,
            reg_business_number: false,
            businessMsg: ""
          })
        }
      }
      else {
        setPubError({
          ...pubError,
          reg_business_number: false,
          businessMsg: ""
        })
      }
    }

    if (e.target.id === "customer_identifier") {
      if (e.target.value) {
        if (cusIdReg.test(e.target.value) === false) {
          setPubError({
            ...pubError,
            customer_identifier: true,
            custidMsg: "Only alphanumeric characters and hyphen is allowed"
          })
        }
        else {
          setPubError({
            ...pubError,
            customer_identifier: false,
            custidMsg: ""
          })
        }
      }
      else {
        setPubError({
          ...pubError,
          customer_identifier: false,
          custidMsg: ""
        })
      }
    }

    if (e.target.id === "users_name") {
      if (checked) {
        if (e.target.value) {
          setPubError({
            ...pubError,
            users_name: false,
          });
        }
        else {
          setPubError({
            ...pubError,
            users_name: true,
          });
        }
      }

    }

    if (e.target.id === "email_address") {
      if (checked) {
        if (e.target.value) {
          EmailCheck(e.target.value);
        } else {
          setPubError({
            ...pubError,
            email_address: true,
            emailMsg: "This field is required",
          });
        }
      }
      else {
        setPubError({
          ...pubError,
          email_address: false,
          emailMsg: "",
        });
      }
    }
    if (e.target.id === "discount_price") {
      if (e.target.value) {
        if (discountReg.test(e.target.value) === false) {
          setPubError({
            ...pubError,
            discount_price: true,
            discountMsg: "Please enter a value between 0 and 99.9"
          })
        }
        else {
          setPubError({
            ...pubError,
            discount_price: false,
            discountMsg: ""
          })
        }
      }
      else {
        setPubError({
          ...pubError,
          discount_price: false,
          discountMsg: ""
        })
      }
    }
    setAddPublisher(newPublisher);
  };

  const [resValue, setResValue] = useState();
  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      ChannelService.emailChannelCheck({ email_address: value })
        .then((res) => {
          setResValue(res.data);
          if (res.data === false) {
            setPubError({
              ...pubError,
              email_address: true,
              emailMsg: "This​ email ​already​ used",
            });
          } else if (emailReg.test(value) === false) {
            setPubError({
              ...pubError,
              email_address: true,
              emailMsg: "Please enter a valid email address.",
            });
          } else {
            setPubError({
              ...pubError,
              email_address: false,
              emailMsg: ""
            });
          }
        })
        .catch((error) => {
          setErrMsg("Something went wrong. Please reload the page!");
          seterrorOpen(true);
        })
    );
  }
  const typeRef = React.useRef();
  const cmpyNameRef = React.useRef();
  const statusRef = React.useRef();
  const idRef = React.useRef();

  const ValidatePublisher = (addPublisher) => {
    let count = 0;
    const nwerror = { ...pubError };
    if (addPublisher.companyname !== "") {
      if (companyReg.test(addPublisher.companyname) === false) {

        nwerror.companyname = true;
        nwerror.nameMsg = "Field must have at least 1 character a-z or A-Z";
        count++;
        cmpyNameRef.current.focus();
      }
      else {
        nwerror.companyname = false;
        nwerror.nameMsg = "";
      }

    }
    else {
      nwerror.companyname = true;
      nwerror.nameMsg = "This field is required.";
      count++;
      cmpyNameRef.current.focus();
    }


    if (addPublisher.manager_email !== "") {
      if (managerEmailReg.test(addPublisher.manager_email) === false) {

        nwerror.manager_email = true;
        nwerror.managerEmail = "Please enter a valid email address.";
        count++;
      }
      else {
        nwerror.manager_email = false;
        nwerror.managerEmail = "";
      }
    }
    else {
      nwerror.manager_email = false;
      nwerror.managerEmail = "";
    }

    if (addPublisher.company_type !== "") {

      nwerror.company_type = false;
    }
    else {
      nwerror.company_type = true;
      count++;
      typeRef.current.focus();
    }


    if (addPublisher.status !== "") {
      nwerror.status = false;
    }
    else {
      nwerror.status = true;
      count++;
      statusRef.current.focus();
    }



    if (addPublisher.reg_phone_number !== "") {

      if (phoneReg.test(addPublisher.reg_phone_number) === false) {
        nwerror.reg_phone_number = true;
        nwerror.phoneMsg = "Valid characters are '+' 0-9 and space";
        count++;
      }
      else {
        nwerror.reg_phone_number = false;
        nwerror.phoneMsg = "";
      }
    }
    else {
      nwerror.reg_phone_number = false;
      nwerror.phoneMsg = "";
    }


    if (addPublisher.reg_business_number !== "") {

      if (businessReg.test(addPublisher.reg_business_number) === false) {
        nwerror.reg_business_number = true;
        nwerror.businessMsg = "Only alphanumeric characters or underscore and the first character must be a letter";
        count++;
      }
      else {
        nwerror.reg_business_number = false;
        nwerror.businessMsg = "";
      }
    }
    else {
      nwerror.reg_business_number = false;
      nwerror.businessMsg = "";
    }

    if (addPublisher.customer_identifier !== "") {
      if (cusIdReg.test(addPublisher.customer_identifier) === false) {
        nwerror.customer_identifier = true;
        nwerror.custidMsg = "Only alphanumeric characters and hyphen is allowed";
        count++;
        idRef.current.focus();
      }
      else {
        nwerror.customer_identifier = false;
        nwerror.custidMsg = "";
      }
    }
    else {
      nwerror.customer_identifier = false;
      nwerror.custidMsg = "";
    }

    if (addPublisher.discount_price !== "") {
      if (discountReg.test(addPublisher.discount_price) === false) {
        nwerror.discount_price = true;
        nwerror.discountMsg = "Please enter a value between 0 and 99.9";
        count++;
      }
      else {
        nwerror.discount_price = false;
        nwerror.discountMsg = "";
      }
    }
    else {
      nwerror.discount_price = false;
      nwerror.discountMsg = "";
    }

    if (checked) {
      if (addPublisher.email_address !== "") {

        if (emailReg.test(addPublisher.email_address) === false) {
          nwerror.email_address = true;
          nwerror.emailMsg = "Enter a valid email address";
          count++;
        }
        else if (resValue === false) {
          nwerror.email_address = true;
          nwerror.emailMsg = "This email already exits";
          count++;
        }
        else {
          nwerror.email_address = false;
          nwerror.emailMsg = "";
        }
      }
      else {
        nwerror.email_address = true;
        nwerror.emailMsg = "This field is required";
        count++;
      }

      if (addPublisher.users_name !== "") {

        nwerror.users_name = false;
      }
      else {
        nwerror.users_name = true;
        count++;
      }
    }
    setPubError(nwerror);
    return count;
  };
  useEffect(() => {
    document.title = "Datafree Portal";
    Channeldata();
    manageListdata();
  }, []);

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Publisher List - Channel View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-3">
                  Status
                </td>
                <td className="col-md-2">
                  Publisher Id
                </td>
                <td className="col-md-2">
                  Publisher Name
                </td>
                <td className="col-md-3">
                  Account Level
                </td>
                <td className="col-md-2">

                </td>

              </tr>
              <tr>
                <td className="col-md-2">
                  <select
                    type="text"
                    className="form-control form-select"
                    onChange={(e) => lifecycleChange(e)}
                    id="searchStatus"
                    name="searchStatus"
                  >
                    <option value=""></option>
                    <option value="New">New</option>
                    <option value="Approved">Approved</option>
                    <option value="NoAgreement">NoAgreement</option>
                    <option value="Suspended">Suspended</option>
                    <option value="Archived">Archived</option>
                  </select>
                </td>
                <td className="col-md-2">
                  <input
                    type="text"
                    className="form-control"
                   
                    id="searchPublisherId"
                    name="searchPublisherId"
                    onChange={(e) => lifecycleChange(e)}
                  ></input>

                </td>
                <td className="col-md-1">
                  <input
                    type="text"
                    className="form-control"
                 
                    id="searchPublisherName"
                    name="searchPublisherName"
                    onChange={(e) => lifecycleChange(e)}
                  ></input>
                </td>
                <td className="col-md-3">
                  <select
                    type="text"
                    className="form-control form-select"
                  
                    onChange={(e) => lifecycleChange(e)}
                    id="searchAccountLevel"
                    name="searchAccountLevel"
                  >
                    <option value=""></option>
                    <option value="Standard">Standard</option>
                  </select>
                </td>
                <td className="col-md-2">
                  <button
                    className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}

                    onClick={publisherListdata}
                  >
                    Search
                  </button>
                </td>

              </tr>
            </tbody>
          </table>


        </div>
        <div><hr className="applications-list-hr"></hr></div>
        <div className="createButtonDiv">
          <button
            disabled={changedropdown ? "disabled" : false}
            className={changedropdown ? "licbtnafterclick" : "licbtn"}
            title="Click here to create new Publisher"
            onClick={() => handleClickOpen("body")}>
            + Create New Publisher
          </button>
        </div>

        {search ? (
          <div>
            {publisherDetails?.publisherListResult?.length > 0 ? (
              <VMCTable key={publisherDetails?.publisherListResult} data={publisherDetails?.publisherListResult} col={tablecols} navigateFunction={handleRouteLink} tableName="Channel Publisher LIst" />
            ) :
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Publishers Found" col={tablecols} tableName="Applications List" /> : null}
              </div>

            }
          </div>
        ) : (
          null
        )}
      </div>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Create Publisher - Channel View
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Type</div>
              <select
                type="text"
                id="company_type"
                name="company_type"
                style={{ width: '101%', height: '34px' }}
                label="name"
                onChange={(e) => addPublisherChange(e)}
                className={!pubError.company_type ? "dialoginput" : "errdialoginput"}
                fullwidth="true"
                ref={typeRef}
              >
                <option value=""></option>
                <option value="CUSTOMER">CUSTOMER</option>
                <option value="RESELLER">RESELLER</option>
              </select>
              {pubError.company_type ? <span className="errorClass">This field is required.</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Publisher Name</div>
              <input
                type="text"
                id="companyname"
                name="companyname"
                label="email"
                className={!pubError.companyname ? "dialoginput" : "errdialoginput"}
                onChange={(e) => addPublisherChange(e)}
                fullwidth="true"
                ref={cmpyNameRef}
              />{pubError.companyname ? <span className="errorClass">{pubError.nameMsg}</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Your Customer Identifier</div>
              <input
                type="text"
                id="customer_identifier"
                name="customer_identifier"
                label="email"
                className={!pubError.customer_identifier ? "dialoginput" : "errdialoginput"}
                onChange={(e) => addPublisherChange(e)}
                fullwidth="true"
                ref={idRef}
              />
              {pubError.customer_identifier ? <span className="errorClass">{pubError.custidMsg}</span> : null}
            </Grid>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">
                #datafree Data Credit Limit
              </div>
              <select
                type="text"
                id="credit_limit"
                name="credit_limit"
                style={{ width: '101%', height: '34px' }}
                label="email"
                className="dialoginput"
                onChange={(e) => addPublisherChange(e)}
                fullwidth="true"
              >
                <option></option>
                {creditLimit && creditLimit.length > 0
                  ? creditLimit.map((post) => (
                    <option
                      key={post.creditLimitId}
                      value={post.creditLimitId}
                    >
                      {post.creditLimit}
                    </option>
                  ))
                  : null}
              </select>
            </Grid>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">Status</div>
              <select
                type="text"
                id="status"
                name="status"
                style={{ width: '101%', height: '34px' }}
                label="email"
                className={!pubError.status ? "dialoginput" : "errdialoginput"}
                onChange={(e) => addPublisherChange(e)}
                fullwidth="true"
                ref={statusRef}
              >
                {" "}
                <option value=""></option>
                <option value="New">New</option>
                <option value="NoAgreement">NoAgreement</option>
              </select>
              {pubError.status ? <span className="errorClass">This field is required</span> : null}
            </Grid>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">Price Discount %</div>
              <input
                type="text"
                id="discount_price"
                name="discount_price"
                label="email"
                className={!pubError.discount_price ? "dialoginput" : "errdialoginput"}
                onChange={(e) => addPublisherChange(e)}
                fullwidth="true"
                style={{ width: '95%' }}
              />{pubError.discount_price ? <span className="errorClass">{pubError.discountMsg}</span> : null}
            </Grid>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">Account Manager Email</div>
              <input
                type="text"
                id="manager_email"
                name="manager_email"
                label="email"
                style={{ width: '95%' }}
                className={!pubError.manager_email ? "dialoginput" : "errdialoginput"}
                onChange={(e) => addPublisherChange(e)}
                fullwidth="true"
              />
              {pubError.manager_email ? <span className="errorClass">{pubError.managerEmail}</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Registered Company Name</div>
              <input
                type="text"
                id="reg_company_name"
                name="reg_company_name"
                label="email"
                onChange={(e) => addPublisherChange(e)}
                className="dialoginput"
                fullwidth="true"
              />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">
                Registered Business Number (if appropriate)
              </div>
              <input
                type="text"
                id="reg_business_number"
                name="reg_business_number"
                onChange={(e) => addPublisherChange(e)}
                label="email"
                className={!pubError.reg_business_number ? "dialoginput" : "errdialoginput"}
                fullwidth="true"
              />
              {pubError.reg_business_number ? <span className="errorClass">{pubError.businessMsg}</span> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Registered Address</div>
              <input
                type="text"
                id="reg_address"
                name="reg_address"
                label="email"
                className="dialoginput"
                onChange={(e) => addPublisherChange(e)}
                fullwidth="true"
              />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Phone Number</div>
              <input
                type="text"
                id="reg_phone_number"
                name="reg_phone_number"
                onChange={(e) => addPublisherChange(e)}
                label="email"
                className={!pubError.reg_phone_number ? "dialoginput" : "errdialoginput"}
                fullwidth="true"
              />
              {pubError.reg_phone_number ? <span className="errorClass">{pubError.phoneMsg}</span> : null}
            </Grid>
            <div>
              <input
                type="checkbox"
                name="option_create_user"
                id="option_create_user"
                onChange={() => setChecked(!checked)}
                checked={checked}
              />
              <label for="websiteName" className="cmpdialoginputtext">
                Optionally Create User ?
              </label>
            </div>
            {checked ? (
              <>
                <Grid item xs={12}>
                  <div className="cmpdialoginputtext">User’s Name</div>
                  <input
                    type="text"
                    id="users_name"
                    name="users_name"
                    label="users_name"

                    className={!pubError.users_name ? "dialoginput" : "errdialoginput"}
                    onChange={(e) => addPublisherChange(e)}
                    fullwidth="true"
                  />
                  {pubError.users_name ? <span className="errorClass">This field is required</span> : null}

                </Grid>
                <Grid item xs={12}>
                  <div className="cmpdialoginputtext">
                    Email Address (username)
                  </div>
                  <input
                    type="text"
                    id="email_address"
                    onChange={(e) => addPublisherChange(e)}
                    name="email_address"
                    label="email"
                    className={!pubError.email_address ? "dialoginput" : "errdialoginput"}
                    fullwidth="true"
                  />
                  {pubError.email_address ? <span className="errorClass">{pubError.emailMsg}</span> : null}
                </Grid>
              </>
            ) : (
              <div></div>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <button
              className="inviteButton"
              disabled={clickSave ? "disabled" : false}
              onClick={AddChannelPublisher}
              color="primary"
            >
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successopen}
        autoHideDuration={1500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          New Publisher Created Successfully!
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success">
          {errMsg ? errMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default PublisherList;
