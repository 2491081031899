import React,{useState, useEffect} from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
  Grid,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AccountService from "../../../api/accountService";
import accountIcon from "../../../assets/img/account-icon-black.png";
import moment from "moment";
import alpha from "../../../assets/img/blank1.png";
import Loader from "react-loading";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",
  },
}));
function AccountManagerAccount() {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [erroropen, seterrorOpen] = useState(false);
  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  
  const [accountDetails, setAccountDetails] = useState();
  const [userDetails, setUserDetails] = useState();
  const [message, setMessage] = useState();
  let API_URL = process.env.REAX;
  const [loadervisible, setLoaderVisible] = useState(false);
  
  //application Index
  const accountView=()=>{
        setLoaderVisible(true);
        AccountService.accountView().then((res) => {
          setAccountDetails(res.data);
          setUserDetails(res.data?.publisherDetail);
          setLoaderVisible(false);
        }).catch((error)=>{
          setMessage("Something went wrong. Please reload the page.");
          seterrorOpen(true);
        })
  }

  useEffect(() => {
    document.title = "Datafree Portal";
    setLoaderVisible(true);
    accountView();
  }, []);
  
  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow" style={{marginTop: '20px', marginBottom: '20px'}}>
          <div>
            <img src={accountIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText" >
            Accounts Details - Account Manager View
          </div>
        </div>
        <AntTabs
          value={value}
          className="tabViewMenu"
          onChange={handleChange}
          aria-label="ant example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <AntTab
            label="Company"
            title="Click here to view Company Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            title="Click here to view Registered Business Details Tab"
            label="Registered Business Details"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          /> 
          <AntTab
            label="Contacts"
            title="Click here to view Contacts Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            label="Users"
            title="Click here to view Users Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
         
        </AntTabs>
        <Typography className={classes.padding} />
        <TabPanel value={value} index={0}>
        {
                loadervisible &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '-100px',
                    right: '0px',
                    margin: 'auto',
                    position: 'absolute',
                    left: '15px',
                    bottom: '0px',
                    zIndex: '3000',
                    backgroundColor: "#ffffff",
                    paddingTop: 'px',
                    paddingBottom: '8px',
                    borderRadius: '5px',
                    flexDirection: 'column',
                    width: '70px',
                    height: '60px'
                }} >
                     <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
                </div>
            }
          <div className="companyForm">
            <Grid container spacing={1}>
              <Grid item  xs={12} sm={4} md={4}>
                <div className="companyLabel">Channel Name</div>
                <div>
                    <input
                      type="text"
                      className="form-control"
                      disabled="disabled"
                      value={userDetails?.name}
                      style={{cursor:'not-allowed'}}
                    />
                </div>
              </Grid>
              <Grid item  xs={12} sm={1} md={1}></Grid>
              <Grid item  xs={12} sm={4} md={4}>
              {accountDetails?.publisherDetail.logoFile !== null ? 
                <div className="fileIcon">
                  <img
                       src={
                        accountDetails ?
                        API_URL + accountDetails?.logoFile + accountDetails?.publisherDetail.logoFile
                        : alpha
                          }
                        alt=""
                        className="imgStyle"
                      > 
                  </img> 
                </div>: ""}
              </Grid>
              <Grid item  xs={12} sm={4} md={4}>
                <div className="companyLabel">Credit Controller Email</div>
                <div>
                    <input
                      type="text"
                      className="form-control"
                      value={userDetails?.creditControlEmail}
                      style={{cursor:'not-allowed'}}
                      disabled
                    />
                </div>
              </Grid>
              <Grid item xs={12} sm={1} md={1}></Grid>
              <Grid item  xs={12} sm={4} md={4}>
                <div className="companyLabel">Status</div>
                <div>
                    <input
                      type="text"
                      className="form-control"
                      name="Publishername"
                      disabled="disabled"
                      style={{cursor:'not-allowed'}}
                      value={userDetails?.status}
                    />
                </div>
              </Grid>
              <Grid item  xs={12} sm={4} md={4}>
                <div className="companyLabel">Company Type</div>
                <div> 
                    <input
                      type="text"
                      className="form-control"
                      name="Publishername"
                      value={userDetails?.companyType}
                      style={{cursor:'not-allowed'}}
                      disabled="disabled"
                    />
                </div>
              </Grid>
              <Grid item  xs={12} sm={1} md={1}></Grid>
              <Grid item  xs={12} sm={4} md={4}>
                <div className="companyLabel">Created</div>
                <div>
                    <input
                      type="text"
                      className="form-control"
                      name="Publishername"
                      disabled="disabled"
                      style={{cursor:'not-allowed'}}
                      value={moment(userDetails?.created).format("YYYY-MM-DD") + " GMT"}
                    /> 
                </div>
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        
        <TabPanel value={value} index={1}>
          <div className="companyForm">
            <Grid container spacing={1}>
             
              <Grid item  xs={12} sm={4} md={4}>
                <div className="companyLabel">Registered Company Name</div>
                <div>
                    <input
                      type="text"
                      className="form-control"
                      value={userDetails?.bizName}
                      disabled="disabled"
                      style={{cursor:'not-allowed'}}
                    />
                </div>
              </Grid>
              <Grid item  xs={12} sm={1} md={1}></Grid>
              <Grid item xs={4}>
                <div className="companyLabel">Registered Business Number (if applicable)</div>
                <div>
                  <input
                      type="text"
                      className="form-control"
                      name="Publishername"
                      disabled="disabled"
                      value={userDetails?.bizRego}
                    />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Registered Business Address</div>
                <div>
                    <input
                      type="text"
                      className="form-control"
                      name="Publishername"
                      value={userDetails?.bizAddress}
                      style={{cursor:'not-allowed'}}
                      disabled="disabled"
                    />
                </div>
              </Grid>
              <Grid item xs={12} sm={1} md={1}></Grid>
              <Grid item  xs={12} sm={4} md={4}>
                <div className="companyLabel">Company Phone Number</div>
                <div>
                    <input
                      type="text"
                      className="form-control"
                      name="Publishername"
                      disabled="disabled"
                      value={userDetails?.bizPhone}
                    />
                </div>
              </Grid>
            </Grid>
          </div>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <TableContainer>
            <Table className="accouttablestyle" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">Name</TableCell>
                  <TableCell className="tableHead">Email</TableCell>
                  <TableCell className="tableHead">Mobile</TableCell>
                  <TableCell className="tableHead">Position</TableCell>
                  <TableCell className="tableHead">Pirmary Commercial</TableCell>
                  <TableCell className="tableHead">Primary Billing</TableCell>
                  <TableCell className="tableHead">Primary Technical</TableCell>
                 
                </TableRow>
              </TableHead>
              <TableBody disabled>
              {accountDetails?.contactDetails.map((item,index) =>(
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                    >{item?.contactName}
                    </TableCell>
                    <TableCell>{item?.email}</TableCell>
                    <TableCell>{item?.mobile}</TableCell>
                    <TableCell>{item?.position}</TableCell>
                    <TableCell><input type="checkbox" name="checkbox" 
                    className="checkboxForm" disabled checked={item?.primCommercial}/></TableCell>
                    <TableCell><input type="checkbox" name="checkbox" 
                    className="checkboxForm"
                    disabled checked={item?.primBilling}/></TableCell>
                    <TableCell><input type="checkbox" name="checkbox"
                    className="checkboxForm" 
                    disabled checked={item?.primTechnical}/></TableCell>
                  
                  </TableRow>
                  ))}  
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <TableContainer>
            <Table className="accouttablestyle" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">User’s Name</TableCell>
                  <TableCell className="tableHead">Email Address</TableCell>
                  <TableCell className="tableHead">Role</TableCell>
                  <TableCell className="tableHead">Type</TableCell>
                  <TableCell className="tableHead">Verified</TableCell>
                  <TableCell className="tableHead">Status</TableCell>
                  <TableCell className="tableHead">Created</TableCell>
                 
                </TableRow>
              </TableHead>
             <TableBody>
             {accountDetails?.usersListDetail.map((items,index) =>(
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                    >{items?.userIdObj?.name}
                    </TableCell>
                    <TableCell>{items?.userIdObj?.email}</TableCell>
                    <TableCell>{items?.role}</TableCell>
                    <TableCell>{items?.userIdObj?.userType}</TableCell>
                    <TableCell>{items?.userIdObj?.verified}</TableCell>
                    <TableCell>{items?.status}</TableCell>
                    <TableCell>{moment(items?.userIdObj?.created).format("Do MMM YYYY") + " GMT"}</TableCell>
                    
                  </TableRow>
             ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </div>
      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
         {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default AccountManagerAccount;
