import React,{useContext} from "react";
import { Route, Switch,Redirect} from "react-router-dom";
import Card from "./userPages/datafree/datafreeCard";
import ReportCard  from "./userPages/reporting/reporting";
import Utilities  from "./userPages/utilities/utilities";
import Account  from "./userPages/account/account";
import GettingStarted from "./userPages/gettingStarted/gettingStarted";
import RegForm from "./userPages/gettingStarted/regForm/registerFomGettingStart";
import Application from "./userPages/datafree/application";
import UserDetails from "./userPages/userDetails";
import NotificationDetails from "../channel/channelPages/notificationDetails"
import ReportSchedule  from "./userPages/reporting/reportSchedule";
import ClientList  from "./userPages/clientList";
import {
  userContext
 } from "../../api/context"

export default function User(props) {
  const userContextVal = useContext(userContext)
  return (
    <div>
      <Switch>
      {userContextVal?.token !== null && userContextVal?.token !== undefined ?
      <>
        <Route exact path="/user/datafree" component={Card} />
        <Route exact path="/user/application/:paramsId/details" component={Application} />
        <Route exact path="/user/reporting" component={ReportCard}/>
        <Route exact path="/user/utilities" component={Utilities}/>
        <Route exact path="/user/account" component={Account}/>
        <Route exact path="/user/gettingStarted" component={GettingStarted}/>
        <Route exact path="/user/register" component={RegForm}/>
        <Route exact path="/user/details/view" component={UserDetails}/>
        <Route exact path="/user/notification/details" component={NotificationDetails}/>
        <Route exact path="/user/scheduledreport/index" component={ReportSchedule}/>
        <Route exact path="/user/reseller/client/list" component={ClientList}/>
        </>
      :<Redirect  to="/"></Redirect>}
      </Switch> 
    </div>
  );
}
