import React, { useState } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import DataTable from "react-data-table-component";
import { FaCaretDown } from "react-icons/fa";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});



const customStyles = {
  headCells: {
    style: {
      border:"1px solid #e7ecf1",
      borderBottom: "1px solid #111"
    },
  },
  cells: {
    style: {
      border:"1px solid #e7ecf1"
    },
  },
};


function MaintainMoyaUserStatics() {
  const [search, setSearch] = useState(false);
  const classes = useStyles();
  const [scroll, setScroll] = useState("paper");
  const [searchDetails, setSearchDetails] = useState();
  const[openUser,setOpenUser]=useState(false);
  const [changedropdown, setChangedropdown] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const[loaderVisible,setLoaderVisible] = useState(false);
  const [data] = useState({  
      period_start : "", 
      period_end : ""    
  });

  const tablecols = [
    {
      name: "Date",
      selector: (row) => row.usageDate,
      sortable: true,
      cell:(props) => {
        
        const custom_date = props.usageDate.slice(0,10);
        const custom_dates = moment(custom_date).format("DD/MM/YYYY");
            return <span >{custom_dates}</span>
        }
    },
    {
      name: "DAUs",
      selector: (row) => row.dau,
      sortable: true,
    },
    {
      name: "MAUs",
      selector: (row) => row.mau,
      sortable: true,
      
    },
    
    {
      name: "New Registations",
      selector: (row) => row.newRegistration,
      sortable: true,
     
    },
    
    {
      name: <button className="deleteRow" onClick={() => setConfirmDelete(true)}>Delete</button>,
      cell:(props) => {
        return <input type="checkbox" className="checkbox" 
        onClick={(e)=>handleSelectedRow(props.usageDate)}
        
        />},
     
    }
  ]; 

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const[checkBoxSelect,setcheckBoxSelect] = useState([]);
  
  const handleSelectedRow = (date) => {
   const dateNew = date.slice(0,10);
   const dateNews = moment(dateNew).format("DD/MM/YYYY");
   const valsnws = checkBoxSelect.find((v)=>v.myTextEditBox === dateNews);
   
   if(valsnws !== undefined){
    let indexss = checkBoxSelect.map(x => { 
      return x
    }).indexOf(valsnws);
    checkBoxSelect.splice(indexss,1);
   }
   else{
    checkBoxSelect.push({myTextEditBox: dateNews});
   }
    setcheckBoxSelect(checkBoxSelect); 
  };

   //delete Moya
   async function MoyaDelete(){
   try {
     trackPromise(
       AdminService.deleteMoya(checkBoxSelect).then((res) => {
        if (res.status === 200) {
          checkBoxSelect.splice(0,checkBoxSelect.length);
          handleClose();
          MoyaInvoice();
          setSearch(false);
        }
        setcheckBoxSelect([]);
       })
     );
   } catch (err) {
    setMessage("Something went wrong. Please reload the page!")
    seterrorOpen(true);
   }
 }

  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
 const handleClose =()=>{
   setOpenUser(false);
   MoyaInvoice();
   setConfirmDelete(false);
   setcheckBoxSelect([]);
   checkBoxSelect.splice(0,checkBoxSelect.length);
   setMnoDetail(
    {
      taskList:[{index:Math.random(),date: "", dau: "",mau: "",new_registration: "",
       }]
    }
 
  )
  setmnoError((prevState)=>(
    [{ 
      
       date:false,
       yearExist:true,
       dau:false,
       mau:false,
       new_registration:false,
       mnoYearExist:"",
       mnoRequired:"",
       mauMsg:"",
       regMsg:"",
       mnoIndex:""
     }]
    ))
    setYearCheck({
      yearExist:false
    })
 }
  const handleClickOpen =()=>{
    setScroll('body');
    setOpenUser(true);
  }
  const handleStart = (date) => {
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setStartDate(date);
    setEndDate(null);
    if(date){
      data['period_start']=moment(date).format("DD/MM/YYYY");
    } 
  };
  
  const handleEnd = (date) => {
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEndDate(date);
    if(date){
      data['period_end']=moment(date).format("DD/MM/YYYY");   
    }
  };
  //managed By List
  const [empty, setEmpty] = useState(false);
  const [message, setMessage] = useState();
  const MoyaInvoice = () =>{
    setLoaderVisible(true);
    setSearch(true);
    setEmpty(false);
    setSearchDetails({});
    try {
       AdminService.moyaInvoiceSearch(data).then((res) => {
        setLoaderVisible(false);
        setSearchDetails(res.data);
        if (res.status === 200 && res.data.length > 0) {
          setEmpty(false);
        } else if (res.status === 200 && (res.data.length === 0||res.data === [])) {
          setEmpty(true);
        } else {
          setEmpty(false);
        }
      })
    } catch (err) {
      setLoaderVisible(false);
      setMessage("Something went wrong. Please reload the page!")
      seterrorOpen(true);
    }
 }
 

  const addRow = e => {
    e.preventDefault();
    setMnoDetail((prevState)=>({
      taskList:[...prevState.taskList,{index:Math.random(),
      date: "",
      dau: "",
      mau: "",
      new_registration: ""}]
    }))
    
   setmnoError((prevState)=>(
   [...prevState,{ 
     
      date:false,
      dau: false,
      mau: false,
      new_registration:false,
      mnoYearExist:"",
      mnoRequired:"",
      mauMsg:"",
      regMsg:"",
      mnoIndex:""
    }]
   ))
   setselectedDateFormat([...selectedDateFormat,{date:""}])
  };

  const deleteRow = (record,date) =>{
  
    setMnoDetail({taskList:mnoDetail.taskList.filter(r => r !== record)
    });
    //setselectedDateFormat({date:selectedDateFormat.filter(r=>r!== date)})
  }
  
  const[mnoDetail,setMnoDetail] = useState(
    {
      taskList:[{index:Math.random(),
      date: "",
      dau: "",
      mau: "",
      new_registration: ""}]
    }
 
  )

  const[selectedDateFormat,setselectedDateFormat]= useState([{
    date:""
  }]);
  const handlemoyaDate =(date,idx)=>{
 
    setEndDate(null);
    let taskList= [...mnoDetail.taskList];
    let newDate = [...selectedDateFormat];
    newDate[idx]['date']= date;
  
    taskList[idx]['date']= moment(date).format("DD/MM/YYYY");
    if(idx === 0 && date !== ""){
     
      if(date){
       
        mnoCheckExistData()
      }else{
       setYearCheck({
         ...yearCheck,
        yearExist:true
       })
       checkError();
      }
    
    }else{
      checkError();
    }
  }
  const mnoyearChange = (e)=>{

    if(["date", "dau","mau","new_registration",
    ].includes(e.target.name)){
      let taskList= [...mnoDetail.taskList]
      taskList[e.target.dataset.id][e.target.name]= e.target.value
      if(e.target.dataset.id === "0" && e.target.name === "date"){
        if(e.target.value){
          mnoCheckExistData()
          
        }else{
         setYearCheck({
           ...yearCheck,
          yearExist:true
         })
         checkError();
        }
      
      }else{
        checkError();
      }
    
       }
    else{
      setMnoDetail({[e.target.name]:e.target.value})
    }
   
  }

  const checkError =()=>{
   
  for(let i=0;i<=mnoDetail.taskList.length;i++){
     setmnoError(mnoError.filter((newError,index)=>{
      if(index === i){
        newError.date = mnoDetail.taskList[i]?.date !== "" ? false : true;
      

       if(mnoDetail.taskList[i]?.dau === ""){
        newError.dau = true;
       }
       else{
          newError.dau = false;
       }

       if(mnoDetail.taskList[i]?.mau === ""){
        newError.mau = true;
        newError.mauMsg = "This field is required";
       }
       else{
         if(parseInt(mnoDetail.taskList[i]?.mau) < parseInt(mnoDetail.taskList[i]?.dau)){
          newError.mau = true;
          newError.mauMsg = "*MAU - must be greater or equal to DAU"
         }
         else{
          newError.mau = false;
          newError.mauMsg = "";
         }
       }

       if(mnoDetail.taskList[i]?.new_registration === ""){
        newError.new_registration = true;
        newError.regMsg = "This field is required"
       }
       else{
         if(parseInt(mnoDetail.taskList[i]?.new_registration) <= parseInt(mnoDetail.taskList[i]?.mau)){
          newError.new_registration = false;
          newError.regMsg = ""
         }
         else{
          newError.new_registration = true;
          newError.regMsg = "*NewRegistrations - must be smaller or equal to DAU"
         }
       }
       
      }
      return newError
    }))

   
  }
  }

  const[mnoError,setmnoError] = useState([{
    index:Math.random(),
    date:false,
    yearExist:true,
    dau:false,
    mau:false,
    new_registration:false,
    mauMsg:"",
    regMsg:"",
    mnoYearExist:"",
    mnoRequired:"",
    mnoIndex:""
  }])
  const[yearCheck,setYearCheck] = useState({
    yearExist:false,
    mnoYearExist:""
  })
      const mnoCheckExistData =()=>{
  
      AdminService.moyaCheckExist(mnoDetail).then((res) => {
       
        if (res.data === 1) {
          setYearCheck({
            ...yearCheck,
            yearExist:true,
            mnoYearExist:"The value already exists"
          })
        }
        else if (res.data === 2) {
          setYearCheck({
            ...yearCheck,
            yearExist:true,
            mnoYearExist:"The value must be unique"
          })
        }
        else{
          setYearCheck({
            ...yearCheck,
          yearExist:false,
        
        })
        checkError();

        }
      }).catch((error)=>{
        setLoading(false);
        setMessage("Something went wrong. Please reload the page!")
        seterrorOpen(true);

      }) 
      
        }
    //const [mnoMessage, setMnoMessage] = useState();
    const [toastMsg, setToastMsg] = useState();
    const [saveClick, setSaveClick] = useState(false);
    const [loading, setLoading] = useState(false);
     const mnoAdd =()=>{
     
      for(var i=0;i< mnoDetail.taskList.length;i++)
      {
              if(mnoDetail.taskList[i].date === '' ||mnoDetail.taskList[i].dau === ''
              || mnoDetail.taskList[i].mau === '' ||  mnoDetail.taskList[i].new_registration === ''
              )
              {
             checkError();
              }
              else{
               
                if(i === mnoDetail.taskList.length-1){
                  mnoCheckExistData();
             
                  if(yearCheck.yearExist === false){
                    setSaveClick(true);
                    setLoading(true);
                    AdminService.moyaAdd(mnoDetail).then((res) => {
                      if (res.status === 200) {
                        setToastMsg("Mno Details Added Successfully!");
                        setLoading(false);
                        setsuccessOpen(true);
                        //setMnoMessage(res?.data?.monoDataInvoice?.message);
                        setOpenUser(false);
                        setMnoDetail(
                          {
                            taskList:[{index:Math.random(),date: "", dau: "",mau: "",new_registration: "",
                             }]
                          });
                          MoyaInvoice();
                      }
                      setSaveClick(false);
                    })
                    .catch((error)=>{
                        setLoading(false);
                        setMessage("Something went wrong. Please reload the page!")
                        seterrorOpen(true);
                    })
                  }
                  
                  
                }
                else{
                  checkError()
                }
              }
            }
      
             
                    
      }
         


  // useEffect(() => {
  //   MoyaInvoice();
  // }, []);

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Maintain Moya User Statistics</div>
        </div>
        <div className="NavBoxRowFilter">
          <Grid container >
            
              <div className="NavBoxRowFilterLabel"  style={{paddingTop:"8px",paddingRight:"6px"}}>Period Start</div>
          
            <Grid item xs={12} sm={3} md={3}>
              <div>
                 <DatePicker
                  id="period_start"
                  name="period_start"
                  minDate={moment().startOf('month')}
                  selected={startDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={handleStart}
                  className="NavBoxRowFilterMno auto"
                  />
                {/* <input type="text" className="NavBoxRowFilterinput auto" id="mno_year" name="mno_year" onChange={(e) => lifecycleChange(e)}/> */}
                  
               
              </div>
            </Grid>
            
              <div className="NavBoxRowFilterLabel" style={{paddingTop:"8px",paddingRight:"5px"}}>Period End</div>
          

            <Grid item xs={12} sm={4} md={4}>
              <div>
              <DatePicker
                  id="period_end"
                  name="period_end"
                  dateFormat="dd/MM/yyyy"
                   selected={endDate}
                  minDate={startDate}
                  onChange={handleEnd}
                  className="NavBoxRowFilterMno auto"
                  />
              {/* <input type="text" className="NavBoxRowFilterinput auto"  id="mno_year" name="mno_year" onChange={(e) => lifecycleChange(e)}/> */}
              </div>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <div className="ivitediv">
                <button  className={changedropdown ? "navRowButtonafterclick" : "navRowButton"} 
                onClick={MoyaInvoice}>
                  Search
                </button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className="ivitediv">
                <button  className= "navRowButton" onClick={handleClickOpen}>
                 Add Moya User Data
                </button>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="ButtonDiv"> {         
                loaderVisible?
                <div className="loaderdata padding" >
                   <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
                  
                </div>:null
            }</div>
     
        {search && !loaderVisible ? (
          !empty?  <div>
            <DataTable  columns={tablecols} data={searchDetails} customStyles={customStyles} 
             sortIcon={<FaCaretDown />} noDataComponent={false}/>
          </div>:  <DataTable  columns={tablecols} key = {searchDetails} data={searchDetails} 
            customStyles={customStyles} sortIcon={<FaCaretDown />} />
         
        ) : (
           null
        )}
     
      </div>
      <Dialog
        open={openUser}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">
        <div className="FlexRow">
            <div className="cmpdialogtitle">Add Moya User Datas</div>
            <div className="cmpdialogtitle">
            <button className="inviteButton" onClick={addRow}>
                Add
              </button>
            </div>
          </div>
         
        </DialogTitle>
        <DialogContent>
        {mnoDetail?.taskList?.map((val, idx) => {
            let date = `date-${idx}`,dau=`dau-${idx}`,mau=`mau-${idx}`,new_registration=`new_registration-${idx}`
                return (
          <Grid container spacing={2} key={val.index}>
        <Grid item xs={2}>
        <div className="dialoginputtext">Date</div>

                  <DatePicker 
                    id={date}
                    data-id ={idx}
                    name="date"
                    className={mnoError[idx]?.date?"MnoDialoginputError":yearCheck?.yearExist? "MnoDialoginputError":"MnoDialoginput"}
                    dateFormat="dd/MM/yyyy"
                    autoComplete="off"
                  selected={selectedDateFormat[idx].date}
                  popperPlacement='right-end'
                  onChange={(date)=>handlemoyaDate(date,idx)}
                  />
        {mnoError[idx]?.date? <span className="errorClass">This field is required</span> : null}
        </Grid>
        <Grid item xs={2}>
        <div className="dialoginputtext">DAUs</div>
        
        <input
            type="text"
            id={dau}
            data-id ={idx}
            onChange={(e)=>mnoyearChange(e)}
            name="dau"
            label="email"
           
            className={mnoError[idx]?.dau ?"MnoDialoginputError":"MnoDialoginput"}
           
            fullWidth
         />
        {mnoError[idx]?.dau? <span className="errorClass">This field is required</span> : null}
        </Grid>
        <Grid item xs={2}>
        <div className="dialoginputtext">MAUs</div>
        <input
            type="text"
            id={mau}
            data-id ={idx}
            onChange={(e)=>mnoyearChange(e)}
            name="mau"
            className={mnoError[idx]?.mau ?"MnoDialoginputError":"MnoDialoginput"}
           
            fullWidth
         />
          {mnoError[idx]?.mau? <span className="errorClass">{mnoError[idx]?.mauMsg ? mnoError[idx]?.mauMsg : "This field is required"}</span> : null}
        </Grid>
        <Grid item xs={2}>
        <div className="dialoginputtext">New Registrations</div>
        <input
            type="text"
            id={new_registration}
            data-id ={idx}
            onChange={(e)=>mnoyearChange(e)}
            name="new_registration"
            className={mnoError[idx]?.new_registration ?"MnoDialoginputError":"MnoDialoginput"}
           
            fullWidth
         />
          {mnoError[idx]?.new_registration? <span className="errorClass">{mnoError[idx]?.regMsg ? mnoError[idx]?.regMsg : "This field is required"}</span> : null}
        </Grid>
        
       
          <Grid item xs={1}>
          {idx !== 0 ? (
                    <div>
                      <button className="closeIcon tops"   onClick={()=>deleteRow(val,selectedDateFormat[idx].date)}>
                        X
                      </button>
                    </div>
                    ) : null}
        </Grid>
          </Grid> );
          })}
            {yearCheck?.yearExist? <span className="errorClass">{yearCheck.mnoYearExist}</span> : null}
        </DialogContent>
        <DialogActions>
        {loading ?  <div className="loaderContainer">
            <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
            <div className="ivitediv">
              <button
                className="inviteButton"
                onClick={()=>setOpenUser(false)}
                color="primary"
              >
                Cancel
              </button>

              <button
                className="inviteButton"
                disabled={saveClick ? "disabled" : false}
                color="primary"
                onClick={mnoAdd}
              >
                Save
              </button>
            </div>
         
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDelete} classes={{ scrollPaper: classes.scrollPaper }} maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="headformText">WARNING : You have requested to DELETE 1 or more MNO Invoice Details - Do you wish to continue?</div>
        </DialogTitle>
        <DialogContent>
          <div className="BtnsALign">
            <button type="text" className="inviteButton" onClick={handleClose}>
              Cancel
            </button>
            <button className="continueBtn" onClick={MoyaDelete}>
              Continue with Delete
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
         {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default MaintainMoyaUserStatics;
