import React from 'react';
import "./adminStyle.css";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Loader from "react-loading";

function ConnectCreateFirstStep({ NextFormStep, handleChange, data, error, generateAppId, logo, icon, splash, LicenseeData, ChannelData, PublisherData, loaderVisible }) {
  let history = useHistory();
  return (
    <div className="directapplication">

      <Grid container spacing={3}>

        {
          loaderVisible ?

            <Grid item xs={12}> <div className="loaderpop" style={{ marginTop: '-75px' }} >
              <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />

            </div></Grid> : null}
        <Grid item xs={12}>
          <div className="gridHead">Step 1 of 6 - Selecting the Publisher Owner of the App and basic Application details</div>
        </Grid>

        <Grid item xs={4}>
          <div className="label_Direct"> Licensee </div>
          <div>
            <select type="text"
              className={!error.licensee ? "directFieldSelect" : "directFieldSelecterr"}
              id="licensee"
              name="licensee"
              style={{ height: '38px' }}
              onChange={handleChange}
              defaultValue={data.licensee}
            >
              <option></option>
              {LicenseeData && LicenseeData.length > 0
                ? LicenseeData.map((post) => (
                  <option key={post.companyId} value={post.companyId}>
                    {post.name}
                  </option>
                ))
                : null}
            </select>
            {error.licensee ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">{error.msg}</span>
            ) : (
              ""
            )}
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className="label_Direct">Channel</div>
          <div>
            <select type="text"
              className={
                !error.channel ? "directFieldSelect" : "directFieldSelecterr"
              }
              id="channel"
              name="channel"
              onChange={handleChange}
              style={{ height: '38px' }}
              defaultValue={data.channel}>
              <option></option>
              {ChannelData && ChannelData.length > 0
                ? ChannelData.map((post) => (
                  <option key={post.companyId} value={post.companyId}>
                    {post.name}
                  </option>
                ))
                : null}

            </select>

            {error.channel ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">{error.msg}</span>
            ) : (
              ""
            )}
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className="label_Direct">Publisher</div>
          <div>
            <select type="text"
              className={
                !error.publisher ? "directFieldSelect" : "directFieldSelecterr"
              }
              id="publisher"
              name="publisher"
              style={{ height: '38px' }}
              onChange={handleChange}
              defaultValue={data.publisher}>
              <option></option>
              {PublisherData && PublisherData.length > 0
                ? PublisherData.map((post) => (
                  <option key={post.id} value={post.id}>
                    {post.name}
                  </option>
                ))
                : null}

            </select>

            {error.publisher ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">{error.msg}</span>
            ) : (
              ""
            )}
          </div>
        </Grid>


        <Grid item xs={4}>
          <div className="label_Direct">App Name</div>
          <div>
            <input type="text"
              defaultValue={data.appname}
              style={{ width: '98%' }}
              className={
                !error.appname ? "directFieldSelect" : "directFieldSelecterr"
              }
              id="appname"
              name="appname"
              onChange={handleChange}></input>

            {error.appname ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">{error.nameMsg}</span>
            ) : (
              ""
            )}
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className="createButtonDiv">
            <button
              className="inviteButtonconnect"
              onClick={() => generateAppId(data.appname, data.publisher, data.licensee)}
            >
              Generate Store App ID
            </button>
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className="label_Direct">Store App ID for CONNECT App</div>
          <div>
            <input type="text"
              defaultValue={data.appId}
              style={{ width: '98%' }}
              className={
                !error.appId ? "directFieldSelect" : "directFieldSelecterr"
              }
              id="appId"
              name="appId"
              onChange={handleChange}
            />

            {error.appId ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">{error.appmsg}</span>
            ) : (
              ""
            )}
          </div>
        </Grid>

        <Grid item xs={2}>
          <div className="rowClass loadimg" >
            <img src={logo.preview ? logo.preview : ""} alt="" id="logosrc" name="logo"></img>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className="gridHead">Upload an logo image</div>
          <div className="labelDirect">
            <p> This used within the App to brand the App (a company logo is suggested </p>
            <p> (min. Dimensions of 512 x 512)</p>

          </div>

          <div className="createButtonDiv">
            <div> <input type="file" name="logo_image" id="logo_image"
              onChange={handleChange} /> </div>
            {error.logo_image ? (
              <p style={{ float: 'left' }} className="errorClass">{error.imgmsg}</p>
            ) : (
              ""
            )}
          </div>

        </Grid>

        <Grid item xs={4}></Grid>

        <Grid item xs={2}>
          <div className="rowClass loadimg" >
            <img src={icon.preview ? icon.preview : ""} alt="" name="icon"></img>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className="gridHead">Upload a icon image</div>
          <div className="labelDirect">
            <p> This used by the App as the icon displayed on the mobile device and within the Publisher Portal as a visual prompter </p>
            <p> (min. Dimensions of 512 x 512)</p>

          </div>

          <div className="createButtonDiv">
            <div> <input type="file" name="icon_image" id="icon_image" onChange={handleChange} /></div>
            {error.icon_image ? (
              <p style={{ float: 'left' }} className="errorClass">{error.iconmsg}</p>
            ) : (
              ""
            )}
          </div>

        </Grid>

        <Grid item xs={4}></Grid>


        <Grid item xs={2}>
          <div className="rowClass splashimg" style={{ marginLeft: '5px' }}>
            <img src={splash.preview ? splash.preview : ""} alt="" name="splash" />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className="gridHead">Upload a splash image</div>
          <div className="labelDirect">
            <p> This used within the App as the initial screen displayed at App startup </p>
            <p> (min. Dimensions of 1080 x 1920)</p>

          </div>

          <div className="createButtonDiv">
            <div> <input type="file" name="splash_image" id="splash_image" onChange={handleChange} /></div>
            {error.splash_image ? (
              <p style={{ float: 'left' }} className="errorClass">{error.splashmsg}</p>
            ) : (
              ""
            )}
          </div>

        </Grid>

        <Grid item xs={4}></Grid>

        <Grid item xs={6}>
          <div className="ivitediv">
            <button className="datafreeConnectBtnDanger" color="danger"
              onClick={() => history.push("/admin/datafreeconnect/application/index")}>
              Cancel
            </button>
          </div>
        </Grid>

        <Grid item xs={6} >
          <div className="ivitediv">
            <button className="datafreeConnectButton"
              onClick={() => NextFormStep(1)}
              style={{ float: 'right' }}>
              Next
            </button>
          </div>
        </Grid>

      </Grid>
      <div className="divSpace"></div>
    </div>
  )
}

export default ConnectCreateFirstStep
