import React, { useState, useEffect } from "react";
import "./detailView.css";
import Service from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import VMCTable from "../../../Recomponent/Table";
import DatePicker from "react-datepicker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { usePromiseTracker } from "react-promise-tracker";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Loader from "react-loading";

const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function AdminDeployedIpDetails(props) {
  const classes = useStyles();
  const { id } = useParams();
  let history = useHistory();
  const { serviceid } = useParams();
  const propsBInu = props.history.location.state
  const { licseeid } = props?.location?.state;
  const [serviceData, setServiceData] = useState();
  const [addHNI, setAddHNI] = useState(false);
  const handleClickOpen = () => {
    history.push(`/admin/licensee/${id}/service/details`, { licseeid: licseeid });
  };
  const [editInfo, setEditInfo] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [lodervisible, setlodervisible] = useState(false);
  const [updateHNI, setUpdateHNI] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [tablecols] = useState([
    {
      colName: "HNI",
      colMap: "HNI",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Start",
      colMap: "start_date",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "End",
      colMap: "end_date",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Billed",
      colMap: "name",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "",
      colMap: "Edit",
      linkName: true,
      cModel: true,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]);
  const [empty, setEmpty] = useState(false);
  const getAdminDeployedIpData = () => {
    setServiceData({});
    setEmpty(false);
    try {
      trackPromise(
        Service.datafreeIpIdDetails(id, serviceid).then((res) => {
          setServiceData(res.data);

          setUpdateData({
            start_date: moment(res.data.deployedIps.startDate).format("DD/MM/YYYY"),
            end_date: moment(res.data.deployedIps.endDate).format("DD/MM/YYYY"),
            ignore_id: serviceid,
            ip_address: res.data.deployedIps.IpAddress,
            ldip_id: serviceid,
          });
          hniData['company_id'] = res.data.licenseeService.companyId.companyId;
          if (res.status === 200 && res.data.hniLists.length === 0) {
            setEmpty(true);

          } else {

            setEmpty(false);
          }
        })
      );
    } catch (err) {

    }
  };
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAddHNI(false);
      setUpdateHNI(false);
    }
    setEditInfo(false);
    setlicenseeCheckbox(false);
    setDatafreeChecked(false);
    setUpdateEndDate("");
    setStartDate("");
    setEndDate("");
    setHNIData({
      HNI: "",
      dataFree_licensee: "",
      start_date: "",
      end_date: "",
      company_id: "",
      ldip_id: ""
    })
    setHNIError({

      start_date: false,
      hni: false,
      end_date: false
    })
    setDeployDateEror({
      end_date: false,
      endDateMsg: ""
    })
    setHNIEditData({
      start_date: "",
      ldip_id: "",
      HNI: "",
      end_date: "",
      hfia_id: "",
    });
    sethniExistUpError({
      ...hniExistUpError,
      end_date: false,
      endDateMsg: ""
    })
  };
  const edit = () => {
    setEditInfo(true);
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [updateEndDate, setUpdateEndDate] = useState(null);
  const [deployIpDate, setdeployIpDate] = useState(null);
  const [updateData, setUpdateData] = useState({
    start_date: "",
    end_date: "",
    ignore_id: "",
    ip_address: "",
    ldip_id: "",
  });

  const [licenseeCheckbox, setlicenseeCheckbox] = useState(false);
  const [Datafreechecked, setDatafreeChecked] = useState(false);
  const updateIpData = () => {
    if (deployedDateEror.end_date) {
      setDeployDateEror({
        ...deployedDateEror,
        end_date: true,

      })
    } else {
      trackPromise(
        Service.EditDeploymentIp(updateData).then((res) => {

          if (res.status === 200) {
            handleClose();
            setsuccessOpen(true);
            getAdminDeployedIpData();
          }
        })
      );

    }

  };

  const HNIADD = () => {
    setAddHNI(true);
  }
  const handleStart = (date) => {
    setStartDate(date !== null ? date : null);
    setEndDate(null);
    if (date !== null) {

      if (date) {
        StartDateRange(moment(date).format("DD/MM/YYYY"));
      }
    }
    else {
      setHNIError({
        ...hniError,
        start_date: true,
        startRangeMsg: "This field is required."
      })
    }
    const HNIData = { ...hniData };
    HNIData['start_date'] = date !== null ? moment(date).format("DD/MM/YYYY") : "";

    setHNIData(HNIData);
  };
  //DateRange
  const StartDateRange = (date) => {
    Service.AdminDateRange({ start_date: date }).then((res) => {
      if (res.status === 200 && res.data === false) {
        setHNIError({
          ...hniError,
          start_date: true,
          startRangeMsg: "MUST be greater or equal to today"
        })
      }
      else {
        setHNIError({
          ...hniError,
          start_date: false,
          startRangeMsg: ""
        })
        hniExistCheck(date)
      }
    })

  }
  //Check IPAddress
  const hniExistCheck = (date_) => {
    hniData['start_date'] = date_;
    Service.HNIExists(hniData).then((res) => {
      if (res.data === true) {
        setHNIError({
          ...hniError,
          start_date: true,
          startRangeMsg: "Hni already exists within the range"
        })
      }
      else {
        setHNIError({
          ...hniError,
          start_date: false,
          startRangeMsg: ""
        })

      }
    })

  }
  // define handler change function on check-out date
  const handleEnd = (date) => {
    setEndDate(date !== null ? date : null);
    if (date !== null) {
      if (date) {
        EndDateRange(moment(date).format("DD/MM/YYYY"))
      }
    }
    else {
      setHNIError({
        ...hniError,
        end_date: false,
        endRangeMsg: ""
      })
    }
    const HNIData = { ...hniData };
    HNIData['end_date'] = date !== null ? moment(date).format("DD/MM/YYYY") : "";
    setHNIData(HNIData);
  };
  //End DateRange
  const EndDateRange = (date) => {

    Service.AdminDateRange({ end_date: date }).then((res) => {
      if (res.status === 200 && res.data === false) {
        setHNIError({
          ...hniError,
          end_date: true,

          endRangeMsg: "Must be greater or equal than the start date AND MUST be greater or equal to today"
        })
      }
      else {
        setHNIError({
          ...hniError,
          end_date: false,
          endRangeMsg: ""
        })
        DateGreater(date);
      }
    })

  }
  //DateGreater
  const DateGreater = (date) => {
    hniData['end_date'] = date;

    Service.CheckDateGreater(hniData).then((res) => {
      if (res.status === 200 && res.data === false) {
        setHNIError({
          ...hniError,
          end_date: true,

          endRangeMsg: "Must be greater or equal than the start date AND MUST be greater or equal to today"
        })
      }
      else {
        setHNIError({
          ...hniError,
          end_date: false,
          endRangeMsg: ""
        })

      }
    })

  }

  const [hniData, setHNIData] = useState({
    HNI: "",
    dataFree_licensee: "",
    start_date: "",
    end_date: "",
    company_id: "",
    ldip_id: ""
  })
  const [hniError, setHNIError] = useState({
    hni: false,
    dataFree_licensee: false,
    start_date: false,
    end_date: false,
    licensee: false,
    hniMSG: "",
    dataFree_licenseeMsg: "",
    start_dateMSG: "",
    startRangeMsg: "",
    endRangeMsg: ""
  })
  //handelchange
  const handleHNI = (e) => {
    const newdata = { ...hniData };
    newdata[e.target.id] = e.target.value;

    newdata["ldip_id"] = serviceid;
    hniData['company_id'] = serviceData?.licenseeService?.companyId?.companyId;
    if (e.target.id === "HNI") {
      if (e.target.value) {
        setHNIError({
          ...hniError,
          hni: false,
          hniMSG: ""
        });
      }
      else {
        setHNIError({
          ...hniError,
          hni: true,
          hniMSG: "This field is required"
        });
      }
    }


    setHNIData(newdata);
  }
  //add HNI
  const [clickSave, setClickSave] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  const [message, setMessage] = useState();
  const AddHNI = () => {


    if (hniData.HNI.length === 0 || hniData.start_date.length === 0 || hniData.dataFree_licensee.length === 0) {
      setHNIError({
        ...hniError,
        start_date: hniData.start_date.length === 0 ? true : false,
        hni: hniData.HNI.length === 0 ? true : false,
        dataFree_licensee: hniData.dataFree_licensee.length === 0 ? true : false
      })
    }
    else if (hniError.hniExistUpError || hniError.start_date || hniError.end_date) {

      setHNIError({
        ...hniError,
        start_date: hniError.start_date ? true : false,
        hni: hniError.hni ? true : false,
        end_date: hniError.end_date ? true : false
      })
    }
    else {
      setClickSave(true);
      setlodervisible(true)
      trackPromise(
        Service.addHNI(hniData).then((res) => {
          setlodervisible(false)
          if (res.status === 200) {
            setToastMsg("HNI Agreement Added Successfully!")
            handleClose();
            setsuccessOpen(true);
            getAdminDeployedIpData();
            setClickSave(false);
          }
        }).catch((error) => {
          setMessage("Something went wrong. Please reload the page!.");
          seterrorOpen(true);
        })
      );
    }
  };
  const checkHandle = (e) => {

    hniData['company_id'] = serviceData?.licenseeService?.companyId?.companyId;
    if (e.target.id === "licensee") {
      setlicenseeCheckbox(!licenseeCheckbox);
      setDatafreeChecked(false);

      if (licenseeCheckbox === false) {
        hniData['dataFree_licensee'] = 1;
        setHNIError({
          ...hniError,
          dataFree_licensee: false,

        });
      }
      else {
        hniData['dataFree_licensee'] = "";
        setHNIError({
          ...hniError,
          dataFree_licensee: true,

        });
      }


    }
    else if (e.target.id === "dataFree_licensee") {
      setlicenseeCheckbox(false);
      setDatafreeChecked(!Datafreechecked);
      if (Datafreechecked === false) {
        hniData['dataFree_licensee'] = 2;
        setHNIError({
          ...hniError,
          dataFree_licensee: false,

        });
      }
      else {
        hniData['dataFree_licensee'] = "";
        setHNIError({
          ...hniError,
          dataFree_licensee: true,

        });
      }

      setHNIError({
        ...hniError,
        dataFree_licensee: false,

      });

    }
  }
  const openHNIList = (HNI_id) => {
    setUpdateHNI(true);
    GETHNI(HNI_id);
  }
  const [getHNIList, setgetHNIList] = useState()
  //get HNI
  const GETHNI = (id) => {
    trackPromise(
      Service.getHNI({ id: id }).then((res) => {

        if (res.status === 200) {
          setgetHNIList(res.data);
          setHNIEditData({
            start_date: res.data.start_date,
            ldip_id: res.data.ldip_id,
            HNI: res.data.hni,
            end_date: res.data.end_date ? res.data.end_date : "",
            hfia_id: res.data.hfia_id,
          });

        }
      }).catch((error) => {
        setMessage("Something went wrong. Please reload the page!.");
        seterrorOpen(true);
      })
    );

  };


  const [hniExistUpError, sethniExistUpError] = useState({
    end_date: false,
    endDateMsg: ""
  })
  const [HNIEditData, setHNIEditData] = useState({
    start_date: "",
    ldip_id: "",
    HNI: "",
    end_date: "",
    hfia_id: "",

  })
  const EndDateUpdate = (date) => {
    setUpdateEndDate(date !== null ? date : null);

    if (date) {
      if (date !== null) {
        hniexistUpdateCheck(moment(date).format("DD/MM/YYYY"))
      }
      else {
        sethniExistUpError({
          ...hniExistUpError,
          end_date: false,
          endDateMsg: ""
        })
      }
    }
    else {
      sethniExistUpError({
        ...hniExistUpError,
        end_date: false,
        endDateMsg: ""
      })
    }
    const HNIData = { ...HNIEditData };
    HNIData['end_date'] = date !== null ? moment(date).format("DD/MM/YYYY") : "";
    setHNIEditData(HNIData);
  }
  const hniexistUpdateCheck = (date) => {
    HNIEditData['end_date'] = date;

    trackPromise(
      Service.HNIExists(HNIEditData).then((res) => {
        if (res.data === true) {
          sethniExistUpError({
            ...hniExistUpError,

            end_date: true,
            endDateMsg: "This hni is already exist"
          })
        }
        else {
          sethniExistUpError({
            ...hniExistUpError,

            end_date: false,

          })
          DateUpdateGreater(date)
        }
      })
    );
  }

  //End DateRange
  const EndDateUpdateRange = (date) => {

    trackPromise(
      Service.AdminDateRange({ end_date: date }).then((res) => {
        if (res.status === 200 && res.data === false) {
          sethniExistUpError({
            ...hniExistUpError,
            end_date: true,

            endDateMsg: "MUST be greater or equal to today"
          })
        }
        else {
          sethniExistUpError({
            ...hniExistUpError,
            end_date: false,
            endDateMsg: ""

          })

        }
      })
    );
  }
  //DateGreater
  const DateUpdateGreater = (date) => {
    HNIEditData['end_date'] = date;
    trackPromise(
      Service.CheckDateGreater(HNIEditData).then((res) => {
        if (res.status === 200 && res.data === false) {
          sethniExistUpError({
            ...hniExistUpError,
            end_date: true,

            endDateMsg: "value must be greater than start date"
          })
        }
        else {
          sethniExistUpError({
            ...hniExistUpError,
            end_date: false,
            endDateMsg: ""
          })
          EndDateUpdateRange(date)
        }
      })
    );
  }

  const validateEditHNI = () => {
    let countval = 0;
    let nwerror = { ...hniExistUpError }
    if (HNIEditData.end_date === "") {
      nwerror.end_date = false;
      nwerror.endDateMsg = ""
    }
    else {
      if (hniExistUpError.end_date === true) {
        nwerror.end_date = true;
        nwerror.endDateMsg = hniExistUpError.endDateMsg
        countval++;
      }
      else {
        nwerror.end_date = false;
        nwerror.endDateMsg = ""
      }
    }
    sethniExistUpError(nwerror);
    return countval;
  }
  //update HNI
  const HNIEdit = () => {
    const deployCounts = validateEditHNI();
    if (deployCounts === 0) {
      setlodervisible(true);
      trackPromise(
        Service.updateHNI(HNIEditData).then((res) => {
          setlodervisible(false);
          if (res.status === 200) {
            setToastMsg("HNI Edited Successfully!");
            handleClose();

            setsuccessOpen(true);
            getAdminDeployedIpData();
          }
        }).catch((error) => {
          setMessage("Something went wrong. Please reload the page!.");
          seterrorOpen(true);
        })
      );
    }

  };

  const [deployedDateEror, setDeployDateEror] = useState({
    end_date: false,
    endDateMsg: ""
  })
  const deployIPEndDate = (date) => {

    setdeployIpDate(date);
    const IPData = { ...updateData };

    if (date) {
      DateDeployGreater(moment(date).format("DD/MM/YYYY"))
      IPData['end_date'] = moment(date).format("DD/MM/YYYY");
    }
    else {
      DateDeployGreater("");
      IPData['end_date'] = "";
    }
    setUpdateData(IPData);
  }

  const DeployDateCheck = (date) => {
    updateData['end_date'] = date;

    trackPromise(
      Service.IpExists(updateData).then((res) => {
        if (res.data === false) {
          setDeployDateEror({
            ...deployedDateEror,

            end_date: true,
            endDateMsg: "This Ip Address is already assigned"
          })
        }
        else {
          setDeployDateEror({
            ...deployedDateEror,

            end_date: false,

          })
          DeployDateRange(date)
        }
      })
    );
  }

  //End DateRange
  const DeployDateRange = (date) => {

    trackPromise(
      Service.AdminDateRange({ end_date: date }).then((res) => {
        if (res.status === 200 && res.data === false) {
          setDeployDateEror({
            ...deployedDateEror,
            end_date: true,

            endDateGeaterMsg: "MUST be greater or equal to today"
          })
        }
        else {
          setDeployDateEror({
            ...deployedDateEror,
            end_date: false,
            endDateGeaterMsg: ""

          })

        }
      })
    );
  }
  //DateGreater
  const DateDeployGreater = (date) => {
    updateData['end_date'] = date;
    trackPromise(
      Service.CheckDateGreater(updateData).then((res) => {
        if (res.status === 200 && res.data === false) {
          setDeployDateEror({
            ...deployedDateEror,
            end_date: true,

            endDateMsg: "value must be greater than start date"
          })
        }
        else {
          setDeployDateEror({
            ...deployedDateEror,
            end_date: false,
            endDateMsg: ""
          })
          DeployDateCheck(date)
        }
      })
    );
  }

  useEffect(() => {
    getAdminDeployedIpData();
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="licenseHead"># Assigned Deployment IP Details</div>
      <div className="rowhead">
        <div>Service Details</div>
      </div>
      <div className="gridsp">
        <Grid container>
          <Grid item xs={2}>
            <div className="ServiceName">Service Name </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService ? serviceData?.licenseeService?.name : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="ServiceName">Description </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService ? serviceData?.licenseeService?.description : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="ServiceName">Service Type </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.service ? serviceData?.licenseeService?.service?.service : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="ServiceName">Proxy Domain </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService ? serviceData?.licenseeService?.proxyDomain : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="ServiceName">Zero Rated ?</div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.zeroRated === 1 ? "yes" : "no"} disabled="disabled"></input>
            </div>
          </Grid>

          <Grid item xs={2}>
            <div>
              <div className="ServiceName">Status</div>
              <div>
                <input type="text" className="field" value={serviceData?.licenseeService ? serviceData?.licenseeService?.status : ""} disabled="disabled"></input>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="ServiceName">Service Config Approved</div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.serviceConfigApproved === 0 ? false : true} disabled="disabled"></input>
            </div>
          </Grid>
        </Grid>
      </div>

      <hr className="applnslisthr" />
      <div className="fieldhead">Deployment Ip Details</div>
      <div className="deployIProw">
        <div className="fieldhead">Deployed IPs</div>
        <div>
          <button className={editInfo ? "invitedisabledButton" : "inviteButton"} onClick={edit}
            disabled={editInfo ? "disabled" : false}>
            Edit Details
          </button>
        </div>
      </div>

      <div className="gridsp">
        <Grid container>

          <Grid item xs={4}>
            <div className="ServiceName">biNu IP ? </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.binuIp === false ? "N" : "Y"} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="ServiceName">IP Address </div>
            <div>
              <input type="text" id="ip_address" name="ip_address" className="field"
                value={serviceData?.deployedIps?.IpAddress ? serviceData?.deployedIps?.IpAddress : ""} disabled="disabled"></input>
            </div>
          </Grid>

          <Grid item xs={4}>
            {serviceData?.licenseeService?.service?.service === "CONNECT" ? (
              <div>
                <div className="ServiceName">Licensee Deployment Type </div>
                <div>
                  <input type="text" className="field" value={serviceData?.deployedIps?.licDepType ? serviceData?.deployedIps?.licDepType : ""} disabled="disabled"></input>
                </div>
              </div>
            ) : null}
          </Grid>
          {propsBInu?.propsBInuIP ? <Grid item xs={4}>
            <div className="ServiceName">IP ID </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.ipId} disabled="disabled"></input>
            </div>
          </Grid> : editInfo ? <Grid item xs={4}>
            <div className="ServiceName">IP ID </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.ipId} disabled="disabled"></input>
            </div>
          </Grid> : null
          }
          {propsBInu?.propsBInuIP ? <Grid item xs={4}>
            <div className="ServiceName">Cloud </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.cloUd ? serviceData?.deployedIps?.cloUd : ""} disabled="disabled"></input>
            </div>
          </Grid> : editInfo ? <Grid item xs={4}>
            <div className="ServiceName">Cloud </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.cloUd ? serviceData?.deployedIps?.cloUd : ""} disabled="disabled"></input>
            </div>
          </Grid> : null
          }
          {propsBInu?.propsBInuIP ? <Grid item xs={4}>
            <div className="ServiceName">Account </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.account ? serviceData?.deployedIps?.account : ""} disabled="disabled"></input>
            </div>
          </Grid> : editInfo ? <Grid item xs={4}>
            <div className="ServiceName">Account </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.account ? serviceData?.deployedIps?.account : ""} disabled="disabled"></input>
            </div>
          </Grid> : null
          }
          {propsBInu?.propsBInuIP ? <Grid item xs={4}>
            <div className="ServiceName">Region </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.region ? serviceData?.deployedIps?.region : ""} disabled="disabled"></input>
            </div>
          </Grid> : editInfo ? <Grid item xs={4}>
            <div className="ServiceName">Region </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.region ? serviceData?.deployedIps?.region : ""} disabled="disabled"></input>
            </div>
          </Grid> : null
          }
          {propsBInu?.propsBInuIP ? <Grid item xs={4}>
            <div className="ServiceName">IP Domain </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.ipDomain ? serviceData?.deployedIps?.ipDomain : ""} disabled="disabled"></input>
            </div>
          </Grid> : editInfo ? <Grid item xs={4}>
            <div className="ServiceName">IP Domain </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.ipDomain ? serviceData?.deployedIps?.ipDomain : ""} disabled="disabled"></input>
            </div>
          </Grid> : null
          }

          <Grid item xs={4}>
            <div className="ServiceName">Start Date</div>
            <div>
              <input type="text" className="field" id="start_date" name="start_date" value={moment(serviceData?.deployedIps?.startDate).format("DD/MM/YYYY")} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="ServiceName">End Date </div>
            <div>
              {editInfo ?
                <DatePicker
                  id="end_date"
                  name="end_date"
                  className={deployedDateEror.end_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  selected={deployIpDate}

                  //value={deployIpDate?deployIpDate:serviceData?.deployedIps?.endDate?moment(serviceData?.deployedIps?.endDate).format("DD/MM/YYYY"):""} 
                  //  minDate={startDate}
                  onChange={deployIPEndDate}

                />

                :

                <input type="text" className="field" value={serviceData?.deployedIps?.endDate ? moment(serviceData?.deployedIps?.endDate).format("DD/MM/YYYY") : null} disabled="disabled" />}

              {deployedDateEror.end_date ? <span className="errorClass">{deployedDateEror.endDateMsg ? deployedDateEror.endDateMsg : ""}</span> : null}
            </div>
          </Grid>
        </Grid>
        {editInfo ? (
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="inviteButton" color="primary" onClick={updateIpData}>
              Save
            </button>
            <div style={{ marginRight: "3%" }}></div>
          </div>
        ) : null}
      </div>


      <div>
        <hr className="applnslisthr" />
        <div className="deployIProw">
          <div className="fieldhead">HNI Agreements</div>
          {serviceData?.licenseeService?.zeroRated === 1 ? (
            <div>
              <button className="inviteButton" onClick={HNIADD} >

                Add HNI Agreements
              </button>
            </div>
          ) : (
            <div>
              <button className="disabledHNIbutton" disabled="disabled">
                Add HNI Agreements
              </button>
            </div>
          )}
        </div>
        {serviceData && serviceData?.hniLists?.length > 0 ? (
          <VMCTable data={serviceData.hniLists} col={tablecols} style={{ marginLeft: "12px" }} tableName="Admin Licence Service Deployed" navigateFunction={openHNIList} />
        ) : <div>
          <LoadingIndicator />
          {empty ? <EmptyTable data="No HNIs agreements Found" col={tablecols} tableName="Admin Licence Service Deployed" /> : null}
        </div>}

        <div className="invitesdiv">
          <button className="inviteButton" onClick={handleClickOpen}>
            Return To Licensee Service Details
          </button>
        </div>
        {/* ////Add HNI  */}

        <Dialog open={addHNI} classes={{ scrollPaper: classes.scrollPaper }}
          fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <div className="FlexRow">
              <div className="cmpdialogtitle"> Add Licensee HNI Agreement</div>
              <div className="cmpdialogtitle">
                <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
              </div>
            </div>

          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="companyLabel">HNI</div>

              </Grid>

              <Grid item xs={6}>
                <select
                  type="text"
                  id="HNI"
                  name="HNI"
                  style={{ height: '34px' }}
                  className={hniError.hni ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  onChange={(e) => handleHNI(e)}
                  fullWidth

                >
                  <option value=""></option>
                  {serviceData && serviceData?.hniDetails?.length > 0
                    ? serviceData?.hniDetails.map((post) => (
                      <option key={post.HNI} value={post.HNI}>
                        {post.HNI}
                      </option>
                    ))
                    : null}


                </select>
                {hniError.hni ? <span className="errorClass">This field is required.</span> : null}
              </Grid>
              <Grid item xs={4}>
                <div className="companyLabel">Licensee Billed by MNO</div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <input type="checkbox" name="licensee" id="licensee" checked={licenseeCheckbox} onChange={(e) => checkHandle(e)} />
                  <label htmlFor="websiteName" className="cmpdialoginputtext">
                    The Licensee

                  </label>

                </div>
                {hniError.dataFree_licensee ? <span className="errorClass">This field is required</span> : null}
              </Grid>
              <Grid item xs={4}>
                <div>
                  <input type="checkbox" name="dataFree_licensee" id="dataFree_licensee"
                    onChange={(e) => checkHandle(e)} checked={Datafreechecked} />
                  <label htmlFor="websiteName" className="cmpdialoginputtext">
                    Datafree Technologies

                  </label>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="companyLabel">Start Date of Agreement</div>

              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  id="start_date"
                  name="start_date"
                  className={hniError.start_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  selected={startDate}
                  popperPlacement='left-start'
                  autoComplete="off"
                  // // minDate={new Date()}
                  onChange={handleStart}
                />
                {hniError.start_date ? <span className="errorClass">{hniError.startRangeMsg ? hniError.startRangeMsg : "This field is required"}</span> : null}
              </Grid>
              <Grid item xs={6}>
                <div className="companyLabel">End Date of Agreement</div>

              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  id="end_date"
                  name="end_date"
                  className={hniError.end_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  selected={endDate}
                  popperPlacement='top-start'
                  //  minDate={startDate}
                  autoComplete="off"
                  onChange={handleEnd}
                />
                {hniError.end_date ? <span className="errorClass">{hniError.endRangeMsg ? hniError.endRangeMsg : ""}</span> : null}
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>
            {lodervisible ? <LoadingIndicator /> : null}
            <div className="ivitediv">
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>
              <button className="inviteButton"
                disabled={clickSave ? "disabled" : false}
                color="primary" onClick={AddHNI}>
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>

        {/* //Update HNI */}

        <Dialog open={updateHNI}

          classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <div className="FlexRow">
              <div className="cmpdialogtitle"> Edit Licensee HNI Agreement</div>
              <div className="cmpdialogtitle">
                <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
              </div>
            </div>

          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="companyLabel">HNI</div>

              </Grid>

              <Grid item xs={6}>
                <input
                  type="text"

                  disabled="disabled"
                  className="disabledialoginput"
                  defaultValue={getHNIList?.hni ? getHNIList?.hni : ""}
                  fullWidth
                /></Grid>
              <Grid item xs={4}>
                <div className="companyLabel">Licensee Billed by MNO</div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <input type="checkbox" name="option-create-user"
                    disabled="disabled" id="option-create-user" checked={getHNIList?.company_id
                      === 1 ? true : false} />
                  <label htmlFor="websiteName" className="cmpdialoginputtext">
                    The Licensee

                  </label>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <input type="checkbox" name="option-create-user"
                    disabled="disabled" id="option-create-user" checked={getHNIList?.company_id
                      === 2 ? true : false} />
                  <label htmlFor="websiteName" className="cmpdialoginputtext">
                    Datafree Technologies


                  </label>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="companyLabel">Start Date of Agreement</div>

              </Grid>

              <Grid item xs={6}>
                <input
                  type="text"
                  id="startUpdate_date"
                  name="startUpdate_date"
                  disabled="disabled"
                  className="disabledialoginput"
                  defaultValue={getHNIList?.start_date ? getHNIList?.start_date : null}
                  popperPlacement='left-start'
                  fullWidth
                /></Grid>
              <Grid item xs={6}>
                <div className="companyLabel">End Date of Agreement</div>

              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  id="end_date"
                  name="end_date"
                  className={hniExistUpError.end_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  selected={updateEndDate}
                  popperPlacement='top-start'
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  placeholderText={HNIEditData?.end_date ? HNIEditData?.end_date : ""}
                  onChange={EndDateUpdate}
                />
                {hniExistUpError.end_date ? <span className="errorClass">{hniExistUpError.endDateMsg ? hniExistUpError.endDateMsg : ""}</span> : null}
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>
            {lodervisible ? <LoadingIndicator /> : null}
            <div className="ivitediv">
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>
              <button className="inviteButton" color="primary" onClick={HNIEdit}>
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>
        <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
          <Alert onClose={handlesuccessAlert} severity="success">
            {toastMsg ? toastMsg : "Updated Successfully!"}
          </Alert>
        </Snackbar>

        <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="error">
            {message ? message : "An Error Occured!"}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
export default AdminDeployedIpDetails;
