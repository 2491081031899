import React, { useState, useEffect } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import { useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AdminService from "../../../api/adminService";
import VMCTable from "../../../Recomponent/Table";
import EmptyTable from "../../../Recomponent/EmptyTable";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function DataFreeGatewayDomain() {
  const [search, setSearch] = useState(false);
  const [searchDetails, setSearchDetails] = useState();
  const [manageList, setManageList] = useState();
  const [changedropdown, setChangedropdown] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [data, setData] = useState({
    searchParentCompany: "",
    searchCompanyType: "",
    searchCompanyName: "",
    searchDomain: ""
  });

  const [tablecols] = useState([
    {
      colName: "Managed By",
      colMap: "companyIdparentCompanyIdname",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Type",
      colMap: "companyIdcompanyType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Name",
      colMap: "companyIdname",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "#datafree Gateway Domain",
      colMap: "gateway",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Description",
      colMap: "description",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "companyIdstatus",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Use For Products",
      colMap: "productsproductType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },

    {
      colName: "Points to Service",
      colMap: "licServeIdname",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },

  ]); //no application found

  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/admin/datafreegatewaydomain/create`);
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  const handleRouteLink = (companyId, companytype) => {

    if (companytype === "CUSTOMER" || "RESLR CLNT") {
      history.push(`/admin/customer/${companyId}/detail/view`);
    }
    if (companytype === "CHANNEL") {
      history.push(`/admin/channel/${companyId}/detail/view`, {
        companyId: companyId,
      });
    }
    if (companytype === "LICENSEE") {
      history.push(`/admin/licensee/${companyId}/detail/view`, {
        licenseeId: companyId,
      });
    }
    if (companytype === "companyIdgateway") {
      history.push(`/admin/datafreegatewaydomain/${companyId}/detail/view`);
    }
  };
  const lifecycleChange = (e) => {
    e.preventDefault();
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };

  //managed By List
  async function manageListdata() {
    try {
      trackPromise(
        AdminService.datafreeGatewayDomain().then((res) => {
          setManageList(res.data.publisherListResult);

        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [resData, setResData] = useState();
  const [errMsg, setErrMsg] = useState();
  const [empty, setEmpty] = useState(false);
  //search
  async function dataFreeListListSearch() {
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setSearchDetails({});

    try {
      trackPromise(
        AdminService.datafreeGatewayDomainSearch(data).then((res) => {

          setSearchDetails(res.data.dataFreeSearchDomainData);
          setResData(res.data);
          if (res.status === 200 && res.data.dataFreeSearchDomainData.length > 0) {

            setChangedropdown(false);
          } else if (res.status === 200 && res.data.dataFreeSearchDomainData.length === 0) {
            setChangedropdown(false);
            setEmpty(true);
          } else {
            setChangedropdown(true);
          }

        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  useEffect(() => {
    manageListdata();
  }, []);

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Company Specific #datafree Gateway Domain List - Admin View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                  Managed By
                </td>
                <td className="col-md-2">
                  Company Type
                </td>
                <td className="col-md-2">
                  Company Name
                </td>
                <td className="col-md-2">
                  #datafree Gateway Domain
                </td>
                <td className="col-md-2">

                </td>

              </tr>
              <tr>
                <td className="col-md-2">
                  <select type="text" className="form-control form-select" id=" searchParentCompany" name=" searchParentCompany" onChange={(e) => lifecycleChange(e)}>
                    <option value=""></option>

                    {manageList && manageList.length > 0
                      ? manageList.map((post) => (
                        <option key={post.companyId} value={post.companyId}>
                          {post.name}
                        </option>
                      ))
                      : null}
                  </select>
                </td>
                <td className="col-md-2">
                  <select type="text" className="form-control form-select" id="searchCompanyType" name="searchCompanyType" onChange={(e) => lifecycleChange(e)}>
                    <option value=""></option>
                    <option value="CHANNEL">CHANNEL</option>
                    <option value="CUSTOMER">CUSTOMER</option>
                    <option value="RESELLER">RESELLER</option>
                    <option value="LICENSEE">LICENSEE</option>
                  </select>

                </td>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="searchCompanyName" name="searchCompanyName" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="searchDomain" name="searchDomain" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                  <button className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                    disabled={changedropdown ? "disabled" : false}
                    onClick={dataFreeListListSearch}>
                    Search
                  </button>
                </td>

              </tr>
            </tbody>
          </table>


        </div>
        <div><hr className="applications-list-hr"></hr></div>
        <div className="createButtonDiv">

          <button className="defultbtn"
            title="Click here to create New Company Specific #datafree Gateway Domain"
            onClick={() => handleClickOpen()}>
            + Create New Company Specific #datafree Gateway Domain
          </button>
        </div>

        {search ? (
          <div>
            {searchDetails && searchDetails.length > 0 ? (
              <VMCTable key={searchDetails} data={searchDetails} col={tablecols} additionalData={resData} navigateFunction={handleRouteLink} tableName="Admin data free Gateway Domain" />
            ) :
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Applications Found" col={tablecols} tableName="Admin data free Gateway Domain" /> : null}
              </div>
            }
          </div>
        ) : null}
      </div>

      <Snackbar open={successopen} autoHideDuration={2500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          "Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={2500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default DataFreeGatewayDomain;
