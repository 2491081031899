import React,{useContext }  from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CompanyList from "./adminPages/companyList";
import UserList from "./adminPages/userList";
import ApplicationList from "./adminPages/application";
import BinuList from "./adminPages/binuPlatformVersions";
import Utilities from "./adminPages/utilities";
import AdminAccount from "./adminPages/account";
import BinuSDK from "./adminPages/binuSDK";
import LicenseeDetailView from "./detailViews/adminLicenseeDetailView";
import ChannelDetailView from "./detailViews/adminChannelDetailView";
import CustomerDetailView from "./detailViews/adminCustomerDetailView";
import AdminLicenseeServiceView from "./detailViews/adminLicenseeServiceView";
import AdminDeployedIpDetails from "./detailViews/adminLicensServiceDeployedIpDetails";
import DataFreeDirectApplication from "./adminPages/dataFreeDirectApplication";
import MultipleRoles from "./adminPages/maintainMultipleRoles";
import AdminDirectApplication from "./detailViews/adminDatafreeDirectApplication";
import AdminConnectApplication from "./detailViews/adminDatafreeConnectApplication";
import AdminDirectApplicationCreate from "./detailViews/adminDatafreeAppCreate";
import AdminDataFreeGatewayDomainIndex from "./adminPages/dataFreeGatewayDomain";
import AdminDataFreeGatewayDomainCreate from "./adminPages/adminDatafreeGatewayDomainCreate";
import AdminViewBinuDeployment from "./adminPages/viewBinuIpDeployment";
import MaintainInvoiceData from "./adminPages/maintainInvoiceData";
import AdminDataFreeGatewayDomainDetailView from "./detailViews/dataFreeGatewayDomainDetailView";
import MaintainMoyaUserStatics from "./adminPages/maintainMoyaUserStatistics";
import Connect from "./adminPages/connect";
import ConnectCreate from "./adminPages/connectCreate";
import UserDetails from "../user/userPages/userDetails";
import NotificationPage from "./adminPages/notificationPage";
import MultipleRoleDetails from "./adminPages/multipleUserDetails";
import LicenseeRevenue from "./adminPages/licenseeRevenue";
import {
  userContext
 } from "../../api/context"


export default function Admin() {
   const userContextVal = useContext(userContext)
   
  return (
    
    <div>
      <Switch>
       {userContextVal?.token !== null && userContextVal?.token !== undefined ?
        <>
        <Route exact path="/admin/company/index" component={CompanyList} />
        <Route exact path="/admin/user/index" component={UserList} />
        <Route
          exact
          path="/admin/application/index"
          component={ApplicationList}
        />
        <Route exact path="/admin/binu/versions/list/index" component={BinuList} />
        <Route exact path="/admin/utilities/option" component={Utilities} />
        <Route exact path="/admin/user/account/details/view" component={AdminAccount} />
        <Route exact path="/admin/binu/sdk/versions/list/index" component={BinuSDK} />
        <Route exact path="/admin/licensee/:licenceeid/detail/view" component={LicenseeDetailView} />
        <Route exact path="/admin/customer/:customerid/detail/view" component={CustomerDetailView} />
        <Route exact path="/admin/channel/:companyId/detail/view" component={ChannelDetailView} />
        <Route exact path="/admin/licensee/:serviceid/service/details" component={AdminLicenseeServiceView} />
        <Route exact path="/admin/datafreedirectapplication/approve:id/:serviceid/ips/details" component={AdminDeployedIpDetails} />
        <Route exact path="/admin/datafreedirect/application/index" component={DataFreeDirectApplication} />
        <Route exact path="/admin/datafreedirect/application/:appId/detail/view" component={AdminDirectApplication} />
        <Route exact path="/admin/datafreedirect/application/create" component={AdminDirectApplicationCreate} />
        <Route exact path="/admin/datafreegatewaydomain/index" component={AdminDataFreeGatewayDomainIndex} />
        <Route exact path="/admin/datafreegatewaydomain/create" component={AdminDataFreeGatewayDomainCreate} />
        <Route exact path="/admin/datafreegatewaydomain/:gateway/detail/view" component={AdminDataFreeGatewayDomainDetailView} />
        <Route exact path="/admin/view/binuipdeployment/index" component={AdminViewBinuDeployment} />
        <Route exact path="/admin/mno/invoice/reference/data" component={MaintainInvoiceData} />
        <Route exact path="/admin/moya/user/statistics" component={MaintainMoyaUserStatics} />
        <Route exact path="/admin/datafreeconnect/application/index" component={Connect} />
        <Route exact path="/admin/datafreeconnect/application/create" component={ConnectCreate} />
        <Route exact path="/admin/user/details/view" component={UserDetails}/>
        <Route exact path="/admin/datafreeconnect/application/:appId/detail/view" component={AdminConnectApplication} />
        <Route exact path="/admin/view/licensee/:serviceid/service/details" component={AdminLicenseeServiceView} />
        <Route exact path="/admin/notifications/maintenance/index" component={NotificationPage} />
        <Route exact path="/admin/multiple/maintenance/index" component={MultipleRoles} />
        <Route exact path="/admin/multipleuser/:id/details" component={MultipleRoleDetails} />
        <Route exact path="/admin/revenue/maintenance/index" component={LicenseeRevenue} />
        
        </>
      :<Redirect  to="/"></Redirect>}
    
      </Switch>
    </div>
    
  );
}