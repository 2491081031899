import React, {  useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Style from "./style";
import datafreelogo from "../../assets/img/datafree_logo_white.png";
import service from "../../api/service";
import Loader from "react-loading";
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles(Style);

function VerifiedUser(props) {

const classes = useStyles();
const code = useLocation().search;
const codeNew = new URLSearchParams(code).get('verificationCode');
const [loading, setLoading] = useState();
const [verify, setVerify] = useState();
const getVerified = () =>{
setLoading(true);
const codes = new URLSearchParams(code).get('verificationCode');
service.verifyUser(codes).then((res) => {
    
    if(res.status === 200){
     setVerify(res.data?.randomCodeVerification);
    }
    setLoading(false);
    }).catch((err) => {

    })
}

const [data, setData] = useState({
    newPassword: "",
    verificationCode: codeNew,
    renewPassword:"",
  });
const [error, setError] = useState({
    newPassword: false,
    renewPassword:false,
    new_passwordMSG:"",
    confirm_passworMSG:"",
  });
const[newpasswd,setNewpsswrd] = useState(null);
const passwrdchalength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{8,}/;
function handle(e) {
    e.preventDefault();
    const newdata = { ...data};
    newdata[e.target.id] = e.target.value;
    if(e.target.id === "newPassword"){
      if(e.target.value){
       setNewpsswrd(e.target.value);
       if(e.target.value.length < 8){
        setError({
          ...error,
          newPassword:true,
          new_passwordMSG:"Please enter at least 8 characters."
        })
       }
        else if(passwrdchalength.test(e.target.value) === false){
          setError({
            ...error,
            newPassword:true,
            new_passwordMSG:"Please enter at​ least​​ 8​ characters​ with​ at least 1​ uppercase,1 lowercase,1 digit"
          })
        }else{
          setError({
            ...error,
            newPassword:false,
            new_passwordMSG:""
          })
        }
      }else{
        setError({
          ...error,
          newPassword:true,
          new_passwordMSG:"This field is required"
        })
      }
    }

    if(e.target.id === "renewPassword"){
      if(e.target.value){
        if(newpasswd !== e.target.value){
          setError({
            ...error,
            renewPassword:true,
            confirm_passworMSG:"Please enter the same password as above."
          })
        }else{
          setError({
            ...error,
            renewPassword:false,
            confirm_passworMSG:""
          })
        }
      }else{
        setError({
          ...error,
          renewPassword:true,
          confirm_passworMSG:"This field is required"
        })
      }
    }
    setData(newdata);
}

const validatePasswordCreate = (data) =>{

let count = 0;
const nwerror = {...error};
if(data.newPassword === ""){
  nwerror.newPassword = true;
  nwerror.new_passwordMSG = "This field is required.";
  count++;
}
else{
  if(data.newPassword.length < 8){
    nwerror.newPassword = true;
    nwerror.new_passwordMSG = "Please enter at least 8 characters.";
    count++;
   }
   else if(passwrdchalength.test(data.newPassword) === false){
    nwerror.newPassword = true;
    nwerror.new_passwordMSG = "Please enter at​ least​​ 8​ characters​ with​ at least 1​ uppercase,1 lowercase,1 digit";
    count++;
   }
   else{
    nwerror.newPassword = false;
    nwerror.new_passwordMSG = ""; 
   }
}

if(data.renewPassword === ""){
  nwerror.renewPassword = true;
  nwerror.confirm_passworMSG = "This field is required.";
  count++;
}
else{
  if(data.newPassword !== data.renewPassword){
    nwerror.renewPassword = true;
    nwerror.confirm_passworMSG = "Please enter the same password as above.";
    count++; 
  }else{
    nwerror.renewPassword = false;
    nwerror.confirm_passworMSG = "";
  }
}

setError(nwerror);
return count;
}
const [clickSave, setClickSave] = useState(false);
const createPassword = () =>{
  
    const countPass = validatePasswordCreate(data);
    if(countPass === 0){

      setLoading(true);
      setClickSave(true);
      service.newPass(data).then((res) => {
      
        if(res.data?.verifyStatus?.status === "success"){
          
          props.history.push("/new/password",{ message: res.data?.verifyStatus?.message});
        }
        setLoading(false);
        setClickSave(false);
        }).catch((err) => {
    
        })
    }
}
useEffect(()=>{
   setLoading(true);
   getVerified();
   },[]);


    return (
        <div className={classes.paper}>
      <div className={classes.outer}>
        <div>
          <img src={datafreelogo} alt="" className={classes.datafreelogocls}></img>
        </div>
        
        <div className={classes.subLogoImg}>Datafree Portal</div>
        <div className={classes.signupbox}>
            <form className={classes.form}>
                {verify && verify === 1 ? 
                <><div className={classes.headText}>Your email address has been verified</div></> :
                verify && verify === 0 ?
                <><div className={classes.headText} style={{color:'red'}}>Your email address has been not verified</div></> : null 
                }
                <div className="cmpdialoginputtext" disabled="disabled">
                    Please enter a new password.
                </div>
                <input
                    type="password"
                    //className={classes.textField}
                    className={
                     !error?.newPassword ? classes.textField : classes.texterrorField
                    }
                    required
                    placeholder="Password"
                    id="newPassword"
                    onChange={(e) => handle(e)}
                />
                {error?.newPassword ? <span className="errorClass">{error?.new_passwordMSG}</span> : null}
                <input
                    type="password"
                    //className={classes.textField}
                    className={
                     !error?.renewPassword ? classes.textField : classes.texterrorField
                    }
                    required
                    placeholder="Re-Type Password"
                    id="renewPassword"
                    onChange={(e) => handle(e)}
                />

                {error?.renewPassword ? <span className="errorClass">{error?.confirm_passworMSG}</span> : null}
                {loading?  <div className="loaderContainer">
            <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
                <div className={classes.flexRow} >
                   <div></div>

                    <div className={classes.button}
                    
                    disabled={verify === 0 || clickSave ? "disabled" : false}
                    onClick={createPassword}
                    >SUBMIT</div>
                    
                </div>
                
            </form>
        </div>
        </div>
        </div>
    )
}



export default VerifiedUser;