import React from 'react'
import {dataFreeWebMenu} from "./dataFreeWebsiteItem";
import "./webCard.css";
import { useHistory } from "react-router-dom";
function WebCard(props) {
    let history = useHistory();
    const goToPage=(name)=>{
        history.push("/user/register", {
            prop1: props.deploytype,
            cardName:name
          });
    }
   
    return (
        <>
        {dataFreeWebMenu?.map((item,index)=>(
            <div className="webCardMain">
                <span className="webcardtitle">{item.title}</span>
            <div>
           
            </div>
            <div className="webcardRowContent">
                <div><img src={item.logo} alt="" className="webCardLogo"></img></div>
                <div className="webcardcontent">{item.content}</div>
            </div>
            <div><button className="webcardbtn" onClick={(param)=>goToPage(item.name)}>Get Started</button></div>
        </div>
        ))}
        </>
    )
}
export default  WebCard;