import React, { useState } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import DataTable from "react-data-table-component";
import { FaCaretDown } from "react-icons/fa";
import MuiAlert from "@material-ui/lab/Alert";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});

const customStyles = {
  headCells: {
    style: {
      border: "1px solid #e7ecf1",
      borderBottom: "1px solid #111",
      fontSize: '14px',
      whiteSpace: 'normal !important',
      wordWrap: "break-word"
    },
  },
  cells: {
    style: {
      border: "1px solid #e7ecf1",
      fontSize: '14px',
      whiteSpace: 'normal !important',
      wordWrap: "break-word"
    },
  },
  title: {
    style: {
      whiteSpace: 'normal !important',
      wordWrap: "break-word"
    }
  },

};

function MaintainInvoiceData() {
  const [search, setSearch] = useState(false);
  const classes = useStyles();
  const [scroll] = useState("paper");
  const [searchDetails, setSearchDetails] = useState();
  const [openUser, setOpenUser] = useState(false);
  const [changedropdown, setChangedropdown] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [data, setData] = useState({
    mno_year: "",
    mno_month: "",
    mno_country: "",
    mno_telco: "",
  });

  const [val, setVal] = useState([
    {
      year: "", month: "", country: "", data_usage: "", invoice_amt: ""
    }
  ])
  const tablecols = [
    {
      name: "Year",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Month",
      selector: (row) => row.monthName,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => row.monthName,
      sortable: true,
    },
    {
      name: "Telco",
      selector: (row) => row.telco,
      sortable: true,
    },
    {
      name: "Data Usage (MB)",
      selector: (row) => row.dataUsageMB,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: (
        <button className="deleteRow" onClick={() => setConfirmDelete(true)}>
          Delete
        </button>
      ),
      cell: (props) => {

        return <input type="checkbox" className="checkbox" onClick={() => handleSelectedRow(props.year, props.monthNumber, props.country, props.telco)} />;
      },
    },
  ];
  const [checkBoxSelect, setcheckBoxSelect] = useState([]);
  const [clickDelete, setClickDelete] = useState(false);
  //delete Moya 
  async function MoyaDelete() {
    setClickDelete(true);
    try {
      trackPromise(
        AdminService.deleteMNOInvoice(checkBoxSelect).then((res) => {
          setClickDelete(false);
          if (res.status === 200) {
            checkBoxSelect.splice(0, checkBoxSelect.length);
            handleClose();
            dataFreeListListSearch();
            setSearch(false);
          }
        })
      );
    } catch (err) {
      setMessage("Something went wrong. Please reload the page!")
      seterrorOpen(true);
    }
  }

  const handleSelectedRow = (y, m, c, t) => {
    const body_Data = y + "_" + m + "_" + c + "_" + t;

    // checkBoxSelect.push({ myTextEditBox:body_Data });
    // setcheckBoxSelect(checkBoxSelect);

    const valsnws = checkBoxSelect.find((v) => v.myTextEditBox === body_Data);

    if (valsnws !== undefined) {
      let indexss = checkBoxSelect.map(x => {
        return x
      }).indexOf(valsnws);

      checkBoxSelect.splice(indexss, 1);
    }
    else {
      checkBoxSelect.push({ myTextEditBox: body_Data });

    }
    setcheckBoxSelect(checkBoxSelect);

  };

  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenUser(false);
    }

    dataFreeListListSearch();
    setConfirmDelete(false);
    setcheckBoxSelect([]);
    setMnoDetail(
      {
        taskList: [{
          index: Math.random(), mno_year: "", mno_month: "", mno_country: "", mno_telco: "",
          data_usage: "", invoice_amount: ""
        }]
      }

    )
    setmnoError((prevState) => (
      [{

        mnoYear: false,
        yearExist: true,
        mno_month: false,
        mno_country: false,
        mno_telco: false,
        data_usage: false,
        invoice_amount: false,
        mnoYearExist: "",
        mnoRequired: "",
        mnoIndex: ""
      }]
    ))
    setYearCheck({
      yearExist: false
    })
    setSearch(false)
  };
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpenUser(true);
    setVal([...val, { year: "", month: "", country: "", data_usage: "", invoice_amt: "" }]);
  };

  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };


  //search
  async function dataFreeListListSearch() {
    setLoaderVisible(true);
    setEmpty(false);
    setSearch(true);
    setChangedropdown(true);

    try {
      trackPromise(
        AdminService.searchMaintainInvoice(data).then((res) => {
          setLoaderVisible(false);
          setSearchDetails(res.data.applicationListResults);
          if (res.status === 200 && res.data.applicationListResults.length > 0) {

            setChangedropdown(false);

          } else if (res.status === 200 && res.data.applicationListResults.length === 0) {
            setChangedropdown(true);
            setEmpty(true);
          } else {
            setChangedropdown(true);
            setEmpty(false);
          }

        })
      );
    } catch (err) {
      setLoaderVisible(false);
      setMessage("Something went wrong. Please reload the page!")
      seterrorOpen(true);
    }
  }

  const addRow = e => {
    e.preventDefault();
    setMnoDetail((prevState) => ({
      taskList: [...prevState.taskList, { index: Math.random(), mno_year: "", mno_month: "", mno_country: "", mno_telco: "", data_usage: "", invoice_amount: "" }]
    }))

    setmnoError((prevState) => (
      [...prevState, {

        mnoYear: false,
        yearExist: true,
        mno_month: false,
        mno_country: false,
        mno_telco: false,
        data_usage: false,
        invoice_amount: false,
        mnoYearExist: "",
        mnoRequired: "",
        mnoIndex: ""
      }]
    ))

  };

  const deleteRow = (record) => {

    setMnoDetail({
      taskList: mnoDetail.taskList.filter(r => r !== record)
    });
  }

  const [mnoDetail, setMnoDetail] = useState(
    {
      taskList: [{
        index: Math.random(), mno_year: "", mno_month: "", mno_country: "", mno_telco: "",
        data_usage: "", invoice_amount: ""
      }]
    }

  )
  const mnoyearChange = (e) => {
    e.preventDefault();
    if (["mno_year", "mno_month", "mno_country", "mno_telco",
      "data_usage", "invoice_amount"].includes(e.target.name)) {
      let taskList = [...mnoDetail.taskList]
      taskList[e.target.dataset.id][e.target.name] = e.target.value
      if (e.target.dataset.id === "0" && e.target.name === "mno_year") {
        if (e.target.value) {
          setYearCheck({
            ...yearCheck,
            yearExist: false
          })
          mnoCheckExistData()
        } else {
          setYearCheck({
            ...yearCheck,
            yearExist: true
          })
          checkError();
        }

      } else {
        checkError();
      }

    }
    else {
      setMnoDetail({ [e.target.name]: e.target.value })
    }

  }

  const checkError = () => {

    for (let i = 0; i <= mnoDetail.taskList.length; i++) {
      setmnoError(mnoError.filter((newError, index) => {
        if (index === i) {
          newError.mnoYear = mnoDetail.taskList[i]?.mno_year !== "" ? false : true;
          newError.mno_month = mnoDetail.taskList[i]?.mno_month !== "" ? false : true;
          newError.mno_country = mnoDetail.taskList[i]?.mno_country !== "" ? false : true;
          newError.mno_telco = mnoDetail.taskList[i]?.mno_telco !== "" ? false : true;
          newError.data_usage = mnoDetail.taskList[i]?.data_usage !== "" ? false : true;
          newError.invoice_amount = mnoDetail.taskList[i]?.invoice_amount !== "" ? false : true
        }
        //  else if(i ===0 && mnoDetail.taskList[0].mno_year != ""){
        //    mnoCheckExistData()
        //  }
        return newError
      }))


    }
  }

  const [mnoError, setmnoError] = useState([{
    index: Math.random(),
    mnoYear: false,
    yearExist: true,
    mno_month: false,
    mno_country: false,
    mno_telco: false,
    data_usage: false,
    invoice_amount: false,
    mnoYearExist: "",
    mnoRequired: "",
    mnoIndex: ""
  }])
  const [yearCheck, setYearCheck] = useState({
    yearExist: false,
    mnoYearExist: ""
  })
  const mnoCheckExistData = () => {

    AdminService.mnoCheckExist(mnoDetail).then((res) => {

      if (res.data === 1) {
        setYearCheck({
          ...yearCheck,
          yearExist: true,
          mnoYearExist: "The value already exists"
        })
      }
      else if (res.data === 2) {
        setYearCheck({
          ...yearCheck,
          yearExist: true,
          mnoYearExist: "The value must be unique"
        })
      }
      else {
        setYearCheck({
          ...yearCheck,
          yearExist: false,

        })
        checkError();

      }
    }).catch((error) => {
      setMessage("Something went wrong. Please reload the page!")
      seterrorOpen(true);
    })

  }
  const [mnoMessage, setMnoMessage] = useState();
  const [message, setMessage] = useState();
  const [saveClick, setSaveClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const mnoAdd = () => {
    for (var i = 0; i < mnoDetail.taskList.length; i++) {
      if (mnoDetail.taskList[i].mno_year === '' || mnoDetail.taskList[i].mno_month === ''
        || mnoDetail.taskList[i].mno_country === '' || mnoDetail.taskList[i].mno_telco === ''
        || mnoDetail.taskList[i].data_usage === '' || mnoDetail.taskList[i].invoice_amount === '') {
        checkError();
      }
      else {

        if (i === mnoDetail.taskList.length - 1) {
          mnoCheckExistData();

          if (yearCheck.yearExist === false) {
            setSaveClick(true);
            setLoading(true);
            AdminService.mnoAdd(mnoDetail).then((res) => {
              if (res.status === 200) {
                setLoading(false);
                setsuccessOpen(true);
                setMnoMessage("MNO Invoice Data Added Successfully!!");
                handleClose();
              }
              setSaveClick(false);
            }).catch((error) => {
              setLoading(false);
              setMessage("Something went wrong. Please reload the page!")
              seterrorOpen(true);
            })
          }


        }
        else {
          checkError()
        }
      }
    }



  }

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Maintain MNO Invoice Reference Data</div>
        </div>
        <div className="navBoxRowFilter">
          <Grid container>
            <div className="NavBoxRowFilter_Label">
              Year
            </div>

            <Grid item xs={1} sm={1} md={1}>
              <div>
                <select type="text" className="form-control form-select img-rounded" id="mno_year" name="mno_year" onChange={(e) => lifecycleChange(e)}>
                  <option value="">select</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                </select>
              </div>
            </Grid>

            <div className="NavBoxRowFilter_Label" style={{ marginLeft: '40px' }}>
              Month
            </div>

            <Grid item xs={12} sm={1} md={1}>
              <div>
                <select type="text" className="form-control form-select img-rounded" id="mno_month" name="mno_month" onChange={(e) => lifecycleChange(e)}>
                  <option value="">select</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </Grid>

            <div className="NavBoxRowFilter_Label" style={{ marginLeft: '40px' }}>
              Country
            </div>

            <Grid item xs={12} sm={1} md={1}>
              <div>
                <select type="text" className="form-control form-select img-rounded" id="mno_country" name="mno_country" onChange={(e) => lifecycleChange(e)}>
                  <option value="">select</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="South Africa">South Africa</option>
                </select>
              </div>
            </Grid>

            <div className="NavBoxRowFilter_Label" style={{ marginLeft: '40px' }}>
              Telco
            </div>

            <Grid item xs={12} sm={2} md={2}>
              <div>
                <select type="text" className="form-control form-select img-rounded" id="mno_telco" name="mno_telco" onChange={(e) => lifecycleChange(e)}>
                  <option value="">select</option>
                  <option value="Airtel/ZAIN/Econet">Airtel/ZAIN/Econet</option>
                  <option value="Cell C">Cell C</option>
                  <option value="MTN">MTN</option>
                  <option value="Telkom">Telkom</option>
                  <option value="Vodacom">Vodacom</option>
                </select>
              </div>
            </Grid>


            <Grid item xs={12} sm={3} md={3}>
              <div className="ivitediv">
                <button
                  style={{ border: '2px solid', fontWeight: '600' , float:'left', marginLeft:'40px' }}
                  className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"} onClick={dataFreeListListSearch}>
                  Search
                </button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className="ivitediv">
                <button style={{ border: '2px solid', fontWeight: '600' }}
                  className="navRowButton" onClick={handleClickOpen}>
                  Add New Invoice Data
                </button>
              </div>
            </Grid>
          </Grid>

        </div>

        <div className="ButtonDiv">
          {
            loaderVisible ?
              <div className="loaderdata" >
                <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />

              </div> : null
          }
        </div>

        {search && !loaderVisible ? (

          !empty ? <div>

            <DataTable columns={tablecols} key={searchDetails} data={searchDetails}
              customStyles={customStyles} sortIcon={<FaCaretDown />} noDataComponent={false} />
          </div> : <DataTable columns={tablecols} key={searchDetails} data={searchDetails}
            customStyles={customStyles} sortIcon={<FaCaretDown />} />

        ) : (
          ""
        )}
      </div>
      <Dialog open={openUser} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="lg" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle">Add New Invoice Data</div>
            <div className="cmpdialogtitle">
              <button className="inviteButton" onClick={addRow}>
                Add
              </button>
            </div>
          </div>

        </DialogTitle>
        <DialogContent>
          {mnoDetail?.taskList?.map((val, idx) => {
            let mnoYear = `mno_year-${idx}`, mnoMonth = `mno_month-${idx}`, mnoCountry = `mno_country-${idx}`, mnoTelco = `mno_telco-${idx}`, dataUsage = `data_usage-${idx}`, invoiceAmount = `invoice_amount-${idx}`
            return (
              <Grid container spacing={1} key={val.index}>
                <Grid item xs={1}>
                  <div className="dialoginputtext">Year</div>
                  <select type="text" id={mnoYear} label="email" name="mno_year" data-id={idx} className={mnoError[idx]?.mnoYear ? "MnoDialoginputError" : yearCheck?.yearExist ? "MnoDialoginputError" : "MnoDialoginput"} fullWidth onChange={(e) => mnoyearChange(e)}>
                    <option value=""></option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </select>
                  {mnoError[idx]?.mnoYear ? <span className="errorClass">This field is required</span> : null}
                </Grid>

                <Grid item xs={2}>
                  <div className="dialoginputtext">Month</div>
                  <select type="text" id={mnoMonth} label="email" name="mno_month" data-id={idx} className={mnoError[idx]?.mno_month ? "MnoDialoginputError" : "MnoDialoginput"} fullWidth onBlur={(e) => mnoyearChange(e)} onChange={(e) => mnoyearChange(e)}>
                    <option value=""></option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                  {mnoError[idx]?.mno_month ? <span className="errorClass">This field is required</span> : null}
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">Country</div>
                  <select type="text" id={mnoCountry} label="email" name="mno_country" data-id={idx} className={mnoError[idx]?.mno_country ? "MnoDialoginputError" : "MnoDialoginput"} fullWidth onBlur={(e) => mnoyearChange(e)} onChange={(e) => mnoyearChange(e)}>
                    <option value=""></option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="South Africa">South Africa</option>
                  </select>
                  {mnoError[idx]?.mno_country ? <span className="errorClass">This field is required</span> : null}
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">Telco</div>
                  <select type="text" id={mnoTelco} data-id={idx} label="email" name="mno_telco" className={mnoError[idx]?.mno_telco ? "MnoDialoginputError" : "MnoDialoginput"} fullWidth onBlur={(e) => mnoyearChange(e)} onChange={(e) => mnoyearChange(e)}>
                    <option value=""></option>
                    <option value="Airtel/ZAIN/Econet">Airtel/ZAIN/Econet</option>
                    <option value="Cell C">Cell C</option>
                    <option value="MTN">MTN</option>
                    <option value="Telkom">Telkom</option>
                    <option value="Vodacom">Vodacom</option>
                  </select>
                  {mnoError[idx]?.mno_telco ? <span className="errorClass">This field is required</span> : null}
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">Data Usage (MB)</div>
                  <input type="text" id={dataUsage} data-id={idx} label="email"
                    name="data_usage" className={mnoError[idx]?.data_usage ? "MnoDialoginputError" : "MnoDialoginput"} fullWidth
                    style={{ height: '30px' }} onBlur={(e) => mnoyearChange(e)} onChange={(e) => mnoyearChange(e)}></input>
                  {mnoError[idx]?.data_usage ? <span className="errorClass">This field is required</span> : null}
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">Invoice Amount</div>
                  <input type="text" id={invoiceAmount} data-id={idx} label="email" name="invoice_amount"
                    style={{ height: '30px' }} className={mnoError[idx]?.invoice_amount ? "MnoDialoginputError" : "MnoDialoginput"} fullWidth onBlur={(e) => mnoyearChange(e)} onChange={(e) => mnoyearChange(e)} />
                  {mnoError[idx]?.invoice_amount ? <span className="errorClass">This field is required</span> : null}
                </Grid>

                <Grid item xs={1}>
                  {idx !== 0 ? (
                    <div>
                      <button className="closeIcon tops" onClick={() => deleteRow(val)}>
                        X
                      </button>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            );
          })}
          {yearCheck?.yearExist ? <span className="errorClass">{yearCheck.mnoYearExist}</span> : null}

        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>

            <button className="inviteButton" color="primary"
              disabled={saveClick ? "disabled" : false}
              onClick={mnoAdd}>
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //ConfirmDelete/// */}
      <Dialog open={confirmDelete} classes={{ scrollPaper: classes.scrollPaper }} maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="headformText">WARNING : You have requested to DELETE 1 or more MNO Invoice Details - Do you wish to continue?</div>
        </DialogTitle>
        <DialogContent>
          <div className="BtnsALign">
            <button type="text" className="inviteButton" onClick={handleClose}>
              Cancel
            </button>
            <button className="continueBtn"
              disabled={clickDelete ? "disabled" : false}
              onClick={MoyaDelete}>
              Continue with Delete
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {mnoMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default MaintainInvoiceData;