import switchLogo from "../../../../../assets/img/switch logo.jpg";
import maxLogo from "../../../../../assets/img/max logo.jpg";
export const dataFreeAppMenu = [
  {
    id: 1,
    title: "I want to ...",
    logo: switchLogo,
    content: ".... Publish my existing App #datafree with minimal redevelopment.",
    name:"switch",
    path: "",
  },
  {
    id: 2,
    title: "I want to ...",
    logo: maxLogo,
    content: "... Develop a new data-efficient, #datafree App utilising the biNu Platform.",
    name:"max",
    path: "",
  },
];
