import {FiSettings} from "react-icons/fi";
import {BsHash} from "react-icons/bs";
import {FaIndustry,FaUsers} from "react-icons/fa";
import applicationIcon from "../../../assets/img/white-app.png";
import reportingIcon from "../../../assets/img/reporting-icon.png";
import docIcon from "../../../assets/img/doc-icon.png";
import accountIcon from "../../../assets/img/account-icon.png";
import supportIcon from "../../../assets/img/support-icon.png";

export const SideNavLicensee = [
    //Development
    {
      id:1,
      title: "Development",
      collapsedIcon:<BsHash/>,
      subMenu: [
        {
          id:1,
          title: "Channel List",
          titles: "Click here to view Company List",
          icon : <FaIndustry/>,
          path: "/licensee/index/action",
        },
        {
            id:2,
            title: "Publisher List",
            titles: "Click here to view Company List",
            icon :<FaIndustry/>,
            path: "/licensee/publisher/index",
          },
          {
            id:3,
            title: "User List",
            titles: "Click here to view User List",
            icon : <FaUsers/>,
            path: "/licensee/user/index",
          },
          {
            id:4,
            title: "Applications",
            titles: "Click here to view application list",
            imageIcon :applicationIcon,
            path: "/licensee/application/index",
          },
          {
            id:5,
            title: "Reporting",
            titles: "Click here to view Reporting",
            imageIcon : reportingIcon,
            path: "https://powerbi.microsoft.com/en-us/",
          },
          {
            id:6,
            title: "Utilities",
            titles: "Click here to view Utilities",
            icon : <FiSettings/>,
            path: "/licensee/utilities/option",
          },
          {
            id:7,
            title: "Datafree Knowledge Base",
            titles: "Click here to view biNu Knowledge Base",
            imageIcon :docIcon,
            path: "https://sites.google.com/bi.nu/datafree-knowledgebase",
          },
      ],
      
    },
    //Settings
    {
      id:2,
      title: "Settings",
      collapsedIcon:<BsHash/>,
      subMenu: [
        { id:1,  imageIcon: accountIcon,title: "Account", titles: "Click here to view account details", path: "/licensee/account/detail/view" },
        { id:2, imageIcon : supportIcon,title: "Support", titles: "Example Pages", path: "https://datafr.ee/contact/" },
       
      ],
     
    },
   
  ];
  