import React, { useState, useEffect } from "react";
import "../../admin/detailViews/detailView.css";
import { useHistory } from "react-router-dom";
import Service from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import VMCTable from "../../../Recomponent/Table";
import DatePicker from "react-datepicker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { usePromiseTracker } from "react-promise-tracker";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Loader from "react-loading";

const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function AdminDeployedIpDetails(props) {
  const classes = useStyles();
  const { id } = useParams();
  const { serviceid } = useParams();
  const propsBInu = props.history.location.state
  const [serviceData, setServiceData] = useState();
  const [addHNI, setAddHNI] = useState(false);
  let history = useHistory();
  const handleClickOpen = () => {
    history.push(`/licensee/account/view/${id}/service/details`);
  };
  const [editInfo, setEditInfo] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [updateHNI, setUpdateHNI] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [tablecols] = useState([
    {
      colName: "HNI",
      colMap: "HNI",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Start",
      colMap: "start_date",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "End",
      colMap: "end_date",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Billed",
      colMap: "name",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "",
      colMap: "Edit",
      linkName: true,
      cModel: true,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]);
  const [empty, setEmpty] = useState(false);
  const getAdminDeployedIpData = () => {
    setServiceData({});
    setEmpty(false);
    try {
      trackPromise(
        Service.datafreeIpIdDetails(id, serviceid).then((res) => {
          setServiceData(res.data);

          setUpdateData({
            start_date: moment(res.data.deployedIps.startDate).format("DD/MM/YYYY"),
            end_date: moment(res.data.deployedIps.endDate).format("DD/MM/YYYY"),
            ignore_id: serviceid,
            ip_address: res.data.deployedIps.IpAddress,
            ldip_id: serviceid,
          });
          hniData['company_id'] = res.data.licenseeService.companyId.companyId;
          if (res.status === 200 && res.data.hniLists.length === 0) {
            setEmpty(true);

          } else {

            setEmpty(false);
          }
        })
      );
    } catch (err) {
      seterrorOpen(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAddHNI(false);
      setUpdateHNI(false);
    }
    setEditInfo(false);


    setHNIError({

      start_date: false,
      hni: false,
      end_date: false
    })
    setDeployDateEror({
      end_date: false,
      endDateMsg: ""
    })
  };

  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [updateEndDate, setUpdateEndDate] = useState(null);
  const [deployIpDate, setdeployIpDate] = useState(null);
  const [updateData, setUpdateData] = useState({
    start_date: "",
    end_date: "",
    ignore_id: "",
    ip_address: "",
    ldip_id: "",
  });

  const [licenseeCheckbox, setlicenseeCheckbox] = useState(false);
  const [Datafreechecked, setDatafreeChecked] = useState(false);
  const updateIpData = () => {
    if (deployedDateEror.end_date) {
      setDeployDateEror({
        ...deployedDateEror,
        end_date: true,

      })
    } else {
      trackPromise(
        Service.EditDeploymentIp(updateData).then((res) => {

          if (res.status === 200) {
            handleClose();
            setsuccessOpen(true);
            getAdminDeployedIpData();
          }
        })
      );

    }

  };

  const handleStart = (date) => {

    setStartDate(date);
    setEndDate(null);
    if (date) {
      StartDateRange(moment(date).format("DD/MM/YYYY"))

    }
    const HNIData = { ...hniData };
    HNIData['start_date'] = moment(date).format("DD/MM/YYYY");

    setHNIData(HNIData);

  };
  //DateRange
  const StartDateRange = (date) => {
    trackPromise(
      Service.AdminDateRange({ start_date: date }).then((res) => {
        if (res.status === 200 && res.data === false) {
          setHNIError({
            ...hniError,
            start_date: true,

            startRangeMsg: "MUST be greater or equal to today"
          })
        }
        else {
          setHNIError({
            ...hniError,
            start_date: false,

          })
          hniExistCheck(date)
        }
      })
    );
  }
  //Check IPAddress
  const hniExistCheck = (date_) => {
    hniData['start_date'] = date_;
    trackPromise(
      Service.HNIExists(hniData).then((res) => {
        if (res.data === true) {
          setHNIError({
            ...hniError,

            start_date: true,
            startRangeMsg: "Hni already exists within the range"
          })
        }
        else {
          setHNIError({
            ...hniError,

            start_date: false,

          })

        }
      })
    );
  }
  // define handler change function on check-out date
  const handleEnd = (date) => {
    setEndDate(date);
    const HNIData = { ...hniData };
    HNIData['end_date'] = moment(date).format("DD/MM/YYYY");

    if (date) {
      EndDateRange(moment(date).format("DD/MM/YYYY"))

    }
    setHNIData(HNIData);

  };
  //End DateRange
  const EndDateRange = (date) => {

    trackPromise(
      Service.AdminDateRange({ end_date: date }).then((res) => {
        if (res.status === 200 && res.data === false) {
          setHNIError({
            ...hniError,
            end_date: true,

            endRangeMsg: "Must be greater or equal than the start date AND MUST be greater or equal to today"
          })
        }
        else {
          setHNIError({
            ...hniError,
            end_date: false,


          })
          DateGreater(date);
        }
      })
    );
  }
  //DateGreater
  const DateGreater = (date) => {
    hniData['end_date'] = date;
    trackPromise(
      Service.CheckDateGreater(hniData).then((res) => {
        if (res.status === 200 && res.data === false) {
          setHNIError({
            ...hniError,
            end_date: true,

            endRangeMsg: "Must be greater or equal than the start date AND MUST be greater or equal to today"
          })
        }
        else {
          setHNIError({
            ...hniError,
            end_date: false,

          })

        }
      })
    );
  }

  const [hniData, setHNIData] = useState({
    HNI: "",
    dataFree_licensee: "",
    start_date: "",
    end_date: "",
    company_id: "",
    ldip_id: ""
  })
  const [hniError, setHNIError] = useState({
    hni: false,
    dataFree_licensee: false,
    start_date: false,
    end_date: false,
    licensee: false,
    hniMSG: "",
    dataFree_licenseeMsg: "",
    start_dateMSG: "",
    startRangeMsg: "",
    endRangeMsg: ""
  })
  //handelchange
  const handleHNI = (e) => {
    const newdata = { ...hniData };
    newdata[e.target.id] = e.target.value;

    newdata["ldip_id"] = serviceid;
    if (e.target.id === "HNI") {
      setHNIError({
        ...hniError,
        HNI: false,
        hniMSG: "This Field Required"
      });
    }

    setHNIData(newdata);
  }
  //add HNI
  const AddHNI = () => {
    if (hniData.HNI.length === 0 || hniData.start_date.length === 0 || hniData.dataFree_licensee.length === 0) {
      setHNIError({
        ...hniError,
        start_date: hniData.start_date.length === 0 ? true : false,
        hni: hniData.HNI.length === 0 ? true : false,
        dataFree_licensee: hniData.dataFree_licensee.length === 0 ? true : false
      })
    }
    else if (hniError.hniExistUpError || hniError.start_date || hniError.end_date) {

      setHNIError({
        ...hniError,
        start_date: hniError.start_date ? true : false,
        hni: hniError.hni ? true : false,
        end_date: hniError.end_date ? true : false
      })
    }
    else {
      trackPromise(
        Service.addHNI(hniData).then((res) => {

          if (res.status === 200) {
            handleClose();
            setsuccessOpen(true);
            getAdminDeployedIpData();
          }
        }).catch((error) => {
          seterrorOpen(true);
        })
      );
    }
  };
  const checkHandle = (e) => {
    e.preventDefault();
    if (e.target.id === "licensee") {
      setlicenseeCheckbox(!licenseeCheckbox);
      setDatafreeChecked(false);

      if (licenseeCheckbox === false) {
        hniData['dataFree_licensee'] = 1;
      }
      else {
        hniData['dataFree_licensee'] = "";
      }
      setHNIError({
        ...hniError,
        dataFree_licensee: false,

      });

    }
    else if (e.target.id === "dataFree_licensee") {
      setlicenseeCheckbox(false);
      setDatafreeChecked(!Datafreechecked);
      if (Datafreechecked === false) {
        hniData['dataFree_licensee'] = 2;
      }
      else {
        hniData['dataFree_licensee'] = "";
      }

      setHNIError({
        ...hniError,
        dataFree_licensee: false,

      });

    }
  }
  const openHNIList = (HNI_id) => {
    setUpdateHNI(false);
    GETHNI(HNI_id);
  }
  const [getHNIList, setgetHNIList] = useState()
  //get HNI
  const GETHNI = (_id) => {
    trackPromise(
      Service.getHNI({ id: _id }).then((res) => {

        if (res.status === 200) {
          setgetHNIList(res.data);
          setHNIEditData({
            start_date: res.data.start_date,
            ldip_id: res.data.ldip_id,
            HNI: res.data.hni,
            end_date: moment(res.data.end_date).format("DD/MM/YYYY"),
            hfia_id: res.data.hfia_id,



          });

        }
      }).catch((error) => {
        seterrorOpen(true);
      })
    );

  };

  const [hniExistUpError, sethniExistUpError] = useState({
    end_date: false,
    endDateMsg: ""
  })
  const [HNIEditData, setHNIEditData] = useState({
    start_date: "",
    ldip_id: "",
    HNI: "",
    end_date: "",
    hfia_id: "",

  })
  const EndDateUpdate = (date) => {
    setUpdateEndDate(date);

    const HNIData = { ...HNIEditData };
    HNIData['end_date'] = moment(date).format("DD/MM/YYYY");

    if (date) {
      hniexistUpdateCheck(moment(date).format("DD/MM/YYYY"))

    }

    setHNIEditData(HNIData);
  }
  const hniexistUpdateCheck = (date) => {
    HNIEditData['end_date'] = date;

    trackPromise(
      Service.HNIExists(HNIEditData).then((res) => {
        if (res.data === true) {
          sethniExistUpError({
            ...hniExistUpError,

            end_date: true,
            endDateMsg: "This hni is already exist"
          })
        }
        else {
          sethniExistUpError({
            ...hniExistUpError,

            end_date: false,

          })
          DateUpdateGreater(date)
        }
      })
    );
  }

  //End DateRange
  const EndDateUpdateRange = (date) => {

    trackPromise(
      Service.AdminDateRange({ end_date: date }).then((res) => {
        if (res.status === 200 && res.data === false) {
          sethniExistUpError({
            ...hniExistUpError,
            end_date: true,

            endDateMsg: "MUST be greater or equal to today"
          })
        }
        else {
          sethniExistUpError({
            ...hniExistUpError,
            end_date: false,


          })

        }
      })
    );
  }
  //DateGreater
  const DateUpdateGreater = (date) => {
    HNIEditData['end_date'] = date;
    trackPromise(
      Service.CheckDateGreater(HNIEditData).then((res) => {
        if (res.status === 200 && res.data === false) {
          sethniExistUpError({
            ...hniExistUpError,
            end_date: true,

            endDateMsg: "value must be greater than start date"
          })
        }
        else {
          sethniExistUpError({
            ...hniExistUpError,
            end_date: false,

          })
          EndDateUpdateRange(date)
        }
      })
    );
  }

  //update HNI
  const HNIEdit = () => {
    if (hniExistUpError.end_date) {
      sethniExistUpError({
        end_date: true,

      })
    }
    else {
      trackPromise(
        Service.updateHNI(HNIEditData).then((res) => {

          if (res.status === 200) {
            handleClose();
            setsuccessOpen(true);
            getAdminDeployedIpData();
          }
        }).catch((error) => {
          seterrorOpen(true);
        })
      );
    }

  };

  const [deployedDateEror, setDeployDateEror] = useState({
    end_date: false,
    endDateMsg: ""
  })
  const deployIPEndDate = (date) => {

    setdeployIpDate(date);
    const IPData = { ...updateData };

    if (date) {
      DateDeployGreater(moment(date).format("DD/MM/YYYY"))
      IPData['end_date'] = moment(date).format("DD/MM/YYYY");
    }
    else {
      DateDeployGreater("");
      IPData['end_date'] = "";
    }
    setUpdateData(IPData);
  }

  const DeployDateCheck = (date) => {
    updateData['end_date'] = date;

    trackPromise(
      Service.IpExists(updateData).then((res) => {
        if (res.data === false) {
          setDeployDateEror({
            ...deployedDateEror,

            end_date: true,
            endDateMsg: "This Ip Address is already assigned"
          })
        }
        else {
          setDeployDateEror({
            ...deployedDateEror,

            end_date: false,

          })
          DeployDateRange(date)
        }
      })
    );
  }

  //End DateRange
  const DeployDateRange = (date) => {

    trackPromise(
      Service.AdminDateRange({ end_date: date }).then((res) => {
        if (res.status === 200 && res.data === false) {
          setDeployDateEror({
            ...deployedDateEror,
            end_date: true,

            endDateGeaterMsg: "MUST be greater or equal to today"
          })
        }
        else {
          setDeployDateEror({
            ...deployedDateEror,
            end_date: false,


          })

        }
      })
    );
  }
  //DateGreater
  const DateDeployGreater = (date) => {
    updateData['end_date'] = date;
    trackPromise(
      Service.CheckDateGreater(updateData).then((res) => {
        if (res.status === 200 && res.data === false) {
          setDeployDateEror({
            ...deployedDateEror,
            end_date: true,

            endDateMsg: "value must be greater than start date"
          })
        }
        else {
          setDeployDateEror({
            ...deployedDateEror,
            end_date: false,

          })
          DeployDateCheck(date)
        }
      })
    );
  }

  useEffect(() => {
    getAdminDeployedIpData();
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="licenseHead"># Assigned Deployment IP Details</div>
      <div className="rowhead">
        <div>Service Details</div>
      </div>
      <div className="gridsp">
        <Grid container>
          <Grid item xs={2}>
            <div className="ServiceName">Service Name </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService ? serviceData?.licenseeService?.name : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="ServiceName">Description </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService ? serviceData?.licenseeService?.description : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="ServiceName">Service Type </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.service ? serviceData?.licenseeService?.service?.service : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="ServiceName">Proxy Domain </div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService ? serviceData?.licenseeService?.proxyDomain : ""} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="ServiceName">Zero Rated ?</div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.zeroRated === 1 ? "yes" : "no"} disabled="disabled"></input>
            </div>
          </Grid>

          <Grid item xs={2}>
            <div>
              <div className="ServiceName">Status</div>
              <div>
                <input type="text" className="field" value={serviceData?.licenseeService ? serviceData?.licenseeService?.status : ""} disabled="disabled"></input>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={3}>
            <div className="ServiceName">Service Config Approved</div>
            <div>
              <input type="text" className="field" value={serviceData?.licenseeService?.serviceConfigApproved== 0 ? false : true} disabled="disabled"></input>
            </div>
          </Grid> */}
        </Grid>
      </div>

      <hr className="applnslisthr" />
      <div className="fieldhead">Deployment Ip Details</div>
      <div className="deployIProw">
        <div className="fieldhead">Deployed IPs</div>
        {/* <div>
          <button className={editInfo?"invitedisabledButton":"inviteButton"} onClick={edit}
          disabled={editInfo?"disabled":false}>
            Edit Details
          </button>
        </div> */}
      </div>

      <div className="gridsp">
        <Grid container>

          <Grid item xs={5}>
            <div className="ServiceName">biNu IP ? </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.binuIp === false ? "N" : "Y"} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className="ServiceName">IP Address </div>
            <div>
              <input type="text" id="ip_address" name="ip_address" className="field"
                value={serviceData?.deployedIps?.IpAddress ? serviceData?.deployedIps?.IpAddress : ""} disabled="disabled"></input>
            </div>
          </Grid>

          {/* <Grid item xs={4}>
              {serviceData?.licenseeService?.service?.service === "CONNECT" ? (
                <div>
                  <div className="ServiceName">Licensee Deployment Type </div>
                  <div>
                    <input type="text" className="field" value={serviceData?.deployedIps?.licDepType ? serviceData?.deployedIps?.licDepType : ""} disabled="disabled"></input>
                  </div>
                </div>
              ) : null}
            </Grid> */}
          {propsBInu?.propsBInuIP ? <Grid item xs={4}>
            <div className="ServiceName">IP ID </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.ipId} disabled="disabled"></input>
            </div>
          </Grid> : editInfo ? <Grid item xs={4}>
            <div className="ServiceName">IP ID </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.ipId} disabled="disabled"></input>
            </div>
          </Grid> : null
          }
          {propsBInu?.propsBInuIP ? <Grid item xs={2}>
            <div className="ServiceName">Cloud </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.cloUd ? serviceData?.deployedIps?.cloUd : ""} disabled="disabled"></input>
            </div>
          </Grid> : editInfo ? <Grid item xs={2}>
            <div className="ServiceName">Cloud </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.cloUd ? serviceData?.deployedIps?.cloUd : ""} disabled="disabled"></input>
            </div>
          </Grid> : null
          }
          {propsBInu?.propsBInuIP ? <Grid item xs={3}>
            <div className="ServiceName">Account </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.account ? serviceData?.deployedIps?.account : ""} disabled="disabled"></input>
            </div>
          </Grid> : editInfo ? <Grid item xs={3}>
            <div className="ServiceName">Account </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.account ? serviceData?.deployedIps?.account : ""} disabled="disabled"></input>
            </div>
          </Grid> : null
          }
          {propsBInu?.propsBInuIP ? <Grid item xs={3}>
            <div className="ServiceName">Region </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.region ? serviceData?.deployedIps?.region : ""} disabled="disabled"></input>
            </div>
          </Grid> : editInfo ? <Grid item xs={3}>
            <div className="ServiceName">Region </div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.region ? serviceData?.deployedIps?.region : ""} disabled="disabled"></input>
            </div>
          </Grid> : null
          }
          {/* {propsBInu?.propsBInuIP? <Grid item xs={4}>
          <div className="ServiceName">IP Domain </div>
              <div>
                <input type="text" className="field" value={serviceData?.deployedIps?.ipDomain ? serviceData?.deployedIps?.ipDomain : ""} disabled="disabled"></input>
              </div>
            </Grid>:editInfo?<Grid item xs={4}>
            <div className="ServiceName">IP Domain </div>
              <div>
                <input type="text" className="field" value={serviceData?.deployedIps?.ipDomain ? serviceData?.deployedIps?.ipDomain : ""} disabled="disabled"></input>
              </div>
            </Grid>:null
            } */}

          <Grid item xs={3}>
            <div className="ServiceName">IP Domain</div>
            <div>
              <input type="text" className="field" value={serviceData?.deployedIps?.ipDomain ? serviceData?.deployedIps?.ipDomain : ""} disabled="disabled"></input>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className="ServiceName">Start Date</div>
            <div>
              <input type="text" className="field" id="start_date" name="start_date" value={moment(serviceData?.deployedIps?.startDate).format("DD/MM/YYYY")} disabled="disabled"></input>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="ServiceName">End Date </div>
            <div>
              {propsBInu?.propsBInuIP ? editInfo ?
                <DatePicker
                  id="end_date"
                  name="end_date"
                  className={deployedDateEror.end_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  selected={deployIpDate}

                  //value={deployIpDate?deployIpDate:serviceData?.deployedIps?.endDate?moment(serviceData?.deployedIps?.endDate).format("DD/MM/YYYY"):""} 
                  //  minDate={startDate}
                  onChange={deployIPEndDate}

                />

                :

                <input type="text" className="field" value={serviceData?.deployedIps?.endDate ? moment(serviceData?.deployedIps?.endDate).format("DD/MM/YYYY") : null} disabled="disabled" /> :
                <input type="text" className="field" value={serviceData?.deployedIps?.endDate ? moment(serviceData?.deployedIps?.endDate).format("DD/MM/YYYY") : null} disabled="disabled" />}
              {deployedDateEror.end_date ? <span className="errorClass">{deployedDateEror.endDateMsg ? deployedDateEror.endDateMsg : ""}</span> : null}
            </div>
          </Grid>
        </Grid>
        {editInfo ? (
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="inviteButton" color="primary" onClick={updateIpData}>
              Save
            </button>
            <div style={{ marginRight: "3%" }}></div>
          </div>
        ) : null}
      </div>


      <div>
        <hr className="applnslisthr" />
        {/* <div className="deployIProw">
          <div className="fieldhead">HNI Agreements</div>
          {serviceData?.licenseeService?.zeroRated == 1 ? (
            <div>
              <button className="inviteButton" onClick={HNIADD} >
               
                Add HNI Agreements
              </button>
            </div>
        ) : (
           <div>
             <button  className ="disabledHNIbutton" disabled="disabled">
               
               Add HNI Agreements
             </button>
           </div>
         )}
        </div> */}
        {serviceData && serviceData?.hniLists?.length > 0 ? (
          <VMCTable data={serviceData.hniLists} col={tablecols} tableName="Admin Licence Service Deployed" navigateFunction={openHNIList} />
        ) : <div>
          <LoadingIndicator />
          {empty ? <EmptyTable data="No HNIs agreements Found" col={tablecols} tableName="Admin Licence Service Deployed" /> : null}
        </div>}

        <div className="invitesdiv">
          <button className="inviteButton" onClick={handleClickOpen}>
            Return Licensee Service List
          </button>
        </div>
        {/* ////Add HNI  */}

        <Dialog open={addHNI} classes={{ scrollPaper: classes.scrollPaper }}

          fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <div className="FlexRow">
              <div className="cmpdialogtitle"> Add Licensee HNI Agreement</div>
              <div className="cmpdialogtitle">
                <IoMdCloseCircleOutline style={{ cursor: "pointer" }} onClick={handleClose} />
              </div>
            </div>

          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="companyLabel">HNI</div>

              </Grid>

              <Grid item xs={6}>
                <select
                  type="text"
                  id="HNI"
                  name="HNI"
                  className={hniError.hni ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  onChange={(e) => handleHNI(e)}
                  fullWidth

                >
                  <option value=""></option>
                  {serviceData && serviceData?.hniDetails?.length > 0
                    ? serviceData?.hniDetails.map((post) => (
                      <option key={post.HNI} value={post.HNI}>
                        {post.HNI}
                      </option>
                    ))
                    : null}


                </select>
                {hniError.hni ? <span className="errorClass">This Field Required.</span> : null}
              </Grid>
              <Grid item xs={4}>
                <div className="companyLabel">Licensee Billed by MNO</div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <input type="checkbox" name="licensee" id="licensee" checked={licenseeCheckbox} onChange={(e) => checkHandle(e)} />
                  <label for="websiteName" className="cmpdialoginputtext">
                    The Licensee

                  </label>

                </div>
                {hniError.dataFree_licensee ? <span className="errorClass">This Field Required</span> : null}
              </Grid>
              <Grid item xs={4}>
                <div>
                  <input type="checkbox" name="dataFree_licensee" id="dataFree_licensee"
                    onChange={(e) => checkHandle(e)} checked={Datafreechecked} />
                  <label for="websiteName" className="cmpdialoginputtext">
                    Datafree Technologies


                  </label>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="companyLabel">Start Date of Agreement</div>

              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  id="start_date"
                  name="start_date"
                  className={hniError.start_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  selected={startDate}
                  popperPlacement='bottom-start'

                  // // minDate={new Date()}
                  onChange={handleStart}
                />
                {hniError.start_date ? <span className="errorClass">{hniError.startRangeMsg ? hniError.startRangeMsg : "This Field Required"}</span> : null}
              </Grid>
              <Grid item xs={6}>
                <div className="companyLabel">End Date of Agreement</div>

              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  id="end_date"
                  name="end_date"
                  className={hniError.end_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  selected={endDate}
                  popperPlacement='top-start'
                  //  minDate={startDate}
                  onChange={handleEnd}
                />
                {hniError.end_date ? <span className="errorClass">{hniError.endRangeMsg ? hniError.endRangeMsg : ""}</span> : null}
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>
            <div className="ivitediv">
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>
              <button className="inviteButton" color="primary" onClick={AddHNI}>
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>

        {/* //Update HNI */}

        <Dialog open={updateHNI}

          classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <div className="FlexRow">
              <div className="cmpdialogtitle"> Edit Licensee HNI Agreement</div>
              <div className="cmpdialogtitle">
                <IoMdCloseCircleOutline style={{ cursor: "pointer" }} onClick={handleClose} />
              </div>
            </div>

          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="companyLabel">HNI</div>

              </Grid>

              <Grid item xs={6}>
                <input
                  type="text"

                  disabled="disabled"
                  className="disabledialoginput"
                  defaultValue={getHNIList?.hni ? getHNIList?.hni : ""}
                  fullWidth
                /></Grid>
              <Grid item xs={4}>
                <div className="companyLabel">Licensee Billed by MNO</div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <input type="checkbox" name="option-create-user"
                    disabled="disabled" id="option-create-user" checked={getHNIList?.company_id
                      === 1 ? true : false} />
                  <label for="websiteName" className="cmpdialoginputtext">
                    The Licensee

                  </label>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <input type="checkbox" name="option-create-user"
                    disabled="disabled" id="option-create-user" checked={getHNIList?.company_id
                      === 2 ? true : false} />
                  <label for="websiteName" className="cmpdialoginputtext">
                    Datafree Technologies


                  </label>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="companyLabel">Start Date of Agreement</div>

              </Grid>

              <Grid item xs={6}>
                <input
                  type="text"
                  id="startUpdate_date"
                  name="startUpdate_date"
                  disabled="disabled"
                  className="disabledialoginput"
                  defaultValue={getHNIList?.start_date ? getHNIList?.start_date : null}

                  fullWidth
                /></Grid>
              <Grid item xs={6}>
                <div className="companyLabel">End Date of Agreement</div>

              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  id="end_date"
                  name="end_date"
                  className={hniExistUpError.end_date ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  selected={updateEndDate}
                  popperPlacement='top-start'

                  placeholderText={getHNIList?.end_date ? getHNIList?.end_date : null}
                  onChange={EndDateUpdate}
                />
                {hniExistUpError.end_date ? <span className="errorClass">{hniExistUpError.endDateMsg ? hniExistUpError.endDateMsg : ""}</span> : null}
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>
            <div className="ivitediv">
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>
              <button className="inviteButton" color="primary" onClick={HNIEdit}>
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>
        <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
          <Alert onClose={handlesuccessAlert} severity="success">
            "Updated Successfully!
          </Alert>
        </Snackbar>

        <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="error">
            An Error Occured!
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
export default AdminDeployedIpDetails;
