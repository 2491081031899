import React, { useState } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import AdminService from "../../../api/adminService";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import EmptyTable from "../../../Recomponent/EmptyTable";
import VMCTable from "../../../Recomponent/Table";
import moment from "moment";
import { IoMdCloseCircleOutline } from "react-icons/io";
const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function UserList() {
  const [search, setSearch] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const classes = useStyles();
  const [empty, setEmpty] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [searchDetails, setSearchDetails] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [changedropdown, setChangedropdown] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [errMsg, setErrMsg] = useState();
  let history = useHistory();

  const [tablecols] = useState([
    {
      colName: "User’s Name",
      colMap: "name",
      linkName: true,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: true,
    },
    {
      colName: "Email Address",
      colMap: "email",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Role",
      colMap: "role",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Type",
      colMap: "userType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Verified",
      colMap: "verified",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Managed By",
      colMap: "companyIdparentCompanyIdname",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company",
      colMap: "CompanyParentJson",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Type",
      colMap: "CompanyTypeParentJson",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]);

  const [data, setData] = useState({
    searchCompanyType: "",
    searchCompanyName: "",
    searchUserName: "",
    searchEmail: "",
    searchVerified: "",
    searchStatus: "",
  });
  function userListdata() {
    setSearchDetails({});
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    try {
      trackPromise(
        AdminService.UserSearch(data).then((res) => {
          setSearchDetails(res.data.userListResult);
          if (res.status === 200 && res.data.userListResult.length > 0) {
            setChangedropdown(false);
          }
          else if (res.status === 200 && res.data.userListResult.length === 0) {
            setEmpty(true);
            setChangedropdown(false);
          }
          else {
            setChangedropdown(true);
            setEmpty(false);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page");
      seterrorOpen(true);
    }
  }
  const [userNameError, setUserNameError] = useState({
    username: false,
    msg: "",
  });
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
    setsuccessRequest(false);
  };
  const [user_id, setUser_id] = useState();
  const [company_id, setcompany_id] = useState();
  const [userDetails, setuserDetails] = useState();
  const [successRequest, setsuccessRequest] = useState(false);
  const [message, setMessage] = useState();
  const [userdata, setUserdata] = useState({
    username: "",
    user_role: "",
    user_type: "",
    user_verified: "",
    user_status: "",
    user_id: "",
    company_id: "",
  });
  //passwordReset
  async function resetPassword(EMAIL) {

    try {
      trackPromise(
        AdminService.passWordReset({ email: EMAIL, admin: "admin" }).then((res) => {
          if (res.status === 200) {
            setsuccessRequest(true);
            setMessage(res.data.message);
            handleClose();
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page");
      seterrorOpen(true);
    }
  }
  const EditUser = (e) => {
    e.preventDefault();
    const newdata = { ...userdata };
    newdata[e.target.id] = e.target.value;
    newdata["user_id"] = user_id;
    newdata["company_id"] = company_id;
    if (e.target.id === "username") {
      if (e.target.value) {
        setUserNameError({
          username: false,

        });
      } else {
        setUserNameError({
          username: true,
          msg: "This field is required",
        });
      }
    }
    setUserdata(newdata);
  };
  //getUser
  async function getUserDetail(value, Id) {
    try {
      setuserDetails({});
      trackPromise(
        AdminService.getUserById({ userId: value, companyId: Id }).then((res) => {
          setuserDetails(res.data);
          setUserdata({
            username: res.data.userName,
            user_role: res.data.role,
            user_type: res.data.type,
            user_verified: res.data.verified && res.data.verified === 1 ? "Yes" : "No",
            user_status: res.data.status,
            user_id: res.data.userId,
            company_id: res.data.companyId,
          });
          setUser_id(res.data.userId);
          setcompany_id(res.data.companyId);

        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page");
      seterrorOpen(true);
    }
  }
  //editUser Detail
  const userNameRef = React.useRef();
  async function EditUserData() {

    if (userNameError.username) {
      setUserNameError({
        username: true,
      });
      userNameRef.current.focus();
    } else {
      try {
        trackPromise(
          AdminService.editUser(userdata).then((res) => {
            if (res.status === 200) {
              userListdata();
              setsuccessOpen(true);

              setOpenUser(false);
              handleClose();
            } else {
              seterrorOpen(true);
              setOpenUser(true);
            }
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page");
        seterrorOpen(true);
      }
    }
  }
  const handlerouteLink = (companyType, companyId) => {
    if (companyType === "CUSTOMER" || "RESLR CLNT") {
      history.push(`/admin/customer/${companyId}/detail/view`);
    }
    if (companyType === "CHANNEL") {
      history.push(`/admin/channel/${companyId}/detail/view`);
    }
    if (companyType === "LICENSEE") {
      history.push(`/admin/licensee/${companyId}/detail/view`);
    }
  };

  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };

  const handleClickOpen = (scrollType, Id, companyId) => {


    getUserDetail(Id, companyId);

    setOpenUser(true);
    setScroll(scrollType);
  };
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenUser(false);
    }

    setUserdata({
      username: "",
      user_role: "",
      user_type: "",
      user_verified: "",
      user_status: "",
      user_id: "",
      company_id: "",
    })
    setUserNameError({

      username: false,
      msg: "",

    })
  };

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div  className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">User List - Admin View</div>
        </div>
        <div className="NavBoxRowFilter col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                Company Type
                </td>
                <td className="col-md-3">
                Company
                </td>
                <td className="col-md-2">
                User's Name
                </td>
                <td className="col-md-3">
                Email Address
                </td>
                <td className="col-md-1">
                Verified
                </td>
                <td className="col-md-2">
                Status
                </td>
                <td className="col-md-1">
               
                </td>
              </tr>
              <tr>
                <td className="col-md-2">
                <select type="text" className="form-control form-select" id="searchCompanyType" name="searchCompanyType"
                  onChange={(e) => lifecycleChange(e)}>
                  <option value=""></option>
                  <option value="BINU">BINU</option>
                  <option value="CHANNEL">CHANNEL</option>
                  <option value="CUSTOMER">CUSTOMER</option>
                  <option value="RESELLER">RESELLER</option>
                  <option value="LICENSEE">LICENSEE</option>
                </select>
                </td>
                <td className="col-md-3">
                <input type="text" className="form-control" id="searchCompanyName" name="searchCompanyName" onChange={(e) => lifecycleChange(e)}></input>

                </td>
                <td className="col-md-2">
                <input type="text" className="form-control" id="searchUserName" name="searchUserName" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-3">
                <input type="text" className="form-control" id="searchEmail" name="searchEmail" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-1">
                <select type="text" className="form-control form-select" onChange={(e) => lifecycleChange(e)} id="searchVerified" name="searchVerified">
                  {" "}
                  <option value=""></option>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
                </td>
                <td className="col-md-2">
                <select type="text" className="form-control form-select"
                  onChange={(e) => lifecycleChange(e)} id="searchStatus"
                 
                  name="searchStatus">
                  {" "}
                  <option value=""></option>
                  <option value="Active">Active</option>
                </select>
                </td>
                <td className="col-md-1">
                <button className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                  onClick={userListdata}>
                  Search
                </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div><hr className="applications-list-hr"></hr></div>

        </div>
       
        </div>
      
        {search ? (
          <div>
            {searchDetails && searchDetails.length > 0 ? (
              <VMCTable key={searchDetails} data={searchDetails} col={tablecols}
                navigateFunction={handlerouteLink} tabNavigation={handleClickOpen} tableName="User List" />
            ) : (
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Users Found" col={tablecols} tableName="User List" /> : null}
              </div>
            )}
          </div>
        ) : null}
      </div>
      <Snackbar open={successopen} autoHideDuration={2500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          "Updated Successfully!
        </Alert>
      </Snackbar>

      <Dialog open={openUser} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">

          <div className="FlexRow">
            <div className="cmpdialogtitle"> User Details - Admin View</div>
            <div className="cmpdialogtitle"><IoMdCloseCircleOutline style={{ cursor: "pointer" }}
            className="modalClose"
            onClick={handleClose} /></div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">Company Name:</div>
          <input type="text" id="name" label="name" disabled="disabled"
            value={userDetails ? userDetails.companyName : ""} className="form-control" fullWidth />
          <div className="dialoginputtext">Company Type:</div>
          <input type="text" id="email" label="email" disabled="disabled" className="form-control"
            value={userDetails ? userDetails.companyType : ""} fullWidth />
          <div className="dialoginputtext">User’s Name:</div>
          <input
            type="text"
            id="username"
            label="email"
            name="username"
            
            className={userNameError.username ? "errorField form-control" : "form-control"}
            defaultValue={userDetails ? userDetails.userName : ""}
            onChange={(e) => EditUser(e)}
            ref={userNameRef}
            fullWidth
          />
          {userNameError.username ? <span style={{ fontSize: "10px", color: "#c00" }}>{userNameError.msg ? userNameError.msg : "This field required"}</span> : null}
          <div className="dialoginputtext">Email Address:</div>
          <input type="text" id="email" label="email" disabled="disabled" className="form-control" value={userDetails ? userDetails.email : ""} fullWidth />
          <div className="dialoginputtext">Role:</div>
          <select type="text" id="user_role" label="email"  name="user_role" className="form-control form-select" onChange={(e) => EditUser(e)} fullWidth>
            <option value={userDetails?.role}>{userDetails?.role}</option>
          </select>
          <div className="dialoginputtext">Type:</div>
          <select type="text" id="user_type" label="email"  name="user_type" className="form-control form-select" onChange={(e) => EditUser(e)} fullWidth>
            <option value="INTERNAL" selected={userDetails?.type === "INTERNAL" && true}>INTERNAL</option>
            <option value="PUBLISHER" selected={userDetails?.type === "PUBLISHER" && true}>PUBLISHER</option>
          </select>
          <div className="dialoginputtext">Verified:</div>
          <select type="text" id="user_verified"  label="email" name="user_verified" className="form-control form-select" onChange={(e) => EditUser(e)} fullWidth>
            <option value="Yes" selected={userDetails?.verified === 1 && true}>Yes</option>
            <option value="No" selected={userDetails?.verified === 0 && true}>No</option>
          </select>
          <div className="dialoginputtext">Status:</div>
          <select type="text" id="user_status"  label="email" name="user_status" className="form-control form-select" onChange={(e) => EditUser(e)} fullWidth>
            <option value="Active" selected={userDetails?.status === "Active" && true}>Active</option>
            <option value="Deleted" selected={userDetails?.status === "Deleted" && true}>Deleted</option>
          </select>
          <div className="dialoginputtext">Created:</div>
          <input type="text" id="email" label="email" disabled="disabled" className="form-control"
            value={moment(userDetails ? userDetails.created : "").format("YYYY-MM-DD HH:mm") + " GMT"}
            fullWidth />
        </DialogContent>
        <DialogActions>
          <div className="buttonRow">
            <div>
              <button className="inviteButton" onClick={() => resetPassword(userDetails.email)} color="primary">
                Request Password Reset
              </button>
            </div>
            <div>
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>

              <button className="inviteButton" onClick={EditUserData} color="primary">
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar open={successRequest} autoHideDuration={2500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success">
          {message}
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={2500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default UserList;
