export const API_URL = process.env.REACT_APP_API_URL+"/api";

export const authRequests = {
  login: "/login_check",
  checkLoginUser:"/v1/login_check/getuser",
  verifiedLogin:"/v1/login/user",
  getchangeAccount:"/v1/multiple/companies/details",
  verifyCaptcha:"/recaptcha/verification"
};
export const userProfileRequests = {
  getUserData: "/v1/details/view",
  updateUserData:"/v1/details/edit",
  updatePassword:"/v1/password/change",
  reportDownload:"/v1/reporting/csv/download",
  userRoleSearch:"/v1/multipleuser/index/search",
  getUserRoleById:"/v1/multipleuser"
}
export const datafreeRequests = {
  getCardDataIndex: "/v1/userApplications/index",
  search: "/v1/applications/index/search",
  applicationDetails:"/v1/application",
  saveReachProd:"/v1/create/deployment/reach",
  ifAppId:"/v1/generate/appid",
  createSwitch:"/v1/create/deployment/switch",
  saveSwitcDev:"/v1/add/security/rules/development",
  editSecurity:"/v1/add/security/rules",
  addEventsNew:"/v1/add/event",
  appCreated:"/v1/check/config/created",
  popupPlatform:"/v1/useradd/configuration/binuplatform/popup",
  yesClick:"/v1/clone/config",
  noClick:"/v1/application/configuration/add",
  viewBuildApk:"/v1/generate/build/details",
  createApk:"/v1/generate/apk",
  viewBuildHistory:"/v1/generate/config/builds",
  checkConfig:"/v1/generate/builds",
  viewMore:"/v1/production/more/info",
  editApkConfig:"/v1/application/configuration/edit",
  getConfigdetails:"/v1/get/config/details",
  configLookandFeel:"/v1/application/config/lookfeel/edit",
  wrapApkConfig:"/v1/application/configuration/edit",
  getConfig:"/v1/display/config",
  configEntryPoint:"/v1/application/config/entrypoint/edit",
  configSecurityEdit:"/v1/add/security/rules/development",
  lookEdit:"/v1/application/config/lookfeel/edit",
  securityEdits:"/v1/add/security/rules/development",
  validateXml:"/v1/validate/config/xml",
  maxProdSecurityEdit:"/v1/add/security/rules",
  deleteAppRegistration:"/v1/delete/datafree/website/",
  createdApp:"/v1/check/config/created",
  buildWrap:"/v1/check/config/builds",
  bucketWrap:"/v1/copy/config/bucket",
  launchXmlStatus:"/v1/check/launch/xml/status",
  xmlValidateConfig:"/v1/validate/config/xml",
  wrapConfigCheck:"/v1/show/configs",
};
export const reportingRequests = {
  getReportByIndex: "/v1/reporting/index",
  getPeriodIndex:"/v1/reporting/period",
  reportingSearch:"/v1/userreporting/search"
};
export const gettingStartRequests = {
  gettingStartedRegister: "/v1/getstarted/app/create",
  saveReachSecutity:"/v1/add/security/rules/development/reach",
  generateAppId:"/v1/generate/appid",
  reachProdSecurity:"/v1/add/security/rules/production/reach"
};
export const utilityRequests = {
  getUtilities: "/v1/utilities/option",
  
};
export const forgettenpassword={
  forgetPassword:"/forgot/password"
}
export const accountRequests = {
  getAccountDetails: "/v1/publisher/account/user/details/view",
  editAccountDetails:"/v1/user/edit/publisher/details",
  inviteUser:"/v1/invite/user/new/users"
};
export const configRequests = {
 cloneConfig:"/v1/clone/config"
};
//Admin
export const companyListRequests = {
  getManagedList:"/v1/company/index",
  companyListsearch: "/v1/admin/publisher/index/search",
  editChannelList:"/v1/edit/channel/details",
  getChanneldetailView:"/v1/channel",
  getuserlist:"/v1/user/details/view",
  invitechannelUser:"/v1/invite/new/company/users",
  editUserDetails:"/v1/edit/user/details",
  getUserById:"/v1/get/user/details",
  inviteCompanyUser:"/v1/invite/new/company/users",
  getbinusdkListbyId:"/v1/get/binu/sdk/versions/details",
  editBinuSdk:"/v1/update/binu/sdk/version",
  getReservedIps:"/v1/get/deploymentips",
  addCompany:"/v1/add/company",
  getApplicationChannelBYLicensee:"/v1/get/company/channel/list",
  licenseeServiceIdDetails:"/v1/licensee",
  editLicenseeSevice:"/v1/edit/licensee/service",
  getdatafree_direct_application_ips_details:"/v1/datafreedirectapplication/approve",
  datafreeApprove:"/v1/datafreedirectapplication/approve",
  datafreeDirectDecommision:"/v1/datafreedirectapplication/decommission",
  addNewIp:"/v1/create/deployment/ips",
  datafreedirectApplicationIndex:"/v1/datafreedirect/application/index",
  datafreeApplicationSearch:"/v1/direct/application/search",
  datafreeAppIdDeatilView:"/v1/datafreedirect/application",
  datafreeAppCreate:"/v1/datafreedirect/application/create",
  getPublisherByChannelId:"/v1/get/company/list/channelname",
  getDeploymentType:"/v1/company/status/check",
  getDeploymentByAccount:"/v1/view/binuipdeploymentips/get",
  getProviderByDeployment:"/v1/view/binuipdeploymentips/get/cloud",
  getRegionByProvider:"/v1/binuipdeploymentips/get/data",
  getAccountByRegion:"/v1/binuipdeploymentips/get/account",
  admin_datafreedirect_application_create_route:"/v1/datafreedirect/application/create/route",
  admindatafreeGatewayDomainIndex:"/v1/datafreegatewaydomain/index",
  admindatafreeGatewayDomainSearch:"/v1/datafreegatewaydomain/detail/search",
  getCompanynameByChannelInGateway:"/v1/get/company/list/channelname/parentId",
  getapi_get_company_list_companytype:"/v1/get/company/list/companytype",
  getapi_get_licensee_point_service:"/v1/get/licensee/point/service",
  datafreegatewaySave:"/v1/datafreegatewaydomain/save",
  viewBinuIpDeployment:"/v1/binu_deployment_ips/search",
  getdatafreeGatewayDomainDetailView:"/v1/edit/datafreegatewaydomain",
  editdatafreeGatewayDomainDetailView:"/v1/datafreegatewaydomain/update",
  MNOInvoice:"/v1/admin/mno/invoice/search",
  MNOMOUSEARCH :"/v1/moya/user/search",
  companyNameCheck:"/v1/company/value/check",
  companyCheck:"/v1/company/check",
  versionSearch:"/v1/client/version/search",
  getVersionDetails:"/v1/get/client/version/details",
  updateBinuVersion:"/v1/update/client/version/detail",
  versionIndex:"/v1/binu/versions/list/index",
  versionAdd:"/v1/client/version/add",
  connectIndex:"/v1/datafreeconnect/application/index",
  // connectSearch:"/v1/connect/application/search",
  approveConfiguration:"/v1/get/approve/configuration",
  moyaDelete:"/v1/delete/moya/user/data",
  moyaAdd:"/v1/moya/add/user/data",
  maintainInvoiceDelete:"/v1/mno/invoice/delete/data",
  checkAdminServiceStatus:"/v1/check/domain/required/status",
  addLicenseeService:"/v1/create/license/service",
  adminLicenseeSeviceAppove:"/v1/licenseeservice/approve",
  updateDeploymentApis:"/v1/update/deployment/ips",
  checkEmailExist:"/v1/check/email/exist",
  passWordReset:"/v1/password/reset/admin/request",
  inviteAccount:"/v1/admin/invite/new/users",
  checkLicenseeShotCode:"/v1/shortcode/value/check",
  changeOwnerShip :"/v1/change/publisher/ownership",
  admin_check_date_greater:"/v1/check/date/greater",
  admindateRange:"/v1/check/date/range",
  ipExist:"/v1/check/ip/exists",
  addHNI:"/v1/create/hni/agreement",
  updateHNI:"/v1/edit/hni/agreement",
  checkHNIExist:"/v1/check/hni/exists",
  getHNI:"/v1/get/hni/agreement",
  mnoCheckExist:"/v1/mno/invoice/check/existing/data",
  mnoAdd:"/v1/admin/mno/invoice/save",
  moyaCheck:"/v1/moya/check/existing/data",
  convertAppSelf:"/v1/convert/prod/config",
  roleSave:"/v1/multipleuser/add",
  clientList:"/v1/reseller/client/list",
  clientExistCheck:"/v1/company/value/check",
  clientAdd:"/v1/reseller/add/client/company",
  //connect
  adminConnectLicensee:"/v1/datafreeconnect/application/create",
  getChannelName:"/v1/get/company/channel/list",
  getPublisherName:"/v1/get/company/list/channelname",
  getGatewayList:"/v1/datafreeconnect/configservergateway/list",
  getVersionList:"/v1/datafree/connect/version",
  getGatewayDescription:"/v1/get/gateway/description",
  saveConnect:"/v1/datafreeconnect/application/save",
  uploadLogo:"/v1/AwsS3Service/ImageUpload/LogoImage",
  uploadIcon:"/v1/AwsS3Service/ImageUpload/IconImage",
  uploadSplash:"/v1/AwsS3Service/ImageUpload/splashImage",
  shortCodeConnect:"/v1/get/licensee/shortcode",
  
  //connect detail view
  connectDetailIndex:"/v1/datafreeconnect/application/",
  connectEdit:"/v1/datafreeconnect/application/edit",
  connectAuth:"/v1/datafreeconnect/display/authentication",
  connectConfig:"/v1/datafreeconnect/display/config",
  connectSupportedApps:"/v1/datafreeconnect/display/supportedapps",
  ConfigSave:"/v1/datafreeconnect/application/configuration/edit",
  LookSave:"/v1/datafreeconnect/application/config/lookfeel/edit",
  AuthSave:"/v1/datafreeconnect/authentication/edit",
  SupportedAppsSave:"/v1/datafreeconnect/supported/apps/edit",
  ViewApk:"/v1/generate/build/details",
  GetPopup:"/v1/populate/build/types/popup",
  viewBuild:"/v1/generate/builds",
  viewPublish:"/v1/publish/details",
  GenerateApk:"/v1/connect/generate/apk",
  editPublish:"/v1/connect/publish/configuration",
  moreInfo:"/v1/production/more/info",
  decommisionConnect:"/v1/admin/connect/decommission",
  notifyMsg:"/v1/admin/notification/publishmessage",
  apkUrl:"/v1/download/apk",
  checkmnoExist:"/v1/mno/invoice/check/existing/data",
  connectSearch:"/v1/connect/application/index/search",
 //direct
  dataFreeAppEditRoute:"/v1/datafreedirect/application/update/route",
 //Account Manager 
  AccountIndex:"/v1/applications/accountmanager/index",
  ApplicationSearch:"/v1/application/accountmanager/index/search",
  popUp:"/v1/useradd/configuration/binuplatform/popup",
  publisherListSearch:"/v1/publisher/accountmanager/index/search",
  publisherIdDetailView:"/v1/publisher/accountmanager",
  publishergetUserDetails:"/v1/get/accountmanager/user/details",
  publishereditUserDetails:"/v1/edit/accountmanager/user/details",
  publisherEditCompanyDetails:"/v1/edit/accountmanager/publisher/details",
  publisherCompanyNameCheck:"/v1/publisher/accountmanager/edit/value/check",
  publisherPasswordReset:"/v1/password/accountmanager/reset/request",
  userListSearch:"/v1/user/accountmanager/index/search",
  accountApplicationListSearch:"/v1/application/accountmanager/index/search",
  publisherUserEmailCheck:"/v1/check/accountmanager/email/exist",
  invitePublisherUser: "/v1/invite/accountmanager/new/company/users",
  accountManagerAccountView:"/v1/account/accountmanager/details/view",
  reportingAccount:"/v1/reporting/accountmanager/index",
  reportPeriod:"/v1/reporting/accountmanager/period",
  //User Reach
  GatewayDrop:"/v1/application/create/index",
  CheckExist:"/v1/userreach/domain/check",
  showConfig :"/v1/show/configs",
  //PublisherList Warp Edit
  editWrap:"/v1/publisher/application/edit",

//Verify USer
  verifyUser:"/verify/new/user/",
  newPass:"/new/password",
  resetVerify:"/verification/code/",
  //USer Account
  CheckCompany:"/v1/user/company/value/check",
  CheckEmail:"/v1/user/check/email/exist",
  Period:"/v1/reporting/period",
  directLogo:"/v1/datafreedirect/image/save",
  reportSearch:"/v1/scheduled/report/search",
  scheduleReportSave:"/v1/create/scheduled/report",
  getAppId:"/v1/get/appId",
  deleteReport:"/v1/scheduled/report/cancel",


  companyExist:"/v1/check/company/exist",
  revenueExist:"/v1/check/revenuecode/exist",
  revenueAdd:"/v1/add/revenuetype",
  revenueSearch:"/v1/revenuetype/search",
  revenueExists:"/v1/application/revenueType/exist",
  revenueDelete:"/v1/revenuetypes/delete/data",
  revenueChange:"/v1/application/change/revenuetype"
};//licServId: 8

export const customerListRequests = {
  getcustomerList:"/v1/customer",
  editcustomerRequest:"/v1/edit/customer/details",
  invitecustomerUser:"/v1/invite/new/company/users",

  
  //Notification Portal in Admin
  notifySearch:"/v1/notification/search",
  notifyDetails:"/v1/get/notification/details",
  rangeDateTime:"/v1/check/datetime/range",
  GreaterDate:"/v1/check/datetime/greater",
  saveNotify:"/v1/notification/save",
  editNotify:"/v1/notification/edit/details",
};

export const impersonate={
  impersonateuser:"/v1/impersonation"
}
export const exitimpersonate={
  exitimpersonateuser:"/v1/exit/impersonation"
}
export const userListRequests = {
  getsearchList:"/v1/user/search",
 
};
export const accountListRequests = {
  getsaccountList:"/v1/account/details/view",
 
};
export const applicationListRequests = {
  getApplicationsearchList:"/v1/application/search",
  getapplicationIndex:"/v1/application/index"
 
};
export const binuSdkRequests = {
  getbinusdkList:"/v1/binusdkversionslist",
  seachName:"/v1/binu/sdk/versions/list/search"
};
export const licenseeListRequests = {
  getlicenseeDetailView:"/v1/licensee",
  editLicenseeDetailView:"/v1/edit/channel/details",

  revenueSearchs:"/v1/licensee/revenuetype/search",
  revenueAddds:"/v1/licensee/add/revenuetype",
  revenueCodeExist:"/v1/licensee/check/revenuecode/exist",
  revenueDeletes:"/v1/licensee/revenuetypes/delete/data",
  revenueTypeExist:"/v1/licensee/application/revenueType/exist"
  
};
//Channel
export const publisherListRequests = {
  channelListSearch:"/v1/publisher/channel/index/search",
  channelUserDetail:"/v1/user/channel/details/view",
  channelPublisherDetail:"/v1/publisher/channel",
  channelUseEdit:"/v1/edit/channel/user/details",
  getChannelUserDetails:"/v1/users/channel/details",
  editChannelDetails:"/v1/edit/channel/publisher/details",
  userDetailBYID:"/v1/get/channel/user/details",
  invitechannelNewUser:"/v1/invite/channel/new/company/users",
  addChannelPublisher:"/v1/add/channel/publisher",
  inviteAccountNewUser:"/v1/invite/channel/new/users",
  imageEdit:"/v1/AwsS3Service/ImageUpload/LogoImage",
  splashImageEdit:"/v1/AwsS3Service/ImageUpload/splashImage",
  channelIndex:"/v1/publisher/channel/index",
  applicationChannelIndex:"/v1/applications/channel/index",
  AccountEdit:"/v1/publisher/channel/companyname/edit",


  //Account Manager 

 //applicationAcManagerIndex:"/v1/applications/accountmanager/index",
 
 //Notification View
 notificationDetails:"/v1/channel/notification/details",

};

//licensee
export const userChannelListRequests = {
 versionDetails:"/v1/index/licensee/action",
 getUsersList:"/v1/user/index/channel/search",
 channelApplicationSearch:"/v1/application/channel/index/search",
 channelUtilities:"/v1/utilities/channel/option",
 accountRequest:"/v1/account/channel/details/view",
 licenseeChannelDetailView:"/v1/channel/licensee",
 licenseeChannelDetailViewEdit:"/v1/edit/licensee/channel/details",
 licenseeuseLIstsearch:"/v1/user/licensee/index/search",
 licenseeCustomeriddetailview:"/v1/customer/licensee",
 editCustomerDetail:"/v1/licensee/edit/customer/details",
 publiserlistIndex:"/v1/publisher/licensee/index",
 publisherIndexSearch:"/v1/publisher/licensee/index/search",
 inviteLicenseeUser:"/v1/invite/licensee/new/company/users",
 editLicenseeUserDetails:"/v1/edit/licensee/user/details",
 licenseegetUserDetails:"/v1/get/licensee/user/details",
 getLicenseeIndex:"/v1/channel/licensee/index",
 getapplicationListIndexLicensee:"/v1/application/licensee/index",
 getapplicationListSearchLicensee:"/v1/application/licensee/index/search",
 addLicenseeCompany:"/v1/add/licensee/company",
 licenseeUtility:"/v1/utilities/licensee/option",
 licenseeAccountDetailView:"/v1/account/licensee/details/view",
 companynamevalueCheck:"/v1/company/licensee/value/check",
 licemailCheck:"/v1/check/licensee/email/exist",
 emailRequest:"/v1/password/channel/reset/request",
 emailChannelCheck:"/v1/check/channel/email/exist",

//Channel 

ChangeStatus:"/v1/application/channel/change/status",
ChangeHealth:"/v1/application/channel/prodhealthcheck",
};

export const licenseeUserRequests = {
  getlicenseeUser:"/v1/user/licensee/details/view",
  getlicenseeIndex:"/v1/channel/licensee/index/search",
  passwordRequest:"/v1/password/licensee/reset/request",
  accountEdit:"/v1/edit/licensee/channel/details",
  inviteNew:"/v1/invite/licensee/new/company/users",
  checkMails:"/v1/check/licensee/email/exist",
  applicationList:"/v1/application/licensee/index/search",
};

