import React, { useState, useEffect } from "react";
import "./reporting.css";
import ReportingImg from "../../../../assets/img/reporting-icon-black.png";
import Service from "../../../../api/service";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoMdCloseCircle } from "react-icons/io"
import { Dialog, DialogContent, DialogTitle, makeStyles, Grid, DialogActions } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ReportSchedule() {
  const [application, setApplication] = useState();
  const classes = useStyles();
  let history = useHistory();
  const [scroll, setScroll] = useState("paper");
  const [open, setOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [reportData, setReportData] = useState({
    application_name: "",
    dataFreeAppId: "",
    dayweek: "",
  });

  //get all application
  async function ApplicationListdata() {
    try {
      trackPromise(
        Service.reportData().then((res) => {
          setApplication(res.data.applicationsListResult);

        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [successopen, setsuccessOpen] = useState(false);
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };

  const [searchDetails, setSearchDetails] = useState();
  const [reportsearch, setreportSearch] = useState(false);
  const distemail = /([a-zA-Z0-9_\-.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))/

  /// Search value change
  const lifecycleChange = (e) => {
    const newdata = { ...reportData, [e.target.id]: e.target.value };
    setReportData(newdata);
  };

  const BackReportOpen = () => {
    history.push("/user/reporting");
  }
  const handleClose = () => {
    setOpen(false)
    setDataSave({
      application_list: "",
      emaillist: "",
      dayofweek: ""
    });
    setDataSaveError({
      application_list: false,
      emaillist: false,
      dayofweek: false,
      emailMsg: "",
      webMsg: ""
    })
  }
  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };
  const [dataSave, setDataSave] = useState({
    binuId: "",
    application_list: "",
    emaillist: "",
    dayofweek: ""
  })
  const [dataSaveError, setDataSaveError] = useState({
    binuId: false,
    application_list: false,
    emaillist: false,
    dayofweek: false,
    emailMsg: "",
    webMsg: ""
  });

  /// validate report Save
  const validateReportSave = (dataSave) => {
    let count = 0;
    const nwerror = { ...dataSaveError };

    if (dataSave.binuId === "") {
      nwerror.binuId = true;
      count++;
    } else {
      nwerror.binuId = false;
    }
    if (dataSave.emaillist === "") {
      nwerror.emaillist = true;
      nwerror.emailMsg = "This field is required";
      count++;
    } else {
      if (distemail.test(dataSave.emaillist) === false) {
        nwerror.emaillist = true;
        nwerror.emailMsg = "Warning: No message defined for emaillist";
        count++;
      }
      else {
        nwerror.emaillist = false;
        nwerror.emailMsg = "";
      }

    }
    if (dataSave.dayofweek === "") {
      nwerror.dayofweek = true;
      count++;
    } else {
      nwerror.dayofweek = false;
    }

    if (dataSave.application_list === "") {
      nwerror.application_list = true;
      count++;
    } else {
      nwerror.application_list = false;
    }
    setDataSaveError(nwerror);
    return count;
  }

  const [reportExist, setReportExist] = useState(false);
  const [clickSave, setClickSave] = useState(false)
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [toastMsg, setToastMsg] = useState(false);
  /// Report Save Click 
  const sheduleReportSave = () => {
    const nCount = validateReportSave(dataSave);

    if (nCount === 0) {
      setClickSave(true);
      setLoadings(true);
      try {
        Service.scheduleReportSave(dataSave).then((res) => {

          if (res.data.status === "success") {
            setToastMsg("Schedule Report Added Successfully!")
            setClickSave(false);
            setLoadings(false);
            setsuccessOpen(true);
            handleClose();
          }
          else { 
            
            setClickSave(false);
            setLoadings(false);
            handleClose();
           
          }
        })

      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
        
      }
    }
  }

  // Get AppId on appname Change
  const getAppId = (Id) => {
    Service.getAppId({ applicationId: Id }).then((res) => {
      const newdata = { ...dataSave };
      newdata["application_list"] = Id;
      document.getElementById("binuId").value = res.data;
      newdata['binuId'] = res.data;
      setDataSave(newdata);
    }).catch((err) => {

    })
  }

  const changeHandler = (e) => {
    const newdata = { ...dataSave }
    newdata[e.target.id] = e.target.value

    if (e.target.id === "dayofweek") {
      if (e.target.value) {
        setDataSaveError({
          ...dataSaveError,
          dayofweek: false
        })
      }
      else {
        setDataSaveError({
          ...dataSaveError,
          dayofweek: true
        })
      }
    }
    if (e.target.id === "application_list") {
      getAppId(e.target.value);
      if (e.target.value) {
        setDataSaveError({
          ...dataSaveError,
          application_list: false
        })
      }
      else {
        setDataSaveError({
          ...dataSaveError,
          application_list: true
        })
      }
    }
    if (e.target.id === "emaillist") {
      if (e.target.value) {
        if (distemail.test(e.target.value) === false) {
          setDataSaveError({
            ...dataSaveError,
            emaillist: true,
            emailMsg: "Warning: No message defined for emaillist"
          })
        } else {
          setDataSaveError({
            ...dataSaveError,
            emaillist: false,
            emailMsg: ""
          })
        }
      }
      else {
        setDataSaveError({
          ...dataSaveError,
          emaillist: true,
          emailMsg: "This field is required"
        })
      }
    }
    setDataSave(newdata);
  }

  // Report Search
  const searchScheduleReport = () => {
    setLoading(true);
    setreportSearch(true);
    setSearchDetails({});
    Service.reportSearch(reportData).then((res) => {
      setLoading(false);
      setSearchDetails(res.data)
      
    }).catch((err) => {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    });
  }

  const cancelReport = (Id) => {
    Service.deleteReport({ reportId: Id }).then((res) => {
      searchScheduleReport();
    }).catch((err) => {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    });
  }

  useEffect(() => {
    ApplicationListdata();
    document.title = "Datafree Portal";
  }, []);

  return (
    <>
      <div className="reportingView">
        <div className="reportpageView">
          <div className="reportNav">
            <div className="reportingtextview">
              <div className="reportingtextTop">
                <div>
                  <img
                    src={ReportingImg}
                    alt=""
                    className="reportheadIcon"
                  ></img>
                </div>
                <div className="reportheadToptext">Scheduled Reporting</div>
              </div>
            </div>
          </div>
          <div style={{ margin: "25px" }}>
            <div className="reportCard">
              <div className="reportcardView">

                <div className="alignRight">
                  <button
                    type="submit"
                    className="runbutton"
                    onClick={BackReportOpen}
                  >
                    Return to online Reporting
                  </button>

                </div>
                <Grid container>
                  <Grid item xs={12} sm={3} md={3}>
                    <div className="labelfield">
                      <label className="rrlabel">Application</label>
                      <select
                        className="form-control form-select"
                        id="application_name"
                        name="application_name"
                        defaultValue="All Apps"
                        onChange={(e) => lifecycleChange(e)}
                      >
                        <option value=""></option>
                        {application && application.length > 0
                          ? application.map((post) => (
                            <option key={post.appId} value={post.appId}>
                              {post.applicationId.name}
                            </option>
                          ))
                          : null}
                      </select>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <div className="labelfield">
                      <label className="rrlabel">Datafree App ID</label>
                      <input
                        type="text"
                        className="form-control"
                        name="dataFreeAppId"
                        id="dataFreeAppId"
                        style={{ borderRadius: '6px !important' }}
                        onChange={(e) => lifecycleChange(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <div className="labelfield">
                      <label className="rrlabel">Day of Week</label>
                      <select
                        className="form-control form-select"
                        defaultValue=""
                        id="dayweek"
                        name="dayweek"
                        onChange={(e) => lifecycleChange(e)}
                      >
                        <option value=""></option>
                        <option value="monday">Monday</option>
                        <option value="tuesday">Tuesday</option>
                        <option value="wednesday">Wednesday</option>
                        <option value="thursday">Thursday</option>
                        <option value="friday">Friday</option>
                        <option value="saturday">Saturday</option>
                        <option value="sunday">Sunday</option>
                      </select>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <label className="rrlabel"></label>
                    <div><button
                      type="submit"
                      className="defultbtn"
                      onClick={() => searchScheduleReport()}
                    >
                      Search
                    </button></div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="alignRight">
                      <button
                        type="submit"
                        className="runbutton"
                        onClick={() => handleClickOpen('body')}
                      >
                        Shedule New Report
                      </button>
                    </div>
                  </Grid>
                </Grid>

              </div>
            </div>

            {/* sheduleReport Search */}
            {loading ? <div className="loaderContainer">
              <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}

            {reportsearch && !loading ?

              <div className="user-reporting-search-div">
                <div className="index-application-report-view-main-div">
                  <div className="reportsMainTable">
                    <div className="table-responsive">
                      <table className="table table-bordered table-sm table-striped user_application_report_table">
                        <thead className="">
                          <tr>
                            <th>Rep ID</th>
                            <th>Application</th>
                            <th>Datafree App ID</th>
                            <th>Distribution List</th>
                            <th>DOW</th>
                            <th>Created</th>
                            <th>Cancel</th>

                          </tr>
                        </thead>
                        <tbody>

                          {searchDetails && searchDetails?.scheduledReportSearch?.length > 0 ? searchDetails?.scheduledReportSearch?.map((item, index) => (

                            <tr>
                              <td>{item?.report_id}</td>
                              <td>{item?.name}</td>
                              <td>{item?.app_id}</td>
                              <td>{item?.distribution_email}</td>
                              <td>{item?.day_of_week}</td>
                              <td>{item?.created}</td>
                              <td>
                                <IoMdCloseCircle style={{
                                  color: "red", fontSize: "25px", cursor: 'pointer',
                                  textAlign: "center", marginLeft: "22px", fontWeight: '600'
                                }}
                                  onClick={() => cancelReport(item?.report_id)} />
                              </td>
                            </tr>
                          )) : <tr><td colSpan={"20"}>No Results Found</td></tr>}
                        </tbody>
                      </table>
                    </div></div></div></div> : null}
          </div>
        </div>

        {/* shedule New Report Model */}

        <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }}
          fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
            <div className="cmpdialogtitle">Schedule Report
              <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <div className="labelfield btnmargin">
                  <label className="rrlabel">Application</label>
                  <select
                    className={dataSaveError?.application_list ? "fieldtext errEdit" : "fieldtext"}
                    id="application_list"
                    name="application_list"
                    onChange={(e) => changeHandler(e)}
                  >
                    <option value=""></option>
                    {application && application?.length > 0
                      ? application.map((post) => (
                        <option key={post.applicationId.applicationId} value={post.applicationId.applicationId}>
                          {post.applicationId.name}
                        </option>
                      ))
                      : null}
                  </select>
                  {dataSaveError.application_list ? (
                    <span style={{ float: 'left' }} className="errormsg topAlign">This field is required</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="labelfield btnmargin">
                  <label className="rrlabel">Datafree App ID</label>
                  <input
                    type="text"
                    className="applicationtext"
                    name="binuId"
                    id="binuId"
                    disabled="disabled"
                  />

                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="labelfield btnmargin">
                  <label className="rrlabel">Distribution List (comma delimited email list)</label>
                  <input
                    type="text"
                    className={dataSaveError?.emaillist ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
                    name="emaillist"
                    id="emaillist"
                    onChange={(e) => changeHandler(e)}


                  />
                  {dataSaveError.emaillist ? (
                    <span style={{ float: 'left' }} className="errormsg topAlign">{dataSaveError?.emailMsg ? dataSaveError?.emailMsg : ""}</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="labelfield btnmargin">
                  <label className="rrlabel">Day of Week (that the report will be return)</label>
                  <select
                    className={dataSaveError?.dayofweek ? "fieldtext errEdit" : "fieldtext"}
                    id="dayofweek"
                    name="dayofweek"
                    onChange={(e) => changeHandler(e)}
                  >
                    <option value=""></option>
                    <option value="monday">Monday</option>
                    <option value="tuesday">Tuesday</option>
                    <option value="wednesday">Wednesday</option>
                    <option value="thursday">Thursday</option>
                    <option value="friday">Friday</option>
                    <option value="saturday">Saturday</option>
                    <option value="sunday">Sunday</option>
                  </select>
                  {dataSaveError.dayofweek ? (
                    <span style={{ float: 'left' }} className="errormsg topAlign">This field is required</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {loadings ? <div className="loaderContainer">
              <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
            <div className="ivitediv">
              <button className="inviteButton redBtn" onClick={handleClose} >
                Cancel
              </button>

              <button className="inviteButton"
                disabled={clickSave ? "disabled" : false}
                color="primary" onClick={sheduleReportSave}>
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>

        {/* shedule New Report already exists Model */}
        <Dialog open={reportExist}
          classes={{ scrollPaper: classes.scrollPaper }}
          fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
          aria-labelledby="form-dialog-title">
          <DialogContent className="diaContent">
            <div className="logoConfirmtext">Report for this app already exist</div>
          </DialogContent>
          <DialogActions>
            <div className="popupbutton">
              <button className="popupOk" name="popup" color="primary" onClick={() => setReportExist(false)}>
                Cancel
              </button>
            </div>
          </DialogActions>
        </Dialog>


        <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
          <Alert onClose={handlesuccessAlert} severity="success">
            {toastMsg ? toastMsg : "Added Successfully!"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={erroropen}
          autoHideDuration={1500}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity="error">
            {errMsg ? errMsg : "An Error Occured!"}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
export default ReportSchedule;
