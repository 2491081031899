const Style = {
  paper: {
    // display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    backgroundColor: "#364150",
    overflowX: "hidden",
    overflowY: "scroll",
  },
  subLogoImg: {
    color:"#0094cd",
    fontWeight: "700",
    marginTop: "20px",
    marginbottom: "10px",
    fontSize: "36px"
  },
  alertsSuccess:{
    backgroundColor: "#dff0d8",
    borderColor: "#abe7ed",
    color: "#27a4b0",
    padding: "18px 12px",
    width: "94%",
    margin: "0px",
    fontSize:"15px",
  },
  alertsError:{
    backgroundColor: "#f3b3b3",
    borderColor: "#cf7e7e",
    color: "#e92c2c",
    padding: "18px 12px",
    width: "94%",
    margin: "0px",
    fontSize:"15px",
  },
  texterror: {
    // display: "block",
    color: "#cc0000 !important",
   
   
    fontSize: "11px",
  },
  privacyText: {
    display: "inline",
    fontSize: "14px",
  },
  outer: {
    marginTop: "7vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    color: "#FFFFFF",
    backgroundColor: "#32c5d2",
    borderColor: "#32c5d2",
    Display: "inline - block",
    fontWeight: 400,
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    border: "1px solid transparent",
    whiteSpace: "nowrap",
    padding: "10px 20px",
    marginBottom: 0,
  },
  popupTitle:{
    fontSize: "14px"
  },
  buttonDisable: {
    color: "#FFFFFF",
    backgroundColor: "#32c5d2",
    borderColor: "#32c5d2",
    Display: "inline - block",
    fontWeight: 400,
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "none",
    border: "1px solid transparent",
    whiteSpace: "nowrap",
    padding: "10px 20px",
    marginBottom: 0,
  },
  disablebtn:{
    color: "#666666",
    backgroundColor: "#cccccc",
    borderColor: "#999999",
    Display: "inline - block",
    fontWeight: 400,
    textAlign: "center",
    verticalAlign: "middle",
    PointerEvent: "none",
    border: "1px solid #999999",
    whiteSpace: "nowrap",
    padding: "10px 20px",
    marginBottom: 0,
    cursor:'none'
  },
  disabledbutton: {
    color: "#666666",
    backgroundColor: "#cccccc",

    Display: "inline - block",
    fontWeight: 400,
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    border: "1px solid #999999",
    whiteSpace: "nowrap",
    padding: "10px 20px",
    marginBottom: 0,
  },
  Backbutton: {
    color: "#32c5d2",
    backgroundColor: "#ffffff",
    borderColor: "#32c5d2",
    Display: "inline - block",
    fontWeight: 400,
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    border: "1px solid transparent",
    whiteSpace: "nowrap",
    padding: "10px 20px",
    marginBottom: 0,
    "&:hover": {
      background: "#32c5d2",
      color: "#ffffff",
    },
  },
  forgetLink: {
    color: "#337ab7",
    textDecoration: "none",
    fontSize: "14px",
    float: "right",
    display: "inline-block",
    marginTop: "10px",
    cursor: "pointer",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
    width:"400px",
    paddingTop: "10px",
    backgroundColor: "#FFF",
  },
  scrollPaper:{
    width: "600px",
    display: "block",
    margin: "30px auto"
  },
  forgetBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
    paddingTop: "10px",
    backgroundColor: "#FFF",
    width: "400px",
  },
  forgetlabel: {
    margin: "0 0 10px",
    fontSize: "14px",
    marginBottom: "12px",
  },
  signupbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    paddingTop: "10px",
    backgroundColor: "#FFF",
    width: "368px",
  },
  headText: {
    color: "#4db3a5",
    textAlign: "center",
    fontSize: "28px",
    fontWeight: 400,
    marginBottom: "25px",
  },
  form: {
    width: "90%",
    marginTop: 10,
  },
  textField: {
    backgroundColor: "#dde3ec",
    height: "43px",
    width: "95%",
    
    padding: "0px 10px",
    marginTop: "15px",
    outline: "none",
    border: "1px solid #dde3ec",
  },
    texterrorField: {
    backgroundColor: "#edd !important",
    border: "1px solid #c00 !important",
    height: "43px",
    width: "95%",
    padding: "0px 10px",
    marginTop:"10px",
    outline: "none",
  },
  popupEnter:{
    width: "100%",
    margin: "12px 0px 12px 0px",
    overflow:"auto",
    borderCollapse: "collapse",
    borderSpacing: 0 ,
  },
  popupHead: {
    textAlign: "left"
  },
  popupRow:{
    height: "48px",
    borderBottom: "1px solid #e5e5e5"
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    border: "0px",
    padding: "25px 30px 25px 30px",
    marginLeft: "-30px",
    marginRight: "-30px",
    justifyContent: "space-between",
  },
  createAccount: {
    backgroundColor: "#6c7a8d",
    fontweight: 600,
    fontSize: "14px",
    color: "#c3cedd",
    padding: "20px 0px",
    textAlign: "center",
    width: "100%",
    display: "inline-block",
    cursor: "pointer",
  },
  datafreelogocls: {
    height: "100px",
    verticalAlign: "middle",
  },
  "@media (max-width: 500px)": {
    datafreelogocls: {
      height: "50px",
      verticalAlign: "middle",
    },
  },
};
export default Style;
