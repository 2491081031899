import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import adminService from "../../../api/adminService";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
  snackbarClass: {
    backgroundColor: "white",
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AdminDatafreeDirectApplication() {
  const { appId } = useParams()
  const [data, setData] = useState();
  const classes = useStyles();
  let history = useHistory();
  const [editInfo, setEditInfo] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [message, setMessage] = useState();
  const [state] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const handleAlertClose = () => {
    seterrorOpen(false);

  };
  const [datas, setDatas] = useState(
    {
      app_name: "",
      report_aggregation_tag: "",
      high_data_threshold: "",
      monthly_data_alert_limit: "",
      alert_email_address: "",
      max_byte_per_user: "",
      include_daily_dataFree_limit_connect_direct: "",
      max_byte_per_day: "",
      action_required: "",
      protocol_row_count: "",
      protocol_row_count_disable: "",
      protocol_row_count_cancel: "",
      applicationId: "",
      deploymentId: "",
      forward_address: "",
      protocol: [],
      start_port_range: [],
      end_port_range: [],
    }
  );
  const [portError, setPortError] = useState([{
    protocol: false,
    start_port_range: false,
    end_port_range: false,
    startRange: "",
    endRange: "",
    portmsg: "",

  }])

  const [portVal, setPortVal] = useState([
    {
      protocol: "",
      start_port_range: "",
      end_port_range: "",
    }
  ])
  const { vertical, horizontal } = state;
  //Datafree Id Detail
  async function getDetailById() {
    try {
      trackPromise(
        AdminService.datafreeAppIdDetailView(appId).then((res) => {
          setData(res.data);

          const temp = {};
          const port1 = res.data?.portConfigs?.map(prt => prt.portProtocol);
          temp.protocol = port1.map(prt => prt.protocol);

          temp.start_port_range = res.data?.portConfigs?.map(start => start.portStart === null ? "" : start.portStart);

          temp.end_port_range = res.data?.portConfigs?.map(end => end.portEnd === null ? "" : end.portEnd);

          for (let i = 0; i < res.data?.portConfigs.length - 1; i++) {
            setPortError((prevState) => (
              [...prevState, {

                protocol: false,
                start_port_range: false,
                end_port_range: false,
                startRange: "",
                endRange: "",
                portmsg: "",
              }]
            ))
          }

          for (let i = 0; i < res.data?.portConfigs.length - 1; i++) {
            setPortVal((prevState) => (
              [...prevState, {

                protocol: "",
                start_port_range: "",
                end_port_range: "",

              }]
            ))
          }
          setDatas({
            ...temp,
            app_name: res.data?.applicationDetail.name,
            report_aggregation_tag: "",
            high_data_threshold: res.data?.applicationDetail.ipHighDataThreshold === null ? "" : res.data?.applicationDetail.ipHighDataThreshold,
            monthly_data_alert_limit: res.data?.applicationDetail.appDataUsageAlert === null ? "" : res.data?.applicationDetail.appDataUsageAlert,
            alert_email_address: res.data?.applicationDetail.alertEmail === null ? "" : res.data?.applicationDetail.alertEmail,
            max_byte_per_user: res.data?.applicationDetail.maxBytesPerUser === null ? "" : res.data?.applicationDetail.maxBytesPerUser,
            include_daily_dataFree_limit_connect_direct: "",
            max_byte_per_day: res.data?.applicationDetail.maxBytesPerDay === null ? "" : res.data?.applicationDetail.maxBytesPerDay,
            action_required: res.data?.applicationDetail.maxBytesPerDayAction === null ? "" : res.data?.applicationDetail.maxBytesPerDayAction,
            protocol_row_count: res.data?.portConfigs?.length,
            protocol_row_count_disable: res.data?.portConfigs?.length - res.data?.protocol?.length,
            protocol_row_count_cancel: res.data?.portConfigs?.length,
            forward_address: res.data?.applicationDetail.directIpForward === null ? "" : res.data?.applicationDetail.directIpForward,
            applicationId: res.data?.applicationDetail.applicationId === null ? "" : res.data?.applicationDetail.applicationId,
            deploymentId: res.data?.applicationDeploymentDetail.deploymentId === null ? "" : res.data?.applicationDeploymentDetail.deploymentId,
          });

        })
      );
    } catch (err) {

    }
  }
  const [scroll, setScroll] = useState("paper");
  const [approve, setApprove] = useState(false);
  const [decommision, setDecommision] = useState(false);
  const ApproveConfig = (scrollType) => {
    setApprove(true);
    setScroll(scrollType);
  }
  const DecommisionConfig = (scrollType) => {

    setDecommision(true);
    setScroll(scrollType);
  }
  useEffect(() => {
    getDetailById();
  }, []);
  const [serviceId] = useState({
    applicationId: "",
    app_id: "",
    app_type: "DIRECT"
  });
  const [openApprove, setOpenApprove] = useState(false);
  const [approveError, setApproveError] = useState();
  const approveService = () => {
    serviceId["applicationId"] = appId;
    serviceId["app_id"] = data?.applicationDeploymentDetail?.appId;
    try {
      trackPromise(
        adminService.approveDatafree(serviceId).then((res) => {
          if (res.status === 200) {
            setApprove(false);
            setOpenApprove(true);

            setApproveError(JSON.stringify(res.data));
          }
        })
      );
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 500) {
        setMessage("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }
  };
  const [decommisionData] = useState({
    applicationId: "",
    deploymentId: ""
  })
  const Decommision = () => {
    decommisionData["applicationId"] = appId;
    decommisionData["deploymentId"] = data.applicationDeploymentDetail.deploymentId;
    try {
      trackPromise(
        adminService.datafreeDecommision(decommisionData).then((res) => {
          if (res.status === 200) {
            setDecommision(false);
            getDetailById();
          }
        })
      );
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 500) {
        setMessage("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }
  }
  const handleApproveAlertClose = () => {
    setOpenApprove(false);
  };
  const handleClose = () => {
    setApprove(false);

    setDecommision(false);

  }
  const route = () => {
    setEditInfo(false);
    document.getElementById("EditDirect").style.visibility = "visible";
    setError({
      app_name: false,
      high_data_threshold: false,
      monthly_data_alert_limit: false,
      alert_email_address: false,
      max_byte_per_user: false,
      max_byte_per_day: false,
      forward_address: false,
      msg: "",
      appmsg: "",
      emailmsg: "",
      forwardmsg: "",
      monthlymsg: "",
      bytedaymsg: "",
      byteusermsg: "",
      thresholdmsg: "",
    });
    setPortError([{
      protocol: false,
      start_port_range: false,
      end_port_range: false,
      startRange: "",
      endRange: "",
      portmsg: "",
    }]);
    setPortVal([{
      protocol: "",
      start_port_range: "",
      end_port_range: "",
    }]);
    getDetailById();

  }
  const EditClick = () => {
    setEditInfo(true);
    document.getElementById("EditDirect").style.visibility = "hidden";
  }
  const [checked, setChecked] = useState(false);
  const returnList = () => {
    history.push("/admin/datafreedirect/application/index")
  }
  const emailReg = /[a-zA-Z0-9_\-.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$)/;

  const appReg = /[,]/;
  const forwardReg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const high_data_thresholdReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const max_byte_per_dayReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const max_byte_per_userReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const monthly_data_alert_limitReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const portRange = /^(0|[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
  const [error, setError] = useState({
    app_name: false,
    high_data_threshold: false,
    monthly_data_alert_limit: false,
    alert_email_address: false,
    max_byte_per_user: false,
    max_byte_per_day: false,
    forward_address: false,
    msg: "",
    appmsg: "",
    emailmsg: "",
    forwardmsg: "",
    monthlymsg: "",
    bytedaymsg: "",
    byteusermsg: "",
    thresholdmsg: "",
  })

  const handleChange = (e) => {
    e.preventDefault();
    const newdata = { ...datas };
    newdata[e.target.id] = e.target.value;

    setDatas(newdata);
  }
  const nameRef = React.useRef();
  const forwardRef = React.useRef();
  const emailRef = React.useRef();
  const thresholdRef = React.useRef();
  const byteUserRef = React.useRef();
  const byteDayRef = React.useRef();
  const monthlyRef = React.useRef();

  const validateData = (datas) => {
    let error = {};
    let msg = "This field is required."
    let count = 0;
    if (datas.app_name === "") {
      error.app_name = true;
      error.appmsg = msg;
      count++;
      nameRef.current.focus();
    }
    else {
      if (appReg.test(datas.app_name) === true) {
        error.app_name = true;
        error.appmsg = "Please enter a name without comma";
        count++;
        nameRef.current.focus();
      }
      else {
        error.app_name = false;
        error.appmsg = "";
      }
    }

    if (datas.forward_address === "") {
      error.forward_address = true;
      error.forwardmsg = msg;
      count++;
      forwardRef.current.focus();
    }
    else {
      if (forwardReg.test(datas.forward_address) === false) {
        error.forward_address = true;
        error.forwardmsg = "Invalid IP address";
        count++;
        forwardRef.current.focus();
      }
      else {
        error.forward_address = false;
        error.forwardmsg = "";
      }
    }


    if (datas.alert_email_address !== "") {
      if (emailReg.test(datas.alert_email_address) === false) {
        error.alert_email_address = true;
        error.emailmsg = "Please enter a valid email address.";
        count++;
        emailRef.current.focus();
      }
      else {
        error.alert_email_address = false;
        error.emailmsg = "";
      }
    }
    else {
      error.alert_email_address = false;
      error.emailmsg = "";
    }

    if (datas.high_data_threshold !== "") {
      if (high_data_thresholdReg.test(datas.high_data_threshold) === false) {
        error.high_data_threshold = true;
        error.thresholdmsg = "Invalid Format.";
        count++;
        thresholdRef.current.focus();
      }
      else {
        error.high_data_threshold = false;
        error.thresholdmsg = "";
      }
    }
    else {
      error.high_data_threshold = false;
      error.thresholdmsg = "";
    }

    if (datas.max_byte_per_user !== "") {
      if (max_byte_per_userReg.test(datas.max_byte_per_user) === false) {
        error.max_byte_per_user = true;
        error.byteusermsg = "Invalid Format.";
        count++;
        byteUserRef.current.focus();
      }
      else {
        error.max_byte_per_user = false;
        error.byteusermsg = "";
      }
    }
    else {
      error.max_byte_per_user = false;
      error.byteusermsg = "";
    }

    if (datas.monthly_data_alert_limit !== "") {
      if (monthly_data_alert_limitReg.test(datas.monthly_data_alert_limit) === false) {
        error.monthly_data_alert_limit = true;
        error.monthlymsg = "Invalid Format.";
        count++;
        monthlyRef.current.focus();
      }
      else {
        error.monthly_data_alert_limit = false;
        error.monthlymsg = "";
      }
    }
    else {
      error.monthly_data_alert_limit = false;
      error.monthlymsg = "";
    }

    if (datas.max_byte_per_day !== "") {
      if (max_byte_per_dayReg.test(datas.max_byte_per_day) === false) {
        error.max_byte_per_day = true;
        error.bytedaymsg = "Invalid Format.";
        count++;
        byteDayRef.current.focus();
      }
      else {
        error.max_byte_per_day = false;
        error.bytedaymsg = "";
      }
    }
    else {
      error.max_byte_per_day = false;
      error.bytedaymsg = "";
    }



    for (let i = 0; i <= datas.protocol.length; i++) {
      setPortError(portError.filter((newError, index) => {
        if (index === i) {

          if (datas.protocol[i] !== '') {
            newError.protocol = false;
          }
          else {
            newError.protocol = true;
            count++;
          }


          if (datas.start_port_range[i] !== '') {

            if (portRange.test(datas.start_port_range[i]) === false) {
              newError.start_port_range = true;
              newError.startRange = "Please enter a value between 1 and 65535.";
              count++;
            }
            else {
              newError.start_port_range = false;
              newError.startRange = "";
            }
          }
          else {

            if (datas.protocol[i] === "GRE") {
              newError.start_port_range = false;
              newError.startRange = "";
            }
            else {
              newError.start_port_range = true;
              newError.startRange = "This field is required.";
              count++;
            }
          }

          if (datas.end_port_range[i] !== '') {

            if (portRange.test(datas?.end_port_range[i]) === false) {
              newError.end_port_range = true;
              newError.endRange = "Please enter a value between 1 and 65535.";
              count++;
            } else {
              newError.end_port_range = false;
              newError.endRange = "";
            }
          }
          else {
            if (datas.protocol[i] === "GRE") {
              newError.end_port_range = false;
              newError.endRange = "";
            }
            else {
              newError.end_port_range = true;
              newError.endRange = "This field is required.";
              count++;
            }
          }

        }
        return newError;
      }))



    }
    setError(error);
    return count;
  }

  const deleteRow = (idx) => {
    const newPort = [...datas.protocol];
    newPort.splice(idx, 1);
    const newStart = [...datas.start_port_range];
    newStart.splice(idx, 1);
    const newEnd = [...datas.end_port_range];
    newEnd.splice(idx, 1);
    setDatas({
      ...datas,
      protocol: newPort,
      start_port_range: newStart,
      end_port_range: newEnd,
    })
    const temps = [...portError];
    temps.splice(idx, 1);
    setPortError(temps);

    const temp1 = [...portVal];
    temp1.splice(idx, 1);
    setPortVal(temp1);
  }
  const handlePort = (e, index) => {

    if (["protocol"].includes(e.target.name)) {
      let taskList1 = [...datas.protocol]
      taskList1[e.target.dataset.id] = e.target.value
      setDatas({ ...datas, protocol: taskList1 })

      //checkError();
    }
    if (["start_port_range"].includes(e.target.name)) {
      let taskList2 = [...datas.start_port_range]
      taskList2[e.target.dataset.id] = e.target.value
      setDatas({ ...datas, start_port_range: taskList2 })

      //checkError();
    }
    if (["end_port_range"].includes(e.target.name)) {
      let taskList3 = [...datas.end_port_range]
      taskList3[e.target.dataset.id] = e.target.value
      setDatas({ ...datas, end_port_range: taskList3 })

      //checkError();
    }
  }



  const addRow = (e) => {

    e.preventDefault();
    setDatas((prevState) => ({
      ...prevState,
      protocol: [...prevState.protocol, ""],
      start_port_range: [...prevState.start_port_range, ""],
      end_port_range: [...prevState.end_port_range, ""]

    }))
    setPortError((prevState) => (
      [...prevState, {

        protocol: false,
        start_port_range: false,
        end_port_range: false,
        startRange: "",
        endRange: "",
        portmsg: "",
      }]
    ))

    setPortVal((prevState) => (
      [...prevState, {

        protocol: "",
        start_port_range: "",
        end_port_range: "",
      }]
    ))
  }
  const [successopen, setsuccessOpen] = useState(false);
  const handlesuccessAlert = () => {
    setsuccessOpen(false);

  };
  const [clickSave, setClickSave] = useState(false);
  const SaveData = () => {
    let nwcount = validateData(datas);

    if (checked) {
      datas["include_daily_dataFree_limit_connect_direct"] = "1";
    }
    else {
      datas["include_daily_dataFree_limit_connect_direct"] = "0";
    }

    datas["protocol_row_count"] = datas.protocol.length;
    const disablelen = datas.protocol.filter(el => el === "GRE").length;
    datas["protocol_row_count_disable"] = disablelen;
    datas["protocol_row_count_cancel"] = datas.protocol.length;

    if (nwcount === 0) {
      datas["protocol"] = portVal;
      setClickSave(true);
      trackPromise(
        AdminService.dataFreeAppEditRoute(datas).then((res) => {

          if (res.status === 200) {
            setsuccessOpen(true);
            setEditInfo(false);
            document.getElementById("EditDirect").style.visibility = "visible";
            setPortError([{
              protocol: false,
              start_port_range: false,
              end_port_range: false,
              startRange: "",
              endRange: "",
              portmsg: "",
            }])
            setClickSave(false);
            setPortVal([{
              protocol: "",
              start_port_range: "",
              end_port_range: "",
            }])
            getDetailById();

          }
        }).catch((error) => {
          if (error.response.status === 400 || error.response.status === 500) {
            setMessage("Something went wrong. Please reload the page!");
            seterrorOpen(true);
          }
        })
      );
    }

  }
  return (
    <div>
      <div className="NavBoxRow">
        <div className="NavBoxRowText">#datafree DIRECT Application List - Admin View</div>
      </div>
      <div className="directapplication">
        <Grid container spacing={3} style={{ alignItems: "center" }}>
          <Grid item xs={2}>
            <div className="gridHead">DIRECT App Definition Approved ?</div>
          </Grid>
          <Grid item xs={2}>
            <div>
              <input type="text" className="directFieldInput" disabled="disabled" value={data?.applicationDetail?.ipConfigApproved} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <button className="editSubBtn" disabled="disabled "
                style={{ fontSize: '12px' }}>
                View Direct Configuration history
              </button>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={editInfo ? "gridBoxDisable" : "gridBox"} disabled={editInfo ? "disabled" : false}>
              <div className="approve-direct"><button className={editInfo ? "reviewAppDisable" : "reviewApp"}
                disabled={data?.applicationDetail.status === "Deleted" ? "disabled" : false}

                onClick={() => ApproveConfig('body')} >Review/Approve Direct App Definition</button></div>
              <div className="approve-direct"><button className={editInfo ? "decommisonAppDisable" : "decommisonApp"}
                disabled={data?.applicationDetail.status === "Deleted" ? "disabled" : false}
                onClick={() => DecommisionConfig('body')}>Decommission Direct App</button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="gridHead">App Owner and Basic Details</div>
          </Grid>

          <Grid item xs={3}>
            <div className="labelDirect">Channel Owner</div>
            <div>
              <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail?.publisherId?.parentCompanyId?.name} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="labelDirect">Publisher</div>
            <div>
              <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail?.publisherId?.name} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="labelDirect">Status</div>
            <div>
              <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail.status} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="labelDirect">Created</div>
            <div>
              <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={moment(data?.applicationDetail.created).format("DD/MM/YYYY")} />
            </div>
          </Grid>

          <Grid item xs={2}>
            <div>
              <button value="Edit Details" id="EditDirect"
                className={data?.applicationDetail.status === "Deleted" ? "editBtn" : "inviteButton"}
                disabled={data?.applicationDetail.status === "Deleted" ? "disabled" : false} onClick={EditClick}>
                Edit Details
              </button>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="labelDirect">App Name</div>
            {editInfo ?
              <div>
                <input type="text" id="app_name" name="app_name"
                  className={!error.app_name ? "directFieldActive" : "directFieldActiveerr"}
                  defaultValue={data?.applicationDetail.name}
                  ref={nameRef}
                  onChange={handleChange} />
              </div> :
              <div>
                <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail.name} />
              </div>
            }
            {error.app_name ? (
              <span style={{ float: 'left' }} className="errorClass topALign">{error.appmsg
                ? error.appmsg : "This field is required"}</span>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={4}>
            <div className="labelDirect">Deployment Type</div>
            <div>
              <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDeploymentDetail.deploymentType} />
            </div>
          </Grid>

          <Grid item xs={4}>
            <div className="labelDirect">Report Aggregation Tag</div>
            {editInfo ?
              <div>
                <input type="text" id="report_aggregation_tag" name="report_aggregation_tag" className="directFieldActive" defaultValue={datas.report_aggregation_tag} onChange={handleChange} />

              </div> :
              <div>
                <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} />
              </div>
            }
          </Grid>
          <Grid item xs={12}>
            <div className="gridHead">App Owner and Basic Details</div>
          </Grid>
          <Grid item xs={6}>
            <div className="labelDirect">High Data Threshold - Operational alarms raised when threshold reached in 5 minute period</div>
            {editInfo ?
              <div>
                <input type="text" id="high_data_threshold"
                  ref={thresholdRef}
                  name="high_data_threshold"
                  className={error.high_data_threshold ? "directFieldActiveerr" : "directFieldActive"}
                  defaultValue={data?.applicationDetail.ipHighDataThreshold} onChange={handleChange} />
                {error.high_data_threshold ? (
                  <span style={{ float: 'left' }} className="errorClass topALign">{error.thresholdmsg
                    ? error.thresholdmsg : "This field is required"}</span>
                ) : (
                  ""
                )}
              </div> :

              <div>
                <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail?.ipHighDataThreshold} />
              </div>
            }
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <div className="labelDirect">Monthly App #datafree Data Alert Limit (bytes)</div>
          </Grid>
          <Grid item xs={3}>
            {editInfo ?
              <div>
                <input type="text" id="monthly_data_alert_limit"
                  ref={monthlyRef} name="monthly_data_alert_limit"
                  className={error.monthly_data_alert_limit ? "directFieldActiveerr" : "directFieldActive"}
                  defaultValue={data?.applicationDetail.appDataUsageAlert} onChange={handleChange} />
                {error.monthly_data_alert_limit ? (
                  <span style={{ float: 'left' }} className="errorClass topALign">{error.monthlymsg
                    ? error.monthlymsg : "This field is required"}</span>
                ) : (
                  ""
                )}
              </div> :
              <div>
                <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail?.appDataUsageAlert} />
              </div>
            }
          </Grid>
          <Grid item xs={3}>
            <div className="labelDirect">Alert Email Addresses (comma separated)</div>
          </Grid>
          <Grid item xs={3}>
            {editInfo ?
              <div>
                <input type="text" id="alert_email_address"
                  ref={emailRef}
                  name="alert_email_address"
                  className={error.alert_email_address ? "directFieldActiveerr" : "directFieldActive"}
                  defaultValue={data?.applicationDetail.alertEmail} onChange={handleChange} />
                {error.alert_email_address ? (
                  <span style={{ float: 'left' }} className="errorClass topALign">{error.emailmsg
                    ? error.emailmsg : "This field is required"}</span>
                ) : (
                  ""
                )}
              </div> :
              <div>
                <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail?.alertEmail} />
              </div>
            }
          </Grid>
          <Grid item xs={3}>
            <div className="labelDirect">Maximum Bytes per User</div>
          </Grid>
          <Grid item xs={3}>
            {editInfo ?
              <div>
                <input type="text" id="max_byte_per_user"
                  ref={byteUserRef} name="max_byte_per_user"
                  className={error.max_byte_per_user ? "directFieldActiveerr" : "directFieldActive"}
                  defaultValue={data?.applicationDetail.maxBytesPerUser} onChange={handleChange} />
                {error.max_byte_per_user ? (
                  <span style={{ float: 'left' }} className="errorClass topALign">{error.byteusermsg
                    ? error.byteusermsg : "This field is required"}</span>
                ) : (
                  ""
                )}
              </div> :
              <div>
                <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail?.maxBytesPerUser} />
              </div>
            }
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={3}>
            <div className="labelDirect">Include Daily #datafree Data Limit Alerting ?</div>
          </Grid>
          <Grid item xs={1}>
            {editInfo ?
              <div>
                <input type="checkbox" onChange={() => setChecked(!checked)}
                  checked={checked} id="include_daily_dataFree_limit_connect_direct" name="include_daily_dataFree_limit_connect_direct" />
              </div> :
              <div>
                <input type="checkbox" disabled="disabled" />
              </div>
            }
          </Grid>
          <Grid item xs={8}>
            {checked ? <div className="rowClass">


              <div container className="datafeeBox">
                <div className="label_Direct">Daily #datafree data limit (bytes)</div>

                <div>
                  <input type="text"
                    className="BoxsubFieldInput"
                    ref={byteDayRef}
                    id="max_byte_per_day" name="max_byte_per_day"
                    defaultValue={data?.applicationDetail?.maxBytesPerDay} onChange={handleChange} />
                  {error.max_byte_per_day ? (
                    <span style={{ float: 'left' }} className="errorClass topALign">{error.bytedaymsg
                      ? error.bytedaymsg : "This field is required"}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="label_Direct">Required Action on Daily Limit</div>

                <div>
                  <select type="text" className="BoxFieldInput" id="action_required" name="action_required" onChange={handleChange}>
                    <option value="0" selected={data?.applicationDetail?.maxBytesPerDayAction === "0" && true}>Send Alerts Only</option>
                    <option value="1" selected={data?.applicationDetail?.maxBytesPerDayAction === "1" && true}>Send Alerts And Stop App</option>
                  </select>
                </div>
              </div>
            </div> : null}
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={12}>
            <div className="gridHead">biNu Server Location / Assignment</div>
          </Grid>

          <Grid item xs={2}>
            <div className="labelDirect">Provider</div>
            <div>
              <input type="text" className="directFieldInput"
                value={data?.assignedDeploymentIps?.cloud}
                disabled="disabled" style={{ cursor: "not-allowed" }} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="labelDirect">Region</div>
            <div>
              <input type="text" className="directFieldInput"
                value={data?.assignedDeploymentIps?.region}
                disabled="disabled" style={{ cursor: "not-allowed" }} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="labelDirect">Account</div>
            <div>
              <input type="text" className="directFieldInput"
                value={data?.assignedDeploymentIps?.ipAddress?.account}
                disabled="disabled" style={{ cursor: "not-allowed" }} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="labelDirect">Reserved IP</div>
            <div>
              <input type="text" className="directFieldInput"
                value={data?.assignedDeploymentIps?.ipId}
                disabled="disabled" style={{ cursor: "not-allowed" }} />
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className="labelDirect">biNu App Id</div>
            <div>
              <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDeploymentDetail?.appId} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="gridHead">Customer Server Specifics</div>
          </Grid>

          <Grid item xs={4}>
            <div className="labelDirect">IPv4 Address - Customer Endpoint</div>
            {editInfo ?
              <div>
                <input type="text"
                  ref={forwardRef}
                  id="forward_address" name="forward_address"
                  className={error.forward_address ? "directFieldActiveerr" : "directFieldActive"}
                  defaultValue={data?.applicationDetail?.directIpForward} onChange={handleChange} />
                {error.forward_address ? (
                  <span style={{ float: 'left' }} className="errorClass topALign">{error.forwardmsg
                    ? error.forwardmsg : "This field is required"}</span>
                ) : (
                  ""
                )}
              </div> :
              <div>
                <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail?.directIpForward} />
              </div>
            }
          </Grid>
          <Grid item xs={4}>
            <div className="labelDirect">biNu #datafree Sub-Domain</div>
            <div>
              <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail?.reachSubDomain} />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="labelDirect">#datafree Gateway</div>
            <div>
              <input type="text" className="directFieldInput" disabled="disabled" style={{ cursor: "not-allowed" }} value={data?.applicationDetail?.reachGateway} />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="gridHead">Protocol and Port Requirement</div>
          </Grid>
          <Grid item xs={2}>
            <div className="labelDirect" style={{ float: 'inline-end', textAlign: 'center' }}>Protocol</div>
          </Grid>
          <Grid item xs={2}>
            <div className="labelDirect" style={{ float: 'right' }}>Start of Port Range</div>
          </Grid>
          <Grid item xs={2}>
            <div className="labelDirect" style={{ float: 'right' }}>End of Port Range</div>
          </Grid>
          <Grid item xs={10}>
            {editInfo ?
              <div className="datafeeSubBox">
                {datas?.protocol && datas?.protocol.length > 0 ? datas?.protocol.map((item, index) => (
                  <div className="boxRow">
                    <div className="flex1">
                      <select type="text"
                        className={

                          !portError[index]?.protocol ? "inputBox_Row" : "inputBox_Rowerr"
                        }
                        style={{ width: '69%', height: '34px' }}
                        id="protocol" name="protocol"
                        onChange={(e) => handlePort(e, index)} data-id={index}
                      >
                        <option value=""></option>
                        <option value="GRE" selected={item === "GRE" && true}>GRE</option>
                        <option value="TCP" selected={item === "TCP" && true}>TCP</option>
                        <option value="UDP" selected={item === "UDP" && true}>UDP</option>

                      </select>
                      {portError[index]?.protocol ? (
                        <p style={{ float: 'left', marginLeft: '45px', marginTop: '0px', textAlign: 'left' }} className="errorClass">This field is required.</p>
                      ) : (
                        ""
                      )
                      }
                    </div>

                    <div className="flex1">
                      <input type="text" id="start_port_range" name="start_port_range" disabled={item === "GRE" || item === "" ? "disabled" : false} className="inputBox_Row"
                        value={datas?.start_port_range[index]} onChange={(e) => handlePort(e, index)} data-id={index} />

                      {portError[index]?.start_port_range ? (
                        <p style={{ float: 'left', marginLeft: '34px', marginTop: '0px', textAlign: 'left' }} className="errorClass">{portError[index]?.startRange}</p>
                      ) : (
                        ""
                      )
                      }
                    </div>
                    <div className="flex1">
                      <input type="text" id="end_port_range" name="end_port_range" disabled={item === "GRE" || item === "" ? "disabled" : false} className="inputBox_Row"
                        value={datas?.end_port_range[index]} onChange={(e) => handlePort(e, index)} data-id={index} />

                      {portError[index]?.end_port_range ? (
                        <p style={{ float: 'left', marginLeft: '34px', marginTop: '0px', textAlign: 'left' }} className="errorClass">{portError[index]?.endRange}</p>
                      ) : (
                        ""
                      )
                      }
                    </div>
                    <div className="flex1">
                      <button className="closeIcon" disabled={index === 0 ? "disabled" : false} onClick={() => deleteRow(index)}>
                        X
                      </button>
                    </div>
                  </div>
                )) : null}
                <div style={{ textAlign: "left" }}>
                  <button className="defultbtn" onClick={addRow} >
                    Add
                  </button>
                </div>
              </div> :
              <div className="datafeeSubBox">
                {data?.portConfigs && data?.portConfigs.length > 0 ? data?.portConfigs.map((item, index) => (
                  <div className="boxRow">
                    <div>
                      <input type="text" disabled="disabled" style={{ cursor: "not-allowed" }} className="inputBox_Row" value={item?.portProtocol?.protocol} />
                    </div>
                    <div>
                      <input type="text" disabled="disabled" style={{ cursor: "not-allowed" }} className="inputBox_Row" value={item?.portStart} />
                    </div>
                    <div>
                      <input type="text" disabled="disabled" style={{ cursor: "not-allowed" }} className="inputBox_Row" value={item?.portEnd} />
                    </div>
                  </div>
                )) : null}
              </div>
            }
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={12}>
            {editInfo ? <div className="ivitediv">
              <button className="inviteButton" color="primary" onClick={route} >
                Cancel
              </button>
              <button className="inviteButton"
                disabled={clickSave ? "disabled" : false}
                color="primary" onClick={SaveData}>
                Save
              </button>
            </div> : null}
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}> <div className="ivitediv">
            <button

              className={editInfo ? "inviteButtonDirect" : "inviteButton"}
              onClick={returnList}
              color="primary"
              disabled={editInfo ? "disabled" : false}
            >
              Return to #datafree Direct Application List
            </button>
          </div></Grid>

        </Grid>


        <div className="divSpace"></div>
      </div>
      {/* //////////////////////Approve Config///////////// */}
      <Dialog open={approve}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth scroll={scroll} maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="appdialogtitle">#datafree DIRECT - Review / Approve Configuration</div>
          <div className="appsubHead">
            PLEASE REVIEW THE #datafree DIRECT App DEFINITION BELOW. IF APPROVED PLEASE CLICK ON THE APPROVAL BUTTON AT THE BOTTOM OF THE SCREEN. THE APP WILL THEN BE AVAILABLE FOR DEPLOYMENT.
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div className="topHead">App Owner and Basic Details</div>
            </Grid>

            <Grid item xs={4}>
              <div className="labelDirect">Channel Owner</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.publisherId?.parentCompanyId?.name}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Publisher</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.publisherId?.name}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Status</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail.status}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>

            <Grid item xs={4}>
              <div className="labelDirect">App Name</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail.name}
                disabled="disabled"
                label="email"
                className="dialoginputValue"

                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Deployment Type</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDeploymentDetail.deploymentType}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Report Aggregation Tag</div>
              <input
                type="text"
                id="versionName"
                name="versionName"

                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="topHead">Data Usage Limits</div>
            </Grid>

            <Grid item xs={4}>
              <div className="labelDirect">High Data Threshold</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail.ipHighDataThreshold}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Maximum Bytes per Day</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.maxBytesPerDay}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Maximum Bytes per User per Day</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail.maxBytesPerUser}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="topHead">biNu Server Location / Assignment</div>
            </Grid>

            <Grid item xs={2}>
              <div className="labelDirect">Provider</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.assignedDeploymentIps?.cloud}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <div className="labelDirect">Region</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.assignedDeploymentIps?.region}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <div className="labelDirect">Account</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.assignedDeploymentIps?.ipAddress?.account}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <div className="labelDirect">Reserved IP</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.assignedDeploymentIps?.ipId}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <div className="labelDirect">biNu App Id</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDeploymentDetail?.appId}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="topHead">Customer Server Specifics</div>
            </Grid>

            <Grid item xs={4}>
              <div className="labelDirect">IPv4 Address - Customer Endpoint</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.directIpForward}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">biNu #datafree Sub-Domain</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.reachSubDomain}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">#datafree Gateway</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.reachGateway}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="gridHead">Protocol and Port Requirement</div>
            </Grid>
            <Grid item xs={4}>
              <div className="labelDirect">Protocol</div>
            </Grid>
            <Grid item xs={4}>
              <div className="labelDirect">Start of Port Range</div>
            </Grid>
            <Grid item xs={4}>
              <div className="labelDirect">End of Port Range</div>
            </Grid>
            <Grid item xs={12}>


              <div className="datafeeSubBox">
                {data?.portConfigs && data?.portConfigs.length > 0 ? data?.portConfigs.map((item, index) => (
                  <div className="boxRow">
                    <div>
                      <input type="text" disabled="disabled" className="inputBox_Row" value={item?.portProtocol?.protocol} />
                    </div>
                    <div>
                      <input type="text" disabled="disabled" className="inputBox_Row" value={item?.portStart} />
                    </div>
                    <div>
                      <input type="text" disabled="disabled" className="inputBox_Row" value={item?.portEnd} />
                    </div>
                  </div>
                )) : null}
              </div>

            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>

          <div className="ivitediv">

            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="approveButtonDirect" color="primary" onClick={approveService}>
              Review/Approve Direct App Configuration
            </button>
          </div>

        </DialogActions>
      </Dialog>

      {/* //////////////////////Decommision Direct App///////////// /////////////*/}
      <Dialog open={decommision} scroll={scroll}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth maxWidth="md" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="appdialogtitle">#datafree DIRECT - Decommission DIRECT App</div>
          <div className="appsubHead styleColor">
            YOU HAVE REQUESTED TO DECOMMISSION THE #datafree DIRECT App DEFINED BELOW - ARE YOU SURE ? IF SO PLEASE CLICK ON THE DECOMMISSION BUTTON AT THE BOTTOM OF THE SCREEN. THE #datafree IP WILL BE RELEASED Type FOR FUTURE USAGE AND THE APP WILL BE SET AS ARCHIVED.
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div className="topHead">App Owner and Basic Details</div>
            </Grid>

            <Grid item xs={4}>
              <div className="labelDirect">Channel Owner</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.publisherId?.parentCompanyId?.name}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Publisher</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.publisherId?.name}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Status</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail.status}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>

            <Grid item xs={4}>
              <div className="labelDirect">App Name</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail.name}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Deployment Type</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDeploymentDetail.deploymentType}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Report Aggregation Tag</div>
              <input
                type="text"
                id="versionName"
                name="versionName"

                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="topHead">Data Usage Limits</div>
            </Grid>

            <Grid item xs={4}>
              <div className="labelDirect">High Data Threshold</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail.ipHighDataThreshold}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Maximum Bytes per Day</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.maxBytesPerDay}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">Maximum Bytes per User per Day</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail.maxBytesPerUser}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="topHead">biNu Server Location / Assignment</div>
            </Grid>

            <Grid item xs={2}>
              <div className="labelDirect">Provider</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.assignedDeploymentIps?.cloud}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <div className="labelDirect">Region</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.assignedDeploymentIps?.region}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <div className="labelDirect">Account</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.assignedDeploymentIps?.ipAddress?.account}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <div className="labelDirect">Reserved IP</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.assignedDeploymentIps?.ipId}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <div className="labelDirect">biNu App Id</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDeploymentDetail?.appId}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="topHead">Customer Server Specifics</div>
            </Grid>

            <Grid item xs={4}>
              <div className="labelDirect">IPv4 Address - Customer Endpoint</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.directIpForward}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">biNu #datafree Sub-Domain</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.reachSubDomain}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <div className="labelDirect">#datafree Gateway</div>
              <input
                type="text"
                id="versionName"
                name="versionName"
                value={data?.applicationDetail?.reachGateway}
                disabled="disabled"
                label="email"
                className="dialoginputValue"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="gridHead">Protocol and Port Requirement</div>
            </Grid>
            <Grid item xs={4}>
              <div className="labelDirect">Protocol</div>
            </Grid>
            <Grid item xs={4}>
              <div className="labelDirect">Start of Port Range</div>
            </Grid>
            <Grid item xs={4}>
              <div className="labelDirect">End of Port Range</div>
            </Grid>
            <Grid item xs={12}>
              <div className="datafeeSubBox">
                {data?.portConfigs && data?.portConfigs.length > 0 ? data?.portConfigs.map((item, index) => (
                  <div className="boxRow">
                    <div>
                      <input type="text" disabled="disabled" className="inputBox_Row" value={item?.portProtocol?.protocol} />
                    </div>
                    <div>
                      <input type="text" disabled="disabled" className="inputBox_Row" value={item?.portStart} />
                    </div>
                    <div>
                      <input type="text" disabled="disabled" className="inputBox_Row" value={item?.portEnd} />
                    </div>
                  </div>
                )) : null}
              </div>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>

          <div className="ivitediv">
            <button className="clearblueButton" color="primary" onClick={handleClose}>
              Cancel
            </button>

          </div>

          <Grid item xs={12}>
            <button className="ButtonDirect" color="primary" onClick={Decommision}>
              Decommision #datafree Direct App
            </button>

          </Grid>


        </DialogActions>
      </Dialog>
      <Snackbar
        style={{
          backgroundColor: "white", maxWidth: '100%', height: 100
        }}
        open={openApprove}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={2500}
        onClose={handleApproveAlertClose}
      >
        <Alert
          style={{
            backgroundColor: "white",
            alignItems: "center",
            fontSize: "13px",
            maxWidth: '100%', height: 100,
            color: "#939598",
          }}
          onClose={handleApproveAlertClose}
          severity="error"
        >
          {approveError ? approveError : ""}
        </Alert>
      </Snackbar>

      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          #datafree Direct Edited Successfully
        </Alert>
      </Snackbar>
      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default AdminDatafreeDirectApplication;
