import React, { useState, useEffect } from "react";
import "../../admin/adminPages/adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Grid } from "@material-ui/core";
import licenseeService from "../../../api/licenseeService";
import DataTable from "react-data-table-component";
import { IoMdCloseCircleOutline } from "react-icons/io";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});


const customStyles = {
  headCells: {
    style: {
      border: "1px solid #e7ecf1",
      borderBottom: "1px solid #111",
      fontSize: '14px',
      whiteSpace: 'normal !important',
      wordWrap: "break-word"
    },
  },
  cells: {
    style: {
      border: "1px solid #e7ecf1",
      fontSize: '14px',
      whiteSpace: 'normal !important',
      wordWrap: "break-word"
    },
  },
  title: {
    style: {
      whiteSpace: 'normal !important',
      wordWrap: "break-word"
    }
  },

};

function LicenseeRevenue() {
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [scroll, setScroll] = useState("paper");
  const [searchDetails, setSearchDetails] = useState();
  const [changedropdown, setChangedropdown] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [existOpen, setExistOpen] = useState(false);
  const companyIds = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : "";

  const [data, setData] = useState({
    licensee: companyIds,
    revenueTypeCode: "",
    revenueTypeDescription: ""
  });
  const [invite, setInvite] = useState({
    licensee: companyIds,
    licenseeName: localStorage.getItem("companyname"),
    revenueTypeCode: "",
    revenueTypeDescription: ""
  });


  const [tablecol] = useState([
    {
      name: "Licensee",
      selector: "name"
    },
    {
      name: "Revenue Type Code",
      selector: "revType",
    },
    {
      name: "Revenue Type Description",
      selector: "revDescription",
    },
    {
      name: (
        <button className="deleteRows"
          onClick={() => setConfirmDelete()}
        >
          Delete
        </button>
      ),
      cell: (props) => {
        return <input type="checkbox" className="existCheck" id={"checks_" + props.rtId}
          onClick={() => handleSelectedRow(props.rtId, props.companyId, props.revType)}
        />;
      },
      selector: "",
    },
  ]);
  const [checkBoxSelect, setcheckBoxSelect] = useState([]);
  const handleSelectedRow = (revId, companyid, revtype) => {
    const body_Data = companyid + "_" + revtype + "_" + revId;
    const checkboxs = document.getElementById("checks_" + revId);
    try {
      licenseeService.revenueTypeExist({ 'rtId': revId }).then((res) => {
        if (res.data === true) {
          setExistOpen(true);
          setTimeout(() => {
            setExistOpen(false);
            checkboxs.checked = false;
          }, 2000);
        }
        else {

          const valsnws = checkBoxSelect.find((v) => v.myTextEditBox === body_Data);

          if (valsnws !== undefined) {
            let indexss = checkBoxSelect.map(x => {
              return x
            }).indexOf(valsnws);

            checkBoxSelect.splice(indexss, 1);
          }
          else {
            checkBoxSelect.push({ myTextEditBox: body_Data });

          }
          setcheckBoxSelect(checkBoxSelect);
        }
      });
    } catch (err) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  };

  const setConfirmDelete = () => {
    try {
      licenseeService.revenueDeletes({ 'myTextEditBox': checkBoxSelect }).then((res) => {
        checkBoxSelect.splice(0, checkBoxSelect.length);
        companyListdata();
      });
    } catch (err) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const handleClose = () => {
    checkBoxSelect.splice(0, checkBoxSelect.length);
    setOpen(false);
    setError({
      licensee: false,
      revenueTypeCode: false,
      revenueTypeDescription: false,
      licenseeMsg: "",
      typeCode: "",
      typeDescription: ""
    });
    setInvite({
      ...invite,
      revenueTypeCode: "",
      revenueTypeDescription: ""
    });

  };
  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };


  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };


  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  //search
  async function companyListdata() {
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setLoaderVisible(true);
    setSearchDetails({});
    checkBoxSelect.splice(0, checkBoxSelect.length);
    try {
      trackPromise(
        licenseeService.revenueSearchs(data).then((res) => {
          setLoaderVisible(false);
          setSearchDetails(res.data);
          if (res.status === 200 && res.data.length > 0) {
            setChangedropdown(false);
          } else if (res.status === 200 && res.data.length === 0) {
            setEmpty(true);
            setChangedropdown(false);
          } else {
            setChangedropdown(true);
            setEmpty(false);
          }
        })
      );
    } catch (error) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  const [error, setError] = useState({
    licensee: false,
    revenueTypeCode: false,
    revenueTypeDescription: false,
    licenseeMsg: "",
    typeCode: "",
    typeDescription: ""
  });

  const [resvals, setResvals] = useState(false);
  const checkRevenue = (typecodes) => {
    invite['revenueTypeCode'] = typecodes;
    if (typecodes === "") {
      setError({
        ...error,
        revenueTypeCode: true,
        typeCode: "This field is required",
      });
    }
    else if (revenueReg.test(typecodes) === false) {
      setError({
        ...error,
        revenueTypeCode: true,
        typeCode: "Only alphanumeric characters and underscore or hyphen is allowed",
      });
    }
    else if (typecodes.length > 10) {
      setError({
        ...error,
        revenueTypeCode: true,
        typeCode: "Please enter no more than 10 characters.",
      });
    }
    else {
      try {
        licenseeService.revenueCodeExist(invite).then((res) => {
          setResvals(res.data);
          if (res.data === true) {
            setError({
              ...error,
              revenueTypeCode: true,
              typeCode: "Value must be unique"
            })
          }
          else {
            setError({
              ...error,
              revenueTypeCode: false,
              typeCode: ""
            })
          }
        });
      } catch (err) {
        setMessage("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }

  }

  const revenueReg = /^[a-zA-Z0-9-_]*$/i;
  const handleChange = (e) => {
    e.preventDefault();
    const newdata = { ...invite };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "revenueTypeCode") {
      checkRevenue(e.target.value);
      if (e.target.value === "") {
        setError({
          ...error,
          revenueTypeCode: true,
          typeCode: "This field is required"
        });
      }
      else if (revenueReg.test(e.target.value) === false) {

        setError({
          ...error,
          revenueTypeCode: true,
          typeCode: "Only alphanumeric characters and underscore or hyphen is allowed",
        });
      }
      else if ((e.target.value).length > 10) {
        setError({
          ...error,
          revenueTypeCode: true,
          typeCode: "Please enter no more than 10 characters.",
        });
      }
      else {
        setError({
          ...error,
          revenueTypeCode: false,
          typeCode: ""
        });
      }
    }

    if (e.target.id === "revenueTypeDescription") {
      if (e.target.value === "") {
        setError({
          ...error,
          revenueTypeDescription: true,
          typeDescription: "This field is required"
        });
      }
      else if (revenueReg.test(e.target.value) === false) {
        setError({
          ...error,
          revenueTypeDescription: true,
          typeDescription: "Only alphanumeric characters and underscore or hyphen is allowed",
        });
      }
      else if ((e.target.value).length > 40) {
        setError({
          ...error,
          revenueTypeDescription: true,
          typeDescription: "Please enter no more than 40 characters.",
        });
      }
      else {
        setError({
          ...error,
          revenueTypeDescription: false,
          typeDescription: ""
        });
      }
    }
    setInvite(newdata);
  };


  const [message, setMessage] = useState();


  const [clickSave, setClickSave] = useState(false);
  const [toastMsg, setToastMsg] = useState();

  const validateSave = () => {
    let count = 0;
    let nwerror = { ...error }
    if (invite.licensee === "") {
      nwerror.licensee = true;
      nwerror.licenseeMsg = "This field is required"
      count++;
    }
    else {
      nwerror.licensee = false;
      nwerror.licenseeMsg = ""
    }

    if (invite.revenueTypeCode === "") {
      nwerror.revenueTypeCode = true;
      nwerror.typeCode = "This field is required"
      count++;
    }
    else if (revenueReg.test(invite.revenueTypeCode) === false) {
      nwerror.revenueTypeCode = true;
      nwerror.typeCode = "Only alphanumeric characters and underscore or hyphen is allowed";
      count++;
    }
    else if ((invite.revenueTypeCode.length) > 10) {
      nwerror.revenueTypeCode = true;
      nwerror.typeCode = "Please enter no more than 10 characters.";
      count++;
    }
    else if (resvals === true) {
      nwerror.revenueTypeCode = true;
      nwerror.typeCode = "Value must be unique";
      count++;
    }
    else {
      nwerror.revenueTypeCode = false;
      nwerror.typeCode = ""
    }

    if (invite.revenueTypeDescription === "") {
      nwerror.revenueTypeDescription = true;
      nwerror.typeDescription = "This field is required"
      count++;
    }
    else if (revenueReg.test(invite.revenueTypeDescription) === false) {
      nwerror.revenueTypeDescription = true;
      nwerror.typeDescription = "Only alphanumeric characters and underscore or hyphen is allowed";
      count++;
    }
    else if (invite.revenueTypeDescription.length > 40) {
      nwerror.revenueTypeDescription = true;
      nwerror.typeDescription = "Please enter no more than 40 characters.";
      count++;
    }
    else {
      nwerror.revenueTypeDescription = false;
      nwerror.typeDescription = ""
    }

    setError(nwerror);
    return count;
  }
  const addRevenue = () => {
    const counts = validateSave();
    if (counts === 0) {
      setClickSave(true);
      setLoading(true);
      try {
        licenseeService.revenueAddds(invite).then((res) => {
          if (res.data === "success") {
            setToastMsg("RevenueType Added Successfully");
            handleClose();
            setsuccessOpen(true);
            companyListdata();
          }
          setClickSave(false);
          setLoading(false);
        });
      } catch (err) {
        setMessage("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }
  }
  useEffect(() => {
    document.title = "Datafree Portal";
    const companyName = localStorage.getItem("companyname") ? localStorage.getItem("companyname") : "";
    document.getElementById('licensee').value = companyName
    //getLicensee();
  }, []);

  return (
    <div className="mainBox">
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Maintain Licensee Revenue Types - Admin View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">Licensee</td>
                <td className="col-md-2">Revenue Type Code</td>
                <td className="col-md-4">	Revenue Type Description</td>

                <td className="col-md-2"></td>
              </tr>
              <tr>
                <td className="col-md-2">
                  <input type="text" disabled

                    className="form-control"
                    id="licensee" name="licensee"></input>
                </td>

                <td className="col-md-2">
                  <input type="text"

                    className="form-control"
                    id="revenueTypeCode" name="revenueTypeCode" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-4">
                  <input type="text"

                    className="form-control" id="revenueTypeDescription" name="revenueTypeDescription" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                  <button className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                    disabled={changedropdown ? "disabled" : false}
                    onClick={companyListdata}>
                    Search
                  </button>
                </td>

              </tr>
            </tbody>
          </table>

        </div>
        <div className="createButtonDiv">
          <button className="defultbtn"
            title="Click here to create new Revenue Type"
            onClick={() => handleClickOpen("body")}>

            + Create New Revenue Type
          </button>
        </div>
        <div className="ButtonDiv">
          {
            loaderVisible ?
              <div className="loaderdata" style={{ marginTop: '6%' }}>
                <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />

              </div> : null
          }
        </div>
        {search && !loaderVisible ? (

          !empty ? <div>

            <DataTable columns={tablecol} key={searchDetails} data={searchDetails}
              customStyles={customStyles} noDataComponent={false} />
          </div> : <DataTable columns={tablecol} key={searchDetails} data={searchDetails}
            customStyles={customStyles} />

        ) : (
          ""
        )}
      </div>
      {/* <EmptyTable  data="No Companies Found" col={tablecols}  tableName="Company List" /> */}
      {/* //// ======================================Create Licensee Revenue Type  ======================= */}
      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Create Licensee Revenue Type
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Licensee</div>

              <input type="text" disabled
                style={{ backgroundColor: '#eef1f5', cursor: 'not-allowed', border: '1px solid #c2cad8', width: '49%', height:'34px' }}
                className={error.licensee ? "errcmpnydialoginput" : "cmpnydialoginput"}
                id="licensee" name="licensee" defaultValue={invite.licenseeName}></input>
              {error.licensee ? <p className="errorClass">{error.licenseeMsg ? error.licenseeMsg : "This field is required"}</p> : null}
            </Grid>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Revenue Type Code</div>
              <input
                type="text"
                id="revenueTypeCode"
                name="revenueTypeCode"
                style={{ width: '49%',height:'34px'}}
                className={error.revenueTypeCode ? "errcmpnydialoginput" : "cmpnydialoginput"}
                onChange={(e) => handleChange(e)}
                onBlur={(e) => handleChange(e)}
                fullWidth
              />
              {error.revenueTypeCode ? <p className="errorClass">{error.typeCode ? error.typeCode : "This field is required"}</p> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Revenue Type Description</div>
              <input
                type="text"
                id="revenueTypeDescription"
                name="revenueTypeDescription"
                style={{height:'34px'}}
                className={error.revenueTypeDescription ? "errcmpnydialoginput" : "cmpnydialoginput"}
                onChange={(e) => handleChange(e)}
                onBlur={(e) => handleChange(e)}
                fullWidth
              />

              {error.revenueTypeDescription ? <p className="errorClass">{error.typeDescription ? error.typeDescription : "This field is required"}</p> : null}

            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>

            <button className="inviteButton"
              disabled={clickSave ? "disabled" : false}
              onClick={addRevenue} color="primary">
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={existOpen} classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogContent>
          <p>
            This Revenue Type cannot be deleted as it has been assigned to an App
          </p>
        </DialogContent>

      </Dialog>
      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Saved Successfully!!"}
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default LicenseeRevenue;