import reachLogo from "../../../../../assets/img/reach logo.jpg";
import wrapLogo from "../../../../../assets/img/wrap logo.jpg";
export const dataFreeWebMenu = [
  {
    id: 1,
    title: "I want to ...",
    logo: reachLogo,
    content: "... Publish my website so it can be accessed in a mobile browser #datafree",
    name:"reach",
    path: "",
  },
  {
    id: 2,
    title: "I want to ...",
    logo: wrapLogo,
    content: "... Take my existing website and quickly replicate it as a smartphone App #datafree",
    name:"wrap",
    path: "",
  },
  
];
