import axios from "axios";
import {
  API_URL,
  companyListRequests,
  exitimpersonate
} from "./constants";

class AccountService {
    //show Account Manager Index Page
    async AccountIndex() {
      let auth = localStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      };
  
      const response = await axios.get(
        `${API_URL}${companyListRequests.AccountIndex}`,
        { headers }
      );
      return response;
    }
    async publisherIdDetailView(id) {
      let auth = localStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      };
  
      const response = await axios.get(
        `${API_URL}${companyListRequests.publisherIdDetailView}/${id}/detail/view`,
        { headers }
      );
      return response;
    }
    async getUserDetails(data) {
      let auth = localStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      };
  
      const response = await axios.post(
        `${API_URL}${companyListRequests.publishergetUserDetails}`,data,
        { headers }
      );
      return response;
    }
    async EditUserDetails(data) {
      let auth = localStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      };
  
      const response = await axios.post(
        `${API_URL}${companyListRequests.publishereditUserDetails}`,data,
        { headers }
      );
      return response;
    }
    async resetPassword(data) {
      let auth = localStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      };
  
      const response = await axios.post(
        `${API_URL}${companyListRequests.publisherPasswordReset}`,data,
        { headers }
      );
      return response;
    }
    async editPublisherCompany(data) {
      let auth = localStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      };
  
      const response = await axios.post(
        `${API_URL}${companyListRequests.publisherEditCompanyDetails}`,data,
        { headers }
      );
      return response;
    }
    async NameCheck(data) {
      let auth = localStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      };
  
      const response = await axios.post(
        `${API_URL}${companyListRequests.publisherCompanyNameCheck}`,data,
        { headers }
      );
      return response;
    }
    async exitImpersonate() {
      let auth = localStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      };
  
      const response = await axios.get(
        `${API_URL}${exitimpersonate.exitimpersonateuser}`,
        { headers }
      );
      return response;
    }
    //Application List Search
    async ApplicationSearch(data) {
        let auth = localStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth,
        };
        const response = await axios.post(
          `${API_URL}${companyListRequests.ApplicationSearch}`,
          data,
          { headers }
          // { headers,params: data  }
        );
        return response;
      }
      async publisherListSearch(data) {
        let auth = localStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth,
        };
        const response = await axios.post(
          `${API_URL}${companyListRequests.publisherListSearch}`,
          data,
          { headers }
        );
        return response;
      }
      async userListSearch(data) {
        let auth = localStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth,
        };
        const response = await axios.post(
          `${API_URL}${companyListRequests.userListSearch}`,
          data,
          { headers }
        );
        return response;
      }
      async accountApplicationSearch(data) {
        let auth = localStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth,
        };
        const response = await axios.post(
          `${API_URL}${companyListRequests.accountApplicationListSearch}`,
          data,
          { headers }
        );
        return response;
      }
      async EmailCheck(data) {
        let auth = localStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth,
        };
    
        const response = await axios.post(
          `${API_URL}${companyListRequests.publisherUserEmailCheck}`,data,
          { headers }
        );
        return response;
      }
      async inviteUsers(data) {
        let auth = localStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth,
        };
    
        const response = await axios.post(
          `${API_URL}${companyListRequests.invitePublisherUser}`,data,
          { headers }
        );
        return response;
      }
      async accountView() {
        let auth = localStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth,
        };
    
        const response = await axios.get(
          `${API_URL}${companyListRequests.accountManagerAccountView}`,
          { headers }
        );
        return response;
      }
      
      //Report Index
      async reportingAccount() {
        let auth = localStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth,
        };
    
        const response = await axios.get(
          `${API_URL}${companyListRequests.reportingAccount}`,
          { headers }
        );
        return response;
      }

      // Report Period
      async reportPeriod(data) {
        let auth = localStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth,
        };
    
        const response = await axios.post(
          `${API_URL}${companyListRequests.reportPeriod}`,data,
          { headers }
        );
        return response;
      }
}

export default new AccountService();