import {FiSettings} from "react-icons/fi";
import {BsHash} from "react-icons/bs";
import {FaTags,FaIndustry,FaUsers,FaTasks} from "react-icons/fa";
import applicationIcon from "../../../assets/img/white-app.png";
import reportingIcon from "../../../assets/img/reporting-icon.png";
import docIcon from "../../../assets/img/doc-icon.png";
import accountIcon from "../../../assets/img/account-icon.png";
import supportIcon from "../../../assets/img/support-icon.png";

export const sideNavAdmin = [
    //Development
    {
      id:1,
      title: "Development",
      collapsedIcon:<BsHash/>,
      subMenu: [
        {
          id:1,
          titles:"Click here to view Company List",
          title: "Company List",
          icon : <FaIndustry/>,
          path: "/admin/company/index",
        },
        {
            id:2,
            title: "User List",
            titles:"Click here to view User List",
            icon :<FaUsers/>,
            path: "/admin/user/index",
          },
          {
            id:3,
            title: "Applications",
            titles:"Click here to view application list",
            imageIcon : applicationIcon,
            path: "/admin/application/index",
          },
          {
            id:4,
            title: "biNu Platform Versions",
            titles:"Click here to view biNu client versions list",
            icon : <FaTags/>,
            path: "/admin/binu/versions/list/index",
          },
          {
            id:5,
            title: "biNu SDK Versions",
            icon : <FaTasks/>,
            titles:"Click here to view biNu SDK versions list",
            path: "/admin/binu/sdk/versions/list/index",
          },
          {
            id:6,
            title: "Reporting",
            imageIcon : reportingIcon,
            titles:"Click here to view Reporting",
            path: "https://powerbi.microsoft.com/en-us/",
          },
          {
            id:7,
            title: "Utilities",
            icon : <FiSettings/>,
            titles:"Click here to view Utilities",
            path: "/admin/utilities/option",
          },
          {
            id:8,
            title: "Datafree Knowledge Base",
            titles:"Click here to view biNu Knowledge Base",
            imageIcon : docIcon,
            
          },
      ],
      
    },
    //Settings
    {
      id:2,
      title: "Settings",
      collapsedIcon:<BsHash/>,
      subMenu: [
        { id:1,  imageIcon: accountIcon,title: "Account", titles:"Click here to view account details", path: "/admin/user/account/details/view" },
        { id:2, imageIcon : supportIcon,title: "Support", titles:"Example Pages", path: "https://datafr.ee/contact/" },
       
      ],
     
    },
   
  ];
  